import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  FormGroup,
  Input,
  Label,
  Spinner,
  Table,
} from "reactstrap";
import swal from "sweetalert";
import EjecutarApi from "../Consultas/Consultas";
import Notifications from "react-notify-toast";
import { getVars } from "../GlobalStorage";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Sidebar from "../components/Sidebar";
import { isNullOrUndefined } from "../utils/emptyValidations";
import LineGraphHg from "../components/graficas/LineGraphHg";
import BarGraphHg from "../components/graficas/BarGraphHg";
import BarGraphHorizontal from "../components/graficas/BarHorizontal";

const EstadisticasVales = (props) => {
  const [userData, setUserData] = useState();
  const [menu, setMenu] = useState({
    Ver: 0,
    Agregar: 0,
    Editar: 0,
    Eliminar: 0,
    Seguimiento: 0,
    Exportar: 0,
    Imprimir: 0,
    ViewAll: 0,
    idTipoUser: 0,
  });
  const history = useHistory();
  const token = getVars("Token").token;
  const [semanas, setSemanas] = useState([]);
  const [remesas, setRemesas] = useState([]);
  const [catDaysUser, setCatDaysUser] = useState([]);
  const [form, setForm] = useState({
    semana: 0,
    fecha: "",
  });
  const [formUser, setFormUser] = useState({
    semana: 0,
    fecha: "",
  });
  const [formBarRegion, setFormBarRegion] = useState({
    remesa: 0,
    region: 0,
  });
  const [datos2, setDatos2] = useState([]);
  const [datosR, setDatosR] = useState([]);
  const [datosM, setDatosM] = useState([]);
  const [datosUser, setDatosUser] = useState([]);
  const [datosRegion, setDatosRegion] = useState([]);
  const [datosMunicipio, setDatosMunicipio] = useState([]);
  const [datosMetasRegion, setDatosMetasRegion] = useState([]);
  const [datosMetasMunicipio, setDatosMetasMunicipio] = useState([]);
  const [myLabelsUser, setMyLabelsUser] = useState([]);
  // const [dataCveInterventor, setDataCveInterventor] = useState({});
  const [myLabelsMetasRegion, setMyLabelsMetasRegion] = useState([]);
  const [myLabelsMetasMunicipio, setMyLabelsMetasMunicipio] = useState([]);
  const [loadingCatalogs, setLoadingCatalogs] = useState(false);
  const [loadingDaysUser, setLoadingDaysUser] = useState(false);
  const [loadingWeek, setLoadingWeek] = useState(false);
  const [loadingUser, setLoadingUser] = useState(false);
  const [loadingRemesa, setLoadingRemesa] = useState(false);
  const [viewGrapHora, setViewGrapHora] = useState(false);
  const [viewGrapUser, setViewGrapUser] = useState(false);
  const [viewGrapBarRegion, setViewGrapBarRegion] = useState(false);
  const [viewGrapBarMunicipio, setViewGrapBarMunicipio] = useState(false);
  const handleMiniClick = () => {
    document.body.classList.toggle("sidebar-mini");
  };

  useEffect(() => {
    setUserData(getVars("Token"));
  }, []);

  useEffect(() => {
    if (isNullOrUndefined(userData)) return;
    const menus = userData.menu;
    const currentMenu = menus.find((menu) => menu.idMenu === 31);
    if (isNullOrUndefined(currentMenu) || currentMenu.Ver < 1) {
      swal({
        title: "Atención",
        text: "No tiene permisos para este módulo, contacte al administrador",
        icon: "warning",
      });
      history.push("/not-found");
      return;
    }
    setMenu({
      Ver: currentMenu.Ver,
      Agregar: currentMenu.Agregar,
      Editar: currentMenu.Editar,
      Eliminar: currentMenu.Eliminar,
      Seguimiento: currentMenu.Seguimiento,
      Exportar: currentMenu.Exportar,
      Imprimir: currentMenu.Imprimir,
      ViewAll: currentMenu.ViewAll,
      idTipoUser: userData.user.idTipoUser.id,
    });
  }, [userData, history]);

  useEffect(() => {
    setLoadingCatalogs(true);
    EjecutarApi({}, `vales/getSemanasTrabajo`, token, "GET")
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.success && res.results) {
          let cat = res.data;
          setSemanas(cat);
        } else {
          swal({
            title: "Ha ocurrido un error",
            text: res.errors,
            icon: "warning",
          });
        }
        setLoadingCatalogs(false);
      })
      .catch((error) => {
        setLoadingCatalogs(false);
        swal({
          title: "Ha ocurrido un error",
          text: error.errors,
          icon: "warning",
        });
      });
  }, [token]);

  useEffect(() => {
    setLoadingCatalogs(true);
    EjecutarApi({}, `vales/getRemesas`, token, "GET")
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.success && res.results) {
          let cat = res.data;
          setRemesas(cat);
        } else {
          swal({
            title: "Ha ocurrido un error",
            text: res.errors,
            icon: "warning",
          });
        }
        setLoadingCatalogs(false);
      })
      .catch((error) => {
        setLoadingCatalogs(false);
        swal({
          title: "Ha ocurrido un error",
          text: error.errors,
          icon: "warning",
        });
      });
  }, [token]);

  const getDataGrap = () => {
    setLoadingWeek(true);

    if (form.semana === 0) {
      swal({
        title: "¡Atención!",
        text: `Debe seleccionar la semana a consultar`,
        icon: "warning",
      });
      setLoadingWeek(false);
      return;
    }

    const token = getVars("Token");
    const data = {
      semana: form.semana,
      fecha: form.fecha,
    };
    EjecutarApi(data, "vales/getPineo", token.token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.results && res.success) {
          let datos2 = [];
          res.data.forEach((item, index) => {
            let totales = [];
            item.data.forEach((d) => {
              totales.push(d.Total);
            });
            datos2.push({
              name:
                `Día: ${item.dia} - Total: ` +
                new Intl.NumberFormat("en-EN").format(item.total),
              data: totales,
            });
          });
          setDatos2(datos2);
          setViewGrapHora(true);
        } else {
          swal({
            title: "¡Atención!",
            text: `${res.errors}`,
            icon: "info",
          });
        }
        setLoadingWeek(false);
      })
      .catch((e) => {
        setLoadingWeek(false);
        swal({
          title: "¡Atención!",
          text: `${e.message}`,
          icon: "warning",
        });
      });
  };

  const getDataGrapUser = () => {
    setLoadingUser(true);

    if (formUser.semana === 0) {
      swal({
        title: "¡Atención!",
        text: `Debe seleccionar la semana a consultar`,
        icon: "warning",
      });
      setLoadingUser(false);
      return;
    }

    if (formUser.fecha === "") {
      swal({
        title: "¡Atención!",
        text: `Debe seleccionar el día a consultar`,
        icon: "warning",
      });
      setLoadingUser(false);
      return;
    }

    const token = getVars("Token");
    const data = {
      semana: formUser.semana,
      fecha: formUser.fecha,
    };
    EjecutarApi(data, "vales/getPineoUser", token.token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.results && res.success) {
          let datos = [];
          let etiquetas = [];
          res.data.forEach((item) => {
            datos.push(item.Total);
            etiquetas.push(item.User);
          });
          setMyLabelsUser(etiquetas);
          setDatosUser(datos);
          setViewGrapUser(true);
        } else {
          swal({
            title: "¡Atención!",
            text: `${res.errors}`,
            icon: "info",
          });
        }
        setLoadingUser(false);
      })
      .catch((e) => {
        setLoadingUser(false);
        swal({
          title: "¡Atención!",
          text: `${e.message}`,
          icon: "warning",
        });
      });
  };

  const handleChangeSemana = (semana) => {
    if (semana !== null && semana !== "") {
      setForm({
        ...form,
        semana: semana,
        fecha: "",
      });
    } else {
      setForm({
        ...form,
        semana: 0,
      });
    }
  };

  const handleChangeSemanaUser = (semana) => {
    if (semana !== null && semana !== "") {
      setCatDaysUser([]);
      setFormUser({
        ...formUser,
        semana: semana,
        fecha: "",
      });
      getDaysUser(semana);
    } else {
      setFormUser({
        ...formUser,
        semana: 0,
      });
    }
  };

  const getDaysUser = (semana) => {
    setLoadingDaysUser(true);
    const data = {
      clave: semana,
    };
    EjecutarApi(data, `vales/getDaysForWeek`, token, "POST")
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.success && res.results) {
          setCatDaysUser(res.data);
          setLoadingDaysUser(false);
        } else {
          swal({
            title: "Ha ocurrido un error",
            text: res.errors,
            icon: "warning",
          });
        }
      })
      .catch((error) => {
        swal({
          title: "Ha ocurrido un error",
          text: error.errors,
          icon: "warning",
        });
        setLoadingDaysUser(false);
      });
  };

  const getDataGrapRegion = () => {
    setLoadingRemesa(true);
    if (formBarRegion.remesa === "" || !formBarRegion.remesa) {
      swal({
        title: "¡Atención!",
        text: `Debe seleccionar la remesa a consultar`,
        icon: "warning",
      });
      setLoadingRemesa(false);
      return;
    }

    if (formBarRegion.region === 0 && menu.ViewAll === 1) {
      swal({
        title: "¡Atención!",
        text: `Debe seleccionar la región a consultar`,
        icon: "warning",
      });
      setLoadingRemesa(false);
      return;
    }

    const token = getVars("Token");
    const data = {
      remesa: formBarRegion.remesa,
      region: menu.ViewAll === 1 ? formBarRegion.region : 0,
    };

    EjecutarApi(data, "vales/getPineoRegionMunicipio", token.token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.results && res.success) {
          let aprobados = [];
          let pineados = [];
          let etiquetas = [];

          setDatosR(res.data.region);
          setDatosM(res.data.municipio);

          res.data.region.forEach((item) => {
            aprobados.push(item.Aprobados);
            pineados.push(item.Pineados);
            etiquetas.push("R" + item.Region);
          });
          setDatosMetasRegion(aprobados);
          setDatosRegion(pineados);
          const avanceRegion = aprobados.map((item, index) => {
            return (
              (etiquetas[index] +=
                " <br><b> " + Math.trunc((pineados[index] / item) * 100)) +
              "% </b>"
            );
          });
          setMyLabelsMetasRegion(avanceRegion);

          pineados = [];
          aprobados = [];
          etiquetas = [];

          res.data.municipio.forEach((item) => {
            aprobados.push(item.Aprobados);
            pineados.push(item.Pineados);
            if (
              item.Municipio === "DOLORES HIDALGO CUNA DE LA INDEPENDENCIA NAL."
            ) {
              etiquetas.push("DOLORES HIDALGO");
            } else {
              etiquetas.push(item.Municipio);
            }
          });
          setDatosMunicipio(pineados);
          setDatosMetasMunicipio(aprobados);
          setMyLabelsMetasMunicipio(etiquetas);
          // setDataCveInterventor(res.data.cveInterventor);
          setViewGrapBarRegion(true);
          setViewGrapBarMunicipio(true);
        } else {
          swal({
            title: "¡Atención!",
            text: `${res.errors}`,
            icon: "info",
          });
        }
        setLoadingRemesa(false);
      })
      .catch((e) => {
        setLoadingRemesa(false);
        swal({
          title: "¡Atención!",
          text: `${e.message}`,
          icon: "warning",
        });
      });
  };

  const style = {
    table: {
      width: "100%",
      display: "table",
      borderSpacing: 0,
      borderCollapse: "separate",
      fontSize: "8px",
    },
    th: {
      top: 0,
      left: 0,
      zIndex: 2,
      position: "sticky",
      backgroundColor: "rgb(136,180,231)",
      textAlign: "center",
      color: "white",
    },
    td: {
      padding: 1,
    },
  };

  return (
    <>
      <div className="wrapper">
        <Sidebar {...props} bgColor="black" activeColor="info" />
        <div className="main-panel">
          <Navbar {...props} handleMiniClick={handleMiniClick} />
          <Notifications />
          <div className="content">
            <>
              <Row>
                <Col md="12">
                  <Card>
                    <CardHeader>
                      <Row>
                        <Col md="12" style={{ textAlign: "center" }}>
                          <CardTitle tag="h6">
                            Escaneo de Vales por Región - Municipio
                          </CardTitle>
                        </Col>
                      </Row>
                    </CardHeader>
                    <CardBody>
                      <Col sm={{ size: "10", offset: "1" }}>
                        <Card>
                          <Row>
                            <Col sm="4">
                              <FormGroup>
                                <Label>Remesa:</Label>
                                <span className="text-danger">*</span>
                                {loadingCatalogs && <Spinner size="sm" />}
                                <Input
                                  name="Remesa"
                                  type="select"
                                  disabled={loadingCatalogs || loadingRemesa}
                                  value={formBarRegion.remesa}
                                  onChange={(e) => {
                                    setViewGrapBarMunicipio(false);
                                    setViewGrapBarRegion(false);
                                    setDatosMetasMunicipio([]);
                                    setDatosMetasRegion([]);
                                    setMyLabelsMetasRegion([]);
                                    setMyLabelsMetasMunicipio([]);
                                    setFormBarRegion({
                                      ...formBarRegion,
                                      remesa: e.target.value,
                                    });
                                  }}
                                >
                                  <option value={""}></option>
                                  {remesas.map((element) => (
                                    <option
                                      key={element.value}
                                      value={element.value}
                                    >
                                      {element.value}
                                    </option>
                                  ))}
                                </Input>
                              </FormGroup>
                            </Col>
                            {menu.ViewAll > 0 && (
                              <Col sm="3">
                                <FormGroup>
                                  <Label>Región:</Label>
                                  <span className="text-danger">*</span>
                                  <Input
                                    name="dia"
                                    type="select"
                                    disabled={loadingRemesa}
                                    value={formBarRegion.region}
                                    onChange={(e) => {
                                      setViewGrapBarMunicipio(false);
                                      setViewGrapBarRegion(false);
                                      setDatosMetasMunicipio([]);
                                      setDatosMetasRegion([]);
                                      setMyLabelsMetasRegion([]);
                                      setMyLabelsMetasMunicipio([]);
                                      setFormBarRegion({
                                        ...formBarRegion,
                                        region: parseInt(e.target.value),
                                      });
                                    }}
                                  >
                                    <option value={0}></option>
                                    <option value={1}>Región I</option>
                                    <option value={2}>Región II</option>
                                    <option value={3}>Región III</option>
                                    <option value={4}>Región IV</option>
                                    <option value={5}>Región V</option>
                                    <option value={6}>Región VI</option>
                                    <option value={7}>Región VII</option>
                                  </Input>
                                </FormGroup>
                              </Col>
                            )}

                            <Col sm="2">
                              <Button
                                color="info"
                                size="sm"
                                disabled={loadingRemesa}
                                onClick={getDataGrapRegion}
                                style={{ marginTop: "2rem" }}
                              >
                                {viewGrapBarRegion ? "Actualizar" : "Generar"}
                              </Button>
                              {loadingRemesa && <Spinner />}
                            </Col>
                          </Row>
                        </Card>
                      </Col>
                      <Row>
                        <Col sm={{ size: "12" }}>
                          {viewGrapBarRegion && (
                            <BarGraphHorizontal
                              labels={myLabelsMetasRegion}
                              data={datosRegion}
                              dataMetas={datosMetasRegion}
                              title={"Vales por Region"}
                            ></BarGraphHorizontal>
                            // <BarGraph
                            //   myLabels={myLabelsRegion}
                            //   myLabelsMetas={myLabelsMetasRegion}
                            //   data={datosRegion}
                            //   dataMetas={datosMetasRegion}
                            //   title={title}
                            //   viewLabels={true}
                            // ></BarGraph>
                          )}
                        </Col>
                        <Col sm={{ size: "12" }}>
                          {viewGrapBarMunicipio && (
                            <BarGraphHg
                              labels={myLabelsMetasMunicipio}
                              data={datosMunicipio}
                              dataMetas={datosMetasMunicipio}
                              title={"Vales por Municipio"}
                            ></BarGraphHg>
                            // <BarGraph
                            //   myLabels={myLabelsMetasMunicipio}
                            //   myLabelsMetas={myLabelsMetasMunicipio}
                            //   data={datosMunicipio}
                            //   dataMetas={datosMetasMunicipio}
                            //   title={title}
                            //   viewLabels={true}
                            // ></BarGraph>
                          )}
                        </Col>
                        <Col sm="12">
                          <hr></hr>
                        </Col>
                        <Col sm="5">
                          {viewGrapBarRegion && (
                            <Table
                              responsive
                              bordered
                              hover
                              size="sm"
                              maxHeight={600}
                              style={{ fontSize: "11px" }}
                            >
                              <thead>
                                <tr>
                                  <th style={style.th}>Region</th>
                                  <th style={style.th}>Aprobados</th>
                                  <th style={style.th}>Pineados</th>
                                  <th style={style.th}>Pendientes</th>
                                </tr>
                              </thead>
                              <tbody>
                                {datosR.length > 0 &&
                                  datosR.map((item, index) => (
                                    <tr key={item.Region}>
                                      <td
                                        style={style.td}
                                        className="text-center"
                                      >
                                        {item.Region}
                                      </td>
                                      <td
                                        style={style.td}
                                        className="text-center"
                                      >
                                        {new Intl.NumberFormat("en-EN").format(
                                          item.Aprobados
                                        )}
                                      </td>
                                      <td
                                        style={style.td}
                                        className="text-center"
                                      >
                                        {new Intl.NumberFormat("en-EN").format(
                                          item.Pineados
                                        )}
                                      </td>
                                      <td
                                        style={style.td}
                                        className="text-center"
                                      >
                                        {new Intl.NumberFormat("en-EN").format(
                                          item.Aprobados - item.Pineados
                                        )}
                                      </td>
                                    </tr>
                                  ))}
                              </tbody>
                            </Table>
                          )}
                        </Col>
                        <Col sm="7">
                          {viewGrapBarRegion && (
                            <Table
                              responsive
                              bordered
                              hover
                              size="sm"
                              maxHeight={600}
                              style={{ fontSize: "11px" }}
                            >
                              <thead>
                                <tr>
                                  <th style={style.th}>Municipio</th>
                                  <th style={style.th}>Aprobados</th>
                                  <th style={style.th}>Pineados</th>
                                  <th style={style.th}>Pendientes</th>
                                </tr>
                              </thead>
                              <tbody>
                                {datosM.length > 0 &&
                                  datosM.map((item, index) => (
                                    <tr key={item.Municipio}>
                                      <td
                                        style={style.td}
                                        className="text-center"
                                      >
                                        {item.Municipio}
                                      </td>
                                      <td
                                        style={style.td}
                                        className="text-center"
                                      >
                                        {new Intl.NumberFormat("en-EN").format(
                                          item.Aprobados
                                        )}
                                      </td>
                                      <td
                                        style={style.td}
                                        className="text-center"
                                      >
                                        {new Intl.NumberFormat("en-EN").format(
                                          item.Pineados
                                        )}
                                      </td>
                                      <td
                                        style={style.td}
                                        className="text-center"
                                      >
                                        {new Intl.NumberFormat("en-EN").format(
                                          item.Aprobados - item.Pineados
                                        )}
                                      </td>
                                    </tr>
                                  ))}
                              </tbody>
                            </Table>
                          )}
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="12">
                  <Card>
                    <CardHeader>
                      <Row>
                        <Col md="12" style={{ textAlign: "center" }}>
                          <CardTitle tag="h6">
                            Escaneo de Vales por Semana/Día - Hora
                          </CardTitle>
                        </Col>
                      </Row>
                    </CardHeader>
                    <CardBody>
                      <Col sm={{ size: "10", offset: "1" }}>
                        <Card>
                          <Row>
                            <Col sm="4">
                              <FormGroup>
                                <Label>Semana:</Label>
                                <span className="text-danger">*</span>
                                {loadingCatalogs && <Spinner size="sm" />}
                                <Input
                                  name="Semana"
                                  type="select"
                                  disabled={loadingCatalogs || loadingWeek}
                                  value={form.semana}
                                  onChange={(e) =>
                                    handleChangeSemana(e.target.value)
                                  }
                                >
                                  <option value={""}></option>
                                  {semanas.map((element) => (
                                    <option
                                      key={element.value}
                                      value={element.value}
                                    >
                                      {element.label}
                                    </option>
                                  ))}
                                </Input>
                              </FormGroup>
                            </Col>
                            <Col sm="2">
                              <Button
                                color="info"
                                size="sm"
                                disabled={loadingWeek}
                                onClick={getDataGrap}
                                style={{ marginTop: "2rem" }}
                              >
                                {viewGrapHora ? "Actualizar" : "Generar"}
                              </Button>
                              {loadingWeek && <Spinner />}
                            </Col>
                          </Row>
                        </Card>
                      </Col>
                      <Col sm={{ size: "10", offset: "1" }}>
                        <>
                          {viewGrapHora && (
                            // <LineGraphHour
                            //   myLabels={myLabels}
                            //   data={datos}
                            //   title={title}
                            //   viewLabels={true}
                            // ></LineGraphHour>
                            <LineGraphHg data={datos2}></LineGraphHg>
                          )}
                        </>
                      </Col>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="12">
                  <Card>
                    <CardHeader>
                      <Row>
                        <Col md="12" style={{ textAlign: "center" }}>
                          <CardTitle tag="h6">
                            Escaneo de Vales por Día - Usuario
                          </CardTitle>
                        </Col>
                      </Row>
                    </CardHeader>
                    <CardBody>
                      <Col sm={{ size: "10", offset: "1" }}>
                        <Card>
                          <Row>
                            <Col sm="5">
                              <FormGroup>
                                <Label>Semana:</Label>
                                <span className="text-danger">*</span>
                                {loadingCatalogs && <Spinner size="sm" />}
                                <Input
                                  name="Semana"
                                  type="select"
                                  disabled={loadingCatalogs || loadingUser}
                                  value={formUser.semana}
                                  onChange={(e) =>
                                    handleChangeSemanaUser(e.target.value)
                                  }
                                >
                                  <option value={""}></option>
                                  {semanas.map((element) => (
                                    <option
                                      key={element.value}
                                      value={element.value}
                                    >
                                      {element.label}
                                    </option>
                                  ))}
                                </Input>
                              </FormGroup>
                            </Col>
                            <Col sm="5">
                              <FormGroup>
                                <Label>Día:</Label>
                                <span className="text-danger">*</span>
                                {loadingDaysUser && <Spinner size="sm" />}
                                <Input
                                  name="dia"
                                  type="select"
                                  disabled={
                                    catDaysUser.length === 0 || loadingUser
                                  }
                                  value={formUser.fecha}
                                  onChange={(e) =>
                                    setFormUser({
                                      ...formUser,
                                      fecha: e.target.value,
                                    })
                                  }
                                >
                                  <option value={""}></option>
                                  {catDaysUser.map((element) => (
                                    <option
                                      key={element.value}
                                      value={element.value}
                                    >
                                      {element.value}
                                    </option>
                                  ))}
                                </Input>
                              </FormGroup>
                            </Col>
                            <Col sm="2">
                              <Button
                                color="info"
                                size="sm"
                                disabled={loadingUser}
                                onClick={getDataGrapUser}
                                style={{ marginTop: "2rem" }}
                              >
                                {viewGrapUser ? "Actualizar" : "Generar"}
                              </Button>
                              {loadingUser && <Spinner />}
                            </Col>
                          </Row>
                        </Card>
                      </Col>
                      <Col sm={{ size: "10", offset: "1" }}>
                        {viewGrapUser && (
                          <BarGraphHorizontal
                            labels={myLabelsUser}
                            dataMetas={datosUser}
                            title={"Vales por Usuario"}
                            vielabel={false}
                          ></BarGraphHorizontal>
                        )}
                      </Col>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </>
          </div>
          <Footer fluid />
        </div>
      </div>
    </>
  );
};
export default EstadisticasVales;
