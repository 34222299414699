import React, { useEffect } from "react";

import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";

//Zincri imports
import SimpleMap from "../components/Maps/SimpleMap.jsx";
import Notifications from 'react-notify-toast';
import { Card, CardHeader, CardBody, CardTitle, Row, Col} from 'reactstrap';


const Mapas = (props) => {

    useEffect(() => {

    }, [])

    const handleMiniClick = () => {
        document.body.classList.toggle("sidebar-mini");
    };



    return (
        <>
            <div className="wrapper">
                <Sidebar {...props} bgColor="black" activeColor="info" />
                <div className="main-panel">
                    <Navbar {...props} handleMiniClick={handleMiniClick} />
                    <Notifications />
                    <div className="content">
                        <>
                            <Card>
                                <CardHeader>
                                    <Row>
                                        <Col md="6">
                                            <CardTitle tag="h4">Mapa de comercios</CardTitle>
                                        </Col>
                                    </Row>
                                </CardHeader>
                            </Card>

                            <Row>
                                <Col md="12">
                                    <Card>
                                        <CardBody>
                                            <SimpleMap>

                                            </SimpleMap>

                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </>
                    </div>
                </div>
            </div>
        </>);
};

export default Mapas;