import React, { useState, useEffect } from "react";
import { Button, Input, Form, Label } from "reactstrap";
import Select from "react-select";
import EjecutarApi from "../Consultas/Consultas";
import { notify } from "react-notify-toast";
import { getVars } from "../GlobalStorage";
import "./styles.css";

function PluginFilterPrevalidacion(props) {
  const { sendFiltro } = props;
  const [classes, setclasses] = useState("dropdown");
  const token = getVars("Token");

  const [form, setForm] = useState({
    CveInterventor: "",
    Pendientes: false,
    Expedientes: false,
    ExpedientesF: false,
    Aprobados: false,
    Region: "",
    Municipio: "",
  });
  const [MultiValueMuninicpio, setMultiValueMunicipio] = useState([]);
  const [MultiValueMuninicpio2, setMultiValueMunicipio2] = useState([]);
  const [MultiValueRegion, setMultiValueRegion] = useState([]);
  const [filterRegion, setfilterRegion] = useState([]);
  const [MultiValueRemesa, setMultiValueRemesa] = useState([]);
  const [MultiValueRemesa2, setMultiValueRemesa2] = useState([]);
  const handleClick = () => {
    if (classes === "dropdown") {
      setclasses("dropdown show");
    } else {
      setclasses("dropdown");
    }
  };

  useEffect(() => {
    EjecutarApi({}, "calentadoresSolares/getMunicipios", token.token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.results) {
          var region = [];
          setMultiValueMunicipio(
            res.data.map((municipio, i) => {
              if (region.indexOf(municipio["SubRegion"]) === -1) {
                region.push(municipio["SubRegion"]);
              }
              return {
                value: municipio["Id"],
                label: municipio["Nombre"],
              };
            })
          );
          var regio_n = region.sort((a, b) => a - b);
          setfilterRegion(
            regio_n.map((region_) => {
              return { value: region_, label: region_ };
            })
          );
        }
      })
      .catch((e) => {
        notify.show(`Error en la busqueda! ${e.message}`, "custom", 5000, {
          background: "#d61818",
          text: "#FFFFFF",
        });
      });
    let datoArticulador = {};
    datoArticulador = {
      page: 0,
      tipo: "and",
      pageSize: 1000,
      sorted: [{ id: "Nombre", desc: false }],
      filtered: [],
      ejercicio: 2023,
    };

    EjecutarApi(datoArticulador, "getRemesasVales", token.token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.results) {
          setMultiValueRemesa(
            res.data.map((data, i) => {
              return {
                value: data["RemesaSistema"],
                label: data["RemesaSistema"],
              };
            })
          );
        }
      })
      .catch((e) => {
        notify.show(`Error en la busqueda! ${e.message}`, "custom", 5000, {
          background: "#d61818",
          text: "#FFFFFF",
        });
      });
  }, []);

  const handleMultiRegion = (option) => {
    setMultiValueRegion(option);
  };

  const setFilter = (event) => {
    event.preventDefault();
    let region = [];

    if (MultiValueRegion) {
      if (MultiValueRegion.length > 0) {
        MultiValueRegion.map((r) => {
          region.push(r.value);
        });
      }
    }
    let filter = [];
    if (form.CveInterventor.length > 0)
      filter.push({ id: ".CveInterventor", value: form.CveInterventor });
    if (form.Pendientes) filter.push({ id: ".Validado", value: 0 });
    if (form.Expedientes) filter.push({ id: ".Expedientes", value: 1 });
    if (form.ExpedientesF) filter.push({ id: ".Expedientes", value: 0 });
    if (form.Aprobados) filter.push({ id: ".Validado", value: 1 });
    if (region.length > 0) filter.push({ id: "region", value: region });
    if (MultiValueMuninicpio2.length > 0)
      filter.push({ id: ".idMunicipio", value: MultiValueMuninicpio2 });
    if (MultiValueRemesa2.length > 0)
      filter.push({ id: ".Remesa", value: MultiValueRemesa2 });
    let datos_ = {};
    datos_ = {
      page: 0,
      tipo: "or",
      pageSize: 10,
      filtered: filter,
    };
    sendFiltro(datos_);
  };

  return (
    <div className="fixed-plugin">
      <Form onSubmit={setFilter}>
        <div className={classes}>
          <div onClick={handleClick}>
            <i className="fa fa-cog fa-2x" />
          </div>
          <ul className="dropdown-menu show ul">
            <li className="adjustments-line">CveInterventor:</li>
            <Input
              type="text"
              name="CveInterventor"
              id="CveInterventor"
              placeholder="CveInterventor"
              value={form.CveInterventor}
              maxLength={6}
              onChange={(e) =>
                setForm({
                  ...form,
                  CveInterventor: e.target.value.toUpperCase(),
                })
              }
              className="form-control"
            />
            <li className="adjustments-line">
              <Label>Remesa:</Label>
            </li>
            <li className="li">
              <Select
                className="SelectSize"
                name="remesa"
                placeholder="Remesa"
                options={MultiValueRemesa}
                onChange={(event, i) => {
                  if (event) {
                    setMultiValueRemesa2(
                      event.map((dato) => {
                        return "'" + dato.value + "'";
                      })
                    );
                  } else {
                    setMultiValueRemesa2({
                      MultiValueRemesa2: MultiValueRemesa2.map((dato) => {
                        if (i.removedValue.value !== dato) {
                          return "'" + dato.value + "'";
                        }
                      }),
                    });
                  }
                }}
                isMulti
              />
            </li>
            <li className="adjustments-line">
              <Label>Región:</Label>
            </li>
            <li className="li">
              <Select
                name="region"
                className="SelectSize"
                placeholder="Región"
                value={MultiValueRegion}
                options={filterRegion}
                onChange={handleMultiRegion}
                isMulti
              />
            </li>
            <li className="adjustments-line">
              <Label>Municipio:</Label>
            </li>
            <li className="li">
              <Select
                className="SelectSize"
                name="Municipio"
                placeholder="Municipio"
                options={MultiValueMuninicpio}
                onChange={(event, i) => {
                  if (event) {
                    setMultiValueMunicipio2(
                      event.map((dato) => {
                        return dato.value;
                      })
                    );
                  } else {
                    setMultiValueMunicipio2({
                      MultiValueMuninicpio2: MultiValueMuninicpio2.map(
                        (dato) => {
                          if (i.removedValue.value !== dato) {
                            return dato.value;
                          }
                        }
                      ),
                    });
                  }
                }}
                isMulti
              />
            </li>
            <li className="adjustments-line">
              <Label
                style={{
                  display: "block",
                  overflow: "hidden",
                  float: "left",
                }}
              >
                {"Sin Expedientes Cargados"}
              </Label>{" "}
              <Input
                style={{
                  verticalAlign: "middle",
                  float: "right",
                  marginLeft: "5pt",
                }}
                type="checkbox"
                onChange={(e) =>
                  setForm({
                    ...form,
                    ExpedientesF: !form.ExpedientesF,
                  })
                }
              />
            </li>
            <li className="adjustments-line">
              <Label
                style={{
                  display: "block",
                  overflow: "hidden",
                  float: "left",
                }}
              >
                {"Con Expedientes Cargados"}
              </Label>{" "}
              <Input
                style={{
                  verticalAlign: "middle",
                  float: "right",
                  marginLeft: "5pt",
                }}
                type="checkbox"
                onChange={(e) =>
                  setForm({
                    ...form,
                    Expedientes: !form.Expedientes,
                  })
                }
              />
            </li>
            <li className="adjustments-line">
              <Label
                style={{
                  display: "block",
                  overflow: "hidden",
                  float: "left",
                }}
              >
                {"Pendientes de Validar"}
              </Label>{" "}
              <Input
                style={{
                  verticalAlign: "middle",
                  float: "right",
                  marginLeft: "5pt",
                }}
                type="checkbox"
                onChange={(e) =>
                  setForm({
                    ...form,
                    Pendientes: !form.Pendientes,
                  })
                }
              />
            </li>
            <li className="adjustments-line">
              <Label
                style={{
                  display: "block",
                  overflow: "hidden",
                  float: "left",
                }}
              >
                {"Validados Completos"}
              </Label>{" "}
              <Input
                style={{
                  verticalAlign: "middle",
                  float: "right",
                  marginLeft: "5pt",
                }}
                type="checkbox"
                onChange={(e) =>
                  setForm({
                    ...form,
                    Aprobados: !form.Aprobados,
                  })
                }
              />
            </li>
            <li className="button-container">
              <span>
                <Button color="info" block className="btn-round" type="submit">
                  Filtrar
                </Button>
              </span>
            </li>

            <li className="li"></li>
          </ul>
        </div>
      </Form>
    </div>
  );
}
export default PluginFilterPrevalidacion;
