import React, { useEffect, useState } from "react";
// react component for creating dynamic tables

import ReactTable from "react-table";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  FormGroup,
  Form,
  Input,
  Label,
  Modal,
} from "reactstrap";
import styled from "styled-components";
import { ImpulseSpinner } from "react-spinners-kit";

import Notifications, { notify } from "react-notify-toast";

import ReactBSAlert from "react-bootstrap-sweetalert";

import EjecutarApi from "../Consultas/Consultas";
import { getVars, setVars } from "../GlobalStorage";
import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";
import { URL_GENERAL } from "../data/consts";
import Pagination from "../components/pagination";

// reactstrap components

import matchSorter from "match-sorter";
import ModalHeader from "reactstrap/lib/ModalHeader";
import ModalBody from "reactstrap/lib/ModalBody";
import ListadoSolicitudes from "../components/Grupos/ListadoSolicitudes";

const ReactSelect = styled(Select)`
  .Select-control {
    height: 26px;
    font-size: small;

    .Select-placeholder {
      line-height: 26px;
      font-size: small;
    }

    .Select-value {
      line-height: 26px;
    }

    .Select-input {
      height: 26px;
      font-size: small;
    }
  }
`;

function Grupos(props) {
  const [loading, setloading] = useState(false);
  const [Grupos, setGrupos] = useState([]);
  const [CatMunicipio, setCatMunicipio] = useState([]);
  const [CatRemesa, setCatRemesa] = useState([]);
  const [CatResponsable, setCatResponsable] = useState([]);
  const [totalGrupos, setTotalGrupos] = useState(0);
  const [data, setData] = useState([]);
  const [flagBuscaArticulador, setflagBuscaArticulador] = useState(false);
  const [Folio, setFolio] = useState("");
  const [vale, setVale] = useState({ id: null });
  const [message, setMessage] = useState(null);
  const [idArticulador, setidArticulador] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [grupoSeleccionado, setGrupoSeleccionado] = useState({});

  let history = useHistory();

  const token = getVars("Token");
  const datos = {
    page: 0,
    tipo: "and",
    pageSize: 1000,
    sorted: [],
    filtered: [],
  };
  const [_menu, _setmenu] = useState({});
  //const [,]=useEffect();
  //const [,]=useEffect();
  //const [,]=useEffect();
  //const [,]=useEffect();
  //const [,]=useEffect();
  //const [,]=useEffect();

  useEffect(() => {
    setearMenu("Estado");
    setloading(true);
    //Obtengo los catalogos
    EjecutarApi({}, "getCatGrupos", token.token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        console.log("=>Res Catálogos: ", res);
        setloading(false);
        setCatMunicipio(
          res.data.Municipios.map((item) => {
            return <option value={item}>{item}</option>;
          })
        );
        setCatRemesa(
          res.data.Remesas.map((item) => {
            return <option value={item}>{item}</option>;
          })
        );
        setCatResponsable(
          res.data.Responsables.map((item) => {
            return <option value={item}>{item}</option>;
          })
        );
        //setloadingGrupo(false);
      })
      .catch((e) => {
        console.log("=Errorget Catalogos: ", e);
        console.log("=Error [token]", token);

        setloading(false);
        notify.show(`Error al obtener Grupos: ${e.errors}`, "custom", 5000, {
          background: "#0857B6",
          fontSize: 14,
          text: "#FFFFFF",
        });

        //setloadingGrupo(false);
      });
  }, []);
  const handleMiniClick = () => {
    document.body.classList.toggle("sidebar-mini");
  };

  const setearMenu = (dependecia) => {
    if (dependecia === "Estado") {
      token.menu.forEach((prop, key) => {
        if (prop.Menu === "Grupos") {
          _setmenu({
            Ver: prop.Ver,
            Agregar: prop.Agregar,
            Editar: prop.Editar,
            Eliminar: prop.Eliminar,
            Seguimiento: prop.Seguimiento,
            Exportar: prop.Exportar,
            Imprimir: prop.Imprimir,
            ViewAll: prop.ViewAll,
            idTipoUser: token.user.idTipoUser.id,
          });
          //setRegiones({ regiones: prop.regiones });
        }
      });
    } else {
      var json = {};

      token.menu.forEach((prop, key) => {
        if (prop.Menu === "Grupos") {
          json = {
            Ver: prop.Ver,
            Agregar: prop.Agregar,
            Editar: prop.Editar,
            Eliminar: prop.Eliminar,
            Seguimiento: prop.Seguimiento,
            Exportar: prop.Exportar,
            Imprimir: prop.Imprimir,
            ViewAll: prop.ViewAll,
            idTipoUser: token.user.idTipoUser.id,
            regiones: token.regiones,
          };
        }
      });

      return json;
    }
  };

  const mini_tableArticulador = (
    <>
      <ReactBSAlert
        style={{ display: "block", marginTop: "-250pt" }}
        //title="Here's a message!"
        onConfirm={() => setflagBuscaArticulador(false)}
        onCancel={() => setflagBuscaArticulador(false)}
        confirmBtnBsStyle="info"
        size="sm"
      >
        <Card className="mb-5 text-muted">
          <CardBody className="mb-5 text-muted">
            <Form
              className="form-horizontal"
              id="TypeValidation"
              onSubmit={(e) => btnBuscar(e)}
            >
              <Row>
                <Label sm="2">Folio:</Label>
                <Col sm="4">
                  <FormGroup>
                    <Input
                      name="Folio"
                      type="text"
                      value={Folio}
                      onChange={(event) => setFolio(event.target.value)}
                    />
                  </FormGroup>
                </Col>
                <Col md="2">
                  <Button type="submit" color="primary" size="sm">
                    <span className="btn-label">
                      <i className="nc-icon nc-zoom-split" />
                    </span>
                  </Button>
                </Col>
              </Row>
              {loading && (
                <ImpulseSpinner color={"#1261ff"} size={30} loading={true} />
              )}
              {message !== null && (
                <div>
                  <b>{message}</b>
                </div>
              )}
              {vale.id && (
                <Row>
                  <table>
                    <tr>
                      <td style={{ textAlign: "left" }}>Remesa:</td>
                      <td style={{ textAlign: "left", fontSize: "12px" }}>
                        <b>{vale.Remesa}</b>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ textAlign: "left" }}>Municipio:</td>
                      <td style={{ textAlign: "left", fontSize: "12px" }}>
                        <b>{vale.Municipio}</b>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ textAlign: "left" }}>Articulador:</td>
                      <td style={{ textAlign: "left", fontSize: "12px" }}>
                        <b>{vale.Articulador}</b>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ textAlign: "left" }}>Capturo:</td>
                      <td style={{ textAlign: "left", fontSize: "12px" }}>
                        <b>{vale.Capturo}</b>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ textAlign: "left" }}>Hora:</td>
                      <td style={{ textAlign: "left", fontSize: "12px" }}>
                        <b>{vale.created_at}</b>
                      </td>
                    </tr>
                  </table>
                </Row>
              )}
            </Form>
          </CardBody>
        </Card>
      </ReactBSAlert>
    </>
  );

  const handleArticulador = () => {
    history.push("/gruposadd");
  };

  const handleDescargaExcel = (param) => {
    console.log("=>Exportar DescargaExcel: ", param);

    var url = new URL(
      URL_GENERAL +
        `getReporteNominaVales?idGrupo=${param.id}&token=${token.token}`
    );
    window.open(url, "_blank");
  };

  const handleDescargaPDF = (param) => {
    setloading(true);
    const grupo = {
      idGrupo: param.id,
    };

    EjecutarApi(grupo, "validarGrupo", token.token)
      .then((res) => {
        if (res.results) {
          var url = new URL(
            URL_GENERAL +
              `getReporteAcuseVales?idGrupo=${param.id}&token=${token.token}`
          );
          window.open(url, "_blank");
          setloading(false);
        } else {
          notify.show(
            `Aún no se asignan los vales de este grupo`,
            "custom",
            5000,
            {
              background: "#0857B6",
              fontSize: 14,
              text: "#FFFFFF",
            }
          );
          setloading(false);
        }
      })

      .catch((e) => {
        notify.show(
          `Aún no se asignan los vales de este grupo`,
          "custom",
          5000,
          {
            background: "#0857B6",
            fontSize: 14,
            text: "#FFFFFF",
          }
        );
        setloading(false);
      });
  };

  const handleDescargaSolicitud = (param) => {
    setloading(true);

    var url = new URL(
      URL_GENERAL +
        `getReporteSolicitudVales?idGrupo=${param.id}&token=${token.token}`
    );
    window.open(url, "_blank");
    setloading(false);
  };

  const btnBuscar = (e) => {
    e.preventDefault();
    console.log("=>Buscar: ", Folio);

    setMessage(null);

    setloading(true);

    const ParametrosVale = {
      Folio,
    };

    EjecutarApi(ParametrosVale, "getSearchFolio", token.token)
      .then((res) => {
        console.log("Vales->results:", res);
        setloading(false);
        if (res.results) {
          if (res.data) setVale(res.data);
          else setMessage("Sin resultados");
        } else {
          setVale({ id: null });
        }
      })
      .catch((e) => {
        setloading(false);
        console.log("=>Error: ", e);
      });
  };

  const handleOpenListado = (grupo) => {
    console.log(grupo);
    setGrupoSeleccionado(grupo);
    setIsModalOpen(true);
  };

  return (
    <div className="wrapper">
      <Sidebar {...props} bgColor="black" activeColor="info" />
      <div className="main-panel">
        <Navbar {...props} handleMiniClick={handleMiniClick} />
        <Notifications />
        <div className="content">
          <Modal
            scrollable
            isOpen={isModalOpen}
            centered
            fade={false}
            size="lg"
            toggle={() => setIsModalOpen(false)}
          >
            <ModalHeader toggle={() => setIsModalOpen(false)}>
              Recibo de entrega-recepción
            </ModalHeader>
            <ModalBody>
              <ListadoSolicitudes
                userOwned={grupoSeleccionado.UserOwned}
                totalSolicitudes={grupoSeleccionado.TotalAprobados}
                token={token.token}
              />
            </ModalBody>
          </Modal>
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <Row>
                    <Col md="8">
                      <CardTitle tag="h6">Listado de Grupos</CardTitle>
                    </Col>
                    <Col md="2">
                      {_menu.Agregar == 1 ? (
                        <>
                          <Button
                            color="primary"
                            size="sm"
                            onClick={() => {
                              handleArticulador();
                            }}
                          >
                            <span className="btn-label">
                              <i className="nc-icon nc-simple-add" />
                            </span>{" "}
                            Agregar
                          </Button>
                        </>
                      ) : (
                        <></>
                      )}
                    </Col>
                    <Col md="2">
                      <Button
                        color="primary"
                        size="sm"
                        onClick={() => setflagBuscaArticulador(true)}
                      >
                        <span className="btn-label">
                          <i className="nc-icon nc-zoom-split" />
                        </span>
                        Buscar Vale
                      </Button>
                      {flagBuscaArticulador ? mini_tableArticulador : <></>}
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Col sm="12">
                    <ReactTable
                      size="sm"
                      noDataText="¡No existen datos a mostrar!"
                      data={Grupos}
                      filterable
                      manual
                      columns={[
                        {
                          Header: "Remesa",
                          minWidth: 40,
                          id: "Remesa",
                          accessor: (d) => d.Remesa,
                          filterMethod: (filter, rows) => {
                            if (filter.value === "Cualquier") {
                              return true;
                            }
                            return rows[filter.id] === filter.value;
                          },
                          Filter: ({ filter, onChange }) => (
                            <select
                              onChange={(event) => onChange(event.target.value)}
                              style={{ width: "100%" }}
                              value={filter ? filter.value : "Cualquier"}
                            >
                              <option value="Cualquier">Cualquier</option>
                              {CatRemesa}
                            </select>
                          ),
                        },
                        {
                          Header: "Municipio",
                          minWidth: 50,
                          id: "Municipio",
                          accessor: (d) => d.Municipio,
                          filterMethod: (filter, rows) => {
                            if (filter.value === "Cualquier") {
                              return true;
                            }
                            return rows[filter.id] === filter.value;
                          },
                          Filter: ({ filter, onChange }) => (
                            <select
                              onChange={(event) => onChange(event.target.value)}
                              style={{ width: "100%" }}
                              value={filter ? filter.value : "Cualquier"}
                            >
                              <option value="Cualquier">Cualquier</option>
                              {CatMunicipio}
                            </select>
                          ),
                        },
                        {
                          Header: "Articulador",
                          minWidth: 70,
                          id: "FullName",
                          accessor: (d) => d.FullName,
                          filterMethod: (filter, rows) => {
                            if (filter.value === "Cualquier") {
                              return true;
                            }
                            return rows[filter.id] === filter.value;
                          },
                          Filter: ({ filter, onChange }) => (
                            <select
                              onChange={(event) => onChange(event.target.value)}
                              style={{ width: "100%" }}
                              value={filter ? filter.value : "Cualquier"}
                            >
                              <option value="Cualquier">Cualquier</option>
                              {CatResponsable}
                            </select>
                          ),
                        },
                        {
                          Header: "Total Solicitudes",
                          minWidth: 30,
                          id: "Solicitudes",
                          accessor: (d) => d.Solicitudes,
                          filterMethod: (filter, rows) =>
                            matchSorter(rows, filter.value, {
                              keys: ["Solicitudes"],
                            }),
                          filterAll: false,
                        },
                        {
                          headerClassName: "text-center",
                          Header: "Acciones",
                          minWidth: 70,
                          id: "Acciones",
                          accessor: (d) =>
                            _menu.Exportar == 1 ? d.action : "",
                          filterMethod: (filter, rows) =>
                            matchSorter(rows, filter.value, {
                              keys: ["Acciones"],
                            }),
                          filterAll: false,
                        },
                      ]}
                      className="-striped -highlight primary-pagination"
                      loading={loading}
                      showPagination={true}
                      showPaginationBottom={true}
                      showTotalPages={true}
                      showPageJump={false}
                      canNextFromData={true}
                      defaultPageSize={10}
                      total={totalGrupos}
                      pages={Math.floor(totalGrupos / 10)}
                      previousText="Anterior"
                      nextText="Siguiente"
                      ofText="de"
                      pageSizeOptions={[10, 20, 25, 50, 100]}
                      PaginationComponent={Pagination}
                      onFetchData={(stateComp, instance) => {
                        setloading(true);

                        var arrays = [];

                        var temFullName = "";
                        stateComp.filtered.map((Filtro) => {
                          if (Filtro.id === "FullName") {
                            temFullName = Filtro.value;
                          }
                          if (Filtro.id === "Remesa") {
                            arrays.push({
                              id: "vales_grupos.Remesa",
                              value: Filtro.value,
                            });
                          }
                          if (Filtro.id === "Municipio") {
                            arrays.push({
                              id: "et_cat_municipio.Nombre",
                              value: Filtro.value,
                            });
                          }
                          if (Filtro.id === "Solicitudes") {
                            arrays.push({
                              id: "vales_grupos.TotalAprobados",
                              value: Filtro.value,
                            });
                          }
                        });

                        //const token = getVars("Token");
                        var parametros = {};
                        let menu_local_storege = setearMenu("Storage");
                        console.log("=>Grid[_menu]: ", menu_local_storege);
                        if (menu_local_storege.ViewAll === 1) {
                          parametros = {
                            page: stateComp.page,
                            tipo: "and",
                            pageSize: stateComp.pageSize,
                            sorted: [
                              { id: "vales_grupos.created_at", desc: true },
                            ],
                            filtered: arrays,
                            NombreCompleto: temFullName,
                          };
                        } else {
                          parametros = {
                            page: stateComp.page,
                            tipo: "and",
                            pageSize: stateComp.pageSize,
                            sorted: [
                              { id: "vales_grupos.created_at", desc: true },
                            ],
                            filtered: arrays,
                            NombreCompleto: temFullName,
                            Propietario: token.user.id,
                          };
                        }
                        console.log(
                          "-->[Grupos->parametros->Grid]: ",
                          JSON.stringify(parametros)
                        );
                        console.log("[GridGrupos->Token]: ", token);
                        EjecutarApi(parametros, "getGrupos", token.token)
                          .then(function (res) {
                            return res;
                          })
                          .then((res) => {
                            setloading(false);
                            setTotalGrupos(res.total);
                            setGrupos(
                              res.data.map((prop, key) => {
                                return {
                                  Remesa: prop.Remesa,
                                  Municipio: prop.Municipio,
                                  FullName: prop.Articulador,
                                  Solicitudes: prop.TotalAprobados,
                                  action: (
                                    <>
                                      {prop.created_at < "2022-09-26" ||
                                      menu_local_storege.Eliminar === 1 ? (
                                        <Button
                                          size="sm"
                                          color="success"
                                          onClick={() =>
                                            handleDescargaExcel(prop)
                                          }
                                        >
                                          <span className="btn-label">
                                            <i className="fa fa-file-excel-o fa-1x" />
                                          </span>
                                        </Button>
                                      ) : (
                                        <></>
                                      )}

                                      <Button
                                        size="sm"
                                        color="danger"
                                        onClick={() =>
                                          handleDescargaSolicitud(prop)
                                        }
                                      >
                                        <span className="btn-label">
                                          Solicitud{" "}
                                          <i className="fa fa-file-pdf-o fa-1x" />
                                        </span>
                                      </Button>
                                      <Button
                                        size="sm"
                                        color="danger"
                                        onClick={() => handleDescargaPDF(prop)}
                                      >
                                        <span className="btn-label">
                                          Acuse{" "}
                                          <i className="fa fa-file-pdf-o fa-1x" />
                                        </span>
                                      </Button>
                                    </>
                                  ),
                                  data: prop,
                                };
                              })
                            );
                            //setloadingGrupo(false);
                          })
                          .catch((e) => {
                            console.log("=Error[getGrupos]: ", e);
                            console.log("=Error [token]", token);
                            console.log(
                              "=Error: parametros",
                              JSON.stringify(parametros)
                            );
                            setloading(false);
                            notify.show(
                              `Error al obtener Grupos: ${e.errors}`,
                              "custom",
                              5000,
                              {
                                background: "#0857B6",
                                fontSize: 14,
                                text: "#FFFFFF",
                              }
                            );

                            //setloadingGrupo(false);
                          });
                      }}
                    />
                  </Col>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}

export default Grupos;
