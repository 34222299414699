import React, { useEffect, useState } from "react";

import EjecutarApi from "../../Consultas/Consultas";
import { URL_GENERAL } from "../../data/consts";

import swal from "sweetalert";
import { Table } from "reactstrap";
import Spinner from "reactstrap/lib/Spinner";
import Button from "reactstrap/lib/Button";

const ListadoSolicitudes = ({ totalSolicitudes, token, userOwned }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [solicitudes, setSolicitudes] = useState([]);

  useEffect(() => {
    const data = {
      page: 0,
      tipo: "or",
      Propietario: 1,
      pageSize: totalSolicitudes,
      sorted: [
        {
          id: "vales.Paterno",
          desc: false,
        },
        {
          id: "vales.FechaSolicitud",
          desc: true,
        },
      ],
      Duplicados: 0,
      NombreCompleto: "",
      Ejercicio: 2021,
      NombreCreated: "",
      UserOwned: [userOwned],
      Remesa: "",
      idMunicipio: "",
      Colonia: "",
      Regiones: [1, 2, 3, 4, 5, 6, 7],
      Folio: "",
      filtered: [],
    };
    setIsLoading(true);
    EjecutarApi(data, "getValesV2", token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.success && res.results) {
          setSolicitudes(res.data);
        } else {
          swal({
            title: "Error",
            text: `Error al obtener solicitudes: ${res.message}`,
            icon: "warning",
          });
        }
        setIsLoading(false);
      })
      .catch((e) => {
        console.log("=Errorget solicitudes: ", e);

        setIsLoading(false);
        swal({
          title: "Error",
          text: `Error al obtener solicitudes: ${e.errors}`,
          icon: "warning",
        });
      });
  }, [token, userOwned, totalSolicitudes]);

  const handleDowloadFile = (idVale) => {
    var url = new URL(
      URL_GENERAL + `reciboEntregaRecepcion?idVale=${idVale}&token=${token}`
    );
    window.open(url, "_blank");
  };

  return (
    <>
      {isLoading && <Spinner />}
      {!isLoading && (
        <Table>
          <thead>
            <tr>
              <th>CURP</th>
              <th>Nombre</th>
              <th>Fecha de solicitud</th>
              <th>Municipio</th>
              <th>Recibo</th>
            </tr>
          </thead>
          <tbody>
            {solicitudes.map((solicitud, index) => (
              <tr key={index}>
                <td scope="row">{solicitud.CURP}</td>
                <td scope="row">
                  {solicitud.Nombre} {solicitud.Paterno} {solicitud.Materno}
                </td>
                <td scope="row">{solicitud.FechaSolicitud}</td>
                <td scope="row">{solicitud.idMunicipio.Municipio}</td>
                <td>
                  <Button
                    size="sm"
                    color="success"
                    onClick={() => handleDowloadFile(solicitud.id)}
                  >
                    <span className="btn-label">
                      <i className="fa fa-download" />
                    </span>
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </>
  );
};

export default ListadoSolicitudes;
