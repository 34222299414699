import Select from "react-select";
import ReactTable from "react-table";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { getVars } from "../GlobalStorage";
import Sidebar from "../components/Sidebar";
import { URL_GENERAL } from "../data/consts";
import Notifications from "react-notify-toast";
import EjecutarApi from "../Consultas/Consultas";
import Pagination from "../components/pagination";
import { CircleSpinner } from "react-spinners-kit";
import React, { useEffect, useState } from "react";
import { numberWithCommas } from "../utils/funciones";
import { Card, CardBody, FormGroup, Row, Col, Button } from "reactstrap";

const CalentadoresAvances2022 = (props) => {
  const token = getVars("Token");
  const [data, setData] = useState([]);
  const [dataFilter, setDataFilter] = useState([]);
  const [loading, setLoading] = useState(false);
  const [anio] = useState([{ value: "2022", label: "2022" }]);
  const [anios, setanios] = useState([{ value: "2022", label: "2022" }]);
  const [_menu, _setmenu] = useState({});
  const [state, setState] = useState({
    Region: { value: "", label: "Todos" },
    Regiones: [{ value: "", label: "Todos" }],
  });

  useEffect(() => {
    const reg = [{ value: "", label: "Todos" }];

    if (token.regiones) {
      const mRegiones = token.regiones.map((item) => {
        return { value: item, label: `Region ${item}` };
      });

      setState({ ...state, Regiones: reg.concat(mRegiones) });
    }
  }, []);

  useEffect(() => {
    if (state.Region.value) {
      setDataFilter(data.filter((item) => item.Region === state.Region.value));
    } else {
      setDataFilter(data);
    }
  }, [state]);

  useEffect(() => {
    setLoading(true);
    const token = getVars("Token");
    let menu_local_storage = setearMenu("Storage");
    var ParametrosVale = {};
    if (menu_local_storage.regiones.length > 0) {
      ParametrosVale = {
        Regiones: menu_local_storage.regiones,
        Anio: anios.value,
        idUser: token.user.id,
      };
    } else {
      ParametrosVale = {
        idUser: token.user.id,
      };
    }
    if (anios.value) {
      EjecutarApi(ParametrosVale, "getCalentadoresAvances", token.token)
        .then((res) => {
          if (res.results) {
            setLoading(false);
            const infodata = res.data.map((prop, key) => {
              return {
                Region: prop.Region,
                Municipio: prop.Municipio,
                Apoyos: prop.Apoyos,
                Entregados: prop.Entregados,
                Incidencias: prop.Incidencias,
                AprobadosComite: prop.AprobadosComite,
                ApoyosMenosApronadosComite: prop.ApoyosMenosApronadosComite,
                SolicitudesPorAprobar: prop.SolicitudesPorAprobar,
                data: prop,
              };
            });
            setData(infodata);
            setDataFilter(infodata);
            setLoading(false);
          }
        })
        .catch((e) => {
          setLoading(false);
        });
    }
  }, [anios]);

  const setearMenu = (dependecia) => {
    if (dependecia === "Estado") {
      token.menu.forEach((prop, key) => {
        if (prop.Menu === "Vales") {
          _setmenu({
            Ver: prop.Ver,
            Agregar: prop.Agregar,
            Editar: prop.Editar,
            Eliminar: prop.Eliminar,
            Seguimiento: prop.Seguimiento,
            Exportar: prop.Exportar,
            Imprimir: prop.Imprimir,
            ViewAll: prop.ViewAll,
            idTipoUser: token.user.idTipoUser.id,
          });
        }
      });
    } else {
      var json = {};

      token.menu.forEach((prop, key) => {
        if (prop.Menu === "Vales") {
          json = {
            Ver: prop.Ver,
            Agregar: prop.Agregar,
            Editar: prop.Editar,
            Eliminar: prop.Eliminar,
            Seguimiento: prop.Seguimiento,
            Exportar: prop.Exportar,
            Imprimir: prop.Imprimir,
            ViewAll: prop.ViewAll,
            idTipoUser: token.user.idTipoUser.id,
            regiones: token.regiones,
          };
        }
      });

      return json;
    }
  };

  const btnExportar = () => {
    var url = new URL(URL_GENERAL + `getReporteAvances?token=${token.token}`);
    window.open(url, "_blank");
  };

  const handleMiniClick = () => {
    document.body.classList.toggle("sidebar-mini");
  };

  return (
    <>
      <div className="wrapper">
        <Sidebar {...props} bgColor="black" activeColor="info" />
        <div className="main-panel">
          <Navbar {...props} handleMiniClick={handleMiniClick} />
          <Notifications />
          <div className="content">
            <Row>
              <Col sm="12" md="12" lg="12">
                <Row>
                  <Col sm="4">
                    <FormGroup>
                      Año:
                      <Select
                        className="react-select primary"
                        name="anios"
                        placeholder="Seleccione..."
                        options={anio}
                        value={anios}
                        onChange={(e) => setanios(e)}
                      />
                    </FormGroup>
                  </Col>
                  <Col sm="4">
                    <FormGroup>
                      Región:
                      <Select
                        className="SelectSize"
                        name="Region"
                        placeholder="Seleccione..."
                        options={state.Regiones}
                        value={state.Region}
                        onChange={(Region) => setState({ ...state, Region })}
                      />
                    </FormGroup>
                  </Col>
                  <Col sm="1"> </Col>
                  <Col sm="3">
                    {/* <Button color="success" size="sm" onClick={btnExportar}>
                      <span className="btn-label">
                        <i className="fa fa-file-excel-o fa-2x" />
                      </span>
                      Exportar
                    </Button> */}
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <Card>
                      <CardBody>
                        {loading && (
                          <CircleSpinner
                            className="text-center"
                            size={30}
                            color="#686769"
                            loading={loading}
                          />
                        )}
                        <ReactTable
                          size="sm"
                          data={dataFilter}
                          noDataText="No Existen Datos a Mostrar!"
                          //filerable
                          manual
                          columns={[
                            {
                              Header: "Region",
                              minWidth: 50,
                              id: "Region",
                              accessor: (d) => d.Region,
                              filterAll: false,
                              headerStyle: { fontSize: "11px" },
                            },

                            {
                              Header: "Municipio",
                              minWidth: 160,
                              id: "Municipio",
                              accessor: (d) => d.Municipio,
                              filterAll: false,
                              headerStyle: { fontSize: "11px" },
                            },
                            {
                              Header: "Meta",
                              minWidth: 70,
                              id: "Apoyos",
                              accessor: (d) => d.Apoyos,
                              filterAll: false,
                              headerStyle: { fontSize: "11px" },
                              Footer: (
                                <span>
                                  {numberWithCommas(
                                    dataFilter
                                      .map((d) => d.Apoyos)
                                      .reduce((a, b) => a + b, 0)
                                  )}
                                </span>
                              ),
                            },
                            {
                              Header: "Aprobados Comite",
                              minWidth: 100,
                              id: "AprobadosComite",
                              accessor: (d) => d.AprobadosComite,
                              filterAll: false,
                              headerStyle: { fontSize: "11px" },
                              Footer: (
                                <span>
                                  {numberWithCommas(
                                    dataFilter
                                      .map((d) => d.AprobadosComite)
                                      .reduce((a, b) => a + b, 0)
                                  )}
                                </span>
                              ),
                            },
                            {
                              Header: "Entregados",
                              minWidth: 100,
                              id: "Entregados",
                              accessor: (d) => d.Entregados,
                              filterAll: false,
                              headerStyle: { fontSize: "11px" },
                              Footer: (
                                <span>
                                  {numberWithCommas(
                                    dataFilter
                                      .map((d) => d.Entregados)
                                      .reduce((a, b) => a + b, 0)
                                  )}
                                </span>
                              ),
                            },
                            {
                              Header: "Incidencias",
                              minWidth: 100,
                              id: "Incidencias",
                              accessor: (d) => d.Incidencias,
                              filterAll: false,
                              headerStyle: { fontSize: "11px" },
                              Footer: (
                                <span>
                                  {numberWithCommas(
                                    dataFilter
                                      .map((d) => d.Incidencias)
                                      .reduce((a, b) => a + b, 0)
                                  )}
                                </span>
                              ),
                            },
                            {
                              Header: "Disponibles",
                              minWidth: 80,
                              id: "ApoyosMenosApronadosComite",
                              accessor: (d) => d.ApoyosMenosApronadosComite,
                              filterAll: false,
                              headerStyle: { fontSize: "11px" },
                              Footer: (
                                <span>
                                  {numberWithCommas(
                                    dataFilter
                                      .map((d) => d.ApoyosMenosApronadosComite)
                                      .reduce((a, b) => a + b, 0)
                                  )}
                                </span>
                              ),
                            },
                            {
                              Header: "Solicitudes",
                              minWidth: 80,
                              id: "SolicitudesPorAprobar",
                              accessor: (d) => d.SolicitudesPorAprobar,
                              filterAll: false,
                              headerStyle: { fontSize: "11px" },
                              Footer: (
                                <span>
                                  {numberWithCommas(
                                    dataFilter
                                      .map((d) => d.SolicitudesPorAprobar)
                                      .reduce((a, b) => a + b, 0)
                                  )}
                                </span>
                              ),
                            },
                          ]}
                          className="-striped -highlight primary-pagination"
                          loading={loading}
                          showPagination={true}
                          showPaginationBottom={true}
                          showTotalPages={true}
                          showPageJump={false}
                          canNextFromData={true}
                          defaultPageSize={dataFilter.length}
                          total={dataFilter.length}
                          pages={Math.floor(dataFilter.length / 10)}
                          previousText="Anterior"
                          nextText="Siguiente"
                          ofText="de"
                          pageSizeOptions={[dataFilter.length]}
                          PaginationComponent={Pagination}
                          onFetchData={(stateComp, instance) => {
                            setLoading(true);
                            var editar = false;
                            const token = getVars("Token");
                            let menu_local_storage = setearMenu("Storage");
                            var ParametrosVale = {};
                            if (menu_local_storage.regiones.length > 0) {
                              ParametrosVale = {
                                Regiones: menu_local_storage.regiones,
                                Anio: anios.value,
                                idUser: token.user.id,
                              };
                            } else {
                              ParametrosVale = {
                                idUser: token.user.id,
                              };
                            }

                            EjecutarApi(
                              ParametrosVale,
                              "getCalentadoresAvances",
                              token.token
                            )
                              .then((res) => {
                                if (res.results) {
                                  setLoading(false);
                                  const infodata = res.data.map((prop, key) => {
                                    return {
                                      Region: prop.Region,
                                      Municipio: prop.Municipio,
                                      Apoyos: prop.Apoyos,
                                      Entregados: prop.Entregados,
                                      Incidencias: prop.Incidencias,
                                      AprobadosComite: prop.AprobadosComite,
                                      ApoyosMenosApronadosComite:
                                        prop.ApoyosMenosApronadosComite,
                                      SolicitudesPorAprobar:
                                        prop.SolicitudesPorAprobar,
                                      data: prop,
                                    };
                                  });
                                  setData(infodata);
                                  setDataFilter(infodata);
                                }
                              })
                              .catch((e) => {
                                setLoading(false);
                              });
                          }}
                        />
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
          <Footer fluid />
        </div>
      </div>
    </>
  );
};
export default CalentadoresAvances2022;
