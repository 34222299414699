import React, { useState, useEffect } from "react";
// react plugin used to create switch buttons
import { CircleSpinner } from "react-spinners-kit";
import Select from "react-select";
import { Button, Input } from "reactstrap";
import "./styles.css";

import Notifications, { notify } from "react-notify-toast";
import EjecutarApi from "../Consultas/Consultas";
import { getVars } from "../GlobalStorage";

function PluginFilter(props) {
  const token = getVars("Token").token;

  const [classes, setclasses] = useState("dropdown");
  const [ClaveUnica, setClaveUnica] = useState("");
  const [Refrendo2021, setRefrendo2021] = useState("");
  const [RFC, setRFC] = useState("");
  const [Empresa, setEmpresa] = useState("");
  const [Contacto, setContacto] = useState([]);
  const [MultiValueRegion, setMultiValueRegion] = useState([]);
  const [MultiValueRegion2, setMultiValueRegion2] = useState([]);
  const [MultiValueMuninicpio, setMultiValueMunicipio] = useState([]);
  const [MultiValueMuninicpio2, setMultiValueMunicipio2] = useState([]);
  const [Colonia, setColonia] = useState("");
  const [Calle, setCalle] = useState("");
  const [NumExt, setNumExt] = useState("");
  const [NumInt, setNumInt] = useState("");
  const [CP, setCP] = useState("");
  const [MultiValueTipoNegocio, setMultiValueTipoNegocio] = useState([]);
  const [MultiValueTipoNegocio2, setMultiValueTipoNegocio2] = useState([]);

  const [Correo, setCorreo] = useState("");

  const [Celular, setCelular] = useState("");
  const [TelNegocio, setTelNegocio] = useState("");
  const [TelCasa, setTelCasa] = useState("");
  const [MultiValueEstatus, setMultiValueEstatus] = useState([]);

  const [filterEstatus, setfilterEstatus] = useState([
    { value: "1", label: "Por Verificar" },
    { value: "2", label: "Por Autorizar" },
    { value: "3", label: "Autorizado" },
    { value: "4", label: "Cancelado" },
  ]);
  const [filterRefrendo, setfilterRefrendo] = useState([
    { value: "", label: "Sin Refrendo" },
    { value: "2021", label: "2021" },
    { value: "2022", label: "2022" },
    { value: "2023", label: "2023" },
    { value: "2024", label: "2024" },
  ]);

  const [FechaInscripcion, setFechaInscripcion] = useState("");
  const [HorarioAtencion, setHorarioAtencion] = useState("");
  const [isLoading, setisLoading] = useState(false);
  const [Filtered, setFiltered] = useState([]);
  const datos = {
    page: 0,
    tipo: "and",
    pageSize: 500,
    sorted: [],
    filtered: [],
  };
  useEffect(() => {
    /**Region: regiones,
                                Colonia: data.Colonia,
                                Municipios: municipios ,
                                TipoNegocio: tipos */
    //Tipos
    EjecutarApi(datos, "getNegociosTipo", token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.results) {
          setMultiValueTipoNegocio(
            res.data.map((tipo, i) => {
              return {
                value: tipo["id"],
                label: tipo["Tipo"],
              };
            })
          );
        }
      })
      .catch((e) => {
        notify.show(`Error en la busqueda! ${e.message}`, "custom", 5000, {
          background: "#d61818",
          text: "#FFFFFF",
        });
      });

    //Municipios
    EjecutarApi(datos, "getMunicipiosET", token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.results) {
          var region = [];
          setMultiValueMunicipio(
            res.data.map((municipio, i) => {
              if (region.indexOf(municipio["SubRegion"]) === -1) {
                region.push(municipio["SubRegion"]);
              }
              return {
                value: municipio["Id"],
                label: municipio["Nombre"],
              };
            })
          );
          var regio_n = region.sort((a, b) => a - b);
          setMultiValueRegion(
            regio_n.map((region_) => {
              return { value: region_, label: region_ };
            })
          );
        }
      })
      .catch((e) => {
        notify.show(`Error en la busqueda! ${e.message}`, "custom", 5000, {
          background: "#d61818",
          text: "#FFFFFF",
        });
      });
  }, []);

  const handleMultiEstatus = (option) => {
    setMultiValueEstatus(option);
  };

  const setFilter = (event) => {
    event.preventDefault();
    const myFiltro = [];

    //console.log('=>Refrendo2021: ', Refrendo2021); return true;
    //if (ClaveUnica.length) myFiltro.push({ id: 'ClaveUnica', value: ClaveUnica });
    if (RFC.length) myFiltro.push({ id: "RFC", value: RFC });
    if (Refrendo2021.value)
      myFiltro.push({ id: "Refrendo2021", value: Refrendo2021.value });
    if (Empresa.length) myFiltro.push({ id: "NombreEmpresa", value: Empresa });
    if (Contacto.length) myFiltro.push({ id: "Contacto", value: Contacto });
    if (TelNegocio.length)
      myFiltro.push({ id: "TelNegocio", value: TelNegocio });
    if (TelCasa.length) myFiltro.push({ id: "TelCasa", value: TelCasa });
    if (Celular.length) myFiltro.push({ id: "Celular", value: Celular });
    if (Calle.length) myFiltro.push({ id: "Calle", value: Calle });
    if (NumExt.length) myFiltro.push({ id: "NumExt", value: NumExt });
    if (NumInt.length) myFiltro.push({ id: "NumInt", value: NumInt });
    if (Colonia.length) myFiltro.push({ id: "Colonia", value: Colonia });
    if (CP.length) myFiltro.push({ id: "CP", value: CP });
    if (FechaInscripcion.length > 1)
      myFiltro.push({ id: "FechaInscripcion", value: FechaInscripcion });
    if (HorarioAtencion.length)
      myFiltro.push({ id: "HorarioAtencion", value: HorarioAtencion });
    if (Correo.length) myFiltro.push({ id: "Correo", value: Correo });

    //console.log('MultiValueTipoNegocio',MultiValueTipoNegocio);

    //MultiValueRegion
    var x = 0;
    if (MultiValueRegion2.length > 0)
      myFiltro.push({ id: "Region", value: MultiValueRegion2 });
    //console.log('=>MultiValueRegion2: ', MultiValueRegion2);
    //for (x = 0; x < MultiValueRegion2.length; x++)

    //MultiValueMuninicpio
    if (MultiValueMuninicpio2.length > 0)
      myFiltro.push({ id: "idMunicipio", value: MultiValueMuninicpio2 });
    //for (x = 0; x < MultiValueMuninicpio2.length; x++)

    //TipoNegocio
    if (MultiValueTipoNegocio2.length > 0)
      myFiltro.push({ id: "idTipoNegocio", value: MultiValueTipoNegocio2 });
    //for (x = 0; x < MultiValueTipoNegocio2.length; x++)

    //MultiValueEstatus
    if (MultiValueEstatus) {
      if (MultiValueEstatus.length > 0) {
        const itemEstatus = [];
        MultiValueEstatus.forEach((item) => {
          itemEstatus.push(item.value);
        });

        console.log("=itemEstatus: ", itemEstatus);
        if (itemEstatus.length)
          myFiltro.push({ id: "Estatus", value: itemEstatus });
      }
    }

    console.log("=>Mis Filtros: ClaveUnica :->", ClaveUnica);
    setFiltered(myFiltro);

    props.filtrar({ filtered: myFiltro, claveunica: ClaveUnica });
  };

  const handleClick = () => {
    if (classes === "dropdown") {
      setclasses("dropdown show");
    } else {
      setclasses("dropdown");
    }
  };

  return (
    <div className="fixed-plugin">
      <form onSubmit={setFilter}>
        <div className={classes}>
          <div onClick={handleClick}>
            <i className="fa fa-cog fa-2x" />
          </div>
          <ul className="dropdown-menu show ul">
            <li className="header-title">Filtrar Resultados</li>
            <li className="li">
              <Select
                name="Refrendocomercio"
                className="SelectSize"
                placeholder="Refrendo"
                value={Refrendo2021}
                options={filterRefrendo}
                onChange={setRefrendo2021}
              />
            </li>

            <li className="adjustments-line">
              <Input
                placeholder="Folio Comercio"
                id="FolioComercio"
                value={ClaveUnica}
                type="text"
                onChange={(e) => setClaveUnica(e.target.value)}
              />
            </li>
            <li className="adjustments-line">
              <Input
                placeholder="RFC"
                id="RFC"
                value={RFC}
                type="text"
                onChange={(e) => setRFC(e.target.value)}
              />
            </li>
            <li className="adjustments-line">
              <Input
                placeholder="Nombre Comercio"
                id="Empresa"
                value={Empresa}
                type="text"
                onChange={(e) => setEmpresa(e.target.value)}
              />
            </li>
            <li className="adjustments-line">
              <Input
                placeholder="Contacto"
                id="Contacto"
                value={Contacto}
                type="text"
                onChange={(e) => setContacto(e.target.value)}
              />
            </li>
            <li className="adjustments-line">
              <Input
                placeholder="Correo"
                id="Correo"
                value={Correo}
                type="text"
                onChange={(e) => setCorreo(e.target.value)}
              />
            </li>
            <li className="adjustments-line">
              <Input
                placeholder="Calle"
                id="Calle"
                value={Calle}
                type="text"
                onChange={(e) => setCalle(e.target.value)}
              />
            </li>

            <li className="li">
              <Input
                placeholder="Horario Atención"
                id="Colonia"
                value={Colonia}
                type="text"
                onChange={(e) => setColonia(e.target.value)}
              />
            </li>
            <li className="adjustments-line">
              <Input
                placeholder="Número Exterior"
                id="NumExt"
                value={NumExt}
                type="text"
                onChange={(e) => setNumExt(e.target.value)}
              />
            </li>
            <li className="adjustments-line">
              <Input
                placeholder="Número Interior"
                id="NumInt"
                value={NumInt}
                type="text"
                onChange={(e) => setNumInt(e.target.value)}
              />
            </li>
            <li className="adjustments-line">
              <Input
                placeholder="Código Postal"
                id="CP"
                value={CP}
                type="text"
                onChange={(e) => setCP(e.target.value)}
              />
            </li>
            <li className="adjustments-line">
              <Input
                placeholder="Celular"
                id="Celular"
                value={Celular}
                type="text"
                onChange={(e) => setCelular(e.target.value)}
              />
            </li>
            <li className="adjustments-line">
              <Input
                placeholder="Teléfono Negocio"
                id="TelNegocio"
                value={TelNegocio}
                type="text"
                onChange={(e) => setTelNegocio(e.target.value)}
              />
            </li>
            <li className="adjustments-line">
              <Input
                placeholder="Teléfono Casa"
                id="TelCasa"
                value={TelCasa}
                type="text"
                onChange={(e) => setTelCasa(e.target.value)}
              />
            </li>
            <li className="adjustments-line">
              <Input
                placeholder="Horario Atención"
                id="HorarioAtencion"
                value={HorarioAtencion}
                type="text"
                onChange={(e) => setHorarioAtencion(e.target.value)}
              />
            </li>
            <li className="li">
              <Select
                className="SelectSize"
                name="Region"
                placeholder="Región"
                //value={MultiValueRegion}
                options={MultiValueRegion}
                onChange={(event, i) => {
                  if (event) {
                    setMultiValueRegion2(
                      event.map((dato) => {
                        return dato.value;
                      })
                    );
                  } else {
                    setMultiValueRegion2({
                      MultiValueRegion2: MultiValueRegion2.map((dato) => {
                        if (i.removedValue.value !== dato) {
                          return dato.value;
                        }
                      }),
                    });
                  }
                }}
                isMulti
              />
            </li>
            <li className="adjustments-line" />
            <li className="li">
              <Select
                className="SelectSize"
                name="Municipio"
                placeholder="Municipio"
                //value={MultiValueMuninicpio}
                options={MultiValueMuninicpio}
                onChange={(event, i) => {
                  if (event) {
                    setMultiValueMunicipio2(
                      event.map((dato) => {
                        return dato.value;
                      })
                    );
                  } else {
                    setMultiValueMunicipio2({
                      MultiValueMuninicpio2: MultiValueMuninicpio2.map(
                        (dato) => {
                          if (i.removedValue.value !== dato) {
                            return dato.value;
                          }
                        }
                      ),
                    });
                  }
                }}
                isMulti
              />
            </li>

            <li className="adjustments-line" />
            <li className="li">
              <Select
                name="Tipo Negocio"
                className="SelectSize"
                placeholder="Tipo Negocio"
                //value={MultiValueTipoNegocio}
                options={MultiValueTipoNegocio}
                onChange={(event, i) => {
                  console.log("llegaaqui");
                  if (event) {
                    console.log("llegaaqui");
                    setMultiValueTipoNegocio2(
                      event.map((dato) => {
                        return dato.value;
                      })
                    );
                  } else {
                    setMultiValueTipoNegocio2({
                      MultiValueTipoNegocio2: MultiValueTipoNegocio2.map(
                        (dato) => {
                          if (i.removedValue.value !== dato) {
                            return dato.value;
                          }
                        }
                      ),
                    });
                  }
                }}
                isMulti
              />
            </li>
            <li className="adjustments-line" />
            <li className="li">
              <Select
                name="Estatus"
                className="SelectSize"
                placeholder="Estatus"
                value={MultiValueEstatus}
                options={filterEstatus}
                onChange={handleMultiEstatus}
                isMulti
              />
            </li>
            <li className="adjustments-line" />

            <li className="header-title">Fecha Inscripción</li>
            <input
              type="date"
              name="FechaInscripcion"
              id="FechaInscripcion"
              placeholder="date placeholder"
              value={FechaInscripcion}
              onChange={(e) => setFechaInscripcion(e.target.value)}
              className="form-control"
            />

            <li className="adjustments-line" />
            <li className="button-container">
              <span>
                {!isLoading && (
                  <Button
                    color="info"
                    block
                    className="btn-round"
                    type="submit"
                  >
                    Filtrar
                  </Button>
                )}
                {isLoading && (
                  <CircleSpinner
                    className="text-center"
                    size={30}
                    color="#686769"
                    loading={isLoading}
                  />
                )}
              </span>
            </li>
          </ul>
        </div>
      </form>
    </div>
  );
}
export default PluginFilter;
