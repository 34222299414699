import React from "react";
import Logo from "../../assets/img/logo-gto.png";

const PublicNavBar = (props) => {
    return (
        <header>
            <div
                id="top-bar-sticky-wrapper"
                className="fixed-top gto-2022 sticky-bar"
            >
                <div className="container">
                    <ul className="nav">
                        <li className="nav-item hidden-md-down">
                            <a
                                className="nav-link active"
                                href="/"
                                rel="noopener noreferrer"
                            >
                                <i className="fa fa-phone mr-1 align-middle"></i>
                                <small className="align-middle">(473) 735-36-26 al 33</small>
                            </a>
                        </li>
                        <li className="nav-item ml-auto justify-content-end">
                            <span className="nav-link">
                                <a
                                    href="https://www.facebook.com/pages/SedeshuGto/457581867618996"
                                    target="_blank"
                                    rel="opener noreferrer"
                                >
                                    <i className="fa fa-facebook fa-1x px-2 align-middle"></i>
                                </a>
                                <a
                                    href="http://twitter.com/SedeshuGto"
                                    target="_blank"
                                    rel="opener noreferrer"
                                >
                                    <i className="fa fa-twitter fa-1x px-2 align-middle"></i>
                                </a>
                                <a
                                    href="https://www.instagram.com/sedeshugto"
                                    target="_blank"
                                    rel="opener noreferrer"
                                >
                                    <i className="fa fa-instagram fa-1x px-2 align-middle"></i>
                                </a>
                                <a
                                    href="http://www.youtube.com/user/SedeshuGto"
                                    target="_blank"
                                    rel="opener noreferrer"
                                >
                                    <i className="fa fa-youtube-play fa-1x px-2 align-middle"></i>
                                </a>
                            </span>
                        </li>
                    </ul>
                </div>
            </div>
            <div id="nav">
                <nav
                    id="nav-top-second"
                    component="nav"
                    className="navbar navbar-toggleable-md navbar-dark scrolling-navbar"
                    style={{ boxShadow: "none", marginTop: 30 }}
                >
                    <div className="container">
                        <a className="navbar-brand p-0 m-0" href="/">
                            <img
                                src={Logo}
                                className="d-inline-block align-top wow fadeInLeft img-fluid"
                                data-wow-delay="0.6s"
                                alt="Impulso"
                                style={{ maxWidth: "98%", borderRadius: "20px!important", padding: 25 }}
                            />
                        </a>
                        <div className="collapse navbar-collapse" id="navbarNav">
                            <ul className="navbar-nav ml-auto"></ul>
                        </div>
                    </div>
                </nav>
            </div>

        </header>
    );
};

export default PublicNavBar;
