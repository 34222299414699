import React, { useEffect, useState } from 'react';
import {
    Button,
    Label,
    FormGroup,
    Form,
    Input,
    Row,
    Col,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Card,
    CardBody, CardTitle
} from "reactstrap";

import Select from "react-select";
import swal from 'sweetalert';
import EjecutarApi from '../Consultas/Consultas';
import { RotateSpinner, ImpulseSpinner } from 'react-spinners-kit';
import Notifications, { notify } from 'react-notify-toast';
import DateTimeComponent from "./DateTimeComponent";
import ReactDatetime from "react-datetime";
import moment, { now } from 'moment';
import { getVars } from '../GlobalStorage';
import ReactTable from 'react-table';
import ReactBSAlert from "react-bootstrap-sweetalert";

import catOcupacion from '../data/catOcupacion.json';

import {curpValida} from '../utils/funciones';

function ModalRecepcionVale({ show, item, onHide }) {

  const token = getVars("Token");
  const [flagPDF, setFlagPDF] = useState(false);
  const [loading, setloading] = useState(false);


  const [otraColonia, setOtraColonia] = useState("");
  const [municipios, setMunicipios] = useState([]);
  const [localidades, setLocalidades] = useState([]);
  const [nomMunicipio, setNomMunicipio] = useState("");
  const [flagEscogerColonia, setFlagEscogerColonia] = useState(false);
  const [colonias, setColonias] = useState([]);
  const [flag, setFlag] = useState(false);
  const [flagSet, setFlagSet] = useState(false);
  const [msgErrorValidacion, setmsgErrorValidacion] = useState("");
  const [flagMsgErrorValidacion, setFlagMsgErrorValidacion] = useState(false);
  const [ban, setban] = useState(false);

  const [data, setData] = useState([]);
  const [totalColonias, setTotalColonias] = useState(0);

  const [vale, setVale] = useState({
    FechaSolicitud: "",
    ClaveUnica: '',
    id: null,
    CURP: "",
    Nombre: "",
    Ocupacion: {value: '', label: ''},
    OcupacionOtro: '',
    Paterno: "",
    Materno: "",
    Sexo: "",
    FechaNacimiento: "",
    Calle: "",
    NumInt: "",
    NumExt: "",
    Colonia: "",
    CP: "",
    idMunicipio: {value: null, label:''},
    idLocalidad:  {value: null, label:''},
    TelFijo: "",
    TelCelular: "",
    TelRecado: "",
    Compania: {value: null, label:''},
    CorreoElectronico: "",
    idStatus: 1,
    IngresoPercibido: 0,
    OtrosIngresos: 0,
    NumeroPersonas: 0
  });

  const Spinenerstyle = {
    height: "50pt",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const optcompania = [
    { value: "0", label: "Seleccionar una Compañía Telefónica" },
    { value: "Telcel", label: "Telcel" },
    { value: "Megacable", label: "Megacable" },
    { value: "Iusacell", label: "Iusacell" },
    { value: "Movistar", label: "Movistar" },
    { value: "at&t", label: "at&t" },
    { value: "Unefon", label: "Unefon" },
  ];


  useEffect(() => {

    if(item)
    {
      setVale({
        FechaSolicitud: item.FechaSolicitud,
        ClaveUnica: item.ClaveUnica,
        id: item.id,
        CURP: item.CURP,
        Nombre: item.Nombre,
        Paterno: item.Paterno,
        Materno: item.Materno,
        Ocupacion: {value: item.OcupacionOtro === 1 ? 'Otro': item.Ocupacion, label: item.OcupacionOtro === 1 ? 'Otro': item.Ocupacion},
        OcupacionOtro: item.OcupacionOtro === 1 ? item.Ocupacion:'',
        Sexo: item.Sexo,
        FechaNacimiento: item.FechaNacimiento,
        Calle: item.Calle,
        NumInt: item.NumInt,
        NumExt: item.NumExt,
        Colonia: item.Colonia,
        CP: item.CP,
        idMunicipio: {value: item.idMunicipio.id, label: item.idMunicipio.Municipio},
        idLocalidad: {value: item.idLocalidad.id, label: item.idLocalidad.Nombre},
        TelFijo: item.TelFijo,
        TelCelular: item.TelCelular,
        TelRecado: item.TelRecado,
        Compania: {value: item.Compania, label: item.Compania},
        CorreoElectronico: item.CorreoElectronico,
        IngresoPercibido: item.IngresoPercibido,
        OtrosIngresos: item.OtrosIngresos,
        NumeroPersonas: item.NumeroPersonas
      })
      
    }
    if(item){
      console.log("TI: ", item);
      municipioInit(item.idMunicipio.id);
    }
  }, [item])

  const municipioInit = (mun) => {
    const datos_localidades = {
      page: 0,
      tipo: "and",
      pageSize: 1000,
      sorted: [],
      filtered: [
        {
          id: "IdMunicipio",
          value: mun,
        },
      ],
    };

    EjecutarApi(datos_localidades, "getLocalidadET", token.token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.results) {
          //setData(res.data);

          //setVale({...vale, Colonia: '', idLocalidad: {value: res.data[0].Id, label: res.data[0].Nombre}})
          setLocalidades(
            res.data.map((status_) => {
              return {
                value: status_["Id"],
                label: status_["Nombre"],
              };
            })
          );
        }
        else
        {
          //setVale({...vale, Colonia: '', idLocalidad: {value: null, label: ''}})
        }
      })
      .catch((e) => {
        notify.show(`Error en la busqueda! ${e.message}`, "custom", 5000, {
          background: "#d61818",
          text: "#FFFFFF",
        });
      });
  }
  
  useEffect(() => {
    EjecutarApi({
      page: 0,
      tipo: "and",
      pageSize: 1000,
      sorted: [],
      filtered: [],
    }, "getMunicipiosET", token.token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.results) {
          setMunicipios(
            res.data.map((municipio) => {
              return {
                value: municipio["Id"],
                label: municipio["Nombre"],
              };
            })
          );
        }
      })
      .catch((e) => {
        notify.show(`Error en la busqueda! ${e.message}`, "custom", 5000, {
          background: "#d61818",
          text: "#FFFFFF",
        });
      });

    //ObteniendoArticuladores
    var datoArt = {
      page: 0,
      tipo: "and",
      pageSize: 400,
      sorted: [],
      filtered: [{ id: "idTipoUser", value: 9 }],
    };
    //document.body.classList.toggle('sidebar-mini')
    //document.body(("selectSexo" ) => ({ value: vale.Sexo }));
    //setItems(body.results.map(({ name }) => ({ label: name, value: name })));
  }, []);


  const btnGuardarDatos = () => {
    setFlagSet(true);
    

    setban(false);
    
    var updateVales = {
      id: vale.id,
      FechaSolicitud: vale.FechaSolicitud,
      Ocupacion: vale.Ocupacion.value === "Otro" ? vale.OcupacionOtro: vale.Ocupacion.value,
      OcupacionOtro: vale.Ocupacion.value === "Otro" ? 1:0,
      CURP: vale.CURP,
      Nombre: vale.Nombre,
      Paterno: vale.Paterno,
      Materno: vale.Materno,
      Sexo: vale.Sexo,
      FechaNacimiento: vale.FechaNacimiento,
      Calle: vale.Calle,
      NumInt: vale.NumInt,
      NumExt: vale.NumExt,
      CP: vale.CP,
      Bloqueado: 1,
      BloqueadoDate: moment().format("YYYY-MM-DD HH:mm:ss"),
      BloqueadoUser: token.user.id,
      isDocumentacionEntrega: 1,
      FechaDocumentacion: moment().format("YYYY-MM-DD HH:mm:ss"),
      idUserDocumentacion: token.user.id,
      idMunicipio: vale.idMunicipio.id,
      idLocalidad: vale.idLocalidad.id,
      TelFijo: vale.TelFijo,
      TelCelular: vale.TelCelular,
      TelRecados: vale.TelRecados,
      Compania: vale.Compania.value,
      IngresoPercibido: vale.IngresoPercibido,
      OtrosIngresos: vale.OtrosIngresos,
      NumeroPersonas: vale.NumeroPersonas,
      CorreoElectronico: vale.CorreoElectronico,
      Colonia:
        vale.Colonia !== "" && vale.Colonia !== "otro"
          ? vale.Colonia
          : otraColonia
    };
   

    setFlagSet(true);
    console.log("FechaSolicitud-->validacion:", vale.FechaSolicitud);
    if (vale.FechaSolicitud.length < 1) {
      setFlagSet(false);
      setmsgErrorValidacion("La fecha de solicitud es requerida");
      setFlagMsgErrorValidacion(true);
      /*notify.show("Error: La fecha de solicitud es requerida", "custom", 5000, {
        background: "#0857B6",
        fontSize: 14,
        text: "#FFFFFF",
      });*/
      return true;
    }
    if (vale.CURP.length === 0) {
      if (!curpValida(vale.CURP)) {
        setFlagSet(false);
        setmsgErrorValidacion("La CURP del cobrador es requerida y válida!");
        setFlagMsgErrorValidacion(true);

        return true;
      }
    }
    if (vale.Nombre.length < 1) {
      setFlagSet(false);
      setmsgErrorValidacion("Nombre es requerido!");
      setFlagMsgErrorValidacion(true);
      /*notify.show("Error: Nombre es requerido", "custom", 5000, {
        background: "#0857B6",
        fontSize: 14,
        text: "#FFFFFF",
      });*/
      return true;
    }
    if (vale.Paterno.length < 1) {
      setFlagSet(false);
      setmsgErrorValidacion("Apellido Paterno es requerido!");
      setFlagMsgErrorValidacion(true);
      /*notify.show("Error: Nombre es requerido", "custom", 5000, {
        background: "#0857B6",
        fontSize: 14,
        text: "#FFFFFF",
      });*/
      return true;
    }

    if (vale.FechaNacimiento.length < 3) {
      setFlagSet(false);
      setmsgErrorValidacion("Fecha de Nacimiento es requerida!");
      setFlagMsgErrorValidacion(true);

      return true;
    }

    if (vale.Ocupacion.value ==="Otro") {
      if(vale.Ocupacion.value.length<3)
      {
        setFlagSet(false);
        notify.show("Error: La fecha de solicitud es requerida", "custom", 5000, {
          background: "#0857B6",
          fontSize: 14,
          text: "#FFFFFF",
        });
        return true;
      }
    }else
    {
      if(vale.Ocupacion.value.length<3)
      {
        setFlagSet(false);
        notify.show("Error: La fecha de solicitud es requerida", "custom", 5000, {
          background: "#0857B6",
          fontSize: 14,
          text: "#FFFFFF",
        });
        return true;
      }
    }

    if (vale.idMunicipio.length < 1) {
      setFlagSet(false);
      setmsgErrorValidacion("Municipio es requerido!");
      setFlagMsgErrorValidacion(true);

      return true;
    }
    if (vale.Calle.length < 1) {
      setFlagSet(false);
      setmsgErrorValidacion("Calle es requerido!");
      setFlagMsgErrorValidacion(true);

      return true;
    }
    if (vale.NumExt.length < 1) {
      setFlagSet(false);
      setmsgErrorValidacion("Número Exterior es requerido!");
      setFlagMsgErrorValidacion(true);

      return true;
    }
    if (vale.Colonia.length < 1) {
      setFlagSet(false);
      setmsgErrorValidacion("Colonia es requerido!");
      setFlagMsgErrorValidacion(true);

      return true;
    }
    if (vale.idLocalidad.length < 1) {
      setFlagSet(false);
      setmsgErrorValidacion("Localidad es requerido!");
      setFlagMsgErrorValidacion(true);

      return true;
    }

    if (vale.CP.length !== 5) {
      setFlagSet(false);
      setmsgErrorValidacion(
        "Codigo Postal es requerido y debe tener 5 caracteres!"
      );
      setFlagMsgErrorValidacion(true);

      return true;
    }
    if(vale.TelCelular)
    {
      if(vale.TelCelular.length !== 0){
        if(vale.TelCelular){
          if (
            (vale.TelCelular.length > 0 && vale.TelCelular.length < 10) ||
            vale.TelCelular.length > 10 ||
            vale.TelCelular.length === 0
          ) {
            setFlagSet(false);
            setmsgErrorValidacion("El teléfono celular debe ser de 10 digitos!");
            setFlagMsgErrorValidacion(true);
  
            return true;
          }
        }else
        { 
            setFlagSet(false);
            setmsgErrorValidacion("El teléfono celular debe ser de 10 digitos!");
            setFlagMsgErrorValidacion(true);
    
              return true;
        }
      }
    }
    

    if(vale.Compania.length !==0){
      if (vale.Compania.length < 3) {
        setFlagSet(false);
        setmsgErrorValidacion("La compañía de celular no es requerida!");
        setFlagMsgErrorValidacion(true);
  
        return true;
      }
    }
    

    if (vale.NumeroPersonas < 1) {
      setFlagSet(false);
      setmsgErrorValidacion("Numero de personas dependientes del Ingreso Mensual es requerido!");
      setFlagMsgErrorValidacion(true);

      return true;
    }

    console.log("=>updateVale:", JSON.stringify(updateVales));
    console.log("token:", token.token);
   

    EjecutarApi(updateVales, "updateRecepcionDocumento", token.token)
      .then(function (res) {
        console.log("=>>updateRS", res);

        if (res.results) {
            setFlagSet(false);
            // handleonHide();
            swal({
              title: "Registro Éxitoso!",
              text: 'Recepción exitosa!',
              icon: "success",
            });
            onHide(true);
            return res;
        }else
        {
          setFlagSet(false);
          notify.show(`Error en registro de vales! ${res.message}`, "custom", 5000, {
            background: "#d61818",
            text: "#FFFFFF",
            fontSize: 14,
          });
        }
      })
      .catch((e) => {
        console.log("=>>errrorrrrrrrrr", e);
        setFlagSet(false);

        swal({
          title: "Error vales",
          text: e.message,
          icon: "warning",
        });

        
        notify.show(`Error en registro de vales! ${e.message}`, "custom", 5000, {
          background: "#d61818",
          text: "#FFFFFF",
          fontSize: 14,
        });
      });

    //}
    setFlagSet(false);
  }

  const mini_table = (
    <>
      <ReactBSAlert
        className="fontSize: 8px"
        style={{ display: "block", marginTop: "-250pt" }}
        title="Colonias!"
        onConfirm={() => setFlagEscogerColonia(false)}
        onCancel={() => setFlagEscogerColonia(false)}
        confirmBtnBsStyle="info"
      >
        <Card>
          <CardBody>
            <ReactTable
              className="-striped -highlight primary-pagination"
              data={data}
              noDataText="Debe Seleccionar un Municipio"
              columns={[
                {
                  Header: "Colonia",
                  minWidth: 10,
                  id: "Colonia",
                  accessor: (d) => d.Colonia,
                  filterAll: false,
                },
              ]}
              defaultPageSize={5}
              filterable
              loading={loading}
              showPagination={true}
              showPageJump={false}
              previousText="Anterior"
              nextText="Siguiente"
              canNextFromData={true}
              manual
              total={totalColonias}
              pages={Math.floor(totalColonias / 10)}
              previousText="Anterior"
              nextText="Siguiente"
              pageSizeOptions={[10, 20, 25, 50, 100]}
              getTrProps={(state, rowInfo, column, instance) => {
                return {
                  onClick: (e) => {
                    setVale({
                      ...vale,
                      Colonia: rowInfo.original.Colonia,
                      CP: rowInfo.original.data.d_codigo,
                    });
                    setFlagEscogerColonia(false);
                  },
                };
              }}
              onFetchData={(stateComp, instance) => {
                setloading(true);
                const token = getVars("Token").token;

                var array_filtros = [];
                array_filtros.push({ id: "c_estado", value: 11 });
                array_filtros.push({ id: "c_mnpio", value: vale.idMunicipio });
                if (stateComp.filtered.length != 0) {
                  array_filtros.push({
                    id: "d_asenta",
                    value: stateComp.filtered[0].value,
                  });
                }
                const datax = {
                  page: stateComp.page,
                  tipo: "and",
                  pageSize: stateComp.pageSize,
                  sorted: [{ id: "d_asenta", desc: false }],
                  filtered: array_filtros,
                };

                EjecutarApi(datax, "getCP", token)
                  .then(function (res) {
                    return res;
                  })
                  .then((res) => {
                    setloading(false);
                    setTotalColonias(res.total);
                    setData(
                      res.data.map((prop, key) => {
                        return {
                          Colonia: prop.d_asenta,
                          data: prop,
                        };
                      })
                    );
                  })
                  .catch((e) => {
                    console.log("=Error[getCP]: ", e);
                    setloading(false);
                    notify.show(
                      `Error al obtener colonias: ${e.errors}`,
                      "custom",
                      5000,
                      {
                        background: "#0857B6",
                        fontSize: 14,
                        text: "#FFFFFF",
                      }
                    );
                  });
              }}
            />
          </CardBody>
        </Card>
      </ReactBSAlert>
    </>
  );


  const municipioChange = (mun) => {
    
    if (mun) {
      const datos_localidades = {
        page: 0,
        tipo: "and",
        pageSize: 1000,
        sorted: [],
        filtered: [
          {
            id: "IdMunicipio",
            value: mun,
          },
        ],
      };

      EjecutarApi(datos_localidades, "getLocalidadET", token.token)
        .then(function (res) {
          return res;
        })
        .then((res) => {
          if (res.results) {
            //setData(res.data);

            setVale({...vale, Colonia: '', idLocalidad: {value: res.data[0].Id, label: res.data[0].Nombre}})
            setLocalidades(
              res.data.map((status_) => {
                return {
                  value: status_["Id"],
                  label: status_["Nombre"],
                };
              })
            );
          }
          else
          {
            setVale({...vale, Colonia: '', idLocalidad: {value: null, label: ''}})
          }
        })
        .catch((e) => {
          notify.show(`Error en la busqueda! ${e.message}`, "custom", 5000, {
            background: "#d61818",
            text: "#FFFFFF",
          });
        });
    }
  };



  const getCURP = () => {
    setloading(true);
    console.log("=> Buscar CURP");
    if (!vale.CURP) {
      setloading(false);
      notify.show(`Error: CURP inválida!`, "custom", 5000, {
        background: "#d61818",
        text: "#FFFFFF",
      });
      return true;
    }

    if (vale.CURP.length != 18) {
      setloading(false);
      notify.show(`Error: CURP inválida!`, "custom", 5000, {
        background: "#d61818",
        text: "#FFFFFF",
      });
      return true;
    }

    const CURP = vale.CURP.toUpperCase();
    var sexo_obtenido;
    console.log("=> Buscar CURP: ", CURP);
    fetch(
      `https://seguimiento.guanajuato.gob.mx/apiinformacionsocial/api/renapo/porcurp/pL@t_1n|Run$28/${CURP}`
    )
      .then((res) => res.json())
      .then((dataRS) => {
        setloading(false);
        console.log("=> Resultados CURP", dataRS);
        if (dataRS.Mensaje == "OK") {
          //aqui
          var FN = dataRS.Resultado.fechNac.split("/");

          console.log("=FNAC: ", FN); //año-mes-dia
          console.log("@@@valores SEXO", dataRS.Resultado.sexo);
          sexo_obtenido = dataRS.Resultado.sexo;
          setVale({
            ...vale,
            Nombre: dataRS.Resultado.nombres,
            Paterno: dataRS.Resultado.apellido1,
            Materno: dataRS.Resultado.apellido2,
            Sexo: dataRS.Resultado.sexo === "H" ? "M" : "F", // { value: "M", label:"MASCULINO"} : { value: "F", label:"FEMENINO"} , //{ label: dataRS.Resultado.sexo, value: dataRS.Resultado.sexo === 'H' ? 'M' : 'F' },//"M",//dataRS.Resultado.sexo === 'H' ? 'M' : 'F',
            FechaNacimiento: FN[2] + "-" + FN[1] + "-" + FN[0],
          });
          console.log("=>valores CURP", vale);
        } else {
          setloading(false);
          if (
            dataRS.Mensaje === "La CURP no se encuentra en la base de datos"
          ) {
            console.log("La CURP no se encuentra en la base de datos");
            notify.show(`Error en CURP...! ${dataRS.Mensaje}`, "custom", 5000, {
              background: "#d61818",
              text: "#FFFFFF",
            });
          } else if (
            dataRS.Mensaje ===
            "El campo CURP: No cumple con el formato especificado."
          ) {
            console.log(
              "El campo CURP: No cumple con el formato especificado."
            );
            notify.show(`Error en CURP...! ${dataRS.Mensaje}`, "custom", 5000, {
              background: "#d61818",
              text: "#FFFFFF",
            });
          } else {
            console.log("El servicio no esta disponible.");
          }
          return true;
        }
        console.log("=> Resultados CURP", dataRS);
      })
      .catch((err) => {
        setloading(false);
        console.log("=>Error en CURO: ", err);
      });
    //
  };

  return (
      <Modal
          isOpen={show}
          toggle={onHide}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
      >
          <ModalHeader toggle={onHide}>
              Recepción de Expediente <b>{vale.ClaveUnica}</b>
          </ModalHeader>
          <ModalBody>
          <h6>Datos del Solicitante</h6>

                          <Row>
                            <Label sm="2">
                              Fecha de Solicitud:
                              <span className="text-danger">*</span>
                            </Label>
                            <Col sm="8">
                              <FormGroup>
                                <DateTimeComponent
                                  id="FechaSolicitud"
                                  name="FechaSolicitud"
                                  dateFormat="YYYY-MM-DD"
                                  maxDate={new Date("2021-12-31")}
                                  minDate={new Date("2021-01-30")}
                                  timeFormat={false}
                                  closeOnSelect={true}
                                  onChange={(e) =>
                                    setVale({
                                      ...vale,
                                      FechaSolicitud: moment(e._d).format(
                                        "YYYY-MM-DD"
                                      ),
                                    })
                                  }
                                  inputProps={{ className: "form-control" }}
                                  defaultValue={vale.FechaSolicitud}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Label sm="2">CURP:</Label>
                            <Col sm="4">
                              <FormGroup>
                                <Input
                                  maxLength={18}
                                  name="CURP"
                                  type="text"
                                  value={vale.CURP}
                                  onChange={(event) =>
                                    setVale({
                                      ...vale,
                                      CURP: event.target.value.toUpperCase(),
                                    })
                                  }
                                />
                              </FormGroup>
                            </Col>
                            <Col sm="2">
                              <FormGroup>
                                <Button color="primary" onClick={getCURP}>
                                  {loading ? (
                                    <ImpulseSpinner
                                      color={"#1261ff"}
                                      size={30}
                                      loading={loading}
                                    />
                                  ) : (
                                    ""
                                  )}

                                  {loading ? (
                                    <span>Validando</span>
                                  ) : (
                                    <span>Validar</span>
                                  )}
                                </Button>
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Label sm="2">
                              Nombre:<span className="text-danger">*</span>
                            </Label>
                            <Col sm="6">
                              <FormGroup>
                                <Input
                                  name="nombre"
                                  type="text"
                                  value={vale.Nombre}
                                  onChange={(event) =>
                                    setVale({
                                      ...vale,
                                      Nombre: event.target.value.toUpperCase(),
                                    })
                                  }
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Label sm="2">Apellido Paterno:</Label>
                            <Col sm="6">
                              <FormGroup>
                                <Input
                                  name="paterno"
                                  type="text"
                                  value={vale.Paterno}
                                  onChange={(event) =>
                                    setVale({
                                      ...vale,
                                      Paterno: event.target.value.toUpperCase(),
                                    })
                                  }
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Label sm="2">Apellido Materno:</Label>
                            <Col sm="6">
                              <FormGroup>
                                <Input
                                  name="materno"
                                  type="text"
                                  value={vale.Materno}
                                  onChange={(event) =>
                                    setVale({
                                      ...vale,
                                      Materno: event.target.value.toUpperCase(),
                                    })
                                  }
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Label sm="2">Sexo:</Label>
                            <Col sm="6">
                              <FormGroup>
                                <Input
                                  disabled
                                  className="react-select primary"
                                  //defaultValue={vale.Sexo.value === "M"? { value: "M", label: "MASCULINO" } :{ value: "F", label: "FEMENINO" }}
                                  id="sexo"
                                  value={
                                    vale.Sexo == "M"
                                      ? "Masculino"
                                      : vale.Sexo == ""
                                      ? ""
                                      : "Femenino"
                                  }
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Label sm="2">
                              Fecha de Nacimiento:
                              <span className="text-danger">*</span>
                            </Label>
                            <Col sm="4">
                              <FormGroup>
                                <ReactDatetime
                                  id="FechaNacimiento"
                                  name="FechaNacimiento"
                                  dateFormat="YYYY-MM-DD"
                                  timeFormat={false}
                                  value={vale.FechaNacimiento}
                                  closeOnSelect
                                  onChange={(e) =>
                                    setVale({
                                      ...vale,
                                      FechaNacimiento: moment(e._d).format(
                                        "YYYY-MM-DD"
                                      ),
                                    })
                                  }
                                  inputProps={{ className: "form-control" }}
                                />
                              </FormGroup>
                            </Col>
                          </Row>

                          <Row>
                  <Label sm="2">
                    Ocupación:<span className="text-danger">*</span>
                  </Label>
                  <Col sm="6">
                    <FormGroup>
                      <Select
                        className="react-select primary"
                        id="Ocupacion"
                        value={vale.Ocupacion}
                        onChange={(event) => setVale({...vale, Ocupacion: event})}
                        options={catOcupacion.data}
                        placeholder="seleccione su Ocupacion"
                      />
                    </FormGroup>
                  </Col>
                </Row>

                          {vale.Ocupacion.value === "Otro" &&<Row>
                            <Label sm="2">
                              Otra Ocupación:<span className="text-danger">*</span>
                            </Label>
                            <Col sm="6">
                              <FormGroup>
                                <Input
                                  name="OcupacionOtro"
                                  type="text"
                                  value={vale.OcupacionOtro}
                                  onChange={(event) =>
                                    setVale({
                                      ...vale,
                                      OcupacionOtro: event.target.value.toUpperCase(),
                                    })
                                  }
                                />
                              </FormGroup>
                            </Col>
                          </Row>}

                          <h6>Datos de Ubicación Geográfica</h6>

                          <Row>
                            {municipios.length !== 0 ? (
                              <>
                                <Label sm="2">
                                  Municipio:
                                  <span className="text-danger">*</span>
                                </Label>
                                <Col sm="6">
                                  <FormGroup>
                                    <Select
                                      className="react-select primary"
                                      id="municipio"
                                      value={vale.idMunicipio}
                                      onChange={(event) => {
                                        setVale({
                                          ...vale,
                                          idMunicipio: event,
                                        });
                                        setNomMunicipio(event.value);
                                        municipioChange(event.value);
                                      }}
                                      options={municipios}
                                      placeholder="seleccione su municipio"
                                    />
                                  </FormGroup>
                                </Col>
                              </>
                            ) : (
                              <>
                                <Col sm="2" style={Spinenerstyle}>
                                  <RotateSpinner
                                    color={"#1261ff"}
                                    size={30}
                                    loading={true}
                                  />
                                </Col>
                              </>
                            )}
                          </Row>
                          <Row>
                            <Label sm="2">
                              Calle:<span className="text-danger">*</span>
                            </Label>
                            <Col sm="6">
                              <FormGroup>
                                <Input
                                  id="calle"
                                  type="text"
                                  value={vale.Calle}
                                  onChange={(event) =>
                                    setVale({
                                      ...vale,
                                      Calle: event.target.value,
                                    })
                                  }
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Label sm="2">
                              Número Exterior:
                              <span className="text-danger">*</span>
                            </Label>
                            <Col sm="2">
                              <FormGroup>
                                <Input
                                  id="numExt"
                                  type="text"
                                  value={vale.NumExt}
                                  onChange={(event) =>
                                    setVale({
                                      ...vale,
                                      NumExt: event.target.value,
                                    })
                                  }
                                />
                              </FormGroup>
                            </Col>
                            <Label sm="2">Número Interior</Label>
                            <Col sm="2">
                              <FormGroup>
                                <Input
                                  id="numInt"
                                  type="text"
                                  value={`${vale.NumInt}`}
                                  onChange={(event) =>
                                    setVale({
                                      ...vale,
                                      NumInt: event.target.value,
                                    })
                                  }
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Label sm="2">
                              Colonia:<span className="text-danger">*</span>
                            </Label>
                            <Col sm="4">
                              <FormGroup>
                                <Input
                                  name="Colonia"
                                  type="text"
                                  value={vale.Colonia}
                                  onChange={(event) =>
                                    setVale({
                                      ...vale,
                                      Colonia: event.target.value,
                                    })
                                  }
                                />
                              </FormGroup>
                            </Col>
                            <Col sm="2">
                              <Button
                                onClick={() => {
                                  setFlagEscogerColonia(true);
                                  console.log("ONCLIK");
                                }}
                              >
                                BUSCAR
                              </Button>
                              {flagEscogerColonia ? mini_table : <></>}
                            </Col>
                          </Row>
                          <Row>
                            <Label sm="2">
                              Localidad:<span className="text-danger">*</span>
                            </Label>
                            <Col sm="6">
                              <FormGroup>
                                <Select
                                  className="react-select primary"
                                  id="Localidad"
                                  value={vale.idLocalidad}
                                  onChange={(event) => {
                                    setVale({
                                      ...vale,
                                      idLocalidad: event,
                                    });
                                  }}
                                  options={localidades}
                                  placeholder="seleccione su localidad"
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Label sm="2">
                              Codigo Postal:
                              <span className="text-danger">*</span>
                            </Label>
                            <Col sm="4">
                              <FormGroup>
                                <Input
                                  name="cp"
                                  type="number"
                                  value={vale.CP}
                                  onChange={(event) =>
                                    setVale({ ...vale, CP: event.target.value })
                                  }
                                />
                              </FormGroup>
                            </Col>
                          </Row>

                          <CardTitle tag="h6">Datos del Contacto</CardTitle>
                          <Row>
                            <Label sm="2">Correo:</Label>
                            <Col sm="6">
                              <FormGroup>
                                <Input
                                  name="correo"
                                  type="email"
                                  value={vale.CorreoElectronico}
                                  onChange={(event) =>
                                    setVale({
                                      ...vale,
                                      CorreoElectronico: event.target.value,
                                    })
                                  }
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Label sm="2">Teléfono Fijo:</Label>
                            <Col sm="4">
                              <FormGroup>
                                <Input
                                  max="999999999"
                                  id="TelFijo"
                                  type="number"
                                  onChange={(event) =>
                                    setVale({
                                      ...vale,
                                      TelFijo: event.target.value,
                                    })
                                  }
                                  value={vale.TelFijo}
                                />
                              </FormGroup>
                            </Col>
                            <Label sm="2">Teléfono Recados:</Label>
                            <Col sm="4">
                              <FormGroup>
                                <Input
                                  maxLength={10}
                                  id="telRecados"
                                  type="number"
                                  onChange={(event) =>
                                    setVale({
                                      ...vale,
                                      TelRecado: event.target.value,
                                    })
                                  }
                                  value={vale.TelRecado}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Label sm="2">
                              Teléfono Celular:
                              {/* <span className="text-danger">*</span> */}
                            </Label>
                            <Col sm="4">
                              <FormGroup>
                                <Input
                                  max="999999999"
                                  min="1"
                                  id="celular"
                                  type="number"
                                  onChange={(event) =>
                                    setVale({
                                      ...vale,
                                      TelCelular: event.target.value,
                                    })
                                  }
                                  value={vale.TelCelular}
                                />
                              </FormGroup>
                            </Col>
                            <Label sm="2">
                              Compañía Celular:
                             {/*  <span className="text-danger">*</span> */}
                            </Label>
                            <Col sm="4">
                              <FormGroup>
                                <Select
                                  className="react-select primary"
                                  value={vale.Compania}
                                  name="ciaCelular"
                                  //defaultValue={{ value: optcompania[0].value, label: optcompania[0].label }}
                                  onChange={(event) => {
                                    setVale({ ...vale, Compania: event.label });
                                  }}
                                  options={optcompania}
                                  placeholder="Seleccione Compañía Telefónica"
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <CardTitle tag="h6">Datos de ingresos</CardTitle>
                          <Row>
                            <Label sm="2">Ingreso mensual percibido:</Label>
                            <Col sm="6">
                              <FormGroup>
                                <Input
                                  name="IngresoPercibido"
                                  type="text" 
                                  pattern="[0-9]*"
                                  value={vale.IngresoPercibido}
                                  onChange={event => setVale({...vale, IngresoPercibido: event.target.value.replace(/\D/,'')})}
                                  
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Label sm="2">Otros Ingresos:</Label>
                            <Col sm="6">
                              <FormGroup>
                                <Input
                                  name="OtrosIngresos"
                                  type="text" 
                                  pattern="[0-9]*"
                                  value={vale.OtrosIngresos}
                                  onChange={event => setVale({...vale, OtrosIngresos: event.target.value.replace(/\D/,'')})}
                                  
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Label sm="2">Número de personas dependientes del ingreso mensual total:</Label>
                            <Col sm="6">
                              <FormGroup>
                                <Input
                                  name="NumeroPersonas"
                                  type="text" 
                                  pattern="[0-9]*"
                                  value={vale.NumeroPersonas}
                                  onChange={event => setVale({...vale, NumeroPersonas: event.target.value.replace(/\D/,'')})}
                                  
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          
          </ModalBody>

          <ModalFooter>
             
              {!flagPDF &&<Button onClick={btnGuardarDatos}> Recepcionar</Button>}
              {flagPDF &&<RotateSpinner color={"#1261ff"} size={30} loading={flagPDF} />}
          </ModalFooter>
      </Modal>
  );
}


export default ModalRecepcionVale;