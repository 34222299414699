import React, { useState, useEffect } from 'react';
// react plugin used to create switch buttons

import { Button, Input, Form, FormGroup, CardBody, Row, Col, Card, CardHeader, CardTitle, Label, CardFooter } from 'reactstrap';
import { Link } from 'react-router-dom';
import ReactBSAlert from "react-bootstrap-sweetalert";
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import Sidebar from '../components/Sidebar';
import Notifications, { notify } from 'react-notify-toast';
import EjecutarApi from '../Consultas/Consultas';
import { getVars } from '../GlobalStorage';
import { useHistory } from 'react-router-dom';
import ReactTable from 'react-table';
import Select from "react-select";
import { RotateSpinner, ImpulseSpinner } from 'react-spinners-kit';
import Grupos from './Grupos';
import InfiniteScroll from "react-infinite-scroll-component";
import matchSorter from 'match-sorter';
import DateTimeComponent from '../components/DateTimeComponent';
import moment from 'moment';
import { CircleSpinner } from 'react-spinners-kit';

function GruposEntregaValePaso1(props) {
   
    const [flagBotonGuardar,setflagBotonGuardar]=useState(false);
    const [loading, setloading] = useState(false);
    let history = useHistory();
    const [dataUsers, setDataUsers] = useState({ data: [], total: 0 });
    const [hasMoreUsers, setHasMoreUsers] = useState(true);
    const [DetalleSolicitante, setDetalleSolicitante] = useState({ data: [], total: 0 });
   
    const [dataSearch, setDataSearch] = useState({ page: 0, pageSize: 20 });

    const token = getVars('Token');
    const dataGrupo = getVars('dataGrupo');
   
    const [msgErrorAlerta,setmsgErrorAlerta]=useState('');
    const [flagmsgErrorAlerta,setflagmsgErrorAlerta]=useState(false);
    const [flagCancelarVale,setflagCancelarVale] = useState(false);
    
    useEffect(() => {
        llenadoInfiniteScrool();
       
    }, []);

    const limpiarDataSet=()=>{
        setDataUsers({ data: [], total: 0 });
    }

    const llenadoInfiniteScrool=()=>{
        let datax = {
            page: 0,
            tipo: "and",
            pageSize: 50,
            sorted: [],
            filtered: [{ "id": "vales.UserOwned", "value": dataGrupo.UserOwned }], //idARticuladortoken
            Remesa:dataGrupo.Remesa,
            isEntregadoOwner:0 //pendientes por entrega 0 y 1 = ya entregados
        };
        limpiarDataSet();
        //console.log('######EGrupoEditArticulador', JSON.stringify(datax));
        EjecutarApi(datax, 'getValesDocumentacionIn', token.token)
            .then(function (res) {
                return res;
            })
            .then(items => {
                console.log("===> Data getValesDocumentacionIn: ", items);

                if (items.results) {
                    setDataSearch({ ...dataSearch, page: dataSearch.page + 1 });
                    setloading(false);

                    setDataUsers({
                        data: items.data,//dataUsers.data.concat(items.data),
                        total: items.total
                    });
                }
            })
            .catch((e) => {
                console.log('=Error[getValesDocumentacionIn]: ', e);
                setloading(false);
                notify.show(
                    `Error al obtener Vales: ${e.errors}`,
                    'custom',
                    5000,
                    {
                        background: '#0857B6',
                        fontSize: 14,
                        text: '#FFFFFF',
                    }
                );

                //setloadingGrupo(false);
            });
        
    }

    const handleMiniClick = () => {
        document.body.classList.toggle('sidebar-mini');
    };

    const Spinenerstyle = {
        'height': '50pt',
        'display': 'flex',
        'justifyContent': 'center',
        'alignItems': 'center',
        'color':'blue'
    };
   
    const handleReturn = () => {
        history.push('/entregavales');
    };
    const handleCancelarVale = () => {
       setflagCancelarVale(true);
    };
    const [_fechaEntrega, _setFechaEntrega] = useState('');
 
    const GrupoValeEntrega = (_comentario,_isEntregadoOwner) => {
        setflagBotonGuardar(false);
        setloading(true);
        if(_fechaEntrega.length===0)
        {
            notify.show('Debe ingresar la fecha', 'custom', 5000, {
                background: '#18c97d',
                text: '#FFFFFF',
            });
            setloading(false);
            return true;
        }
        var data = {
            id: itemFolio.idSolicitud.idSolicitud,
            //isEntregado:_isEntregadoOwner,// 1=Entregado, 2:CancelarVale [Obligatorio el comentario solo para el 2]
            isEntregadoOwner: _isEntregadoOwner,// 1=Entregado, 2:CancelarVale [Obligatorio el comentario solo para el 2]
            idUserReportaEntrega: token.user.id, //sesion reporta
            FechaReportaEntrega: _fechaEntrega,
            ComentarioEntrega:_comentario
        };
        console.log("=>ValeEntregado, data", JSON.stringify(data));
       
        EjecutarApi(data, 'updateVales', token.token)
            .then(function (res) {
                console.log("RESPONSE BACK:", res)
                setloading(false);
                notify.show('Registro Exitoso!', 'custom', 5000, {
                    background: '#18c97d',
                    text: '#FFFFFF',
                });

                return res;

            })
            .catch((e) => {
                setloading(false);
                console.log("error", e.errors);

                notify.show(`Error:! ${e.message}`, 'custom', 5000, {
                    background: '#d61818',
                    text: '#FFFFFF',
                });
            });

            llenadoInfiniteScrool();


    }
 
    const fetchMoreUsers = () => {
        if (dataSearch.page * dataSearch.pageSize >= DetalleSolicitante.total) {
            console.log("=>PorAutorizar Llego a su final: ",
                dataSearch.page * dataSearch.pageSize
            );

            setHasMoreUsers(false);
            return;
        }

        setloading(true);

        const data = {
            page: dataSearch.page + 1,
            tipo: "and",
            pageSize: dataSearch.pageSize,
            sorted: [],
            filtered: [{ id: "vales.UserOwned", value: dataGrupo.UserOwned }],
            Remesa:dataGrupo.Remesa,
            isEntregadoOwner:0 //pendientes por entrega 0 y 1 = ya entregados
        };


        console.log("= getValesDocumentacionIn:  ", data);

        EjecutarApi(data, "getValesDocumentacionIn", getVars('Token').token, "POST")
            .then(function (res) {
                return res;
            })
            .then(items => {
                //console.log("===> Data Por Autorizar: ", items);

                if (items.results) {
                    setDataSearch({ ...dataSearch, page: dataSearch.page + 1 });
                    setloading(false);

                    setDataUsers({
                        data: dataUsers.data.concat(items.data),
                        total: items.total
                    });
                }
            })
            .catch(e => {
                console.log("error getValesDocumentacionIn: ", e.message);
                setloading(false);
            });
    };

    
    const [itemFolio, setItemFolio] = useState([]);
    
    const visualizaDetalleVales = (item) => {
        console.log('Entra a setear Detalles Vales => item', item);
        setItemFolio(item);
    };

    const alertaMensaje = (<ReactBSAlert
        style={{ display: "block", marginTop: "-100px" , fontSize:"8px" }}
        title={'Error:  ' + msgErrorAlerta + '!!!!'}
        confirmBtnText="Aceptar"
        cancelBtnText="No"
        onConfirm={() => {setflagmsgErrorAlerta(false); }}
        onCancel={()=>{flagmsgErrorAlerta(false);} }
        confirmBtnBsStyle="info sm btn-round"
        
      />);
    const inputConfirmAlert =(e)=>{
      
        GrupoValeEntrega(e,2);
        setflagCancelarVale(false);
    };
    const CancelarValeinputAlert = (
            <ReactBSAlert
              input
              showCancel
              style={{ display: "block", marginTop: "-100px" }}
              title={"Comentario"}
              onConfirm={e => inputConfirmAlert(e)}
              onCancel={() => setflagCancelarVale(false)}
              confirmBtnBsStyle="info"
              confirmBtnText="Cancelar"
              cancelBtnBsStyle="danger"
              cancelBtnText="Salir"
            />
          );
       
    return (
        <div className="wrapper">
            <Sidebar {...props} bgColor="black" activeColor="info" />
            <div className="main-panel">
                <Navbar {...props} handleMiniClick={handleMiniClick} />
                <Notifications />
                <div className="content">
                    <Row>
                        <Col md="12">
                            <Card>
                                <CardHeader>
                                    <Row>
                                        <Col md="9">
                                            <CardTitle tag="h6">Entrega de Vales</CardTitle>
                                        </Col>
                                        <Col>
                                            <Button color="success" onClick={() => { handleReturn() }} size="sm"  >
                                                <i className="nc-icon nc-minimal-left" />
                                            Regresar

                                    </Button>
                                        </Col>
                                    </Row>
                                </CardHeader>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="12">
                            <Card>
                                <CardBody>
                                    <Form className="form-horizontal" id="TypeValidation">
                                        <Notifications />
                                        <Row>
                                            <Label sm="2">Articulador:<span className="text-danger">*</span></Label>

                                            <Col sm="6">
                                                <FormGroup >
                                                    <Input id="articu" type="text" value={dataGrupo.FullName} disabled />
                                                </FormGroup>
                                            </Col>

                                        </Row>
                                        <Row>
                                            <Label sm="2">Municipio:<span className="text-danger">*</span></Label>
                                            <Col sm="6">
                                                <FormGroup >
                                                    <Input id="articu" type="text" value={dataGrupo.Municipio} disabled />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Label sm="2">Total Aprobados:<span className="text-danger">*</span></Label>
                                            <Col sm="4">
                                                <FormGroup >
                                                    <Input id="Total" type="text" value={dataGrupo.Total} disabled />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Label sm="2">Número de Remesa:<span className="text-danger">*</span></Label>
                                            <Col sm="4">
                                                <FormGroup >
                                                    <Input id="remesa" type="text" value={dataGrupo.Remesa} disabled />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Form>
                                </CardBody>
                                <CardFooter className="text-center">

                                </CardFooter>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="6">
                            <Card>
                                <CardHeader>
                                    <Row>
                                        <Col>
                                            <CardTitle tag="h6">Detalle del Solicitante</CardTitle>
                                        </Col>
                                    </Row>

                                </CardHeader>

                                <CardBody>
                                    <InfiniteScroll
                                        dataLength={dataUsers.data.length}
                                        next={fetchMoreUsers}
                                        hasMore={hasMoreUsers}
                                        loader={dataUsers.data.length===0?<h4>Sin Registros...</h4>:<h4>cargando...</h4>}
                                        height={600}
                                        endMessage={
                                            <p style={{ textAlign: "center" }}>
                                                <b>Listo! Esos son todos!</b>
                                            </p>
                                        }
                                    >
                                        {dataUsers.data.map((item, index) =>
                                            (item.isEntregadoOwner===1 || item.isEntregadoOwner===2)?(<></>):(
                                            
                                            <div key={index}>
                                                <span style={{ color: "#4ACCCD" }}>
                                                    <b onClick={() => visualizaDetalleVales(item)}>{item.CURP}</b>
                                                </span>
                                                <h6>
                                                    {`${item.Nombre} ${item.Paterno} ${item.Materno}`}
                                                </h6>

                                                <h6>
                                                    {`Mpio: ${item.idMunicipio.Municipio}, Loc: ${item.idLocalidad.Nombre} `}
                                                </h6>

                                                <h6>
                                                    {`Folio Inicial: ${item.idSolicitud.SerieInicial}`}
                                                </h6>
                                                <h6>
                                                    {`Folio Final: ${item.idSolicitud.SerieFinal}`}
                                                </h6>
                                                <Button size="sm" color="primary" onClick={() => {visualizaDetalleVales(item); 
                                                                                                setflagBotonGuardar(true)}}>
                                                    Entregar Vale
                                                </Button>
                                                
                                                <hr />
                                            </div>)
                                                         
                                        )}
                                    </InfiniteScroll>
                                </CardBody>


                            </Card>
                        </Col>

                        <Col md="6">

                            <Form md="12">
                                <Card md="12">
                                    <CardHeader md="12">
                                        <h6>Entrega de Vales</h6>
                                    </CardHeader>
                                    <CardBody md="12">
                                        <Row>
                                            {itemFolio.length===0?(<>
                                                    <Label sm="12"> <h6>Seleccinar un beneficiario</h6></Label> </>
                                            ):( <>
                                                <Label sm="12"> <h6>{`${itemFolio.Nombre} ${itemFolio.Paterno} ${itemFolio.Materno}`}</h6></Label>
                                            </>
                                            )
                                            }
                                            
                                        </Row>
                                        <Row>
                                            <Label sm="4">Fecha Entrega:<span className="text-danger">*</span></Label>
                                            <Col sm="8">
                                                <FormGroup>
                                                    <DateTimeComponent
                                                        id="FechaEntrega"
                                                        name="FechaEntrega"
                                                        dateFormat="YYYY-MM-DD"
                                                        maxDate={new Date("2020-12-31")}
                                                        minDate={new Date("2020-05-01")}
                                                        timeFormat={false}
                                                        closeOnSelect={true}
                                                        onChange={e => _setFechaEntrega(moment(e._d).format('YYYY-MM-DD'))}
                                                        inputProps={{ className: "form-control" }}

                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Label sm="4">Serie Inicial:<span className="text-danger">*</span></Label>
                                            <Col sm="8">
                                                <FormGroup >
                                                    <Input
                                                        name="SerieInicial"
                                                        type="number"
                                                        value={itemFolio.length == 0 ? '' : itemFolio.idSolicitud.SerieInicial}
                                                        //onChange={event => setSolicitanteFolioInicial(event.target.value)}
                                                        disabled
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Label sm="4">Serie Final:<span className="text-danger">*</span></Label>
                                            <Col sm="8">
                                                <FormGroup >
                                                    <Input
                                                        name="FolioFinal"
                                                        type="text"
                                                        value={itemFolio == 0 ? '' : itemFolio.idSolicitud.SerieFinal}

                                                        disabled
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        
                                   
                                    </CardBody>
                                    <CardFooter>
                                        <Row>
                                            {loading && (
                                                <CircleSpinner
                                                    className="text-center"
                                                    size={30}
                                                    color="#686769"
                                                    loading={loading}
                                                />
                                            )}
                                            {flagBotonGuardar?
                                                (<>
                                                    <Button color="primary" onClick={() => { GrupoValeEntrega("",1)  }} size="sm"  >
                                                     Vale Entregado  </Button>

                                                    <Button color="danger" onClick={handleCancelarVale} size="sm"  >
                                                        <i className="nc-icon nc-simple-remove" />
                                                    Cancelar Vale
                                                </Button> </>
                                                ) : (<>
                                                    </>

                                                )}
                                        </Row>
                                        {flagCancelarVale?CancelarValeinputAlert:<></>}
                                    </CardFooter>
                                </Card>
                            </Form>

                        </Col>
                    </Row>

                </div>
            </div>
        </div>
    );
}
export default GruposEntregaValePaso1;