import React, { useEffect, useState } from 'react';
// react component for creating dynamic tables
import ReactTable from 'react-table';
import EjecutarApi from '../Consultas/Consultas';
import Notifications, { notify } from 'react-notify-toast';
import { getVars, setVars } from '../GlobalStorage';
import Navbar from '../components/Navbar';
import Sidebar from '../components/Sidebar';
import { useHistory } from 'react-router-dom';
// reactstrap components
import { Button, Card, CardHeader, CardBody, CardTitle, Row, Col} from 'reactstrap';
import matchSorter from 'match-sorter';
import { CircleSpinner } from 'react-spinners-kit';
import { URL_GENERAL } from '../data/consts';

function GruposDocumentos (props){
    const [loading,setloading]= useState(false);
    const [Grupos,setGrupos]=useState([]);    
    const [totalGrupos,setTotalGrupos]=useState(0);
    const [idArticulador,setidArticulador]=useState("");
   
    let history = useHistory();

    const token = getVars('Token');
    
    const [_menu,_setmenu]=useState({});
   

    useEffect(()=>{
        token.menu.forEach((prop, key) => {
           console.log('==>prop.Menu',prop.Menu);
            if(prop.Menu==='Entrega Documentos'){
                _setmenu( {
                Ver:prop.Ver,
                Agregar:prop.Agregar,
                Editar:prop.Editar,
                Eliminar:prop.Eliminar,
                Seguimiento:prop.Seguimiento,
                Exportar:prop.Exportar,
                Imprimir:prop.Imprimir,  
                ViewAll:prop.ViewAll,  
                idTipoUser:token.user.idTipoUser.id                             
                })}
        });
    },[]);
    const handleMiniClick = () =>{
        document.body.classList.toggle('sidebar-mini');
    };

 
    const handleDescargaExcel= (param)=>{
        console.log('=>Exportar DescargaExcel: ',param);

        var url = new URL(URL_GENERAL+`getReporteNominaVales?idGrupo=${param.id}&token=${token.token}`);
        window.open(url, '_blank');
        
    }
    return(
        <div className="wrapper">
            <Sidebar {...props} bgColor="black" activeColor="info" />
            <div className="main-panel">
                <Navbar {...props} handleMiniClick={handleMiniClick} />
                <Notifications />
                <div className="content">
                    <Row>
                        <Col md="12">
                            <Card>
                                <CardHeader>
                                    <Row>
                                        <Col md="9">
                                            <CardTitle tag="h6">Entrega de Documentos</CardTitle>
                                        </Col>
                                        <Col md="2">
                                          
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    {loading && (
                                        <CircleSpinner
                                            className="text-center"
                                            size={30}
                                            color="#686769"
                                            loading={loading}
                                        />
                                    )}
                                    <Col sm="12">
                                        <ReactTable size="sm"
                                            noDataText="¡No existen datos a mostrar!"
                                            data={Grupos}
                                            filterable
                                            manual
                                            columns={[
                                                {
                                                    Header: 'Remesa',
                                                            minWidth: 40,
                                                            id: 'Remesa',
                                                            accessor: d => d.Remesa,
                                                            filterMethod: (filter, rows) =>
                                                                matchSorter(rows, filter.value, { keys: ['Remesa'] }),
                                                            filterAll: false
                                                },
                                                {
                                                    Header: 'Municipio',
                                                            minWidth: 50,
                                                            id: 'Municipio',
                                                            accessor: d => d.Municipio,
                                                            filterMethod: (filter, rows) =>
                                                                matchSorter(rows, filter.value, { keys: ['Municipio'] }),
                                                            filterAll: true
                                                },
                                                {
                                                    Header: 'Articulador',
                                                            minWidth: 70,
                                                            id: 'FullName',
                                                            accessor: d => d.FullName,
                                                            filterMethod: (filter, rows) =>
                                                                matchSorter (rows, filter.value,{ keys: ['FullName'] }),
                                                            filterAll: true
                                                },
                                                {
                                                    Header: 'Total Solicitudes',
                                                                minWidth: 30,
                                                                id: 'Total',
                                                                accessor: d => d.Total,
                                                                filterMethod: (filter, rows) =>
                                                                    matchSorter(rows, filter.value, { keys: ['Total'] }),
                                                                filterAll: false
                                                },
                                                {
                                                    Header: 'Expedientes Faltantes',
                                                                minWidth: 30,
                                                                id: 'Faltantes',
                                                                accessor: d => d.Faltantes,
                                                                filterMethod: (filter, rows) =>
                                                                    matchSorter(rows, filter.value, { keys: ['Faltantes'] }),
                                                                filterAll: false
                                                },
                                                {
                                                    Header: 'Acciones',
                                                            minWidth: 30,
                                                            id: 'Acciones',
                                                            accessor: d => (_menu.Exportar==1?d.action:''),
                                                            filterMethod: (filter, rows) =>
                                                                matchSorter(rows, filter.value, { keys: ['Acciones'] }),
                                                            filterAll: false,
                                                            
                                                }
                                            ]}
                                            className="-striped -highlight primary-pagination"
                                            loading={loading}
                                            showPagination={true}
                                            showPageJump={false}
                                            canNextFromData={true}
                                            defaultPageSize={10}
                                            total={totalGrupos}
                                            pages={Math.floor(totalGrupos / 10)}
                                            previousText="Anterior"
                                            nextText="Siguiente"
                                            pageSizeOptions={[10, 20, 25, 50, 100]}
                                            getTrProps={(state, rowInfo, column, instance) => {
                                                return {
                                                    onClick: e => {
                                                        console.log('@#@#@--- Datos', rowInfo.original.data.UserOwned);
                                                        setidArticulador(rowInfo.original.data.UserOwned)
                                                        if(_menu.Editar===1)
                                                        {
                                                            setVars('dataGrupo',rowInfo.original.data);
                                                            history.push('/gruposdocpaso1');
                                                        }
                                                        else 
                                                        {
                                                            notify.show(
                                                                `No tiene permisos. Verifique con el administrador..`,
                                                                'custom',
                                                                5000,
                                                                {
                                                                    background: '#0857B6',
                                                                    fontSize: 14,
                                                                    text: '#FFFFFF',
                                                                }
                                                            );
                                                        }
                                                        
                                                    }
                                                    
                                                }
                                            
                                            }}
                                            onFetchData={(stateComp, instance) => {
                                                setloading(true);
                                                var arrays=[];
                                                var temFullName ='';
                                                stateComp.filtered.map((Filtro)=>{
                                                    if(Filtro.id==='FullName')
                                                    {
                                                        temFullName=Filtro.value;
                                                        
                                                    }
                                                    if(Filtro.id==='Remesa')
                                                    {
                                                        arrays.push({id: "vales_grupos.Remesa", value: Filtro.value})
                                                    }
                                                    if(Filtro.id==='Municipio')
                                                    {
                                                        arrays.push({id: "et_cat_municipio.Nombre", value: Filtro.value})
                                                    }
                                                    if(Filtro.id==='Solicitudes')
                                                    {
                                                        arrays.push({id: "vales_grupos.TotalAprobados", value: Filtro.value})
                                                    }
                                                    
                                                })

                                                const token = getVars('Token');
                                                const datax = {
                                                    page: stateComp.page,
                                                    tipo: 'and',
                                                    pageSize: stateComp.pageSize,
                                                    sorted: [],
                                                    filtered:arrays,
                                                    NombreCompleto: temFullName
                                                };
                                                
                                                EjecutarApi(datax, 'getArticularDocumentacion', token.token)
                                                    .then(function (res) {
                                                        return res;
                                                    })
                                                    .then((res) => {
                                                        setloading(false);
                                                        setTotalGrupos(res.total);
                                                        setGrupos(
                                                            res.data.map((prop, key) => {
                                                                
                                                                return {
                                                                    Remesa: prop.Remesa,
                                                                    Municipio: prop.Municipio,
                                                                    FullName: prop.FullName,
                                                                    Faltantes: prop.Faltantes,
                                                                    Total:prop.Total,
                                                                    Solicitudes:0,
                                                                    action:(<>
                                                                            
                                                                            <Button size="sm" color="success" onClick={()=>handleDescargaExcel(prop)}><span className="btn-label">
                                                                            <i className="fa fa-file-excel-o fa-2x" />
                                                                                    </span>
                                                                                </Button> 
                                                                             
                                                                                </>
                                                                            ),
                                                                    data: prop,
                                                                };
                                                            })
                                                        );
                                                        
                                                    })
                                                    .catch((e) => {
                                                        console.log('=Error[getExport]: ', e);
                                                        setloading(false);
                                                        notify.show(
                                                            `Error al obtener Vales: ${e.errors}`,
                                                            'custom',
                                                            5000,
                                                            {
                                                                background: '#0857B6',
                                                                fontSize: 14,
                                                                text: '#FFFFFF',
                                                            }
                                                        );

                                                    });
                                            }}
                                        />
                                    </Col>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row> 
                </div>
            </div>
        </div>
       
    );

}

export default GruposDocumentos;