export const FormatedUser = {
  Nombre: "",
  Paterno: "",
  Materno: "",
  TelCelular: "",
  email: "",
  password: "",
  Correo: "",
  Region: "",
  idTipoUser: 0,
  idStatus: 1,
  defaultPage: "/",
};
