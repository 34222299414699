import React from "react";

import {
  Badge,
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  Row,
  Spinner,
  Card,
  CardImg,
} from "reactstrap";
import { isNullOrUndefined } from "../../utils/emptyValidations";
import { Link, Redirect } from "react-router-dom";
const FileList = ({
  files,
  archivosClasificacion,
  catalogClasificacion,
  handleChangeArchivos,
  handleChangeClasificacion,
  handleDeleteFile,
  loadingCatalogs,
  onlyForView,
  fullscreen = false,
}) => {
  const handleSelectItems = (newFiles) => {
    console.log(newFiles);
    handleChangeArchivos(files.concat(Array.from(newFiles)));
  };

  const getValorClasificacion = (index) => {
    const valor = archivosClasificacion[index];
    if (isNullOrUndefined(valor)) return "";
    return valor;
  };

  const handleSelectClasificacion = (idClasificacion, index) => {
    let clasificacionAux = [...archivosClasificacion];
    clasificacionAux[index] = idClasificacion;
    handleChangeClasificacion(clasificacionAux);
  };

  const deleteFile = (index) => {
    let filesAux = [...files];
    let clasificacionAux = [...archivosClasificacion];
    filesAux.splice(index, 1);
    clasificacionAux.splice(index, 1);
    handleDeleteFile(filesAux, clasificacionAux);
  };
  return (
    <Row style={{ width: "100%" }}>
      {files.map((file, index) => (
        <>
          <Col sm="6" style={{ border: "1px solid" }}>
            <Col
              sm="12"
              xs="12"
              style={{
                display: "grid",
                placeItems: "center",
              }}
            >
              {" "}
              {file && (
                <Link
                  //to={file.ruta}
                  className={(isActive) =>
                    "nav-link" + (!isActive ? " unselected" : "")
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    window.open(file.ruta, "_blank");
                  }}
                  style={{
                    fontWeight: "bold",
                    fontSize: "12px",
                    paddingTop: "1rem",
                  }}
                >
                  {file.Clasificacion}
                </Link>
              )}
            </Col>
            <Col
              sm="12"
              xs="12"
              style={{
                display: "grid",
                placeItems: "center",
              }}
            >
              <>
                {file &&
                  file.lastModified &&
                  (file.type === "application/pdf" ? (
                    <object
                      type="application/pdf"
                      aria-labelledby={file.name}
                      data={URL.createObjectURL(file)}
                      width="100%"
                      height="250"
                    ></object>
                  ) : (
                    <CardImg
                      variant="top"
                      src={URL.createObjectURL(file)}
                      style={{ height: "250px", width: "85%" }}
                    ></CardImg>
                  ))}
                {file && file.type === "image" ? (
                  <Card>
                    <CardImg
                      variant="top"
                      src={file.ruta}
                      style={{ height: "300px" }}
                    ></CardImg>
                  </Card>
                ) : (
                  file.type === "pdf" && (
                    <div
                      display="flex"
                      style={{
                        width: "100%",
                        border: "1px solid #999",
                        height: "300px",
                      }}
                    >
                      <object
                        type="application/pdf"
                        aria-labelledby={file.NombreOriginal}
                        data={file.ruta}
                        width="100%"
                        height="300"
                      ></object>
                    </div>

                    //     <i
                    //       class="fa fa-file-pdf-o fa-4x"
                    //       aria-hidden="true"
                    //       style={{ color: "#d34a37", alignItems: "center" }}
                    //     ></i>
                    //   ) : (
                    //     <i
                    //       class="fa fa-file-image-o fa-4x"
                    //       aria-hidden="true"
                    //       style={{ color: "#d34a37", alignItems: "center" }}
                    //     ></i>
                  )
                )}
              </>
            </Col>

            <Col sm="12" xs="12">
              <FormGroup>
                <Label>
                  Clasificación: <span className="text-danger">*</span>
                  {loadingCatalogs && <Spinner size="sm" />}
                </Label>
                <Input
                  name="idTipoCombustible"
                  type="select"
                  disabled={loadingCatalogs || onlyForView}
                  style={{
                    borderColor:
                      getValorClasificacion(index) === ""
                        ? "#d03d3dcf"
                        : "#3dd03dcf",
                  }}
                  value={getValorClasificacion(index)}
                  onChange={(e) =>
                    handleSelectClasificacion(e.target.value, index)
                  }
                >
                  <option value={""}></option>
                  {catalogClasificacion.map((element) => (
                    <option key={element.value} value={element.value}>
                      {element.label}
                    </option>
                  ))}
                </Input>
              </FormGroup>
            </Col>
            <Col sm="12" xs="12">
              {!onlyForView && (
                <Button
                  color="danger"
                  size="sm"
                  onClick={() => deleteFile(index)}
                  style={{ width: "100%" }}
                >
                  <span className="btn-label">
                    <i className="fa fa-trash fa-xs"></i>
                  </span>
                </Button>
              )}
            </Col>
          </Col>
        </>
      ))}

      {!onlyForView && (
        <Col xs="12" style={{ display: "flex", justifyContent: "center" }}>
          <FormGroup>
            <Input
              id="exampleFile"
              name="file"
              type="file"
              // style={{display: "none"}}
              style={{ cursor: "pointer" }}
              multiple
              onChange={(e) => handleSelectItems(e.target.files)}
            />
            <Button onClick={() => {}}>Seleccionar archivos</Button>
          </FormGroup>
        </Col>
      )}
    </Row>
  );
};

export default FileList;
