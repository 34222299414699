const isNullOrUndefined = (data) => {
  return data === null || data === undefined;
};

const isEmptyOrNullObject = (data) => {
  for(var key in data){
    return false
  };
  return true;
}

const isEmptyOrInvalidString = (value) => {
  return value === null || value === undefined || value.split(/\s+/).join('') === ""
}

const isEmptyOrInvalidArray = (value) => {
  return value === null || value === undefined || value.length === 0
}

module.exports = { isNullOrUndefined, isEmptyOrNullObject, isEmptyOrInvalidString, isEmptyOrInvalidArray };
