import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";
// react component for creating dynamic tables
import ReactTable from "react-table";
import { CircleSpinner } from "react-spinners-kit";
import Select from "react-select";
import EjecutarApi from "../Consultas/Consultas";
import Notifications, { notify } from "react-notify-toast";
import { RotateSpinner, ImpulseSpinner } from "react-spinners-kit";

import { getVars } from "../GlobalStorage";

import moment from "moment";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Sidebar from "../components/Sidebar";
import FixedPlugin from "../components/PluginFilterVales";
import ModalRecepcionVale from "../components/ModalRecepcionVale";
import Pagination from "../components/pagination";

const ValesRecepcionAdd = (props) => {
  //const [flag, setFlag] = useState(false);
  const [expediente, setExpediente] = useState({
    Ejercicio: { value: moment().format("Y"), label: moment().format("Y") },
    Folio: "",
    CURP: "",
    NombreCompleto: "",
    Responsable: "",
    TipoBusqueda: { value: 1, label: "Folio" },
  });
  const [loading, setLoading] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [expedientes, setExpedientes] = useState([]);
  const [itemExpediente, setItemExpediente] = useState(null);

  const token = getVars("Token");

  useEffect(() => {
    console.log("=>expediente: ", expediente);
  }, [expediente]);

  const handleMiniClick = () => {
    document.body.classList.toggle("sidebar-mini");
  };

  const btnRecepcionExpediente = (item) => {
    console.log("=>Recepcionar Expediente: ", item);
    setItemExpediente(item);
    setModalShow(true);
  };

  const btnBuscar = (e) => {
    e.preventDefault();

    setLoading(true);

    if (expediente.Folio.length < 1) {
      setLoading(false);

      notify.show(
        `Error: ${expediente.TipoBusqueda.label} es requerido`,
        "custom",
        5000,
        {
          background: "red",
          fontSize: 14,
          text: "#FFFFFF",
        }
      );
      return true;
    }

    const filtros = [];

    if (expediente.TipoBusqueda.value === 2) {
      filtros.push({ id: "vales.CURP", value: expediente.Folio });
    }

    const data = {
      page: 0,
      tipo: "and",
      pageSize: 1000,
      Ejercicio: expediente.Ejercicio.value,
      Folio: expediente.TipoBusqueda.value === 1 ? expediente.Folio : "",
      NombreCompleto:
        expediente.TipoBusqueda.value === 3 ? expediente.Folio : "",
      sorted: [],
      filtered: filtros,
    };

    console.log("=>getValesV2", JSON.stringify(data));

    EjecutarApi(data, "getValesV2", token.token)
      .then(function (res) {
        console.log("=>getValesV2 RS: ", res);
        setLoading(false);

        if (res.results) {
          if (res.data.length === 0) {
            notify.show(`No se encontraron resultados`, "custom", 5000, {
              background: "orange",
              text: "#FFFFFF",
            });
          } else {
            setExpedientes(
              res.data.map((prop, key) => {
                console.log("=>prop: ", prop);

                return {
                  id: prop.id,
                  CURP: prop.CURP,
                  Solicitante:
                    prop.isDocumentacionEntrega === 0 ? (
                      <Button
                        color="info"
                        size="sm"
                        onClick={() => btnRecepcionExpediente(prop)}
                      >
                        <span className="btn-label">
                          <i className="nc-icon nc-ruler-pencil" />
                        </span>
                        {prop.Nombre} {prop.Paterno} {prop.Materno}
                      </Button>
                    ) : (
                      `${prop.Nombre} ${prop.Paterno} ${prop.Materno}`
                    ),
                  Region: prop.idMunicipio.Region,
                  Municipio: prop.idMunicipio.Municipio,
                  Folio: prop.ClaveUnica,
                  FechaSolicitud: prop.FechaSolicitud,
                  CURP: prop.CURP,
                  Responsable: `${prop.UserOwned.Nombre} ${prop.UserOwned.Paterno} ${prop.UserOwned.Materno}`,
                  FechaRecepcionExpediente: prop.FechaDocumentacion,
                  Recepciono: prop.UserDocumentacion,
                  Remesa: prop.Remesa,
                };
              })
            );
          }
        } else {
          notify.show(`No se encontraron resultados`, "custom", 5000, {
            background: "orange",
            text: "#FFFFFF",
          });
        }
      })
      .catch((e) => {
        console.log("=>getValesV2 Error: ", e);
        setLoading(false);
        notify.show(`Error al cargar getValesV2`, "custom", 5000, {
          background: "#d61818",
          text: "#FFFFFF",
        });
      });
  };

  return (
    <>
      <div className="wrapper">
        <Sidebar {...props} bgColor="black" activeColor="info" />
        <div className="main-panel">
          <Navbar {...props} handleMiniClick={handleMiniClick} />
          <Notifications />
          <div className="content">
            <Row>
              <Col md="12">
                <Card>
                  <CardHeader>
                    <Form
                      className="form-horizontal"
                      id="TypeValidation"
                      onSubmit={btnBuscar}
                    >
                      <Row>
                        <Col sm="2">
                          <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            id="municipio"
                            value={expediente.Ejercicio}
                            onChange={(event) => {
                              setExpediente({
                                ...expediente,
                                Ejercicio: event,
                              });
                              //console.log('=>expediente: ', event);
                              //setNomMunicipio(event.label)
                            }}
                            options={[
                              { value: 2022, label: "2022" },
                              { value: 2021, label: "2021" },
                              { value: 2020, label: "2020" },
                            ]}
                            placeholder="Buscar Por"
                          />
                        </Col>
                        <Col sm="4">
                          <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            id="municipio"
                            value={expediente.TipoBusqueda}
                            onChange={(event) => {
                              setExpediente({
                                ...expediente,
                                TipoBusqueda: event,
                              });
                              //console.log('=>expediente: ', event);
                              //setNomMunicipio(event.label)
                            }}
                            options={[
                              { value: 1, label: "Folio" },
                              { value: 2, label: "CURP" },
                              { value: 3, label: "Nombre" },
                            ]}
                            placeholder="Buscar Por"
                          />
                        </Col>
                        <Col sm="4">
                          <Input
                            id="Folio"
                            type="text"
                            placeholder={`Escriba ${expediente.TipoBusqueda.label}`}
                            value={expediente.Folio}
                            onChange={(event) =>
                              setExpediente({
                                ...expediente,
                                Folio: event.target.value.toUpperCase(),
                              })
                            }
                          />
                        </Col>
                        <Col sm="4">
                          {!loading && (
                            <Button color="primary" type="submit">
                              {loading ? (
                                <span>Buscando..</span>
                              ) : (
                                <span>Buscar</span>
                              )}
                            </Button>
                          )}
                          {loading && (
                            <ImpulseSpinner
                              color={"#1261ff"}
                              size={30}
                              loading={loading}
                            />
                          )}
                        </Col>
                      </Row>
                    </Form>
                  </CardHeader>
                  <CardBody>
                    <ReactTable
                      size="sm"
                      data={expedientes}
                      noDataText="No Existen Datos a Mostrar!"
                      //filerable
                      manual
                      columns={[
                        {
                          Header: "R",
                          headerStyle: { fontSize: "10px" },
                          minWidth: 40,
                          id: "Region",
                          accessor: (d) => d.Region,
                          filterAll: false,
                          show: token.regiones.length > 0 ? true : false,
                        },
                        {
                          Header: "Municipio",
                          headerStyle: { fontSize: "10px" },
                          minWidth: 80,
                          id: "Municipio",
                          accessor: (d) => d.Municipio,
                          filterAll: false,
                          style: {
                            textAlign: "center",
                            fontSize: "11px",
                          },
                        },

                        {
                          Header: "Folio",
                          headerStyle: { fontSize: "10px" },
                          minWidth: 70,
                          id: "Folio",
                          accessor: (d) => d.Folio,
                          filterAll: false,
                          style: {
                            textAlign: "center",
                            fontSize: "11px",
                          },
                        },
                        {
                          Header: "Solicitud",
                          headerStyle: { fontSize: "10px" },
                          minWidth: 80,
                          id: "FechaSolicitud",
                          accessor: (d) => d.FechaSolicitud,
                          filterAll: false,
                          style: {
                            textAlign: "center",
                            fontSize: "11px",
                          },
                        },
                        {
                          Header: "CURP",
                          headerStyle: { fontSize: "10px" },
                          minWidth: 130,
                          id: "CURP",
                          accessor: (d) => d.CURP,
                          filterAll: false,
                          style: {
                            textAlign: "center",
                            fontSize: "11px",
                          },
                        },
                        {
                          Header: "Solicitante",
                          headerStyle: { fontSize: "10px" },
                          minWidth: 180,
                          id: "Solicitante",
                          accessor: (d) => d.Solicitante,
                          filterAll: false,
                          style: {
                            textAlign: "center",
                            fontSize: "10px",
                          },
                        },
                        {
                          Header: "Recepción",
                          headerStyle: { fontSize: "10px" },
                          minWidth: 80,
                          id: "FechaRecepcionExpediente",
                          accessor: (d) => d.FechaRecepcionExpediente,
                          filterAll: false,
                          style: {
                            textAlign: "center",
                            fontSize: "10px",
                          },
                        },
                        {
                          Header: "Recepciono",
                          headerStyle: { fontSize: "10px" },
                          minWidth: 120,
                          id: "Recepciono",
                          accessor: (d) => d.Recepciono,
                          filterAll: false,
                          style: {
                            textAlign: "center",
                            fontSize: "10px",
                          },
                        },
                        {
                          Header: "Remesa",
                          headerStyle: { fontSize: "10px" },
                          minWidth: 80,
                          id: "Remesa",
                          accessor: (d) => d.Remesa,
                          filterAll: false,
                          style: {
                            textAlign: "center",
                            fontSize: "10px",
                          },
                        },
                        {
                          Header: "Responsable",
                          headerStyle: { fontSize: "10px" },
                          minWidth: 80,
                          id: "Responsable",
                          accessor: (d) => d.Responsable, //,Capturo, Articulador, FechaCaptura
                          filterAll: false,
                          style: {
                            textAlign: "center",
                            fontSize: "10px",
                          },
                        },
                      ]}
                      className="-striped -highlight primary-pagination"
                      loading={loading}
                      showPagination={true}
                      showPaginationBottom={true}
                      showTotalPages={true}
                      showPageJump={false}
                      canNextFromData={true}
                      defaultPageSize={10}
                      total={expedientes.length}
                      pages={Math.floor(expedientes.length / 10)}
                      previousText="Anterior"
                      nextText="Siguiente"
                      ofText="de"
                      pageSizeOptions={[10, 20, 25, 50, 100]}
                      PaginationComponent={Pagination}
                    />
                    {loading && (
                      <CircleSpinner
                        className="text-center"
                        size={30}
                        color="#686769"
                        loading={loading}
                      />
                    )}
                    <ModalRecepcionVale
                      show={modalShow}
                      item={itemExpediente}
                      onHide={() => setModalShow(false)}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>

          <Footer fluid />
        </div>
      </div>
    </>
  );
};
export default ValesRecepcionAdd;
