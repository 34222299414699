import { URL_GENERAL } from "../data/consts";
import { isNullOrUndefined } from "../utils/emptyValidations";
var URL_ = URL_GENERAL;

const EjecutarApi = (data, api, token, method = "POST") => {
  return new Promise((resolve, reject) => {
    var requestInfo = {
      method: "POST",
      body: JSON.stringify(data),
      headers: new Headers({
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      }),
    };

    if (method === "GET") {
      requestInfo = {
        method: "GET",
        headers: new Headers({
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        }),
      };

      fetch(URL_ + api, requestInfo)
        .then((response) => {
          return response.json();
        })
        .then((dataRS) => {
          if (!dataRS.success) {
            const rs = {
              results: false,
              data: {},
              error: dataRS.error,
            };
            reject(rs);
          } else {
            resolve(dataRS);
          }
        })
        .catch((e) => {
          const rs = {
            results: false,
            data: {},
            message: e.message,
          };
          reject(rs);
        });
    } else {
      //console.log(token);
      fetch(URL_ + api, requestInfo)
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          throw new Error(`Ocurrio un error: ${api}: ${response.statusText}`);
        })
        .then((dataRS) => {
          if (!dataRS.success) {
            var messageTemporal = "";
            if (dataRS.errors.SerieInicial) {
              messageTemporal = "La Serie Inicial ya fue usada";
            }
            if (dataRS.errors.SerieFinal) {
              messageTemporal = "La Serie Final ya fue usada";
            }
            if (dataRS.errors.idSolicitud) {
              messageTemporal = "La Solicitud ya fue registrada";
            }
            if (dataRS.errors.CodigoBarrasInicial) {
              messageTemporal = "El código de barra inicial ya fue usado";
            }
            if (dataRS.errors.CodigoBarrasFinal) {
              messageTemporal = "El código de barra final ya fue usado";
            }
            const rs = {
              results: false,
              data: {},
              errors: dataRS.errors.CURP
                ? "¡La CURP ya esta registrada!"
                : dataRS.errors,
              message:
                messageTemporal !== "" ? messageTemporal : dataRS.message,
            };
            reject(rs);
          } else if (dataRS.results) {
            resolve(dataRS);
          } else {
            console.log("dataRS", dataRS);
            const rs = {
              results: false,
              data: {},
              errors: dataRS.errors,
              message: dataRS.message,
            };
            reject(rs);
          }
        })
        .catch((e) => {
          console.log("==>Error: " + api + ": ", e);

          const rs = {
            results: false,
            data: {},
            message: e.message,
          };
          reject(rs);
        });
    }
  });
};

export const uploadFiles = ({ api, data, token, method = "POST" }) => {
  return new Promise((resolve, reject) => {
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      createFormData(formData, key, data[key]);
    });
    fetch(URL_ + api, {
      method: method,
      body: formData,
      headers: new Headers({
        Authorization: `Bearer ${token}`,
      }),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error(`Ocurrio un error: ${api}: ${response.statusText}`);
      })
      .then((dataRS) => {
        if (dataRS.success) {
          resolve(dataRS);
        } else {
          reject({
            results: false,
            data: {},
            error: dataRS.error,
          });
        }
      })
      .catch((error) => {
        reject({
          results: false,
          data: {},
          error: error.message,
        });
      });
  });
};

function createFormData(formData, key, data) {
  //   if (
  //     (data === Object(data) && !(data instanceof File)) ||
  //     Array.isArray(data)
  //   ) {
  //     for (var i in data) {
  //       createFormData(formData, key + "[" + i + "]", data[i]);
  //     }
  //   } else {
  data = isNullOrUndefined(data) ? null : data;
  if (Array.isArray(data)) {
    data.forEach((element) => {
      if (typeof element === "object" && !isNullOrUndefined(element.id))
        element = JSON.stringify(element);
      formData.append(`${key}[]`, element);
    });
    // formData.append(`${key}[]`, data);
  } else {
    formData.append(key, data);
  }
  //   }
}

export default EjecutarApi;
