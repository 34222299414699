import React, { useState, useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
} from "reactstrap";
import swal from "sweetalert";
import ReactTable from "react-table";
import { CircleSpinner } from "react-spinners-kit";
import EjecutarApi from "../../Consultas/Consultas";
import Notifications from "react-notify-toast";
import { getVars } from "../../GlobalStorage";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import Pagination from "../../components/pagination";
import Sidebar from "../../components/Sidebar";
import PluginFilterValesAd from "../../components/PluginFilterValesAd";
import { isNullOrUndefined } from "../../utils/emptyValidations";
import FileListAd from "../../components/Archivos/FileListAd";
import SolicitudVale from "../SolicitudVale";

const ValesAuditoria = (props) => {
  const [userData, setUserData] = useState();
  const [menu, setMenu] = useState({
    Ver: 0,
    Agregar: 0,
    Editar: 0,
    Eliminar: 0,
    Seguimiento: 0,
    Exportar: 0,
    Imprimir: 0,
    ViewAll: 0,
    idTipoUser: 0,
  });
  const history = useHistory();
  const [total, setTotal] = useState(0);
  const [loadingCedulaList, setLoadingCedulaList] = useState(false);
  const [dataCedulaList, setDataCedulaList] = useState([]);
  const [menuLoaded, setMenuLoaded] = useState(false);
  const [isLoadingArchivos, setIsLoadingArchivos] = useState(false);
  const [archivos, setArchivos] = useState(false);
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState({});
  //Filtro
  const [filtro, setFiltro] = useState([]);
  const [pageOptions, setPage] = useState({
    page: 0,
    pageSize: 10,
  });

  const [isSolicitud, setIsSolicitud] = useState(false);
  const [solicitud, setSolicitud] = useState(null);

  const columns = [
    {
      Header: "Acciones",
      minWidth: 100,
      id: "Acciones",
      accessor: (d) => d.Acciones,
      className: "text-center",
      filterAll: false,
      style: { fontSize: "10px" },
      headerStyle: { fontSize: "10px" },
    },
    {
      Header: "Ejercicio",
      minWidth: 80,
      id: "Ejercicio",
      accessor: (d) => d.Ejercicio,
      filterAll: false,
      style: { fontSize: "10px" },
      headerStyle: { fontSize: "10px" },
    },
    {
      Header: "Folio",
      minWidth: 80,
      id: "FolioSolicitud",
      accessor: (d) => d.FolioSolicitud,
      filterAll: false,
      style: { fontSize: "10px" },
      headerStyle: { fontSize: "10px" },
    },
    {
      Header: "Nombre Completo",
      minWidth: 200,
      id: "NombreCompleto",
      accessor: (d) => d.NombreCompleto,
      filterAll: false,
      style: { fontSize: "10px" },
      headerStyle: { fontSize: "10px" },
    },
    {
      Header: "CURP",
      minWidth: 120,
      id: "CURP",
      accessor: (d) => d.CURP,
      filterAll: false,
      style: { fontSize: "10px" },
      headerStyle: { fontSize: "10px" },
    },
    {
      Header: "Región",
      minWidth: 70,
      id: "RegionM",
      accessor: (d) => d.Region,
      filterAll: false,
      style: { fontSize: "10px" },
      headerStyle: { fontSize: "10px" },
    },
    {
      Header: "Municipio",
      minWidth: 80,
      id: "Municipio",
      accessor: (d) => d.Municipio,
      filterAll: false,
      style: { fontSize: "10px" },
      headerStyle: { fontSize: "10px" },
    },
    // {
    //   Header: "Localidad",
    //   minWidth: 80,
    //   id: "Localidad",
    //   accessor: (d) => d.Localidad,
    //   filterAll: false,
    //   style: { fontSize: "10px" },
    //   headerStyle: { fontSize: "10px" },
    // },
    // {
    //   Header: "Serie Inicial",
    //   minWidth: 85,
    //   id: "SerieInicial",
    //   accessor: (d) => d.SerieInicial,
    //   filterAll: false,
    //   style: { fontSize: "10px" },
    //   headerStyle: { fontSize: "10px" },
    // },
    // {
    //   Header: "Serie Final",
    //   minWidth: 110,
    //   id: "SerieFinal",
    //   accessor: (d) => d.SerieFinal,
    //   filterAll: false,
    //   style: { fontSize: "10px" },
    //   headerStyle: { fontSize: "10px" },
    // },
  ];

  useEffect(() => {
    if (isNullOrUndefined(userData)) return;
    const menus = userData.menu;
    const currentMenu = menus.find((menu) => menu.idMenu === 38);
    if (isNullOrUndefined(currentMenu) || currentMenu.Ver < 1) {
      swal({
        title: "Atención",
        text: "No tiene permisos para este módulo, contacte al administrador",
        icon: "warning",
      });
      history.push("/not-found");
      return;
    }
    setMenu({
      Ver: currentMenu.Ver,
      Agregar: currentMenu.Agregar,
      Editar: currentMenu.Editar,
      Eliminar: currentMenu.Eliminar,
      Seguimiento: currentMenu.Seguimiento,
      Exportar: currentMenu.Exportar,
      Imprimir: currentMenu.Imprimir,
      ViewAll: currentMenu.ViewAll,
      idTipoUser: userData.user.idTipoUser.id,
    });
    setMenuLoaded(true);
  }, [userData, history]);

  const handleMiniClick = () => {
    document.body.classList.toggle("sidebar-mini");
  };

  useEffect(() => {
    setUserData(getVars("Token"));
  }, []);

  const handleShowFiles = (solicitud) => {
    setIsModalOpened(true);
    setIsLoadingArchivos(true);
    setSelectedRecord(solicitud);
    const token = getVars("Token");
    EjecutarApi(solicitud, `vales/getFilesValesAd`, token.token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.results && res.success) {
          setArchivos(res.data.Archivos);
        } else {
          setIsModalOpened(false);
          swal({
            title: "¡Atención!",
            text: `${res.message}`,
            icon: "info",
          });
        }
        setIsLoadingArchivos(false);
      })
      .catch((error) => {
        setIsLoadingArchivos(false);
        setIsModalOpened(false);
        swal({
          title: "¡Atención!",
          text: `${error.message}`,
          icon: "info",
        });
      });
  };

  const fetchData = useCallback(
    ({ pageSize, page, sorted, params }) => {
      const data = {
        page: page > pageOptions.page ? page : pageOptions.page,
        pageSize: pageSize,
        programa: 1,
        filtered: filtro,
      };
      setPage({
        page: page,
        pageSize: pageSize,
      });
      getRegistros(data);
    },
    [menu, filtro]
  );

  const getRegistros = (data) => {
    const token = getVars("Token");
    setLoadingCedulaList(true);
    data.filtered = filtro;
    EjecutarApi(data, "vales/getQ3450", token.token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.results && res.success) {
          setDataCedulaList(
            res.data.map((item) => {
              if (item.Paterno == null) item.Paterno = "";
              if (item.Materno == null) item.Materno = "";
              return {
                ...item,
                NombreCompleto: `${item.Nombre} ${item.Paterno} ${item.Materno}`,
                Acciones: (
                  <>
                    {/* <Row>
                      <Col>
                        <Button
                          color="info"
                          size="sm"
                          style={{ width: "100%" }}
                          onClick={() => sendDataSolicitud(item)}
                        >
                          <i className="fa fa-file-archive-o fa-xs"></i>
                          Solicitud
                        </Button>
                      </Col>
                    </Row> */}
                    <Row>
                      <Col>
                        <Button
                          color="warning"
                          size="sm"
                          style={{ width: "100%" }}
                          onClick={() => handleShowFiles(item)}
                        >
                          <i className="fa fa-file-archive-o fa-xs"></i>
                          Archivos
                        </Button>
                      </Col>
                    </Row>
                  </>
                ),
              };
            })
          );
          setTotal(res.total);
        } else {
          swal({
            title: "¡Atención!",
            text: `${res.message}`,
            icon: "info",
          });
        }
        setLoadingCedulaList(false);
      })
      .catch((e) => {
        setLoadingCedulaList(false);
        swal({
          title: "¡Atención!",
          text: `${e.message}`,
          icon: "warning",
        });
      });
  };

  const sendDataSolicitud = (item) => {
    setSolicitud(item);
    setIsSolicitud(!isSolicitud);
  };

  const setFilter = (filtered) => {
    setFiltro(filtered.filtered);
    const token = getVars("Token");
    setLoadingCedulaList(true);
    EjecutarApi(filtered, "vales/getQ3450", token.token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.results && res.success) {
          setDataCedulaList(
            res.data.map((item) => {
              if (item.Paterno == null) item.Paterno = "";
              if (item.Materno == null) item.Materno = "";
              return {
                ...item,
                NombreCompleto: `${item.Nombre} ${item.Paterno} ${item.Materno}`,
                Acciones: (
                  <>
                    {/* <Row>
                      <Col>
                        <Button
                          color="info"
                          size="sm"
                          style={{ width: "100%" }}
                          onClick={() => sendDataSolicitud(item)}
                        >
                          <i className="fa fa-file-archive-o fa-xs"></i>
                          Solicitud
                        </Button>
                      </Col>
                    </Row> */}
                    <Row>
                      <Col>
                        <Button
                          color="warning"
                          size="sm"
                          style={{ width: "100%" }}
                          onClick={() => handleShowFiles(item)}
                        >
                          <i className="fa fa-file-archive-o fa-xs"></i>
                          Archivos
                        </Button>
                      </Col>
                    </Row>
                  </>
                ),
              };
            })
          );
          setTotal(res.total);
        } else {
          swal({
            title: "¡Atención!",
            text: `${res.message}`,
            icon: "info",
          });
        }
        setLoadingCedulaList(false);
      })
      .catch((e) => {
        setLoadingCedulaList(false);
        swal({
          title: "¡Atención!",
          text: `${e.message}`,
          icon: "warning",
        });
      });
  };

  return (
    <>
      <div className="wrapper">
        <Sidebar {...props} bgColor="black" activeColor="info" />
        <div className="main-panel">
          <Navbar {...props} handleMiniClick={handleMiniClick} />
          <Notifications />
          <div className="content">
            {!isSolicitud ? (
              <>
                <Modal
                  size="xl"
                  isOpen={isModalOpened}
                  toggle={() => setIsModalOpened(!isModalOpened)}
                >
                  <ModalHeader>Archivos</ModalHeader>
                  <ModalBody>
                    {isLoadingArchivos && <Spinner />}
                    {!isLoadingArchivos && <FileListAd files={archivos} />}
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      size="sm"
                      color="danger"
                      disabled={isLoadingArchivos}
                      onClick={() => setIsModalOpened(false)}
                    >
                      Cerrar
                    </Button>
                  </ModalFooter>
                </Modal>{" "}
                <Row>
                  <Col md="12">
                    <Card>
                      <CardHeader>
                        <Row>
                          <Col md="12">
                            <CardTitle tag="h6">
                              Listado de Solicitudes
                            </CardTitle>
                          </Col>
                        </Row>
                      </CardHeader>
                      <CardBody>
                        {loadingCedulaList && (
                          <CircleSpinner
                            className="text-center"
                            size={30}
                            color="#686769"
                            loading={loadingCedulaList}
                          />
                        )}
                        <Col lg="12" md="12">
                          {menuLoaded && dataCedulaList && (
                            <ReactTable
                              size="sm"
                              data={dataCedulaList}
                              noDataText="No Existen Datos a Mostrar!"
                              manual
                              columns={columns}
                              className="-striped -highlight primary-pagination"
                              loading={loadingCedulaList}
                              showPagination={true}
                              showPaginationBottom={true}
                              showTotalPages={true}
                              showPageJump={false}
                              canNextFromData={true}
                              defaultPageSize={pageOptions.pageSize}
                              total={total}
                              page={pageOptions.page}
                              pages={Math.floor(total / pageOptions.pageSize)}
                              previousText="Anterior"
                              nextText="Siguiente"
                              ofText="de"
                              pageSizeOptions={[10, 20, 25, 50, 100]}
                              PaginationComponent={Pagination}
                              onFetchData={fetchData}
                            />
                          )}
                        </Col>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                <PluginFilterValesAd sendFiltro={setFilter} />
                <Footer fluid />
              </>
            ) : (
              <SolicitudVale
                data={solicitud}
                back={() => {
                  setIsSolicitud(!isSolicitud);
                }}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default ValesAuditoria;
