import React, { useEffect, useState } from "react";
import {
  Button,
  Label,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Card,
  CardBody,
} from "reactstrap";

import Select from "react-select";
import Switch from "react-bootstrap-switch";
import EjecutarApi from "../Consultas/Consultas";
import { RotateSpinner, ImpulseSpinner } from "react-spinners-kit";
import { notify } from "react-notify-toast";
import swal from "sweetalert";

import ReactDatetime from "react-datetime";
import moment, { now } from "moment";
import { getVars } from "../GlobalStorage";
import ReactTable from "react-table";
import ReactBSAlert from "react-bootstrap-sweetalert";

function ModalComercioUpdate({ show, onHide, api_ }) {
  var api = api_ ? api_ : {};
  const [flag, setFlag] = useState(false);
  const [municipios, setMunicipios] = useState([]);
  const [giros, setGiros] = useState([]);
  const [tipos, setTipos] = useState([]);
  const [colonias, setColonias] = useState([]);
  const [otraColonia, setOtraColonia] = useState("");
  const [nomMunicipio, setNomMunicipio] = useState("");
  const [status, setStatus] = useState([]);
  const token = getVars("Token");
  const [data, setData] = useState([]);
  const [totalColonias, setTotalColonias] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isvisibleEstatus, setisvisibleEstatus] = useState(true); //True es no puede editar // false puede editar
  const [AlertaMensaje, setAlertaMensaje] = useState("");
  const [idEstatusCambio, setidEstatusCambio] = useState(0);
  const [flagAlertaMensaje2, setflagAlertaMensaje2] = useState(false);
  const [flagAlertaMensajeBaja, setflagAlertaMensajeBaja] = useState(false);
  const [flagEscogerColonia, setFlagEscogerColonia] = useState(false);
  const [_menu, _setmenu] = useState({});
  const [_menuidTipoUser, _setmenuidTipoUser] = useState(0);
  const [_isTipoUserAdmin, _setisTipoUserAdmin] = useState(false);
  const [msgErrorAlerta, setmsgErrorAlerta] = useState("");
  const [flagmsgErrorAlerta, setflagmsgErrorAlerta] = useState(false);
  const [Refrendo2021, setRefrendo2021] = useState({
    value: api.Refrendo2021,
    label: api.Refrendo2021,
  });
  const [filterRefrendo, setfilterRefrendo] = useState([
    { value: "", label: "Sin Refrendo" },
    { value: "2021", label: "2021" },
    { value: "2022", label: "2022" },
    { value: "2023", label: "2023" },
    { value: "2024", label: "2024" },
  ]);

  const [showDatosTarjeta, setShowDatosTarjeta] = useState(
    api.QuiereTransferencia == 1 ? true : false
  );
  const [comercio, setComercio] = useState({
    id: api.id ? api.id : "",
    RFC: api.RFC ? api.RFC : "",
    Refrendo2021: api.Refrendo2021 ? api.Refrendo2021 : "",
    NombreEmpresa: api.NombreEmpresa ? api.NombreEmpresa : "",
    Nombre: api.Nombre ? api.Nombre : "",
    Paterno: api.Paterno ? api.Paterno : "",
    Materno: api.Materno ? api.Materno : "",
    TelNegocio: api.TelNegocio ? api.TelNegocio : "",
    TelCasa: api.TelCasa ? api.TelCasa : "",
    Celular: api.Celular ? api.Celular : "",
    idMunicipio: api.idMunicipio ? api.idMunicipio : "",
    Municipio: api.Municipio ? api.Municipio : "",
    CP: api.CP ? api.CP : "",
    Calle: api.Calle ? api.Calle : "",
    NumInt: api.NumInt ? api.NumInt : "",
    NumExt: api.NumExt ? api.NumExt : "",
    Colonia: api.Colonia ? api.Colonia : "",
    Correo: api.Correo ? api.Correo : "",
    Latitude: api.Latitude ? api.Latitude : "",
    Longitude: api.Longitude ? api.Longitude : "",
    FechaInscripcion: api.FechaInscripcion ? api.FechaInscripcion : "",
    QuiereTransferencia:
      api.QuiereTransferencia === 1
        ? true
        : api.QuiereTransferencia === true
        ? true
        : false,
    idStatus: api.idStatus ? api.idStatus : 1,
    Estatus: api.Estatus ? api.Estatus : "",
    ClaveUnica: api.ClaveUnica ? api.ClaveUnica : "",
    HorarioAtencion: api.HorarioAtencion ? api.HorarioAtencion : "",
    idTipoNegocio: api.idTipoNegocio ? api.idTipoNegocio : "",
    TipoGiro: api.TipoGiro ? api.TipoGiro : "",
    Giros: api.Giros.map((giro) => {
      return giro.idGiro;
    }),

    Cobrador:
      api.Cobradores.length !== 0
        ? api.Cobradores[0]
        : {
            id: null,
            CURP: "",
            Nombre: "",
            Paterno: "",
            Materno: "",
            idStatus: 1,
          },
    Banco: api.Banco ? api.Banco : "",
    CLABE: api.CLABE ? api.CLABE : "",
    NumTarjeta: api.NumTarjeta ? api.NumTarjeta : "",
    QuiereTransferencia: api.QuiereTransferencia ? api.QuiereTransferencia : "",
  });
  const [girosBase, setGirosBase] = useState(api.Giros ? api.Giros : "");

  //console.log("API", api);
  const datos = {
    page: 0,
    tipo: "and",
    pageSize: 1000,
    sorted: [],
    filtered: [],
  };
  const datosColonia = {
    page: 0,
    tipo: "and",
    pageSize: 9999,
    sorted: [],
    filtered: [
      {
        id: "d_codigo",
        value: comercio.CP,
      },
      {
        id: "D_mnpio",
        value: nomMunicipio,
      },
    ],
  };

  const Spinenerstyle = {
    height: "50pt",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  useEffect(() => {
    console.log("=>comercio: ", comercio);
    console.log("=>api: ", api);
  }, [api, comercio]);

  useEffect(() => {
    //verificando permisos de Menus
    console.log("-->en Modal: token", token);
    token.menu.forEach((prop, key) => {
      if (prop.Menu === "Comercios") {
        _setmenu({
          Ver: prop.Ver,
          Agregar: prop.Agregar,
          Editar: prop.Editar,
          Eliminar: prop.Eliminar,
          Seguimiento: prop.Seguimiento,
          Exportar: prop.Exportar,
          Imprimir: prop.Imprimir,
          idTipoUser: token.user.idTipoUser.id,
        });
      }
    });
    // console.log('-->token',token.user.idTipoUser.id);
    _setmenuidTipoUser(token.user.idTipoUser.id);

    //TipoUsuario==3 y Status ==1 puede editar
    if (comercio.idStatus == 1 && token.user.idTipoUser.id == 3) {
      setisvisibleEstatus(false);
      setComercio({ ...comercio, idStatus: 2 });
    } //True es no puede editar // false puede editar
    else if (comercio.idStatus == 1 && token.user.idTipoUser.id != 1) {
      setisvisibleEstatus(false);
    }

    //si el usuario es administrador entonces podrá editar y visualizar los botones

    const _datosColonia = {
      page: 0,
      tipo: "and",
      pageSize: 999,
      sorted: [],
      filtered: [
        {
          id: "d_codigo",
          value: comercio.CP,
        },
        {
          id: "D_mnpio",
          value: comercio.Municipio,
        },
      ],
    };
    EjecutarApi(_datosColonia, "getCP", token.token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.results) {
          //setData(res.data);
          var colonias_ = res.data.map((cp) => {
            return {
              value: cp["d_asenta"],
              label: cp["d_asenta"],
            };
          });

          colonias_.push({
            value: "otro",
            label: "otro",
          });
          setColonias(colonias_);
        }
      })
      .catch((e) => {
        //console.log('Catch1');
        notify.show(`Error en la busqueda! ${e.message}`, "custom", 5000, {
          background: "#d61818",
          text: "#FFFFFF",
        });
      });

    EjecutarApi(datos, "getMunicipiosET", token.token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.results) {
          setMunicipios(
            res.data.map((municipio) => {
              return {
                value: municipio["Id"],
                label: municipio["Nombre"],
              };
            })
          );
        }
      })
      .catch((e) => {
        //console.log('Catch2');
        notify.show(`Error en la busqueda! ${e.message}`, "custom", 5000, {
          background: "#d61818",
          text: "#FFFFFF",
        });
      });

    EjecutarApi(datos, "getStatus", token.token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.results) {
          //setData(res.data);
          setStatus(
            res.data.map((status_) => {
              return {
                value: status_["id"],
                label: status_["Estatus"],
              };
            })
          );
        }
      })
      .catch((e) => {
        //console.log('Catch3',e);
        notify.show(`Error en la busqueda! ${e.message}`, "custom", 5000, {
          background: "#d61818",
          text: "#FFFFFF",
        });
      });

    EjecutarApi(datos, "getNegociosTipo", token.token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.results) {
          //setData(res.data);
          setTipos(
            res.data.map((tipo) => {
              return {
                value: tipo["id"],
                label: tipo["Tipo"],
              };
            })
          );
        }
      })
      .catch((e) => {
        //console.log('Catch4');
        notify.show(`Error en la busqueda! ${e.message}`, "custom", 5000, {
          background: "#d61818",
          text: "#FFFFFF",
        });
      });

    EjecutarApi(datos, "getGiros", token.token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.results) {
          //setData(res.data);
          setGiros(
            res.data.map((giro_) => {
              return {
                value: giro_["id"],
                label: giro_["Giro"],
              };
            })
          );
        }
      })
      .catch((e) => {
        //console.log('Catch5');
        notify.show(`Error en la busqueda! ${e.message}`, "custom", 5000, {
          background: "#d61818",
          text: "#FFFFFF",
        });
      });
  }, []);
  //comercio, datos, token.menu, token.token, token.user.idTipoUser.id
  // console.log ('Permisos 1--> idtipoUser',token.user.idTipoUser.id);
  // console.log ('Permisos 1 - isvisibleEstatus',isvisibleEstatus);
  // console.log ('Permisos 1 - comercio.idStatus',comercio.idStatus);
  // console.log ('Permisos 1 - Menu-editar',_menu.Editar);

  const coloniasChange = () => {
    EjecutarApi(datosColonia, "getCP", token.token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.results) {
          //setData(res.data);
          var colonias_ = res.data.map((cp) => {
            return {
              value: cp["d_asenta"],
              label: cp["d_asenta"],
            };
          });

          colonias_.push({
            value: "otro",
            label: "otro",
          });
          setColonias(colonias_);
        }
      })
      .catch((e) => {
        notify.show(`Error en la busqueda! ${e.message}`, "custom", 5000, {
          background: "#d61818",
          text: "#FFFFFF",
        });
      });
  };

  const showAlertBajaComercio = () => {
    return;
  };

  const UpdateNegocioBaja = () => {
    console.log("=>Actualizar comercio");
    const objectUpdateComercio = {
      id: comercio.id,
    };

    console.log(
      "=>Para actualziar Comercio: ",
      JSON.stringify(objectUpdateComercio)
    );
    console.log("=>token: ", token.token);

    showAlertBajaComercio();
  };

  const botonBajaComercio = () => {
    //const data={id:comercio.id, idStatus: Estatus};

    const objectUpdateBajaComercio = {
      id: comercio.id,
      idStatus: 4,
    };

    setFlag(true);

    EjecutarApi(objectUpdateBajaComercio, "updateBajaNegocios", token.token)
      .then(function (res) {
        console.log("=> MODAL COMERCIO UPDATE RESPUESTA", res.data);

        setFlag(false);
        onHide();
        notify.show("Registro Exitoso!", "custom", 5000, {
          background: "#18c97d",
          text: "#FFFFFF",
        });

        return res;
      })
      .catch((e) => {
        setFlag(false);
        notify.show(
          `Error en no se logro el registro! ${e.errors}`,
          "custom",
          5000,
          {
            background: "#d61818",
            text: "#FFFFFF",
          }
        );
      });
  };

  const UpdateNegocioSetRefrendo = (Refrendo) => {
    setLoading(true);
    setFlag(true);
    console.log("=>Update Refrendo: ", Refrendo);

    setRefrendo2021(Refrendo);

    const objectUpdateComercio = {
      id: comercio.id,
      Refrendo2021: Refrendo.value,
      UserRefrendo: token.user.id,
      FechaRefrendo2021: moment().format("YYYY-MM-DD"),
    };

    console.log(
      "=>Para actualziar Comercio: ",
      JSON.stringify(objectUpdateComercio)
    );
    console.log("=>token: ", token.token);
    //return true;

    EjecutarApi(objectUpdateComercio, "updateRefrendoNegocios", token.token)
      .then(function (res) {
        console.log("=>updateRefrendoNegocios", res);

        setFlag(false);
        setLoading(false);

        swal({
          title: "Actualización Exitosa!",
          text: "Se actualizo refrendo correctamente!",
          icon: "success",
        });

        // notify.show('Actualización Exitoso!', 'custom', 5000, {
        //     background: '#18c97d',
        //     text: '#FFFFFF',
        // });
        //setComercio({ ...comercio, Cobrador: comercio.Cobrador[0] });
        return res;
      })
      .catch((e) => {
        //setComercio({ ...comercio, Cobrador: comercio.Cobrador[0] });
        setFlag(false);
        setLoading(false);

        swal({
          title: "Error de actualización",
          text: "Hubo un error al actualziar refrendo!",
          icon: "warning",
        });
        // notify.show(`Error en no se logro actualizar registro! ${e.errors}`, 'custom', 5000, {
        //     background: '#d61818',
        //     text: '#FFFFFF',
        // });
      });
  };

  const UpdateNegocio = () => {
    //console.log("SI PASO: UpdateNegocio ");
    //return true;
    //setmsgErrorAlerta("Error Error");
    //setflagmsgErrorAlerta(true);

    console.log("=>Updatecomercio: ", comercio);

    //setComercio({ ...comercio, Colonia: (comercio.Colonia !== "" && comercio.Colonia !== "otro") ? comercio.Colonia : otraColonia });

    setFlag(true);
    //console.log('Imprimo para verificar Comercio-->',comercio);
    //console.log('Imprimo para verificar _PorValidar-->',_PorValidar);

    var dataToUpdate = {
      ...comercio,
      QuiereTransferencia: comercio.QuiereTransferencia ? 1 : 0,
      Colonia:
        comercio.Colonia !== "" && comercio.Colonia !== "otro"
          ? comercio.Colonia
          : otraColonia,
    };

    if (comercio.idTipoNegocio.length < 1) {
      setFlag(false);
      // setmsgErrorAlerta("La clasificacion del comercio es requerido.");
      // setflagmsgErrorAlerta(true);
      // /*notify.show('Error: ', 'custom', 5000, {
      //     background: '#0857B6',
      //     fontSize: 14,
      //     text: '#FFFFFF',
      // });*/
      swal({
        title: "Error",
        text: "La clasificacion del comercio es requerido.",
        icon: "success",
      });
      return true;
    }

    if (comercio.Giros.length < 1) {
      setFlag(false);
      // setmsgErrorAlerta("Giros es requerido.");
      // setflagmsgErrorAlerta(true);
      // /*notify.show('Error: Giros es requerido', 'custom', 5000, {
      //     background: '#0857B6',
      //     fontSize: 14,
      //     text: '#FFFFFF',
      // });*/
      swal({
        title: "Error",
        text: "Giros es requerido.",
        icon: "success",
      });
      return true;
    }

    if (comercio.NombreEmpresa.length < 1) {
      setFlag(false);
      // setmsgErrorAlerta("El Nombre del comercio es requerido.");
      // setflagmsgErrorAlerta(true);
      // /*notify.show('Error: El Nombre del comercio es requerido.', 'custom', 5000, {
      //     background: '#0857B6',
      //     fontSize: 14,
      //     text: '#FFFFFF',
      // });*/
      swal({
        title: "Error",
        text: "El Nombre del comercio es requerido.",
        icon: "success",
      });
      return true;
    }
    if (comercio.NombreEmpresa.length > 119) {
      setFlag(false);
      // setmsgErrorAlerta("El nombre del comercio es muy largo.");
      // setflagmsgErrorAlerta(true);
      // /*notify.show('Error: El nombre del comercio es muy largo.', 'custom', 5000, {
      //     background: '#0857B6',
      //     fontSize: 14,
      //     text: '#FFFFFF',
      // });*/
      swal({
        title: "Error",
        text: "El nombre del comercio es muy largo.",
        icon: "success",
      });
      return true;
    }
    if (comercio.FechaInscripcion.length < 3) {
      setFlag(false);
      // setmsgErrorAlerta("La fecha de firma del convenio es requerida.");
      // setflagmsgErrorAlerta(true);
      // /*notify.show('Error: La fecha de firma del convenio es requerida.', 'custom', 5000, {
      //     background: '#0857B6',
      //     fontSize: 14,
      //     text: '#FFFFFF',
      // });*/
      swal({
        title: "Error",
        text: "La fecha de firma del convenio es requerida.",
        icon: "success",
      });
      return true;
    }

    console.log("Aqui voy:");

    if (comercio.RFC.length > 0) {
      if (comercio.RFC.length > 13) {
        setFlag(false);
        swal({
          title: "Error",
          text: "RFC incorecto",
          icon: "success",
        });
        return true;
      }
    } else {
      setFlag(false);
      swal({
        title: "Error",
        text: "RFC incorecto",
        icon: "success",
      });
      return true;
    }

    if (!isLatitude(comercio.Latitude) && comercio.Latitude.length !== 0) {
      setFlag(false);
      swal({
        title: "Error",
        text: "La latitud es requerida y con un formato valido.",
        icon: "success",
      });
      return true;

      //     notify.show('Error: La latitud es requerida y con un formato valido.', 'custom', 5000, {
      //         background: '#0857B6',
      //         fontSize: 14,
      //         text: '#FFFFFF',
      //     });
      // return true;
    }

    if (!isLongitude(comercio.Longitude) && comercio.Longitude.length !== 0) {
      setFlag(false);
      swal({
        title: "Error",
        text: "La longitud es requerida y con un formato valido.",
        icon: "success",
      });
      return true;
      //     notify.show('Error: La longitud es requerida y con un formato valido.', 'custom', 5000, {
      //         background: '#0857B6',
      //         fontSize: 14,
      //         text: '#FFFFFF',
      //     });

      // return true;
    }

    if (comercio.Longitude.length > 150) {
      setFlag(false);
      swal({
        title: "Error",
        text: "La longitud demasiado grande",
        icon: "success",
      });
      return true;
    }

    console.log("=>Updatecomercio3: ", comercio);

    if (comercio.Latitude.length > 150) {
      setFlag(false);
      swal({
        title: "Error",
        text: "La Latitude es obligatorio y menor a 150",
        icon: "success",
      });
      // setmsgErrorAlerta("La Latitude es obligatorio y menor a 150.");
      // setflagmsgErrorAlerta(true);

      return true;
    }

    if (comercio.HorarioAtencion.length < 2) {
      if (comercio.HorarioAtencion.length > 119) {
        setFlag(false);
        swal({
          title: "Error",
          text: "El horario de atención es muy largo.",
          icon: "success",
        });
        // setmsgErrorAlerta("El horario de atención es muy largo.");
        // setflagmsgErrorAlerta(true);
        // /* notify.show('Error: El horario de atención es muy largo.', 'custom', 5000, {
        //     background: '#0857B6',
        //     fontSize: 14,
        //     text: '#FFFFFF',
        // }); */
        return true;
      }
    }

    //TARJETA
    if (showDatosTarjeta) {
      if (comercio.Banco.length < 3) {
        setFlag(false);
        swal({
          title: "Error",
          text: "El nombre del banco de la cuenta es requerido.",
          icon: "success",
        });
        // setmsgErrorAlerta("El nombre del banco de la cuenta es requerido.");
        // setflagmsgErrorAlerta(true);
        // /* notify.show('Error: El nombre del banco de la cuenta es requerido.', 'custom', 5000, {
        //     background: '#0857B6',
        //     fontSize: 14,
        //     text: '#FFFFFF',
        // }); */
        return true;
      }
      if (comercio.Banco.length > 78) {
        setFlag(false);
        swal({
          title: "Error",
          text: "El nombre del banco de la cuenta es requerido.",
          icon: "success",
        });
        // setmsgErrorAlerta("El nombre del banco de la cuenta es requerido.");
        // setflagmsgErrorAlerta(true);
        // /* notify.show('Error: El nombre del banco de la cuenta es requerido.', 'custom', 5000, {
        //     background: '#0857B6',
        //     fontSize: 14,
        //     text: '#FFFFFF',
        // }); */
        return true;
      }
      if (comercio.CLABE.length !== 18) {
        setFlag(false);
        swal({
          title: "Error",
          text: "La clabe interbancaria es requerida y debe tener 18 digito",
          icon: "success",
        });
        // setmsgErrorAlerta("La clabe interbancaria es requerida y debe tener 18 digitos");
        // setflagmsgErrorAlerta(true);
        // /* notify.show('Error: La clabe interbancaria es requerida y debe tener 18 digitos', 'custom', 5000, {
        //     background: '#0857B6',
        //     fontSize: 14,
        //     text: '#FFFFFF',
        // }); */
        return true;
      }
      dataToUpdate = { ...comercio, QuiereTransferencia: 1 };
      //comercio.QuiereTransferencia = 1
    } else {
      dataToUpdate = { ...comercio, QuiereTransferencia: 0 };
      //comercio.QuiereTransferencia = 0
    }

    if (comercio.idMunicipio.length < 1) {
      setFlag(false);
      swal({
        title: "Error",
        text: "El municipio es requerido.",
        icon: "success",
      });
      // setmsgErrorAlerta("El municipio es requerido.");
      // setflagmsgErrorAlerta(true);
      // /* notify.show('Error: El municipio es requerido.', 'custom', 5000, {
      //     background: '#0857B6',
      //     fontSize: 14,
      //     text: '#FFFFFF',
      // }); */
      return true;
    }

    if (comercio.Colonia.length < 3) {
      setFlag(false);
      swal({
        title: "Error",
        text: "La colonia es requerida.",
        icon: "success",
      });
      // setmsgErrorAlerta("La colonia es requerida.");
      // setflagmsgErrorAlerta(true);
      // /* notify.show('Error: La colonia es requerida.', 'custom', 5000, {
      //     background: '#0857B6',
      //     fontSize: 14,
      //     text: '#FFFFFF',
      // }); */
      return true;
    }
    if (comercio.Colonia.length > 118) {
      setFlag(false);
      swal({
        title: "Error",
        text: "El nombre de la colonia es demasiado largo.",
        icon: "success",
      });
      //     setmsgErrorAlerta("El nombre de la colonia es demasiado largo.");
      //     setflagmsgErrorAlerta(true);
      //    /*  notify.show('Error: El nombre de la colonia es demasiado largo.', 'custom', 5000, {
      //         background: '#0857B6',
      //         fontSize: 14,
      //         text: '#FFFFFF',
      //     }); */
      return true;
    }

    if (comercio.CP.length !== 5) {
      setFlag(false);
      swal({
        title: "Error",
        text: "Codigo Postal es requerido. El codigo postal debe tener 5 caracteres.",
        icon: "success",
      });
      // setmsgErrorAlerta("Codigo Postal es requerido. El codigo postal debe tener 5 caracteres.");
      // setflagmsgErrorAlerta(true);
      // /* notify.show('Error: Codigo Postal es requerido. El codigo postal debe tener 5 caracteres.', 'custom', 5000, {
      //     background: '#0857B6',
      //     fontSize: 14,
      //     text: '#FFFFFF',
      // }); */
      return true;
    }

    if (comercio.Calle.length < 3) {
      setFlag(false);
      swal({
        title: "Error",
        text: "La calle es requerida.",
        icon: "success",
      });
      // setmsgErrorAlerta("La calle es requerida.");
      // setflagmsgErrorAlerta(true);
      // /* notify.show('Error: La calle es requerida.', 'custom', 5000, {
      //     background: '#0857B6',
      //     fontSize: 14,
      //     text: '#FFFFFF',
      // }); */
      return true;
    }
    if (comercio.Calle.length > 118) {
      setFlag(false);
      swal({
        title: "Error",
        text: "La calle es demaciado larga.",
        icon: "success",
      });
      // setmsgErrorAlerta("La calle es demaciado larga.");
      // setflagmsgErrorAlerta(true);
      // /* notify.show('Error: La calle es demaciado larga.', 'custom', 5000, {
      //     background: '#0857B6',
      //     fontSize: 14,
      //     text: '#FFFFFF',
      // }); */
      return true;
    }

    if (comercio.NumExt.length < 1) {
      setFlag(false);
      swal({
        title: "Error",
        text: "El número exterior es requerido.",
        icon: "success",
      });

      //     setmsgErrorAlerta("El número exterior es requerido.");
      //     setflagmsgErrorAlerta(true);
      //    /*  notify.show('Error: El número exterior es requerido.', 'custom', 5000, {
      //         background: '#0857B6',
      //         fontSize: 14,
      //         text: '#FFFFFF',
      //     }); */
      return true;
    }
    if (comercio.NumExt.length > 43) {
      setFlag(false);
      swal({
        title: "Error",
        text: "El número exterior es demasiado largo.",
        icon: "success",
      });
      // setmsgErrorAlerta("El número exterior es demasiado largo.");
      // setflagmsgErrorAlerta(true);
      // /* notify.show('Error: El número exterior es demasiado largo.', 'custom', 5000, {
      //     background: '#0857B6',
      //     fontSize: 14,
      //     text: '#FFFFFF',
      // }); */
      return true;
    }
    if (comercio.NumInt.length !== 0) {
      if (comercio.NumInt.length > 43) {
        setFlag(false);
        swal({
          title: "Error",
          text: "El número interior es demasiado largo.",
          icon: "success",
        });
        // setmsgErrorAlerta("El número interior es demasiado largo.");
        // setflagmsgErrorAlerta(true);
        // /* notify.show('Error: El número interior es demasiado largo.', 'custom', 5000, {
        //     background: '#0857B6',
        //     fontSize: 14,
        //     text: '#FFFFFF',
        // }); */
        return true;
      }
    }

    if (comercio.Nombre.length < 1) {
      setFlag(false);
      swal({
        title: "Error",
        text: "El nombre es requerido.",
        icon: "success",
      });
      // setmsgErrorAlerta("El nombre es requerido.");
      // setflagmsgErrorAlerta(true);
      // /* notify.show('Error: El nombre es requerido.', 'custom', 5000, {
      //     background: '#0857B6',
      //     fontSize: 14,
      //     text: '#FFFFFF',
      // }); */
      return true;
    }
    if (comercio.Nombre.length > 63) {
      setFlag(false);
      swal({
        title: "Error",
        text: "El nombre es demasiado largo.",
        icon: "success",
      });
      // setmsgErrorAlerta("El nombre es demasiado largo.");
      // setflagmsgErrorAlerta(true);
      // /* notify.show('Error: El nombre es demasiado largo.', 'custom', 5000, {
      //     background: '#0857B6',
      //     fontSize: 14,
      //     text: '#FFFFFF',
      // }); */
      return true;
    }
    if (comercio.Paterno.length < 1) {
      setFlag(false);
      swal({
        title: "Error",
        text: "El apellido paterno es requerido.",
        icon: "success",
      });
      // setmsgErrorAlerta("El apellido paterno es requerido.");
      // setflagmsgErrorAlerta(true);
      // /* notify.show('Error: El apellido paterno es requerido.', 'custom', 5000, {
      //     background: '#0857B6',
      //     fontSize: 14,
      //     text: '#FFFFFF',
      // }); */
      return true;
    }
    if (comercio.Paterno.length > 63) {
      setFlag(false);
      swal({
        title: "Error",
        text: "El apellido paterno es demasiado largo.",
        icon: "success",
      });
      //     setmsgErrorAlerta("El apellido paterno es demasiado largo.");
      //     setflagmsgErrorAlerta(true);
      //    /*  notify.show('Error: El apellido paterno es demasiado largo.', 'custom', 5000, {
      //         background: '#0857B6',
      //         fontSize: 14,
      //         text: '#FFFFFF',
      //     }); */
      return true;
    }
    if (comercio.Materno.length < 1) {
      setFlag(false);
      swal({
        title: "Error",
        text: "El apellido materno es requerido.",
        icon: "success",
      });
      // setmsgErrorAlerta("El apellido materno es requerido.");
      // setflagmsgErrorAlerta(true);
      // /* notify.show('Error: El apellido materno es requerido.', 'custom', 5000, {
      //     background: '#0857B6',
      //     fontSize: 14,
      //     text: '#FFFFFF',
      // }); */
      return true;
    }
    if (comercio.Materno.length > 63) {
      setFlag(false);
      swal({
        title: "Error",
        text: "El apellido materno es demasiado largo.",
        icon: "success",
      });
      // setmsgErrorAlerta("El apellido materno es demasiado largo.");
      // setflagmsgErrorAlerta(true);
      // /* notify.show('Error: El apellido materno es demasiado largo.', 'custom', 5000, {
      //     background: '#0857B6',
      //     fontSize: 14,
      //     text: '#FFFFFF',
      // }); */
      return true;
    }

    if (comercio.Correo.length !== 0) {
      if (!validateEmail(comercio.Correo)) {
        setFlag(false);
        swal({
          title: "Error",
          text: "El correo debe ser por ejemplo [email@ejemplo.com].",
          icon: "success",
        });
        // setmsgErrorAlerta("El correo debe ser por ejemplo [email@ejemplo.com].");
        // setflagmsgErrorAlerta(true);
        // /* notify.show('Error: El correo debe ser por ejemplo [email@ejemplo.com].', 'custom', 5000, {
        //     background: '#0857B6',
        //     fontSize: 14,
        //     text: '#FFFFFF',
        // }); */
        return true;
      }
      if (comercio.Correo.length > 118) {
        setFlag(false);
        swal({
          title: "Error",
          text: "El correo es demasiado largo.",
          icon: "success",
        });
        // setmsgErrorAlerta("El correo es demasiado largo.");
        // setflagmsgErrorAlerta(true);
        // /* notify.show('Error: El correo es demasiado largo.', 'custom', 5000, {
        //     background: '#0857B6',
        //     fontSize: 14,
        //     text: '#FFFFFF',
        // }); */
        return true;
      }
    }

    console.log("=>hasta aqui ok");

    if (comercio.Celular.length !== 0) {
      if (comercio.Celular.length > 30) {
        setFlag(false);
        swal({
          title: "Error",
          text: "El teléfono celular es demasiado largo.",
          icon: "success",
        });
        // setmsgErrorAlerta("El teléfono celular es demasiado largo.");
        // setflagmsgErrorAlerta(true);
        // /* notify.show('Error: El telefono celular es demasiado largo.', 'custom', 5000, {
        //     background: '#0857B6',
        //     fontSize: 14,
        //     text: '#FFFFFF',
        // }); */
        return true;
      }
    }

    if (comercio.TelNegocio.length !== 0) {
      if (comercio.TelNegocio.length > 30) {
        setFlag(false);
        swal({
          title: "Error",
          text: "El teléfono negocio es demasiado largo.",
          icon: "success",
        });
        // setmsgErrorAlerta("El teléfono negocio es demasiado largo.");
        // setflagmsgErrorAlerta(true);
        // /* notify.show('Error: El telefono celular es demasiado largo.', 'custom', 5000, {
        //     background: '#0857B6',
        //     fontSize: 14,
        //     text: '#FFFFFF',
        // }); */
        return true;
      }
    }

    if (comercio.TelCasa.length !== 0) {
      if (comercio.TelCasa.length > 30) {
        setFlag(false);
        swal({
          title: "Error",
          text: "El teléfono de casa es demasiado largo.",
          icon: "success",
        });
        // setmsgErrorAlerta("El teléfono de casa es demasiado largo.");
        // setflagmsgErrorAlerta(true);
        // /* notify.show('Error: El telefono de casa es demasiado largo.', 'custom', 5000, {
        //     background: '#0857B6',
        //     fontSize: 14,
        //     text: '#FFFFFF',
        // }); */
        return true;
      }
    }

    //COBRADOR

    if (comercio.Cobrador.CURP.length > 20) {
      setFlag(false);
      swal({
        title: "Error",
        text: "La CURP es demasiado larga.",
        icon: "success",
      });
      // setmsgErrorAlerta("La CURP es demasiado larga.");
      // setflagmsgErrorAlerta(true);
      // /* notify.show('Error: La CURP es demasiado larga.', 'custom', 5000, {
      //     background: '#0857B6',
      //     fontSize: 14,
      //     text: '#FFFFFF',
      // }); */
      return true;
    }
    if (comercio.Cobrador.Nombre.length < 1) {
      setFlag(false);
      swal({
        title: "Error",
        text: "El nombre del cobrador es requerido.",
        icon: "success",
      });
      // setmsgErrorAlerta("El nombre del cobrador es requerido.");
      // setflagmsgErrorAlerta(true);
      // /* notify.show('Error: El nombre del cobrador es requerido.', 'custom', 5000, {
      //     background: '#0857B6',
      //     fontSize: 14,
      //     text: '#FFFFFF',
      // }); */
      return true;
    }
    if (comercio.Cobrador.Nombre.length > 63) {
      setFlag(false);
      swal({
        title: "Error",
        text: "El nombre del cobrador es demasiado largo..",
        icon: "success",
      });
      // setmsgErrorAlerta("El nombre del cobrador es demasiado largo.");
      // setflagmsgErrorAlerta(true);
      // /* notify.show('Error: El nombre del cobrador es demasiado largo.', 'custom', 5000, {
      //     background: '#0857B6',
      //     fontSize: 14,
      //     text: '#FFFFFF',
      // }); */
      return true;
    }
    if (comercio.Cobrador.Paterno.length < 1) {
      setFlag(false);
      swal({
        title: "Error",
        text: "El apellido paterno del cobrador es requerido..",
        icon: "success",
      });
      // setmsgErrorAlerta("El apellido paterno del cobrador es requerido.");
      // setflagmsgErrorAlerta(true);
      // /* notify.show('Error: El apellido paterno del cobrador es requerido.', 'custom', 5000, {
      //     background: '#0857B6',
      //     fontSize: 14,
      //     text: '#FFFFFF',
      // }); */
      return true;
    }
    if (comercio.Cobrador.Paterno.length > 63) {
      setFlag(false);
      swal({
        title: "Error",
        text: "El apellido paterno del cobrador es demasiado largo..",
        icon: "success",
      });
      // setmsgErrorAlerta("El apellido paterno del cobrador es demasiado largo.");
      // setflagmsgErrorAlerta(true);
      // /* notify.show('Error: El apellido paterno del cobrador es demasiado largo.', 'custom', 5000, {
      //     background: '#0857B6',
      //     fontSize: 14,
      //     text: '#FFFFFF',
      // }); */
      return true;
    }
    if (comercio.Cobrador.Materno.length < 1) {
      setFlag(false);
      swal({
        title: "Error",
        text: "El apellido materno del cobrador es requerido",
        icon: "success",
      });
      // setmsgErrorAlerta("El apellido materno del cobrador es requerido");
      // setflagmsgErrorAlerta(true);
      // /* notify.show('Error: El apellido materno del cobrador es requerido', 'custom', 5000, {
      //     background: '#0857B6',
      //     fontSize: 14,
      //     text: '#FFFFFF',
      // }); */
      return true;
    }

    if (comercio.Cobrador.Materno.length > 63) {
      setFlag(false);
      swal({
        title: "Error",
        text: "El apellido materno del cobrador es demasiado largo.",
        icon: "success",
      });
      // setmsgErrorAlerta("El apellido materno del cobrador es demasiado largo.");
      // setflagmsgErrorAlerta(true);
      // /* notify.show('Error: El apellido materno del cobrador es demasiado largo.', 'custom', 5000, {
      //     background: '#0857B6',
      //     fontSize: 14,
      //     text: '#FFFFFF',
      // }); */
      return true;
    }

    comercio.Cobrador = [comercio.Cobrador];

    dataToUpdate = { ...comercio, Cobrador: comercio.Cobrador };

    // setFlag(false)
    // return true;

    console.log("=>updateNegocios: ", JSON.stringify(dataToUpdate));

    EjecutarApi(dataToUpdate, "updateNegocios", token.token)
      .then(function (res) {
        console.log("=> updateNegociosRS: ", res.data);

        setFlag(false);

        swal({
          title: "Actualización Exitosa!",
          text: "Se actualizo correctamente!",
          icon: "success",
        });

        // notify.show('Registro Exitoso!', 'custom', 5000, {
        //     background: '#18c97d',
        //     text: '#FFFFFF',
        // });
        //setComercio({ ...comercio, Cobrador: comercio.Cobrador[0] });
        return res;
      })
      .catch((e) => {
        //setComercio({ ...comercio, Cobrador: comercio.Cobrador[0] });
        console.log("=>error updateNegocios: ", e);
        setFlag(false);
        notify.show(
          `Error en no se logro el registro! ${e.errors}`,
          "custom",
          5000,
          {
            background: "#d61818",
            text: "#FFFFFF",
          }
        );
      });

    onHide();
    setFlag(false);
  };

  function isLatitude(lat) {
    return isFinite(lat) && Math.abs(lat) <= 90;
  }

  function isLongitude(lng) {
    return isFinite(lng) && Math.abs(lng) <= 180;
  }
  const alerta = (
    <ReactBSAlert
      style={{ display: "block", marginTop: "-100px" }}
      title={
        "¿Está seguro que desea cambiar el estatus a " + AlertaMensaje + "?"
      }
      confirmBtnText="Si"
      cancelBtnText="No"
      onConfirm={() => {
        setflagAlertaMensaje2(false);
        botonCancelar(idEstatusCambio);
      }}
      onCancel={() => setflagAlertaMensaje2(false)}
      showCancel
      confirmBtnBsStyle="info"
      cancelBtnBsStyle="danger"
    />
  );

  const alertaCancelBajaComercio = (
    <ReactBSAlert
      style={{ display: "block", marginTop: "-100px" }}
      title={"¿Está seguro que desea cambiar dar de baja al comercio?"}
      confirmBtnText="Si"
      cancelBtnText="No"
      onConfirm={() => {
        setflagAlertaMensajeBaja(false);
        botonBajaComercio();
      }}
      onCancel={() => setflagAlertaMensajeBaja(false)}
      showCancel
      confirmBtnBsStyle="info"
      cancelBtnBsStyle="danger"
    />
  );

  const alertaMensaje = (
    <ReactBSAlert
      style={{ display: "block", marginTop: "-100px", fontSize: "8px" }}
      title={"Error:  " + msgErrorAlerta + "!!!!"}
      confirmBtnText="Aceptar"
      //cancelBtnText="No"
      onConfirm={() => {
        setflagmsgErrorAlerta(false);
      }}
      confirmBtnBsStyle="info"
    />
  );

  function curpValida(curp) {
    var re =
        /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/,
      validado = curp.match(re);

    if (!validado)
      //Coincide con el formato general?
      return false;

    //Validar que coincida el dígito verificador
    function digitoVerificador(curp17) {
      //Fuente https://consultas.curp.gob.mx/CurpSP/
      var diccionario = "0123456789ABCDEFGHIJKLMNÑOPQRSTUVWXYZ",
        lngSuma = 0.0,
        lngDigito = 0.0;
      for (var i = 0; i < 17; i++)
        lngSuma = lngSuma + diccionario.indexOf(curp17.charAt(i)) * (18 - i);
      lngDigito = 10 - (lngSuma % 10);
      if (lngDigito == 10) return 0;
      return lngDigito;
    }

    if (validado[2] != digitoVerificador(validado[1])) return false;

    return true; //Validado
  }

  const validateEmail = (email) => {
    var re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  const mini_table = (
    <>
      <ReactBSAlert
        title={""}
        style={{ display: "block", marginTop: "-350pt" }}
        //title="Here's a message!"
        onConfirm={() => setFlagEscogerColonia(false)}
        onCancel={() => setFlagEscogerColonia(false)}
        confirmBtnBsStyle="info"
      >
        <Card>
          <CardBody>
            <ReactTable
              data={data}
              noDataText="Sin información"
              columns={[
                {
                  Header: "Colonia",
                  minWidth: 10,
                  id: "Colonia",
                  accessor: (d) => d.Colonia,
                  filterAll: false,
                },
              ]}
              defaultPageSize={10}
              filterable
              className={"-striped -highlight primary-pagination"}
              loading={loading}
              showPagination={true}
              showPageJump={false}
              previousText="Anterior"
              nextText="Siguiente"
              canNextFromData={true}
              manual
              total={totalColonias}
              pages={Math.floor(totalColonias / 10)}
              pageSizeOptions={[10, 20, 25, 50, 100]}
              getTrProps={(state, rowInfo, column, instance) => {
                return {
                  onClick: (e) => {
                    setComercio({
                      ...comercio,
                      Colonia: rowInfo.original.Colonia,
                      CP: parseInt(rowInfo.original.data.d_codigo, 10),
                    });
                    setFlagEscogerColonia(false);
                  },
                };
              }}
              onFetchData={(stateComp, instance) => {
                setLoading(true);
                const token = getVars("Token").token;

                var array_filtros = [];
                array_filtros.push({ id: "c_estado", value: 11 });
                array_filtros.push({
                  id: "c_mnpio",
                  value: comercio.idMunicipio,
                });
                if (stateComp.filtered.length != 0) {
                  array_filtros.push({
                    id: "d_asenta",
                    value: stateComp.filtered[0].value,
                  });
                }
                const datax = {
                  page: stateComp.page,
                  tipo: "and",
                  pageSize: stateComp.pageSize,
                  sorted: [{ id: "d_asenta", desc: false }],
                  filtered: array_filtros,
                };

                EjecutarApi(datax, "getCP", token)
                  .then(function (res) {
                    return res;
                  })
                  .then((res) => {
                    setLoading(false);
                    setTotalColonias(res.total);
                    setData(
                      res.data.map((prop, key) => {
                        return {
                          Colonia: prop.d_asenta,
                          data: prop,
                        };
                      })
                    );
                  })
                  .catch((e) => {
                    setLoading(false);
                    notify.show(
                      `Error al obtener colonias: ${e.errors}`,
                      "custom",
                      5000,
                      {
                        background: "#0857B6",
                        fontSize: 14,
                        text: "#FFFFFF",
                      }
                    );
                  });
              }}
            />
          </CardBody>
        </Card>
      </ReactBSAlert>
    </>
  );

  const botonCancelar = (Estatus) => {
    const data = { id: comercio.id, idStatus: Estatus };
    console.log("=>updateNegocios: ", data);
    setFlag(true);
    EjecutarApi(data, "updateNegocios", token.token)
      .then(function (res) {
        console.log("=> MODAL COMERCIO UPDATE RESPUESTA", res.data);

        setFlag(false);
        onHide();
        notify.show("Registro Exitoso!", "custom", 5000, {
          background: "#18c97d",
          text: "#FFFFFF",
        });

        return res;
      })
      .catch((e) => {
        setFlag(false);
        notify.show(
          `Error en no se logro el registro! ${e.errors}`,
          "custom",
          5000,
          {
            background: "#d61818",
            text: "#FFFFFF",
          }
        );
      });
  };

  return comercio !== undefined ? (
    <>
      <Modal
        isOpen={show}
        toggle={onHide}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <ModalHeader toggle={onHide}>
          Edición del Comercio: {api.NombreEmpresa}
        </ModalHeader>
        <ModalBody>
          <Form>
            <h6>Datos del Comercio</h6>

            <Row>
              <Label sm="2">Estatus del Comercio:</Label>
              <Col sm="3">
                <Input
                  disabled={true}
                  id="estatusComercio"
                  type="text"
                  value={comercio.Estatus}
                />
              </Col>
              <Label sm="2">Folio del Comercio:</Label>
              <Col sm="3">
                <Input
                  disabled={true}
                  id="folioComercio"
                  type="text"
                  value={comercio.ClaveUnica}
                />
              </Col>
            </Row>
            <Row>
              <Label sm="2">Refrendo Comercio:</Label>
              <Col sm="5" md="5">
                <Select
                  name="Refrendocomercio"
                  className="SelectSize"
                  placeholder="Refrendo"
                  value={Refrendo2021}
                  options={filterRefrendo}
                  onChange={UpdateNegocioSetRefrendo}
                />
              </Col>
              <Col sm="5" md="5">
                {comercio.idStatus === 3 && (
                  <Button
                    color="#F72805"
                    onClick={() => setflagAlertaMensajeBaja(true)}
                  >
                    {
                      <ImpulseSpinner
                        color={"#1261ff"}
                        size={30}
                        loading={flag}
                      />
                    }
                    {flag ? (
                      <span>Guardando...</span>
                    ) : (
                      <span>Baja comercio</span>
                    )}
                  </Button>
                )}
              </Col>
            </Row>
            <Row>
              <Label sm="2">Refrendo Comercio:</Label>

              <Col sm="3">
                <Input
                  disabled={true}
                  id="folioComercio"
                  type="text"
                  value={comercio.ClaveUnica}
                />
              </Col>
            </Row>

            {/**Clasificacion del comercio */}
            {!isvisibleEstatus || _menu.Editar == 1 ? (
              <Row>
                {tipos.length > 0 ? (
                  <>
                    <Label sm="2">
                      Clasificación del Comercio:
                      <span className="text-danger">*</span>
                    </Label>
                    <Col sm="4">
                      <Select
                        className="react-select primary"
                        classNamePrefix="react-select"
                        id="tipo"
                        defaultValue={{
                          label: comercio.TipoGiro,
                          value: comercio.idTipoNegocio,
                        }}
                        onChange={(event) => {
                          setComercio({
                            ...comercio,
                            idTipoNegocio: event.value,
                          });
                        }}
                        options={tipos}
                        placeholder="seleccione la clasificación"
                      />
                    </Col>
                  </>
                ) : (
                  <Col sm="2" style={Spinenerstyle}>
                    <RotateSpinner color={"#1261ff"} size={30} loading={true} />
                  </Col>
                )}
                {giros.length > 0 ? (
                  <>
                    <Label sm="2">
                      Giro(s) del Comercio:
                      <span className="text-danger">*</span>
                    </Label>
                    <Col sm="4">
                      <Select
                        id="example-multiple-selected"
                        isMulti="true"
                        isSearchable
                        options={giros}
                        defaultValue={girosBase.map((giro) => {
                          return {
                            label: giro.Giro,
                            value: giro.idGiro,
                            disabled: true,
                          };
                        })}
                        onChange={(event, i) => {
                          //setComercio({ ...comercio, Giros: event.map(dato => { return dato.value }) })
                          if (event) {
                            setComercio({
                              ...comercio,
                              Giros: event.map((dato) => {
                                return dato.value;
                              }),
                            });
                          } else {
                            setComercio({
                              ...comercio,
                              Giros: comercio.Giros.map((dato) => {
                                if (i.removedValue.value !== dato) {
                                  return dato.value;
                                }
                              }),
                            });
                          }
                        }}
                      />
                    </Col>
                  </>
                ) : (
                  <Col sm="2" style={Spinenerstyle}>
                    <RotateSpinner color={"#1261ff"} size={30} loading={true} />
                  </Col>
                )}
              </Row>
            ) : (
              //INICIO DE PERMISOS EDITAR
              <Row>
                {tipos.length > 0 ? (
                  <>
                    <Label sm="2">
                      Clasificación del Comercio:
                      <span className="text-danger">*</span>
                    </Label>
                    <Col sm="4">
                      <Select
                        className="react-select primary"
                        classNamePrefix="react-select"
                        id="tipo"
                        defaultValue={{
                          label: comercio.TipoGiro,
                          value: comercio.idTipoNegocio,
                        }}
                        onChange={(event) => {
                          setComercio({
                            ...comercio,
                            idTipoNegocio: event.value,
                          });
                        }}
                        options={tipos}
                        isDisabled="true"
                        placeholder="seleccione la clasificación"
                      />
                    </Col>
                  </>
                ) : (
                  <Col sm="2" style={Spinenerstyle}>
                    <RotateSpinner color={"#1261ff"} size={30} loading={true} />
                  </Col>
                )}
                {giros.length > 0 ? (
                  <>
                    <Label sm="2">
                      Giro(s) del Comercio:
                      <span className="text-danger">*</span>
                    </Label>
                    <Col sm="4">
                      <Select
                        isDisabled="true"
                        id="example-multiple-selected"
                        isMulti="true"
                        isSearchable
                        options={giros}
                        defaultValue={girosBase.map((giro) => {
                          return {
                            label: giro.Giro,
                            value: giro.idGiro,
                            disabled: true,
                          };
                        })}
                        onChange={(event, i) => {
                          //setComercio({ ...comercio, Giros: event.map(dato => { return dato.value }) })
                          if (event) {
                            setComercio({
                              ...comercio,
                              Giros: event.map((dato) => {
                                return dato.value;
                              }),
                            });
                          } else {
                            setComercio({
                              ...comercio,
                              Giros: comercio.Giros.map((dato) => {
                                if (i.removedValue.value !== dato) {
                                  return dato.value;
                                }
                              }),
                            });
                          }
                        }}
                      />
                    </Col>
                  </>
                ) : (
                  <Col sm="2" style={Spinenerstyle}>
                    <RotateSpinner color={"#1261ff"} size={30} loading={true} />
                  </Col>
                )}
              </Row>
              //PREGUNTA DE PERMISOS
            )}
            {/**Nombre del comercio */}
            <Row>
              <Label sm="2">
                Nombre Comercio:<span className="text-danger">*</span>
              </Label>
              <Col sm="10">
                <FormGroup>
                  <Input
                    disabled={
                      !isvisibleEstatus || _menu.Editar == 1 ? false : true
                    }
                    id="nombreEmpresa"
                    type="text"
                    value={comercio.NombreEmpresa}
                    onChange={(event) => {
                      console.log("=>Nombreempresa: ", event.target.value);
                      setComercio({
                        ...comercio,
                        NombreEmpresa: event.target.value.toUpperCase(),
                      });
                    }}
                  />
                </FormGroup>
              </Col>
            </Row>
            {/**Fecha Convenio del comercio */}
            <Row>
              <Col sm="6">
                <Label>
                  Fecha Firma Convenio:<span className="text-danger">*</span>
                </Label>

                <FormGroup>
                  <ReactDatetime
                    disabled={
                      !isvisibleEstatus || _menu.Editar == 1 ? false : true
                    }
                    id="fechaInscripcion"
                    name="fechaInscripcion"
                    dateFormat="YYYY-MM-DD"
                    timeFormat={false}
                    closeOnSelect
                    defaultValue={comercio.FechaInscripcion}
                    onChange={(e) =>
                      setComercio({
                        ...comercio,
                        FechaInscripcion: moment(e._d).format("YYYY-MM-DD"),
                      })
                    }
                    inputProps={{ className: "form-control" }}
                  />
                </FormGroup>
              </Col>

              {/**RFC del comercio */}
              <Col sm="6">
                <Label>RFC</Label>

                <FormGroup>
                  <Input
                    disabled={
                      !isvisibleEstatus || _menu.Editar == 1 ? false : true
                    }
                    name="rfc"
                    type="text"
                    value={comercio.RFC}
                    onChange={(event) =>
                      setComercio({
                        ...comercio,
                        RFC: event.target.value.toUpperCase(),
                      })
                    }
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Label sm="2">
                Latitud:<span className="text-danger">*</span>
              </Label>
              <Col sm="4">
                <FormGroup>
                  <Input
                    disabled={
                      !isvisibleEstatus || _menu.Editar == 1 ? false : true
                    }
                    id="Latitud"
                    type="text"
                    value={comercio.Latitude}
                    onChange={(event) =>
                      setComercio({ ...comercio, Latitude: event.target.value })
                    }
                  />
                </FormGroup>
              </Col>
              <Label sm="2">
                Longitud:<span className="text-danger">*</span>
              </Label>
              <Col sm="4">
                <FormGroup>
                  <Input
                    disabled={
                      !isvisibleEstatus || _menu.Editar == 1 ? false : true
                    }
                    id="Longitud"
                    type="text"
                    value={comercio.Longitude}
                    onChange={(event) =>
                      setComercio({
                        ...comercio,
                        Longitude: event.target.value,
                      })
                    }
                  />
                </FormGroup>
              </Col>
            </Row>
            <Label sm="2">Definir Banco:</Label>
            <Switch
              disabled={!isvisibleEstatus || _menu.Editar == 1 ? false : true}
              sm="6"
              value={
                comercio.QuiereTransferencia === 1
                  ? true
                  : comercio.QuiereTransferencia === true
                  ? true
                  : false
              }
              onColor="default"
              offColor="default"
              onChange={() => {
                setShowDatosTarjeta(!comercio.QuiereTransferencia);
                setComercio({
                  ...comercio,
                  QuiereTransferencia: !comercio.QuiereTransferencia,
                });
              }}
            />
            {showDatosTarjeta ? (
              <>
                <Row>
                  <Label sm="2">
                    Banco:<span className="text-danger">*</span>
                  </Label>
                  <Col sm="10">
                    <FormGroup>
                      <Input
                        disabled={
                          !isvisibleEstatus || _menu.Editar == 1 ? false : true
                        }
                        name="Banco"
                        type="text"
                        value={comercio.Banco}
                        onChange={(event) =>
                          setComercio({
                            ...comercio,
                            Banco: event.target.value.toUpperCase(),
                          })
                        }
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Label sm="2">
                    CLABE:<span className="text-danger">*</span>
                  </Label>
                  <Col sm="10">
                    <FormGroup>
                      <Input
                        disabled={
                          !isvisibleEstatus || _menu.Editar == 1 ? false : true
                        }
                        name="CLABE"
                        type="text"
                        value={comercio.CLABE}
                        onChange={(event) =>
                          setComercio({
                            ...comercio,
                            CLABE: event.target.value.toUpperCase(),
                          })
                        }
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Label sm="2">Numero de Tarjeta:</Label>
                  <Col sm="10">
                    <FormGroup>
                      <Input
                        disabled={
                          !isvisibleEstatus || _menu.Editar == 1 ? false : true
                        }
                        name="NumTarjeta"
                        type="text"
                        value={comercio.NumTarjeta}
                        onChange={(event) =>
                          setComercio({
                            ...comercio,
                            NumTarjeta: event.target.value.toUpperCase(),
                          })
                        }
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </>
            ) : (
              <></>
            )}
            <h6>Domicilio del Comercio</h6>
            <Row>
              {!isvisibleEstatus || _menu.Editar == 1 ? (
                municipios.length !== 0 ? (
                  <>
                    <Label sm="2">
                      Municipio:<span className="text-danger">*</span>
                    </Label>
                    <Col sm="10">
                      <FormGroup>
                        <Select
                          className="react-select primary"
                          classNamePrefix="react-select"
                          id="municipio"
                          defaultValue={{
                            value: comercio.idMunicipio,
                            label: comercio.Municipio,
                          }}
                          onChange={(event) => {
                            setComercio({
                              ...comercio,
                              idMunicipio: event.value,
                            });
                            //setNomMunicipio(event.label)
                          }}
                          options={municipios}
                          placeholder="seleccione su municipio"
                        />
                      </FormGroup>
                    </Col>
                  </>
                ) : (
                  <>
                    <Col sm="2" style={Spinenerstyle}>
                      <RotateSpinner
                        color={"#1261ff"}
                        size={30}
                        loading={true}
                      />
                    </Col>
                  </>
                )
              ) : municipios.length !== 0 ? (
                <>
                  <Label sm="2">
                    Municipio:<span className="text-danger">*</span>
                  </Label>
                  <Col sm="10">
                    <FormGroup>
                      <Select
                        isDisabled="true"
                        className="react-select primary"
                        classNamePrefix="react-select"
                        id="municipio"
                        defaultValue={{
                          value: comercio.idMunicipio,
                          label: comercio.Municipio,
                        }}
                        onChange={(event) => {
                          setComercio({
                            ...comercio,
                            idMunicipio: event.value,
                          });
                          //setNomMunicipio(event.label)
                        }}
                        options={municipios}
                        placeholder="seleccione su municipio"
                      />
                    </FormGroup>
                  </Col>
                </>
              ) : (
                <>
                  <Col sm="2" style={Spinenerstyle}>
                    <RotateSpinner color={"#1261ff"} size={30} loading={true} />
                  </Col>
                </>
              )}
            </Row>
            <Row>
              <Label sm="1">
                Colonia:<span className="text-danger">*</span>
              </Label>
              <Col sm="3">
                <FormGroup>
                  <Input
                    disabled={
                      !isvisibleEstatus || _menu.Editar == 1 ? false : true
                    }
                    name="Colonia"
                    type="text"
                    value={comercio.Colonia}
                    onChange={(event) =>
                      setComercio({ ...comercio, Colonia: event.target.value })
                    }
                  />
                </FormGroup>
              </Col>
              <Col sm="2">
                <Button
                  disabled={
                    !isvisibleEstatus || _menu.Editar == 1 ? false : true
                  }
                  onClick={() => {
                    setFlagEscogerColonia(true);
                  }}
                >
                  BUSCAR
                </Button>
                {flagEscogerColonia ? mini_table : <></>}
              </Col>

              <Label sm="2">
                Codigo Postal:<span className="text-danger">*</span>
              </Label>
              <Col sm="4">
                <FormGroup>
                  <Input
                    disabled={
                      !isvisibleEstatus || _menu.Editar == 1 ? false : true
                    }
                    name="cp"
                    type="text"
                    pattern="[0-9]*"
                    maxLength={5}
                    value={comercio.CP}
                    onChange={(event) =>
                      setComercio({
                        ...comercio,
                        CP: event.target.value.replace(/\D/, ""),
                      })
                    }
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Label sm="2">
                Calle:<span className="text-danger">*</span>
              </Label>
              <Col sm="4">
                <FormGroup>
                  <Input
                    disabled={
                      !isvisibleEstatus || _menu.Editar == 1 ? false : true
                    }
                    id="calle"
                    type="text"
                    value={comercio.Calle}
                    onChange={(event) =>
                      setComercio({ ...comercio, Calle: event.target.value })
                    }
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Label sm="2">
                Número Exterior:<span className="text-danger">*</span>
              </Label>
              <Col sm="4">
                <FormGroup>
                  <Input
                    disabled={
                      !isvisibleEstatus || _menu.Editar == 1 ? false : true
                    }
                    id="numExt"
                    type="text"
                    value={comercio.NumExt}
                    onChange={(event) =>
                      setComercio({ ...comercio, NumExt: event.target.value })
                    }
                  />
                </FormGroup>
              </Col>
              <Label sm="2">Número Interior</Label>
              <Col sm="4">
                <FormGroup>
                  <Input
                    disabled={
                      !isvisibleEstatus || _menu.Editar == 1 ? false : true
                    }
                    id="numInt"
                    type="text"
                    value={comercio.NumInt}
                    onChange={(event) =>
                      setComercio({ ...comercio, NumInt: event.target.value })
                    }
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              {comercio.Colonia === "otro" ? (
                <>
                  <Label sm="2">
                    Otra Colonia:<span className="text-danger">*</span>
                  </Label>
                  <Col sm="4">
                    <FormGroup>
                      <Input
                        disabled={
                          !isvisibleEstatus || _menu.Editar == 1 ? false : true
                        }
                        name="ocolonia"
                        type="text"
                        value={otraColonia}
                        onChange={(event) => setOtraColonia(event.target.value)}
                      />
                    </FormGroup>
                  </Col>
                </>
              ) : (
                ""
              )}
            </Row>

            <h6>Datos del Dueño o Representante</h6>
            <Row>
              <Label sm="2">
                Nombre:<span className="text-danger">*</span>
              </Label>
              <Col sm="10">
                <FormGroup>
                  <Input
                    disabled={
                      !isvisibleEstatus || _menu.Editar == 1 ? false : true
                    }
                    name="nombre"
                    type="text"
                    value={comercio.Nombre}
                    onChange={(event) =>
                      setComercio({
                        ...comercio,
                        Nombre: event.target.value.toUpperCase(),
                      })
                    }
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Label sm="2">Apellido Paterno:</Label>
              <Col sm="10">
                <FormGroup>
                  <Input
                    disabled={
                      !isvisibleEstatus || _menu.Editar == 1 ? false : true
                    }
                    name="paterno"
                    type="text"
                    value={comercio.Paterno}
                    onChange={(event) =>
                      setComercio({
                        ...comercio,
                        Paterno: event.target.value.toUpperCase(),
                      })
                    }
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Label sm="2">Apellido Materno:</Label>
              <Col sm="10">
                <FormGroup>
                  <Input
                    disabled={
                      !isvisibleEstatus || _menu.Editar == 1 ? false : true
                    }
                    name="materno"
                    type="text"
                    value={comercio.Materno}
                    onChange={(event) =>
                      setComercio({
                        ...comercio,
                        Materno: event.target.value.toUpperCase(),
                      })
                    }
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Label sm="2">Correo Electronico:</Label>
              <Col sm="10">
                <FormGroup>
                  <Input
                    disabled={
                      !isvisibleEstatus || _menu.Editar == 1 ? false : true
                    }
                    name="correo"
                    type="email"
                    value={comercio.Correo}
                    onChange={(event) =>
                      setComercio({ ...comercio, Correo: event.target.value })
                    }
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Label sm="2">Teléfono del negocio:</Label>
              <Col sm="4">
                <FormGroup>
                  <Input
                    disabled={
                      !isvisibleEstatus || _menu.Editar == 1 ? false : true
                    }
                    id="TelNegocio"
                    type="text"
                    pattern="[0-9]*"
                    maxLength={10}
                    value={comercio.TelNegocio}
                    onChange={(event) =>
                      setComercio({
                        ...comercio,
                        TelNegocio: event.target.value.replace(/\D/, ""),
                      })
                    }
                  />
                </FormGroup>
              </Col>
              <Label sm="2">Teléfono Celular:</Label>
              <Col sm="4">
                <FormGroup>
                  <Input
                    disabled={
                      !isvisibleEstatus || _menu.Editar == 1 ? false : true
                    }
                    id="celular"
                    type="text"
                    pattern="[0-9]*"
                    maxLength={10}
                    onChange={(event) =>
                      setComercio({
                        ...comercio,
                        Celular: event.target.value.replace(/\D/, ""),
                      })
                    }
                    value={comercio.Celular}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Label sm="2">Telefono de casa:</Label>
              <Col sm="4">
                <FormGroup>
                  <Input
                    disabled={
                      !isvisibleEstatus || _menu.Editar == 1 ? false : true
                    }
                    id="TelCasa"
                    type="text"
                    pattern="[0-9]*"
                    maxLength={10}
                    onChange={(event) =>
                      setComercio({
                        ...comercio,
                        TelCasa: event.target.value.replace(/\D/, ""),
                      })
                    }
                    value={comercio.TelCasa}
                  />
                </FormGroup>
              </Col>

              <Label sm="2">
                Horario de atención:<span className="text-danger">*</span>
              </Label>
              <Col sm="4">
                <FormGroup>
                  <Input
                    disabled={
                      !isvisibleEstatus || _menu.Editar == 1 ? false : true
                    }
                    id="hora"
                    type="textarea"
                    value={comercio.HorarioAtencion}
                    onChange={(event) =>
                      setComercio({
                        ...comercio,
                        HorarioAtencion: event.target.value.toUpperCase(),
                      })
                    }
                  />
                </FormGroup>
              </Col>
            </Row>
            <h6>Cobrador Acreditado</h6>
            <Row>
              <Col sm="6">
                <Label>
                  CURP:<span className="text-danger">*</span>
                </Label>

                <FormGroup>
                  <Input
                    disabled={
                      !isvisibleEstatus || _menu.Editar == 1 ? false : true
                    }
                    name="CURP"
                    type="text"
                    value={comercio.Cobrador.CURP}
                    onChange={(event) =>
                      setComercio({
                        ...comercio,
                        Cobrador: {
                          ...comercio.Cobrador,
                          CURP: event.target.value,
                        },
                      })
                    }
                  />
                </FormGroup>
              </Col>

              <Col sm="6">
                <Label>
                  Nombre:<span className="text-danger">*</span>
                </Label>

                <FormGroup>
                  <Input
                    disabled={
                      !isvisibleEstatus || _menu.Editar == 1 ? false : true
                    }
                    name="nombre"
                    type="text"
                    value={comercio.Cobrador.Nombre}
                    onChange={(event) =>
                      setComercio({
                        ...comercio,
                        Cobrador: {
                          ...comercio.Cobrador,
                          Nombre: event.target.value.toUpperCase(),
                        },
                      })
                    }
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col sm="6">
                <Label>
                  Apellido Paterno:<span className="text-danger">*</span>
                </Label>

                <FormGroup>
                  <Input
                    disabled={
                      !isvisibleEstatus || _menu.Editar == 1 ? false : true
                    }
                    name="paterno"
                    type="text"
                    value={comercio.Cobrador.Paterno}
                    onChange={(event) =>
                      setComercio({
                        ...comercio,
                        Cobrador: {
                          ...comercio.Cobrador,
                          Paterno: event.target.value.toUpperCase(),
                        },
                      })
                    }
                  />
                </FormGroup>
              </Col>

              <Col sm="6">
                <Label>
                  Apellido Materno:<span className="text-danger">*</span>
                </Label>

                <FormGroup>
                  <Input
                    disabled={
                      !isvisibleEstatus || _menu.Editar == 1 ? false : true
                    }
                    name="materno"
                    type="text"
                    value={comercio.Cobrador.Materno}
                    onChange={(event) =>
                      setComercio({
                        ...comercio,
                        Cobrador: {
                          ...comercio.Cobrador,
                          Materno: event.target.value.toUpperCase(),
                        },
                      })
                    }
                  />
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </ModalBody>

        <ModalFooter>
          {_isTipoUserAdmin || _menu.Seguimiento == 1 ? (
            <>
              <Button
                color="warning"
                disabled={
                  _isTipoUserAdmin || _menu.Seguimiento == 1
                    ? false
                    : isvisibleEstatus
                }
                onClick={(e) => {
                  setAlertaMensaje("Por Verificar", 1);
                  setidEstatusCambio(1);
                  setflagAlertaMensaje2(true);
                }}
                value="1"
              >
                {<ImpulseSpinner color={"#1261ff"} size={30} loading={flag} />}
                {flag ? (
                  <span className="btn-label">
                    <i className="nc-icon nc-zoom-split" />
                    Validando
                  </span>
                ) : (
                  <span className="btn-label">
                    <i className="nc-icon nc-zoom-split" /> Por Verificar
                  </span>
                )}
              </Button>

              <Button
                color="success"
                disabled={
                  _isTipoUserAdmin || _menu.Seguimiento == 1
                    ? false
                    : isvisibleEstatus
                }
                onClick={() => {
                  setAlertaMensaje("Autorizar", 3);
                  setidEstatusCambio(3);
                  setflagAlertaMensaje2(true);
                }}
                value="3"
              >
                {<ImpulseSpinner color={"#1261ff"} size={30} loading={flag} />}
                {flag ? (
                  <span className="btn-label">
                    <i className="nc-icon nc-check-2" />
                    Autorizando
                  </span>
                ) : (
                  <span className="btn-label">
                    <i className="nc-icon nc-check-2" />
                    Autorizar
                  </span>
                )}
              </Button>

              <Button
                color="danger"
                disabled={
                  _isTipoUserAdmin || _menu.Seguimiento == 1
                    ? false
                    : isvisibleEstatus
                }
                onClick={() => {
                  setAlertaMensaje("Bloqueado");
                  setidEstatusCambio(4);
                  setflagAlertaMensaje2(true);
                }}
                value="4"
              >
                {<ImpulseSpinner color={"#1261ff"} size={30} loading={flag} />}
                {flag ? (
                  <span className="btn-label">
                    <i className="nc-icon nc-simple-remove" />
                    Bloqueado....
                  </span>
                ) : (
                  <span className="btn-label">
                    <i className="nc-icon nc-simple-remove" />
                    Bloquear Comercio
                  </span>
                )}
              </Button>
            </>
          ) : (
            <></>
          )}

          <Button
            color="primary"
            disabled={
              _isTipoUserAdmin || _menu.Seguimiento == 1 || _menu.Editar == 1
                ? false
                : isvisibleEstatus
            }
            onClick={UpdateNegocio}
          >
            {<ImpulseSpinner color={"#1261ff"} size={30} loading={flag} />}
            {flag ? <span>Guardando...</span> : <span>Guardar Cambios</span>}
          </Button>

          {flagAlertaMensaje2 ? alerta : <> </>}
          {flagAlertaMensajeBaja ? alertaCancelBajaComercio : <> </>}
          {flagmsgErrorAlerta ? alertaMensaje : <> </>}
        </ModalFooter>
      </Modal>
    </>
  ) : (
    <></>
  );
}
export default ModalComercioUpdate;
