import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  Row,
  Col,
  Spinner,
  FormGroup,
  Input,
  Label,
} from "reactstrap";
import swal from "sweetalert";
import ReactTable from "react-table";
import EjecutarApi from "../Consultas/Consultas";
import Notifications from "react-notify-toast";
import { getVars } from "../GlobalStorage";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Sidebar from "../components/Sidebar";
import Pagination from "../components/pagination";
import { isNullOrUndefined } from "../utils/emptyValidations";

const RevisionGrupo = (props) => {
  const [userData, setUserData] = useState();
  const [menu, setMenu] = useState({
    Ver: 0,
    Agregar: 0,
    Editar: 0,
    Eliminar: 0,
    Seguimiento: 0,
    Exportar: 0,
    Imprimir: 0,
    ViewAll: 0,
    idTipoUser: 0,
  });
  const history = useHistory();
  const [total, setTotal] = useState(0);
  const [loadingCedulaList, setLoadingCedulaList] = useState(false);
  const [dataCedulaList, setDataCedulaList] = useState([]);
  const [menuLoaded, setMenuLoaded] = useState(false);
  const [pageOptions, setPage] = useState({
    page: 0,
    pageSize: 10,
  });

  //   const [loading, setLoading] = useState(false);
  //   const [loadingCedulaList, setLoadingCedulaList] = useState(false);
  //   const [apoyos, setApoyos] = useState([]);

  const [form, setForm] = useState({
    Remesa: "",
    Municipio: 0,
    CveInterventor: "",
    Localidad: 0,
    Responsable: "",
  });

  const [remesa, setRemesa] = useState([]);
  //   const [municipio, setMunicipio] = useState([]);
  const [cveInterventor, setCveInterventor] = useState([]);
  //   const [localidad, setLocalidad] = useState([]);
  const [responsable, setResponsable] = useState([]);

  const [loadingRemesa, setLoadingRemesa] = useState(false);
  //   const [loadingMunicipio, setLoadingMunicipio] = useState(false);
  const [loadingCveInterventor, setLoadingCveInterventor] = useState(false);
  //   const [loadingLocalidad, setLoadingLocalidad] = useState(false);
  const [loadingResponsable, setLoadingResponsable] = useState(false);
  const columns = [
    {
      Header: "Remesa",
      minWidth: 70,
      id: "Remesa",
      accessor: (d) => d.Remesa,
      filterAll: false,
      style: { fontSize: "10px" },
      headerStyle: { fontSize: "10px" },
    },
    {
      Header: "Municipio",
      minWidth: 130,
      id: "Municipio",
      accessor: (d) => d.Municipio,
      filterAll: false,
      style: { fontSize: "10px" },
      headerStyle: { fontSize: "10px" },
    },
    {
      Header: "CveInterventor",
      minWidth: 85,
      id: "CveInterventor",
      accessor: (d) => d.CveInterventor,
      filterAll: false,
      style: { fontSize: "10px" },
      headerStyle: { fontSize: "10px" },
    },
    {
      Header: "Localidad",
      minWidth: 130,
      id: "Localidad",
      accessor: (d) => d.Localidad,
      filterAll: false,
      style: { fontSize: "10px" },
      headerStyle: { fontSize: "10px" },
    },
    {
      Header: "Responsable",
      minWidth: 130,
      id: "Responsable",
      accessor: (d) => d.Responsable,
      filterAll: false,
      style: { fontSize: "10px" },
      headerStyle: { fontSize: "10px" },
    },
    {
      Header: "Aprobados",
      minWidth: 70,
      id: "Aprobados",
      accessor: (d) => d.Aprobados,
      filterAll: false,
      style: { fontSize: "10px" },
      headerStyle: { fontSize: "10px" },
    },
    {
      Header: "Pineados",
      minWidth: 70,
      headerClassName: "text-center",
      id: "Pineados",
      accessor: (d) => d.Pineados,
      filterAll: false,
      style: { fontSize: "10px" },
      headerStyle: { fontSize: "10px" },
    },
    {
      Header: "Pendientes",
      minWidth: 80,
      headerClassName: "text-center",
      id: "Pendientes",
      accessor: (d) => d.Pendientes,
      filterAll: false,
      style: { fontSize: "10px" },
      headerStyle: { fontSize: "10px" },
    },
  ];

  const handleMiniClick = () => {
    document.body.classList.toggle("sidebar-mini");
  };

  useEffect(() => {
    setUserData(getVars("Token"));
  }, []);

  useEffect(() => {
    if (isNullOrUndefined(userData)) return;
    const menus = userData.menu;
    const currentMenu = menus.find((menu) => menu.idMenu === 25);
    if (isNullOrUndefined(currentMenu) || currentMenu.Ver < 1) {
      swal({
        title: "Atención",
        text: "No tiene permisos para este módulo, contacte al administrador",
        icon: "warning",
      });
      history.push("/not-found");
      return;
    }
    setMenu({
      Ver: currentMenu.Ver,
      Agregar: currentMenu.Agregar,
      Editar: currentMenu.Editar,
      Eliminar: currentMenu.Eliminar,
      Seguimiento: currentMenu.Seguimiento,
      Exportar: currentMenu.Exportar,
      Imprimir: currentMenu.Imprimir,
      ViewAll: currentMenu.ViewAll,
      idTipoUser: userData.user.idTipoUser.id,
    });
    setMenuLoaded(true);
  }, [userData]);

  useEffect(() => {
    setLoadingRemesa(true);
    const token = getVars("Token");
    EjecutarApi({ ejercicio: 2023 }, `vales/getRemesasAll`, token.token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.success && res.results) {
          let remesas = res.data;
          setRemesa(remesas);
        } else {
          swal({
            title: "Ha ocurrido un error",
            text: res.errors,
            icon: "warning",
          });
        }
        setLoadingRemesa(false);
      })
      .catch((error) => {
        setLoadingRemesa(false);
        swal({
          title: "¡Atención!",
          text: error.message,
          icon: "warning",
        });
      });
  }, []);

  //   const getMunicipio = (remesa) => {
  //     setLoadingMunicipio(true);
  //     const token = getVars("Token");
  //     if (remesa === "") {
  //       swal({
  //         title: "¡Atención!",
  //         text: `Debe seleccionar la remesa a consultar`,
  //         icon: "warning",
  //       });
  //       setLoadingMunicipio(false);
  //       return;
  //     }

  //     EjecutarApi(
  //       { remesa: remesa },
  //       `vales/getMunicipiosValesRemesas`,
  //       token.token
  //     )
  //       .then(function (res) {
  //         return res;
  //       })
  //       .then((res) => {
  //         if (res.success && res.results) {
  //           let municipios = res.data;
  //           setMunicipio(municipios);
  //         } else {
  //           swal({
  //             title: "Ha ocurrido un error",
  //             text: res.errors,
  //             icon: "warning",
  //           });
  //         }
  //         setLoadingMunicipio(false);
  //       })
  //       .catch((error) => {
  //         setLoadingMunicipio(false);
  //         swal({
  //           title: "Ha ocurrido un error",
  //           text: error.errors,
  //           icon: "warning",
  //         });
  //       });
  //   };

  const handleChangeRemesa = (remesa) => {
    if (remesa) {
      setForm({
        ...form,
        Remesa: remesa,
        CveInterventor: "",
      });
      getCveInterventor(remesa);
    } else {
      setForm({
        ...form,
        Remesa: "",
        CveInterventor: "",
        Responsable: "",
      });
    }
  };

  //   const handleChangeMunicipio = (municipio) => {
  //     setForm({
  //       ...form,
  //       Municipio: municipio,
  //     });
  //     getCveInterventor(municipio);
  //   };

  const handleChangeCveInterventor = (cveInterventor) => {
    setForm({
      ...form,
      CveInterventor: cveInterventor,
      Responsable: "",
    });
    getResponsablesEntrega(cveInterventor);
  };

  //   const handleChangeLocalidad = (localidad) => {
  //     setForm({
  //       ...form,
  //       Localidad: localidad,
  //     });
  //     getResponsablesEntrega(localidad);
  //   };

  const getCveInterventor = (remesa) => {
    setLoadingCveInterventor(true);
    const token = getVars("Token");
    const data = {
      remesa: remesa,
    };
    EjecutarApi(data, `vales/getCveInterventor`, token.token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.success && res.results) {
          let cveInterventor = res.data;
          setCveInterventor(cveInterventor);
        } else {
          swal({
            title: "Ha ocurrido un error",
            text: res.errors,
            icon: "warning",
          });
        }
        setLoadingCveInterventor(false);
      })
      .catch((error) => {
        setLoadingCveInterventor(false);
        swal({
          title: "Ha ocurrido un error",
          text: error.errors,
          icon: "warning",
        });
      });
  };

  //   const getLocalidad = (cveInterventor) => {
  //     setLoadingLocalidad(true);
  //     const token = getVars("Token");
  //     const data = {
  //       remesa: form.Remesa,
  //       municipio: form.Municipio,
  //       cveInterventor: cveInterventor,
  //     };
  //     EjecutarApi(data, `vales/getLocalidad`, token.token)
  //       .then(function (res) {
  //         return res;
  //       })
  //       .then((res) => {
  //         if (res.success && res.results) {
  //           let localidades = res.data;
  //           setLocalidad(localidades);
  //         } else {
  //           swal({
  //             title: "Ha ocurrido un error",
  //             text: res.errors,
  //             icon: "warning",
  //           });
  //         }
  //         setLoadingLocalidad(false);
  //       })
  //       .catch((error) => {
  //         setLoadingLocalidad(false);
  //         swal({
  //           title: "Ha ocurrido un error",
  //           text: error.errors,
  //           icon: "warning",
  //         });
  //       });
  //   };

  const getResponsablesEntrega = (cveInterventor) => {
    setLoadingResponsable(true);
    const token = getVars("Token");
    const data = {
      remesa: form.Remesa,
      cveInterventor: cveInterventor,
    };
    EjecutarApi(data, `vales/getResponsablesEntrega`, token.token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.success && res.results) {
          let responsables = res.data;
          setResponsable(responsables);
        } else {
          swal({
            title: "Ha ocurrido un error",
            text: res.errors,
            icon: "warning",
          });
        }
        setLoadingResponsable(false);
      })
      .catch((error) => {
        setLoadingResponsable(false);
        swal({
          title: "Ha ocurrido un error",
          text: error.errors,
          icon: "warning",
        });
      });
  };

  //   const btnDescargar = (folio) => {
  //     console.log(folio);
  //     const token = getVars("Token");
  //     var url = new URL(
  //       URL_GENERAL +
  //         `getAcuseUnico?folio=${folio.id}&token=${token.token}&ejercicio=${folio.Ejercicio}`
  //     );
  //     window.open(url, "_blank");
  //     swal({
  //       title: "Descarga completa",
  //       text: "Se genero correctamente el acuse",
  //       icon: "success",
  //     });
  //   };

  //   const btnDescargarSol = (folio) => {
  //     console.log(folio);
  //     const token = getVars("Token");
  //     var url = new URL(
  //       URL_GENERAL +
  //         `vales/getSolicitudesValeUnico?folio=${folio.id}&token=${token.token}`
  //     );
  //     window.open(url, "_blank");
  //     swal({
  //       title: "Descarga completa",
  //       text: "Se genero correctamente la solicitud",
  //       icon: "success",
  //     });
  //   };

  const btnBuscar = () => {
    setLoadingCedulaList(true);
    if (form.Remesa === "") {
      setLoadingCedulaList(false);
      swal({
        title: "!Atención!",
        text: "Debe seleccionar la remesa a consultar",
        icon: "warning",
      });
      return;
    }
    if (form.CveInterventor === "") {
      setLoadingCedulaList(false);
      swal({
        title: "!Atención!",
        text: "Debe seleccionar un CveInterventor válido",
        icon: "warning",
      });
      return;
    }

    if (form.Responsable === "") {
      setLoadingCedulaList(false);
      swal({
        title: "!Atención!",
        text: "Debe seleccionar un Responsable válido",
        icon: "warning",
      });
      return;
    }
    const token = getVars("Token");
    const data = {
      remesa: form.Remesa,
      cveInterventor: form.CveInterventor,
      responsable: form.Responsable,
    };
    EjecutarApi(data, "vales/getGruposRevision", token.token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.success && res.results) {
          if (res.data.length === 0) {
            swal({
              title: "!Atención!",
              text: res.message,
              icon: "warning",
            });
          }
          setDataCedulaList(
            res.data.map((item) => {
              return {
                ...item,
                Pendientes: item.Aprobados - item.Pineados,
              };
            })
          );
          setTotal(res.total);
        } else {
          swal({
            title: "!Atención!",
            text: res.message,
            icon: "warning",
          });
        }
        setLoadingCedulaList(false);
      })
      .catch((e) => {
        console.log(e);
        setLoadingCedulaList(false);
        swal({
          title: "Ha ocurrido un error",
          text: "Contacte al administrador",
          icon: "warning",
        });
      });
  };

  return (
    <>
      <div className="wrapper">
        <Sidebar {...props} bgColor="black" activeColor="info" />
        <div className="main-panel">
          <Navbar {...props} handleMiniClick={handleMiniClick} />
          <Notifications />
          <div className="content">
            <>
              <Row>
                <Col md="12">
                  <Card>
                    <CardBody>
                      <Row>
                        <Col sm="3">
                          <FormGroup>
                            <Label>Remesa:</Label>
                            <span className="text-danger">*</span>
                            {loadingRemesa && <Spinner size="sm" />}
                            <Input
                              name="Remesa"
                              type="select"
                              disabled={loadingRemesa}
                              value={form.Remesa}
                              onChange={(e) => {
                                handleChangeRemesa(e.target.value);
                              }}
                            >
                              <option value={""}></option>
                              {remesa.map((element) => (
                                <option
                                  key={element.RemesaSistema}
                                  value={element.RemesaSistema}
                                >
                                  {element.RemesaSistema}
                                </option>
                              ))}
                            </Input>
                          </FormGroup>
                        </Col>
                        {/* <Col sm="4">
                          <FormGroup>
                            <Label>Municipio:</Label>
                            <span className="text-danger">*</span>
                            {loadingMunicipio && <Spinner size="sm" />}
                            <Input
                              name="Remesa"
                              type="select"
                              disabled={form.Remesa === ""}
                              value={form.Municipio}
                              onChange={(e) => {
                                handleChangeMunicipio(e.target.value);
                              }}
                            >
                              <option value={""}></option>
                              {municipio.map((element) => (
                                <option
                                  key={element.value}
                                  value={element.value}
                                >
                                  {element.label}
                                </option>
                              ))}
                            </Input>
                          </FormGroup>
                        </Col> */}
                        <Col sm="3">
                          <FormGroup>
                            <Label>CveInterventor:</Label>
                            <span className="text-danger">*</span>
                            {loadingCveInterventor && <Spinner size="sm" />}
                            <Input
                              name="CveInterventor"
                              type="select"
                              disabled={form.Remesa === ""}
                              value={form.CveInterventor}
                              onChange={(e) => {
                                handleChangeCveInterventor(e.target.value);
                              }}
                            >
                              <option value={""}></option>
                              {cveInterventor.map((element) => (
                                <option
                                  key={element.CveInterventor}
                                  value={element.CveInterventor}
                                >
                                  {element.CveInterventor}
                                </option>
                              ))}
                            </Input>
                          </FormGroup>
                        </Col>
                        <Col sm="4">
                          <FormGroup>
                            <Label>Reponsable:</Label>
                            <span className="text-danger">*</span>
                            {loadingResponsable && <Spinner size="sm" />}
                            <Input
                              name="Responsable"
                              type="select"
                              disabled={form.CveInterventor === ""}
                              value={form.Responsable}
                              onChange={(e) => {
                                setForm({
                                  ...form,
                                  Responsable: e.target.value,
                                });
                              }}
                            >
                              <option value={""}></option>
                              {responsable.map((element) => (
                                <option
                                  key={element.Responsable}
                                  value={element.Responsable}
                                >
                                  {element.Responsable}
                                </option>
                              ))}
                            </Input>
                          </FormGroup>
                        </Col>
                        <Col
                          sm="2"
                          style={{ display: "grid", placeItems: "center" }}
                        >
                          <Button
                            color="primary"
                            size="sm"
                            style={{ width: "100%" }}
                            onClick={() => {
                              btnBuscar();
                            }}
                          >
                            Buscar
                          </Button>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col sm="12">
                  <Card>
                    <CardBody>
                      <Col lg="12" md="12">
                        {menuLoaded && (
                          <ReactTable
                            size="sm"
                            data={dataCedulaList}
                            noDataText="No Existen Datos a Mostrar!"
                            manual
                            columns={columns}
                            className="-striped -highlight primary-pagination"
                            loading={loadingCedulaList}
                            showPagination={false}
                            showPaginationBottom={true}
                            showTotalPages={true}
                            showPageJump={false}
                            canNextFromData={true}
                            defaultPageSize={pageOptions.pageSize}
                            total={total}
                            page={pageOptions.page}
                            pages={Math.floor(total / pageOptions.pageSize)}
                            previousText="Anterior"
                            nextText="Siguiente"
                            ofText="de"
                            pageSizeOptions={[10, 20, 25, 50, 100]}
                            PaginationComponent={Pagination}
                          />
                        )}
                      </Col>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Footer fluid />
            </>
          </div>
        </div>
      </div>
    </>
  );
};
export default RevisionGrupo;
