import React, { useState } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Label,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Col,
  Row,
} from "reactstrap";
import Notifications, { notify } from "react-notify-toast";
import { useHistory } from "react-router-dom";
import { MetroSpinner } from "react-spinners-kit";
import swal from "sweetalert";
import { setVars } from "../GlobalStorage";
import { URL_GENERAL, VERSION } from "../data/consts";

const Login = () => {
  const [Username, setUsername] = useState("");
  const [Passwd, setPasswd] = useState("");
  const [loading, setLoading] = useState(false);
  let history = useHistory();

  const signIn = () => {
    setLoading(true);
    if (Username.length < 10) {
      notify.show("Error: Usuario requerido.", "custom", 5000, {
        background: "#0857B6",
        fontSize: 14,
        text: "#FFFFFF",
      });
      setLoading(false);
      return true;
    }

    if (Passwd.length < 6) {
      notify.show("Error: Contraseña requerida.", "custom", 5000, {
        background: "#0857B6",
        fontSize: 14,
        text: "#FFFFFF",
      });
      setLoading(false);
      return true;
    }

    if (!validateUser(Username)) {
      const data = {
        email: Username,
        password: Passwd,
        message: "",
      };

      const requestInfo = {
        method: "POST",
        body: JSON.stringify(data),
        headers: new Headers({
          "Content-Type": "application/json",
        }),
      };
      fetch(URL_GENERAL + "login", requestInfo)
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          throw new Error("Login inválido");
        })
        .then((loginRS) => {
          console.log("=>loginRS: ", loginRS);
          setLoading(false);

          if (loginRS.results) {
            setVars("Token", loginRS);

            history.push(
              loginRS.user.defaultPage ? loginRS.user.defaultPage : "/"
            );
          } else {
            swal({
              title: "¡Atención!",
              text: `${loginRS.message}`,
              icon: "error",
            });
          }
        })
        .catch((e) => {
          console.error("Error de login: ", e.message);
          setLoading(false);
          swal({
            title: "¡Atención!",
            text: `${e.message}`,
            icon: "error",
          });
        });
    } else {
      swal({
        title: "¡Atención!",
        text: "El Usuario ingresado es inválido.",
        icon: "error",
      });

      setLoading(false);
      return true;
    }
  };

  const validateUser = (value) => {
    const pattern = /['=%;-?!¡"`+]/;
    console.log(value.match(pattern));
    return value.match(pattern) ? true : false;
  };

  return (
    <div className="wrapper wrapper-full-page">
      <div className="full-page section-image">
        <Notifications />
        <div className="login-page">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" lg="4" md="6">
                <Form className="form" onSubmit={signIn}>
                  <Card className="card-login">
                    <CardHeader>
                      <CardHeader>
                        <h3 className="header text-center">Iniciar Sesión</h3>
                      </CardHeader>
                    </CardHeader>
                    <CardBody>
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="nc-icon nc-single-02" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Username"
                          id="Username"
                          type="text"
                          onChange={(e) => setUsername(e.target.value)}
                        />
                      </InputGroup>
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="nc-icon nc-key-25" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Password"
                          id="Passwd"
                          type="password"
                          autoComplete="off"
                          security="*"
                          onChange={(e) => setPasswd(e.target.value)}
                          onKeyPress={(event) => {
                            if (event.key === "Enter") {
                              signIn();
                            }
                          }}
                        />
                      </InputGroup>
                      <br />
                      <FormGroup>
                        <FormGroup check>
                          <Label check>Acceso a personal autorizado</Label>
                        </FormGroup>
                      </FormGroup>
                    </CardBody>
                    <CardFooter>
                      {!loading && (
                        <Button
                          block
                          className="btn-round mb-3"
                          color="warning"
                          onClick={signIn}
                        >
                          Iniciar Sesión
                        </Button>
                      )}

                      {loading && (
                        <MetroSpinner
                          size={30}
                          color="#686769"
                          loading={loading}
                        />
                      )}
                      <Label className="text-center">{VERSION}</Label>
                    </CardFooter>
                  </Card>
                </Form>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </div>
  );
};

export default Login;
