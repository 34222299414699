import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

Highcharts.setOptions({
  lang: {
    thousandsSep: ",",
    numericSymbols: null,
  },
  colors: ["#2e86c1", "#f4d03f", "#e74c3c", "#aab7b8", "#229954"],
});
const BarGraphHorizontal = (props) => {
  const { labels, data, dataMetas, title, vielabel = true } = props;

  const getOptions = (type) => ({
    chart: {
      type: "bar",
      style: {
        fontSize: "30%",
      },
    },
    title: {
      text: title,
      align: "center",
    },
    subtitle: {
      text: "Vales Grandeza ",
      align: "center",
    },
    lang: {
      thousandsSep: ",",
      numericSymbols: null,
    },
    xAxis: {
      categories: labels,
      gridLineWidth: 1,
      lineWidth: 0,
      labels: {
        enabled: vielabel,
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: "Vales pineados",
        align: "high",
      },
      labels: {
        overflow: "justify",
      },
      gridLineWidth: 2,
    },
    plotOptions: {
      bar: {
        borderRadius: "1%",
        dataLabels: {
          enabled: true,
        },
        groupPadding: 0.1,
      },
      //   column: {
      //     dataLabels: {
      //       enabled: true,
      //       rotation: -90,
      //       align: "right",
      //     },
      //   },
    },
    legend: {
      enabled: vielabel,
      layout: "vertical",
      align: "right",
      verticalAlign: "top",
      x: -40,
      y: 80,
      floating: true,
      borderWidth: 1,
      backgroundColor:
        Highcharts.defaultOptions.legend.backgroundColor || "#FFFFFF",
      shadow: true,
    },
    credits: {
      enabled: false,
    },
    series: [
      {
        name: "Meta",
        data: dataMetas,
      },
      {
        name: "Pineados",
        data: data,
      },
    ],
  });
  return (
    <>
      {
        <HighchartsReact
          highcharts={Highcharts}
          options={getOptions("column")}
        />
      }
    </>
  );
};
export default BarGraphHorizontal;
