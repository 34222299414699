import React, { useState, Fragment, useEffect } from "react";
import { Col, Row, FormGroup, Label, Input, Alert } from "reactstrap";
import moment from "moment";
import ReactDatetime from "react-datetime";
import { CircleSpinner } from "react-spinners-kit";
import { notify } from "react-notify-toast";
import { Stats } from "./Stats";
import EjecutarApi from "../../Consultas/Consultas";
import { getVars, setVars } from "../../GlobalStorage";

const FirstStep = (props) => {
  const steps = getVars("StepForm");
  const [formData, setFormData] = useState({
    FechaEntrega: moment(),
    CveInterventor: "",
    Folios: [],
  });
  const [errors, setErrors] = useState({
    FechaEntrega: "",
    CveInterventor: "",
    Folios: "",
  });
  const [valid, setValid] = useState(false);
  const [loadingValidate, setloadingValidate] = useState(false);
  const [cveData, setCveData] = useState({
    Cve: "",
    Region: "",
    idMunicipio: "",
    Municipio: "",
    Fecha: "",
  });

  useEffect(() => {
    if (!isEmptyOrNullObject(steps)) {
      setCveData({
        Cve: steps.Cve,
        Region: steps.Region,
        idMunicipio: steps.idMunicipio,
        Municipio: steps.Municipio,
        Fecha: steps.Fecha,
      });
      setFormData({
        FechaEntrega: moment(steps.FechaEntrega),
        CveInterventor: steps.CveInterventor,
        Folios: steps.Folios,
      });
      setValid(steps.step > 1);

      if (steps.step === props.totalSteps) {
        props.goToStep(props.totalSteps);
      }
    } else {
      init();
    }
    //eslint-disable-next-line
  }, []);

  const init = () => {
    setFormData({
      FechaEntrega: moment(),
      CveInterventor: "",
      Folios: [],
    });
    setErrors({
      FechaEntrega: "",
      CveInterventor: "",
      Folios: "",
    });
    setValid(false);
    setCveData({
      Cve: "",
      Region: "",
      idMunicipio: "",
      Municipio: "",
      Fecha: "",
    });
    setVars("StepForm", {
      ...formData,
      ...cveData,
      step: 1,
    });
  };
  const isEmptyOrNullObject = (data) => {
    for (var key in data) {
      return false;
    }
    return true;
  };

  const regexValidationCode = (value) => {
    const pattern = /^01_2023_[1-9]{1}([0-9]{0,1})_[1-6]{1}([0-9]{0,1})$/;
    return value.match(pattern) ? true : false;
  };

  const handleChangeFormData = (e) => {
    const value = e.target.value;
    const name = e.target.name;

    setFormData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleBlurFormData = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    switch (name) {
      case "CveInterventor":
        setErrors((prevState) => ({
          ...prevState,
          CveInterventor: regexValidationCode(value)
            ? ""
            : "Ingrese un código válido",
        }));

        if (regexValidationCode(value)) {
          validateCveFromServer(value);
        }
        break;

      default:
        break;
    }
  };

  const validateCveFromServer = (value) => {
    setloadingValidate(true);
    const token = getVars("Token").token;
    const data = {
      CveInterventor: value,
      Fecha: moment(formData.FechaEntrega).format("YYYY-MM-DD"),
    };

    EjecutarApi(data, "vales/validateCveInterventor", token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.results) {
          setValid(true);
          setCveData({
            Cve: res.data.Cve,
            Region: res.data.Region,
            idMunicipio: res.data.idMuncipio,
            Municipio: res.data.Municipio,
            Fecha: moment(formData.FechaEntrega).format("YYYY-MM-DD"),
          });
        }
      })
      .catch((e) => {
        notify.show(e.message, "custom", 7000, {
          background: "#0857B6",
          fontSize: 14,
          text: "#FFFFFF",
        });
      });
    setloadingValidate(false);
  };

  const validate = () => {
    if (valid && formData.FechaEntrega) {
      setVars("StepForm", {
        ...formData,
        ...cveData,
        step: 2,
      });
      props.nextStep();
    }
  };

  return (
    <Fragment>
      <Row>
        <Col sm={12} md={6} lg={6}>
          <FormGroup>
            <Label>CveInterventor</Label>
            <Input
              autoFocus={true}
              type="text"
              name="CveInterventor"
              id="CveInterventor"
              placeholder="Ingrese el CveInterventor"
              value={formData.CveInterventor}
              onChange={handleChangeFormData}
              onBlur={handleBlurFormData}
              className={`form-control ${
                errors.CveInterventor.length > 0 && "is-invalid"
              }`}
            />
            {errors.CveInterventor.length > 0 && (
              <div className="invalid-feedback">{errors.CveInterventor}</div>
            )}
          </FormGroup>
        </Col>
        <Col sm={12} md={6} lg={6}>
          <FormGroup>
            <Label>Fecha de entrega</Label>
            <ReactDatetime
              id="FechaEntrega"
              name="FechaEntrega"
              dateFormat="YYYY-MM-DD"
              maxDate={new Date("2023-03-01")}
              minDate={new Date("2023-12-22")}
              timeFormat={false}
              closeOnSelect={true}
              onBlur={(e) => {
                if (typeof e === "string") {
                  setErrors((prevState) => ({
                    ...prevState,
                    FechaEntrega: "La Fecha de Entrega es requerida",
                  }));
                }
              }}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  FechaEntrega: moment(e._d).format("YYYY-MM-DD"),
                })
              }
              inputProps={{
                className: `form-control ${
                  errors.FechaEntrega.length > 0 && "is-invalid"
                }`,
              }}
              value={formData.FechaEntrega}
            />
            {errors.FechaEntrega.length > 0 && (
              <div className="invalid-feedback">{errors.FechaEntrega}</div>
            )}
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col md={6} lg={6} sm={12}>
          {loadingValidate && (
            <CircleSpinner
              className="text-center"
              size={30}
              color="#686769"
              loading={loadingValidate}
            />
          )}
          {valid && (
            <Alert variant="success">
              <h6 className="alert-heading">Validado con éxito</h6>
              <p>
                <strong>Región: </strong>
                {cveData.Region}
              </p>
              <p>
                <strong>Municipio: </strong>
                {cveData.Municipio}
              </p>
              <p>
                <strong>Cveinterventor: </strong>
                {cveData.Cve}
              </p>
            </Alert>
          )}
        </Col>
      </Row>

      <Row>
        <Col sm={12}>
          <Stats
            step={1}
            {...props}
            nextStep={validate}
            disabledNext={loadingValidate || !valid}
          />
        </Col>
      </Row>
    </Fragment>
  );
};

export default FirstStep;
