import React from "react";
import { Route } from "react-router-dom";
import { Redirect } from "react-router-dom";
import { getVars } from "./GlobalStorage";

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const pathname = window.location.pathname;
  if (pathname !== "/fecha-entrega-vales") {
    sessionStorage.removeItem("StepForm");
  }
  if (pathname === "/encuesta-tj") {
    return <Route render={(props) => <Component {...props} />} {...rest} />;
  }

  return (
    <Route
      render={(props) =>
        !getVars("Token").token ? (
          <Redirect to="/login" />
        ) : (
          <Component {...props} />
        )
      }
      {...rest}
    />
  );
};

export default ProtectedRoute;
