import React from "react";
import { Col, FormGroup, Label, Row, Card, CardImg } from "reactstrap";
import { Link } from "react-router-dom";

const FileListAd = ({ files }) => {
  return (
    <Row style={{ width: "100%" }}>
      {files &&
        files.map((file) => (
          <>
            <Col sm="6" style={{ border: "1px solid" }}>
              <Col
                sm="12"
                xs="12"
                style={{
                  display: "grid",
                  placeItems: "center",
                }}
              >
                {" "}
                {file && (
                  <Link
                    className={(isActive) =>
                      "nav-link" + (!isActive ? " unselected" : "")
                    }
                    onClick={(e) => {
                      e.preventDefault();
                      window.open(file.ruta, "_blank");
                    }}
                    style={{
                      fontWeight: "bold",
                      fontSize: "12px",
                      paddingTop: "1rem",
                    }}
                  >
                    {file.Clasificacion}
                  </Link>
                )}
              </Col>
              <Col
                sm="12"
                xs="12"
                style={{
                  display: "grid",
                  placeItems: "center",
                }}
              >
                <>
                  {file &&
                    file.lastModified &&
                    (file.type === "application/pdf" ? (
                      <object
                        type="application/pdf"
                        aria-labelledby={file.name}
                        data={URL.createObjectURL(file)}
                        width="100%"
                        height="250"
                      ></object>
                    ) : (
                      <CardImg
                        variant="top"
                        src={URL.createObjectURL(file)}
                        style={{ height: "250px", width: "85%" }}
                      ></CardImg>
                    ))}
                  {file && file.type === "image" ? (
                    <Card>
                      <CardImg
                        variant="top"
                        src={file.ruta}
                        style={{ height: "300px" }}
                      ></CardImg>
                    </Card>
                  ) : (
                    file.type === "pdf" && (
                      <div
                        display="flex"
                        style={{
                          width: "100%",
                          border: "1px solid #999",
                          height: "300px",
                        }}
                      >
                        <object
                          type="application/pdf"
                          aria-labelledby={file.NombreOriginal}
                          data={file.ruta}
                          width="100%"
                          height="300"
                        ></object>
                      </div>
                    )
                  )}
                </>
              </Col>
            </Col>
          </>
        ))}
    </Row>
  );
};

export default FileListAd;
