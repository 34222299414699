import React, { useState, useEffect } from 'react';
// react plugin used to create switch buttons
import { CircleSpinner } from 'react-spinners-kit';
import Select from 'react-select';
import { Button, Input } from 'reactstrap';
import './style_map.css';

import Notifications, { notify } from 'react-notify-toast';
import EjecutarApi from '../../Consultas/Consultas';
import { getVars } from '../../GlobalStorage';

function PluginFilter (props){
   
    const token = getVars('Token').token;
 
    const [classes,setclasses]=useState('dropdown');
    const [ClaveUnica,setClaveUnica] = useState('');
    const [RFC,setRFC] = useState('');
    const [Empresa,setEmpresa] = useState('');
    const [Contacto,setContacto] = useState([]);
    const [MultiValueRegion,setMultiValueRegion] = useState([]);
    const [MultiValueRegion2,setMultiValueRegion2] = useState([]);
    const [MultiValueMuninicpio,setMultiValueMunicipio] = useState([]);
    const [MultiValueMuninicpio2,setMultiValueMunicipio2] = useState([]);
    const [MultiValueMunicipioNombre,setMultiValueMunicipioNombre] = useState([]);
    
    const [Colonia, setColonia] = useState('');
    const [Calle,setCalle] = useState('');
    const [NumExt,setNumExt] = useState('');
    const [NumInt,setNumInt] = useState('');
    const [CP,setCP] = useState('');
    const [MultiValueTipoNegocio, setMultiValueTipoNegocio] = useState([]);
    const [MultiValueTipoNegocio2, setMultiValueTipoNegocio2] = useState([]);
    
    const [Correo,setCorreo] = useState('');

    const [Celular, setCelular] = useState('');
    const [TelNegocio, setTelNegocio] = useState('');
    const [TelCasa, setTelCasa] = useState('');
    const [MultiValueEstatus, setMultiValueEstatus]= useState([]);
    const [MultiValueTipoGiro, setMultiValueTipoGiro]= useState([]);
    const [MultiValueTipoGiro2, setMultiValueTipoGiro2]= useState([]);
    
    const [filterEstatus, setfilterEstatus] = useState([
        { value: '1', label: 'Por Verificar' },
        { value: '2', label: 'Suspendido' },
        { value: '3', label: 'Cancelado' },
        { value: '4', label: 'Validado' },
     
    ]);
    
    const [FechaInscripcion,setFechaInscripcion]=useState('');
    const [HorarioAtencion,setHorarioAtencion]=useState('');
    const [isLoading,setisLoading] = useState(false);
    const [Filtered,setFiltered] = useState([]);
    const datos = {
        page: 0,
        tipo: 'and',
        pageSize: 500,
        sorted: [],
        filtered: [],
    };
    useEffect(()=>{
        /**Region: regiones,
                                Colonia: data.Colonia,
                                Municipios: municipios ,
                                TipoNegocio: tipos */
        //Tipos
        EjecutarApi(datos, 'getNegociosTipo', token)
            .then(function (res) {
                return res;
            })
            .then((res) => {
                if (res.results) {

                    setMultiValueTipoNegocio(res.data.map((tipo,i) => {
                        
                        return {
                            "value": tipo["id"],
                            "label": tipo["Tipo"]
                        }
                    }));
                }
            })
            .catch((e) => {
                notify.show(`Error en la busqueda! ${e.message}`, 'custom', 5000, {
                    background: '#d61818',
                    text: '#FFFFFF',
                });
            });
        
        //Municipios
        EjecutarApi(datos, 'getMunicipiosET', token)
            .then(function (res) {
                return res;
            })
            .then((res) => {
                if (res.results) {
                    var region = [];
                    setMultiValueMunicipio(res.data.map((municipio,i) => {
                        
                        if (region.indexOf(municipio["SubRegion"]) === -1) {
                            
                            region.push(municipio["SubRegion"]);
                        }
                        return {
                            value: municipio["Id"],
                            label: municipio["Nombre"]
                        }
                    }));
                    var regio_n = region.sort((a, b) => a - b);
                    setMultiValueRegion(regio_n.map(region_ => {
                        return { "value": region_, "label": region_ }
                    }));
                }
            })
            .catch((e) => {
                notify.show(`Error en la busqueda! ${e.message}`, 'custom', 5000, {
                    background: '#d61818',
                    text: '#FFFFFF',
                });
            });    

            EjecutarApi(datos, 'getGiros', token)
            .then(function (res) {
                return res;
            })
            .then((res) => {
                if (res.results) {
                    setMultiValueTipoGiro(res.data.map((giro,i) => {
                    
                        return {
                            value: giro["id"],
                            label: giro["Giro"]
                        }
                    }));
                }
            })
            .catch((e) => {
                notify.show(`Error en la busqueda! ${e.message}`, 'custom', 5000, {
                    background: '#d61818',
                    text: '#FFFFFF',
                });
            });

    },[]);

    
   
    
    const handleMultiEstatus = (option) => {
        setMultiValueEstatus(option);
    };
    const setFilter= (event) =>{
        event.preventDefault();
        const myFiltro = [];
        if (ClaveUnica.length) myFiltro.push({ id: 'ClaveUnica', value: ClaveUnica });
        if (Empresa.length) myFiltro.push({ id: 'NombreEmpresa', value: Empresa });
        if (Contacto.length) myFiltro.push({ id: 'Contacto', value: Contacto });

        
    
       
        //MultiValueRegion
        var x = 0;
        

        if (MultiValueRegion2 != null && MultiValueRegion2.length !== 0 && !MultiValueRegion2.MultiValueRegion2)
            myFiltro.push({ id: 'idRegion', value: MultiValueRegion2 });
        else
            myFiltro.push({ id: 'idRegion', value: [] });
        
        //MultiValueMuninicpio
        if (MultiValueMuninicpio2 != null && MultiValueMuninicpio2.length !== 0 && !MultiValueMuninicpio2.MultiValueMuninicpio2)
            myFiltro.push({ id: 'idMunicipio', value: MultiValueMuninicpio2 });
        else
            myFiltro.push({ id: 'idMunicipio', value: [] });
        //MultiValueMuninicpio
        if (MultiValueMunicipioNombre != null && MultiValueMunicipioNombre.length !== 0 && !MultiValueMunicipioNombre.MultiValueMunicipioNombre)
			for (x = 0; x < MultiValueMunicipioNombre.length; x++)
                myFiltro.push({ id: 'idMunicipioNombre', value: MultiValueMunicipioNombre[x] });
        //TipoNegocio
        if (MultiValueTipoNegocio2 != null && MultiValueTipoNegocio2.length !== 0 && !MultiValueTipoNegocio2.MultiValueTipoNegocio2)
            myFiltro.push({ id: 'idTipoNegocio', value: MultiValueTipoNegocio2 });
        else
            myFiltro.push({ id: 'idTipoNegocio', value: [] });
        if (MultiValueTipoGiro2 != null && MultiValueTipoGiro2.length !== 0 && !MultiValueTipoGiro2.MultiValueTipoNegocio2)
            myFiltro.push({ id: 'idTipoGiro', value: MultiValueTipoGiro2 });
        else
            myFiltro.push({ id: 'idTipoGiro', value: [] });
        
           

      
        setFiltered(myFiltro);
       

        props.filtrar(myFiltro,ClaveUnica);
    };

    const handleClick =()=> {
		if (classes === 'dropdown') {
			setclasses('dropdown show');
		} else {
			setclasses('dropdown');
		}
    };
    
   

    return(
        <div className="fixed-plugin">

            <form onSubmit={setFilter}>
                <div className={classes}>
                    <div onClick={handleClick}>
                        <i className="fa fa-cog fa-2x" />
                    </div>
                    <ul className="dropdown-menu show ul">
                        <li className="header-title">Filtrar Resultados</li>
                        <li className="adjustments-line">
                            <Input
                                placeholder="Folio Comercio"
                                id="FolioComercio"
                                value={ClaveUnica}
                                type="text"

                                onChange={e => setClaveUnica(e.target.value)}
                            />
                        
                        </li>
                        
                        <li className="adjustments-line">
                        
                            <Input
                                placeholder="Empresa"
                                id="Empresa"
                                value={Empresa}
                                type="text"
                                onChange={e => setEmpresa(e.target.value)}
                            />
                        
                        </li>
                        <li className="adjustments-line">
                            <Input
                                placeholder="Contacto"
                                id="Contacto"
                                value={Contacto}
                                type="text"
                                onChange={e => setContacto(e.target.value)}
                            />
                        </li>
                        
                        <li className="li">
                            <Select
                                className="SelectSize"
                                name="Region"
                                placeholder="Región"
                                //value={MultiValueRegion}
                                options={MultiValueRegion}
                                onChange={(event,i) => {
                                                                
                                if(event){
                                    
                                    setMultiValueRegion2(
                                        
                                            event.map(dato =>  { return dato.value })
                                        )
                                                
                                }
                                else{
                                    setMultiValueRegion2({ MultiValueRegion2: MultiValueRegion2.map(dato => { 
                                        if(i.removedValue.value!==dato){
                                            return dato.value 
                                        }
                                        
                                    }) 
                                    })
                                    //setMultiValueRegion2({ MultiValueRegion2: MultiValueRegion2.filter(dato => (dato!=="undefined" && dato!==i.removedValue.value ))})
                                    //setMultiValueRegion2({ MultiValueRegion2:MultiValueRegion2.map(dato => dato.value)});
                                }
                            }} 

                                isMulti
                            />
                        </li>
                        <li className="adjustments-line" />
                        <li className="li">
                            <Select
                                className="SelectSize"
                                name="Municipio"
                                placeholder="Municipio"
                                //value={MultiValueMuninicpio}
                                options={MultiValueMuninicpio}
                                onChange={(event,i) => {
                                                                
                                    if(event){
                                    
                                        setMultiValueMunicipio2(
                                            
                                                event.map(dato =>  { return dato.value })
                                            )
                                        setMultiValueMunicipioNombre(event.map(dato =>  { return dato.label }))
                                                    
                                    }
                                    else{
                                        setMultiValueMunicipio2({ MultiValueMuninicpio2: MultiValueMuninicpio2.map(dato => { 

                                            if(i.removedValue.value!==dato){
                                                return dato.value 
                                            }
                                            
                                        })

                                        })
                                        setMultiValueMunicipioNombre({ MultiValueMunicipioNombre: MultiValueMunicipioNombre.map(dato => { 

                                            if(i.removedValue.label!==dato){
                                                return dato.label 
                                            }
                                            
                                        })
                                        })
                                    }
                                }} 

                                isMulti
                            />
                        </li>
                        
                        <li className="adjustments-line" />
                        <li className="li">
                                <Select
                                    name="Tipo Negocio"
                                    className="SelectSize"
                                    placeholder="Tipo Negocio"
                                    //value={MultiValueTipoNegocio}
                                    options={MultiValueTipoNegocio}
                                    onChange={(event,i) => {
                                        console.log('llegaaqui');                                     
                                        if(event){
                                            console.log('llegaaqui');  
                                            setMultiValueTipoNegocio2(
                                                
                                                    event.map(dato =>  { return dato.value })
                                                )
                                                        
                                        }
                                        else{
                                            setMultiValueTipoNegocio2({ MultiValueTipoNegocio2: MultiValueTipoNegocio2.map(dato => { 
                                                if(i.removedValue.value!==dato){
                                                    return dato.value 
                                                }
                                                
                                            }) 
                                            })
                                        }
                                    }} 
                                    isMulti
                                />
                            </li>

                        
                            <li className="adjustments-line" />
                        <li className="li">
                                <Select
                                    name="Tipo Giros"
                                    className="SelectSize"
                                    placeholder="Tipo Giros"
                                    options={MultiValueTipoGiro}
                                    onChange={(event,i) => {                                    
                                        if(event){ 
                                            setMultiValueTipoGiro2(
                                                
                                                    event.map(dato =>  { return dato.value })
                                                )
                                                        
                                        }
                                        else{
                                            setMultiValueTipoGiro2({ MultiValueTipoGiro: MultiValueTipoGiro.map(dato => { 
                                                if(i.removedValue.value!==dato){
                                                    return dato.value 
                                                }
                                                
                                            }) 
                                            })
                                        }
                                    }} 
                                    isMulti
                                />
                            </li>
                        
                    
                        <li className="button-container">
                            <span>
                                {!isLoading && (
                                    <Button color="info" block className="btn-round" type="submit">
                                        Filtrar
                                    </Button>
                                )}
                                {isLoading && (
                                    <CircleSpinner
                                        className="text-center"
                                        size={30}
                                        color="#686769"
                                        loading={isLoading}
                                    />
                                )}
                            </span>
                        </li>
                    </ul>
                </div>
            </form>
        </div>
    );
}
export default PluginFilter;