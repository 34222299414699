import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Table,
} from "reactstrap";
import ReactBSAlert from "react-bootstrap-sweetalert";
import Select from "react-select";
import EjecutarApi from "../Consultas/Consultas";
import { RotateSpinner, ImpulseSpinner } from "react-spinners-kit";
import { getVars } from "../GlobalStorage";
import Notifications, { notify } from "react-notify-toast";
import ReactDatetime from "react-datetime";
import moment from "moment";
import { Link } from "react-router-dom";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Sidebar from "../components/Sidebar";
import DateTimeComponent from "../components/DateTimeComponent";
import ReactTable from "react-table";

import catOcupacion from "../data/catOcupacion.json";

const ValesAdd = (props) => {
  const token = getVars("Token");
  //console.log ('@@@Token', token);
  const [flag, setFlag] = useState(false);
  const [flagSet, setFlagSet] = useState(false);
  const [otraColonia, setOtraColonia] = useState("");
  const [municipios, setMunicipios] = useState([]);
  const [localidades, setLocalidades] = useState([]);
  const [colonias, setColonias] = useState([]);
  const [Articulador, setArticulador] = useState(
    token.user.Nombre + " " + token.user.Paterno + " " + token.user.Materno
  );
  const [idArticulador, setidArticulador] = useState(token.user.id);
  const [nomMunicipio, setNomMunicipio] = useState("");
  //const [dataArticulador,setdataArticulador] = useState([]);
  const [loading, setloading] = useState(false);
  const [ban, setban] = useState(false);
  const [ClaveUnica, setClaveUnica] = useState("");

  const [data, setData] = useState([]);
  const [totalColonias, setTotalColonias] = useState(0);
  //const [loading, setLoading] = useState(false);

  const [flagEscogerColonia, setFlagEscogerColonia] = useState(false);
  const [flagEscogerArticulador, setflagEscogerArticulador] = useState(false);
  const [NoIngresos, setNoIngresos] = useState(false);
  const [solicitudes, setSolicitudes] = useState([]);

  const refBtnSave = useRef(null);

  /*const [vale, setVale] = useState({
        FechaSolicitud: "",
        CURP: "",
        Nombre: "",
        Paterno: "",
        Materno: "",
        Sexo: "",
        FechaNacimiento: Date.now(),
        Calle: "conocida",
        NumInt: "1212",
        NumExt: "1212",
        Colonia: "centro",
        CP: '36800',
        idMunicipio: '1',
        idLocalidad: '1',
        TelFijo: "9615790521",
        TelCelular: "9615790521",
        TelRecado:"9615790521",
        CompaniaCelular:"9615790521",
        CorreoElectronico: "madelinne@gmail.com",
        idStatus: 1,
        UserOwned:idArticulador
    });*/
  const [vale, setVale] = useState({
    FechaSolicitud: "",
    CURP: "",
    Nombre: "",
    Paterno: "",
    Materno: "",
    Ocupacion: { value: "", label: "" },
    OcupacionOtro: "",
    Sexo: "",
    FechaNacimiento: "",
    Calle: "",
    NumInt: "",
    NumExt: "",
    Colonia: "",
    CP: "",
    idMunicipio: "",
    idLocalidad: "",
    TelFijo: "",
    TelCelular: "",
    TelRecado: "",
    Compania: "",
    CorreoElectronico: "",
    idStatus: 1,
    IngresoPercibido: 0,
    OtrosIngresos: 0,
    NumeroPersonas: 0,
    UserOwned: idArticulador,
    idArticulador: idArticulador,
  });

  const optcompania = [
    { value: "0", label: "Seleccionar una Compañía Telefónica" },
    { value: "Telcel", label: "Telcel" },
    { value: "Megacable", label: "Megacable" },
    { value: "Iusacell", label: "Iusacell" },
    { value: "Movistar", label: "Movistar" },
    { value: "at&t", label: "at&t" },
    { value: "Unefon", label: "Unefon" },
  ];
  const datos = {
    page: 0,
    tipo: "and",
    pageSize: 1000,
    sorted: [],
    filtered: [],
  };
  const datosColonia = {
    page: 0,
    tipo: "and",
    pageSize: 9999,
    sorted: [],
    filtered: [
      {
        id: "d_codigo",
        value: vale.CP,
      },
      {
        id: "D_mnpio",
        value: nomMunicipio,
      },
    ],
  };

  const Spinenerstyle = {
    height: "50pt",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  useEffect(() => {
    if (
      vale.Ocupacion.value === "Desempleado (a)" ||
      vale.Ocupacion.value === "Ama de casa"
    ) {
      setNoIngresos(true);
      setVale({ ...vale, IngresoPercibido: 0 });
    } else {
      setNoIngresos(false);
    }
  }, [vale.Ocupacion.value]);

  useEffect(() => {
    EjecutarApi(datos, "getMunicipiosET", token.token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.results) {
          setMunicipios(
            res.data.map((municipio) => {
              return {
                value: municipio["Id"],
                label: municipio["Nombre"],
              };
            })
          );
        }
      })
      .catch((e) => {
        notify.show(`Error en la busqueda! ${e.message}`, "custom", 5000, {
          background: "#d61818",
          text: "#FFFFFF",
        });
      });

    //ObteniendoArticuladores
    var datoArt = {
      page: 0,
      tipo: "and",
      pageSize: 400,
      sorted: [],
      filtered: [{ id: "idTipoUser", value: 9 }],
    };
    //document.body.classList.toggle('sidebar-mini')
    //document.body(("selectSexo" ) => ({ value: vale.Sexo }));
    //setItems(body.results.map(({ name }) => ({ label: name, value: name })));
  }, []);

  const handleMiniClick = () => {
    document.body.classList.toggle("sidebar-mini");
  };

  async function setValeEventDisabled() {
    let res = await setTimeout(function () {
      return true;
    }, 100);
    if (res) {
      setFlagSet(true);
    }
  }
  async function setValeEventEnabled() {
    let res = await setTimeout(function () {
      return true;
    }, 10);
    if (res) {
      setFlagSet(false);
    }
  }

  const setValeEvent = () => {
    setValeEventDisabled();
    console.log("Vales:", vale);
    let btnSend = document.querySelector("#btnSave");
    btnSend.setAttribute("disabled", "");

    if (vale.Nombre.length < 1) {
      setValeEventEnabled();
      btnSend.removeAttribute("disabled");

      notify.show("Error: Nombre es requerido", "custom", 5000, {
        background: "#0857B6",
        fontSize: 14,
        text: "#FFFFFF",
      });
      return true;
    }

    if (vale.FechaSolicitud.length < 3) {
      setValeEventEnabled();
      btnSend.removeAttribute("disabled");
      notify.show("Error: La fecha de solicitud es requerida", "custom", 5000, {
        background: "#0857B6",
        fontSize: 14,
        text: "#FFFFFF",
      });
      return true;
    }

    if (vale.Ocupacion.value === "Otro") {
      if (vale.Ocupacion.value.length < 3) {
        setValeEventEnabled();
        btnSend.removeAttribute("disabled");
        notify.show(
          "Error: La fecha de solicitud es requerida",
          "custom",
          5000,
          {
            background: "#0857B6",
            fontSize: 14,
            text: "#FFFFFF",
          }
        );
        return true;
      }
    } else {
      if (vale.Ocupacion.value.length < 3) {
        setValeEventEnabled();
        btnSend.removeAttribute("disabled");
        notify.show(
          "Error: La fecha de solicitud es requerida",
          "custom",
          5000,
          {
            background: "#0857B6",
            fontSize: 14,
            text: "#FFFFFF",
          }
        );
        return true;
      }
    }

    // setVale({
    //   ...vale,
    //   Ejercicio: moment(vale.FechaSolicitud).format("Y"),
    //   Colonia:
    //     vale.Colonia !== "" && vale.Colonia !== "otro"
    //       ? vale.Colonia
    //       : otraColonia,
    // });

    if (vale.FechaNacimiento.length < 3) {
      setValeEventEnabled();
      btnSend.removeAttribute("disabled");
      notify.show(
        "Error: La fecha de nacimiento es requerida",
        "custom",
        5000,
        {
          background: "#0857B6",
          fontSize: 14,
          text: "#FFFFFF",
        }
      );
      return true;
    }
    if (vale.idMunicipio.length < 1) {
      setValeEventEnabled();
      btnSend.removeAttribute("disabled");
      notify.show("Error: Municipio es requerido", "custom", 5000, {
        background: "#0857B6",
        fontSize: 14,
        text: "#FFFFFF",
      });
      return true;
    }
    if (vale.idLocalidad.length < 1) {
      setValeEventEnabled();
      btnSend.removeAttribute("disabled");
      notify.show("Error: Localidad es requerido", "custom", 5000, {
        background: "#0857B6",
        fontSize: 14,
        text: "#FFFFFF",
      });
      return true;
    }
    if (vale.Calle.length < 3) {
      setValeEventEnabled();
      btnSend.removeAttribute("disabled");
      notify.show("Error: Calle es requerido", "custom", 5000, {
        background: "#0857B6",
        fontSize: 14,
        text: "#FFFFFF",
      });
      return true;
    }

    if (vale.CP.length != 5) {
      setValeEventEnabled();
      btnSend.removeAttribute("disabled");
      notify.show(
        "Error: Codigo Postal es requerido. El codigo postal debe tener 5 caracteres.",
        "custom",
        5000,
        {
          background: "#0857B6",
          fontSize: 14,
          text: "#FFFFFF",
        }
      );
      return true;
    }
    if (vale.NumExt.length < 1) {
      setValeEventEnabled();
      btnSend.removeAttribute("disabled");
      notify.show("Error: Numero Exterior es requerido", "custom", 5000, {
        background: "#0857B6",
        fontSize: 14,
        text: "#FFFFFF",
      });
      return true;
    }
    if (vale.CURP.length === 0) {
      if (!curpValida(vale.CURP)) {
        setValeEventEnabled();
        btnSend.removeAttribute("disabled");
        notify.show(
          "Error: La CURP del cobrador es requerida y valida",
          "custom",
          5000,
          {
            background: "#0857B6",
            fontSize: 14,
            text: "#FFFFFF",
          }
        );
        return true;
      }
    }

    //console.log('=> Telefono celular',vale.TelCelular.length);

    if (vale.TelCelular.length !== 0) {
      if (
        (vale.TelCelular.length > 0 && vale.TelCelular.length < 10) ||
        vale.TelCelular.length > 10 ||
        vale.TelCelular.length == 0
      ) {
        setValeEventEnabled();
        btnSend.removeAttribute("disabled");
        notify.show(
          "Error: El teléfono celular debe ser de 10 digitos.",
          "custom",
          5000,
          {
            background: "#0857B6",
            fontSize: 14,
            text: "#FFFFFF",
          }
        );
        return true;
      }
    }
    /*
        if ((vale.TelRecado.length > 0 && vale.TelRecado.length < 10 ) || (vale.TelRecado.length > 10 ) || (vale.TelRecado.length == 0 )) 
        {
            setFlagSet(false)
                notify.show('Error: El teléfono recados debe ser de 10 digitos.', 'custom', 5000, {
                    background: '#0857B6',
                    fontSize: 14,
                    text: '#FFFFFF',
                });
                return true;
        } 
        */
    if (vale.Compania.length !== 0) {
      if (vale.Compania.length < 3) {
        setValeEventEnabled();
        btnSend.removeAttribute("disabled");
        notify.show("Error: La compañía no es requerida.", "custom", 5000, {
          background: "#0857B6",
          fontSize: 14,
          text: "#FFFFFF",
        });
        return true;
      }
    }

    if (vale.NumeroPersonas < 1) {
      setValeEventEnabled();
      btnSend.removeAttribute("disabled");
      notify.show(
        "Error: La numero de personas dependientes del ingreso mensual es requerida.",
        "custom",
        5000,
        {
          background: "#0857B6",
          fontSize: 14,
          text: "#FFFFFF",
        }
      );
      return true;
    }

    //console.log("VALE STATE::::::::", vale);
    //if (!flagObligatorio) {
    //    console.log("Si esta en el EJECUTAR");

    const dataVales = {
      FechaSolicitud: vale.FechaSolicitud,
      Ejercicio: moment(vale.FechaSolicitud).format("Y"),
      CURP: vale.CURP,
      Ocupacion:
        vale.Ocupacion.value === "Otro"
          ? vale.OcupacionOtro
          : vale.Ocupacion.value,
      OcupacionOtro: vale.Ocupacion.value === "Otro" ? 1 : 0,
      Nombre: vale.Nombre,
      Paterno: vale.Paterno,
      Materno: vale.Materno,
      Sexo: vale.Sexo,
      FechaNacimiento: vale.FechaNacimiento,
      Calle: vale.Calle,
      NumInt: vale.NumInt,
      NumExt: vale.NumExt,
      Colonia:
        vale.Colonia !== "" && vale.Colonia !== "otro"
          ? vale.Colonia
          : otraColonia,
      CP: vale.CP,
      idMunicipio: vale.idMunicipio,
      idLocalidad: vale.idLocalidad,
      TelFijo: vale.TelFijo,
      TelCelular: vale.TelCelular,
      TelRecado: vale.TelRecado,
      Compania: vale.Compania,
      CorreoElectronico: vale.CorreoElectronico,
      idStatus: 1,
      IngresoPercibido: NoIngresos ? 0 : vale.IngresoPercibido,
      OtrosIngresos: vale.OtrosIngresos,
      NumeroPersonas: vale.NumeroPersonas,
      UserOwned: vale.UserOwned,
      idArticulador: vale.idArticulador,
    };
    console.log("=>VALEVALE:", JSON.stringify(dataVales));
    console.log("=>token.token:", token.token);
    // setFlagSet(false);
    btnSend.removeAttribute("disabled");
    // return true;
    EjecutarApi(dataVales, "setVales", token.token)
      .then(function (res) {
        if (res.results) {
          setFlagSet(false);
          btnSend.removeAttribute("disabled");
          //ClaveUnica
          setClaveUnica(res.data.ClaveUnica);
          setban(true);
          setVale({
            ...vale,
            FechaSolicitud: "" + "-" + "" + "-" + "",
            CURP: "",
            Nombre: "",
            Paterno: "",
            Materno: "",
            Sexo: "",
            FechaNacimiento: "",
            Calle: "",
            NumInt: "",
            NumExt: "",
            TelFijo: "",
            TelCelular: "",
            TelRecado: "",
            CorreoElectronico: "",
          });

          notify.show("Registro Exitoso! ", "custom", 5000, {
            background: "#18c97d",
            text: "#FFFFFF",
          });
          return res;
        } else {
          notify.show(
            `Error en registro de vales! ${res.message}`,
            "custom",
            5000,
            {
              background: "#d61818",
              text: "#FFFFFF",
            }
          );
        }
      })
      .catch((e) => {
        console.log("errrorrrrrrrrr", e);
        setFlagSet(false);
        btnSend.removeAttribute("disabled");
        notify.show(
          `Error en registro de vales! ${e.message}`,
          "custom",
          5000,
          {
            background: "#d61818",
            text: "#FFFFFF",
          }
        );
      });
    //}
    setFlagSet(false);
    btnSend.removeAttribute("disabled");
  };
  const alerta = (
    <ReactBSAlert
      style={{ display: "block", marginTop: "-100px" }}
      title={"Solicitud inscrita con la Clave Única : " + ClaveUnica}
      onConfirm={() => setban(false)}
      confirmBtnBsStyle="info"
    />
  );

  const municipioChange = (mun) => {
    if (mun) {
      const datos_localidades = {
        page: 0,
        tipo: "and",
        pageSize: 1000,
        sorted: [],
        filtered: [
          {
            id: "IdMunicipio",
            value: mun,
          },
        ],
      };

      EjecutarApi(datos_localidades, "getLocalidadET", token.token)
        .then(function (res) {
          return res;
        })
        .then((res) => {
          if (res.results) {
            //setData(res.data);
            setLocalidades(
              res.data.map((status_) => {
                return {
                  value: status_["Id"],
                  label: status_["Nombre"],
                };
              })
            );
          }
        })
        .catch((e) => {
          notify.show(`Error en la busqueda! ${e.message}`, "custom", 5000, {
            background: "#d61818",
            text: "#FFFFFF",
          });
        });
    }
  };

  const coloniasChange = () => {
    setFlag(true);
    EjecutarApi(datosColonia, "getCP", token.token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.results) {
          var colonias_ = res.data.map((cp) => {
            return {
              value: cp["d_asenta"],
              label: cp["d_asenta"],
            };
          });

          colonias_.push({
            value: "otro",
            label: "otro",
          });
          setColonias(colonias_);
          setFlag(true);
        }
      })
      .catch((e) => {
        notify.show(`Error en la busqueda! ${e.message}`, "custom", 5000, {
          background: "#d61818",
          text: "#FFFFFF",
        });
      });
  };

  function curpValida(curp) {
    var re =
        /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/,
      validado = curp.match(re);

    if (!validado)
      //Coincide con el formato general?
      return false;

    //Validar que coincida el dígito verificador
    function digitoVerificador(curp17) {
      //Fuente https://consultas.curp.gob.mx/CurpSP/
      var diccionario = "0123456789ABCDEFGHIJKLMNÑOPQRSTUVWXYZ",
        lngSuma = 0.0,
        lngDigito = 0.0;
      for (var i = 0; i < 17; i++)
        lngSuma = lngSuma + diccionario.indexOf(curp17.charAt(i)) * (18 - i);
      lngDigito = 10 - (lngSuma % 10);
      if (lngDigito == 10) return 0;
      return lngDigito;
    }

    if (validado[2] != digitoVerificador(validado[1])) return false;

    return true; //Validado
  }
  const getSolicitudes = (curp) => {
    const token = getVars("Token").token;
    EjecutarApi({ CURP: curp }, "getSolicitudesPorCURP", token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        setSolicitudes(
          res.data.map((prop, key) => {
            return {
              FechaSolicitud: prop.FechaSolicitud,
              Folio: prop.Folio,
            };
          })
        );
      })
      .catch((e) => {
        setloading(false);
        notify.show(
          `Error al obtener solicitudes: ${e.errors}`,
          "custom",
          5000,
          {
            background: "#0857B6",
            fontSize: 14,
            text: "#FFFFFF",
          }
        );
      });
  };
  const getCURP = () => {
    setloading(true);
    console.log("=> Buscar CURP");
    if (!vale.CURP) {
      setloading(false);
      notify.show(`Error: CURP inválida!`, "custom", 5000, {
        background: "#d61818",
        text: "#FFFFFF",
      });
      return true;
    }

    if (vale.CURP.length != 18) {
      setloading(false);
      notify.show(`Error: CURP inválida!`, "custom", 5000, {
        background: "#d61818",
        text: "#FFFFFF",
      });
      return true;
    }

    const CURP = vale.CURP.toUpperCase();
    getSolicitudes(vale.CURP);
    var sexo_obtenido;
    console.log("=> Buscar CURP: ", CURP);
    fetch(
      `https://seguimiento.guanajuato.gob.mx/apiinformacionsocial/api/renapo/porcurp/pL@t_1n|Run$28/${CURP}/7`
    )
      .then((res) => res.json())
      .then((dataRS) => {
        setloading(false);
        console.log("=> Resultados CURP", dataRS);
        if (dataRS.Mensaje == "OK") {
          //aqui
          var FN = dataRS.Resultado.fechNac.split("/");

          console.log("=FNAC: ", FN); //año-mes-dia
          console.log("@@@valores SEXO", dataRS.Resultado.sexo);
          sexo_obtenido = dataRS.Resultado.sexo;
          setVale({
            ...vale,
            Nombre: dataRS.Resultado.nombres,
            Paterno: dataRS.Resultado.apellido1,
            Materno: dataRS.Resultado.apellido2,
            Sexo: dataRS.Resultado.sexo === "H" ? "M" : "F", // { value: "M", label:"MASCULINO"} : { value: "F", label:"FEMENINO"} , //{ label: dataRS.Resultado.sexo, value: dataRS.Resultado.sexo === 'H' ? 'M' : 'F' },//"M",//dataRS.Resultado.sexo === 'H' ? 'M' : 'F',
            FechaNacimiento: FN[2] + "-" + FN[1] + "-" + FN[0],
          });
          console.log("=>valores CURP", vale);
        } else {
          setloading(false);
          if (
            dataRS.Mensaje === "La CURP no se encuentra en la base de datos"
          ) {
            console.log("La CURP no se encuentra en la base de datos");
            notify.show(`Error en CURP...! ${dataRS.Mensaje}`, "custom", 5000, {
              background: "#d61818",
              text: "#FFFFFF",
            });
          } else if (
            dataRS.Mensaje ===
            "El campo CURP: No cumple con el formato especificado."
          ) {
            console.log(
              "El campo CURP: No cumple con el formato especificado."
            );
            notify.show(`Error en CURP...! ${dataRS.Mensaje}`, "custom", 5000, {
              background: "#d61818",
              text: "#FFFFFF",
            });
          } else {
            console.log("El servicio no esta disponible.");
          }
          return true;
        }
        console.log("=> Resultados CURP", dataRS);
      })
      .catch((err) => {
        setloading(false);
        console.log("=>Error en CURO: ", err);
      });
    //
  };

  const mini_tableArticulador = (
    <>
      <ReactBSAlert
        style={{ display: "block", marginTop: "-250pt" }}
        title="Articulador"
        onConfirm={() => setflagEscogerArticulador(false)}
        onCancel={() => setflagEscogerArticulador(false)}
        confirmBtnBsStyle="info"
      >
        <Card className="mb-2 text-muted">
          <CardBody className="mb-2 text-muted">
            <ReactTable
              data={data}
              noDataText="¡No existen datos a mostrar!"
              columns={[
                {
                  Header: "Articulador",
                  minWidth: 10,
                  id: "idArticulador",
                  accessor: (d) => d.Articulador,
                  filterAll: false,
                },
              ]}
              defaultPageSize={5}
              filterable
              className={"-striped -highlight primary-pagination"}
              loading={loading}
              showPagination={true}
              showPageJump={false}
              previousText="Anterior"
              nextText="Siguiente"
              canNextFromData={true}
              manual
              total={totalColonias}
              pages={Math.floor(totalColonias / 10)}
              previousText="Anterior"
              nextText="Siguiente"
              pageSizeOptions={[10, 20, 25, 50, 100]}
              getTrProps={(state, rowInfo, column, instance) => {
                return {
                  onClick: (e) => {
                    console.log("@#@#@--- Datos", rowInfo.original.data);
                    setVale({
                      ...vale,
                      idArticulador: rowInfo.original.data.id,
                    });
                    //setArticulador(rowInfo.original.data.Nombre !==null ? rowInfo.original.data.Nombre:'' + rowInfo.original.data.Paterno!== null ?rowInfo.original.data.Paterno:'' + rowInfo.original.data.Materno !== null?rowInfo.original.data.Materno:'');
                    setArticulador(
                      rowInfo.original.data.Nombre +
                        " " +
                        rowInfo.original.data.Paterno +
                        " " +
                        rowInfo.original.data.Materno
                    );
                    setflagEscogerArticulador(false);
                  },
                };
              }}
              onFetchData={(stateComp, instance) => {
                setloading(true);
                const token = getVars("Token").token;

                //if (stateComp.filtered.length != 0) {
                //  array_filtros.push({ id: 'NombreCompleto', value: stateComp.filtered[0].value })
                //}
                const datax = {
                  page: stateComp.page,
                  tipo: "and",
                  pageSize: stateComp.pageSize,
                  sorted: [{ id: "Nombre", desc: false }],
                  filtered: [],
                  NombreCompleto:
                    stateComp.filtered.length != 0
                      ? stateComp.filtered[0].value
                      : "",
                };
                console.log(
                  "=>ValesAdd->getUsersArticuladores->datax",
                  JSON.stringify(datax)
                );
                EjecutarApi(datax, "getUsersArticuladores", token)
                  .then(function (res) {
                    return res;
                  })
                  .then((res) => {
                    setloading(false);
                    setTotalColonias(res.total);
                    setData(
                      res.data.map((prop, key) => {
                        return {
                          Articulador: `${
                            prop.Nombre !== null ? prop.Nombre : ""
                          } ${prop.Paterno !== null ? prop.Paterno : ""} ${
                            prop.Materno !== null ? prop.Materno : ""
                          }`,
                          data: prop,
                        };
                      })
                    );
                  })
                  .catch((e) => {
                    console.log("=Error[getArticuladores]: ", e);
                    setloading(false);
                    notify.show(
                      `Error al obtener colonias: ${e.errors}`,
                      "custom",
                      5000,
                      {
                        background: "#0857B6",
                        fontSize: 14,
                        text: "#FFFFFF",
                      }
                    );
                  });
              }}
            />
          </CardBody>
        </Card>
      </ReactBSAlert>
    </>
  );

  const mini_table = (
    <>
      <ReactBSAlert
        className="fontSize: 8px"
        style={{ display: "block", marginTop: "-250pt" }}
        title="Colonia"
        onConfirm={() => setFlagEscogerColonia(false)}
        onCancel={() => setFlagEscogerColonia(false)}
        confirmBtnBsStyle="info"
      >
        <Card>
          <CardBody>
            <ReactTable
              className="-striped -highlight primary-pagination"
              data={data}
              noDataText="Debe Seleccionar un Municipio"
              columns={[
                {
                  Header: "Colonia",
                  minWidth: 10,
                  id: "Colonia",
                  accessor: (d) => d.Colonia,
                  filterAll: false,
                },
              ]}
              defaultPageSize={5}
              filterable
              loading={loading}
              showPagination={true}
              showPageJump={false}
              previousText="Anterior"
              nextText="Siguiente"
              canNextFromData={true}
              manual
              total={totalColonias}
              pages={Math.floor(totalColonias / 10)}
              previousText="Anterior"
              nextText="Siguiente"
              pageSizeOptions={[10, 20, 25, 50, 100]}
              getTrProps={(state, rowInfo, column, instance) => {
                return {
                  onClick: (e) => {
                    setVale({
                      ...vale,
                      Colonia: rowInfo.original.Colonia,
                      CP: rowInfo.original.data.d_codigo,
                    });
                    setFlagEscogerColonia(false);
                  },
                };
              }}
              onFetchData={(stateComp, instance) => {
                setloading(true);
                const token = getVars("Token").token;

                var array_filtros = [];
                array_filtros.push({ id: "c_estado", value: 11 });
                array_filtros.push({ id: "c_mnpio", value: vale.idMunicipio });
                if (stateComp.filtered.length != 0) {
                  array_filtros.push({
                    id: "d_asenta",
                    value: stateComp.filtered[0].value,
                  });
                }
                const datax = {
                  page: stateComp.page,
                  tipo: "and",
                  pageSize: stateComp.pageSize,
                  sorted: [{ id: "d_asenta", desc: false }],
                  filtered: array_filtros,
                };

                EjecutarApi(datax, "getCP", token)
                  .then(function (res) {
                    return res;
                  })
                  .then((res) => {
                    setloading(false);
                    setTotalColonias(res.total);
                    setData(
                      res.data.map((prop, key) => {
                        return {
                          Colonia: prop.d_asenta,
                          data: prop,
                        };
                      })
                    );
                  })
                  .catch((e) => {
                    console.log("=Error[getCP]: ", e);
                    setloading(false);
                    notify.show(
                      `Error al obtener colonias: ${e.errors}`,
                      "custom",
                      5000,
                      {
                        background: "#0857B6",
                        fontSize: 14,
                        text: "#FFFFFF",
                      }
                    );
                  });
              }}
            />
          </CardBody>
        </Card>
      </ReactBSAlert>
    </>
  );

  //console.log('@#@#@--- Datos', optcompania[0].label);
  return (
    <>
      <div className="wrapper">
        <Sidebar {...props} bgColor="black" activeColor="info" />
        <div className="main-panel">
          <Navbar {...props} handleMiniClick={handleMiniClick} />
          <Notifications />
          <div className="content">
            <Row>
              <Col md="12">
                <Card>
                  <CardHeader>
                    <Link to="vales">
                      <Button color="success">Regresar</Button>
                    </Link>
                  </CardHeader>
                  <CardBody>
                    <Form className="form-horizontal" id="TypeValidation">
                      <Notifications />
                      <Card>
                        <CardHeader>
                          <CardTitle tag="h4">Inscripción de Vales</CardTitle>
                        </CardHeader>

                        <CardBody>
                          <CardTitle tag="h6">Articulador</CardTitle>
                          <Row>
                            <Col sm="4">
                              <FormGroup>
                                <Input
                                  id="articu"
                                  type="text"
                                  value={Articulador}
                                  disabled
                                />
                              </FormGroup>
                            </Col>
                            <Col sm="2">
                              <FormGroup>
                                <Button
                                  onClick={() => {
                                    setflagEscogerArticulador(true);
                                  }}
                                >
                                  Buscar
                                </Button>
                                {flagEscogerArticulador ? (
                                  mini_tableArticulador
                                ) : (
                                  <></>
                                )}
                              </FormGroup>
                            </Col>
                          </Row>

                          <Row>
                            <Col>
                              <h6>Datos del Solicitante</h6>

                              <Row>
                                <Label sm="3">
                                  Fecha de Solicitud:
                                  <span className="text-danger">*</span>
                                </Label>
                                <Col sm="9">
                                  <FormGroup>
                                    <DateTimeComponent
                                      id="FechaSolicitud"
                                      name="FechaSolicitud"
                                      dateFormat="YYYY-MM-DD"
                                      maxDate={new Date("2021-12-31")}
                                      minDate={new Date("2021-01-30")}
                                      timeFormat={false}
                                      closeOnSelect={true}
                                      onChange={(e) =>
                                        setVale({
                                          ...vale,
                                          FechaSolicitud: moment(e._d).format(
                                            "YYYY-MM-DD"
                                          ),
                                        })
                                      }
                                      inputProps={{ className: "form-control" }}
                                      defaultValue={vale.FechaSolicitud}
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>
                              <Row>
                                <Label sm="3">CURP:</Label>
                                <Col sm="6">
                                  <FormGroup>
                                    <Input
                                      maxLength={18}
                                      name="CURP"
                                      type="text"
                                      value={vale.CURP}
                                      onChange={(event) =>
                                        setVale({
                                          ...vale,
                                          CURP: event.target.value.toUpperCase(),
                                        })
                                      }
                                    />
                                  </FormGroup>
                                </Col>
                                <Col sm="3">
                                  <FormGroup>
                                    <Button color="primary" onClick={getCURP}>
                                      {loading ? (
                                        <ImpulseSpinner
                                          color={"#1261ff"}
                                          size={30}
                                          loading={loading}
                                        />
                                      ) : (
                                        ""
                                      )}

                                      {loading ? (
                                        <span>Validando</span>
                                      ) : (
                                        <span>Validar</span>
                                      )}
                                    </Button>
                                  </FormGroup>
                                </Col>
                              </Row>
                              <Row>
                                <Label sm="3">
                                  Nombre:<span className="text-danger">*</span>
                                </Label>
                                <Col sm="9">
                                  <FormGroup>
                                    <Input
                                      name="nombre"
                                      type="text"
                                      value={vale.Nombre}
                                      onChange={(event) =>
                                        setVale({
                                          ...vale,
                                          Nombre:
                                            event.target.value.toUpperCase(),
                                        })
                                      }
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>
                              <Row>
                                <Label sm="3">Apellido Paterno:</Label>
                                <Col sm="9">
                                  <FormGroup>
                                    <Input
                                      name="paterno"
                                      type="text"
                                      value={vale.Paterno}
                                      onChange={(event) =>
                                        setVale({
                                          ...vale,
                                          Paterno:
                                            event.target.value.toUpperCase(),
                                        })
                                      }
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>
                              <Row>
                                <Label sm="3">Apellido Materno:</Label>
                                <Col sm="9">
                                  <FormGroup>
                                    <Input
                                      name="materno"
                                      type="text"
                                      value={vale.Materno}
                                      onChange={(event) =>
                                        setVale({
                                          ...vale,
                                          Materno:
                                            event.target.value.toUpperCase(),
                                        })
                                      }
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>
                              <Row>
                                <Label sm="3">Sexo:</Label>
                                <Col sm="9">
                                  <FormGroup>
                                    <Input
                                      disabled
                                      className="react-select primary"
                                      //defaultValue={vale.Sexo.value === "M"? { value: "M", label: "MASCULINO" } :{ value: "F", label: "FEMENINO" }}
                                      id="sexo"
                                      value={
                                        vale.Sexo == "M"
                                          ? "Masculino"
                                          : vale.Sexo == ""
                                          ? ""
                                          : "Femenino"
                                      }
                                      placeholder="Seleccione Sexo"
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>
                              <Row>
                                <Label sm="3">
                                  Fecha de Nacimiento:
                                  <span className="text-danger">*</span>
                                </Label>
                                <Col sm="9">
                                  <FormGroup>
                                    <ReactDatetime
                                      id="FechaNacimiento"
                                      name="FechaNacimiento"
                                      dateFormat="YYYY-MM-DD"
                                      timeFormat={false}
                                      value={vale.FechaNacimiento}
                                      closeOnSelect
                                      onChange={(e) =>
                                        setVale({
                                          ...vale,
                                          FechaNacimiento: moment(e._d).format(
                                            "YYYY-MM-DD"
                                          ),
                                        })
                                      }
                                      inputProps={{ className: "form-control" }}
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>
                              <Row>
                                <Label sm="3">
                                  Ocupación:
                                  <span className="text-danger">*</span>
                                </Label>
                                <Col sm="9">
                                  <FormGroup>
                                    <Select
                                      className="react-select primary"
                                      id="Ocupacion"
                                      value={vale.Ocupacion}
                                      onChange={(event) =>
                                        setVale({ ...vale, Ocupacion: event })
                                      }
                                      options={catOcupacion.data}
                                      placeholder="seleccione su Ocupacion"
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>

                              {vale.Ocupacion.value === "Otro" && (
                                <Row>
                                  <Label sm="3">
                                    Otra Ocupación:
                                    <span className="text-danger">*</span>
                                  </Label>
                                  <Col sm="9">
                                    <FormGroup>
                                      <Input
                                        name="OcupacionOtro"
                                        type="text"
                                        value={vale.OcupacionOtro}
                                        onChange={(event) =>
                                          setVale({
                                            ...vale,
                                            OcupacionOtro:
                                              event.target.value.toUpperCase(),
                                          })
                                        }
                                      />
                                    </FormGroup>
                                  </Col>
                                </Row>
                              )}
                            </Col>

                            <Col>
                            {
                              solicitudes.length !== 0?
                              <>
                              <h6>Registros del solicitante</h6>
                              <Row>
                                <Card>
                                  <Table striped>
                                    <thead>
                                      <tr>
                                        <td>Fecha de Solicitud</td>
                                        <td>Folio</td>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {solicitudes.map((item, i) => {
                                        return (
                                          <tr>
                                            <td>{item.FechaSolicitud}</td>
                                            <td>{item.Folio}</td>
                                          </tr>
                                        );
                                      })}
                                    </tbody>
                                  </Table>
                                </Card>
                              </Row>
                              </>
                              :
                              <></>
                            }
                              
                            </Col>
                          </Row>

                          <h6>Datos de Ubicación Geográfica</h6>

                          <Row>
                            {municipios.length !== 0 ? (
                              <>
                                <Label sm="2">
                                  Municipio:
                                  <span className="text-danger">*</span>
                                </Label>
                                <Col sm="6">
                                  <FormGroup>
                                    <Select
                                      className="react-select primary"
                                      id="municipio"
                                      onChange={(event) => {
                                        setVale({
                                          ...vale,
                                          idMunicipio: event.value,
                                        });
                                        setNomMunicipio(event.value);
                                        municipioChange(event.value);
                                      }}
                                      options={municipios}
                                      placeholder="seleccione su municipio"
                                    />
                                  </FormGroup>
                                </Col>
                              </>
                            ) : (
                              <>
                                <Col sm="2" style={Spinenerstyle}>
                                  <RotateSpinner
                                    color={"#1261ff"}
                                    size={30}
                                    loading={true}
                                  />
                                </Col>
                              </>
                            )}
                          </Row>
                          <Row>
                            <Label sm="2">
                              Calle:<span className="text-danger">*</span>
                            </Label>
                            <Col sm="6">
                              <FormGroup>
                                <Input
                                  id="calle"
                                  type="text"
                                  value={vale.Calle}
                                  onChange={(event) =>
                                    setVale({
                                      ...vale,
                                      Calle: event.target.value,
                                    })
                                  }
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Label sm="2">
                              Número Exterior:
                              <span className="text-danger">*</span>
                            </Label>
                            <Col sm="2">
                              <FormGroup>
                                <Input
                                  id="numExt"
                                  type="text"
                                  value={vale.NumExt}
                                  onChange={(event) =>
                                    setVale({
                                      ...vale,
                                      NumExt: event.target.value,
                                    })
                                  }
                                />
                              </FormGroup>
                            </Col>
                            <Label sm="2">Número Interior</Label>
                            <Col sm="2">
                              <FormGroup>
                                <Input
                                  id="numInt"
                                  type="text"
                                  value={vale.NumInt}
                                  onChange={(event) =>
                                    setVale({
                                      ...vale,
                                      NumInt: event.target.value,
                                    })
                                  }
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Label sm="2">
                              Colonia:<span className="text-danger">*</span>
                            </Label>
                            <Col sm="4">
                              <FormGroup>
                                <Input
                                  name="Colonia"
                                  type="text"
                                  value={vale.Colonia}
                                  onChange={(event) =>
                                    setVale({
                                      ...vale,
                                      Colonia: event.target.value,
                                    })
                                  }
                                />
                              </FormGroup>
                            </Col>
                            <Col sm="2">
                              <Button
                                onClick={() => {
                                  setFlagEscogerColonia(true);
                                  console.log("ONCLIK");
                                }}
                              >
                                BUSCAR
                              </Button>
                              {flagEscogerColonia ? mini_table : <></>}
                            </Col>
                          </Row>
                          <Row>
                            <Label sm="2">
                              Localidad:<span className="text-danger">*</span>
                            </Label>
                            <Col sm="6">
                              <FormGroup>
                                <Select
                                  className="react-select primary"
                                  id="Localidad"
                                  onChange={(event) => {
                                    setVale({
                                      ...vale,
                                      idLocalidad: event.value,
                                    });
                                  }}
                                  options={localidades}
                                  placeholder="seleccione su localidad"
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Label sm="2">
                              Codigo Postal:
                              <span className="text-danger">*</span>
                            </Label>
                            <Col sm="4">
                              <FormGroup>
                                <Input
                                  name="cp"
                                  type="number"
                                  value={vale.CP}
                                  onChange={(event) =>
                                    setVale({ ...vale, CP: event.target.value })
                                  }
                                />
                              </FormGroup>
                            </Col>
                          </Row>

                          <CardTitle tag="h6">Datos del Contacto</CardTitle>
                          <Row>
                            <Label sm="2">Correo:</Label>
                            <Col sm="6">
                              <FormGroup>
                                <Input
                                  name="correo"
                                  type="email"
                                  value={vale.CorreoElectronico}
                                  onChange={(event) =>
                                    setVale({
                                      ...vale,
                                      CorreoElectronico: event.target.value,
                                    })
                                  }
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Label sm="2">Teléfono Fijo:</Label>
                            <Col sm="4">
                              <FormGroup>
                                <Input
                                  max="999999999"
                                  id="TelFijo"
                                  type="number"
                                  onChange={(event) =>
                                    setVale({
                                      ...vale,
                                      TelFijo: event.target.value,
                                    })
                                  }
                                  value={vale.TelFijo}
                                />
                              </FormGroup>
                            </Col>
                            <Label sm="2">Teléfono Recados:</Label>
                            <Col sm="4">
                              <FormGroup>
                                <Input
                                  maxLength={10}
                                  id="telRecados"
                                  type="number"
                                  onChange={(event) =>
                                    setVale({
                                      ...vale,
                                      TelRecado: event.target.value,
                                    })
                                  }
                                  value={vale.TelRecado}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Label sm="2">
                              Teléfono Celular:
                              {/* <span className="text-danger">*</span> */}
                            </Label>
                            <Col sm="4">
                              <FormGroup>
                                <Input
                                  max="999999999"
                                  min="1"
                                  id="celular"
                                  type="number"
                                  onChange={(event) =>
                                    setVale({
                                      ...vale,
                                      TelCelular: event.target.value,
                                    })
                                  }
                                  value={vale.TelCelular}
                                />
                              </FormGroup>
                            </Col>
                            <Label sm="2">
                              Compañía Celular:
                              {/* <span className="text-danger">*</span> */}
                            </Label>
                            <Col sm="4">
                              <FormGroup>
                                <Select
                                  className="react-select primary"
                                  name="ciaCelular"
                                  //defaultValue={{ value: optcompania[0].value, label: optcompania[0].label }}
                                  onChange={(event) => {
                                    setVale({ ...vale, Compania: event.label });
                                  }}
                                  options={optcompania}
                                  placeholder="Seleccione Compañía Telefónica"
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <CardTitle tag="h6">Datos de ingresos</CardTitle>
                          {!NoIngresos && (
                            <Row>
                              <Label sm="2">Ingreso mensual percibido:</Label>
                              <Col sm="6">
                                <FormGroup>
                                  <Input
                                    name="IngresoPercibido"
                                    type="text"
                                    pattern="[0-9]*"
                                    value={vale.IngresoPercibido}
                                    onChange={(event) =>
                                      setVale({
                                        ...vale,
                                        IngresoPercibido:
                                          event.target.value.replace(/\D/, ""),
                                      })
                                    }
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                          )}
                          <Row>
                            <Label sm="2">Otros Ingresos:</Label>
                            <Col sm="6">
                              <FormGroup>
                                <Input
                                  name="OtrosIngresos"
                                  type="text"
                                  pattern="[0-9]*"
                                  value={vale.OtrosIngresos}
                                  onChange={(event) =>
                                    setVale({
                                      ...vale,
                                      OtrosIngresos: event.target.value.replace(
                                        /\D/,
                                        ""
                                      ),
                                    })
                                  }
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Label sm="2">
                              Número de personas dependientes del ingreso
                              mensual total:
                            </Label>
                            <Col sm="6">
                              <FormGroup>
                                <Input
                                  name="NumeroPersonas"
                                  type="text"
                                  pattern="[0-9]*"
                                  value={vale.NumeroPersonas}
                                  onChange={(event) =>
                                    setVale({
                                      ...vale,
                                      NumeroPersonas:
                                        event.target.value.replace(/\D/, ""),
                                    })
                                  }
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          {/* {CharacterDropDown(vale.Sexo)}  */}
                        </CardBody>
                        <CardFooter className="text-center">
                          {!flagSet && (
                            <Button
                              id="btnSave"
                              name="btnSave"
                              color="primary"
                              onClick={setValeEvent}
                            >
                              {
                                <ImpulseSpinner
                                  color={"#1261ff"}
                                  size={30}
                                  loading={flagSet}
                                />
                              }

                              {flagSet ? (
                                <span>Registrando..</span>
                              ) : (
                                <span>Registrar</span>
                              )}
                            </Button>
                          )}
                          {flagSet && (
                            <ImpulseSpinner
                              color={"#1261ff"}
                              size={30}
                              loading={flagSet}
                            />
                          )}
                        </CardFooter>
                      </Card>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
          {ban ? alerta : <> </>}
          <Footer fluid />
        </div>
      </div>
    </>
  );
};
export default ValesAdd;

/**
 * defaultValue= {vale.CompaniaCelular==''?[{value: "0", label: "Seleccione Compañia Telefónica"}]:
                                                                                        vale.CompaniaCelular}
 * 
 * (vale.Sexo === 'F') ? ({ label: 'Femenino', value: 'F' }) : (vale.Sexo === 'M') ? ({ label: 'Masculino', value: 'M' }): ({ label: 'Seleccione Sexo', value: '' })
 */
