import React, { useState, useEffect, useRef } from "react";
import { Fragment } from "react";
import {
  Col,
  Row,
  FormGroup,
  Label,
  Input,
  InputGroup,
  InputGroupAddon,
  Button,
  Table,
} from "reactstrap";
import swal from "sweetalert";
import moment from "moment";
import "moment/locale/es";
import { CircleSpinner } from "react-spinners-kit";

import { Stats } from "./Stats";
import EjecutarApi from "../../Consultas/Consultas";
import { setVars, getVars } from "../../GlobalStorage";
import { descending } from "../../utils/funciones";

const SecondStep = (props) => {
  const steps = getVars("StepForm");
  const minLength = 6;
  const maxLength = 6;
  const folioInputRef = useRef(null);
  const [formData, setFormData] = useState({
    Folio: "",
    SerieInicial: "",
    FechaHora: "",
  });
  const [data, setData] = useState([]);
  const [errorMessage, setErrorMessage] = useState(
    "El campo Folio es requerido"
  );
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!isEmptyOrNullObject(steps)) {
      setData(steps.Folios);
    }
    folioInputRef.current && folioInputRef.current.focus();
  }, []);

  const isEmptyOrNullObject = (data) => {
    for (var key in data) {
      return false;
    }
    return true;
  };

  const validateRegex = (folio) => {
    const re = /^[0-9A-F]+$/gi;
    return folio.match(re) ? true : false;
  };

  const handleChangeFolio = (e) => {
    let value = e.target.value;
    if (value.length > 0) {
      setErrorMessage("");
      value = value.toUpperCase();
      if (!validateRegex(value)) {
        setErrorMessage("El Folio no es válido");
        return;
      }

      if (value.length < minLength && value.length > maxLength) {
        setErrorMessage("El Folio no es válido");
        return;
      }
      setFormData({
        Folio: value,
        SerieInicial: "",
        FechaHora: moment(),
      });
    } else {
      setFormData({
        Folio: "",
        SerieInicial: "",
        FechaHora: moment(),
      });
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      addFolio();
    }
  };

  const addFolio = () => {
    setIsLoading(true);
    if (formData.Folio === "") {
      setErrorMessage("El Folio no es válido");
      setIsLoading(false);
      return;
    }

    if (!validateRegex(formData.Folio)) {
      swal({
        title: "!Atención!",
        text: "Debe agregar un folio válido",
        icon: "warning",
        closeOnClickOutside: false,
        closeEscapeKey: false,
      });
      setIsLoading(false);
      return;
    }

    if (
      formData.Folio.length < minLength &&
      formData.Folio.length > maxLength
    ) {
      setErrorMessage("El Folio no es válido");
      setIsLoading(false);
      return;
    }

    if (data.find((item) => item.Folio === formData.Folio)) {
      setFormData({
        Folio: "",
        SerieInicial: "",
        FechaHora: "",
      });
      setErrorMessage("");
      folioInputRef.current.focus();
      setIsLoading(false);
      return;
    }

    const token = getVars("Token").token;

    const params = {
      CveInterventor: steps.CveInterventor,
      Folio: formData.Folio,
    };

    EjecutarApi(params, "vales/validateFolio", token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        const datax = {
          Folio: formData.Folio,
          SerieInicial: res.data.SerieInicial,
          FechaHora: formData.FechaHora,
        };
        setData([...data, datax]);
        setFormData({
          Folio: "",
          SerieInicia: "",
          FechaHora: "",
        });
        setErrorMessage("");
        setVars("StepForm", {
          ...steps,
          Folios: [...data, datax],
        });
        folioInputRef.current.focus();
      })
      .catch((e) => {
        swal({
          title: "¡Atención!",
          icon: "warning",
          text: `${e.message}`,
        });
      });
    setIsLoading(false);
    return;
  };

  const handleDeleteFolio = (item, index) => {
    swal({
      title: `¿Está seguro que desea eliminar el Folio: ${item}?`,
      icon: "warning",
      allowEscapeKey: false,
      allowOutsideClick: false,
      confirmButtonText: "Sí, Eliminar",
      buttons: true,
      dangerMode: true,
    }).then((res) => {
      if (res) {
        let newData = data.filter((item, j) => j !== index);
        setData(newData);
        swal({
          title: "¡Eliminado con éxito!",
          icon: "success",
        });
        folioInputRef.current.focus();
      }
    });
  };

  const validate = () => {
    swal({
      title: `¿Desea enviar a validación los ${data.length} folio(s) capturado(s)?`,
      text: "Una vez enviado no podrá eliminar o agregar más folios",
      icon: "warning",
      allowEscapeKey: false,
      allowOutsideClick: false,
      confirmButtonText: "Sí, Eliminar",
      buttons: true,
    }).then((res) => {
      if (res) {
        setLoading(true);
        const token = getVars("Token").token;
        const params = {
          Cve: steps.Cve,
          Fecha: steps.Fecha,
          Folios: data,
        };
        EjecutarApi(params, "vales/recepcionVales", token)
          .then(function (res) {
            return res;
          })
          .then((res) => {
            if (res.results) {
              let data = getVars("StepForm");
              setVars("StepForm", { ...data, step: 3 });
              props.nextStep();
            }
          })
          .catch((e) => {
            swal({
              title: "¡Atención!",
              icon: "warning",
              text: `${e.message}`,
            });
          });
        setLoading(false);
      }
    });
  };

  const style = {
    table: {
      width: "100%",
      display: "table",
      borderSpacing: 0,
      borderCollapse: "separate",
    },
    th: {
      top: 0,
      left: 0,
      zIndex: 2,
      position: "sticky",
      backgroundColor: "#fff",
    },
    td: {
      padding: 3,
    },
  };

  return (
    <Fragment>
      <Row>
        <Col sm={12} md={6} lg={6}>
          <FormGroup>
            <Label>Folio:</Label>
            <InputGroup>
              <Input
                autoFocus={true}
                ref={folioInputRef}
                type="text"
                name="Folio"
                id="Folio"
                placeholder="Ingrese el Folio API"
                value={formData.Folio}
                onChange={handleChangeFolio}
                onKeyPress={handleKeyPress}
                className={`form-control ${
                  errorMessage.length > 0 && "is-invalid"
                }`}
                minLength={minLength}
                maxLength={maxLength}
              />
              <InputGroupAddon
                addonType="append"
                style={{ margin: "0px !important", padding: "0px !important" }}
              >
                <Button
                  color="success"
                  onClick={addFolio}
                  // disabled={errorMessage.length > 0}
                >
                  <i className="fa fa-plus fa-xs"></i> Agregar
                </Button>
              </InputGroupAddon>
              {errorMessage.length > 0 && (
                <div className="invalid-feedback">{errorMessage}</div>
              )}
            </InputGroup>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md={8} lg={8} sm={12}>
          <div
            style={{
              maxHeight: "600px",
              overflowY: "auto",
            }}
          >
            {isLoading && (
              <CircleSpinner
                className="text-center"
                size={100}
                color="#000"
                loading={true}
              />
            )}
            <Table striped bordered hover maxHeight={600}>
              <thead>
                <tr>
                  <th style={style.th}>#</th>
                  <th style={style.th}>Acción</th>
                  <th style={style.th}>Folio</th>
                  <th style={style.th}>Serie Inicial</th>
                  <th style={style.th}>Fecha / Hora</th>
                </tr>
              </thead>
              <tbody>
                {data &&
                  data.length > 0 &&
                  data
                    .sort(descending((val, index) => val.FechaHora))
                    .map((item, index) => (
                      <tr key={index}>
                        <td style={style.td}>{data.length - index}</td>
                        <td style={style.td} className="text-center">
                          <Button
                            size="small"
                            color="danger"
                            onClick={(e) =>
                              handleDeleteFolio(item.Folio, index)
                            }
                            style={{ padding: 5 }}
                          >
                            <span className="btn-label">
                              <i className="fa fa-trash fa-1x" />
                            </span>
                          </Button>
                        </td>
                        <td style={style.td} className="text-center">
                          {item.Folio}
                        </td>
                        <td style={style.td} className="text-center">
                          {item.SerieInicial}
                        </td>
                        <td style={style.td}>
                          {moment(item.FechaHora).format("D-MMM-YY H:m:s")}
                        </td>
                      </tr>
                    ))}
              </tbody>
            </Table>
          </div>
        </Col>

        <Col md={4} lg={4} sm={12}>
          <Table>
            <tbody>
              <tr>
                <td>Región</td>
                <td>{steps.Region}</td>
              </tr>
              <tr>
                <td>Municipio</td>
                <td>{steps.Municipio}</td>
              </tr>
              <tr>
                <td>CveInterventor</td>
                <td>{steps.Cve}</td>
              </tr>
              <tr>
                <td>Fecha</td>
                <td>{steps.Fecha}</td>
              </tr>
            </tbody>
          </Table>
          <div className="text-center">
            {loading && (
              <CircleSpinner
                className="text-center"
                size={100}
                color="#686769"
                loading={loading}
              />
            )}
          </div>
        </Col>
      </Row>

      <Row>
        <Col sm={12}>
          <Stats
            step={2}
            {...props}
            nextStep={validate}
            disabledNext={loading}
          />
        </Col>
      </Row>
    </Fragment>
  );
};

export default SecondStep;
