import React, { useState, useEffect, createRef } from "react";

import {
  Button,
  Input,
  Form,
  FormGroup,
  CardBody,
  Row,
  Col,
  Card,
  CardHeader,
  CardTitle,
  Label,
  CardFooter,
} from "reactstrap";
import swal from "sweetalert";
import ReactBSAlert from "react-bootstrap-sweetalert";
import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";
import Notifications, { notify } from "react-notify-toast";
import EjecutarApi from "../Consultas/Consultas";
import { getVars } from "../GlobalStorage";
import { useHistory } from "react-router-dom";
import ReactTable from "react-table";
import { ImpulseSpinner } from "react-spinners-kit";
import matchSorter from "match-sorter";
import { URL_GENERAL } from "../data/consts";

function GruposAdd2023(props) {
  const [data, setData] = useState([]);
  const [flagFolio, setflagFolio] = useState(true);

  const [CveInterventor, setCveInterventor] = useState("");
  const [idLocalidad, setIdLocalidad] = useState("");
  const [idMunicipio, setIdMunicipio] = useState("");
  const [ResponsableEntrega, setResponsableEntrega] = useState("");
  const [flagBotonGruposAdd, setflagBotonGruposAdd] = useState(false);

  const [TotalArticuladores, setTotalArticuladores] = useState(0);
  const [flagMostrarValesAdd, setflagMostrarValesAdd] = useState(false);
  const [flagBuscaArticulador, setflagBuscaArticulador] = useState(false);
  const [loading, setloading] = useState(false);
  const [BuscaArticulador, setBuscaArticulador] = useState("");
  const [BuscaCve, setBuscaCve] = useState("");
  const [TotalNotIN, setTotalNotIN] = useState(0);
  let history = useHistory();

  const [DetalleSolicitante, setDetalleSolicitante] = useState([]);
  const [SolicitanteNombre, setSolicitanteNombre] = useState("");
  const [SolicitanteCURP, setSolicitanteCURP] = useState("");
  const [SolicitanteFolioInicialHumano, setSolicitanteFolioInicialHumano] =
    useState("");
  const [SolicitanteFolioInicial, setSolicitanteFolioInicial] = useState("");
  const [SolicitanteFolioFinal, setSolicitanteFolioFinal] = useState("");
  const [SolicitanteCodigoInicial, setSolicitanteCodigoInicial] = useState("");
  const [SolicitanteCodigoFinal, setSolicitanteCodigoFinal] = useState("");
  const [Grupo, setGrupo] = useState({});
  const [GrupoDetalle, setGrupoDetalle] = useState({});
  const [idGrupoState, setIdGrupoState] = useState(0);
  const [viewButtons, setViewButtons] = useState(true);
  const inputFolio = createRef();
  const token = getVars("Token");
  useEffect(() => {
    const datos = {
      page: 0,
      pageSize: 1000,
      tipo: "and",
      sorted: [],
      filtered: [],
    };
  }, []);
  const btnFocus = () => {
    console.log("btnFocus");
  };

  const handledBucarArticulador = (NombreArticulador) => {
    setloading(true);
    const token = getVars("Token").token;
    const datax = {
      page: 0,
      tipo: "and",
      pageSize: 5,
      sorted: [{ id: "V.ResponsableEntrega", desc: false }],
      filtered: [],
      NombreCompleto: NombreArticulador,
      CveInterventor: BuscaCve ? BuscaCve : "",
    };
    EjecutarApi(datax, "getArticularSolicitudes2023", token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        // setBuscaArticulador("");
        setloading(false);
        setTotalArticuladores(res.total);
        setData(
          res.data.map((prop, key) => {
            return {
              Municipio: prop.Municipio,
              CveInterventor: prop.CveInterventor,
              Localidad: prop.Localidad,
              ResponsableEntrega: prop.ResponsableEntrega,
              Remesa: prop.Remesa,
              Solicitudes: prop.Solicitudes,
              data: prop,
            };
          })
        );
      })
      .catch((e) => {
        setloading(false);
        notify.show(`Error al obtener colonias: ${e.errors}`, "custom", 5000, {
          background: "#0857B6",
          fontSize: 14,
          text: "#FFFFFF",
        });
      });
  };

  const regexValidationCode = (value) => {
    const pattern = /^[1-9]{1}([0-9]{0,1})_[1-6]{1}([0-9]{0,1})$/;
    return value.match(pattern) ? true : false;
  };

  const handleMiniClick = () => {
    document.body.classList.toggle("sidebar-mini");
  };
  const mini_tableArticulador = (
    <>
      <ReactBSAlert
        title=""
        style={{ display: "block", marginTop: "-250pt", width: "800px" }}
        onConfirm={() => setflagBuscaArticulador(false)}
        onCancel={() => setflagBuscaArticulador(false)}
        confirmBtnBsStyle="info"
      >
        <Card className="mb-4 text-muted">
          <CardBody className="mb-4 text-muted">
            <Form>
              <Row>
                <Label sm="2">
                  Articulador:<span className="text-danger">*</span>
                </Label>
                <Col sm="3">
                  <FormGroup>
                    <Input
                      id="articu"
                      type="text"
                      value={BuscaArticulador}
                      onChange={(e) => setBuscaArticulador(e.target.value)}
                    />
                  </FormGroup>
                </Col>
                <Label sm="2">
                  CveArt:<span className="text-danger">*</span>
                </Label>
                <Col sm="2">
                  <FormGroup>
                    <Input
                      id="cve"
                      type="text"
                      value={BuscaCve}
                      onChange={(e) => {
                        setBuscaCve(e.target.value);
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col sm="2">
                  <Button
                    color="primary"
                    size="sm"
                    onClick={() => handledBucarArticulador(BuscaArticulador)}
                  >
                    <i className="nc-icon nc-zoom-split" />
                    Buscar
                  </Button>
                </Col>
              </Row>
              <Row
                style={{
                  overflow: "scroll",
                  height: "430px",
                  width: "100%",
                }}
              >
                <ReactTable
                  data={data}
                  noDataText="¡No existen datos a mostrar!"
                  style={{ width: "100%" }}
                  columns={[
                    {
                      Header: "Remesa",
                      minWidth: 30,
                      id: "Remesa",
                      accessor: (d) => d.Remesa,
                      filterMethod: (filter, rows) =>
                        matchSorter(rows, filter.value, { keys: ["Remesa"] }),
                      filterAll: false,
                      headerStyle: {
                        fontSize: "10px",
                        background: "#74c8cc",
                        color: "white",
                      },
                      style: { fontSize: "13px", fontWeight: "bold" },
                    },
                    {
                      Header: "Municipio",
                      minWidth: 40,
                      id: "Municipio",
                      accessor: (d) => d.Municipio,
                      filterMethod: (filter, rows) =>
                        matchSorter(rows, filter.value, {
                          keys: ["Municipio"],
                        }),
                      filterAll: false,
                      headerStyle: {
                        fontSize: "10px",
                        background: "#74c8cc",
                        color: "white",
                      },
                      style: { fontSize: "11px", fontWeight: "bold" },
                    },
                    {
                      Header: "CveInterventor",
                      minWidth: 40,
                      id: "CveInterventor",
                      accessor: (d) => d.CveInterventor,
                      filterMethod: (filter, rows) =>
                        matchSorter(rows, filter.value, {
                          keys: ["CveInterventor"],
                        }),
                      filterAll: false,
                      headerStyle: {
                        fontSize: "10px",
                        background: "#74c8cc",
                        color: "white",
                      },
                      style: { fontSize: "13px", fontWeight: "bold" },
                    },
                    {
                      Header: "Localidad",
                      minWidth: 40,
                      id: "Localidad",
                      accessor: (d) => d.Localidad,
                      filterMethod: (filter, rows) =>
                        matchSorter(rows, filter.value, {
                          keys: ["Localidad"],
                        }),
                      filterAll: false,
                      headerStyle: {
                        fontSize: "10px",
                        background: "#74c8cc",
                        color: "white",
                      },
                      style: { fontSize: "11px", fontWeight: "bold" },
                    },
                    {
                      Header: "Responsable",
                      minWidth: 70,
                      id: "ResponsableEntrega",
                      accessor: (d) => d.ResponsableEntrega,
                      filterMethod: (filter, rows) =>
                        matchSorter(rows, filter.value, {
                          keys: ["ResponsableEntrega"],
                        }),
                      filterAll: false,
                      headerStyle: {
                        fontSize: "10px",
                        background: "#74c8cc",
                        color: "white",
                      },
                      style: { fontSize: "11px", fontWeight: "bold" },
                    },
                    {
                      Header: "Solicitudes",
                      minWidth: 30,
                      id: "Solicitudes",
                      accessor: (d) => d.Solicitudes,
                      filterMethod: (filter, rows) =>
                        matchSorter(rows, filter.value, {
                          keys: ["Solicitudes"],
                        }),
                      filterAll: false,
                      headerStyle: {
                        fontSize: "10px",
                        background: "#74c8cc",
                        color: "white",
                      },
                      style: { fontSize: "13px", fontWeight: "bold" },
                    },
                  ]}
                  defaultPageSize={5}
                  manual
                  className={" -highlight -hover primary-pagination"}
                  loading={loading}
                  showPagination={true}
                  showPageJump={false}
                  previousText="Anterior"
                  nextText="Siguiente"
                  canNextFromData={true}
                  total={TotalArticuladores}
                  pages={Math.ceil(TotalArticuladores / 5)}
                  pageSizeOptions={[5]}
                  getTrProps={(state, rowInfo, column, instance) => {
                    if (rowInfo) {
                      return {
                        style: {
                          cursor: "pointer",
                        },
                        onClick: (e) => {
                          swal({
                            title: "¡Atención!",
                            text: `Dese seleccionar el grupo: \n Remesa: ${rowInfo.original.data.Remesa} \n  Municipio:${rowInfo.original.data.Municipio} \n CveInterventor:${rowInfo.original.data.CveInterventor} \n Localidad: ${rowInfo.original.data.Localidad} \n Responsable: ${rowInfo.original.data.ResponsableEntrega} \n Solicitudes: ${rowInfo.original.data.Solicitudes}`,
                            dangerMode: true,
                            icon: "warning",
                            buttons: ["Cancelar", true],
                          }).then((response) => {
                            if (response) {
                              setGrupo({
                                ...Grupo,
                                ResponsableEntrega:
                                  rowInfo.original.data.ResponsableEntrega,
                                Municipio: rowInfo.original.data.Municipio,
                                idMunicipio: rowInfo.original.data.idMunicipio,
                                CveInterventor:
                                  rowInfo.original.data.CveInterventor,
                                Localidad: rowInfo.original.data.Localidad,
                                idLocalidad: rowInfo.original.data.idLocalidad,
                                TotalAprobados:
                                  rowInfo.original.data.Solicitudes,
                                Remesa: rowInfo.original.data.Remesa,
                                Region: rowInfo.original.data.Region,
                                idIncidencia: 1,
                              });
                              setIdMunicipio(rowInfo.original.data.idMunicipio);
                              setCveInterventor(
                                rowInfo.original.data.CveInterventor
                              );
                              setIdLocalidad(rowInfo.original.data.idLocalidad);
                              setResponsableEntrega(
                                rowInfo.original.data.ResponsableEntrega
                              );
                              setIdGrupoState(rowInfo.original.data.idGrupo);
                              setflagBuscaArticulador(false); //Bandera de Modal
                            }
                          });
                        },
                      };
                    } else {
                      return {};
                    }
                  }}
                  onFetchData={(stateComp, instance) => {
                    setloading(true);
                    const token = getVars("Token").token;
                    const datax = {
                      page: stateComp.page,
                      tipo: "and",
                      pageSize: stateComp.pageSize,
                      sorted: [{ id: "V.Enlace", desc: false }],
                      filtered: [],
                      NombreCompleto: BuscaArticulador,
                      CveInterventor: BuscaCve ? BuscaCve : "",
                    };
                    EjecutarApi(datax, "getArticularSolicitudes2023", token)
                      .then(function (res) {
                        return res;
                      })
                      .then((res) => {
                        setloading(false);
                        setTotalArticuladores(res.total);
                        setData(
                          res.data.map((prop, key) => {
                            return {
                              Municipio: prop.Municipio,
                              CveInterventor: prop.CveInterventor,
                              Localidad: prop.Localidad,
                              ResponsableEntrega: prop.ResponsableEntrega,
                              Remesa: prop.Remesa,
                              Solicitudes: prop.Solicitudes,
                              data: prop,
                            };
                          })
                        );
                      })
                      .catch((e) => {
                        setloading(false);
                        notify.show(
                          `Error al obtener colonias: ${e.errors}`,
                          "custom",
                          5000,
                          {
                            background: "#0857B6",
                            fontSize: 14,
                            text: "#FFFFFF",
                          }
                        );
                      });
                  }}
                />
              </Row>
            </Form>
          </CardBody>
        </Card>
      </ReactBSAlert>
    </>
  );

  const setGrupoEvent = () => {
    console.log(Grupo);
    if (Object.entries(Grupo).length === 0) {
      notify.show("Error: No se ha seleccionado un grupo", "custom", 5000, {
        background: "#0857B6",
        fontSize: 14,
        text: "#FFFFFF",
      });
      return true;
    }
    if (Grupo.ResponsableEntrega.length < 1) {
      notify.show(
        "Error: Responsable de entrega es requerido",
        "custom",
        5000,
        {
          background: "#0857B6",
          fontSize: 14,
          text: "#FFFFFF",
        }
      );
      return true;
    }
    if (Grupo.idMunicipio.length < 1) {
      notify.show("Error: Municipio es requerido", "custom", 5000, {
        background: "#0857B6",
        fontSize: 14,
        text: "#FFFFFF",
      });
      return true;
    }
    if (Grupo.CveInterventor.length < 1) {
      notify.show("Error: CveInterventor es requerida", "custom", 5000, {
        background: "#0857B6",
        fontSize: 14,
        text: "#FFFFFF",
      });
      return true;
    }
    if (Grupo.idLocalidad.length < 1) {
      notify.show("Error: Localidad es requerida", "custom", 5000, {
        background: "#0857B6",
        fontSize: 14,
        text: "#FFFFFF",
      });
      return true;
    }
    if (Grupo.TotalAprobados.length < 1) {
      notify.show("Error: Total Aprobados es requerido", "custom", 5000, {
        background: "#0857B6",
        fontSize: 14,
        text: "#FFFFFF",
      });
      return true;
    }
    if (Grupo.Remesa.length < 1) {
      notify.show("Error: Remesa es requerido", "custom", 5000, {
        background: "#0857B6",
        fontSize: 14,
        text: "#FFFFFF",
      });
      return true;
    }
    setflagBotonGruposAdd(true);
    handleDetallesSolicitante();
    setflagMostrarValesAdd(true);

    notify.show("Registro Exitoso! ", "custom", 5000, {
      background: "#18c97d",
      text: "#FFFFFF",
    });
    setflagBotonGruposAdd(false);
  };

  const handleReturnGrupos = () => {
    history.push("/Grupos-2023");
  };

  const handleReturnGrupo = () => {
    setGrupo({
      ...Grupo,
      ResponsableEntrega: "",
      Municipio: "",
      idMunicipio: "",
      CveInterventor: "",
      Localidad: "",
      idLocalidad: "",
      TotalAprobados: "",
      Remesa: "",
      Region: "",
      idIncidencia: 1,
    });
    setIdMunicipio("");
    setCveInterventor("");
    setIdLocalidad("");
    setResponsableEntrega("");
    setIdGrupoState(0);
    setflagBuscaArticulador(false); //Bandera de Modal
    setflagBotonGruposAdd(false);
    setDetalleSolicitante([]);
    setflagMostrarValesAdd(false);
    setGrupoDetalle({});
    setSolicitanteFolioInicialHumano("");
    setSolicitanteFolioInicial("");
    setSolicitanteFolioFinal("");
    setSolicitanteCodigoInicial("");
    setSolicitanteCodigoFinal("");
    setSolicitanteCURP("");
    setSolicitanteNombre("");
    setGrupoDetalle({});
  };

  const handleReturn = () => {
    setSolicitanteFolioInicial("");
    setSolicitanteFolioFinal("");
    setSolicitanteCodigoInicial("");
    setSolicitanteCodigoFinal("");
    setSolicitanteCURP("");
    setSolicitanteNombre("");
    setGrupoDetalle({});
    setflagFolio(true);
    handleDetallesSolicitante();
  };

  const handleDescargaExcel = (param) => {
    console.log("=>Exportar DescargaExcel: ", param);
    var url = new URL(
      URL_GENERAL +
        `getReporteNominaVales2023?idGrupo=${param}&token=${token.token}`
    );
    window.open(url, "_blank");
  };

  const handleDescargaEtiquetas = (param) => {
    console.log("=>Exportar DescargaEtiquetas: ", param);

    var url = new URL(
      URL_GENERAL +
        `vales/getEtiquetasVales?idGrupo=${param}&token=${token.token}`
    );
    window.open(url, "_blank");
  };

  const handleDescargaPDF = (param) => {
    setloading(true);
    const grupo = {
      idGrupo: param,
    };

    EjecutarApi(grupo, "validarGrupo2023", token.token)
      .then((res) => {
        if (res.results) {
          var url = new URL(
            URL_GENERAL +
              `getReporteAcuseVales2023?idGrupo=${param}&token=${token.token}`
          );
          window.open(url, "_blank");
          setloading(false);
        } else {
          notify.show(
            `Aún no se asignan los vales de este grupo`,
            "custom",
            5000,
            {
              background: "#0857B6",
              fontSize: 14,
              text: "#FFFFFF",
            }
          );
          setloading(false);
        }
      })
      .catch((e) => {
        notify.show(
          `Aún no se asignan los vales de este grupo`,
          "custom",
          5000,
          {
            background: "#0857B6",
            fontSize: 14,
            text: "#FFFFFF",
          }
        );
        setloading(false);
      });
  };

  const handleDescargaSolicitud = (param) => {
    setloading(true);

    var url = new URL(
      URL_GENERAL +
        `getSolicitudesPdfVales?idGrupo=${param}&token=${token.token}`
    );
    window.open(url, "_blank");
    setloading(false);
  };

  const GrupoValeAdd = (Folio) => {
    setloading(true);
    if (SolicitanteCURP < 1) {
      setflagFolio(true);
      setloading(false);
      notify.show("Error: CURP, es requerido", "custom", 5000, {
        background: "#0857B6",
        fontSize: 14,
        text: "#FFFFFF",
      });
      return true;
    }
    if (SolicitanteNombre < 1) {
      setflagFolio(true);
      setloading(false);
      notify.show("Error: CURP, es requerido", "custom", 5000, {
        background: "#0857B6",
        fontSize: 14,
        text: "#FFFFFF",
      });
      return true;
    }
    if (Folio < 1) {
      setflagFolio(true);
      setloading(false);
      notify.show(
        "Error: Serie / Código de Barras, es requerido",
        "custom",
        5000,
        {
          background: "#0857B6",
          fontSize: 14,
          text: "#FFFFFF",
        }
      );
      return true;
    }
    if (SolicitanteFolioInicial % 10 !== 1) {
      setflagFolio(true);
      setloading(false);
      notify.show(
        "Error: Serie / Código de Barras, no tiene residuo de 1.",
        "custom",
        5000,
        {
          background: "#0857B6",
          fontSize: 14,
          text: "#FFFFFF",
        }
      );
      return true;
    }

    let data = {
      idSolicitud: GrupoDetalle.idSolicitud,
      CURP: GrupoDetalle.CURP,
      Nombre: GrupoDetalle.Nombre,
      Paterno: GrupoDetalle.Paterno,
      Materno: GrupoDetalle.Materno,
      SerieInicial: SolicitanteFolioInicial,
      SerieFinal: SolicitanteFolioFinal,
      CodigoBarrasInicial: SolicitanteCodigoInicial,
      CodigoBarrasFinal: SolicitanteCodigoFinal,
      Articulador: Grupo.ResponsableEntrega,
      idMunicipio: Grupo.idMunicipio,
      Municipio: Grupo.Municipio,
      Remesa: Grupo.Remesa,
    };

    console.log("=> setValesSolicitudes: ", JSON.stringify(data));
    EjecutarApi(data, "setValesSolicitudes2023", token.token)
      .then(function (res) {
        setloading(false);
        setSolicitanteFolioInicialHumano("");
        setSolicitanteFolioInicial("");
        setSolicitanteFolioFinal("");
        setSolicitanteCodigoInicial("");
        setSolicitanteCodigoFinal("");
        setSolicitanteCURP("");
        setSolicitanteNombre("");
        setGrupoDetalle({});
        setflagFolio(true);
        handleDetallesSolicitante();

        notify.show("Registro Exitoso!", "custom", 5000, {
          background: "#18c97d",
          text: "#FFFFFF",
        });

        return res;
      })
      .catch((e) => {
        console.log("error", e.errors);
        setflagFolio(true);
        setloading(false);
        notify.show(`Error:! ${e.message}`, "custom", 5000, {
          background: "#d61818",
          text: "#FFFFFF",
        });
      });
  };

  const handleDetallesSolicitante = () => {
    var array = [];
    if ((idMunicipio, CveInterventor, idLocalidad, ResponsableEntrega)) {
      array.push({ id: "vales.idGrupo", value: idGrupoState });
      array.push({ id: "vales.Remesa", value: Grupo.Remesa });
    }

    setloading(true);
    const token = getVars("Token");
    const datax = {
      page: 0,
      tipo: "and",
      pageSize: 200,
      sorted: [],
      filtered: array,
    };
    EjecutarApi(datax, "getValesNotIn2023", token.token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        setloading(false);
        setTotalNotIN(res.total);
        if (res.total === 0) {
          setViewButtons(true);
        }
        setDetalleSolicitante(
          res.data.map((prop, key) => {
            return {
              CURP: prop.CURP,
              Nombre: prop.Nombre,
              Paterno: prop.Paterno,
              Materno: prop.Materno,
              Remesa: prop.Remesa,
              data: prop,
            };
          })
        );
      })
      .catch((e) => {
        console.log("=Error[getExport]: ", e);
        setloading(false);
        notify.show(
          `Error al obtener getValesNotIn: ${e.errors}`,
          "custom",
          5000,
          {
            background: "#0857B6",
            fontSize: 14,
            text: "#FFFFFF",
          }
        );
      });
  };

  const handleValidarForlio = (Folio) => {
    setloading(true);

    if (SolicitanteCURP < 1) {
      setloading(false);
      setflagFolio(true);
      notify.show("Error: CURP, es requerido", "custom", 5000, {
        background: "#0857B6",
        fontSize: 14,
        text: "#FFFFFF",
      });
      return true;
    }
    if (SolicitanteNombre < 1) {
      setloading(false);
      setflagFolio(true);
      notify.show("Error: CURP, es requerido", "custom", 5000, {
        background: "#0857B6",
        fontSize: 14,
        text: "#FFFFFF",
      });
      return true;
    }
    if (Folio < 1) {
      setloading(false);
      setflagFolio(true);
      notify.show(
        "Error: Serie / Código de Barras, es requerido",
        "custom",
        5000,
        {
          background: "#0857B6",
          fontSize: 14,
          text: "#FFFFFF",
        }
      );
      return true;
    }
    if (Folio < 1) {
      setloading(false);
      setflagFolio(true);
      notify.show(
        "Error: Serie / Código de Barras, es requerido",
        "custom",
        5000,
        {
          background: "#0857B6",
          fontSize: 14,
          text: "#FFFFFF",
        }
      );
      return true;
    }

    const token = getVars("Token");
    const datax = {
      CodigoBarra: Folio,
    };
    EjecutarApi(datax, "getSerieVale2023", token.token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        setloading(false);
        console.log("···············res.data", res.data);
        setflagFolio(false);
        setSolicitanteFolioFinal(res.data.FolioFinal);
        setSolicitanteFolioInicial(res.data.FolioInicial);
        setSolicitanteCodigoInicial(res.data.CodigoBarraInicial);
        setSolicitanteCodigoFinal(res.data.CodigoBarraFinal);
      })
      .catch((e) => {
        console.log("=Error[getExport]: ", e);
        setloading(false);
        notify.show(`Error al obtener Vales: ${e.message}`, "custom", 5000, {
          background: "#0857B6",
          fontSize: 14,
          text: "#FFFFFF",
        });
      });
  };

  return (
    <div className="wrapper">
      <Sidebar {...props} bgColor="black" activeColor="info" />
      <div className="main-panel">
        <Navbar {...props} handleMiniClick={handleMiniClick} />
        <Notifications />
        <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <Row>
                    <Col md="9">
                      <CardTitle tag="h6">Agregar Grupos</CardTitle>
                    </Col>
                    <Col>
                      <Button
                        color="success"
                        onClick={() => {
                          handleReturnGrupos();
                        }}
                        size="sm"
                      >
                        <i className="nc-icon nc-minimal-left" />
                        Regresar
                      </Button>
                    </Col>
                  </Row>
                </CardHeader>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <Card>
                <CardBody>
                  <Form className="form-horizontal" id="TypeValidation">
                    <Notifications />
                    <Row>
                      <Label sm="2">
                        Responsable de entrega:
                        <span className="text-danger">*</span>
                      </Label>
                      <Col sm="4">
                        <FormGroup>
                          <Input
                            id="articu"
                            type="text"
                            value={ResponsableEntrega}
                            disabled
                          />
                        </FormGroup>
                      </Col>
                      <Col md="2">
                        <Button
                          color="primary"
                          size="sm"
                          onClick={() => {
                            setflagBuscaArticulador(true);
                            setGrupo({
                              idMunicipio: "",
                              Municipio: "",
                              idLocalidad: "",
                              Localidad: "",
                              Remesa: "",
                              ResponsableEntrega: "",
                              TotalAprobados: "",
                            });
                            setCveInterventor("");
                            setIdGrupoState(0);
                            setflagMostrarValesAdd(false);
                            setViewButtons(false);
                            setResponsableEntrega("");
                            setBuscaCve("");
                            setBuscaArticulador("");
                            console.log("Buscando Articulador POPup");
                          }}
                        >
                          <span className="btn-label">
                            <i className="nc-icon nc-zoom-split" />
                          </span>{" "}
                          Buscar
                        </Button>
                        {flagBuscaArticulador ? mini_tableArticulador : <></>}
                      </Col>
                    </Row>
                    <Row>
                      <Label sm="2">
                        Municipio:<span className="text-danger">*</span>
                      </Label>
                      <Col sm="6">
                        <FormGroup>
                          <Input
                            id="articu"
                            type="text"
                            value={Grupo.Municipio}
                            disabled
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Label sm="2">
                        CveInterventor:
                        <span className="text-danger">*</span>
                      </Label>
                      <Col sm="4">
                        <FormGroup>
                          <Input
                            id="articu"
                            type="text"
                            value={CveInterventor}
                            disabled
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Label sm="2">
                        Localidad:<span className="text-danger">*</span>
                      </Label>
                      <Col sm="6">
                        <FormGroup>
                          <Input
                            id="articu"
                            type="text"
                            value={Grupo.Localidad}
                            disabled
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Label sm="2">
                        Total Aprobados:<span className="text-danger">*</span>
                      </Label>
                      <Col sm="4">
                        <FormGroup>
                          <Input
                            id="Total"
                            type="text"
                            value={Grupo.TotalAprobados}
                            disabled
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Label sm="2">
                        Número de Remesa:<span className="text-danger">*</span>
                      </Label>
                      <Col sm="4">
                        <FormGroup>
                          <Input
                            id="remesa"
                            type="text"
                            value={Grupo.Remesa}
                            disabled
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
                <CardFooter className="text-center">
                  <>
                    {!viewButtons && Grupo ? (
                      <>
                        {" "}
                        <Button
                          color="primary"
                          onClick={() => {
                            setGrupoEvent();
                          }}
                          size="sm"
                        >
                          {
                            <ImpulseSpinner
                              color={"#1261ff"}
                              size={30}
                              loading={flagBotonGruposAdd}
                            />
                          }

                          {flagBotonGruposAdd ? (
                            <span>Guardando..</span>
                          ) : (
                            <span>Guardar</span>
                          )}
                        </Button>
                        <Button
                          color="danger"
                          onClick={handleReturnGrupo}
                          size="sm"
                        >
                          <i className="nc-icon nc-simple-remove" />
                          Cancelar
                        </Button>{" "}
                      </>
                    ) : idGrupoState > 0 && viewButtons ? (
                      <></>
                    ) : (
                      <></>
                    )}
                  </>
                </CardFooter>
              </Card>
            </Col>
          </Row>
          {flagMostrarValesAdd ? (
            <Row>
              <Col md="6">
                <Card>
                  <CardHeader>
                    <Row>
                      <Col>
                        <CardTitle tag="h6"> Detalle del Solicitante</CardTitle>
                      </Col>
                    </Row>
                    <Row>
                      <p className="text-info">
                        [Total Capturados:{" "}
                        {DetalleSolicitante.length - Grupo.TotalAprobados} de{" "}
                        {Grupo.TotalAprobados} ]
                      </p>
                    </Row>
                  </CardHeader>
                  <Row>
                    <Col>
                      <ReactTable
                        size="sm"
                        noDataText="¡No existen datos a mostrar!"
                        data={DetalleSolicitante}
                        columns={[
                          {
                            Header: "CURP",
                            minWidth: 50,
                            id: "CURP",
                            accessor: (d) => d.CURP,
                            filterMethod: (filter, rows) =>
                              matchSorter(rows, filter.value, {
                                keys: ["Municipio"],
                              }),
                            filterAll: false,
                            headerStyle: { fontSize: "10px" },
                            style: { fontSize: "11px" },
                          },
                          {
                            Header: "Nombre",
                            minWidth: 70,
                            id: "Nombre",
                            accessor: (d) =>
                              `${d.Nombre !== null ? d.Nombre : ""} ${
                                d.Paterno !== null ? d.Paterno : ""
                              } ${d.Materno !== null ? d.Materno : ""}`,
                            filterMethod: (filter, rows) =>
                              matchSorter(rows, filter.value, {
                                keys: ["Articulador"],
                              }),
                            headerStyle: { fontSize: "10px" },
                            style: { fontSize: "11px" },
                          },
                        ]}
                        className="-striped -highlight primary-pagination"
                        loading={loading}
                        showPagination={true}
                        showPageJump={false}
                        canNextFromData={true}
                        defaultPageSize={DetalleSolicitante.length}
                        manual
                        total={TotalNotIN}
                        pages={1}
                        previousText="Anterior"
                        nextText="Siguiente"
                        pageSizeOptions={[50, 100, 200]}
                        getTrProps={(state, rowInfo, column, instance) => {
                          return {
                            onClick: (e) => {
                              setSolicitanteCURP(rowInfo.original.data.CURP);
                              setSolicitanteNombre(
                                rowInfo.original.data.Nombre
                              );
                              setGrupoDetalle({
                                ...GrupoDetalle,
                                idSolicitud: rowInfo.original.data.id,
                                CURP: rowInfo.original.data.CURP,
                                Nombre: rowInfo.original.data.Nombre,
                                Paterno: rowInfo.original.data.Paterno,
                                Materno: rowInfo.original.data.Materno,
                                CodigoBarrasInicial:
                                  rowInfo.original.data.FolioInicial,
                                CodigoBarrasFinal:
                                  rowInfo.original.data.FolioFinal,
                                Remesa: rowInfo.original.data.Remesa,
                                SerialInicial:
                                  rowInfo.original.data.SerialInicial,
                                SerieFinal: rowInfo.original.data.SerieFinal,
                              });
                              inputFolio.current.focus();
                            },
                          };
                        }}
                      />
                    </Col>
                  </Row>
                </Card>
                {viewButtons && (
                  <Card>
                    <CardTitle tag="h6">Descargar archivos</CardTitle>
                    <CardBody>
                      <>
                        <Button
                          size="sm"
                          color="success"
                          onClick={() => handleDescargaExcel(idGrupoState)}
                        >
                          <span className="btn-label">
                            <i className="fa fa-file-excel-o fa-1x" />
                          </span>
                          Listado
                        </Button>
                        <Button
                          size="sm"
                          color="info"
                          onClick={() => handleDescargaSolicitud(idGrupoState)}
                        >
                          <span className="btn-label">
                            <i className="fa fa-id-card-o fa-1x" />
                          </span>
                          Solicitud
                        </Button>
                        <Button
                          size="sm"
                          color="danger"
                          onClick={() => handleDescargaPDF(idGrupoState)}
                        >
                          <span className="btn-label">
                            <i className="fa fa-file-pdf-o fa-1x" />
                          </span>
                          Acuse
                        </Button>
                        <Button
                          size="sm"
                          color="warning"
                          title="Etiquetas"
                          onClick={() => handleDescargaEtiquetas(idGrupoState)}
                        >
                          <span className="btn-label">
                            <i className="fa fa-tag fa-1x" />
                          </span>
                          Etiquetas
                        </Button>
                      </>
                    </CardBody>
                  </Card>
                )}
              </Col>

              <Col md="6">
                <Form>
                  <Card>
                    <CardHeader>
                      <h6>Datos del Vale</h6>
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Label sm="2">CURP:</Label>
                        <Col sm="10">
                          <FormGroup>
                            <Input
                              id="CURP"
                              type="text"
                              value={SolicitanteCURP}
                              disabled
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Label sm="2">Nombre:</Label>
                        <Col sm="10">
                          <FormGroup>
                            <Input
                              id="Nombre"
                              type="text"
                              value={SolicitanteNombre}
                              disabled
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Label sm="2">
                          Folio/Código Barras Inicial:
                          <span className="text-danger">*</span>
                        </Label>
                        <Col sm="10">
                          <FormGroup>
                            <Input
                              ref={inputFolio}
                              isFocused={btnFocus}
                              autoFocus={true}
                              autoComplete={false}
                              name="FolioInicialHumano"
                              id="FolioInicialHumano"
                              value={SolicitanteFolioInicialHumano}
                              onChange={(event) =>
                                setSolicitanteFolioInicialHumano(
                                  event.target.value
                                )
                              }
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Label sm="2">
                          Folio Inicial:<span className="text-danger">*</span>
                        </Label>
                        <Col sm="10">
                          <FormGroup>
                            <Input
                              name="FolioInicial"
                              type="text"
                              value={SolicitanteFolioInicial}
                              onChange={(event) =>
                                setSolicitanteFolioInicial(event.target.value)
                              }
                              disabled
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Label sm="2">
                          Folio Final:<span className="text-danger">*</span>
                        </Label>
                        <Col sm="10">
                          <FormGroup>
                            <Input
                              name="FolioFinal"
                              type="text"
                              value={SolicitanteFolioFinal}
                              disabled
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Label sm="2">
                          Códifo Barras Inicial:
                          <span className="text-danger">*</span>
                        </Label>
                        <Col sm="10">
                          <FormGroup>
                            <Input
                              name="FolioInicial"
                              type="text"
                              value={SolicitanteCodigoInicial}
                              disabled
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Label sm="2">
                          Código Barras Final:
                          <span className="text-danger">*</span>
                        </Label>
                        <Col sm="10">
                          <FormGroup>
                            <Input
                              name="FolioFinal"
                              type="text"
                              value={SolicitanteCodigoFinal}
                              disabled
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Label sm="2">
                          Remesa:<span className="text-danger">*</span>
                        </Label>
                        <Col sm="10">
                          <FormGroup>
                            <Input
                              name="FolioFinal"
                              type="text"
                              value={Grupo.Remesa}
                              disabled
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </CardBody>
                    <CardFooter>
                      <Row>
                        {flagFolio ? (
                          <>
                            {!loading && (
                              <Button
                                color="primary"
                                onClick={() => {
                                  handleValidarForlio(
                                    SolicitanteFolioInicialHumano
                                  );
                                }}
                                size="sm"
                              >
                                <span>Buscar Vales</span>
                              </Button>
                            )}
                            {loading && (
                              <ImpulseSpinner
                                color={"#1261ff"}
                                size={30}
                                loading={true}
                              />
                            )}
                            <Button
                              color="danger"
                              onClick={handleReturn}
                              size="sm"
                            >
                              <i className="nc-icon nc-simple-remove" />
                              Cancelar
                            </Button>{" "}
                          </>
                        ) : (
                          <>
                            {!loading && (
                              <Button
                                color="primary"
                                onClick={() => {
                                  GrupoValeAdd(SolicitanteFolioInicial);
                                }}
                                size="sm"
                              >
                                <span>Asignar Vales</span>
                              </Button>
                            )}
                            {loading && (
                              <ImpulseSpinner
                                color={"#1261ff"}
                                size={30}
                                loading={true}
                              />
                            )}
                            <Button
                              color="danger"
                              onClick={handleReturn}
                              size="sm"
                            >
                              <i className="nc-icon nc-simple-remove" />
                              Cancelar
                            </Button>{" "}
                          </>
                        )}
                      </Row>
                    </CardFooter>
                  </Card>
                </Form>
              </Col>
            </Row>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
}
export default GruposAdd2023;
