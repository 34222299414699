import React, { useState, useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  FormGroup,
  Input,
  Spinner,
} from "reactstrap";
import swal from "sweetalert";
import ReactTable from "react-table";
import { CircleSpinner } from "react-spinners-kit";
import EjecutarApi from "../Consultas/Consultas";
import Notifications from "react-notify-toast";
import { getVars } from "../GlobalStorage";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Sidebar from "../components/Sidebar";
import Pagination from "../components/pagination";
import { numberWithCommas } from "../utils/funciones";
import { isNullOrUndefined } from "../utils/emptyValidations";
import { URL_GENERAL } from "../data/consts";

const PadronAvance = (props) => {
  const [userData, setUserData] = useState();
  const [remesa, setRemesa] = useState("");
  const [region, setRegion] = useState(0);
  const [catRemesas, setCatRemesas] = useState([]);
  const [catRegiones, setCatRegiones] = useState([]);
  const [loadingRemesas, setLoadingRemesas] = useState(false);
  const [loadingRegiones, setLoadingRegiones] = useState(false);
  const [statusAvance, setStatusAvance] = useState(true);
  const [statusPadron, setStatusPadron] = useState(true);
  const [dataFilter, setDataFilter] = useState([]);
  const history = useHistory();
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [dataCedulaList, setDataCedulaList] = useState([]);
  const [menuLoaded, setMenuLoaded] = useState(false);
  const [menu, setMenu] = useState({
    Ver: 0,
    Agregar: 0,
    Editar: 0,
    Eliminar: 0,
    Seguimiento: 0,
    Exportar: 0,
    Imprimir: 0,
    ViewAll: 0,
  });
  const columns = [
    {
      Header: "Origen",
      minWidth: 40,
      id: "Origen",
      accessor: (d) => d.Origen,
      className: "text-center",
      filterAll: false,
      style: { fontSize: "10px" },
      headerStyle: { fontSize: "10px" },
    },
    {
      Header: "Remesa",
      minWidth: 40,
      id: "Remesa",
      accessor: (d) => d.Remesa,
      className: "text-center",
      filterAll: false,
      style: { fontSize: "10px" },
      headerStyle: { fontSize: "10px" },
    },
    {
      Header: "Municipio",
      headerClassName: "text-center",
      minWidth: 90,
      id: "Folio",
      accessor: (d) => d.Municipio,
      filterAll: false,
      style: { fontSize: "10px" },
      headerStyle: { fontSize: "10px" },
    },
    {
      Header: "Meta",
      headerClassName: "text-center",
      minWidth: 50,
      id: "Meta",
      accessor: (d) => d.Meta,
      filterAll: false,
      style: { fontSize: "10px", textAlign: "center" },
      headerStyle: { fontSize: "10px" },
      Footer: (
        <span>
          {numberWithCommas(
            dataFilter.map((d) => d.Meta).reduce((a, b) => a + b, 0)
          )}
        </span>
      ),
    },
    {
      Header: "Cargados API",
      headerClassName: "text-center",
      minWidth: 50,
      id: "Validados",
      accessor: (d) => d.Validados,
      filterAll: false,
      style: { fontSize: "10px", textAlign: "center" },
      headerStyle: { fontSize: "10px" },
      Footer: (
        <span>
          {numberWithCommas(
            dataFilter.map((d) => d.Validados).reduce((a, b) => a + b, 0)
          )}
        </span>
      ),
    },
    {
      Header: "Pendientes",
      headerClassName: "text-center",
      minWidth: 50,
      id: "Pendientes",
      accessor: (d) => d.Pendientes,
      filterAll: false,
      style: { fontSize: "10px", textAlign: "center" },
      headerStyle: { fontSize: "10px" },
      Footer: (
        <span>
          {numberWithCommas(
            dataFilter.map((d) => d.Pendientes).reduce((a, b) => a + b, 0)
          )}
        </span>
      ),
    },
  ];

  const handleMiniClick = () => {
    document.body.classList.toggle("sidebar-mini");
  };

  useEffect(() => {
    setUserData(getVars("Token"));
  }, []);

  useEffect(() => {
    if (isNullOrUndefined(userData)) return;
    const menus = userData.menu;
    const currentMenu = menus.find((menu) => menu.idMenu === 32);
    if (isNullOrUndefined(currentMenu) || currentMenu.Ver < 1) {
      swal({
        title: "Atención",
        text: "No tiene permisos para este módulo, contacte al administrador",
        icon: "warning",
      });
      history.push("/not-found");
      return;
    }
    setMenu({
      Ver: currentMenu.Ver,
      Agregar: currentMenu.Agregar,
      Editar: currentMenu.Editar,
      Eliminar: currentMenu.Eliminar,
      Seguimiento: currentMenu.Seguimiento,
      Exportar: currentMenu.Exportar,
      Imprimir: currentMenu.Imprimir,
      ViewAll: currentMenu.ViewAll,
    });

    setMenuLoaded(true);
  }, [userData]);

  useEffect(() => {
    setLoadingRemesas(true);
    const token = getVars("Token");
    const data = {
      ejercicio: 2023,
    };
    EjecutarApi(data, "vales/getRemesaEjercicio", token.token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.success && res.results) {
          setCatRemesas(res.data);
        } else {
          swal({
            title: "Ha ocurrido un error",
            text: res.message,
            icon: "warning",
          });
        }
        setLoadingRemesas(false);
      })
      .catch((error) => {
        setLoadingRemesas(false);
        swal({
          title: "¡Atención¡",
          text: error.message,
          icon: "warning",
        });
      });
  }, []);

  useEffect(() => {
    setLoadingRegiones(true);
    const token = getVars("Token");
    EjecutarApi({}, "getRegiones", token.token, "GET")
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.success && res.results) {
          setCatRegiones(res.data);
        } else {
          swal({
            title: "Ha ocurrido un error",
            text: res.message,
            icon: "warning",
          });
        }
        setLoadingRegiones(false);
      })
      .catch((error) => {
        setLoadingRegiones(false);
        swal({
          title: "¡Atención¡",
          text: error.message,
          icon: "warning",
        });
      });
  }, []);

  useEffect(() => {
    setStatusAvance(true);
    if (remesa.length > 1) {
      setFilter(1);
    }
  }, [remesa]);

  useEffect(() => {
    setStatusPadron(true);
    if (remesa.length > 1 && region > 0) {
      setFilter(2);
      return;
    }
    if (menu.ViewAll > 0 && remesa.length > 1) {
      setFilter(1);
      return;
    }
  }, [region]);

  const setFilter = (index) => {
    setLoading(true);
    const token = getVars("Token");
    let data = {};
    if (index === 1) {
      data = {
        remesa: remesa,
      };
    } else {
      data = {
        remesa: remesa,
        region: region,
      };
    }

    EjecutarApi(data, "vales/getAvancesPadron", token.token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.results && res.success) {
          setTotal(res.total);
          console.log(res.total);
          setDataCedulaList(
            res.data.map((item) => {
              return {
                ...item,
              };
            })
          );
          setDataFilter(res.data);
          setStatusAvance(false);
          if (index === 2) {
            setStatusPadron(false);
          }
        } else {
          swal({
            title: "¡Atención!",
            text: `${res.errors}`,
            icon: "info",
          });
        }
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        swal({
          title: "¡Atención!",
          text: `${e.message}`,
          icon: "warning",
        });
      });
  };

  const btnExportarBeneficiarios = () => {
    const token = getVars("Token");
    let filtro = "";
    if (remesa.length > 0) filtro = `&remesa=${remesa}`;
    if (region > 0) filtro += `&region=${region}`;
    var url = new URL(
      URL_GENERAL +
        `vales/getReporteBeneficiarios?token=${token.token}` +
        filtro
    );
    window.open(url, "_blank");
  };

  const btnExportarAvance = () => {
    const token = getVars("Token");
    let filtro = "";
    if (remesa.length > 0) filtro = `&remesa=${remesa}`;
    if (region > 0) filtro += `&region=${region}`;
    var url = new URL(
      URL_GENERAL + `vales/getReporteAvances?token=${token.token}` + filtro
    );
    window.open(url, "_blank");
  };

  return (
    <>
      <div className="wrapper">
        <Sidebar {...props} bgColor="black" activeColor="info" />
        <div className="main-panel">
          <Navbar {...props} handleMiniClick={handleMiniClick} />
          <Notifications />
          <div className="content">
            <>
              <Row>
                <Col md="12">
                  <Card>
                    <CardHeader>
                      <FormGroup>
                        <Row>
                          <Col sm="3">
                            Remesa:
                            <span className="text-danger">*</span>
                            {loadingRemesas && <Spinner size="sm" />}
                            <Input
                              name="Remesa"
                              type="select"
                              disabled={loadingRemesas}
                              value={remesa}
                              onChange={(e) => {
                                setRegion(0);
                                setRemesa(e.target.value);
                              }}
                            >
                              <option value={""}></option>
                              {catRemesas.map((element) => (
                                <option
                                  key={element.value}
                                  value={element.value}
                                >
                                  {element.value}
                                </option>
                              ))}
                            </Input>
                          </Col>
                          <Col sm="3">
                            Región:
                            <span className="text-danger">*</span>
                            {loadingRegiones && <Spinner size="sm" />}
                            <Input
                              name="Remesa"
                              type="select"
                              disabled={loadingRegiones}
                              value={region}
                              onChange={(e) => {
                                setRegion(e.target.value);
                              }}
                            >
                              <option value={""}></option>
                              {catRegiones.map((element) => (
                                <option
                                  key={element.value}
                                  value={element.value}
                                >
                                  {element.label}
                                </option>
                              ))}
                            </Input>
                          </Col>
                          <Col sm="3" style={{ paddingTop: "1rem" }}>
                            <Button
                              size="sm"
                              color="success"
                              disabled={statusAvance}
                              onClick={() => {
                                btnExportarAvance();
                              }}
                            >
                              Exportar Avance
                            </Button>
                          </Col>
                          <Col sm="3" style={{ paddingTop: "1rem" }}>
                            <Button
                              size="sm"
                              color="success"
                              disabled={statusPadron}
                              onClick={() => {
                                btnExportarBeneficiarios();
                              }}
                            >
                              Exportar Padrón
                            </Button>
                          </Col>
                        </Row>
                      </FormGroup>
                    </CardHeader>
                    <CardBody>
                      {loading && (
                        <CircleSpinner
                          className="text-center"
                          size={30}
                          color="#686769"
                          loading={loading}
                        />
                      )}
                      <Col lg="12" md="12">
                        {menuLoaded && (
                          <ReactTable
                            size="sm"
                            data={dataCedulaList}
                            noDataText="Seleccione un filtro"
                            manual
                            columns={columns}
                            className="-striped -highlight primary-pagination"
                            loading={loading}
                            showPagination={true}
                            showPaginationBottom={true}
                            showTotalPages={true}
                            showPageJump={false}
                            canNextFromData={true}
                            defaultPageSize={10}
                            total={total}
                            page={0}
                            pages={Math.floor(total)}
                            previousText="Anterior"
                            nextText="Siguiente"
                            ofText="de"
                            pageSizeOptions={[total]}
                            PaginationComponent={Pagination}
                          />
                        )}
                      </Col>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </>
          </div>
          <Footer fluid />
        </div>
      </div>
    </>
  );
};
export default PadronAvance;
