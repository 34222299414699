import React, { useState, useEffect } from "react";
import { Map, TileLayer, Popup, Marker, GeoJSON } from "react-leaflet";
import L from "leaflet";
import { MagicSpinner } from "react-spinners-kit";
import MarkerClusterGroup from "react-leaflet-markercluster";
import "react-leaflet-markercluster/dist/styles.min.css";

import Municipios from "./Municipios.json";
import ArrayMunicipiosCoordenadas from "./ArrayMunicipiosCoordenadas.json";

import Notifications, { notify } from "react-notify-toast";
import { getVars } from "../../GlobalStorage";
import EjecutarApi from "../../Consultas/Consultas";
import PluginFilterMap from "./PluginFilterMap";
import { CardBody, Card, CardHeader, Row, Col } from "reactstrap";
import avatar from "../../assets/img/Gto192.png";

const SimpleMap = ({ position_center, marker_massage }) => {
  const [zoom, setZoom] = useState(9);
  const [_position, setPosition] = useState(
    position_center ? position_center : [20.816157, -101.021312]
  );
  //const [prop, setProp] = useState({'nom_mun':'','cve_mun':''});
  const [_data, set_data] = useState([]);
  const [resumen, setResumen] = useState([]);
  const [leyendaResumen, setleyendaResumen] = useState("GENERAL");
  const [MunicipiosFiltroGeoJson, setMunicipiosFiltroGeoJson] = useState([]);

  const token = getVars("Token");
  const [loading, setLoading] = useState(false);
  const [flagFinish, setFlagFinish] = useState(false);
  let style_spinner = {
    height: "500pt",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };
  let style_square_superior = {
    padding: "6px 8px",
    font: "14px/16px Arial, Helvetica, sans-serif",
    background: "rgba(255,255,255,0.8)",
    boxShadow: "0 0 15px rgba(0,0,0,0.2)",
    borderRadius: "5px",
  };
  useEffect(() => {
    const datos = {
      page: 0,
      tipo: "and",
      pageSize: 3000,
      sorted: [],
      filtered: [{ id: "Estatus", value: "3" }],
    };

    EjecutarApi(datos, "getNegociosMaps", token.token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.results) {
          set_data(
            res.data.map((prop, key) => {
              return {
                Latitude: prop.Latitude,
                Longitude: prop.Longitude,
                RFC: prop.RFC,
                NombreEmpresa: prop.NombreEmpresa,
                Nombre: `${prop.Nombre !== null ? prop.Nombre : ""} ${
                  prop.Paterno !== null ? prop.Paterno : ""
                } ${prop.Materno !== null ? prop.Materno : ""}`,
                Celular: prop.Celular,
                TelNegocio: prop.TelNegocio,
                TelCasa: prop.TelCasa,
                Correo: prop.Correo,
                Municipio: prop.Municipio,
                Region: prop.Region,
                Colonia: prop.Colonia,
                Calle: prop.Calle,
                Estatus: prop.Estatus,
                ClaveUnica: prop.ClaveUnica,
                data: prop,
              };
            })
          );
          setFlagFinish(true);
        } else {
          console.log("=>getNegociosApp: Sin informacion:", res);
        }
      })
      .catch((e) => {
        notify.show(`Error en la busqueda! ${e.message}`, "custom", 5000, {
          background: "#d61818",
          text: "#FFFFFF",
        });
        ///setLoading(false);
      });
    getresumen("");
  }, []);

  function getresumen(idMunicipioParameter) {
    var datos_resumen;
    if (idMunicipioParameter === "") {
      datos_resumen = {
        page: 0,
        tipo: "and",
        pageSize: 10,
        sorted: [],
        filtered: [],
      };
    } else {
      datos_resumen = {
        page: 0,
        tipo: "and",
        pageSize: 10,
        sorted: [],
        filtered: [],
        idMunicipio: idMunicipioParameter,
      };
    }

    EjecutarApi(datos_resumen, "getNegociosResumen", token.token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.results) {
          setResumen(res.data.tipo);
          if (idMunicipioParameter !== "") {
            var nombre_de_municipios = "";
            idMunicipioParameter.forEach((parametro_municipio) => {
              let nombre = ArrayMunicipiosCoordenadas.RECORDS.filter(
                (coord) => Number(coord.id) === parametro_municipio
              );

              if (nombre_de_municipios.length === 0) {
                nombre_de_municipios = nombre[0].Nombre;
              } else {
                nombre_de_municipios =
                  nombre_de_municipios + ", " + nombre[0].Nombre;
              }
            });
            setleyendaResumen("DE " + nombre_de_municipios);
          } else {
            setleyendaResumen(" GENERAL");
          }
        } else {
          console.log("=>getNegociosResumen: Sin informacion:", res);
        }
      })
      .catch((e) => {
        notify.show(`Error en la busqueda! ${e.message}`, "custom", 5000, {
          background: "#d61818",
          text: "#FFFFFF",
        });
        ///setLoading(false);
      });
  }

  /* FUNCIONES DEL CHOROPETH MAP*/
  function style(feature, flag) {
    let style;
    if (flag === undefined || flag !== true) {
      style = {
        fillColor: "#0066FF", //feature.properties.Color,
        weight: 2,
        opacity: 1,
        color: "white",
        dashArray: "3",
        fillOpacity: 0.5,
      };
    } else {
      style = {
        weight: 5,
        color: "#666",
        dashArray: "",
        fillOpacity: 0.7,
      };
    }
    return style;
  }

  function style_rangos(color, flag) {
    let style;
    if (flag && color !== null && color !== undefined) {
      style = {
        width: "18px",
        height: "18px",
        float: "left",
        marginRight: "8px",
        opacity: "0.7",
        background: color,
      };
    } else {
      style = {
        width: "18px",
        height: "18px",
        float: "left",
        marginRight: "8px",
        opacity: "0.7",
        background: "#FFEDA0",
      };
    }
    return style;
  }

  const highlightFeature = (e) => {
    //Aqui ponemos la configuracion para resaltar el Feture en donde esta el mouse.

    var layer = e.target;
    if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) {
      layer.bringToFront();
    }
    //Si seteamos todo esto se va a renderizar por que es un estado.
    /* setProp({
            'nom_mun':e.target.feature.properties.nom_mun,
            'cve_mun':e.target.feature.properties.cve_mun,
            'avance_porcentual':e.target.feature.properties.avance_porcentual,
            'color':e.target.feature.properties.color,
            'avance':e.target.feature.properties.avance,
            'meta':e.target.feature.properties.meta
        }); */
    layer.setStyle(style(layer.feature, true));
  };

  const clickFeature = (e) => {
    //Aqui configuramos lo que queremos que haga un feature cuando le den click.
  };

  const resetHighlight = (e) => {
    //Con esto reseteamos el color en donde paso el mouse, para que no siga marcado.
    var layer = e.target;
    layer.setStyle(style(e.target.feature));
    if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) {
      layer.bringToFront();
    }
  };

  const onEachFeature = (feature, layer) => {
    //Organizamos los eventos del GeoJson.
    layer.on({
      mouseover: highlightFeature,
      mouseout: resetHighlight,
      click: clickFeature,
    });
  };

  var geojson = (
    <GeoJSON
      onEachFeature={onEachFeature}
      style={style}
      key={"geojson"}
      data={Municipios}
    ></GeoJSON>
  );
  /* FUNCIONES DEL CHOROPETH MAP*/

  const setFilter = (filtered, claveunica) => {
    setFlagFinish(false);
    setZoom(9);
    setPosition([20.816157, -101.021312]);
    //"idTipoGiro" Pendiente
    //"idTipoNegocio" LISTO
    //"idMunicipio" LISTO
    //"idRegion" LISTO
    var municipio_filtrado = filtered.filter(
      (dato) => dato.id === "idMunicipioNombre"
    );
    var idTipoNegocio = filtered.filter((dato) => dato.id === "idTipoNegocio");
    var idMunicipio = filtered.filter((dato) => dato.id === "idMunicipio");
    var idRegion = filtered.filter((dato) => dato.id === "idRegion");
    var idTipoGiro = filtered.filter((dato) => dato.id === "idTipoGiro");

    //municipio_filtrado =municipio_filtrado.value;
    idTipoNegocio = idTipoNegocio[0].value;
    idMunicipio = idMunicipio[0].value;
    idRegion = idRegion[0].value;
    idTipoGiro = idTipoGiro[0].value;

    filtered = filtered.filter(
      (dato) =>
        dato.id !== "idMunicipioNombre" &&
        dato.id !== "idTipoNegocio" &&
        dato.id !== "idMunicipio" &&
        dato.id !== "idRegion" &&
        dato.id !== "ClaveUnica" &&
        dato.id !== "idTipoGiro"
    );

    var coordinates = [];
    municipio_filtrado.forEach((dato) => {
      ArrayMunicipiosCoordenadas.RECORDS.forEach((coord) => {
        if (dato.value === coord.Nombre) {
          coordinates.push([coord.Latitud, coord.Longitud]);
        }
      });
    });

    var array_geojson = [];
    municipio_filtrado.forEach((dato) => {
      Municipios.features.forEach((coord) => {
        if (dato.value === coord.properties.nom_mun.toUpperCase()) {
          //array_geojson.push(coord.geometry.coordinates);
          array_geojson.push(coord);
        }
      });
    });

    //console.log("Features: ",array_geojson);
    setMunicipiosFiltroGeoJson(array_geojson);
    if (coordinates.length === 1) {
      setPosition(coordinates[0]);
      setZoom(11);
      getresumen(idMunicipio);
    } else if (coordinates.length > 1) {
      var LatitudMedia = 0;
      var LongitudMedia = 0;
      coordinates.forEach((dato) => {
        LatitudMedia = LatitudMedia + Number(dato[0]);
        LongitudMedia = LongitudMedia + Number(dato[1]);
      });
      LatitudMedia = LatitudMedia / coordinates.length;
      LongitudMedia = LongitudMedia / coordinates.length;

      setPosition([LatitudMedia, LongitudMedia]);
      getresumen(idMunicipio);
    } else if (coordinates.length === 0) {
      setleyendaResumen(" GENERAL");
      getresumen("");
    }

    setLoading(true);
    const datos_ = {
      page: 0,
      tipo: "and",
      pageSize: 3000,
      sorted: [
        { id: "NombreEmpresa", desc: false },
        { id: "FechaInscripcion", desc: false },
      ],
      filtered: filtered,
      Folio: claveunica != "" ? claveunica : "",
      idStatus: 3,
      idTipoNegocio: idTipoNegocio.length !== 0 ? idTipoNegocio : "",
      idMunicipio: idMunicipio.length !== 0 ? idMunicipio : "",
      idRegion: idRegion.length !== 0 ? idRegion : "",
      idTipoGiro: idTipoGiro.length !== 0 ? idTipoGiro : "",
    };
    EjecutarApi(datos_, "getNegociosMaps", token.token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.results) {
          set_data(
            res.data.map((prop, key) => {
              return {
                Latitude: prop.Latitude,
                Longitude: prop.Longitude,
                RFC: prop.RFC,
                NombreEmpresa: prop.NombreEmpresa,
                Nombre: `${prop.Nombre !== null ? prop.Nombre : ""} ${
                  prop.Paterno !== null ? prop.Paterno : ""
                } ${prop.Materno !== null ? prop.Materno : ""}`,
                Celular: prop.Celular,
                TelNegocio: prop.TelNegocio,
                TelCasa: prop.TelCasa,
                Correo: prop.Correo,
                Municipio: prop.Municipio,
                Region: prop.Region,
                Colonia: prop.Colonia,
                Calle: prop.Calle,
                Estatus: prop.Estatus,
                ClaveUnica: prop.ClaveUnica,
                data: prop,
              };
            })
          );
          setFlagFinish(true);
        }
        setLoading(false);
      })
      .catch((e) => {
        notify.show(`Error en la busqueda! ${e.message}`, "custom", 5000, {
          background: "#d61818",
          text: "#FFFFFF",
        });
        setLoading(false);
      });
  };

  return (
    <>
      <Notifications />
      {_data.lenght !== 0 && flagFinish ? (
        <Map
          className="markercluster-map"
          center={_position}
          zoom={zoom}
          style={{ width: "100%", height: "100vh" }}
        >
          <TileLayer
            attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <MarkerClusterGroup>
            {_data.map((dato) => {
              console.log(dato);
              if (
                dato.Latitude !== null &&
                dato.Longitude !== null &&
                dato.Latitude.length > 5 &&
                dato.Longitude.length > 5
              ) {
                //&& dato.Latitude !=="43412" && dato.Longitude !== "-100.479.06" && dato.Latitude !=="43.412" && dato.Longitude !== "-101.601246617z" && dato.Latitude !== "21.097147" && dato.Latitude !== "21.473852" && dato.Latitude !== "21.473852" ){

                return (
                  <Marker
                    key={dato.data.id}
                    position={[
                      dato.Latitude.replace(",", ""),
                      dato.Longitude.replace(",", ""),
                    ]}
                  >
                    {/* [dato.Latitude,dato.Longitude]}> */}
                    <Popup>
                      <Card>
                        <CardHeader>
                          <Col sm="12" style={{ alignItems: "center" }}>
                            <center>
                              <div className="photo">
                                <img
                                  src={avatar}
                                  alt="Avatar"
                                  style={{ height: "50px", width: "50px" }}
                                />
                              </div>
                            </center>
                          </Col>
                          <Col sm="12">
                            <h6>
                              {dato.NombreEmpresa}
                              <br />
                              {dato.data.TipoGiro}
                            </h6>
                          </Col>
                        </CardHeader>
                        <CardBody>
                          <Row>
                            <strong>Folio: </strong>
                            {dato.ClaveUnica}
                          </Row>
                          <Row>
                            <strong>Dueño: </strong>
                            {dato.Nombre.toUpperCase()}
                          </Row>
                          <Row>
                            <strong>Celular: </strong>
                            {dato.Celular}
                          </Row>
                          <Row>
                            <strong>Municipio: </strong>
                            {dato.Municipio}
                          </Row>
                          <Row>
                            <strong>Colonia: </strong>
                            {dato.Colonia}
                          </Row>
                          <Row>
                            <strong>Calle: </strong>
                            {dato.Calle}
                          </Row>
                          <Row>
                            <strong>NumExt: </strong>
                            {dato.data.NumExt}
                          </Row>
                          <Row>
                            <strong>Estatus: </strong>
                            {dato.Estatus}
                          </Row>
                        </CardBody>
                      </Card>
                    </Popup>
                  </Marker>
                );
              }
            })}
          </MarkerClusterGroup>
          {MunicipiosFiltroGeoJson.length === 0
            ? geojson
            : MunicipiosFiltroGeoJson.map((dato, i) => {
                return (
                  <GeoJSON
                    key={"GEOJSON_" + i}
                    onEachFeature={onEachFeature}
                    style={style}
                    data={dato}
                  ></GeoJSON>
                );
              })}

          <div className="leaflet-control-container">
            <div className="leaflet-top leaflet-right">
              <Card>
                <CardBody>
                  <h6>Resumen {leyendaResumen}</h6>
                  {resumen.length !== 0 ? (
                    <>
                      {resumen.map((res) => {
                        return (
                          <div key={res.TipoNegocio}>
                            {res.TipoNegocio}: <b>{res.Total}</b>
                            <br></br>
                          </div>
                        );
                      })}
                    </>
                  ) : (
                    <>Sin informacion</>
                  )}
                </CardBody>
              </Card>
            </div>
          </div>
        </Map>
      ) : (
        <div style={style_spinner}>
          <MagicSpinner
            size={70}
            color={"#008000"}
            loading={true}
          ></MagicSpinner>
        </div>
      )}
      <PluginFilterMap
        isLoading={loading}
        filtrar={setFilter}
        clasess="dropdowns"
      />
    </>
  );
};
export default SimpleMap;
