import React from 'react';
import Datetime from 'react-datetime';
import moment from 'moment';

//const DateTimeComponent =({id, name, dateFormat, onChange, inputProps, defaultValue, maxDate, minDate, value, placeholder, inputProps, closeOnSelect, timeFormat })=> {
  const DateTimeComponent = (props) => {

    const validation = (currentDate) => {
        return currentDate.isBefore(moment(props.maxDate)) && currentDate.isAfter(moment(props.minDate));
    };

        return (
            <Datetime
                isValidDate={validation}
                id={props.id}
                name={props.name}
                dateFormat={props.dateFormat}
                timeFormat={props.timeFormat}
                closeOnSelect={props.closeOnSelect}
                onChange={props.onChange}
                inputProps={props.inputProps}
                value={props.value}
                defaultValue={props.defaultValue}
            />
        );
}

export default DateTimeComponent;