export const UserValidator = (form) => {
  if (form.Nombre === "" || form.Nombre.length < 3) {
    return {
      error: true,
      field: "Nombre",
      message: "Debe agregar el nombre del usuario",
    };
  }
  if (form.Paterno === "" || form.Nombre.length < 3) {
    return {
      error: true,
      field: "Paterno",
      message: "Debe agregar el apellido del usuario",
    };
  }
  if (form.TelCelular === "") {
    return {
      error: true,
      field: "TelCelular",
      message: "Debe agregar el celular del usuario",
    };
  }
  if (form.TelCelular.length < 10) {
    return {
      error: true,
      field: "TelCelular",
      message: "El formato del celular es incorrecto",
    };
  }
  if (!/^[1-9]\d{2}[1-9]\d{2}\d{4}$/.test(form.TelCelular.replace(/\D/g, ""))) {
    return { error: true, field: "TelCelular", message: "Celular no valido" };
  }
  if (form.Region < 1 && form.idTipoUser < 15) {
    return {
      error: true,
      field: "Region",
      message: "Debe agregar la región del usuario",
    };
  }
  if (form.idTipoUser < 1) {
    return {
      error: true,
      field: "idTipoUser",
      message: "Debe agregar el tipo de usuario",
    };
  }

  if (
    form.Correo !== "" &&
    !/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(
      form.Correo
    )
  ) {
    return { error: true, field: "Correo", message: "Correo no valido" };
  }
  return { error: false, seccion: -1, message: "" };
};
