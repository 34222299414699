import React, { useState, useEffect } from 'react';
// react plugin used to create switch buttons

import { Button, Input, Form,FormGroup,CardBody,Row,Col,Card,CardHeader, CardTitle,Label,CardFooter} from 'reactstrap';

import ReactBSAlert from "react-bootstrap-sweetalert";
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import Sidebar from '../components/Sidebar';
import Notifications, { notify } from 'react-notify-toast';
import EjecutarApi from '../Consultas/Consultas';
import { getVars } from '../GlobalStorage';
import { useHistory } from 'react-router-dom';
import ReactTable from 'react-table';
import Select from "react-select";
import { RotateSpinner, ImpulseSpinner } from 'react-spinners-kit';
import Grupos from './Grupos';
import matchSorter from 'match-sorter';

function GruposAdd (props){
    //console.log('props-modal',props.getResponse);
    const [data,setData]=useState([]);
    const [flagFolio,setflagFolio]=useState(true);
    const [flagFolioValidar,setflagFolioValidar]=useState(false);
    const [InicioGrupos,setInicioGrupos]=useState({});
    const [Articulador,setArticulador]=useState('');
    const [idArticulador,setidArticulador]=useState('');
    const [flagBoton,setflagBoton] = useState(true);
    const [flagBotonGruposAdd,setflagBotonGruposAdd] = useState(false);
    
    const [TotalArticuladores,setTotalArticuladores]=useState(0);
    const [flagMostarAddVales, setflagMostarAddVales] = useState(false);
    const [flagBuscaArticulador, setflagBuscaArticulador] = useState(false);
    const [loading,setloading] = useState(false);
    const [BuscaArticulador,setBuscaArticulador] =useState('');
    const [totalGrupos,setTotalGrupos] = useState(0);
   
    const [FolioInicial,setFolioInicial] =useState("");
    //const []
    let history = useHistory();

    const [DetalleSolicitante, setDetalleSolicitante] = useState([]);
    //const [DetalleSolicitanteFolio, setDetalleSolicitanteFolio] = useState([]);
    const [SolicitanteNombre, setSolicitanteNombre] = useState('');
    const [SolicitanteCURP, setSolicitanteCURP] = useState('');
    const [SolicitanteFolioInicial, setSolicitanteFolioInicial] = useState('');
    const [SolicitanteFolioFinal, setSolicitanteFolioFinal] = useState('');
    const [SolicitanteCodigoInicial, setSolicitanteCodigoInicial] = useState('');
    const [SolicitanteCodigoFinal, setSolicitanteCodigoFinal] = useState('');
    const [solicitanteRemesa, setSolicitanteRemesa] = useState('');
    const [Grupo,setGrupo]=useState({});
    const [GrupoDetalle,setGrupoDetalle]=useState({});
    const token = getVars('Token');
    let idARticuladortoken = getVars('idArticulador');
    let idGrupotoken = getVars('idGrupo');
  
    useEffect(()=>{
        
        let datax = {
            page: 0,
            tipo: "and",
            pageSize: 50,
            sorted: [
                ],
            filtered: [{"id":"vales.UserOwned", "value":173} ] //idARticuladortoken
            };
        console.log('--------------->idArticulador',idARticuladortoken);
        console.log('######GrupoEditArticulador',token);
        console.log('######EGrupoEditArticulador',datax);
        EjecutarApi(datax, 'getValesNotIn', token.token)
            .then(function (res) {
                return res;
            })
            .then((res) => {
                setloading(false);
                console.log('#########Data Grid Usuarios vale',res.data);
                setDetalleSolicitante(
                    res.data.map((prop, key) => {
                        //setArticulador(`${prop.Nombre !==null ? prop.Nombre:'' } ${prop.Paterno!== null ?prop.Paterno:'' } ${prop.Materno !== null?prop.Materno:''}`);
                        
                        return {
                           
                            CURP : prop.CURP,
                            Nombre: prop.Nombre,
                            Paterno:prop.Paterno,
                            Materno:prop.Materno,
                            CodigoBarrasInicial:prop.FolioInicial,
                            CodigoBarrasFinal:prop.FolioFinal,
                            Remesa:prop.Remesa,
                            SerialInicial:prop.SerialInicial,
                            SerieFinal:prop.SerieFinal,
                            data: prop,
                        };
                    })
                    
                );
                //console.log('REGRESEEEE RUBI Y ZINCRI ES AQUI');
                
                //setloadingGrupo(false);
            })
            .catch((e) => {
                console.log('=Error[getExport]: ', e);
                setloading(false);
                notify.show(
                    `Error al obtener Vales: ${e.errors}`,
                    'custom',
                    5000,
                    {
                        background: '#0857B6',
                        fontSize: 14,
                        text: '#FFFFFF',
                    }
                );

                //setloadingGrupo(false);
            });

            const dataGrupo = {
                page: 0,
                tipo: "and",
                pageSize: 50,
                sorted: [ ],
                filtered: [ {id:"vales_grupos.id", value:52}]//idGrupotoken} ]
            };
            //console.log('######Entra a actualizar:::::::',dataGrupo);
            EjecutarApi(dataGrupo, 'getGrupos', token.token)
                .then(function (res) {
                    return res;
                })
                .then((res) => {
                    setloading(false);
                    setTotalGrupos(res.total);
                    setGrupo(
                        res.data.map((prop, key) => {
                            //console.log('######Entra a actualizar::::Items: ', prop);
                            
                            return {
                                Remesa: prop.Remesa,
                                Municipio: prop.Municipio,
                                FullName: prop.Articulador,
                                Solicitudes: prop.TotalAprobados,
                                
                                data: prop,
                            };
                        })
                    );
                    //setloadingGrupo(false);
                })
                .catch((e) => {
                    console.log('=Error[getExport]: ', e);
                    setloading(false);
                    notify.show(
                        `Error al obtener Vales: ${e.errors}`,
                        'custom',
                        5000,
                        {
                            background: '#0857B6',
                            fontSize: 14,
                            text: '#FFFFFF',
                        }
                    );

                    //setloadingGrupo(false);
                });
        

    },[]);
    
   
    const handleMiniClick = () => {
        document.body.classList.toggle('sidebar-mini');
    };
    
    const Spinenerstyle = {
        'height': '50pt',
        'display': 'flex',
        'justifyContent': 'center',
        'alignItems': 'center'

    } ;                      
    const setGrupoEvent=()=>{
       
        if (Grupo.UserOwned.length < 3) {
           
            notify.show('Error: Articulador es requerido', 'custom', 5000, {
                background: '#0857B6',
                fontSize: 14,
                text: '#FFFFFF',
            });
            return true;
        }
        if (Grupo.idMunicipio.length < 1) {
         
            notify.show('Error: Municipio es requerido', 'custom', 5000, {
                background: '#0857B6',
                fontSize: 14,
                text: '#FFFFFF',
            });
            return true;
        }
        if (Grupo.TotalAprobados.length < 1) {
         
            notify.show('Error: Total Aprobados es requerido', 'custom', 5000, {
                background: '#0857B6',
                fontSize: 14,
                text: '#FFFFFF',
            });
            return true;
        }
        if (Grupo.Remesa.length < 1) {
         
            notify.show('Error: Remesa es requerido', 'custom', 5000, {
                background: '#0857B6',
                fontSize: 14,
                text: '#FFFFFF',
            });
            return true;
        }
        console.log("Grupos Add-->", Grupo);
        EjecutarApi(Grupo, 'setGrupos', token.token)
            .then(function (res) 
            { 
                setflagBoton(false);
                 setflagBotonGruposAdd(true);
                console.log("Articulador id ##################",idArticulador);
                handleDetallesSolicitante();
                
                notify.show('Registro Exitoso! ', 'custom', 5000, {
                    background: '#18c97d',
                    text: '#FFFFFF',
                });
                return res;
            })
            .catch((e) => {
                console.log("errrorrrrrrrrr",e.errors);
              
                notify.show(`Error al registrar Grupo, el Articulador ha sido guardado! `, 'custom', 5000, {
                    background: '#d61818',
                    text: '#FFFFFF',
                });
            });

    };
    const handleReturn = () =>{
        history.push('/Grupos');
    };
    const GrupoValeAdd = () => {

        /*
        CURP: api.CURP,
        Nombre: `${api.Nombre !==null ? api.Nombre:'' } ${api.Paterno!== null ?api.Paterno:'' } ${api.Materno !== null?api.Materno:''}`,
        Paterno: api.Paterno,
        Materno:api.Materno,
        FolioInicial: "",
        FolioFinal: "",
        SerialInicial: "",
        SerialFinal: "",
        Remesa:"Remesa 1"
      
        setGrupoDetalle({...GrupoDetalle,
            SerieInicial:SolicitanteFolioInicial,
            SerieFinal:SolicitanteFolioFinal,
            CodigoBarrasInicial:SolicitanteCodigoInicial,
            CodigoBarrasFinal:SolicitanteCodigoFinal,
            Remesa:solicitanteRemesa
        });  */
        //{"UserOwned":230,"Municipio":"CELAYA","idMunicipio":7,"TotalAprobados":59,"Remesa":"Remesa1","Articulador":"ALEJANDRA GPE ZARIÑANA ROSALES","Region":6}
        let data = {
            idSolicitud:GrupoDetalle.idSolicitud,
            CURP: GrupoDetalle.CURP,
            Nombre: GrupoDetalle.Nombre,
            Paterno:GrupoDetalle.Paterno,
            Materno:GrupoDetalle.Materno,
            SerieInicial:SolicitanteFolioInicial,
            SerieFinal:SolicitanteFolioFinal,
            CodigoBarrasInicial:SolicitanteCodigoInicial,
            CodigoBarrasFinal:SolicitanteCodigoFinal,
            Remesa:Grupo.Remesa
        }

        console.log("BANDERAP->>>>>>>>>>>>>>>>", JSON.stringify(data));
        EjecutarApi(data, 'setValesSolicitudes', token.token)
            .then(function (res) {
                console.log("RESPONSE BACK:", res)
                setSolicitanteFolioInicial('');
                setSolicitanteFolioFinal('');
                setSolicitanteCodigoInicial('');
                setSolicitanteCodigoFinal('');
                setSolicitanteCURP('');
                setSolicitanteNombre('');
                setGrupoDetalle({});
                setflagFolio(true);
                handleDetallesSolicitante();
                notify.show('Registro Exitoso!', 'custom', 5000, {
                    background: '#18c97d',
                    text: '#FFFFFF',
                });
                
                return res;

            })
            .catch((e) => {
                
                console.log("error", e.errors);
                setflagFolio(true);
                notify.show(`Error:! ${e.message}`, 'custom', 5000, {
                    background: '#d61818',
                    text: '#FFFFFF',
                });
            });

       
     
    }
    const handleDetallesSolicitante =() =>{
        setloading(true);
        const token = getVars('Token');
        const datax = {
            page: 0,
            tipo: 'and',
            pageSize: 10,
            sorted: [],
            filtered:[{id:"usersC.id",value:idArticulador}]
        };
        console.log('######GrupoArticulador',token);
        console.log('######EGrupoArticulador',datax);
        EjecutarApi(datax, 'getValesNotIn', token.token)
            .then(function (res) {
                return res;
            })
            .then((res) => {
                setloading(false);
                
                setDetalleSolicitante(
                    
                    res.data.map((prop, key) => {
                        
                        return {
                           
                            //Nombre: `${prop.Nombre !==null ? prop.Nombre:'' } ${prop.Paterno!== null ?prop.Paterno:'' } ${prop.Materno !== null?prop.Materno:''}`,
                           CURP : prop.CURP,
                            Nombre: prop.Nombre,
                            Paterno:prop.Paterno,
                            Materno:prop.Materno,
                            CodigoBarrasInicial:prop.FolioInicial,
                            CodigoBarrasFinal:prop.FolioFinal,
                            Remesa:prop.Remesa,
                            SerialInicial:prop.SerialInicial,
                            SerieFinal:prop.SerieFinal,
                            data: prop,
                        };
                    })
                    
                );
                //console.log('REGRESEEEE RUBI Y ZINCRI ES AQUI');
                
                //setloadingGrupo(false);
            })
            .catch((e) => {
                console.log('=Error[getExport]: ', e);
                setloading(false);
                notify.show(
                    `Error al obtener Vales: ${e.errors}`,
                    'custom',
                    5000,
                    {
                        background: '#0857B6',
                        fontSize: 14,
                        text: '#FFFFFF',
                    }
                );

                //setloadingGrupo(false);
            });
    }
    
    const handleValidarForlio =(Folio) =>{
        //validar los folio  getSerieVale
        const token = getVars('Token');
        const datax = {
            "CodigoBarra":Folio
        };
       console.log('Datos del Folio∞∞∞∞∞∞∞∞∞∞∞∞∞∞∞∞∞∞',datax);
        EjecutarApi(datax, 'getSerieVale', token.token)
            .then(function (res) {
                return res;
            })
            .then((res) => {
                console.log('···············res.data',res.data);
                setflagFolio(false);
                setSolicitanteFolioFinal(res.data.FolioFinal);
                setSolicitanteFolioInicial(res.data.FolioInicial);
                setSolicitanteCodigoInicial(res.data.CodigoBarraInicial);//res.data.CodigoBarraInicial);
                setSolicitanteCodigoFinal(res.data.CodigoBarraFinal);
                //setSolicitanteRemesa(res.data.Remesa);
            })
            .catch((e) => {
                console.log('=Error[getExport]: ', e);
                setloading(false);
                notify.show(
                    `Error al obtener Vales: ${e.message}`,
                    'custom',
                    5000,
                    {
                        background: '#0857B6',
                        fontSize: 14,
                        text: '#FFFFFF',
                    }
                );

                //setloadingGrupo(false);
            });

    }
    
    return(
        <div className="wrapper">
            <Sidebar {...props} bgColor="black" activeColor="info" />
            <div className="main-panel">
                <Navbar {...props} handleMiniClick={handleMiniClick} />
                <Notifications />
                <div className="content">
                    <Row>
                        <Col md="12">
                            <Card>
                                <CardHeader>
                                    <Row>
                                        <Col md="9">
                                            <CardTitle tag="h6">Agregar Grupos</CardTitle>
                                        </Col>
                                        <Col>
                                            <Button color="success" onClick={()=>{handleReturn()}} size="sm"  >
                                            <i className="nc-icon nc-minimal-left" />
                                            Regresar
                                        
                                    </Button> 
                                        </Col>
                                    </Row>
                                </CardHeader>
                            </Card>
                        </Col>
                    </Row> 
                    <Row>
                        <Col md="12">
                            <Card>
                                <CardBody>
                                    <Form className="form-horizontal" id="TypeValidation">
                                            <Notifications />
                                    <Row>
                                        <Label sm="2">Articulador:<span className="text-danger">*</span></Label>
                                        
                                        <Col sm="4">
                                            <FormGroup >
                                                <Input id="articu" type="text" value={DetalleSolicitante.Articulador}  disabled    />
                                            </FormGroup>
                                        </Col>
                                        <Col md="2">
                                            <Button color="primary" size="sm" onClick={() => {setflagBuscaArticulador(true); console.log("Buscando Articulador POPup")}}>
                                                    <span className="btn-label">
                                                    <i className="nc-icon nc-zoom-split" />
                                                    </span> Buscar
                                                </Button>
                                               
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Label sm="2">Municipio:<span className="text-danger">*</span></Label>
                                        <Col sm="6">
                                            <FormGroup >
                                                <Input id="articu" type="text" value={DetalleSolicitante.Municipio}  disabled    />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Label sm="2">Total Aprobados:<span className="text-danger">*</span></Label>
                                        <Col sm="4">
                                            <FormGroup >
                                                <Input id="Total" type="text" value={DetalleSolicitante.TotalAprobados} disabled/> 
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                    <Label sm="2">Número de Remesa:<span className="text-danger">*</span></Label>
                                        <Col sm="4">
                                            <FormGroup >
                                                <Input id="remesa" type="text" value={DetalleSolicitante.Remesa} disabled    />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    </Form>
                                </CardBody>
                                <CardFooter className="text-center">
                                    {flagBoton?
                                    (<>
                                        <Button color="primary" onClick={()=>{
                                        //setflagFolio(true);
                                        setGrupoEvent()}} size="sm"   > 

                                        {<ImpulseSpinner color={'#1261ff'} size={30} loading={flagBotonGruposAdd} />}

                                        {flagBotonGruposAdd ? <span>Guardando..</span> : <span>Guardar</span>}

                                    </Button>
                                    <Button color="danger" onClick={handleReturn} size="sm"  > 
                                        <i className="nc-icon nc-simple-remove" />
                                        Cancelar
                                    </Button> </>
                                    ):(<>
                                     </>
                                    )}
                                </CardFooter>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="6">
                            <Card>
                                <CardHeader>
                                    <Row>
                                        <Col>
                                            <CardTitle tag="h6">Detalle del Solicitante</CardTitle>
                                        </Col>
                                    </Row>
                                
                                </CardHeader>
                                <Row>
                                    <Col>
                                    <ReactTable size="sm"
                                        data={DetalleSolicitante}
                                        
                                        columns={[
                                        {
                                            Header: 'CURP',
                                                    minWidth: 50,
                                                    id: 'CURP',
                                                    accessor: d => d.CURP,
                                                    filterMethod: (filter, rows) =>
                                                        matchSorter(rows, filter.value, { keys: ['Municipio'] }),
                                                    filterAll: false
                                        },
                                        {
                                            Header: 'Nombre',
                                                    minWidth: 70,
                                                    id: 'Nombre',
                                                    accessor: d => `${d.Nombre !==null ? d.Nombre:'' } ${d.Paterno!== null ?d.Paterno:'' } ${d.Materno !== null?d.Materno:''}`,
                                                    filterMethod: (filter, rows) =>
                                                        matchSorter (rows, filter.value,{ keys: ['Articulador'] }),
                                                    filterAll: false
                                        }
                                        ]}
                                        className="-striped -highlight primary-pagination"
                                        loading={loading}
                                        showPagination={true}
                                        showPageJump={false}
                                        canNextFromData={true}
                                        defaultPageSize={10}
                                        total={totalGrupos}
                                        pages={Math.floor(totalGrupos / 10)}
                                        previousText="Anterior"
                                        nextText="Siguiente"
                                        pageSizeOptions={[10, 20, 25, 50, 100]}
                                        getTrProps={(state, rowInfo, column, instance) => {
                                            return {
                                                onClick: e => {
                                                    //setDetalleSolicitanteFolio({});
                                                    setSolicitanteCURP(rowInfo.original.data.CURP);
                                                    setSolicitanteNombre(rowInfo.original.data.Nombre);
                                                    setGrupoDetalle({...GrupoDetalle,
                                                        idSolicitud:rowInfo.original.data.id,
                                                        CURP : rowInfo.original.data.CURP,
                                                        Nombre: rowInfo.original.data.Nombre,
                                                        Paterno:rowInfo.original.data.Paterno,
                                                        Materno:rowInfo.original.data.Materno,
                                                        CodigoBarrasInicial:rowInfo.original.data.FolioInicial,
                                                        CodigoBarrasFinal:rowInfo.original.data.FolioFinal,
                                                        Remesa:rowInfo.original.data.Remesa,
                                                        SerialInicial:rowInfo.original.data.SerialInicial,
                                                        SerieFinal:rowInfo.original.data.SerieFinal,
                                                       
                                                    }
                                                    )
                                                    //console.log('$$$$$$$$$$$$$llenado del grid',);
                                                }
                                                
                                            }
                                        
                                        }}
                                    />
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    
                        <Col md="6">
                            <Form>
                            <Card>
                                <CardHeader>
                                    <h6>Datos del Vale</h6>
                                </CardHeader>
                                <CardBody>
                                <Row>
                                    <Label sm="2">CURP:</Label>
                                    <Col sm="10">
                                        <FormGroup >
                                            <Input
                                                id="CURP"
                                                type="text"
                                                value={SolicitanteCURP}
                                                disabled
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Label sm="2">Nombre:</Label>
                                    <Col sm="10">
                                        <FormGroup >
                                            <Input
                                                id="Nombre"
                                                type="text"
                                                value={SolicitanteNombre}
                                                disabled
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Label sm="2">Folio/Código Barras Inicial:<span className="text-danger">*</span></Label>
                                    <Col sm="10">
                                        <FormGroup >
                                            <Input
                                                name="FolioInicial"
                                                type="number"
                                                value={SolicitanteFolioInicial}
                                                onChange={event => setSolicitanteFolioInicial(event.target.value)}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Label sm="2">Folio Inicial:<span className="text-danger">*</span></Label>
                                    <Col sm="10">
                                        <FormGroup >
                                            <Input
                                                name="FolioInicial"
                                                type="number"
                                                value={SolicitanteFolioInicial}
                                                onChange={event => setSolicitanteFolioInicial(event.target.value)}
                                                disabled
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Label sm="2">Folio Final:<span className="text-danger">*</span></Label>
                                    <Col sm="10">
                                        <FormGroup >
                                            <Input
                                                name="FolioFinal"
                                                type="text"
                                                value={SolicitanteFolioFinal}
                                               
                                                disabled
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Label sm="2">Códifo Barras Inicial:<span className="text-danger">*</span></Label>
                                    <Col sm="10">
                                        <FormGroup >
                                            <Input
                                                name="FolioInicial"
                                                type="text"
                                                value={SolicitanteCodigoInicial}
                                                
                                                disabled
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Label sm="2">Código Barras Final:<span className="text-danger">*</span></Label>
                                    <Col sm="10">
                                        <FormGroup >
                                            <Input
                                                name="FolioFinal"
                                                type="text"
                                                value={SolicitanteCodigoFinal}
                                             
                                                disabled
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Label sm="2">Remesa:<span className="text-danger">*</span></Label>
                                    <Col sm="10">
                                        <FormGroup >
                                            <Input
                                                name="FolioFinal"
                                                type="text"
                                                value={Grupo.Remesa}
                                         
                                                disabled
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                </CardBody>
                                <CardFooter>
                                <Row>
                                    {flagFolio?
                                     (<>
                                        <Button color="primary" onClick={()=>{
                                        
                                            handleValidarForlio(SolicitanteFolioInicial)}} size="sm"  > 
                                            {<ImpulseSpinner color={'#1261ff'} size={30} loading={flagFolioValidar} />}

                                            {flagFolioValidar ? <span>Guardando..</span> : <span>Asignar Vale</span>}

                                        </Button>
                                        <Button color="danger" onClick={handleReturn} size="sm"  > 
                                            <i className="nc-icon nc-simple-remove" />
                                            Cancelar
                                        </Button> </>
                                        ):(<>
                                            <Button color="primary" onClick={()=>{
                                        
                                                GrupoValeAdd()}} size="sm"  > 
                                                {<ImpulseSpinner color={'#1261ff'} size={30} loading={flagFolioValidar} />}

                                                {flagFolioValidar ? <span>Guardando..</span> : <span>Guardar</span>}

                                            </Button>
                                            <Button color="danger" onClick={handleReturn} size="sm"  > 
                                                <i className="nc-icon nc-simple-remove" />
                                                Cancelar
                                            </Button> </>
                                        
                                        )}
                            </Row> 
                                </CardFooter>
                            </Card>
                            </Form>

                        </Col>
                    </Row>
                                       
                </div>
            </div>
        </div>
    );
}
export default  GruposAdd;