import React, { useState, useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import swal from "sweetalert";
import ReactTable from "react-table";
import { CircleSpinner } from "react-spinners-kit";
import EjecutarApi, { uploadFiles } from "../Consultas/Consultas";
import Notifications from "react-notify-toast";
import { getVars } from "../GlobalStorage";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Pagination from "../components/pagination";
import Sidebar from "../components/Sidebar";
import { URL_GENERAL } from "../data/consts";
import { isNullOrUndefined } from "../utils/emptyValidations";
import ConciliacionArchivos from "../components/Archivos/ConciliacionArchivos";
import PadronArchivos from "../components/Archivos/Padron";

const Padron = (props) => {
  const [userData, setUserData] = useState();
  const [menu, setMenu] = useState({
    Ver: 0,
    Agregar: 0,
    Editar: 0,
    Eliminar: 0,
    Seguimiento: 0,
    Exportar: 0,
    Imprimir: 0,
    ViewAll: 0,
    idTipoUser: 0,
  });
  const history = useHistory();
  const [total, setTotal] = useState(0);
  const [loadingCedulaList, setLoadingCedulaList] = useState(false);
  const [dataCedulaList, setDataCedulaList] = useState([]);
  const [menuLoaded, setMenuLoaded] = useState(false);
  const [archivos, setArchivos] = useState([]);
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [clasificacion, setClasificacion] = useState([]);
  const [codigo, setCodigo] = useState([]);
  const [catalogos, setCatalogos] = useState([]);
  const [catOrigen, setCatOrigen] = useState([]);
  const [origen, setOrigen] = useState([]);
  const [isLoadingCatalogos, setIsLoadingCatalogos] = useState(false);
  //Filtro
  const [filtro, setFiltro] = useState([]);
  const [pageOptions, setPage] = useState({
    page: 0,
    pageSize: 10,
  });

  const columns = [
    {
      Header: "Acciones",
      headerClassName: "text-center",
      minWidth: 70,
      id: "Acciones",
      accessor: (d) => d.Acciones,
      className: "text-center",
      filterAll: false,
      style: { fontSize: "10px" },
      headerStyle: { fontSize: "10px" },
    },
    {
      Header: "Remesa",
      headerClassName: "text-center",
      minWidth: 70,
      id: "Remesa",
      accessor: (d) => d.Remesa,
      filterAll: true,
      style: { fontSize: "10px" },
      headerStyle: { fontSize: "10px" },
      className: "text-center",
    },
    {
      Header: "Registros",
      headerClassName: "text-center",
      minWidth: 60,
      id: "Registros",
      accessor: (d) => new Intl.NumberFormat().format(d.Registros),
      filterAll: false,
      style: { fontSize: "10px" },
      headerStyle: { fontSize: "10px" },
      className: "text-center",
    },
    {
      Header: "Fecha de Carga",
      headerClassName: "text-center",
      minWidth: 90,
      id: "Quincena",
      accessor: (d) => d.FechaUpload,
      className: "text-center",
      filterAll: true,
      style: { fontSize: "10px" },
      headerStyle: { fontSize: "10px" },
    },
    {
      Header: "Cargado Por",
      headerClassName: "text-center",
      minWidth: 120,
      id: "UserUpload",
      accessor: (d) => d.UserUpload,
      filterAll: false,
      style: { fontSize: "10px" },
      headerStyle: { fontSize: "10px" },
      className: "text-center",
    },
  ];

  useEffect(() => {
    if (isNullOrUndefined(userData)) return;
    const menus = userData.menu;
    const currentMenu = menus.find((menu) => menu.idMenu === 26);
    if (isNullOrUndefined(currentMenu) || currentMenu.Ver < 1) {
      swal({
        title: "Atención",
        text: "No tiene permisos para este módulo, contacte al administrador",
        icon: "warning",
      });
      history.push("/not-found");
      return;
    }
    setMenu({
      Ver: currentMenu.Ver,
      Agregar: currentMenu.Agregar,
      Editar: currentMenu.Editar,
      Eliminar: currentMenu.Eliminar,
      Seguimiento: currentMenu.Seguimiento,
      Exportar: currentMenu.Exportar,
      Imprimir: currentMenu.Imprimir,
      ViewAll: currentMenu.ViewAll,
      idTipoUser: userData.user.idTipoUser.id,
    });
    setMenuLoaded(true);
  }, [userData]);

  const handleMiniClick = () => {
    document.body.classList.toggle("sidebar-mini");
  };

  useEffect(() => {
    setUserData(getVars("Token"));
  }, []);

  const fetchData = useCallback(
    ({ pageSize, page }) => {
      const data = {
        page: page > pageOptions.page ? page : pageOptions.page,
        pageSize: pageSize,
        programa: 1,
        filtered: filtro,
      };
      setPage({
        page: page,
        pageSize: pageSize,
      });
      getRegistros(data);
    },
    [menu, filtro]
  );

  const getRegistros = (data) => {
    const token = getVars("Token");
    setLoadingCedulaList(true);
    data.filtered = filtro;
    EjecutarApi(data, "getPadrones", token.token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.results && res.success) {
          setDataCedulaList(
            res.data.map((item) => {
              return {
                ...item,
                Acciones: (
                  <>
                    {item.Estatus === 0 ? (
                      <Row>
                        <Col>
                          <Button
                            size="sm"
                            color="primary"
                            onClick={() => downloadFile(item)}
                          >
                            Padrón
                          </Button>
                        </Col>
                        <Col>
                          <Button
                            size="sm"
                            color="warning"
                            onClick={() => alert(item)}
                          >
                            Cerrar
                          </Button>
                        </Col>
                      </Row>
                    ) : (
                      <Row>
                        <Col>
                          <Button
                            size="sm"
                            color="primary"
                            onClick={() => downloadFile(item)}
                          >
                            Padrón
                          </Button>
                        </Col>
                      </Row>
                    )}
                  </>
                ),
              };
            })
          );
          setTotal(res.total);

          EjecutarApi({}, `getRemesasPadron`, token.token, "GET")
            .then(function (res) {
              return res;
            })
            .then((res) => {
              if (res.results && res.success) {
                setCatalogos(res.data);
              } else {
                setIsModalOpened(false);
                swal({
                  title: "¡Atención!",
                  text: `${res.message}`,
                  icon: "info",
                });
              }
              setIsLoadingCatalogos(false);
            })
            .catch((e) => {
              setIsLoadingCatalogos(false);
              setIsModalOpened(false);
              swal({
                title: "¡Atención!",
                text: `${e.message}`,
                icon: "info",
              });
            });

          EjecutarApi({}, `getOrigenVales`, token.token, "GET")
            .then(function (res) {
              return res;
            })
            .then((res) => {
              if (res.results && res.success) {
                setCatOrigen(res.data);
              } else {
                setIsModalOpened(false);
                swal({
                  title: "¡Atención!",
                  text: `${res.message}`,
                  icon: "info",
                });
              }
              setIsLoadingCatalogos(false);
            })
            .catch((e) => {
              setIsLoadingCatalogos(false);
              setIsModalOpened(false);
              swal({
                title: "¡Atención!",
                text: `${e.message}`,
                icon: "info",
              });
            });
          setLoadingCedulaList(false);
        } else {
          swal({
            title: "¡Atención!",
            text: `${res.message}`,
            icon: "info",
          });
          setLoadingCedulaList(false);
        }
      })
      .catch((e) => {
        setLoadingCedulaList(false);
        setTimeout(() => {
          swal({
            title: "¡Atención!",
            text: `${e.message}`,
            icon: "warning",
          });
        }, 1000);
      });
  };

  const handleChangeArchivos = (files) => {
    setArchivos(files);
  };

  const handleChangeClasificacion = (arrayClasificacion) => {
    setClasificacion(arrayClasificacion);
  };

  const handleChangeOrigen = (arrayOrigen) => {
    setOrigen(arrayOrigen);
  };

  const handleChangeCodigo = (arrayCodigo) => {
    setCodigo(arrayCodigo);
  };

  const handleDeleteFile = (files, arrayClasificacion, arrayOrigen, codigo) => {
    setArchivos(files);
    setClasificacion(arrayClasificacion);
    setOrigen(arrayOrigen);
    setCodigo([]);
  };

  const downloadFile = (item) => {
    const token = getVars("Token");
    var url = new URL(
      `${URL_GENERAL}getPadronRemesa?id=${item.Remesa}&token=${token.token}`
    );
    window.open(url, "_blank");
  };

  const downloadPlantilla = (item) => {
    const token = getVars("Token");
    var url = new URL(URL_GENERAL + `getPadronPlantilla?token=${token.token}`);
    window.open(url, "_blank");
  };

  const changeFiles = () => {
    setIsModalOpened(false);
    setLoadingCedulaList(true);
    const token = getVars("Token");
    let newFiles = [];
    let newRemesa = [];
    let newOrigen = [];
    let newCodigo = [];
    archivos.forEach((file, index) => {
      newFiles.push(file);
      newRemesa.push(clasificacion[index]);
      newOrigen.push(origen[index]);
      newCodigo.push(codigo[index]);
    });

    if (newFiles.length > 0) {
      let f = false;
      newFiles.map((file) => {
        let nameFile = file.name.toString();
        if (!/(.xlsx|.xls|.xlsb)$/i.test(nameFile)) {
          return (f = true);
        }
      });
      if (f) {
        setIsModalOpened(true);
        setLoadingCedulaList(false);
        swal({
          title: "Alerta",
          text: "Solo puede cargar archivos de tipo excel",
          icon: "warning",
        });
        return;
      }

      if (isNullOrUndefined(newRemesa[0]) || newRemesa[0] === "") {
        setIsModalOpened(true);
        setLoadingCedulaList(false);
        swal({
          title: "Alerta",
          text: "Debe seleccionar una remesa",
          icon: "warning",
        });
        return;
      }

      if (isNullOrUndefined(newOrigen[0]) || newOrigen[0] === "") {
        setIsModalOpened(true);
        setLoadingCedulaList(false);
        swal({
          title: "Alerta",
          text: "Debe seleecionar el origen del archivo",
          icon: "warning",
        });
        return;
      }

      if (isNullOrUndefined(newCodigo[0]) || newCodigo[0] === "") {
        setIsModalOpened(true);
        setLoadingCedulaList(false);
        swal({
          title: "Alerta",
          text: "Debe ingresar el codigo para el archivo",
          icon: "warning",
        });
        return;
      }
    }

    const formAux = {
      NewFiles: newFiles,
      NewRemesa: newRemesa,
      NewOrigen: newOrigen,
      NewCodigo: newCodigo,
    };
    uploadFiles({
      data: formAux,
      api: `uploadPadron`,
      token: token.token,
    })
      .then(function (res) {
        return res;
      })
      .then((res) => {
        setArchivos([]);
        setClasificacion([]);
        setOrigen([]);
        setCodigo([]);
        if (res.success && res.results) {
          if (res.incidencias) {
            let url = new URL(
              `${URL_GENERAL}getIncidenciasPadron?id=${res.idArchivo}&token=${token.token}`
            );
            window.open(url, "_blank");
          }
          swal({
            title: res.incidencias ? "Atención" : "¡Éxito!",
            text: res.message,
            icon: res.incidencias ? "warning" : "success",
          });
          setLoadingCedulaList(false);
          const data = {
            page: 0,
            pageSize: 15,
            programa: 1,
            filtered: filtro,
          };
          getRegistros(data);
        } else {
          swal({
            title: "¡Atención!",
            text: res.message,
            icon: "warning",
          });
          setLoadingCedulaList(false);
        }
      })
      .catch((error) => {
        swal({
          title: "Ha ocurrido un error",
          text: error.message,
          icon: "warning",
        });
        setLoadingCedulaList(false);
      });
  };

  const alert = (item) => {
    swal({
      title: "¡Atención!",
      text: "¿Está seguro que desea cerrar la remesa?  (NO podrá cargar más archivos en ella)",
      icon: "warning",

      buttons: ["Cancelar", true],
    }).then((response) => {
      if (response) {
        closeRemesa(item);
      }
    });
  };

  const closeRemesa = (item) => {
    setLoadingCedulaList(true);
    const token = getVars("Token");
    let data = {
      Remesa: item.Remesa,
    };
    EjecutarApi(data, "updateStatusRemesa", token.token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.results && res.success) {
          swal({
            title: "¡Éxito!",
            text: `${res.message}`,
            icon: "success",
          });
          const data = {
            page: 0,
            pageSize: 15,
            programa: 1,
            filtered: filtro,
          };
          getRegistros(data);
        } else {
          swal({
            title: "¡Atención!",
            text: `${res.message}`,
            icon: "info",
          });
        }
        setLoadingCedulaList(false);
      })
      .catch((e) => {
        setLoadingCedulaList(false);
        swal({
          title: "¡Atención!",
          text: `${e.message}`,
          icon: "warning",
        });
      });
  };

  return (
    <>
      <div className="wrapper">
        <Sidebar {...props} bgColor="black" activeColor="info" />
        <div className="main-panel">
          <Navbar {...props} handleMiniClick={handleMiniClick} />
          <Notifications />
          <div className="content">
            <>
              <Modal
                size="lg"
                isOpen={isModalOpened}
                toggle={() => setIsModalOpened(!isModalOpened)}
                keyboard={false}
                backdrop={"static"}
              >
                <ModalHeader>Cargar Archivo</ModalHeader>
                <ModalBody>
                  <PadronArchivos
                    files={archivos}
                    archivosClasificacion={clasificacion}
                    arrayOrigen={origen}
                    codigo={codigo}
                    catalogClasificacion={catalogos}
                    catalogOrigen={catOrigen}
                    loadingCatalogs={isLoadingCatalogos}
                    handleChangeArchivos={handleChangeArchivos}
                    handleChangeClasificacion={handleChangeClasificacion}
                    handleChangeOrigen={handleChangeOrigen}
                    handleChangeCodigo={handleChangeCodigo}
                    onlyForView={loadingCedulaList > 0}
                    handleDeleteFile={handleDeleteFile}
                    fullscreen={true}
                  />
                </ModalBody>
                <ModalFooter>
                  <Button
                    size="sm"
                    color="danger"
                    onClick={() => {
                      setIsModalOpened(false);
                      setArchivos([]);
                      setClasificacion([]);
                      setOrigen([]);
                      setCodigo([]);
                    }}
                    style={{ paddingRight: "1rem" }}
                  >
                    Cancelar
                  </Button>
                  {menu.Agregar === 1 && archivos.length > 0 && (
                    <Button
                      size="sm"
                      color="primary"
                      onClick={() => changeFiles()}
                    >
                      Guardar
                    </Button>
                  )}
                </ModalFooter>
              </Modal>{" "}
              <Row>
                <Col md="12">
                  <Card>
                    <CardHeader>
                      <Row>
                        <Col md="6">
                          <CardTitle tag="h6">PADRONES CARGADOS</CardTitle>
                        </Col>
                        <Col md="3">
                          {menu.Exportar === 1 ? (
                            <>
                              {!loadingCedulaList && (
                                <Button
                                  size="sm"
                                  color="link"
                                  disabled={false}
                                  onClick={() => downloadPlantilla({ id: 0 })}
                                >
                                  Descargar plantilla
                                </Button>
                              )}
                            </>
                          ) : (
                            <></>
                          )}
                        </Col>
                        <Col md="3">
                          {menu.Exportar === 1 ? (
                            <>
                              {!loadingCedulaList && (
                                <Button
                                  size="sm"
                                  color="primary"
                                  disabled={false}
                                  onClick={() => {
                                    setIsModalOpened(true);
                                  }}
                                >
                                  Cargar Padrón
                                </Button>
                              )}
                            </>
                          ) : (
                            <></>
                          )}
                        </Col>
                      </Row>
                    </CardHeader>
                    <CardBody>
                      {loadingCedulaList && (
                        <CircleSpinner
                          className="text-center"
                          size={30}
                          color="#686769"
                          loading={loadingCedulaList}
                        />
                      )}
                      <Col lg="12" md="12">
                        {menuLoaded && dataCedulaList && (
                          <ReactTable
                            size="sm"
                            data={dataCedulaList}
                            noDataText="No Existen Datos a Mostrar!"
                            manual
                            columns={columns}
                            className="-striped -highlight primary-pagination"
                            loading={loadingCedulaList}
                            showPagination={true}
                            showPaginationBottom={true}
                            showTotalPages={true}
                            showPageJump={false}
                            canNextFromData={true}
                            defaultPageSize={pageOptions.pageSize}
                            total={total}
                            page={pageOptions.page}
                            pages={Math.floor(total / pageOptions.pageSize)}
                            previousText="Anterior"
                            nextText="Siguiente"
                            ofText="de"
                            pageSizeOptions={[10, 20, 25, 50, 100]}
                            PaginationComponent={Pagination}
                            onFetchData={fetchData}
                          />
                        )}
                      </Col>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Footer fluid />
            </>
          </div>
        </div>
      </div>
    </>
  );
};
export default Padron;
