import React from 'react';


import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Row,
    Col
} from "reactstrap";

import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Sidebar from './components/Sidebar';
import { Link } from 'react-router-dom';
import Notifications, { notify } from 'react-notify-toast';
const  App = (props) => {
    const handleMiniClick = () => {
		document.body.classList.toggle('sidebar-mini');
	};
    return (
        <>
        <div className="wrapper">
			<Sidebar {...props} bgColor="black" activeColor="info" />
			<div className="main-panel">
                <Navbar {...props} handleMiniClick={handleMiniClick} />
				<Notifications />
				<div className="content">

                        <Row>
                            <Col md="12">
                                <Card>
                                    <CardHeader>
                                        <Row>
                                            <Col md="6">
                                                <CardTitle tag="h4">Inicio</CardTitle>
                                            </Col>
                                        </Row>
                                    </CardHeader>
                                    <CardBody>
                                       
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        </div>
				<Footer fluid />
			</div>
		</div>
        </>
    );
}

export default App;