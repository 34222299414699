import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
} from "reactstrap";
// react component for creating dynamic tables
import ReactTable from "react-table";
import { CircleSpinner } from "react-spinners-kit";

import EjecutarApi from "../Consultas/Consultas";
import Notifications, { notify } from "react-notify-toast";

import { getVars } from "../GlobalStorage";

import { Link } from "react-router-dom";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Sidebar from "../components/Sidebar";
import FixedPlugin from "../components/PluginFilterRecepcionExpedientes";
import ValesUpdate from "./ValesUpdate";
import Pagination from "../components/pagination";
import { useHistory } from "react-router-dom";
import { URL_GENERAL } from "../data/consts";

const ValesRecepcion = (props) => {
  //const [flag, setFlag] = useState(false);
  let history = useHistory();
  const [totalVales, setTotalVales] = useState(0);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [regiones, setRegiones] = useState([]);
  const [_menu, _setmenu] = useState({
    Ver: 1,
    Agregar: 1,
    Editar: 0,
    Eliminar: 0,
    Seguimiento: 0,
    Exportar: 0,
    Imprimir: 0,
    ViewAll: 0,
  });
  const token = getVars("Token");

  //update
  const [_item, _setItem] = useState({});
  const [isUpdate, setisUpdate] = useState(false);

  //const [banMensaje, setbanMensaje] = useState(false);
  const [FlagFiltro, setFlagFiltro] = useState(false);
  //Filtro
  const [Filtro, setFiltro] = useState({
    _Folio: "",
    _Ejercicio: "",
    _NombreCompleto: "",
    _NombreCreated: "",
    _UserOwned: "",
    _Remesa: "",
    _Municipio: "",
    _Colonia: "",
    _Region: [],
    _filtro: [],
  });

  useEffect(() => {
    console.log("=>Menu cargar");
    setearMenu("Estado");
  }, []);

  useEffect(() => {
    if (_menu.Agregar !== 1) {
      history.goBack();
    }
  }, [_menu]);

  const handleMiniClick = () => {
    document.body.classList.toggle("sidebar-mini");
  };
  const setFilter = (filtered) => {
    /* setFiltro({...Filtro, _Folio: filtered.Folio, 
       _Ejercicio: filtered.Ejercicio,
     _NombreCompleto: filtered.NombreCompleto,
     _NombreCreated: filtered.NombreCreated,
     _UserOwned: filtered.UserOwned,
     _UserRecepciono: filtered.UserRecepciono,
     _Remesa: filtered.Remesa,
     _Municipio: filtered.Municipio,
     _Region: filtered.Regiones,
     _filtro: filtered.filtered}); */

    let menu_local_storage = setearMenu("Storage");

    var ParametrosVale = filtered;
    var editar = false;
    setLoading(true);

    /*  
    {"page":0,"tipo":"or","Propietario":1181,"pageSize":10,
    "sorted":[{"id":"V.FechaDocumentacion","desc":true}],
    "filtered":[],"Folio":"","Ejercicio":2021,"NombreCompleto":"",
    "UserOwned":"","UserRecepciono":"","Remesa":"","idMunicipio":"","Regiones":[]}
    */
    let array_filtered = [];
    /* if(filtered.UserOwned){
      array_filtered.push({ id:'V.UserOwned',value:filtered.UserOwned})
    } */
    if (filtered.UserRecepciono) {
      array_filtered.push({
        id: "V.idUserDocumentacion",
        value: filtered.UserRecepciono,
      });
    }
    let FechaDocumentacion = filtered.filtered.filter(
      (item) => item.id === "V.FechaDocumentacion"
    );
    //console.log("Fecha Documentacion",FechaDocumentacion);
    if (FechaDocumentacion.length !== 0) {
      array_filtered.push({
        id: "V.FechaDocumentacion",
        value: FechaDocumentacion[0].value,
      });
    }
    const data_filtro = {
      Folio: filtered.Folio.length !== 0 ? filtered.Folio : "",
      page: 0, //filtered.page ? filtered.page :  0,
      pageSize: 10, // filtered.pageSize ? filtered.pageSize :  10,
      tipo: "and", //filtered.tipo ? filtered.tipo :  'and',
      sorted: [
        { id: "V.Paterno", desc: false },
        { id: "V.FechaSolicitud", desc: true },
      ],
      filtered: array_filtered,
      NombreCompleto: filtered.NombreCompleto,
      CURP: filtered.CURP.length > 0 ? filtered.CURP : "",
      Ejercicio: filtered.Ejercicio,
      Regiones: filtered.Regiones,
      Remesa: filtered.Remesa,
      Articulador: filtered.UserOwned.length > 0 ? filtered.UserOwned : "",
    };
    setFiltro(data_filtro);
    setFlagFiltro(true);

    console.log(
      "=>getValesRecepcionDocumentacion: ",
      JSON.stringify(data_filtro)
    );
    console.log("=>totken: ", token.token);

    EjecutarApi(data_filtro, "getValesRecepcionDocumentacion", token.token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.results) {
          setLoading(false);
          setTotalVales(res.total);

          setData(
            res.data.map((prop, key) => {
              editar = false;
              if (menu_local_storage.regiones.length === 0) {
                if (menu_local_storage.Editar === 1) {
                  if (prop.Remesa === null) {
                    if (
                      prop.UserOwned.id === token.user.id ||
                      prop.UserCreated.id === token.user.id
                    ) {
                      editar = true;
                    } else {
                      editar = false;
                    }
                  } else {
                    editar = false;
                  }
                } //else de Editar
                else {
                  editar = false;
                }
              } else if (prop.Remesa === null) {
                //regiones
                editar = true;
              }

              return {
                Nombre: editar ? (
                  <Button
                    color="info"
                    size="sm"
                    onClick={() => handleclickRowEdit(prop)}
                  >
                    <span className="btn-label">
                      <i className="nc-icon nc-ruler-pencil" />
                    </span>
                    {prop.Nombre} {prop.Paterno} {prop.Materno}
                  </Button>
                ) : (
                  `${prop.Nombre} ${prop.Paterno}
                        ${prop.Materno}`
                ),
                CURP: prop.CURP,
                FolioSolicitud: prop.FolioSolicitud,
                FechaSolicitud: prop.FechaSolicitud,
                Region: prop.Region,
                Municipio: prop.Municipio,
                isDocumentacionEntrega: prop.isDocumentacionEntrega,
                FechaDocumentacion: prop.FechaDocumentacion,
                idUserDocumentacion: prop.idUserDocumentacion,
                CelularRecepciono: prop.CelularRecepciono,
                UserRecepciono: prop.UserRecepciono,
                data: prop,
              };
            })
          );
        } else if (res.total === 0) {
          setTotalVales(res.total);

          setData([]);
          setLoading(false);
          notify.show(`No Existen datos a Mostrar`, "custom", 5000, {
            background: "#AED6F1",
            text: "#FFFFFF",
          });
        }
      })
      .catch((e) => {
        notify.show(`Error en la busqueda! ${e.message}`, "custom", 5000, {
          background: "#d61818",
          text: "#FFFFFF",
        });
        setLoading(false);
      });
  };

  const setearMenu = (dependecia) => {
    if (dependecia === "Estado") {
      token.menu.forEach((prop, key) => {
        if (prop.Menu === "Recepción Expedientes") {
          console.log("=>Menu: ", prop);
          _setmenu({
            Ver: prop.Ver,
            Agregar: prop.Agregar,
            Editar: prop.Editar,
            Eliminar: prop.Eliminar,
            Seguimiento: prop.Seguimiento,
            Exportar: prop.Exportar,
            Imprimir: prop.Imprimir,
            ViewAll: prop.ViewAll,
            idTipoUser: token.user.idTipoUser.id,
          });
          setRegiones({ regiones: prop.regiones });
        }
      });
    } else {
      var json = {};

      token.menu.forEach((prop, key) => {
        if (prop.Menu === "Recepción Expedientes") {
          console.log("=>Menu: ", prop);
          _setmenu({
            Ver: prop.Ver,
            Agregar: prop.Agregar,
            Editar: prop.Editar,
            Eliminar: prop.Eliminar,
            Seguimiento: prop.Seguimiento,
            Exportar: prop.Exportar,
            Imprimir: prop.Imprimir,
            ViewAll: prop.ViewAll,
            idTipoUser: token.user.idTipoUser.id,
          });
          json = {
            Ver: prop.Ver,
            Agregar: prop.Agregar,
            Editar: prop.Editar,
            Eliminar: prop.Eliminar,
            Seguimiento: prop.Seguimiento,
            Exportar: prop.Exportar,
            Imprimir: prop.Imprimir,
            ViewAll: prop.ViewAll,
            idTipoUser: token.user.idTipoUser.id,
            regiones: token.regiones,
          };
        }
      });

      return json;
    }
  };

  const btnExportar = () => {
    var url = new URL(
      URL_GENERAL + `getRepValesExpedientes?token=${token.token}`
    );

    window.open(url, "_blank");
  };

  const onHide = (action) => {
    setisUpdate(action);
    var datax = {};
    let menu_local_storage = setearMenu("Storage");
    if (menu_local_storage.regiones.length === 0)
      datax = {
        page: 0,
        tipo: "or",
        Propietario: _menu.ViewAll === 1 ? "" : token.user.id,
        pageSize: 10,
        sorted: [
          { id: "vales.Paterno", desc: false },
          { id: "vales.FechaSolicitud", desc: true },
        ],
        filtered: [],
        //UserOwned:token.user.id
      };
    else
      datax = {
        page: 0,
        tipo: "or",
        //Propietario: _menu.ViewAll ==1 ? "":token.user.id,
        pageSize: 10,
        sorted: [
          { id: "vales.Paterno", desc: false },
          { id: "vales.FechaSolicitud", desc: true },
        ],
        filtered: [],
        Regiones: menu_local_storage.regiones,
      };
    setFilter(datax);
  };
  const handleclickRowEdit = (e) => {
    //var item = e;
    //let menu_local_storage = setearMenu("Storage");
    //console.log('Editar: ',menu_local_storage.Editar);
    //if (menu_local_storage.Editar === 1) {
    _setItem(e);
    setisUpdate(true);
    //}
  };

  return (
    <>
      <div className="wrapper">
        <Sidebar {...props} bgColor="black" activeColor="info" />
        <div className="main-panel">
          <Navbar {...props} handleMiniClick={handleMiniClick} />
          <Notifications />
          <div className="content">
            <Row>
              <Col md="12">
                <Card>
                  <CardHeader>
                    <CardTitle tag="h4">Listado de Vales</CardTitle>
                    {_menu.Agregar === 1 ? (
                      <>
                        <Link to="recepcion_add">
                          <Button color="success" size="sm">
                            <span className="btn-label">
                              <i className="fa fa-user-plus fa-2x" />
                            </span>
                            Capturar
                          </Button>
                        </Link>
                      </>
                    ) : (
                      <></>
                    )}
                    {_menu.Exportar === 1 ? (
                      <>
                        <Button onClick={btnExportar} color="success" size="sm">
                          <span className="btn-label">
                            <i className="fa fa-user-plus fa-2x" />
                          </span>
                          Exportar
                        </Button>
                      </>
                    ) : (
                      <></>
                    )}
                  </CardHeader>
                  <CardBody>
                    {loading && (
                      <CircleSpinner
                        className="text-center"
                        size={30}
                        color="#686769"
                        loading={loading}
                      />
                    )}
                    <ReactTable
                      size="sm"
                      data={data}
                      noDataText="No Existen Datos a Mostrar!"
                      //filerable
                      manual
                      columns={[
                        {
                          Header: "Region",
                          minWidth: 40,
                          id: "Region",
                          accessor: (d) => d.Region,
                          filterAll: false,
                          style: { fontSize: "10px" },
                          headerStyle: { fontSize: "10px" },
                        },
                        {
                          Header: "Municipio",
                          minWidth: 80,
                          id: "Municipio",
                          accessor: (d) => d.Municipio,
                          filterAll: false,
                          style: { fontSize: "10px" },
                          headerStyle: { fontSize: "10px" },
                        },

                        {
                          Header: "Folio",
                          minWidth: 40,
                          id: "Folio",
                          accessor: (d) => d.FolioSolicitud,
                          filterAll: false,
                          style: { fontSize: "10px" },
                          headerStyle: { fontSize: "10px" },
                        },
                        {
                          Header: "Solicitante",
                          minWidth: 120,
                          id: "Nombre",
                          accessor: (d) => d.Nombre,
                          filterAll: false,
                          style: { fontSize: "10px" },
                          headerStyle: { fontSize: "10px" },
                        },
                        {
                          Header: "Fecha Recepcionó",
                          minWidth: 60,
                          id: "FechaDocumentacion",
                          accessor: (d) => d.FechaDocumentacion,
                          filterAll: false,
                          style: { fontSize: "10px" },
                          headerStyle: { fontSize: "10px" },
                        },
                        {
                          Header: "Recepciono",
                          minWidth: 120,
                          id: "UserRecepciono",
                          accessor: (d) => d.UserRecepciono,
                          filterAll: false,
                          style: { fontSize: "10px" },
                          headerStyle: { fontSize: "10px" },
                        },
                      ]}
                      className="-striped -highlight primary-pagination"
                      loading={loading}
                      showPagination={true}
                      showPaginationBottom={true}
                      showTotalPages={true}
                      showPageJump={false}
                      canNextFromData={true}
                      defaultPageSize={10}
                      total={totalVales}
                      pages={Math.floor(totalVales / 10)}
                      previousText="Anterior"
                      nextText="Siguiente"
                      ofText="de"
                      pageSizeOptions={[10, 20, 25, 50, 100]}
                      PaginationComponent={Pagination}
                      /* getTrProps={(state, rowInfo, column, instance) => {
                         return {
                           onClick: (e) => {
                             if (_menu.Editar === 1) {
                               console.log(
                                 "Click en Row",
                                 rowInfo.row._original.data
                               );
                               console.log("isUpdate", isUpdate);
                               _setItem(rowInfo.row._original.data);
 
                               setisUpdate(true);
                             }
                           },
                         };
                       }}*/
                      onFetchData={(stateComp, instance) => {
                        setLoading(true);
                        var editar = false;
                        const token = getVars("Token");
                        let menu_local_storage = setearMenu("Storage");
                        var ParametrosVale = {};

                        if (FlagFiltro) {
                          ParametrosVale = Filtro;
                          setFiltro({
                            ...Filtro,
                            page: stateComp.page,
                            pageSize: stateComp.pageSize,
                          });
                        } else {
                          ParametrosVale = {
                            Articulador: "",
                            CURP: "",
                            Ejercicio: 2022,
                            Folio: "",
                            NombreCompleto: "",
                            Regiones: [],
                            Remesa: "",
                            filtered: [],
                            page: stateComp.page,
                            pageSize: stateComp.pageSize,
                            sorted: [
                              { id: "V.Paterno", desc: false },
                              { id: "V.FechaSolicitud", desc: true },
                            ],
                            tipo: "and",
                          };
                        }
                        // console.log('=>getValesRecepcionDocumentacion T: ', token.token);
                        // console.log('=>getValesRecepcionDocumentacion: ', JSON.stringify(ParametrosVale));
                        EjecutarApi(
                          ParametrosVale,
                          "getValesRecepcionDocumentacion",
                          token.token
                        )
                          .then((res) => {
                            if (res.results) {
                              setLoading(false);
                              setTotalVales(res.total);

                              setData(
                                res.data.map((prop, key) => {
                                  editar = false;
                                  if (
                                    menu_local_storage.regiones.length === 0
                                  ) {
                                    if (menu_local_storage.Editar === 1) {
                                      if (prop.Remesa === null) {
                                        if (
                                          prop.UserOwned.id === token.user.id ||
                                          prop.UserCreated.id === token.user.id
                                        ) {
                                          editar = true;
                                        } else {
                                          editar = false;
                                        }
                                      } else {
                                        editar = false;
                                      }
                                    } //else de Editar
                                    else {
                                      editar = false;
                                    }
                                  } else if (prop.Remesa === null) {
                                    //regiones
                                    editar = true;
                                  }

                                  return {
                                    Nombre: editar ? (
                                      <Button
                                        color="info"
                                        size="sm"
                                        onClick={() => handleclickRowEdit(prop)}
                                      >
                                        <span className="btn-label">
                                          <i className="nc-icon nc-ruler-pencil" />
                                        </span>
                                        {prop.Nombre} {prop.Paterno}{" "}
                                        {prop.Materno}
                                      </Button>
                                    ) : (
                                      `${prop.Nombre} ${prop.Paterno}
                                              ${prop.Materno}`
                                    ),
                                    CURP: prop.CURP,
                                    FolioSolicitud: prop.FolioSolicitud,
                                    FechaSolicitud: prop.FechaSolicitud,
                                    Region: prop.Region,
                                    Municipio: prop.Municipio,
                                    isDocumentacionEntrega:
                                      prop.isDocumentacionEntrega,
                                    FechaDocumentacion: prop.FechaDocumentacion,
                                    idUserDocumentacion:
                                      prop.idUserDocumentacion,
                                    CelularRecepciono: prop.CelularRecepciono,
                                    UserRecepciono: prop.UserRecepciono,
                                    data: prop,
                                  };
                                })
                              );
                              //setLoadingGrupo(false);
                            } else if (res.total === 0) {
                              setTotalVales(res.total);

                              setData([]);
                              notify.show(
                                `No Existen datos a Mostrar`,
                                "custom",
                                5000,
                                {
                                  background: "#AED6F1",
                                  text: "#FFFFFF",
                                }
                              );
                            }
                          })
                          .catch((e) => {
                            setLoading(false);
                            notify.show(
                              `Error al obtener Vales: ${e.errors}`,
                              "custom",
                              5000,
                              {
                                background: "#0857B6",
                                fontSize: 14,
                                text: "#FFFFFF",
                              }
                            );

                            //setLoadingGrupo(false);
                          });
                      }}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
          <FixedPlugin
            isLoading={loading}
            filtrar={setFilter}
            _menu={_menu}
            clasess="dropdowns"
          />
          {isUpdate ? (
            <ValesUpdate props={props} _vales={_item} onHide={onHide} />
          ) : (
            <></>
          )}

          <Footer fluid />
        </div>
      </div>
    </>
  );
};
export default ValesRecepcion;
