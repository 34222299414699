import "./styles.css";
import React, { useState } from "react";
import { Button, Input, Form, Label } from "reactstrap";

function PluginFilterListadoExpedientes(props) {
  const { sendFiltro } = props;
  const [classes, setclasses] = useState("dropdown");
  const [form, setForm] = useState({
    CURP: "",
    Nombre: "",
    Materno: "",
    Paterno: "",
    FolioSolicitud: "",
    Validado: false,
    ExpedienteI: false,
    ExpedienteC: false,
  });

  const handleClick = () => {
    if (classes === "dropdown") {
      setclasses("dropdown show");
    } else {
      setclasses("dropdown");
    }
  };

  const setFilter = (event) => {
    event.preventDefault();
    let filter = [];
    if (form.CURP.length > 0) filter.push({ id: ".CURP", value: form.CURP });
    if (form.Nombre.length > 0)
      filter.push({ id: ".Nombre", value: form.Nombre });
    if (form.Paterno.length > 0)
      filter.push({ id: ".Paterno", value: form.Paterno });
    if (form.Materno.length > 0)
      filter.push({ id: ".Materno", value: form.Materno });
    if (form.FolioSolicitud.length > 0)
      filter.push({ id: ".id", value: form.FolioSolicitud });
    if (form.Validado) filter.push({ id: ".Validado", value: 0 });
    if (form.ExpedienteI) filter.push({ id: ".ExpedienteCompleto", value: 0 });
    if (form.ExpedienteC) filter.push({ id: ".ExpedienteCompleto", value: 1 });
    let datos_ = {};
    datos_ = {
      page: 0,
      tipo: "or",
      pageSize: 10,
      filtered: filter,
    };
    sendFiltro(datos_);
  };

  return (
    <div className="fixed-plugin">
      <Form onSubmit={setFilter}>
        <div className={classes}>
          <div onClick={handleClick}>
            <i className="fa fa-cog fa-2x" />
          </div>
          <ul className="dropdown-menu show ul">
            <li className="adjustments-line">Folio Solicitud:</li>
            <Input
              type="text"
              name="FolioSolicitud"
              id="FolioSolicitud"
              placeholder="Folio Solicitud"
              value={form.FolioSolicitud}
              onChange={(e) =>
                setForm({
                  ...form,
                  FolioSolicitud: e.target.value.toUpperCase(),
                })
              }
              className="form-control"
            />
            <li className="adjustments-line">SOLICITANTE:</li>
            <Input
              id="nombre"
              type="text"
              placeholder="Nombre"
              value={form.Nombre}
              onChange={(event) =>
                setForm({
                  ...form,
                  Nombre: event.target.value.toUpperCase(),
                })
              }
            />
            <Input
              id="paterno"
              type="text"
              placeholder="Apellido paterno"
              value={form.Paterno}
              onChange={(event) =>
                setForm({
                  ...form,
                  Paterno: event.target.value.toUpperCase(),
                })
              }
            />
            <Input
              id="materno"
              type="text"
              placeholder="Apellido materno"
              value={form.Materno}
              onChange={(event) =>
                setForm({
                  ...form,
                  Materno: event.target.value.toUpperCase(),
                })
              }
            />
            <Input
              type="text"
              name="CURP"
              placeholder="CURP"
              value={form.CURP}
              style={{ textTransform: "uppercase" }}
              onChange={(e) =>
                setForm({
                  ...form,
                  CURP: e.target.value.toUpperCase(),
                })
              }
            />
            <li className="adjustments-line">
              <Label
                style={{
                  display: "block",
                  overflow: "hidden",
                  float: "left",
                }}
              >
                {"Expediente Incompleto"}
              </Label>{" "}
              <Input
                style={{
                  verticalAlign: "middle",
                  float: "right",
                  marginLeft: "5pt",
                }}
                type="checkbox"
                onChange={(e) =>
                  setForm({
                    ...form,
                    ExpedienteI: !form.ExpedienteI,
                  })
                }
              />
            </li>
            <li className="adjustments-line">
              <Label
                style={{
                  display: "block",
                  overflow: "hidden",
                  float: "left",
                }}
              >
                {"Expediente Completo"}
              </Label>{" "}
              <Input
                style={{
                  verticalAlign: "middle",
                  float: "right",
                  marginLeft: "5pt",
                }}
                type="checkbox"
                onChange={(e) =>
                  setForm({
                    ...form,
                    ExpedienteC: !form.ExpedienteC,
                  })
                }
              />
            </li>
            <li className="adjustments-line">
              <Label
                style={{
                  display: "block",
                  overflow: "hidden",
                  float: "left",
                }}
              >
                {"Pendientes de Validación "}
              </Label>{" "}
              <Input
                style={{
                  verticalAlign: "middle",
                  float: "right",
                  marginLeft: "5pt",
                }}
                type="checkbox"
                onChange={(e) =>
                  setForm({
                    ...form,
                    Validado: !form.Validado,
                  })
                }
              />
            </li>
            <li className="button-container">
              <span>
                <Button color="info" block className="btn-round" type="submit">
                  Filtrar
                </Button>
              </span>
            </li>

            <li className="li"></li>
          </ul>
        </div>
      </Form>
    </div>
  );
}
export default PluginFilterListadoExpedientes;
