import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  FormGroup,
  Row,
  Col,
  Button,
  Spinner,
  Input,
} from "reactstrap";
import Select from "react-select";
import ReactTable from "react-table";
import { CircleSpinner } from "react-spinners-kit";
import EjecutarApi from "../Consultas/Consultas";
import Notifications from "react-notify-toast";
import swal from "sweetalert";
import { getVars } from "../GlobalStorage";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Sidebar from "../components/Sidebar";
import Pagination from "../components/pagination";
import { numberWithCommas } from "../utils/funciones";
import { URL_GENERAL } from "../data/consts";

const GruposAvances = (props) => {
  const [state, setState] = useState({
    Restan: { value: null, label: "Cualquiera" },
  });
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [dataFilter, setDataFilter] = useState([]);
  const [loading, setLoading] = useState(false);
  const [flagDownload, setFlagDownload] = useState(true);
  const [loadingCatalogs, setLoadingCatalogs] = useState(true);
  const [catalogs, setCatalogs] = useState([]);
  const [regiones, setRegiones] = useState([]);
  const [remesa, setRemesa] = useState("");
  const [region, setRegion] = useState(0);
  const [pageOptions, setPage] = useState({
    page: 0,
    pageSize: 50,
  });

  useEffect(() => {
    const token = getVars("Token");
    EjecutarApi({ ejercicio: 2023 }, "vales/getRemesasAll", token.token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.success && res.results) {
          let cat = res.data;
          setCatalogs(cat);
        } else {
          swal({
            title: "Ha ocurrido un error",
            text: res.message,
            icon: "warning",
          });
        }
        setLoadingCatalogs(false);
      })
      .catch((error) => {
        setLoadingCatalogs(false);
        console.log("=Error[getRemesas]: ", error);
      });
  }, []);

  useEffect(() => {
    const token = getVars("Token");
    EjecutarApi({ ejercicio: 11 }, "getRegionesMenu", token.token, "GET")
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.success && res.results) {
          let cat = res.data;
          setRegiones(cat);
        } else {
          swal({
            title: "Ha ocurrido un error",
            text: res.message,
            icon: "warning",
          });
        }
        setLoadingCatalogs(false);
      })
      .catch((error) => {
        setLoadingCatalogs(false);
        console.log("=Error[getRemesas]: ", error);
      });
  }, []);

  useEffect(() => {
    if (remesa.length === 0 && region > 0) {
      swal({
        title: "¡Atención!",
        icon: "warning",
        text: "Debe seleccionar la remesa a consultar",
      });
      setRegion(0);
      setLoading(false);
      return;
    }
    setLoading(true);
    const token = getVars("Token");

    let ParametrosVale = {
      remesa: remesa,
      region: region,
      page: 0,
      pageSize: 50,
    };

    EjecutarApi(ParametrosVale, "vales/getAvancesGrupos", token.token)
      .then((res) => {
        if (res.results) {
          setTotal(res.total);
          const itemsRemesa = res.data.map((prop, key) => {
            return {
              key: key + 1,
              Remesa: prop.RemesaSistema,
              Region: prop.Region,
              Municipio: prop.Municipio,
              CveInterventor: prop.CveInterventor,
              Localidad: prop.Localidad,
              ResponsableEntrega: prop.ResponsableEntrega,
              TotalAprobados: prop.TotalAprobados,
              Avance: prop.Avance,
              Restan: parseInt(prop.TotalAprobados) - parseInt(prop.Avance),
              data: prop,
            };
          });
          setData(itemsRemesa);
          setDataFilter(itemsRemesa);
          setLoading(false);
          if (res.total > 0) {
            setFlagDownload(false);
          }
        }
      })
      .catch((e) => {
        console.log("=Error[getAvanceRemesas]: ", e);
        setLoading(false);
      });
  }, [region]);

  const handleMiniClick = () => {
    document.body.classList.toggle("sidebar-mini");
  };

  const changePendientes = (Restan) => {
    setState({
      ...state,
      Restan,
    });
    if (Restan.value !== null) {
      if (Restan.value === 1)
        setDataFilter(data.filter((item) => item.Restan > 0));
      else if (Restan.value === 2)
        setDataFilter(data.filter((item) => item.Restan == 0));
    }
  };

  const btnExportar = () => {
    const token = getVars("Token");
    var url = new URL(
      URL_GENERAL +
        `vales/getAvancesGruposReporte?token=${token.token}&remesa=${remesa}&region=${region}`
    );
    window.open(url, "_blank");
  };

  return (
    <>
      <div className="wrapper">
        <Sidebar {...props} bgColor="black" activeColor="info" />
        <div className="main-panel">
          <Navbar {...props} handleMiniClick={handleMiniClick} />
          <Notifications />
          <div className="content">
            <Row>
              <Col md="12">
                <Card>
                  <CardHeader>
                    <FormGroup>
                      <Row>
                        <Col sm="3">
                          Restan:
                          {/* <span className="text-danger">*</span> */}
                          <Select
                            className="react-select primary"
                            classNamePrefix="react-select"
                            name="ciaCelular"
                            defaultValue={{
                              value: "",
                              label: "Cualquiera",
                            }}
                            onChange={(event) => changePendientes(event)}
                            options={[
                              { value: null, label: "Cualquiera" },
                              { value: 1, label: "Incompleto" },
                              { value: 2, label: "Completado" },
                            ]}
                            placeholder="con pendientes"
                          />
                        </Col>
                        <Col sm="3">
                          Remesa:
                          <span className="text-danger">*</span>
                          {loadingCatalogs && <Spinner size="sm" />}
                          <Input
                            name="Remesa"
                            type="select"
                            disabled={loadingCatalogs}
                            value={remesa}
                            onChange={(e) => {
                              setRemesa(e.target.value);
                              setFlagDownload(true);
                            }}
                          >
                            <option value={""}></option>
                            {catalogs.map((element) => (
                              <option
                                key={element.RemesaSistema}
                                value={element.RemesaSistema}
                              >
                                {element.RemesaSistema}
                              </option>
                            ))}
                          </Input>
                        </Col>
                        <Col sm="3">
                          Región:
                          <span className="text-danger">*</span>
                          {loadingCatalogs && <Spinner size="sm" />}
                          <Input
                            name="Remesa"
                            type="select"
                            disabled={loadingCatalogs}
                            value={region}
                            onChange={(e) => {
                              setRegion(e.target.value);
                              setFlagDownload(true);
                            }}
                          >
                            <option value={""}></option>
                            {regiones.map((element) => (
                              <option key={element.value} value={element.value}>
                                {element.label}
                              </option>
                            ))}
                          </Input>
                        </Col>
                        <Col sm="3">
                          <Button
                            color="success"
                            disabled={flagDownload}
                            onClick={() => {
                              btnExportar();
                            }}
                          >
                            Exportar Avance
                          </Button>
                        </Col>
                      </Row>
                    </FormGroup>
                  </CardHeader>

                  <CardBody>
                    {loading && (
                      <CircleSpinner
                        className="text-center"
                        size={30}
                        color="#686769"
                        loading={loading}
                      />
                    )}
                    <ReactTable
                      size="sm"
                      data={dataFilter}
                      noDataText="No Existen Datos a Mostrar!"
                      //filerable
                      manual
                      columns={[
                        {
                          Header: "Remesa",
                          minWidth: 50,
                          id: "Remesa",
                          accessor: (d) => d.Remesa,
                          filterAll: false,
                          headerStyle: { fontSize: "12px" },
                        },
                        {
                          Header: "Region",
                          minWidth: 50,
                          id: "Region",
                          accessor: (d) => d.Region,
                          filterAll: false,
                          headerStyle: { fontSize: "12px" },
                        },

                        {
                          Header: "Municipio",
                          minWidth: 160,
                          id: "Municipio",
                          accessor: (d) => d.Municipio,
                          filterAll: false,
                          headerStyle: { fontSize: "12px" },
                        },
                        {
                          Header: "CveInterventor",
                          minWidth: 160,
                          id: "CveInterventor",
                          accessor: (d) => d.CveInterventor,
                          filterAll: false,
                          headerStyle: { fontSize: "12px" },
                        },
                        {
                          Header: "Localidad",
                          minWidth: 160,
                          id: "Localidad",
                          accessor: (d) => d.Localidad,
                          filterAll: false,
                          headerStyle: { fontSize: "12px" },
                        },
                        {
                          Header: "ResponsableEntrega",
                          minWidth: 180,
                          id: "ResponsableEntrega",
                          accessor: (d) => d.ResponsableEntrega,
                          filterAll: false,
                          headerStyle: { fontSize: "12px" },
                        },
                        {
                          Header: "TotalAprobados",
                          minWidth: 50,
                          id: "TotalAprobados",
                          accessor: (d) => d.TotalAprobados,
                          filterAll: false,
                          headerStyle: { fontSize: "12px" },
                          style: { textAlign: "right" },
                          Footer: (
                            <span>
                              {numberWithCommas(
                                data
                                  .map((d) => d.TotalAprobados)
                                  .reduce((a, b) => a + b, 0)
                              )}
                            </span>
                          ),
                        },
                        {
                          Header: "Avance",
                          minWidth: 50,
                          id: "Avance",
                          accessor: (d) => d.Avance,
                          filterAll: false,
                          headerStyle: { fontSize: "12px" },
                          style: { textAlign: "right" },
                          Footer: (
                            <span>
                              {numberWithCommas(
                                data
                                  .map((d) => d.Avance)
                                  .reduce((a, b) => a + b, 0)
                              )}
                            </span>
                          ),
                        },
                        {
                          Header: "Restan",
                          minWidth: 50,
                          id: "Restan",
                          accessor: (d) => d.Restan,
                          filterAll: false,
                          headerStyle: { fontSize: "12px" },
                          style: { textAlign: "right" },
                          Footer: (
                            <span>
                              {numberWithCommas(
                                data
                                  .map((d) => d.Restan)
                                  .reduce((a, b) => a + b, 0)
                              )}
                            </span>
                          ),
                        },
                      ]}
                      className="-striped -highlight primary-pagination"
                      loading={loading}
                      showPagination={true}
                      showPaginationBottom={true}
                      showTotalPages={true}
                      showPageJump={false}
                      canNextFromData={true}
                      defaultPageSize={pageOptions.pageSize}
                      total={total}
                      page={pageOptions.page}
                      pages={Math.floor(total / pageOptions.pageSize)}
                      previousText="Anterior"
                      nextText="Siguiente"
                      ofText="de"
                      pageSizeOptions={[50, 100, 150, , 200, 500]}
                      PaginationComponent={Pagination}
                      onFetchData={(stateComp, pageSize, page, instance) => {
                        const token = getVars("Token");
                        const ParametrosVale = {
                          remesa: remesa,
                          region: region,
                          page: stateComp.page,
                          pageSize: stateComp.pageSize,
                        };
                        setLoading(true);
                        EjecutarApi(
                          ParametrosVale,
                          "vales/getAvancesGrupos",
                          token.token
                        )
                          .then((res) => {
                            if (res.results) {
                              setTotal(res.total);
                              setPage({
                                page: page,
                                pageSize: pageSize,
                              });
                              setLoading(false);
                              const itemsRemesa = res.data.map((prop, key) => {
                                return {
                                  key: key + 1,
                                  Remesa: prop.RemesaSistema,
                                  Region: prop.Region,
                                  Municipio: prop.Municipio,
                                  CveInterventor: prop.CveInterventor,
                                  Localidad: prop.Localidad,
                                  ResponsableEntrega: prop.ResponsableEntrega,
                                  TotalAprobados: prop.TotalAprobados,
                                  Avance: prop.Avance,
                                  Restan:
                                    parseInt(prop.TotalAprobados) -
                                    parseInt(prop.Avance),
                                  data: prop,
                                };
                              });
                              setData(itemsRemesa);
                              setDataFilter(itemsRemesa);
                            }
                          })
                          .catch((e) => {
                            setLoading(false);
                            swal({
                              title: "¡Atención!",
                              icon: "warning",
                              text: e.message,
                            });
                          });
                        setLoading(false);
                      }}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>

          <Footer fluid />
        </div>
      </div>
    </>
  );
};
export default GruposAvances;
