import React, { useState } from "react";
import { Button, Input, Form, Label } from "reactstrap";
import "./styles.css";

function PluginFilterUsers(props) {
  const { sendFiltro, catRegiones, catRoles } = props;
  const [classes, setclasses] = useState("dropdown");
  const [form, setForm] = useState({
    Nombre: "",
    Materno: "",
    Paterno: "",
    Region: "",
    idTipoUser: "",
    Usuario: "",
    Celular: "",
    Activos: false,
    Inactivos: false,
  });

  const handleClick = () => {
    if (classes === "dropdown") {
      setclasses("dropdown show");
    } else {
      setclasses("dropdown");
    }
  };

  const setFilter = (event) => {
    event.preventDefault();
    let filter = [];
    if (form.Nombre.trim().length > 0)
      filter.push({ id: ".Nombre", value: form.Nombre.trim() });
    if (form.Paterno.trim().length > 0)
      filter.push({ id: ".Paterno", value: form.Paterno.trim() });
    if (form.Materno.trim().length > 0)
      filter.push({ id: ".Materno", value: form.Materno.trim() });
    if (form.Usuario.trim().length > 0)
      filter.push({ id: ".email", value: form.Usuario.trim() });
    if (form.Celular.trim().length > 0)
      filter.push({ id: ".TelCelular", value: form.Celular.trim() });
    if (form.Region > 0) filter.push({ id: ".Region", value: form.Region });
    if (form.idTipoUser > 0)
      filter.push({ id: ".idTipoUser", value: form.idTipoUser });
    if (form.Activos) filter.push({ id: ".idStatus", value: 1 });
    if (form.Inactivos) filter.push({ id: ".idStatus", value: 0 });

    let datos_ = {};
    datos_ = {
      page: 0,
      tipo: "or",
      pageSize: 10,
      filtered: filter,
    };
    sendFiltro(datos_);
  };

  return (
    <div className="fixed-plugin">
      <Form onSubmit={setFilter}>
        <div className={classes}>
          <div onClick={handleClick}>
            <i className="fa fa-cog fa-2x" />
          </div>
          <ul className="dropdown-menu show ul">
            <li className="adjustments-line">Filtros:</li>
            <Input
              id="nombre"
              type="text"
              placeholder="Nombre"
              value={form.Nombre}
              onChange={(event) =>
                setForm({
                  ...form,
                  Nombre: event.target.value.toUpperCase().replace(/\d/, ""),
                })
              }
            />
            <Input
              id="paterno"
              type="text"
              placeholder="Apellido paterno"
              value={form.Paterno}
              onChange={(event) =>
                setForm({
                  ...form,
                  Paterno: event.target.value.toUpperCase().replace(/\d/, ""),
                })
              }
            />
            <Input
              id="materno"
              type="text"
              placeholder="Apellido materno"
              value={form.Materno}
              onChange={(event) =>
                setForm({
                  ...form,
                  Materno: event.target.value.toUpperCase().replace(/\d/, ""),
                })
              }
            />
            <Input
              id="Usuario"
              type="text"
              name="Usuario"
              placeholder="Usuario"
              value={form.Usuario}
              onChange={(e) =>
                setForm({
                  ...form,
                  Usuario: e.target.value.toUpperCase().replace(/\d/, ""),
                })
              }
            />
            <Input
              id="Celular"
              type="text"
              name="Celular"
              placeholder="Celular"
              value={form.Celular}
              onChange={(e) =>
                setForm({
                  ...form,
                  Celular: e.target.value.replace(/\D/g, ""),
                })
              }
            />
            <li className="adjustments-line">
              <Label>Región:</Label>
            </li>

            <Input
              name="Region"
              type="select"
              value={form.Region}
              onChange={(e) =>
                setForm({
                  ...form,
                  Region: e.target.value,
                })
              }
            >
              <option value={""}>Seleccione</option>
              {catRegiones.map((element) => (
                <option key={element.value} value={element.value}>
                  {element.label}
                </option>
              ))}
            </Input>

            <li className="adjustments-line">
              <Label>Tipo usuario:</Label>
            </li>

            <Input
              name="idTipoUser"
              type="select"
              value={form.idTipoUser}
              onChange={(e) =>
                setForm({
                  ...form,
                  idTipoUser: e.target.value,
                })
              }
            >
              <option value={""}>Seleccione</option>
              {catRoles.map((element) => (
                <option key={element.value} value={element.value}>
                  {element.label}
                </option>
              ))}
            </Input>

            <li className="adjustments-line">
              <Label
                style={{
                  display: "block",
                  overflow: "hidden",
                  float: "left",
                }}
              >
                {"Activos  "}
              </Label>{" "}
              <Input
                style={{
                  verticalAlign: "middle",
                  float: "right",
                  marginLeft: "5pt",
                }}
                type="checkbox"
                onChange={(e) =>
                  setForm({
                    ...form,
                    Activos: !form.Activos,
                  })
                }
              />
            </li>
            <li className="adjustments-line">
              <Label
                style={{
                  display: "block",
                  overflow: "hidden",
                  float: "left",
                }}
              >
                {"Inactivos  "}
              </Label>{" "}
              <Input
                style={{
                  verticalAlign: "middle",
                  float: "right",
                  marginLeft: "5pt",
                }}
                type="checkbox"
                onChange={(e) =>
                  setForm({
                    ...form,
                    Inactivos: !form.Inactivos,
                  })
                }
              />
            </li>
            <li className="button-container">
              <span>
                {/* {!isLoading && ( */}
                <Button color="info" block className="btn-round" type="submit">
                  Filtrar
                </Button>
              </span>
            </li>

            <li className="li"></li>
          </ul>
        </div>
      </Form>
    </div>
  );
}
export default PluginFilterUsers;
