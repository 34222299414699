import React, {useEffect,useState} from 'react';
import { Row,Col, Modal,ModalHeader,ModalBody} from 'reactstrap';

import { getVars } from '../../GlobalStorage';
import { notify } from 'react-notify-toast';
import EjecutarApi from '../../Consultas/Consultas';
import InfiniteScroll from "react-infinite-scroll-component";
import { CircleSpinner } from 'react-spinners-kit';

const ModalInvitado = ({Datos,onHide}) => {
    const token = getVars('Token');
    const [dataUsers, setDataUsers] = useState({ data: [], total: 0 });
    const [dataSearch, setDataSearch] = useState({ page: 0, pageSize: 20 });
    const [hasMoreUsers, setHasMoreUsers] = useState(true);
    const [loading, setloading] = useState(false);
    const [mensaje,setMensaje] = useState('Cargando....');

    useEffect(() => {
        llenadoInfiniteScrool();
    }, []);
    const llenadoInfiniteScrool=()=>{
        setloading(true)
        EjecutarApi(Datos, 'getListadoInvitados', token.token)
            .then(function (res) {
                return res;
            })
            .then(items => {
                console.log("===> Data getListadoInvitados: ", items);
                if (items.data.length>0) {
                    setMensaje('Cargando...');
                    setloading(false)
                    setDataSearch({ ...dataSearch, page: dataSearch.page + 1 });
                    setDataUsers({
                        data: items.data,//dataUsers.data.concat(items.data),
                        total: items.total
                    });
                }
                else{
                    setloading(false)
                    setMensaje('No se encontraron resultados...');
                }
            })
            .catch((e) => {
                console.log('=Error[getListadoInvitados]: ', e);
                setloading(false)
                notify.show(
                    `Error al obtener Vales: ${e.errors}`,
                    'custom',
                    5000,
                    {
                        background: '#0857B6',
                        fontSize: 14,
                        text: '#FFFFFF',
                    }
                );

                //setloadingGrupo(false);
            });
        
    }
    const fetchMoreUsers = () => {
        if (dataSearch.page * dataSearch.pageSize >= dataUsers.total) {
            console.log("=>PorAutorizar Llego a su final: ",
                dataSearch.page * dataSearch.pageSize
            );

            setHasMoreUsers(false);
            return;
        }

        setloading(true);

        const data = {
            page: dataSearch.page + 1,
            tipo: "and",
            pageSize: dataSearch.pageSize,
            sorted: [],
            filtered:Datos.filtered
        };


        console.log("= getValesIN: ", data);

        EjecutarApi(Datos, 'getListadoInvitados', token.token)
            .then(function (res) {
                return res;
            })
            .then(items => {
                console.log("===> Data getListadoInvitados: ", items);
                if (items.results) {
                    setloading(false)
                    setDataUsers({
                        data: items.data,//dataUsers.data.concat(items.data),
                        total: items.total
                    });
                }
            })
            .catch((e) => {
                console.log('=Error[getListadoInvitados]: ', e);
                setloading(false)
                notify.show(
                    `Error al obtener Vales: ${e.errors}`,
                    'custom',
                    5000,
                    {
                        background: '#0857B6',
                        fontSize: 14,
                        text: '#FFFFFF',
                    }
                );
            });
    };
    return (
      <Modal
        isOpen={true}
        toggle={onHide}
        size="lg"
        aria-labelledby="contained-modal-title-vtop"
        centered
      >
        <ModalHeader toggle={onHide} >
          Datos del Invitado
        </ModalHeader>
        <ModalBody>
            <Col sm="12">
                {loading && (
                    <CircleSpinner
                        className="text-center"
                        size={30}
                        color="#686769"
                        loading={loading}
                    />
                )}
                <Row>
                    <Col sm="12">
                        <InfiniteScroll
                                        dataLength={dataUsers.data.length}
                                        next={fetchMoreUsers}
                                        hasMore={hasMoreUsers}
                                        loader={<h4>{mensaje}</h4>}
                                        height={600}
                                        endMessage={
                                            <p style={{ textAlign: "center" }}>
                                                <b>Listo! Esos son todos!</b>
                                            </p>
                                        }
                                        >
                                        {dataUsers.data.map((item, index) =>
                                            <div key={index}>
                                                <Row>
                                                    <Col className="text-muted">Nombre:<strong>{item.NombreCompleto}</strong></Col>
                                                </Row>
                                                <Row>
                                                    <Col className="text-muted">CURP:<strong>{item.CURP}</strong></Col>
                                                </Row>
                                                <Row>
                                                    <Col className="text-muted">Telefono:<strong>{item.Celular}</strong></Col>
                                                </Row>
                                                <Row>
                                                    <Col className="text-muted">Municipio: <strong>{item.Municipio}</strong></Col>
                                                </Row>
                                                <Row>
                                                    <Col className="text-muted">Responsable: <strong>{item.Responsable}</strong></Col>
                                                </Row>
                                                <Row>
                                                    <Col className="text-muted">Folio: <strong>{item.Folio}</strong></Col>
                                                </Row>
                                                <Row>
                                                    <Col className="text-muted">Código de Barra: <strong>{item.CodigoBarras}</strong></Col>
                                                </Row>
                                                <Row>
                                                    <Col className="text-muted">Número de Invitado: <strong>{item.NumeroInvitado}</strong></Col>
                                                </Row>
                                                
                                                <hr />
                                            </div>

                                        )}
                                    </InfiniteScroll>
                    </Col>
                </Row>
            </Col>   
        </ModalBody>
      </Modal>
    );
};
export default ModalInvitado;