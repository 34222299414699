import "./styles.css";
import moment from "moment";
import Select from "react-select";
import { getVars } from "../GlobalStorage";
import { notify } from "react-notify-toast";
import EjecutarApi from "../Consultas/Consultas";
import React, { useState, useEffect } from "react";
import { Button, Input, Form, Label } from "reactstrap";

function PluginFilterSolicitudVales2022(props) {
  const { sendFiltro } = props;
  const ejercicio = props.Ejercicio ? props.Ejercicio : 2022;
  const token = getVars("Token");
  const [classes, setclasses] = useState("dropdown");
  const [filterRegion, setfilterRegion] = useState([]);
  const [MultiValueRegion, setMultiValueRegion] = useState([]);

  const [MultiValueMuninicpio, setMultiValueMunicipio] = useState([]);
  const [MultiValueMuninicpio2, setMultiValueMunicipio2] = useState([]);
  const [MultiValueArticulador, setMultiValueArticulador] = useState([]);
  const [MultiValueArticulador2, setMultiValueArticulador2] = useState([]);
  const [form, setForm] = useState({
    CURP: "",
    Region: "",
    Nombre: "",
    Materno: "",
    Paterno: "",
    Enviadas: "",
    Municipio: "",
    FolioSolicitud: "",
    FolioVentanilla: "",
    ejercicio: "",
    idIncidencia: false,
    Cedula: false,
    isEntregado: false,
    noEntregado: false,
    ListosParaEnviar: false,
  });

  const handleClick = () => {
    if (classes === "dropdown") {
      setclasses("dropdown show");
    } else {
      setclasses("dropdown");
    }
  };

  useEffect(() => {
    EjecutarApi({}, "vales/getMunicipiosVales", token.token, "GET")
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.results) {
          var region = [];
          setMultiValueMunicipio(
            res.data.map((municipio, i) => {
              if (region.indexOf(municipio["SubRegion"]) === -1) {
                region.push(municipio["SubRegion"]);
              }
              return {
                value: municipio["id"],
                label: municipio["Nombre"],
              };
            })
          );
          var regio_n = region.sort((a, b) => a - b);
          setfilterRegion(
            regio_n.map((region_) => {
              return { value: region_, label: region_ };
            })
          );
        }
      })
      .catch((e) => {
        notify.show(`Error en la busqueda! ${e.message}`, "custom", 5000, {
          background: "#d61818",
          text: "#FFFFFF",
        });
      });

    let datoArticulador = {};
    datoArticulador = {
      page: 0,
      tipo: "and",
      pageSize: 1000,
      sorted: [{ id: "Nombre", desc: false }],
      filtered: [],
      ejercicio: ejercicio,
    };

    EjecutarApi(datoArticulador, "getRemesasVales", token.token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.results) {
          setMultiValueArticulador(
            res.data.map((data, i) => {
              return {
                value: data["RemesaSistema"],
                label: data["RemesaSistema"],
              };
            })
          );
        }
      })
      .catch((e) => {
        notify.show(`Error en la busqueda! ${e.message}`, "custom", 5000, {
          background: "#d61818",
          text: "#FFFFFF",
        });
      });
  }, []);

  const handleMultiRegion = (option) => {
    setMultiValueRegion(option);
  };

  const setFilter = (event) => {
    event.preventDefault();
    let region = [];
    let articulador = [];

    if (MultiValueRegion) {
      if (MultiValueRegion.length > 0) {
        MultiValueRegion.map((r) => {
          region.push(r.value);
        });
      }
    }
    let filter = [];
    if (form.CURP.length > 0) filter.push({ id: ".CURP", value: form.CURP });
    if (form.Nombre.length > 0)
      filter.push({ id: ".Nombre", value: form.Nombre });
    if (form.Paterno.length > 0)
      filter.push({ id: ".Paterno", value: form.Paterno });
    if (form.Materno.length > 0)
      filter.push({ id: ".Materno", value: form.Materno });
    if (form.FolioSolicitud.length > 0)
      filter.push({ id: ".id", value: form.FolioSolicitud });
    if (form.FolioVentanilla.length > 0)
      filter.push({ id: ".Folio", value: form.FolioVentanilla });
    if (form.isEntregado) filter.push({ id: ".isEntregado", value: 1 });
    if (form.noEntregado) filter.push({ id: ".isEntregado", value: 0 });
    if (form.idIncidencia) filter.push({ id: ".Devuelto", value: 1 });
    if (articulador.length > 0)
      filter.push({ id: "articulador", value: articulador });
    if (MultiValueMuninicpio2.length > 0)
      filter.push({ id: ".idMunicipio", value: MultiValueMuninicpio2 });
    if (MultiValueArticulador2.length > 0)
      filter.push({ id: ".Remesa", value: MultiValueArticulador2 });
    if (region.length > 0) filter.push({ id: "region", value: region });
    if (form.ejercicio > 0) {
      filter.push({ id: ".Ejercicio", value: form.ejercicio });
    } else {
      const newFilter = filter.filter((item) => item.id !== ".Ejercicio");
      filter = newFilter;
    }

    let datos_ = {};
    datos_ = {
      page: 0,
      tipo: "or",
      pageSize: 10,
      filtered: filter,
    };
    sendFiltro(datos_);
  };

  return (
    <div className="fixed-plugin">
      <Form onSubmit={setFilter}>
        <div className={classes}>
          <div onClick={handleClick}>
            <i className="fa fa-cog fa-2x" />
          </div>
          <ul className="dropdown-menu show ul">
            <li className="adjustments-line">Folio Solicitud:</li>
            <Input
              type="text"
              name="FolioSolicitud"
              id="FolioSolicitud"
              placeholder="Folio Solicitud"
              value={form.FolioSolicitud}
              onChange={(e) =>
                setForm({
                  ...form,
                  FolioSolicitud: e.target.value.toUpperCase(),
                })
              }
              className="form-control"
            />
            {ejercicio > 2023 && (
              <>
                <li className="adjustments-line">Folio Ventanilla:</li>
                <Input
                  type="text"
                  name="FolioVentanilla"
                  id="FolioVentanilla"
                  placeholder="Folio Ventanilla"
                  value={form.FolioVentanilla}
                  onChange={(e) =>
                    setForm({
                      ...form,
                      FolioVentanilla: e.target.value.toUpperCase(),
                    })
                  }
                  className="form-control"
                />
              </>
            )}
            <li className="adjustments-line">SOLICITANTE:</li>
            <Input
              id="nombre"
              type="text"
              placeholder="Nombre"
              value={form.Nombre}
              onChange={(event) =>
                setForm({
                  ...form,
                  Nombre: event.target.value.toUpperCase(),
                })
              }
            />
            <Input
              id="paterno"
              type="text"
              placeholder="Apellido paterno"
              value={form.Paterno}
              onChange={(event) =>
                setForm({
                  ...form,
                  Paterno: event.target.value.toUpperCase(),
                })
              }
            />
            <Input
              id="materno"
              type="text"
              placeholder="Apellido materno"
              value={form.Materno}
              onChange={(event) =>
                setForm({
                  ...form,
                  Materno: event.target.value.toUpperCase(),
                })
              }
            />
            <Input
              type="text"
              name="CURP"
              placeholder="CURP"
              value={form.CURP}
              style={{ textTransform: "uppercase" }}
              onChange={(e) =>
                setForm({
                  ...form,
                  CURP: e.target.value.toUpperCase(),
                })
              }
            />

            <li className="adjustments-line">
              <Label>Ejercicio:</Label>
            </li>
            <li className="li">
              <Input
                type="select"
                name="ejercicio"
                className="SelectSize"
                id="ejercicio"
                value={form.ejercicio}
                onChange={(e) => {
                  setForm({
                    ...form,
                    ejercicio: e.target.value,
                  });
                }}
              >
                <option value="">Seleccione</option>
                <option value="2023">2023</option>
                <option value="2024">2024</option>
              </Input>
            </li>
            <li className="adjustments-line">
              <Label>Remesa:</Label>
            </li>
            <li className="li">
              <Select
                className="SelectSize"
                name="remesa"
                placeholder="Remesa"
                options={MultiValueArticulador}
                onChange={(event, i) => {
                  if (event) {
                    setMultiValueArticulador2(
                      event.map((dato) => {
                        return "'" + dato.value + "'";
                      })
                    );
                  } else {
                    setMultiValueArticulador2({
                      MultiValueArticulador2: MultiValueArticulador2.map(
                        (dato) => {
                          if (i.removedValue.value !== dato) {
                            return "'" + dato.value + "'";
                          }
                        }
                      ),
                    });
                  }
                }}
                isMulti
              />
            </li>
            <li className="adjustments-line">
              <Label>Región:</Label>
            </li>
            <li className="li">
              <Select
                name="region"
                className="SelectSize"
                placeholder="Región"
                value={MultiValueRegion}
                options={filterRegion}
                onChange={handleMultiRegion}
                isMulti
              />
            </li>
            <li className="adjustments-line">
              <Label>Municipio:</Label>
            </li>
            <li className="li">
              <Select
                className="SelectSize"
                name="Municipio"
                placeholder="Municipio"
                options={MultiValueMuninicpio}
                onChange={(event, i) => {
                  console.log(event);
                  if (event) {
                    setMultiValueMunicipio2(
                      event.map((dato) => {
                        return dato.value;
                      })
                    );
                  } else {
                    setMultiValueMunicipio2({
                      MultiValueMuninicpio2: MultiValueMuninicpio2.map(
                        (dato) => {
                          if (i.removedValue.value !== dato) {
                            return dato.value;
                          }
                        }
                      ),
                    });
                  }
                }}
                isMulti
              />
            </li>
            <li className="adjustments-line">
              <Label
                style={{
                  display: "block",
                  overflow: "hidden",
                  float: "left",
                }}
              >
                {"No Entregados  "}
              </Label>{" "}
              <Input
                style={{
                  verticalAlign: "middle",
                  float: "right",
                  marginLeft: "5pt",
                }}
                type="checkbox"
                onChange={(e) =>
                  setForm({
                    ...form,
                    noEntregado: !form.noEntregado,
                  })
                }
              />
            </li>
            <li className="adjustments-line">
              <Label
                style={{
                  display: "block",
                  overflow: "hidden",
                  float: "left",
                }}
              >
                {"Entregados  "}
              </Label>{" "}
              <Input
                style={{
                  verticalAlign: "middle",
                  float: "right",
                  marginLeft: "5pt",
                }}
                type="checkbox"
                onChange={(e) =>
                  setForm({
                    ...form,
                    isEntregado: !form.isEntregado,
                  })
                }
              />
            </li>

            <li className="adjustments-line">
              <Label
                style={{
                  display: "block",
                  overflow: "hidden",
                  float: "left",
                }}
              >
                {"Devueltos  "}
              </Label>{" "}
              <Input
                style={{
                  verticalAlign: "middle",
                  float: "right",
                  marginLeft: "5pt",
                }}
                type="checkbox"
                onChange={(e) =>
                  setForm({
                    ...form,
                    idIncidencia: !form.idIncidencia,
                  })
                }
              />
            </li>

            {/* <li className="adjustments-line" /> */}
            <li className="button-container">
              <span>
                {/* {!isLoading && ( */}
                <Button color="info" block className="btn-round" type="submit">
                  Filtrar
                </Button>
              </span>
            </li>

            <li className="li"></li>
          </ul>
        </div>
      </Form>
    </div>
  );
}
export default PluginFilterSolicitudVales2022;
