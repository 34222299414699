import React, { useState, useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
} from "reactstrap";
import swal from "sweetalert";
import ReactTable from "react-table";
import { CircleSpinner } from "react-spinners-kit";
import EjecutarApi, { uploadFiles } from "../Consultas/Consultas";
import Notifications, { notify } from "react-notify-toast";
import { getVars } from "../GlobalStorage";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Sidebar from "../components/Sidebar";
import Pagination from "../components/pagination";
import FixedPlugin from "../components/PluginFilterSolicitudCedulaYoPuedo";
import { FormatedSolicitudFormYoPuedo } from "../data/interfaces/Cedula";
import { isNullOrUndefined } from "../utils/emptyValidations";
import ListadoArchivos from "../components/Archivos/ListadoArchivos";
import { URL_GENERAL } from "../data/consts";
import SolicitudYoPuedoAnterior from "./SolicitudCedulaYoPuedoAnterior";
import SolicitudYoPuedo from "./SolicitudCedulaYoPuedo";
import YoPuedoCedulaForm from "./YoPuedoCedulaForm";

const YoPuedo = (props) => {
  const [userData, setUserData] = useState();
  const [menu, setMenu] = useState({
    Ver: 0,
    Agregar: 0,
    Editar: 0,
    Eliminar: 0,
    Seguimiento: 0,
    Exportar: 0,
    Imprimir: 0,
    ViewAll: 0,
    idTipoUser: 0,
  });
  const history = useHistory();
  const [total, setTotal] = useState(0);
  const [loadingCedulaList, setLoadingCedulaList] = useState(false);
  const [dataCedulaList, setDataCedulaList] = useState([]);
  const [isLoadingArchivos, setIsLoadingArchivos] = useState(false);
  const [archivos, setArchivos] = useState(false);
  const [clasificacion, setClasificacion] = useState(false);
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [isLoadingCatalogos, setIsLoadingCatalogos] = useState(false);
  const [catalogos, setCatalogos] = useState([]);
  const [selectedRecord, setSelectedRecord] = useState({});
  const [menuLoaded, setMenuLoaded] = useState(false);
  const [TotalSolicitudes, setTotalSolicitudes] = useState(0);
  const [TotalValidadas, setTotalValidadas] = useState(0);
  const [TotalAprobadas, setTotalAprobadas] = useState(0);
  //Filtro
  const [filtro, setFiltro] = useState([]);
  const [pageOptions, setPage] = useState({
    page: 0,
    pageSize: 10,
  });
  const [isSolicitud, setIsSolicitud] = useState(false);
  const [isCedula, setIsCedula] = useState(false);
  const [solicitud, setSolicitud] = useState(null);
  const [cedula, setCedula] = useState(null);
  const [formato, setFormato] = useState(0);

  const columns = [
    {
      Header: "Acciones",
      minWidth: 250,
      id: "Acciones",
      accessor: (d) => d.Acciones,
      className: "text-center",
      filterAll: false,
      style: { fontSize: "10px" },
      headerStyle: { fontSize: "10px" },
    },
    {
      Header: "Folio Yo Puedo",
      minWidth: 120,
      id: "FolioYoPuedo",
      accessor: (d) => d.FolioYoPuedo,
      filterAll: false,
      style: { fontSize: "10px" },
      headerStyle: { fontSize: "10px" },
    },
    {
      Header: "Folio Ventanilla",
      minWidth: 120,
      id: "Folio",
      accessor: (d) => d.Folio,
      filterAll: false,
      style: { fontSize: "10px" },
      headerStyle: { fontSize: "10px" },
    },
    {
      Header: "Folio Solicitud",
      minWidth: 120,
      id: "FolioSolicitud",
      accessor: (d) => d.FolioSolicitud,
      filterAll: false,
      style: { fontSize: "10px" },
      headerStyle: { fontSize: "10px" },
    },
    {
      Header: "Fecha Solicitud",
      minWidth: 120,
      id: "FechaSolicitud",
      accessor: (d) => d.FechaSolicitud,
      filterAll: false,
      style: { fontSize: "10px" },
      headerStyle: { fontSize: "10px" },
    },
    {
      Header: "Nombre Completo",
      minWidth: 200,
      id: "NombreCompleto",
      accessor: (d) => d.NombreCompleto,
      filterAll: false,
      style: { fontSize: "10px" },
      headerStyle: { fontSize: "10px" },
    },
    {
      Header: "Region",
      minWidth: 80,
      id: "RegionM",
      accessor: (d) => d.RegionM,
      filterAll: false,
      style: { fontSize: "10px" },
      headerStyle: { fontSize: "10px" },
    },
    {
      Header: "Municipio",
      minWidth: 80,
      id: "MunicipioVive",
      accessor: (d) => d.MunicipioVive,
      filterAll: false,
      style: { fontSize: "10px" },
      headerStyle: { fontSize: "10px" },
    },
    {
      Header: "Dirección",
      minWidth: 300,
      id: "Direccion",
      accessor: (d) => d.Direccion,
      filterAll: false,
      style: { fontSize: "10px" },
      headerStyle: { fontSize: "10px" },
    },
    {
      Header: "CURP",
      minWidth: 120,
      id: "CURP",
      accessor: (d) => d.CURP,
      filterAll: false,
      style: { fontSize: "10px" },
      headerStyle: { fontSize: "10px" },
    },

    {
      Header: "Celular",
      minWidth: 120,
      id: "Celular",
      accessor: (d) => d.Celular,
      filterAll: false,
      style: { fontSize: "10px" },
      headerStyle: { fontSize: "10px" },
    },
    {
      Header: "CreadoPor",
      minWidth: 120,
      id: "CreadoPor",
      accessor: (d) => d.CreadoPor,
      filterAll: false,
      style: { fontSize: "10px" },
      headerStyle: { fontSize: "10px" },
    },
  ];

  const handleMiniClick = () => {
    document.body.classList.toggle("sidebar-mini");
  };

  useEffect(() => {
    setUserData(getVars("Token"));
  }, []);

  const handleShowFiles = (cedula) => {
    setIsModalOpened(true);
    setIsLoadingArchivos(true);
    setIsLoadingCatalogos(true);
    setSelectedRecord(cedula);
    const token = getVars("Token");
    EjecutarApi({}, `yopuedo/getClasificacionArchivos`, token.token, "GET")
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.results && res.success) {
          setCatalogos(res.data);
        } else {
          setIsModalOpened(false);
          swal({
            title: "¡Atención!",
            text: `${res.errors}`,
            icon: "info",
          });
        }
        setIsLoadingCatalogos(false);
      })
      .catch((error) => {
        setIsLoadingCatalogos(false);
        setIsModalOpened(false);
        swal({
          title: "¡Atención!",
          text: `${error.error}`,
          icon: "info",
        });
      });
    EjecutarApi(
      {},
      `yopuedo/getArchivosByIdY/${cedula.idCedula}`,
      token.token,
      "GET"
    )
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.results && res.success) {
          setArchivos(res.data.Archivos);
          setClasificacion(res.data.ArchivosClasificacion);
        } else {
          setIsModalOpened(false);
          swal({
            title: "¡Atención!",
            text: `${res.errors}`,
            icon: "info",
          });
        }
        setIsLoadingArchivos(false);
      })
      .catch((error) => {
        setIsLoadingArchivos(false);
        setIsModalOpened(false);
        swal({
          title: "¡Atención!",
          text: `${error.error}`,
          icon: "info",
        });
      });
  };

  useEffect(() => {
    if (isNullOrUndefined(userData)) return;
    const menus = userData.menu;
    const currentMenu = menus.find((menu) => menu.idMenu === 17);
    if (isNullOrUndefined(currentMenu) || currentMenu.Ver < 1) {
      swal({
        title: "Atención",
        text: "No tiene permisos para este módulo, contacte al administrador",
        icon: "warning",
      });
      history.push("/not-found");
      return;
    }
    setMenu({
      Ver: currentMenu.Ver,
      Agregar: currentMenu.Agregar,
      Editar: currentMenu.Editar,
      Eliminar: currentMenu.Eliminar,
      Seguimiento: currentMenu.Seguimiento,
      Exportar: currentMenu.Exportar,
      Imprimir: currentMenu.Imprimir,
      ViewAll: currentMenu.ViewAll,
      idTipoUser: userData.user.idTipoUser.id,
    });
    setMenuLoaded(true);
  }, [userData]);

  const fetchData = useCallback(
    ({ pageSize, page, sorted, params }) => {
      const data = {
        page: page > pageOptions.page ? page : pageOptions.page,
        pageSize: pageSize,
        programa: 1,
        filtered: filtro,
      };
      setPage({
        page: page,
        pageSize: pageSize,
      });
      getRegistros(data);
    },
    [menu, filtro]
  );

  const getRegistros = (data) => {
    const token = getVars("Token");
    setLoadingCedulaList(true);
    data.filtered = filtro;
    EjecutarApi(data, "getSolicitudesYoPuedo", token.token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.results && res.success) {
          setTotal(res.total);
          setDataCedulaList(
            res.data.map((item) => {
              if (item.Paterno == null) item.Paterno = "";
              if (item.Materno == null) item.Materno = "";
              if (item.Referencias == null) item.Referencias = "";
              if (item.NoExtVive == null) item.NoExtVive = "";
              return {
                ...item,
                NombreCompleto: `${item.Nombre} ${item.Paterno} ${item.Materno}`,
                Direccion: `${item.CalleVive} ${item.NoExtVive} ${item.ColoniaVive} ${item.CPVive} ${item.MunicipioVive} ${item.EntidadVive}`,
                Acciones: (
                  <>
                    {item.idEstatus != 8 ? (
                      <>
                        <Row>
                          <Col>
                            <Button
                              color="primary"
                              size="sm"
                              style={{ width: "100%" }}
                              onClick={() => sendDataSolicitud(item)}
                            >
                              <span className="btn-label">
                                <i
                                  className={`fa ${
                                    item.idCedula
                                      ? "fa fa-eye fa-xs"
                                      : "fa-pencil-square-o"
                                  } fa-xs`}
                                ></i>
                              </span>
                              Solicitud
                            </Button>
                          </Col>
                          {item.Formato === 1 && (
                            <Col>
                              <Button
                                color="info"
                                size="sm"
                                style={{ width: "100%" }}
                                onClick={() => sendDataCedula(item, undefined)}
                              >
                                <span className="btn-label">
                                  {item.idCedula &&
                                  item.ListaParaEnviar === 1 ? (
                                    <i className="fa fa-eye fa-xs"></i>
                                  ) : item.idCedula &&
                                    item.ListaParaEnviar < 1 ? (
                                    <i className="fa fa-pencil-square-o fa-xs"></i>
                                  ) : (
                                    <i className="fa fa-plus fa-xs"></i>
                                  )}
                                </span>
                                Cedula
                              </Button>
                            </Col>
                          )}
                        </Row>
                        <Row>
                          <Col>
                            {item.idCedula && (
                              <Button
                                color="warning"
                                size="sm"
                                style={{ width: "100%" }}
                                onClick={() => handleShowFiles(item)}
                              >
                                <i className="fa fa-file-archive-o fa-xs"></i>
                                Archivos
                              </Button>
                            )}
                          </Col>

                          <Col>
                            {item.ListaParaEnviarY === 1 &&
                              menu.Imprimir === 1 &&
                              item.Formato === 1 && (
                                <Button
                                  color="success"
                                  size="sm"
                                  style={{ width: "100%" }}
                                  onClick={() => sendIGTO(item.idCedula)}
                                >
                                  <span>
                                    <i className="fa fa-paper-plane fa-xs" />
                                  </span>{" "}
                                  Ventanilla
                                </Button>
                              )}
                          </Col>
                        </Row>
                      </>
                    ) : (
                      <>
                        {item.Formato === 1 ? (
                          <Row>
                            <Col>
                              <Button
                                color="info"
                                size="sm"
                                style={{ width: "100%" }}
                                onClick={() => sendDataSolicitud(item)}
                              >
                                <span>
                                  <i className="fa fa-paper-plane fa-xs" />
                                </span>{" "}
                                Enviada (Ver)
                              </Button>
                            </Col>
                          </Row>
                        ) : (
                          <Row>
                            <Col>
                              <Button
                                color="info"
                                size="sm"
                                style={{ width: "100%" }}
                                onClick={() => sendDataSolicitud(item)}
                              >
                                <span>
                                  <i className="fa fa-paper-plane fa-xs" />
                                </span>{" "}
                                Validada (Ver)
                              </Button>
                            </Col>
                          </Row>
                        )}
                      </>
                    )}
                  </>
                ),
              };
            })
          );
        } else {
          swal({
            title: "¡Atención!",
            text: `${res.errors}`,
            icon: "info",
          });
        }
        setLoadingCedulaList(false);
      })
      .catch((e) => {
        setLoadingCedulaList(false);
        swal({
          title: "¡Atención!",
          text: `${e.message}`,
          icon: "warning",
        });
      });
  };

  const sendDataSolicitud = (item) => {
    setFormato(item ? item.Formato : formato);
    setIsSolicitud(!isSolicitud);
    setCedula(item);
    setSolicitud(item);
  };

  const sendDataCedula = (item, d) => {
    let data = FormatedSolicitudFormYoPuedo(item);
    let { id, idEstatus, ...formatedData } = data;
    formatedData.idSolicitud = item.idSolicitud ? item.idSolicitud : id;
    formatedData.idCedula = item.idCedula ? item.idCedula : undefined;
    setFormato(0);
    setIsSolicitud(false);
    setIsCedula(!isCedula);
    if (item !== undefined) {
      setCedula(formatedData);
    } else {
      setCedula(item);
    }
  };

  const setFilter = (filtered) => {
    setMenuLoaded(false);
    filtered.programa = 2;
    setFiltro(filtered.filtered);
    const token = getVars("Token");
    setLoadingCedulaList(true);
    EjecutarApi(filtered, "getSolicitudesYoPuedo", token.token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.results && res.success) {
          setTotal(res.total);
          setDataCedulaList(
            res.data.map((item) => {
              if (item.Paterno == null) item.Paterno = "";
              if (item.Materno == null) item.Materno = "";
              if (item.Referencias == null) item.Referencias = "";
              if (item.NoExtVive == null) item.NoExtVive = "";
              return {
                ...item,
                NombreCompleto: `${item.Nombre} ${item.Paterno} ${item.Materno}`,
                Direccion: `${item.CalleVive} ${item.NoExtVive} ${item.ColoniaVive} ${item.CPVive} ${item.MunicipioVive} ${item.EntidadVive}`,
                Acciones: (
                  <>
                    {item.idEstatus != 8 ? (
                      <>
                        <Row>
                          <Col>
                            <Button
                              color="primary"
                              size="sm"
                              style={{ width: "100%" }}
                              onClick={() => sendDataSolicitud(item)}
                            >
                              <span className="btn-label">
                                <i
                                  className={`fa ${
                                    item.idCedula
                                      ? "fa fa-eye fa-xs"
                                      : "fa-pencil-square-o"
                                  } fa-xs`}
                                ></i>
                              </span>
                              Solicitud
                            </Button>
                          </Col>
                          {item.Formato === 1 && (
                            <Col>
                              <Button
                                color="info"
                                size="sm"
                                style={{ width: "100%" }}
                                onClick={() => sendDataCedula(item, undefined)}
                              >
                                <span className="btn-label">
                                  {item.idCedula &&
                                  item.ListaParaEnviar === 1 ? (
                                    <i className="fa fa-eye fa-xs"></i>
                                  ) : item.ListaParaEnviar === 0 ? (
                                    <i className="fa fa-pencil-square-o fa-xs"></i>
                                  ) : (
                                    <i className="fa fa-plus fa-xs"></i>
                                  )}
                                </span>
                                Cedula
                              </Button>
                            </Col>
                          )}
                        </Row>
                        <Row>
                          <Col>
                            {item.idCedula && (
                              <Button
                                color="warning"
                                size="sm"
                                style={{ width: "100%" }}
                                onClick={() => handleShowFiles(item)}
                              >
                                <i className="fa fa-file-archive-o fa-xs"></i>
                                Archivos
                              </Button>
                            )}
                          </Col>
                          <Col>
                            {item.ListaParaEnviarY === 1 &&
                              menu.Imprimir === 1 &&
                              item.Formato == 1 && (
                                <Button
                                  color="success"
                                  size="sm"
                                  style={{ width: "100%" }}
                                  onClick={() => sendIGTO(item.idCedula)}
                                >
                                  <span>
                                    <i className="fa fa-paper-plane fa-xs" />
                                  </span>{" "}
                                  Ventanilla
                                </Button>
                              )}
                          </Col>
                        </Row>
                      </>
                    ) : (
                      <>
                        {item.Formato === 1 ? (
                          <Row>
                            <Col>
                              <Button
                                color="info"
                                size="sm"
                                style={{ width: "100%" }}
                                onClick={() => sendDataSolicitud(item)}
                              >
                                <span>
                                  <i className="fa fa-paper-plane fa-xs" />
                                </span>{" "}
                                Enviada (Ver)
                              </Button>
                            </Col>
                          </Row>
                        ) : (
                          <Row>
                            <Col>
                              <Button
                                color="info"
                                size="sm"
                                style={{ width: "100%" }}
                                onClick={() => sendDataSolicitud(item)}
                              >
                                <span>
                                  <i className="fa fa-paper-plane fa-xs" />
                                </span>{" "}
                                Validada (Ver)
                              </Button>
                            </Col>
                          </Row>
                        )}
                      </>
                    )}
                  </>
                ),
              };
            })
          );
          setMenuLoaded(true);
        } else {
          swal({
            title: "¡Atención!",
            text: `${res.errors}`,
            icon: "info",
          });
        }
        setMenuLoaded(true);
        setLoadingCedulaList(false);
      })
      .catch((e) => {
        setMenuLoaded(true);
        setLoadingCedulaList(false);
        swal({
          title: "¡Atención!",
          text: `${e.message}`,
          icon: "warning",
        });
      });
  };

  useEffect(() => {
    const token = getVars("Token").token;
    EjecutarApi({}, "yopuedo/getEstatusGlobalVentanillaYoPuedo", token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.results) {
          setTotalSolicitudes(res.data[0].Solicitudes);
          setTotalAprobadas(res.data[0].AprobadoComite);
          setTotalValidadas(res.data[0].ProcesoValidacion);
        }
      })
      .catch((e) => {
        notify.show(
          `Error en la obtencion de datos! ${e.message}`,
          "custom",
          5000,
          {
            background: "#d61818",
            text: "#FFFFFF",
          }
        );
      });
  }, [solicitud]);

  const handleChangeArchivos = (files) => {
    setArchivos(files);
  };

  const handleChangeClasificacion = (arrayClasificacion) => {
    setClasificacion(arrayClasificacion);
  };

  const handleDeleteFile = (files, arrayClasificacion) => {
    setArchivos(files);
    setClasificacion(arrayClasificacion);
  };

  const changeFiles = () => {
    const token = getVars("Token");
    let oldFiles = [];
    let newFiles = [];
    let oldClasification = [];
    let newClasification = [];

    archivos.forEach((file, index) => {
      if (isNullOrUndefined(file.id)) {
        newFiles.push(file);
        newClasification.push(clasificacion[index]);
      } else {
        oldFiles.push(file);
        oldClasification.push(clasificacion[index]);
      }
    });

    if (newFiles.length > 0) {
      let f = false;
      newFiles.map((file) => {
        let nameFile = file.name.toString();
        if (!/(.jpg|.jpeg|.png|.pdf)$/i.test(nameFile)) {
          f = true;
        }
      });
      if (f) {
        swal({
          title: "Alerta",
          text: "Solo puede cargar archivos de tipo PDF y JPG o PNG",
          icon: "warning",
        });
        return;
      }
    }

    if (oldClasification.length > 0 || newClasification.length > 0) {
      let formDataFiles = [];
      formDataFiles = oldClasification.concat(newClasification);
      if (formDataFiles.length > 0) {
        const formFiles = formDataFiles.map((element) => {
          return parseInt(element);
        });
        formDataFiles = formFiles;

        let ine = formDataFiles.filter((element) => element == 3);
        if (ine.length > 1) {
          swal({
            title: "Alerta",
            icon: "warning",
            text: "Solo puede cargar la identificación una vez",
          });
          return;
        }

        let domicilio = formDataFiles.filter((element) => element == 6);
        if (domicilio.length > 1) {
          swal({
            title: "Alerta",
            icon: "warning",
            text: "Solo puede cargar el comprobante de domicilio una vez",
          });
          return;
        }

        let curp = formDataFiles.filter((element) => element == 4);
        if (curp.length > 1) {
          swal({
            title: "Alerta",
            icon: "warning",
            text: "Solo puede cargar el CURP una vez",
          });
          return;
        }

        let acuse = formDataFiles.filter((element) => element == 5);
        if (acuse.length > 1) {
          swal({
            title: "Alerta",
            icon: "warning",
            text: "Solo puede cargar el Acuse una vez",
          });
          return;
        }

        let evidencia = formDataFiles.filter((element) => element == 12);
        if (evidencia.length > 1) {
          swal({
            title: "Alerta",
            icon: "warning",
            text: "Solo puede cargar las Evidencias PDF una vez",
          });
          return;
        }

        let infor = formDataFiles.filter((element) => element == 10);
        if (infor.length > 1) {
          swal({
            title: "Alerta",
            icon: "warning",
            text: "Solo puede cargar el Formato de Información del Programa una vez",
          });
          return;
        }

        let tarjeta = formDataFiles.filter((element) => element == 13);
        if (tarjeta.length > 1) {
          swal({
            title: "Alerta",
            icon: "warning",
            text: "Solo puede cargar la Tarjeta Contigo Si una vez",
          });
          return;
        }
      }
    }

    const formAux = {
      OldFiles: oldFiles,
      NewFiles: newFiles,
      OldClasificacion: oldClasification,
      NewClasificacion: newClasification,
      id: selectedRecord.idCedula,
    };
    setIsLoadingArchivos(true);
    uploadFiles({
      data: formAux,
      api: `yopuedo/updateArchivosCedula`,
      token: token.token,
    })
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.success && res.results) {
          swal({
            title: "¡Éxito!",
            icon: "success",
          });
          handleShowFiles(selectedRecord);
        } else {
          swal({
            title: "Ha ocurrido un error",
            text: res.errors,
            icon: "warning",
          });
          setIsLoadingArchivos(false);
        }
      })
      .catch((error) => {
        setIsLoadingArchivos(false);
        swal({
          title: "Ha ocurrido un error",
          text: error.error,
          icon: "warning",
        });
      });
  };

  const sendIGTO = (id) => {
    setLoadingCedulaList(true);
    const token = getVars("Token");
    const data = { id: id };
    EjecutarApi(data, "yopuedo/enviarIGTO", token.token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.results && res.success) {
          swal({
            title: "Cedula enviada con éxito",
            icon: "success",
          });
          setIsCedula(!isCedula);
          setIsSolicitud(!isSolicitud);
          setIsCedula(isCedula);
          setIsSolicitud(isSolicitud);
        } else {
          swal({
            title: "¡Atención!",
            text: `${res.message}`,
            icon: "info",
          });
        }
        setLoadingCedulaList(false);
      })
      .catch((e) => {
        setLoadingCedulaList(false);
        swal({
          title: "¡Atención!",
          text: `${e.message}`,
          icon: "warning",
        });
      });
  };

  const btnExportar = () => {
    const token = getVars("Token");
    var url = new URL(
      URL_GENERAL + `getReporteSolicitudVentanillaYoPuedo?token=${token.token}`
    );
    window.open(url, "_blank");
  };

  return (
    <>
      <div className="wrapper">
        <Sidebar {...props} bgColor="black" activeColor="info" />
        <div className="main-panel">
          <Navbar {...props} handleMiniClick={handleMiniClick} />
          <Notifications />
          <div className="content">
            <Modal
              size="xl"
              isOpen={isModalOpened}
              toggle={() => setIsModalOpened(!isModalOpened)}
            >
              <ModalHeader>Archivos</ModalHeader>
              <ModalBody>
                {isLoadingArchivos && <Spinner />}
                {!isLoadingArchivos && (
                  <ListadoArchivos
                    files={archivos}
                    archivosClasificacion={clasificacion}
                    catalogClasificacion={catalogos}
                    handleChangeArchivos={handleChangeArchivos}
                    handleChangeClasificacion={handleChangeClasificacion}
                    handleDeleteFile={handleDeleteFile}
                    loadingCatalogs={isLoadingCatalogos}
                    onlyForView={selectedRecord.ListaParaEnviarC === 1}
                    fullscreen={true}
                  />
                )}
              </ModalBody>
              <ModalFooter>
                <Button
                  size="sm"
                  color="danger"
                  disabled={isLoadingArchivos}
                  onClick={() => setIsModalOpened(false)}
                >
                  Cancelar
                </Button>
                {selectedRecord.ListaParaEnviarY == 0 && menu.Agregar == 1 && (
                  <Button
                    size="sm"
                    color="primary"
                    disabled={isLoadingArchivos}
                    onClick={() => changeFiles()}
                  >
                    Guardar
                  </Button>
                )}
              </ModalFooter>
            </Modal>
            {!isSolicitud && !isCedula ? (
              <>
                <Row>
                  <Col lg="4" md="6" sm="6">
                    <Card className="card-stats">
                      <CardBody>
                        <Row>
                          <Col md="4" xs="5">
                            <div className="icon-big text-center icon-warning">
                              <i className="nc-icon nc-globe text-warning" />
                            </div>
                          </Col>
                          <Col md="8" xs="7">
                            <div className="numbers">
                              <p className="card-category">
                                Solicitudes Capturadas
                              </p>
                              <CardTitle tag="p">
                                {new Intl.NumberFormat("en-EN").format(
                                  TotalSolicitudes
                                )}
                              </CardTitle>
                              <p />
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>

                  <Col lg="4" md="6" sm="6">
                    <Card className="card-stats">
                      <CardBody>
                        <Row>
                          <Col md="4" xs="5">
                            <div className="icon-big text-center icon-warning">
                              <i className="nc-icon nc-money-coins text-success" />
                            </div>
                          </Col>
                          <Col md="8" xs="7">
                            <div className="numbers">
                              <p className="card-category">
                                Solicitudes Validadas
                              </p>
                              <CardTitle tag="p">
                                {new Intl.NumberFormat("en-EN").format(
                                  TotalValidadas
                                )}
                              </CardTitle>

                              <p />
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  {menu.Seguimiento == 1 && (
                    <Col lg="4" md="6" sm="6">
                      <Card className="card-stats">
                        <CardBody>
                          <Row>
                            <Col md="4" xs="5">
                              <div className="icon-big text-center icon-warning">
                                <i className="nc-icon nc-vector text-danger" />
                              </div>
                            </Col>
                            <Col md="8" xs="7">
                              <div className="numbers">
                                <p className="card-category">
                                  Solicitudes Enviadas
                                </p>
                                <CardTitle tag="p">
                                  {new Intl.NumberFormat("en-EN").format(
                                    TotalAprobadas
                                  )}
                                </CardTitle>

                                <p />
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  )}
                </Row>
                <Row>
                  <Col md="12">
                    <Card>
                      <CardHeader>
                        <Row>
                          <Col md="4">
                            <CardTitle tag="h6">
                              Listado de Solicitudes
                            </CardTitle>
                          </Col>
                          <Col md="3">
                            {menu.Agregar === 1 ? (
                              <Button
                                color="primary"
                                size="sm"
                                onClick={() => {
                                  sendDataSolicitud(null);
                                  setFormato(1);
                                }}
                              >
                                <span className="btn-label">
                                  <i className="fa fa-user-plus fa-2x" />
                                </span>
                                Captura Formato Anterior
                              </Button>
                            ) : (
                              <></>
                            )}
                          </Col>
                          <Col md="2">
                            {menu.Agregar === 1 ? (
                              <Button
                                color="primary"
                                size="sm"
                                onClick={() => {
                                  sendDataSolicitud(null);
                                  setFormato(2);
                                }}
                              >
                                <span className="btn-label">
                                  <i className="fa fa-user-plus fa-2x" />
                                </span>
                                Capturar
                              </Button>
                            ) : (
                              <></>
                            )}
                          </Col>
                          <Col md="3">
                            {menu.Exportar === 1 ? (
                              <>
                                <Button
                                  color="success"
                                  size="sm"
                                  onClick={btnExportar}
                                >
                                  <span className="btn-label">
                                    <i className="fa fa-file-excel-o fa-2x" />
                                  </span>
                                  Exportar
                                </Button>
                              </>
                            ) : (
                              <></>
                            )}
                          </Col>
                        </Row>
                      </CardHeader>
                      <CardBody>
                        {loadingCedulaList && (
                          <CircleSpinner
                            className="text-center"
                            size={30}
                            color="#686769"
                            loading={loadingCedulaList}
                          />
                        )}
                        <Col lg="12" md="12">
                          {menuLoaded && dataCedulaList && (
                            <ReactTable
                              size="sm"
                              data={dataCedulaList}
                              noDataText="No Existen Datos a Mostrar!"
                              manual
                              columns={columns}
                              className="-striped -highlight primary-pagination"
                              loading={loadingCedulaList}
                              showPagination={true}
                              showPaginationBottom={true}
                              showTotalPages={true}
                              showPageJump={false}
                              canNextFromData={true}
                              defaultPageSize={pageOptions.pageSize}
                              total={total}
                              page={pageOptions.page}
                              pages={Math.floor(total / pageOptions.pageSize)}
                              previousText="Anterior"
                              nextText="Siguiente"
                              ofText="de"
                              pageSizeOptions={[10, 20, 25, 50, 100]}
                              PaginationComponent={Pagination}
                              onFetchData={fetchData}
                            />
                          )}
                        </Col>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </>
            ) : isSolicitud && !isCedula && formato == 1 ? (
              <SolicitudYoPuedoAnterior
                data={solicitud}
                backSolicitud={() => {
                  setIsSolicitud(!isSolicitud);
                  setFormato(0);
                }}
                seeCedula={(data) => {
                  sendDataCedula(data ? data : cedula);
                }}
              />
            ) : isSolicitud && !isCedula && formato == 2 ? (
              <SolicitudYoPuedo
                data={solicitud}
                backSolicitud={() => {
                  setIsSolicitud(!isSolicitud);
                  setFormato(0);
                }}
              />
            ) : (
              <YoPuedoCedulaForm
                data={cedula}
                backCedula={() => {
                  setIsCedula(!isCedula);
                  setFormato(0);
                }}
              />
            )}
          </div>
          <FixedPlugin sendFiltro={setFilter} clasess="dropdowns" />
          <Footer fluid />
        </div>
      </div>
    </>
  );
};
export default YoPuedo;
