import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from "reactstrap";
import swal from 'sweetalert';

import ReactBSAlert from "react-bootstrap-sweetalert";
import Select from "react-select";
import EjecutarApi from "../Consultas/Consultas";
import { RotateSpinner, ImpulseSpinner } from "react-spinners-kit";
import { getVars } from "../GlobalStorage";
import Notifications, { notify } from "react-notify-toast";
import ReactDatetime from "react-datetime";
import moment from "moment";
import DateTimeComponent from "../components/DateTimeComponent";
import ReactTable from "react-table";
import catOcupacion from '../data/catOcupacion.json';

const ValesUpdate = (props, { _vales, onHide }) => {
  //console.log("valesupdate->_vales:", _vales);

  const token = getVars("Token");
  //console.log ('@@@Token', token);
  const [flag, setFlag] = useState(false);
  const [flagDelete,setFlagDelete] =  useState(false);
  const [flagSet, setFlagSet] = useState(false);
  const [otraColonia, setOtraColonia] = useState("");
  const [municipios, setMunicipios] = useState([]);
  const [localidades, setLocalidades] = useState([]);
  const [colonias, setColonias] = useState([]);
  const [_menudelete, _setmenudelete] = useState({

  });
  //const [idArticulador, setidArticulador] = useState(token.user.id);
  const [nomMunicipio, setNomMunicipio] = useState("");
  //const [dataArticulador,setdataArticulador] = useState([]);
  const [loading, setloading] = useState(false);
  const [ban, setban] = useState(false);
  const [ClaveUnica, setClaveUnica] = useState("");

  const [data, setData] = useState([]);
  const [totalColonias, setTotalColonias] = useState(0);
  //const [loading, setLoading] = useState(false);

  const [flagEscogerColonia, setFlagEscogerColonia] = useState(false);
  const [flagEscogerArticulador, setflagEscogerArticulador] = useState(false);
  const [close, setClose] = useState(true);
  //Mensaje de error en validaciones
  const [msgErrorValidacion, setmsgErrorValidacion] = useState("");
  const [flagMsgErrorValidacion, setFlagMsgErrorValidacion] = useState(false);
  const setearMenu = (dependecia) => {
    if (dependecia === "Estado") {
      console.log("MENU FUNCTION",token.menu)
      var json = 
      {
        Ver: 0,
        Agregar: 0,
        Editar: 0,
        Eliminar: 0,
        Seguimiento: 0,
        Exportar: 0,
        Imprimir:0,
        ViewAll: 0,
        idTipoUser: token.user.idTipoUser.id,
      };
      token.menu.forEach((prop, key) => {
        if (prop.Menu === "Eliminar Vales") {
          _setmenudelete({
            Ver: prop.Ver,
            Agregar: prop.Agregar,
            Editar: prop.Editar,
            Eliminar: prop.Eliminar,
            Seguimiento: prop.Seguimiento,
            Exportar: prop.Exportar,
            Imprimir: prop.Imprimir,
            ViewAll: prop.ViewAll,
            idTipoUser: token.user.idTipoUser.id,
          });
        }
        else{
          _setmenudelete(json);
        }
      });
    } else {
      var json = 
      {
        Ver: 0,
        Agregar: 0,
        Editar: 0,
        Eliminar: 0,
        Seguimiento: 0,
        Exportar: 0,
        Imprimir:0,
        ViewAll: 0,
        idTipoUser: token.user.idTipoUser.id,
      };

      token.menu.forEach((prop, key) => {
        if (prop.Menu === "Eliminar Vales") {
          _setmenudelete({
            Ver: prop.Ver,
            Agregar: prop.Agregar,
            Editar: prop.Editar,
            Eliminar: prop.Eliminar,
            Seguimiento: prop.Seguimiento,
            Exportar: prop.Exportar,
            Imprimir: prop.Imprimir,
            ViewAll: prop.ViewAll,
            idTipoUser: token.user.idTipoUser.id,
          });

          json = {
            Ver: prop.Ver,
            Agregar: prop.Agregar,
            Editar: prop.Editar,
            Eliminar: prop.Eliminar,
            Seguimiento: prop.Seguimiento,
            Exportar: prop.Exportar,
            Imprimir: prop.Imprimir,
            ViewAll: prop.ViewAll,
            idTipoUser: token.user.idTipoUser.id,
            regiones: token.regiones,
          };
        }
      });

      return json;
    }
  };

  const [vale, setVale] = useState({
    id: props._vales.id,
    Folio: props._vales.ClaveUnica,
    FechaSolicitud: props._vales.FechaSolicitud,
    Ocupacion: {value: props._vales.OcupacionOtro === 1 ? 'Otro': props._vales.Ocupacion, label: props._vales.OcupacionOtro === 1 ? 'Otro': props._vales.Ocupacion},
    OcupacionOtro: props._vales.OcupacionOtro === 1 ? props._vales.Ocupacion:'',
    CURP: props._vales.CURP,
    Nombre: props._vales.Nombre,
    Paterno: props._vales.Paterno,
    Materno: props._vales.Materno,
    Sexo: props._vales.Sexo,
    FechaNacimiento: props._vales.FechaNacimiento,
    Calle: props._vales.Calle,
    NumInt: props._vales.NumInt,
    NumExt: props._vales.NumExt,
    Colonia: props._vales.Colonia,
    CP: props._vales.CP,
    idMunicipio: props._vales.idMunicipio,
    idLocalidad: props._vales.idLocalidad,
    TelFijo: props._vales.TelFijo,
    TelCelular: props._vales.TelCelular,
    TelRecados: props._vales.TelRecados,
    Compania: props._vales.Compania,
    CorreoElectronico: props._vales.CorreoElectronico,
    idStatus: props._vales.idStatus.id,
    UserOwned: props._vales.UserCreated.id,
    idArticulador: props._vales.UserOwned.id,
    IngresoPercibido: props._vales.IngresoPercibido,
    OtrosIngresos: props._vales.OtrosIngresos,
    NumeroPersonas: props._vales.NumeroPersonas,
    Articulador: `${props._vales.UserOwned.Nombre} ${props._vales.UserOwned.Materno} ${props._vales.UserOwned.Materno}`,
  });

  const optcompania = [
    { value: "0", label: "Seleccionar una Compañía Telefónica" },
    { value: "Telcel", label: "Telcel" },
    { value: "Iusacell", label: "Iusacell" },
    { value: "Movistar", label: "Movistar" },
    { value: "at&t", label: "at&t" },
    { value: "Unefon", label: "Unefon" },
  ];
  const datos = {
    page: 0,
    tipo: "and",
    pageSize: 1000,
    sorted: [],
    filtered: [],
  };
  const datosColonia = {
    page: 0,
    tipo: "and",
    pageSize: 9999,
    sorted: [],
    filtered: [
      {
        id: "d_codigo",
        value: vale.CP,
      },
      {
        id: "D_mnpio",
        value: nomMunicipio,
      },
    ],
  };

  const Spinenerstyle = {
    height: "50pt",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };
  console.log("MENU",_menudelete)
  useEffect(() => {
    setearMenu("Estado");
    console.log("valesupdate->props:", props);
    EjecutarApi(datos, "getMunicipiosET", token.token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.results) {
          setMunicipios(
            res.data.map((municipio) => {
              return {
                value: municipio["Id"],
                label: municipio["Nombre"],
              };
            })
          );
        }
      })
      .catch((e) => {
        notify.show(`Error en la busqueda! ${e.message}`, "custom", 5000, {
          background: "#d61818",
          text: "#FFFFFF",
        });
      });

      municipioChange(props._vales.idMunicipio.id);

    //ObteniendoArticuladores
    var datoArt = {
      page: 0,
      tipo: "and",
      pageSize: 400,
      sorted: [],
      filtered: [{ id: "idTipoUser", value: 9 }],
    };
    //document.body.classList.toggle('sidebar-mini')
    //document.body(("selectSexo" ) => ({ value: vale.Sexo }));
    //setItems(body.results.map(({ name }) => ({ label: name, value: name })));
  }, []);

  const handlesetFechaSolicitud = (e) => {
    let event = e;
    if (!event) {
      console.log("DataTimeComponen...Vacio-->e:", e);
      setVale({
        ...vale,
        FechaSolicitud: "",
      });
    } else {
      console.log("DataTimeComponen.. No vacio-->e:", e);
      setVale({
        ...vale,
        FechaSolicitud: moment(e._d).format("YYYY-MM-DD"),
      });
    }
  };
  const setValeEvent = () => {
    //console.log("paso 0 ... actualizacion");
    setFlagSet(true);
    if (vale.Ocupacion.value ==="Otro") {
      if(vale.Ocupacion.value.length<3)
      {
        setFlagSet(false);
        notify.show("Error: La fecha de solicitud es requerida", "custom", 5000, {
          background: "#0857B6",
          fontSize: 14,
          text: "#FFFFFF",
        });
        return true;
      }
    }else
    {
      if(vale.Ocupacion.value.length<3)
      {
        setFlagSet(false);
        notify.show("Error: La fecha de solicitud es requerida", "custom", 5000, {
          background: "#0857B6",
          fontSize: 14,
          text: "#FFFFFF",
        });
        return true;
      }
    }

    setban(false);
    
    var updateVales = {
      id: vale.id,
      FechaSolicitud: vale.FechaSolicitud,
      Ocupacion: vale.Ocupacion.value === "Otro" ? vale.OcupacionOtro: vale.Ocupacion.value,
      OcupacionOtro: vale.Ocupacion.value === "Otro" ? 1:0,
      CURP: vale.CURP,
      Nombre: vale.Nombre,
      Paterno: vale.Paterno,
      Materno: vale.Materno,
      Sexo: vale.Sexo,
      FechaNacimiento: vale.FechaNacimiento,
      Calle: vale.Calle,
      NumInt: vale.NumInt,
      NumExt: vale.NumExt,
      Colonia: vale.Colonia !== "" && vale.Colonia !== "otro"
      ? vale.Colonia
      : otraColonia,
      CP: vale.CP,

      idMunicipio: vale.idMunicipio.id,
      idLocalidad: vale.idLocalidad.id,
      TelFijo: vale.TelFijo,
      TelCelular: vale.TelCelular,
      TelRecados: vale.TelRecados,
      Compania: vale.Compania,
      IngresoPercibido: vale.IngresoPercibido,
      OtrosIngresos: vale.OtrosIngresos,
      NumeroPersonas: vale.NumeroPersonas,
      CorreoElectronico: vale.CorreoElectronico,
      idStatus: 1,
      UserOwned: vale.idArticulador,
    };
    setVale({
      ...vale,
      Colonia:
        vale.Colonia !== "" && vale.Colonia !== "otro"
          ? vale.Colonia
          : otraColonia,
    });

    setFlagSet(true);
    console.log("FechaSolicitud-->validacion:", vale.FechaSolicitud);
    if (vale.FechaSolicitud.length < 1) {
      setFlagSet(false);
      setmsgErrorValidacion("La fecha de solicitud es requerida");
      setFlagMsgErrorValidacion(true);
      /*notify.show("Error: La fecha de solicitud es requerida", "custom", 5000, {
        background: "#0857B6",
        fontSize: 14,
        text: "#FFFFFF",
      });*/
      return true;
    }
    if (vale.CURP.length === 0) {
      if (!curpValida(vale.CURP)) {
        setFlagSet(false);
        setmsgErrorValidacion("La CURP del cobrador es requerida y válida!");
        setFlagMsgErrorValidacion(true);

        return true;
      }
    }
    if (vale.Nombre.length < 1) {
      setFlagSet(false);
      setmsgErrorValidacion("Nombre es requerido!");
      setFlagMsgErrorValidacion(true);
      /*notify.show("Error: Nombre es requerido", "custom", 5000, {
        background: "#0857B6",
        fontSize: 14,
        text: "#FFFFFF",
      });*/
      return true;
    }
    if (vale.Paterno.length < 1) {
      setFlagSet(false);
      setmsgErrorValidacion("Apellido Paterno es requerido!");
      setFlagMsgErrorValidacion(true);
      /*notify.show("Error: Nombre es requerido", "custom", 5000, {
        background: "#0857B6",
        fontSize: 14,
        text: "#FFFFFF",
      });*/
      return true;
    }

    if (vale.FechaNacimiento.length < 3) {
      setFlagSet(false);
      setmsgErrorValidacion("Fecha de Nacimiento es requerida!");
      setFlagMsgErrorValidacion(true);

      return true;
    }
    if (vale.idMunicipio.length < 1) {
      setFlagSet(false);
      setmsgErrorValidacion("Municipio es requerido!");
      setFlagMsgErrorValidacion(true);

      return true;
    }
    if (vale.Calle.length < 1) {
      setFlagSet(false);
      setmsgErrorValidacion("Calle es requerido!");
      setFlagMsgErrorValidacion(true);

      return true;
    }
    if (vale.NumExt.length < 1) {
      setFlagSet(false);
      setmsgErrorValidacion("Número Exterior es requerido!");
      setFlagMsgErrorValidacion(true);

      return true;
    }
    if (vale.Colonia.length < 1) {
      setFlagSet(false);
      setmsgErrorValidacion("Colonia es requerido!");
      setFlagMsgErrorValidacion(true);

      return true;
    }
    if (vale.idLocalidad.length < 1) {
      setFlagSet(false);
      setmsgErrorValidacion("Localidad es requerido!");
      setFlagMsgErrorValidacion(true);

      return true;
    }

    if (vale.CP.length !== 5) {
      setFlagSet(false);
      setmsgErrorValidacion(
        "Codigo Postal es requerido y debe tener 5 caracteres!"
      );
      setFlagMsgErrorValidacion(true);

      return true;
    }
    if(vale.TelCelular){
      if(vale.TelCelular.length !== 0){
        if (
          (vale.TelCelular.length > 0 && vale.TelCelular.length < 10) ||
          vale.TelCelular.length > 10 ||
          vale.TelCelular.length === 0
        ) {
          setFlagSet(false);
          setmsgErrorValidacion("El teléfono celular debe ser de 10 digitos!");
          setFlagMsgErrorValidacion(true);

          return true;
        }
    }else
    { 
        setFlagSet(false);
        setmsgErrorValidacion("El teléfono celular debe ser de 10 digitos!");
        setFlagMsgErrorValidacion(true);

          return true;
    }
    }
     if(vale.Compania){
    if(vale.Compania.length){
      if (vale.Compania.length < 3) {
        setFlagSet(false);
        setmsgErrorValidacion("La compañía de celular es requerida!");
        setFlagMsgErrorValidacion(true);
  
        return true;
      }
    }
  }

    if (vale.NumeroPersonas < 1) {
      setFlagSet(false);
      setmsgErrorValidacion("Numero de personas dependientes del Ingreso Mensual es requerido!");
      setFlagMsgErrorValidacion(true);

      return true;
    }

    console.log("=>updateVale:", JSON.stringify(updateVales));
    console.log("token:", token.token);

    EjecutarApi(updateVales, "updateVales", token.token)
      .then(function (res) {
        console.log("=>>updateRS", res);

        if (res.results) {
            setFlagSet(false);
            handleonHide();
            notify.show("Registro Exitoso! ", "custom", 5000, {
              background: "#18c97d",
              fontSize: 14,
              text: "#FFFFFF",
            });
            return res;
        }else
        {
          setFlagSet(false);
          notify.show(`Error en registro de vales! ${res.message}`, "custom", 5000, {
            background: "#d61818",
            text: "#FFFFFF",
            fontSize: 14,
          });
        }
      })
      .catch((e) => {
        console.log("=>>errrorrrrrrrrr", e);
        setFlagSet(false);

        swal({
          title: "Error vales",
          text: e.message,
          icon: "warning",
        });

        
        notify.show(`Error en registro de vales! ${e.message}`, "custom", 5000, {
          background: "#d61818",
          text: "#FFFFFF",
          fontSize: 14,
        });
      });

    //}
    setFlagSet(false);
  };
  const deleteValeEvent = () => {
    //setFlagDelete(true);
    var updateVales = {
      id: vale.id
    };
    EjecutarApi(updateVales, "deleteVales", token.token)
      .then(function (res) {
        console.log("=>>updateRS", res);

        if (res.results) {
            setFlagSet(false);
            handleonHide();
            notify.show("Eliminación Exitosa! ", "custom", 5000, {
              background: "#18c97d",
              fontSize: 14,
              text: "#FFFFFF",
            });
            return res;
        }else
        {
          setFlagSet(false);
          notify.show(`Error en la eliminacion de vales! ${res.message}`, "custom", 5000, {
            background: "#d61818",
            text: "#FFFFFF",
            fontSize: 14,
          });
        }
      })
      .catch((e) => {
        console.log("=>>errrorrrrrrrrr", e);
        setFlagSet(false);

        swal({
          title: "Error vales",
          text: e.message,
          icon: "warning",
        });

        
        notify.show(`Error en registro de vales! ${e.message}`, "custom", 5000, {
          background: "#d61818",
          text: "#FFFFFF",
          fontSize: 14,
        });
      });

    //}
    setFlagSet(false);
  };
  const handleonHide = () => {
    //onHide(false);
    setban(false);
    props.onHide(false);
  };
  const alerta = (
    <ReactBSAlert
      style={{ display: "block", marginTop: "-100px" }}
      title={"¿Está seguro de actualizar los datos?"}
      onConfirm={() => setValeEvent()}
      onCancel={() => handleonHide()}
      confirmBtnBsStyle="info"
      confirmBtnText="Si"
      cancelBtnText="No"
    />
  );
  const alertaDelete = (
    <ReactBSAlert
      showCancel
      style={{ display: "block", marginTop: "-100px" }}
      title={"¿Está seguro de eliminar el vale?"}
      onConfirm={() => {deleteValeEvent();handleonHide();}}
      onCancel={() => handleonHide()}
      confirmBtnBsStyle="info"
      cancelBtnBsStyle="danger"
      confirmBtnText="Si"
      cancelBtnText="Cancelar"
    />
  );


  const municipioChange = (mun) => {
    if (mun) {
      const datos_localidades = {
        page: 0,
        tipo: "and",
        pageSize: 1000,
        sorted: [],
        filtered: [
          {
            id: "IdMunicipio",
            value: mun,
          },
        ],
      };

      console.log('=>datos_localidades: ', datos_localidades)
      EjecutarApi(datos_localidades, "getLocalidadET", token.token)
        .then(function (res) {
          return res;
        })
        .then((res) => {
          if (res.results) {
            //setData(res.data);
            setLocalidades(
              res.data.map((status_) => {
                return {
                  value: status_["Id"],
                  label: status_["Nombre"],
                };
              })
            );
          }
        })
        .catch((e) => {
          notify.show(`Error en la busqueda! ${e.message}`, "custom", 5000, {
            background: "#d61818",
            text: "#FFFFFF",
            fontSize: 14,
          });
        });
    }
  };

  const coloniasChange = () => {
    setFlag(true);
    EjecutarApi(datosColonia, "getCP", token.token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.results) {
          var colonias_ = res.data.map((cp) => {
            return {
              value: cp["d_asenta"],
              label: cp["d_asenta"],
            };
          });

          colonias_.push({
            value: "otro",
            label: "otro",
          });
          setColonias(colonias_);
          setFlag(true);
        }
      })
      .catch((e) => {
        notify.show(`Error en la busqueda! ${e.message}`, "custom", 5000, {
          background: "#d61818",
          text: "#FFFFFF",
          fontSize: 14,
        });
      });
  };

  function curpValida(curp) {
    var re = /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/,
      validado = curp.match(re);

    if (!validado)
      //Coincide con el formato general?
      return false;

    //Validar que coincida el dígito verificador
    function digitoVerificador(curp17) {
      //Fuente https://consultas.curp.gob.mx/CurpSP/
      var diccionario = "0123456789ABCDEFGHIJKLMNÑOPQRSTUVWXYZ",
        lngSuma = 0.0,
        lngDigito = 0.0;
      for (var i = 0; i < 17; i++)
        lngSuma = lngSuma + diccionario.indexOf(curp17.charAt(i)) * (18 - i);
      lngDigito = 10 - (lngSuma % 10);
      if (lngDigito == 10) return 0;
      return lngDigito;
    }

    if (validado[2] != digitoVerificador(validado[1])) return false;

    return true; //Validado
  }

  const getCURP = () => {
    setloading(true);
    console.log("=> Buscar CURP");
    if (!vale.CURP) {
      setloading(false);
      notify.show(`Error: CURP inválida!`, "custom", 5000, {
        background: "#d61818",
        text: "#FFFFFF",
        fontSize: 14,
      });
      return true;
    }

    if (vale.CURP.length != 18) {
      setloading(false);
      notify.show(`Error: CURP inválida!`, "custom", 5000, {
        background: "#d61818",
        text: "#FFFFFF",
        fontSize: 14,
      });
      return true;
    }

    const CURP = vale.CURP.toUpperCase();
    var sexo_obtenido;
    console.log("=> Buscar CURP: ", CURP);
    fetch(
      `https://seguimiento.guanajuato.gob.mx/apiinformacionsocial/api/renapo/porcurp/pL@t_1n|Run$28/${CURP}`
    )
      .then((res) => res.json())
      .then((dataRS) => {
        setloading(false);
        console.log("=> Resultados CURP", dataRS);
        if (dataRS.Mensaje == "OK") {
          //aqui
          var FN = dataRS.Resultado.fechNac.split("/");

          console.log("=FNAC: ", FN); //año-mes-dia
          console.log("@@@valores SEXO", dataRS.Resultado.sexo);
          sexo_obtenido = dataRS.Resultado.sexo;
          setVale({
            ...vale,
            Nombre: dataRS.Resultado.nombres,
            Paterno: dataRS.Resultado.apellido1,
            Materno: dataRS.Resultado.apellido2,
            Sexo: dataRS.Resultado.sexo === "H" ? "M" : "F", // { value: "M", label:"MASCULINO"} : { value: "F", label:"FEMENINO"} , //{ label: dataRS.Resultado.sexo, value: dataRS.Resultado.sexo === 'H' ? 'M' : 'F' },//"M",//dataRS.Resultado.sexo === 'H' ? 'M' : 'F',
            FechaNacimiento: FN[2] + "-" + FN[1] + "-" + FN[0],
          });
          console.log("=>valores CURP", vale);
        } else {
          setloading(false);
          if (
            dataRS.Mensaje === "La CURP no se encuentra en la base de datos"
          ) {
            console.log("La CURP no se encuentra en la base de datos");
            notify.show(`Error en CURP...! ${dataRS.Mensaje}`, "custom", 5000, {
              background: "#d61818",
              fontSize: 14,
              text: "#FFFFFF",
            });
          } else if (
            dataRS.Mensaje ===
            "El campo CURP: No cumple con el formato especificado."
          ) {
            console.log(
              "El campo CURP: No cumple con el formato especificado."
            );
            notify.show(`Error en CURP...! ${dataRS.Mensaje}`, "custom", 5000, {
              background: "#d61818",
              fontSize: 14,
              text: "#FFFFFF",
            });
          } else {
            console.log("El servicio no esta disponible.");
          }
          return true;
        }
        console.log("=> Resultados CURP", dataRS);
      })
      .catch((err) => {
        setloading(false);
        console.log("=>Error en CURO: ", err);
      });
    //
  };

  const mini_tableArticulador = (
    <>
      <ReactBSAlert
        style={{ display: "block", marginTop: "-250pt" }}
        //title="Here's a message!"
        onConfirm={() => setflagEscogerArticulador(false)}
        onCancel={() => setflagEscogerArticulador(false)}
        confirmBtnBsStyle="info"
      >
        <Card className="mb-2 text-muted">
          <CardBody className="mb-2 text-muted">
            <ReactTable
              data={data}
              noDataText="¡No existen datos a mostrar!"
              columns={[
                {
                  Header: "Articulador",
                  minWidth: 10,
                  id: "idArticulador",
                  accessor: (d) => d.Articulador,
                  filterAll: false,
                },
              ]}
              defaultPageSize={5}
              filterable
              className={"-striped -highlight primary-pagination"}
              loading={loading}
              showPagination={true}
              showPageJump={false}
              previousText="Anterior"
              nextText="Siguiente"
              canNextFromData={true}
              manual
              total={totalColonias}
              pages={Math.floor(totalColonias / 10)}
              pageSizeOptions={[10, 20, 25, 50, 100]}
              getTrProps={(state, rowInfo, column, instance) => {
                return {
                  onClick: (e) => {
                    console.log("@#@#@--- Datos", rowInfo.original.data);
                    setVale({
                      ...vale,
                      idArticulador: rowInfo.original.data.id,
                      Articulador:
                        rowInfo.original.data.Nombre +
                        " " +
                        rowInfo.original.data.Paterno +
                        " " +
                        rowInfo.original.data.Materno,
                    });
                    //setArticulador(rowInfo.original.data.Nombre !==null ? rowInfo.original.data.Nombre:'' + rowInfo.original.data.Paterno!== null ?rowInfo.original.data.Paterno:'' + rowInfo.original.data.Materno !== null?rowInfo.original.data.Materno:'');

                    setflagEscogerArticulador(false);
                  },
                };
              }}
              onFetchData={(stateComp, instance) => {
                setloading(true);
                const token = getVars("Token").token;

                var array_filtros = [];
                array_filtros.push({ id: "idTipoUser", value: 9 });

                //if (stateComp.filtered.length != 0) {
                //  array_filtros.push({ id: 'NombreCompleto', value: stateComp.filtered[0].value })
                //}
                const datax = {
                  page: stateComp.page,
                  tipo: "and",
                  pageSize: stateComp.pageSize,
                  sorted: [{ id: "Nombre", desc: false }],
                  filtered: array_filtros,
                  NombreCompleto:
                    stateComp.filtered.length != 0
                      ? stateComp.filtered[0].value
                      : "",
                };

                EjecutarApi(datax, "getUsersArticuladores", token)
                  .then(function (res) {
                    return res;
                  })
                  .then((res) => {
                    setloading(false);
                    setTotalColonias(res.total);
                    setData(
                      res.data.map((prop, key) => {
                        return {
                          Articulador: `${
                            prop.Nombre !== null ? prop.Nombre : ""
                          } ${prop.Paterno !== null ? prop.Paterno : ""} ${
                            prop.Materno !== null ? prop.Materno : ""
                          }`,
                          data: prop,
                        };
                      })
                    );
                  })
                  .catch((e) => {
                    console.log("=Error[getArticuladores]: ", e);
                    setloading(false);
                    notify.show(
                      `Error al obtener colonias: ${e.errors}`,
                      "custom",
                      5000,
                      {
                        background: "#0857B6",
                        fontSize: 14,
                        text: "#FFFFFF",
                      }
                    );
                  });
              }}
            />
          </CardBody>
        </Card>
      </ReactBSAlert>
    </>
  );

  const mini_table = (
    <>
      <ReactBSAlert
        className="fontSize: 8px"
        style={{ display: "block", marginTop: "-250pt" }}
        //title="Here's a message!"
        onConfirm={() => setFlagEscogerColonia(false)}
        onCancel={() => setFlagEscogerColonia(false)}
        confirmBtnBsStyle="info"
      >
        <Card>
          <CardBody>
            <ReactTable
              className="-striped -highlight primary-pagination"
              data={data}
              noDataText="Debe Seleccionar un Municipio"
              columns={[
                {
                  Header: "Colonia",
                  minWidth: 10,
                  id: "Colonia",
                  accessor: (d) => d.Colonia,
                  filterAll: false,
                },
              ]}
              defaultPageSize={5}
              filterable
              loading={loading}
              showPagination={true}
              showPageJump={false}
              previousText="Anterior"
              nextText="Siguiente"
              canNextFromData={true}
              manual
              total={totalColonias}
              pages={Math.floor(totalColonias / 10)}
              pageSizeOptions={[10, 20, 25, 50, 100]}
              getTrProps={(state, rowInfo, column, instance) => {
                return {
                  onClick: (e) => {
                    setVale({
                      ...vale,
                      Colonia: rowInfo.original.Colonia,
                      CP: rowInfo.original.data.d_codigo,
                    });
                    setFlagEscogerColonia(false);
                  },
                };
              }}
              onFetchData={(stateComp, instance) => {
                setloading(true);
                const token = getVars("Token").token;

                var array_filtros = [];
                array_filtros.push({ id: "c_estado", value: 11 });
                array_filtros.push({ id: "c_mnpio", value: vale.idMunicipio });
                if (stateComp.filtered.length != 0) {
                  array_filtros.push({
                    id: "d_asenta",
                    value: stateComp.filtered[0].value,
                  });
                }
                const datax = {
                  page: stateComp.page,
                  tipo: "and",
                  pageSize: stateComp.pageSize,
                  sorted: [{ id: "d_asenta", desc: false }],
                  filtered: array_filtros,
                };

                EjecutarApi(datax, "getCP", token)
                  .then(function (res) {
                    return res;
                  })
                  .then((res) => {
                    setloading(false);
                    setTotalColonias(res.total);
                    setData(
                      res.data.map((prop, key) => {
                        return {
                          Colonia: prop.d_asenta,
                          data: prop,
                        };
                      })
                    );
                  })
                  .catch((e) => {
                    console.log("=Error[getCP]: ", e);
                    setloading(false);
                    notify.show(
                      `Error al obtener colonias: ${e.errors}`,
                      "custom",
                      5000,
                      {
                        background: "#0857B6",
                        fontSize: 14,
                        text: "#FFFFFF",
                      }
                    );
                  });
              }}
            />
          </CardBody>
        </Card>
      </ReactBSAlert>
    </>
  );

  //console.log('@#@#@--- Datos', optcompania[0].label);
  const customStyles = {
    content: {
      //top: "50%",
      //left: "50%",
      //right: "auto",
      //bottom: "auto",
      //marginRight: "-50%",
      //transform: "translate(-50%, -50%)",
      width: "800px",
    },
  };

  const alertaMensajeValidacion = (
    <ReactBSAlert
      style={{ display: "block", marginTop: "-100px", fontSize: "8px" }}
      title={"Error:  " + msgErrorValidacion + "!!!!"}
      confirmBtnText="Aceptar"
      //cancelBtnText="No"
      onConfirm={() => {
        setFlagMsgErrorValidacion(false);
      }}
      confirmBtnBsStyle="info sm btn-round"
    />
  );

  return (
    <>
      <Modal
        isOpen={close}
        toggle={true}
        style={customStyles}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Col md="12">
          <ModalHeader>
            <h7 md="12">Modificación del Vale: {vale.Folio}</h7>
          </ModalHeader>
          <ModalBody>
            <Form className="form-horizontal" id="TypeValidation">
              <Notifications />
              <h6> Datos Articulador</h6>
              <Row>
                <Col sm="10">
                  <FormGroup>
                    <Input
                      id="articu"
                      type="text"
                      value={vale.Articulador}
                      disabled
                    />
                  </FormGroup>
                </Col>
                <Col sm="2">
                  <FormGroup>
                    {vale.idStatus === 1 ? (
                      <Button
                        onClick={() => {
                          setflagEscogerArticulador(true);
                          console.log("ONCLIK");
                        }}
                      >
                        Buscar
                      </Button>
                    ) : (
                      <></>
                    )}
                    {flagEscogerArticulador ? mini_tableArticulador : <></>}
                  </FormGroup>
                </Col>
              </Row>

              <h6>Datos del Solicitante con Folio: <b>{vale.Folio}</b> </h6>
              <Row>
                <Col sm="3">
                  <FormGroup>
                    Fecha de Solicitud:
                    <span className="text-danger">*</span>
                    <DateTimeComponent
                      id="FechaSolicitud"
                      name="FechaSolicitud"
                      dateFormat="YYYY-MM-DD"
                      maxDate={new Date("2020-12-31")}
                      minDate={new Date("2020-05-01")}
                      timeFormat={false}
                      closeOnSelect={true}
                      onChange={(e) => handlesetFechaSolicitud(e)}
                      inputProps={{ className: "form-control" }}
                      defaultValue={vale.FechaSolicitud}
                    />
                  </FormGroup>
                </Col>
                <Col sm="4">
                  CURP:
                  <FormGroup>
                    <Input
                      maxLength={18}
                      name="CURP"
                      type="text"
                      value={vale.CURP}
                      onChange={(event) =>
                        setVale({
                          ...vale,
                          CURP: event.target.value.toUpperCase(),
                        })
                      }
                    />
                  </FormGroup>
                </Col>

                <Col sm="2">
                  <FormGroup>
                    <Button color="primary" onClick={getCURP}>
                      {loading ? (
                        <ImpulseSpinner
                          color={"#1261ff"}
                          size={30}
                          loading={loading}
                        />
                      ) : (
                        ""
                      )}

                      {loading ? <span>Validando</span> : <span>Validar</span>}
                    </Button>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col sm="4">
                  Nombre:<span className="text-danger">*</span>
                  <FormGroup>
                    <Input
                      name="nombre"
                      type="text"
                      value={vale.Nombre}
                      onChange={(event) =>
                        setVale({
                          ...vale,
                          Nombre: event.target.value.toUpperCase(),
                        })
                      }
                    />
                  </FormGroup>
                </Col>

                <Col sm="4">
                  <FormGroup>
                    Apellido Paterno:<span className="text-danger">*</span>
                    <Input
                      name="paterno"
                      type="text"
                      value={vale.Paterno}
                      onChange={(event) =>
                        setVale({
                          ...vale,
                          Paterno: event.target.value.toUpperCase(),
                        })
                      }
                    />
                  </FormGroup>
                </Col>

                <Col sm="4">
                  <FormGroup>
                    Apellido Materno:
                    <Input
                      name="materno"
                      type="text"
                      value={vale.Materno}
                      onChange={(event) =>
                        setVale({
                          ...vale,
                          Materno: event.target.value.toUpperCase(),
                        })
                      }
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col sm="3">
                  <FormGroup>
                    Sexo:
                    <Input
                      disabled
                      className="react-select primary"
                      classNamePrefix="react-select"
                      //defaultValue={vale.Sexo.value === "M"? { value: "M", label: "MASCULINO" } :{ value: "F", label: "FEMENINO" }}
                      id="sexo"
                      value={
                        vale.Sexo === "M"
                          ? "Masculino"
                          : vale.Sexo === ""
                          ? ""
                          : "Femenino"
                      }
                      defaultValue="Seleccione Sexo"
                    />
                  </FormGroup>
                </Col>

                <Col sm="4">
                  <FormGroup>
                    {" "}
                    Fecha de Nacimiento:
                    <span className="text-danger">*</span>
                    <ReactDatetime
                      id="FechaNacimiento"
                      name="FechaNacimiento"
                      dateFormat="YYYY-MM-DD"
                      timeFormat={false}
                      value={vale.FechaNacimiento}
                      closeOnSelect
                      onChange={(e) =>
                        setVale({
                          ...vale,
                          FechaNacimiento: moment(e._d).format("YYYY-MM-DD"),
                        })
                      }
                      inputProps={{ className: "form-control" }}
                    />
                  </FormGroup>
                </Col>
              </Row>

              <Row>
                  <Label sm="2">
                    Ocupación:<span className="text-danger">*</span>
                  </Label>
                  <Col sm="6">
                    <FormGroup>
                      <Select
                        className="react-select primary"
                        id="Ocupacion"
                        value={vale.Ocupacion}
                        onChange={(event) => setVale({...vale, Ocupacion: event})}
                        options={catOcupacion.data}
                        placeholder="seleccione su Ocupacion"
                      />
                    </FormGroup>
                  </Col>
                </Row>

                          {vale.Ocupacion.value === "Otro" &&<Row>
                            <Label sm="2">
                              Otra Ocupación:<span className="text-danger">*</span>
                            </Label>
                            <Col sm="6">
                              <FormGroup>
                                <Input
                                  name="OcupacionOtro"
                                  type="text"
                                  value={vale.OcupacionOtro}
                                  onChange={(event) =>
                                    setVale({
                                      ...vale,
                                      OcupacionOtro: event.target.value.toUpperCase(),
                                    })
                                  }
                                />
                              </FormGroup>
                            </Col>
                          </Row>}

              <h6>Datos de Ubicación Geográfica</h6>

              <Row>
                {municipios.length !== 0 ? (
                  <>
                    <Col sm="6">
                      <FormGroup>
                        Municipio:
                        <span className="text-danger">*</span>
                        <Select
                          className="react-select primary"
                          classNamePrefix="react-select"
                          id="municipio"
                          defaultValue={{
                            value: vale.idMunicipio.id,
                            label: vale.idMunicipio.Municipio,
                          }}
                          onChange={(event) => {
                            setVale({
                              ...vale,
                              idMunicipio: {
                                ...vale.idMunicipio,
                                id: event.value,
                                Municipio: event.label,
                              },
                            });
                            setNomMunicipio(event.value);
                            municipioChange(event.value);
                          }}
                          options={municipios}
                          placeholder="seleccione su municipio"
                        />
                      </FormGroup>
                    </Col>
                  </>
                ) : (
                  <>
                    <Col sm="2" style={Spinenerstyle}>
                      <RotateSpinner
                        color={"#1261ff"}
                        size={30}
                        loading={true}
                      />
                    </Col>
                  </>
                )}

                <Col sm="6">
                  <FormGroup>
                    Calle:<span className="text-danger">*</span>
                    <Input
                      id="calle"
                      type="text"
                      value={vale.Calle}
                      onChange={(event) =>
                        setVale({
                          ...vale,
                          Calle: event.target.value,
                        })
                      }
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col sm="2">
                  <FormGroup>
                    Número Exterior:
                    <span className="text-danger">*</span>
                    <Input
                      id="numExt"
                      type="text"
                      value={vale.NumExt}
                      onChange={(event) =>
                        setVale({
                          ...vale,
                          NumExt: event.target.value,
                        })
                      }
                    />
                  </FormGroup>
                </Col>

                <Col sm="2">
                  <FormGroup>
                    Número Interior
                    <Input
                      id="numInt"
                      type="text"
                      value={vale.NumInt}
                      onChange={(event) =>
                        setVale({
                          ...vale,
                          NumInt: event.target.value,
                        })
                      }
                    />
                  </FormGroup>
                </Col>

                <Col sm="6">
                  <FormGroup>
                    Colonia:<span className="text-danger">*</span>
                    <Input
                      name="Colonia"
                      type="text"
                      value={vale.Colonia}
                      onChange={(event) =>
                        setVale({
                          ...vale,
                          Colonia: event.target.value,
                        })
                      }
                    />
                  </FormGroup>
                </Col>
                <Col sm="2">
                  <Button
                    onClick={() => {
                      setFlagEscogerColonia(true);
                      console.log("ONCLIK");
                    }}
                  >
                    BUSCAR
                  </Button>
                  {flagEscogerColonia ? mini_table : <></>}
                </Col>
              </Row>
              <Row>
                <Col sm="6">
                  <FormGroup>
                    Localidad:<span className="text-danger">*</span>
                    <Select
                      className="react-select primary"
                      classNamePrefix="react-select"
                      id="Localidad"
                      defaultValue={{
                        value: vale.idLocalidad.id,
                        label: vale.idLocalidad.Nombre,
                      }}
                      onChange={(event) => {
                        setVale({
                          ...vale,
                          idLocalidad: {
                            ...vale.idLocalidad,
                            id: event.value,
                            Nombre: event.label,
                          },
                        });
                      }}
                      options={localidades}
                      placeholder="seleccione su localidad"
                    />
                  </FormGroup>
                </Col>

                <Col sm="4">
                  <FormGroup>
                    Codigo Postal:
                    <span className="text-danger">*</span>
                    <Input
                      name="cp"
                      type="number"
                      value={vale.CP}
                      onChange={(event) =>
                        setVale({
                          ...vale,
                          CP: event.target.value,
                        })
                      }
                    />
                  </FormGroup>
                </Col>
              </Row>

              <CardTitle tag="h6">Datos del Contacto</CardTitle>
              <Row>
                <Col sm="6">
                  <FormGroup>
                    Correo:
                    <Input
                      name="correo"
                      type="email"
                      value={vale.CorreoElectronico}
                      onChange={(event) =>
                        setVale({
                          ...vale,
                          CorreoElectronico: event.target.value,
                        })
                      }
                    />
                  </FormGroup>
                </Col>

                <Col sm="4">
                  <FormGroup>
                    Teléfono Fijo:
                    <Input
                      max="999999999"
                      id="TelFijo"
                      type="number"
                      onChange={(event) =>
                        setVale({
                          ...vale,
                          TelFijo: event.target.value,
                        })
                      }
                      value={vale.TelFijo}
                    />
                  </FormGroup>
                </Col>

                <Col sm="4">
                  <FormGroup>
                    Teléfono Recados:
                    <Input
                      maxLength={18}
                      id="telRecados"
                      type="number"
                      onChange={(event) =>
                        setVale({
                          ...vale,
                          TelRecados: event.target.value,
                        })
                      }
                      value={vale.TelRecados}
                    />
                  </FormGroup>
                </Col>

                <Col sm="4">
                  <FormGroup>
                    Teléfono Celular:
                    {/* <span className="text-danger">*</span> */}
                    <Input
                      max="999999999"
                      min="1"
                      id="celular"
                      type="number"
                      onChange={(event) =>
                        setVale({
                          ...vale,
                          TelCelular: event.target.value,
                        })
                      }
                      value={vale.TelCelular}
                    />
                  </FormGroup>
                </Col>
                <Col sm="4">
                  <FormGroup>
                    Compañía Celular:
                    {/* <span className="text-danger">*</span> */}
                    <Select
                      className="react-select primary"
                      classNamePrefix="react-select"
                      name="ciaCelular"
                      defaultValue={{
                        value: vale.Compania,
                        label: vale.Compania,
                      }}
                      onChange={(event) => {
                        setVale({
                          ...vale,
                          Compania: event.label,
                        });
                      }}
                      options={optcompania}
                      placeholder="Seleccione Compañía Telefónica"
                    />
                  </FormGroup>
                </Col>
              </Row>

              <CardTitle tag="h6">Datos del Contacto</CardTitle>
              <Row>
                            <Col sm="6">
                              <FormGroup>
                              Ingreso mensual percibido:
                                <Input
                                  name="IngresoPercibido"
                                  type="text" 
                                  pattern="[0-9]*"
                                  value={vale.IngresoPercibido}
                                  onChange={event => setVale({...vale, IngresoPercibido: event.target.value.replace(/\D/,'')})}
                                  
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col sm="6">
                              <FormGroup>
                              Otros Ingresos:
                                <Input
                                  name="OtrosIngresos"
                                  type="text" 
                                  pattern="[0-9]*"
                                  value={vale.OtrosIngresos}
                                  onChange={event => setVale({...vale, OtrosIngresos: event.target.value.replace(/\D/,'')})}
                                  
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col sm="6">
                              <FormGroup>
                              Número de personas dependientes del ingreso mensual total:
                                <Input
                                  name="NumeroPersonas"
                                  type="text" 
                                  pattern="[0-9]*"
                                  value={vale.NumeroPersonas}
                                  onChange={event => setVale({...vale, NumeroPersonas: event.target.value.replace(/\D/,'')})}
                                  
                                />
                              </FormGroup>
                            </Col>
                          </Row>

              {/* {CharacterDropDown(vale.Sexo)}  */}
            </Form>
          </ModalBody>
          <ModalFooter className="text-center">
          {_menudelete.Eliminar===1?
          <Button style={{
            backgroundColor: '#ff0000',
            color: '#FFFFFF'

          }} onClick={() => 
            setFlagDelete(true)
            
          }>
              Eliminar Solicitud
            </Button>:
            <></>}
            <Button color="primary" onClick={(e) => setban(true)}>
              {<ImpulseSpinner color={"#1261ff"} size={30} loading={flagSet} />}

              {flagSet ? <span>Registrando..</span> : <span>Guardar</span>}
            </Button>
            <Button color="danger" onClick={() => handleonHide(false)}>
              Cancelar
            </Button>
            
            {ban ? alerta : <> </>}
            {flagDelete ? alertaDelete : <> </>}
            {flagMsgErrorValidacion ? alertaMensajeValidacion : <></>}
          </ModalFooter>
        </Col>
      </Modal>
    </>
  );
};
export default ValesUpdate;

/**
 * <Link to="vales">
                <Button color="success">Regresar</Button>
              </Link>
 *  <Sidebar {...props} bgColor="black" activeColor="info" />
        <div className="main-panel">
          <Navbar {...props} handleMiniClick={handleMiniClick} />
 * defaultValue= {vale.CompaniaCelular==''?[{value: "0", label: "Seleccione Compañia Telefónica"}]:
                                                                                        vale.CompaniaCelular}
 * 
 * (vale.Sexo === 'F') ? ({ label: 'Femenino', value: 'F' }) : (vale.Sexo === 'M') ? ({ label: 'Masculino', value: 'M' }): ({ label: 'Seleccione Sexo', value: '' })
 */
