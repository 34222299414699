import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";
import EjecutarApi from "../Consultas/Consultas";
import {
  FormatedSolicitudFormProyectos,
  SolicitudFormProyectos,
} from "../data/interfaces/Cedula";
import { getVars } from "../GlobalStorage";
import {
  isEmptyOrInvalidString,
  isEmptyOrNullObject,
  isNullOrUndefined,
} from "../utils/emptyValidations";
import { SolicitudValidatorProyectos } from "../data/validators/Cedula";

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardSubtitle,
  CardText,
  CardTitle,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Spinner,
} from "reactstrap";
import Notifications from "react-notify-toast";
import DateTimeComponent from "../components/DateTimeComponent";
import swal from "sweetalert";

const SolicitudProyectosAnterior = (props) => {
  const { data, backSolicitud, seeCedula } = props;
  const history = useHistory();
  const Datos = data ? data : null;
  const [userData, setUserData] = useState();
  const token = getVars("Token").token;
  const [menu, setMenu] = useState({
    Ver: 0,
    Agregar: 0,
    Editar: 0,
    Eliminar: 0,
    Seguimiento: 0,
    Exportar: 0,
    Imprimir: 0,
    ViewAll: 0,
    idTipoUser: 0,
  });
  const [form, setForm] = useState(SolicitudFormProyectos);
  const [cedulaData, setCedulaData] = useState({
    id: -1,
    ListaParaEnviar: 0,
  });
  const [loadingCatalogs, setLoadingCatalogs] = useState(true);
  const [loadingForm, setIsLoadingForm] = useState(true);
  const [isForEdit, setIsForEdit] = useState(false);
  const [catalogs, setCatalogs] = useState({
    entidades: [],
    archivos_clasificacion: [],
    cat_parentesco_jefe_hogar: [],
    cat_parentesco_tutor: [],
    cat_situacion_actual: [],
    cat_estado_civil: [],
    municipios: [],
    localidades: [],
  });

  const [errors, setErrors] = useState({
    error: false,
    seccion: -1,
    message: "",
  });
  const [datosTarjeta, setDatostarjeta] = useState({});
  const [loadingFolio, setLoadingFolio] = useState(false);
  const [loadingCURP, setLoadingCURP] = useState(false);
  const [formLoaded, setFormLoaded] = useState(false);
  const [MunLoaded, setMunLoaded] = useState(false);
  const [LocLoaded, setLocLoaded] = useState(false);

  useEffect(() => {
    setUserData(getVars("Token"));
  }, []);

  useEffect(() => {
    if (isNullOrUndefined(userData)) return;
    const menus = userData.menu;
    const currentMenu = menus.find((menu) => menu.idMenu === 15);
    if (isNullOrUndefined(currentMenu) || currentMenu.Ver < 1) {
      swal({
        title: "Atención",
        text: "No tiene permisos para este módulo, contacte al administrador",
        icon: "warning",
      });
      history.push("/not-found");
      return;
    }
    setMenu({
      Ver: currentMenu.Ver,
      Agregar: currentMenu.Agregar,
      Editar: currentMenu.Editar,
      Eliminar: currentMenu.Eliminar,
      Seguimiento: currentMenu.Seguimiento,
      Exportar: currentMenu.Exportar,
      Imprimir: currentMenu.Imprimir,
      ViewAll: currentMenu.ViewAll,
      idTipoUser: userData.user.idTipoUser.id,
    });
  }, [userData]);

  useEffect(() => {
    EjecutarApi({}, "getCatalogsCedula", token, "GET")
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.success && res.results) {
          let cat = res.data;
          cat.localidades = [];
          setCatalogs(cat);
        } else {
          swal({
            title: "Ha ocurrido un error",
            text: res.errors,
            icon: "warning",
          });
        }
        setLoadingCatalogs(false);
      })
      .catch((error) => {
        setLoadingCatalogs(false);
        swal({
          title: "Ha ocurrido un error",
          text: error.errors,
          icon: "warning",
        });
      });
    setMunLoaded(true);
    setLocLoaded(true);
  }, [token]);

  useEffect(() => {
    setIsLoadingForm(true);
    let data = { ...form };
    data.Files = [];
    data.ArchivosClasificacion = [];
    if (Datos !== null) {
      setIsForEdit(true);
      setCedulaData({
        id: Datos.idCedula ? Datos.idCedula : -1,
        ListaParaEnviar: Datos.ListaParaEnviar ? Datos.ListaParaEnviar : 0,
      });
      data = FormatedSolicitudFormProyectos(Datos);
      setIsLoadingForm(false);
    }
    setTimeout(() => {
      setForm(data);
      setIsLoadingForm(false);
      setFormLoaded(true);
    }, 4000);
  }, [Datos]);

  useEffect(() => {
    if (form.idEntidadVive === 12) {
      let formCatalogs = {};
      if (catalogs.municipios.length > 0) {
        const MunicipioVive = catalogs.municipios.find(
          (x) => x.label === form.MunicipioVive
        );
        if (!isNullOrUndefined(MunicipioVive)) {
          formCatalogs.MunicipioVive = MunicipioVive.value;
        }
      }
      if (catalogs.localidades.length > 0) {
        const LocalidadVive = catalogs.localidades.find(
          (x) => x.label === form.LocalidadVive
        );
        if (!isNullOrUndefined(LocalidadVive)) {
          formCatalogs.LocalidadVive = LocalidadVive.value;
        }
      }
      setForm({
        ...form,
        ...formCatalogs,
      });
    }
  }, [
    catalogs.municipios,
    catalogs.localidades,
    loadingForm,
    formLoaded,
    Datos,
  ]);

  const getLocalities = (municipio) => {
    EjecutarApi(
      {},
      `cedula/getLocalidadesByMunicipio/${municipio}`,
      token,
      "GET"
    )
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.success && res.results) {
          setCatalogs({
            ...catalogs,
            localidades: res.data,
          });
        } else {
          swal({
            title: "Ha ocurrido un error",
            text: res.errors,
            icon: "warning",
          });
        }
      })
      .catch((error) => {
        swal({
          title: "Ha ocurrido un error",
          text: error.errors,
          icon: "warning",
        });
      });
  };

  const validateData = async (type, value) => {
    var urlencoded = new URLSearchParams();
    let folio = type === "Folio" ? value : "";
    let curp = type === "CURP" ? value.toUpperCase() : "";
    urlencoded.append("token", "dfee39bd-acd2-4c95-514d-74384d530496");
    urlencoded.append("Folio", folio);
    urlencoded.append("CURP", curp);

    var requestInfo = {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/x-www-form-urlencoded",
      }),
      body: urlencoded,
      redirect: "follow",
    };
    setLoadingCURP(true);
    setLoadingFolio(true);
    fetch(
      "https://peb.guanajuato.gob.mx/Tarjeta/api/APIBusquedaDatos/SETBusquedaCLUB",
      requestInfo
    )
      .then((response) => {
        return response.json();
      })
      .then((dataRS) => {
        if (dataRS.result == 0) {
          if (type === "CURP") {
            validateCurp(value);
          } else {
            setLoadingCURP(false);
            swal({
              title: "¡Atención!",
              text: dataRS.error,
              icon: "warning",
            });
          }
          setLoadingFolio(false);
        } else {
          setLoadingFolio(false);
          setLoadingCURP(false);
          setDatostarjeta(dataRS.data);
        }
      })
      .catch((error) => {
        setLoadingFolio(false);
        setLoadingCURP(false);
        setLoadingCatalogs(false);
        swal({
          title: "Ha ocurrido un error",
          text: error.error,
          icon: "warning",
        });
      });
  };

  const validateCurp = (CURP) => {
    if (CURP.length < 18) {
      return;
    }
    fetch(
      `https://seguimiento.guanajuato.gob.mx/apiinformacionsocial/api/renapo/porcurp/pL@t_1n|Run$28/${CURP}/7`
    )
      .then((res) => res.json())
      .then((dataRS) => {
        if (dataRS.Mensaje === "OK") {
          const response = dataRS.Resultado;
          const fechaNacimiento = moment(response.fechNac, "DD/MM/YYYY").format(
            "YYYY-MM-DD"
          );
          let data = {
            Nombre: response.nombres,
            Paterno: response.apellido1,
            Materno: response.apellido2,
            Sexo: response.sexo,
            FechaNacimiento: fechaNacimiento,
            Edad: getAgeByDate(fechaNacimiento),
            idEntidadNacimiento: getEstadoNacimiento(response.cveEntidadNac),
            FolioTarjetaImpulso: "",
            TarjetaImpulso: "",
            ContactoTarjetaImpulso: 0,
          };
          setForm({
            ...form,
            ...data,
          });
          setDatostarjeta({});
        } else {
          swal({
            title: "Ha ocurrido un error",
            text: dataRS.Mensaje,
            icon: "warning",
          });
        }
        setLoadingCURP(false);
      })
      .catch((error) => {
        setLoadingCURP(false);
        setLoadingCatalogs(false);
        swal({
          title: "Ha ocurrido un error",
          text: error.error,
          icon: "warning",
        });
      });
  };

  function verifyCURP(CURP) {
    return new Promise((resolve) => {
      let flag;
      if (CURP.length < 18) {
        resolve(false);
      }
      fetch(
        `https://seguimiento.guanajuato.gob.mx/apiinformacionsocial/api/renapo/porcurp/pL@t_1n|Run$28/${CURP}/7`
      )
        .then((res) => res.json())
        .then((dataRS) => {
          if (dataRS.Mensaje != "OK") {
            resolve(false);
          } else resolve(true);
        })
        .catch((error) => {
          resolve(false);
        });
    });
  }

  useEffect(() => {
    if (!isEmptyOrNullObject(datosTarjeta)) {
      let data = {
        CURP: datosTarjeta[0].CURP,
        Sexo: datosTarjeta[0].Sexo,
        Edad: getAgeByDate(datosTarjeta[0].FechaNacimiento),
        FechaNacimiento: moment(
          datosTarjeta[0].FechaNacimiento,
          "DD/MM/YYYY"
        ).format("YYYY-MM-DD"),
        FolioTarjetaImpulso: datosTarjeta[0].Folio ? datosTarjeta[0].Folio : "",
        Nombre: datosTarjeta[0].Nombres,
        Paterno: datosTarjeta[0].PrimerApellido,
        Materno: datosTarjeta[0].SegundoApellido,
      };
      if (
        !isEmptyOrInvalidString(datosTarjeta[0].Folio) ||
        !isEmptyOrInvalidString(datosTarjeta[0].CURP)
      ) {
        data.TarjetaImpulso = 3;
        data.ContactoTarjetaImpulso = 0;
      }
      data.idEntidadNacimiento = getEstadoNacimiento(
        datosTarjeta[0].EntidadNacimiento
      );
      setForm({
        ...form,
        ...data,
      });
    }
  }, [datosTarjeta]);

  useEffect(() => {
    if (!isNaN(parseInt(form.MunicipioVive))) {
      getLocalities(form.MunicipioVive);
    }
  }, [form.MunicipioVive]);

  const getAgeByDate = (fechaNacimiento) => {
    if (fechaNacimiento) {
      let anio = fechaNacimiento.split("/");
      let posicion = 2;
      if (anio.length < 2) {
        anio = fechaNacimiento.split("-");
        posicion = 0;
      }
      return moment().diff(anio[posicion], "years");
    } else return 0;
  };

  const getEstadoNacimiento = (estadoCurp) => {
    const found = catalogs.entidades.find(
      (element) => element.Clave_CURP === estadoCurp
    );
    if (!isNullOrUndefined(found)) {
      return found.value;
    }
    return "";
  };

  const handleChangeEstadoVive = (value) => {
    setForm({
      ...form,
      idEntidadVive: value,
      LocalidadVive: "",
      MunicipioVive: "",
    });
  };

  const saveSolicitud = async (redirect = false) => {
    const hasErrors = SolicitudValidatorProyectos(form);
    setErrors(hasErrors);
    if (!hasErrors.error) {
      setIsLoadingForm(true);
      let url;
      if (isForEdit) {
        url = "updateSolicitudProyectos";
      } else {
        url = "createSolicitudProyectos";
        form.Formato = 1;
      }
      if (form.CURP) {
        const valida = await verifyCURP(form.CURP);
        if (!valida) {
          swal({
            title: "Alerta",
            text: "El CURP ingresado es incorrecto",
            icon: "warning",
          });
          setIsLoadingForm(false);
          return;
        }
      }

      if (form.idEntidadVive !== "" && form.idEntidadVive === 12) {
        form.MunicipioVive =
          form.MunicipioVive !== "" && form.MunicipioVive != null
            ? catalogs.municipios.find(
                (x) => x.value === parseInt(form.MunicipioVive)
              ).label
            : "";
        form.LocalidadVive =
          form.LocalidadVive !== "" && form.LocalidadVive != null
            ? catalogs.localidades.find(
                (x) => x.value === parseInt(form.LocalidadVive)
              ).label
            : "";
      }
      form.NoIntVive = form.NoIntVive === "" ? "S/N" : form.NoIntVive;
      EjecutarApi(form, url, token)
        .then(function (res) {
          return res;
        })
        .then((res) => {
          if (res.success && res.results) {
            if (redirect) {
              const idSolicitud = isForEdit ? form.id : res.data;
              const { id, idEstatus, ...formatedForm } = form;
              let data = { ...formatedForm, idSolicitud: idSolicitud };
              if (cedulaData.id > -1) {
                data = {
                  idCedula: cedulaData.id,
                };
              }
              viewCedula(data);
            } else {
              backSolicitud();
            }
            swal({
              title: "Guardado con éxito",
              text: "",
              icon: "success",
            });
            setIsLoadingForm(false);
          } else {
            swal({
              title: "Ha ocurrido un error",
              text: res.errors,
              icon: "warning",
            });
          }
          setIsLoadingForm(false);
        })
        .catch((error) => {
          setIsLoadingForm(false);
          swal({
            title: "Ha ocurrido un error",
            text: error.message,
            icon: "warning",
          });
        });
    }
  };

  const viewCedula = (data = null) => {
    seeCedula(data);
  };

  const deleteAlert = () => {
    swal({
      title: "¡Atención!",
      text: "¿Está seguro de eliminar la solicitud?",
      dangerMode: true,
      icon: "warning",
      buttons: ["Cancelar", true],
    }).then((response) => {
      if (response) {
        deleteSolicitud();
      }
    });
  };

  const deleteSolicitud = () => {
    setIsLoadingForm(true);
    EjecutarApi({ id: form.id }, "deleteSolicitudProyectos", token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.success && res.results) {
          swal({
            title: "¡Eliminada con éxito!",
            icon: "success",
          });
          backSolicitud();
        } else {
          swal({
            title: "Ha ocurrido un error",
            text: res.message,
            icon: "warning",
          });
        }
        setIsLoadingForm(false);
      })
      .catch((error) => {
        setIsLoadingForm(false);
        swal({
          title: "Ha ocurrido un error",
          text: error.message,
          icon: "warning",
        });
      });
  };

  const handleChangeMunicipio = (municipio) => {
    if (municipio !== null && municipio !== "") {
      getLocalities(municipio);
      setForm({
        ...form,
        MunicipioVive: municipio,
      });
    } else {
      setForm({
        ...form,
        MunicipioVive: "",
        LocalidadVive: "",
      });
    }
  };

  return (
    <>
      <Notifications />
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <Button color="success" onClick={backSolicitud}>
                  Regresar
                </Button>
              </CardHeader>
              <CardBody>
                <CardTitle tag="h5" style={{ textAlign: "center" }}>
                  {isForEdit
                    ? form.idEstatus == 8
                      ? "Solicitud Enviada"
                      : "Editar solicitud"
                    : "Nueva Solicitud"}
                  {loadingForm && <Spinner />}
                </CardTitle>
                {formLoaded && MunLoaded && LocLoaded && (
                  <>
                    <Form>
                      <Col
                        sm="12"
                        style={{ textAlign: "center", color: "red" }}
                      >
                        {cedulaData.id > 0 && (
                          <CardText>
                            La solicitud ya cuenta con una cédula, no se puede
                            editar
                          </CardText>
                        )}
                      </Col>
                      <hr
                        style={{
                          backgroundColor:
                            errors.error && errors.seccion === 0
                              ? "red"
                              : "#E8E8E8",
                        }}
                      />
                      <Row>
                        <Col sm="3" xs="12">
                          <FormGroup>
                            <Label>
                              Fecha solicitud
                              <span className="text-danger">*</span>
                            </Label>
                            <DateTimeComponent
                              id="FechaSolicitud"
                              name="FechaSolicitud"
                              dateFormat="YYYY-MM-DD"
                              maxDate={moment()
                                .add(1, "days")
                                .format("YYYY-MM-DD")}
                              minDate={new Date("2020-01-01")}
                              timeFormat={false}
                              closeOnSelect={true}
                              value={form.FechaSolicitud}
                              onChange={(e) =>
                                setForm({
                                  ...form,
                                  FechaSolicitud: moment(e._d).format(
                                    "YYYY-MM-DD"
                                  ),
                                })
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col sm="3" xs="12">
                          <FormGroup>
                            <Label>Folio tarjeta impulso</Label>
                            <Input
                              id="folio"
                              type="text"
                              value={form.FolioTarjetaImpulso}
                              maxLength={10}
                              onChange={(event) =>
                                setForm({
                                  ...form,
                                  FolioTarjetaImpulso: event.target.value,
                                })
                              }
                            />
                            {cedulaData.id < 0 && (
                              <Button
                                size="sm"
                                color="primary"
                                disabled={form.FolioTarjetaImpulso.length === 0}
                                onClick={() =>
                                  validateData(
                                    "Folio",
                                    form.FolioTarjetaImpulso
                                  )
                                }
                              >
                                Validar {loadingFolio && <Spinner size="sm" />}
                              </Button>
                            )}
                          </FormGroup>
                        </Col>
                        <Col sm="3" xs="12">
                          <FormGroup>
                            <Label>
                              CURP<span className="text-danger">*</span>
                            </Label>
                            <Input
                              name="CURP"
                              type="text"
                              value={form.CURP}
                              maxLength={18}
                              style={{ textTransform: "uppercase" }}
                              onChange={(e) =>
                                setForm({
                                  ...form,
                                  CURP: e.target.value.toUpperCase(),
                                })
                              }
                            />
                            {cedulaData.id < 0 && (
                              <Button
                                size="sm"
                                color="primary"
                                disabled={form.CURP.length != 18}
                                onClick={() => validateData("CURP", form.CURP)}
                              >
                                Validar {loadingCURP && <Spinner size="sm" />}
                              </Button>
                            )}
                          </FormGroup>
                        </Col>
                        <Col sm="3" xs="12">
                          <FormGroup>
                            <Label>
                              Folio de solicitud
                              <span className="text-danger">*</span>
                            </Label>
                            <Input
                              name="Folio"
                              type="text"
                              value={form.Folio}
                              style={{ textTransform: "uppercase" }}
                              onChange={(e) =>
                                setForm({
                                  ...form,
                                  Folio: e.target.value.toUpperCase(),
                                })
                              }
                            />
                          </FormGroup>
                        </Col>
                        {errors.error && errors.seccion === 0 && (
                          <Col xs="12">
                            <CardText
                              style={{ color: "red", textAlign: "center" }}
                            >
                              {errors.message}
                            </CardText>
                          </Col>
                        )}
                      </Row>
                      <hr
                        style={{
                          backgroundColor:
                            errors.error && errors.seccion === 1
                              ? "red"
                              : "#E8E8E8",
                        }}
                      />
                      <CardSubtitle
                        className="mb-2 text-muted"
                        tag="h6"
                        style={{ textAlign: "center" }}
                      >
                        I. DATOS DEL SOLICITANTE
                      </CardSubtitle>
                      <Row>
                        <Col sm="4" xs="12">
                          <FormGroup>
                            <Label>
                              Nombre(s) <span className="text-danger">*</span>
                            </Label>
                            <Input
                              id="nombre"
                              type="text"
                              value={form.Nombre}
                              onChange={(event) =>
                                setForm({
                                  ...form,
                                  Nombre: event.target.value
                                    .toUpperCase()
                                    .replace(/\d/, ""),
                                })
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col sm="4" xs="12">
                          <FormGroup>
                            <Label>
                              Primer apellido
                              <span className="text-danger">*</span>
                            </Label>
                            <Input
                              id="paterno"
                              type="text"
                              value={form.Paterno}
                              onChange={(event) =>
                                setForm({
                                  ...form,
                                  Paterno: event.target.value
                                    .toUpperCase()
                                    .replace(/\d/, ""),
                                })
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col sm="4" xs="12">
                          <FormGroup>
                            <Label>
                              Segundo apellido
                              <span className="text-danger">*</span>
                            </Label>
                            <Input
                              id="nombre"
                              type="text"
                              value={form.Materno}
                              onChange={(event) =>
                                setForm({
                                  ...form,
                                  Materno: event.target.value
                                    .toUpperCase()
                                    .replace(/\d/, ""),
                                })
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col sm="4" xs="12">
                          <FormGroup>
                            <Label>
                              Fecha de nacimiento
                              <span className="text-danger">*</span>
                            </Label>
                            <DateTimeComponent
                              id="FechaNacimiento"
                              name="FechaNacimiento"
                              dateFormat="YYYY-MM-DD"
                              maxDate={moment()
                                .add(1, "days")
                                .format("YYYY-MM-DD")}
                              minDate={new Date("1920-01-01")}
                              value={form.FechaNacimiento}
                              timeFormat={false}
                              closeOnSelect={true}
                              onChange={(e) =>
                                setForm({
                                  ...form,
                                  FechaNacimiento: moment(e._d).format(
                                    "YYYY-MM-DD"
                                  ),
                                })
                              }
                              inputProps={{ readOnly: true }}
                            />
                          </FormGroup>
                        </Col>
                        <Col sm="4" xs="12">
                          <FormGroup>
                            <Label>
                              Edad<span className="text-danger">*</span>
                            </Label>
                            <Input
                              id="nombre"
                              type="text"
                              value={form.Edad}
                              maxLength={3}
                              pattern="[0-9]*"
                              onChange={(event) =>
                                setForm({
                                  ...form,
                                  Edad: event.target.value.replace(/\D/, ""),
                                })
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col sm="4" xs="12">
                          <FormGroup>
                            <Label check>
                              Sexo<span className="text-danger">*</span>
                            </Label>
                            <Row>
                              <Col style={{ marginLeft: "10%" }}>
                                <FormGroup check>
                                  <Input
                                    name="Hombre"
                                    type="radio"
                                    checked={
                                      form.Sexo === "H" || form.Sexo === ""
                                    }
                                    onChange={() =>
                                      setForm({
                                        ...form,
                                        Sexo: "H",
                                      })
                                    }
                                  />{" "}
                                  <Label check>Hombre</Label>
                                </FormGroup>
                              </Col>
                              <Col>
                                <FormGroup check>
                                  <Input
                                    name="Mujer"
                                    type="radio"
                                    checked={form.Sexo === "M"}
                                    onChange={() =>
                                      setForm({
                                        ...form,
                                        Sexo: "M",
                                      })
                                    }
                                  />{" "}
                                  <Label check>Mujer</Label>
                                </FormGroup>
                              </Col>
                            </Row>
                          </FormGroup>
                        </Col>
                        <Col sm="4" xs="12">
                          <FormGroup>
                            <Label>
                              Entidad de nacimiento
                              <span className="text-danger">*</span>
                            </Label>
                            <Input
                              name="EntidadNacimiento"
                              type="select"
                              value={form.idEntidadNacimiento}
                              onChange={(e) =>
                                setForm({
                                  ...form,
                                  idEntidadNacimiento: e.target.value,
                                })
                              }
                            >
                              <option key={"default"} value={""}></option>
                              {catalogs.entidades.map((entidad) => (
                                <option
                                  key={entidad.value}
                                  value={entidad.value}
                                >
                                  {entidad.label}
                                </option>
                              ))}
                            </Input>
                          </FormGroup>
                        </Col>
                        <Col sm="4" xs="12">
                          <FormGroup>
                            <Label>RFC</Label>
                            <Input
                              name="CURP"
                              type="text"
                              value={form.RFC}
                              style={{ textTransform: "uppercase" }}
                              onChange={(e) =>
                                setForm({
                                  ...form,
                                  RFC: e.target.value.toUpperCase(),
                                })
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col sm="4" xs="12">
                          <FormGroup>
                            <Label>
                              Estado civil
                              <span className="text-danger">*</span>
                            </Label>
                            <Input
                              name="EstadoCivil"
                              type="select"
                              value={form.idEstadoCivil}
                              onChange={(e) =>
                                setForm({
                                  ...form,
                                  idEstadoCivil: e.target.value,
                                })
                              }
                            >
                              <option key={"default"} value={""}></option>

                              {catalogs.cat_estado_civil.map((element) => (
                                <option
                                  key={element.value}
                                  value={element.value}
                                >
                                  {element.label}
                                </option>
                              ))}
                            </Input>
                          </FormGroup>
                        </Col>
                        <Col sm="4" xs="12">
                          <FormGroup>
                            <Label>
                              Parentesco con Jefa(e) del hogar:
                              <span className="text-danger">*</span>
                            </Label>
                            <Input
                              name="JefeHogar"
                              type="select"
                              value={form.idParentescoJefeHogar}
                              onChange={(e) =>
                                setForm({
                                  ...form,
                                  idParentescoJefeHogar: e.target.value,
                                })
                              }
                            >
                              <option key={"default"} value={""}></option>

                              {catalogs.cat_parentesco_jefe_hogar.map(
                                (element) => (
                                  <option
                                    key={element.value}
                                    value={element.value}
                                  >
                                    {element.label}
                                  </option>
                                )
                              )}
                            </Input>
                          </FormGroup>
                        </Col>
                        <Col sm="2" xs="6">
                          <FormGroup>
                            <Label>
                              Numero de hijos:
                              <span className="text-danger">*</span>
                            </Label>
                            <Input
                              name="NumHijos"
                              type="text"
                              value={form.NumHijos}
                              maxLength={2}
                              pattern="[0-9]*"
                              onChange={(e) =>
                                setForm({
                                  ...form,
                                  NumHijos: e.target.value.replace(/\D/, ""),
                                })
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col sm="2" xs="6">
                          <FormGroup>
                            <Label>
                              Numero de hijas:
                              <span className="text-danger">*</span>
                            </Label>
                            <Input
                              name="NumHijas"
                              type="text"
                              maxLength={2}
                              pattern="[0-9]*"
                              value={form.NumHijas}
                              onChange={(e) =>
                                setForm({
                                  ...form,
                                  NumHijas: e.target.value.replace(/\D/, ""),
                                })
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col sm="4" xs="12">
                          <FormGroup>
                            <Label>
                              Pueblo o comunidad indígena{"  "}
                              <span className="text-danger">
                                (DEJAR VACÍO SI NO APLICA):
                              </span>
                            </Label>
                            <Input
                              name="ComunidadIndigena"
                              type="text"
                              value={form.ComunidadIndigena}
                              onChange={(e) =>
                                setForm({
                                  ...form,
                                  ComunidadIndigena: e.target.value
                                    .toUpperCase()
                                    .replace(/\d/, ""),
                                })
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col sm="4" xs="12">
                          <FormGroup>
                            <Label>
                              Lengua indígena o dialecto{"  "}
                              <span className="text-danger">
                                (DEJAR VACÍO SI NO APLICA):
                              </span>
                            </Label>
                            <Input
                              name="Dialecto"
                              type="text"
                              value={form.Dialecto}
                              onChange={(e) =>
                                setForm({
                                  ...form,
                                  Dialecto: e.target.value
                                    .toUpperCase()
                                    .replace(/\d/, ""),
                                })
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col sm="4" xs="12">
                          <FormGroup>
                            <Label>
                              ¿Se considera afro mexicano(a) o afrodescendiente?
                              :<span className="text-danger">*</span>
                            </Label>
                            <Row>
                              <Col style={{ marginLeft: "10%" }}>
                                <FormGroup check>
                                  <Input
                                    name="noAfro"
                                    type="radio"
                                    checked={
                                      form.Afromexicano === 0 ||
                                      form.Afromexicano === ""
                                    }
                                    onChange={() =>
                                      setForm({
                                        ...form,
                                        Afromexicano: 0,
                                      })
                                    }
                                  />{" "}
                                  <Label check>No</Label>
                                </FormGroup>
                              </Col>
                              <Col>
                                <FormGroup check>
                                  <Input
                                    name="siAfro"
                                    type="radio"
                                    checked={form.Afromexicano === 1}
                                    onChange={() =>
                                      setForm({
                                        ...form,
                                        Afromexicano: 1,
                                      })
                                    }
                                  />{" "}
                                  <Label check>Sí</Label>
                                </FormGroup>
                              </Col>
                            </Row>
                          </FormGroup>
                        </Col>
                        <Col sm="4" xs="12">
                          <FormGroup>
                            <Label>
                              Por su situación actual, usted se considera:
                              <span className="text-danger">*</span>
                            </Label>
                            <Input
                              name="SituacionActual"
                              type="select"
                              value={form.idSituacionActual}
                              onChange={(e) =>
                                setForm({
                                  ...form,
                                  idSituacionActual: e.target.value,
                                })
                              }
                            >
                              <option key={"default"} value={""}></option>

                              {catalogs.cat_situacion_actual.map((element) => (
                                <option
                                  key={element.value}
                                  value={element.value}
                                >
                                  {element.label}
                                </option>
                              ))}
                            </Input>
                          </FormGroup>
                        </Col>
                        <Col sm="4" xs="12">
                          <FormGroup>
                            <Label>
                              ¿Usted está interesada(o) en obtener la Tarjeta
                              Impulso?
                              <span className="text-danger">*</span>
                            </Label>
                            <Input
                              name="TarjetaImpulso"
                              type="select"
                              value={form.TarjetaImpulso}
                              disabled={
                                datosTarjeta.Persona_Folio &&
                                datosTarjeta.Persona_Folio !== ""
                              }
                              onChange={(e) =>
                                setForm({
                                  ...form,
                                  TarjetaImpulso: e.target.value,
                                })
                              }
                            >
                              {[
                                { value: "", label: "" },
                                { value: 2, label: "No" },
                                { value: 1, label: "Si" },
                                { value: 3, label: "Ya cuenta con tarjeta" },
                              ].map((element) => (
                                <option
                                  key={element.label}
                                  value={element.value}
                                >
                                  {element.label}
                                </option>
                              ))}
                            </Input>
                          </FormGroup>
                        </Col>
                        <Col sm="4" xs="12">
                          <FormGroup>
                            <Label>
                              ¿Autoriza que un servidor(a) publico(a) lo
                              contacte para tramitar la Tarjeta Impulso? :
                              <span className="text-danger">*</span>
                            </Label>
                            <Row>
                              <Col style={{ marginLeft: "10%" }}>
                                <FormGroup check>
                                  <Input
                                    name="TarjetaNo"
                                    type="radio"
                                    checked={form.ContactoTarjetaImpulso === 0}
                                    disabled={
                                      datosTarjeta.Persona_Folio &&
                                      datosTarjeta.Persona_Folio !== ""
                                    }
                                    onChange={() =>
                                      setForm({
                                        ...form,
                                        ContactoTarjetaImpulso: 0,
                                      })
                                    }
                                  />{" "}
                                  <Label check>No</Label>
                                </FormGroup>
                              </Col>
                              <Col>
                                <FormGroup check>
                                  <Input
                                    name="TarjetaSi"
                                    type="radio"
                                    checked={
                                      datosTarjeta.Persona_Folio &&
                                      form.ContactoTarjetaImpulso === 1
                                    }
                                    onChange={() =>
                                      setForm({
                                        ...form,
                                        ContactoTarjetaImpulso: 1,
                                      })
                                    }
                                  />{" "}
                                  <Label check>Sí</Label>
                                </FormGroup>
                              </Col>
                            </Row>
                          </FormGroup>
                        </Col>
                        {errors.error && errors.seccion === 1 && (
                          <Col xs="12">
                            <CardText
                              style={{ color: "red", textAlign: "center" }}
                            >
                              {errors.message}
                            </CardText>
                          </Col>
                        )}
                      </Row>
                      <hr
                        style={{
                          backgroundColor:
                            errors.error && errors.seccion === 2
                              ? "red"
                              : "#E8E8E8",
                        }}
                      />
                      <CardSubtitle
                        className="mb-2 text-muted"
                        tag="h6"
                        style={{ textAlign: "center" }}
                      >
                        II. DATOS DE CONTACTO
                      </CardSubtitle>
                      <Row>
                        <Col sm="3" xs="12">
                          <FormGroup>
                            <Label>
                              Teléfono celular:
                              <span className="text-danger">*</span>
                            </Label>
                            <Input
                              name="telefono"
                              type="text"
                              pattern="[0-9]*"
                              maxLength={10}
                              value={form.Celular}
                              onChange={(e) =>
                                setForm({
                                  ...form,
                                  Celular: e.target.value.replace(/\D/, ""),
                                })
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col sm="3" xs="12">
                          <FormGroup>
                            <Label>
                              Correo electrónico:
                              {/* <span className="text-danger">*</span> */}
                            </Label>
                            <Input
                              name="telefono"
                              type="text"
                              value={form.Correo}
                              onChange={(e) =>
                                setForm({
                                  ...form,
                                  Correo: e.target.value,
                                })
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col sm="3" xs="12">
                          <FormGroup>
                            <Label>Teléfono fijo:</Label>
                            <Input
                              name="telefono"
                              type="text"
                              pattern="[0-9]*"
                              maxLength={10}
                              value={form.Telefono}
                              onChange={(e) =>
                                setForm({
                                  ...form,
                                  Telefono: e.target.value.replace(/\D/, ""),
                                })
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col sm="3" xs="12">
                          <FormGroup>
                            <Label>Teléfono de recados:</Label>
                            <Input
                              name="telRecados"
                              type="text"
                              pattern="[0-9]*"
                              maxLength={10}
                              value={form.TelRecados}
                              onChange={(e) =>
                                setForm({
                                  ...form,
                                  TelRecados: e.target.value.replace(/\D/, ""),
                                })
                              }
                            />
                          </FormGroup>
                        </Col>
                        {errors.error && errors.seccion === 2 && (
                          <Col xs="12">
                            <CardText
                              style={{ color: "red", textAlign: "center" }}
                            >
                              {errors.message}
                            </CardText>
                          </Col>
                        )}
                      </Row>
                      <hr
                        style={{
                          backgroundColor:
                            errors.error && errors.seccion === 3
                              ? "red"
                              : "#E8E8E8",
                        }}
                      />
                      <CardSubtitle
                        className="mb-2 text-muted"
                        tag="h6"
                        style={{ textAlign: "center" }}
                      >
                        IV. DATOS DEL APOYO SOLICITADO
                      </CardSubtitle>
                      <Row>
                        <Col xs="12">
                          <FormGroup>
                            <Label>
                              Descripción de la necesidad de la persona
                              solicitante:
                              <span className="text-danger">*</span>
                            </Label>
                            <Input
                              name="NecesidadSolicitante"
                              type="textarea"
                              value={form.NecesidadSolicitante}
                              onChange={(e) =>
                                setForm({
                                  ...form,
                                  NecesidadSolicitante:
                                    e.target.value.toUpperCase(),
                                })
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col sm="6" xs="12">
                          <FormGroup>
                            <Label>
                              Costo estimado para atender la necesidad:
                              <span className="text-danger">*</span>
                            </Label>
                            <Input
                              name="CostoNecesidad"
                              type="text"
                              value={form.CostoNecesidad}
                              onChange={(e) =>
                                setForm({
                                  ...form,
                                  CostoNecesidad: e.target.value,
                                })
                              }
                            />
                          </FormGroup>
                        </Col>
                        {errors.error && errors.seccion === 3 && (
                          <Col xs="12">
                            <CardText
                              style={{ color: "red", textAlign: "center" }}
                            >
                              {errors.message}
                            </CardText>
                          </Col>
                        )}
                      </Row>
                      <hr
                        style={{
                          backgroundColor:
                            errors.error && errors.seccion === 4
                              ? "red"
                              : "#E8E8E8",
                        }}
                      />
                      <CardSubtitle
                        className="mb-2 text-muted"
                        tag="h6"
                        style={{ textAlign: "center" }}
                      >
                        V. DATOS DE DOMICILIO
                      </CardSubtitle>
                      <Row>
                        <Col sm="6" xs="12">
                          <FormGroup>
                            <Label>
                              Estado: <span className="text-danger">*</span>
                            </Label>
                            <Input
                              name="idEntidadVive"
                              type="select"
                              disabled={true}
                              value={form.idEntidadVive}
                              onChange={(e) =>
                                handleChangeEstadoVive(e.target.value)
                              }
                            >
                              <option value={""}></option>
                              {catalogs.entidades.map((element) => (
                                <option
                                  key={element.value}
                                  value={element.value}
                                >
                                  {element.label}
                                </option>
                              ))}
                            </Input>
                          </FormGroup>
                        </Col>
                        <Col sm="6" xs="12">
                          {!loadingForm && (
                            <FormGroup>
                              <Label>Municipio:</Label>
                              <span className="text-danger">*</span>
                              {loadingCatalogs && <Spinner size="sm" />}
                              <Input
                                name="MunicipioVive"
                                type="select"
                                disabled={loadingCatalogs}
                                value={form.MunicipioVive}
                                onChange={(e) =>
                                  handleChangeMunicipio(e.target.value)
                                }
                              >
                                <option value={""}></option>
                                {catalogs.municipios.map((element) => (
                                  <option
                                    key={element.value}
                                    value={element.value}
                                  >
                                    {element.label}
                                  </option>
                                ))}
                              </Input>
                            </FormGroup>
                          )}
                        </Col>
                        <Col sm="10" xs="12">
                          <FormGroup>
                            <Label>Localidad:</Label>
                            <span className="text-danger">*</span>
                            {loadingCatalogs && <Spinner size="sm" />}
                            <Input
                              name="MunicipioVive"
                              type="select"
                              disabled={
                                loadingCatalogs || form.MunicipioVive === ""
                              }
                              value={form.LocalidadVive}
                              onChange={(e) =>
                                setForm({
                                  ...form,
                                  LocalidadVive: e.target.value,
                                })
                              }
                            >
                              <option value={""}></option>
                              {catalogs.localidades.map((element) => (
                                <option
                                  key={element.value}
                                  value={element.value}
                                >
                                  {element.label}
                                </option>
                              ))}
                            </Input>
                          </FormGroup>
                        </Col>
                        <Col sm="2" xs="12">
                          <FormGroup>
                            <Label>
                              Código Postal:{" "}
                              <span className="text-danger">*</span>
                            </Label>
                            <Input
                              name="CPVive"
                              type="text"
                              pattern="[0-9]*"
                              maxLength={5}
                              value={form.CPVive}
                              onChange={(e) =>
                                setForm({
                                  ...form,
                                  CPVive: e.target.value.replace(/\D/, ""),
                                })
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col sm="12" xs="12">
                          <FormGroup>
                            <Label>
                              Colonia: <span className="text-danger">*</span>
                            </Label>
                            <Input
                              name="ColoniaVive"
                              type="text"
                              value={form.ColoniaVive}
                              onChange={(e) =>
                                setForm({
                                  ...form,
                                  ColoniaVive: e.target.value.toUpperCase(),
                                })
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col sm="8" xs="12">
                          <FormGroup>
                            <Label>
                              Calle: <span className="text-danger">*</span>
                            </Label>
                            <Input
                              name="CalleVive"
                              type="text"
                              value={form.CalleVive}
                              onChange={(e) =>
                                setForm({
                                  ...form,
                                  CalleVive: e.target.value.toUpperCase(),
                                })
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col sm="2" xs="12">
                          <FormGroup>
                            <Label>
                              No. Exterior:{" "}
                              <span className="text-danger">*</span>
                            </Label>
                            <Input
                              name="NoExtVive"
                              type="text"
                              value={form.NoExtVive}
                              onChange={(e) =>
                                setForm({
                                  ...form,
                                  NoExtVive: e.target.value.toUpperCase(),
                                })
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col sm="2" xs="12">
                          <FormGroup>
                            <Label>
                              No. Interior:
                              {/* <span className="text-danger">*</span> */}
                            </Label>
                            <Input
                              name="NoIntVive"
                              type="text"
                              value={form.NoIntVive}
                              onChange={(e) =>
                                setForm({
                                  ...form,
                                  NoIntVive: e.target.value,
                                })
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col sm="12" xs="12">
                          <FormGroup>
                            <Label>
                              Referencias:
                              {/* <span className="text-danger">*</span> */}
                            </Label>
                            <Input
                              name="Referencias"
                              type="textarea"
                              value={form.Referencias}
                              onChange={(e) =>
                                setForm({
                                  ...form,
                                  Referencias: e.target.value.toUpperCase(),
                                })
                              }
                            />
                          </FormGroup>
                        </Col>
                        {errors.error && errors.seccion === 4 && (
                          <Col xs="12">
                            <CardText
                              style={{ color: "red", textAlign: "center" }}
                            >
                              {errors.message}
                            </CardText>
                          </Col>
                        )}
                      </Row>
                      <hr
                        style={{
                          backgroundColor:
                            errors.error && errors.seccion === 5
                              ? "red"
                              : "#E8E8E8",
                        }}
                      />
                      <CardSubtitle
                        className="mb-2 text-muted"
                        tag="h6"
                        style={{ textAlign: "center" }}
                      >
                        VI. DATOS DE ENLACE
                      </CardSubtitle>
                      <Row>
                        <Col xs="12">
                          <FormGroup>
                            <Label>
                              Persona que levantó solicitud en campo:
                              <span className="text-danger">*</span>
                            </Label>
                            <Input
                              name="Enlace"
                              type="text"
                              value={form.Enlace}
                              onChange={(e) =>
                                setForm({
                                  ...form,
                                  Enlace: e.target.value
                                    .toUpperCase()
                                    .replace(/\d/, ""),
                                })
                              }
                            />
                          </FormGroup>
                        </Col>
                        {errors.error && errors.seccion === 5 && (
                          <Col xs="12">
                            <CardText
                              style={{ color: "red", textAlign: "center" }}
                            >
                              {errors.message}
                            </CardText>
                          </Col>
                        )}
                      </Row>
                    </Form>
                    <Row>
                      <Col sm="12" style={{ textAlign: "center" }}>
                        <Button
                          color="warning"
                          disabled={loadingForm}
                          onClick={backSolicitud}
                        >
                          Cancelar
                        </Button>

                        {cedulaData.id < 0 && (
                          <>
                            {isForEdit && menu.Eliminar === 1 && !loadingForm && (
                              <Button
                                color="danger"
                                disabled={loadingForm}
                                onClick={() => deleteAlert()}
                              >
                                Eliminar
                              </Button>
                            )}

                            {menu.Agregar === 1 && !loadingForm && (
                              <>
                                <Button
                                  disabled={loadingForm}
                                  onClick={() => saveSolicitud()}
                                >
                                  Guardar
                                </Button>
                                <Button
                                  color="primary"
                                  disabled={loadingForm}
                                  onClick={() => saveSolicitud(true)}
                                >
                                  Guardar y capturar cédula
                                </Button>
                              </>
                            )}
                          </>
                        )}
                        {cedulaData.id > 0 && (
                          <Button color="info" onClick={() => viewCedula()}>
                            Ver cédula
                          </Button>
                        )}
                        {loadingForm && <Spinner />}
                      </Col>
                      <Col
                        sm="12"
                        style={{ textAlign: "center", color: "red" }}
                      >
                        {cedulaData.id > 0 && (
                          <CardText>
                            La solicitud cuenta con una cédula, no se puede
                            editar
                          </CardText>
                        )}
                        {errors.error && (
                          <CardText>
                            Existen errores, por favor verifique el formulario
                          </CardText>
                        )}
                      </Col>
                    </Row>
                  </>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default SolicitudProyectosAnterior;
