import React from "react";
import { CardFooter, Row, Col, Button } from "reactstrap";

export const Stats = (props) => {
    const {
        currentStep,
        firstStep,
        goToStep,
        lastStep,
        nextStep,
        previousStep,
        totalSteps,
        step,
        disabledNext
    } = props;

    return (
        <CardFooter>
            <Row>

                <Col xs={2}>
                    {step > 1 &&
                        <Button className="btn btn-small btn-default btn-block" onClick={previousStep}>Anterior</Button>
                    }
                </Col>

                {step < totalSteps ?
                    <Col xs={{ size: 2, offset: 8 }}>
                        <Button
                            className="btn btn-small btn-primary btn-block"
                            onClick={nextStep}
                            disabled={disabledNext}
                        >Siguiente</Button>
                    </Col>
                    :
                    <Col xs={{ size: 2, offset: 8 }}>
                        <Button
                            className="btn btn-small btn-success btn-block"
                            onClick={nextStep}
                            disabled={disabledNext}
                        >Finalizar</Button>
                    </Col>
                }

            </Row>
        </CardFooter>
    )
};