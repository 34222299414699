import React from "react";
import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  ListGroup,
  Row,
  CardImg,
} from "reactstrap";
import { isNullOrUndefined } from "../../utils/emptyValidations";

const ModdalUploadFiles = ({
  files,
  archivosClasificacion,
  catalogClasificacion,
  handleChangeArchivos,
  handleChangeClasificacion,
  handleDeleteFile,
}) => {
  const handleSelectItems = (newFiles) => {
    console.log(files);
    handleChangeArchivos(files.concat(Array.from(newFiles)));
  };

  const getValorClasificacion = (index) => {
    const valor = archivosClasificacion[index];
    if (isNullOrUndefined(valor)) return "";
    return valor;
  };

  const handleSelectClasificacion = (idClasificacion, index) => {
    let clasificacionAux = [...archivosClasificacion];
    clasificacionAux[index] = idClasificacion;
    handleChangeClasificacion(clasificacionAux);
  };

  const deleteFile = (index) => {
    let filesAux = [...files];
    let clasificacionAux = [...archivosClasificacion];
    filesAux.splice(index, 1);
    clasificacionAux.splice(index, 1);
    handleDeleteFile(filesAux, clasificacionAux);
  };
  return (
    <Row style={{ width: "100%" }}>
      <Col xs="12">
        <ListGroup
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Row>
            {files.map((file, index) => (
              <>
                <Col
                  sm="6"
                  style={{ border: "1px solid #d5d5d5 ", marginTop: "1rem" }}
                >
                  <Col
                    sm="12"
                    xs="12"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      textAlign: "center",
                    }}
                  >
                    <>
                      <FormGroup>
                        {file.type === "application/pdf" ? (
                          <object
                            type="application/pdf"
                            aria-labelledby={file.name}
                            data={URL.createObjectURL(file)}
                            width="100%"
                            height="250"
                          ></object>
                        ) : (
                          <CardImg
                            variant="top"
                            src={URL.createObjectURL(file)}
                            style={{ height: "250px", width: "85%" }}
                          ></CardImg>
                        )}
                        <Label style={{ fontSize: "8px" }}>{file.name}</Label>
                      </FormGroup>
                    </>
                  </Col>
                  <Col sm="12" xs="12">
                    <FormGroup>
                      <Label>
                        Seleccione la Clasificación:{" "}
                        <span className="text-danger">*</span>
                      </Label>
                      <Input
                        name="idTipoCombustible"
                        type="select"
                        value={getValorClasificacion(index)}
                        style={{
                          borderColor:
                            getValorClasificacion(index) === ""
                              ? "#d03d3dcf"
                              : "#3dd03dcf",
                        }}
                        onChange={(e) =>
                          handleSelectClasificacion(e.target.value, index)
                        }
                      >
                        <option value={""}></option>
                        {catalogClasificacion.map((element) => (
                          <option key={element.value} value={element.value}>
                            {element.label}
                          </option>
                        ))}
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col sm="12" xs="12" style={{ textAlign: "center" }}>
                    <Button
                      color="danger"
                      size="sm"
                      onClick={() => deleteFile(index)}
                      style={{
                        width: "70%",
                      }}
                    >
                      <span className="btn-label">
                        <i className="fa fa-trash fa-xs"></i>
                      </span>
                    </Button>
                  </Col>
                </Col>
              </>
            ))}
          </Row>
        </ListGroup>
      </Col>

      <Col
        xs="12"
        style={{
          display: "flex",
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        <FormGroup style={{ width: "100%" }}>
          <Input
            id="exampleFile"
            name="file"
            type="file"
            size="sm"
            style={{ cursor: "pointer" }}
            multiple
            onChange={(e) => handleSelectItems(e.target.files)}
          />
          <Button
            style={{
              background: "#f5f8f9",
              color: "black",
              width: "100%",
              borderWidth: "2px",
              borderStyle: "dotted",
              borderColor: "#b3c8c8",
              padding: "2rem 0rem",
            }}
            onClick={() => {}}
          >
            {files.length > 0
              ? "Agregue otro archivo"
              : "Seleccione o arrastre los archivos"}
          </Button>
        </FormGroup>
      </Col>
    </Row>
  );
};

export default ModdalUploadFiles;
