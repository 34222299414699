import { getVars } from "../../GlobalStorage";
import React, { useEffect, useState } from "react";
import EjecutarApi, { uploadFiles } from "../../Consultas/Consultas";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  CardImg,
  CardImgOverlay,
  Col,
  Label,
  Row,
  Input,
  FormGroup,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CardText,
} from "reactstrap";
import Notifications from "react-notify-toast";
import swal from "sweetalert";
import { Link } from "react-router-dom";
import { CircleSpinner } from "react-spinners-kit";
import { URL_GENERAL } from "../../data/consts";
import { isNullOrUndefined } from "../../utils/emptyValidations";
import ModdalUploadFiles from "./ModalUpdateFiles";

const Archivos = (props) => {
  const { data, backSolicitud } = props;
  const Datos = data ? data : null;
  const programa = data ? data.idPrograma : 1;
  const token = getVars("Token").token;
  const [isForEdit, setIsForEdit] = useState(false);
  const [archivos, setArchivos] = useState([]);
  const [loadingForm, setIsLoadingForm] = useState(true);
  const [loadingNewFile, setLoadingNewFile] = useState(false);
  const [loadingPreview, setLoadingPreview] = useState(true);
  const [catalogos, setCatalogos] = useState([]);
  const [flagComment, setFlagComment] = useState(false);
  const [flagReplace, setFlagReplace] = useState(false);
  const [flagFile, setFlagFile] = useState(false);
  const [flagNewFile, setFlagNewFile] = useState(false);
  const [archivoObservado, setArchivoObservado] = useState({});
  const [archivoRemplazo, setArchivoRemplazo] = useState({});
  const [archivoNuevo, setArchivoNuevo] = useState({});
  const [observacion, setObservacion] = useState();
  const [flagSolicitud, setFlagSolicitud] = useState(false);
  const [urlSolicitud, setUrlSolicitud] = useState("");
  const [newFiles, setNewFiles] = useState([]);
  const [newClasificacion, setNewClasificacion] = useState([]);

  const handleSelectItems = (newFile) => {
    if (newFile.length > 1) {
      swal({
        title: "¡Atención!",
        text: `Los archivos deben cargarse por separado`,
        icon: "info",
      });
      return;
    }
    setFlagFile(true);
    setArchivoNuevo(Array.from(newFile));
  };

  useEffect(() => {
    const token = getVars("Token");
    EjecutarApi(
      {},
      `calentadoresSolares/getClasificacionArchivos`,
      token.token,
      "GET"
    )
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.results && res.success) {
          setCatalogos(res.data);
        } else {
          swal({
            title: "¡Atención!",
            text: `${res.errors}`,
            icon: "info",
          });
        }
      })
      .catch((error) => {
        swal({
          title: "¡Atención!",
          text: `${error.error}`,
          icon: "info",
        });
      });
  }, []);

  useEffect(() => {
    if (Datos != null) {
      setIsForEdit(true);
      let data = {
        idSolicitud: Datos.id,
        idPrograma: 2,
      };
      const token = getVars("Token");
      EjecutarApi(data, "solicitudes/getArchivosSolicitud", token.token)
        .then(function (res) {
          return res;
        })
        .then((res) => {
          if (res.success && res.results) {
            setArchivos(res.data);
            setIsLoadingForm(false);
          } else {
            swal({
              title: "Ha ocurrido un error",
              text: res.errors,
              icon: "warning",
            });
            backSolicitud();
          }
        })
        .catch((error) => {
          swal({
            title: "Ha ocurrido un error",
            text: error.error,
            icon: "warning",
          });
          backSolicitud();
        });
    }
  }, [Datos, backSolicitud]);

  const getArchivos = () => {
    let data = {
      idSolicitud: Datos.id,
      idPrograma: programa,
    };
    const token = getVars("Token");
    EjecutarApi(data, "solicitudes/getArchivosSolicitud", token.token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.success && res.results) {
          setArchivos(res.data);
          setIsLoadingForm(false);
        } else {
          swal({
            title: "Ha ocurrido un error",
            text: res.errors,
            icon: "warning",
          });
          backSolicitud();
        }
      })
      .catch((error) => {
        swal({
          title: "Ha ocurrido un error",
          text: error.error,
          icon: "warning",
        });
        backSolicitud();
      });
  };

  const handleSelectClasificacion = (idArchivo, idClasificacion) => {
    swal({
      title: "¡Atención!",
      text: "¿Está seguro de cambiar la clasificación de este archivo?",
      dangerMode: true,
      icon: "warning",
      buttons: ["Cancelar", true],
    }).then((response) => {
      if (response) {
        const data = {
          idArchivo: idArchivo,
          idClasificacion: idClasificacion,
        };
        const token = getVars("Token");
        EjecutarApi(data, "calentadoresSolares/setEstatusArchivo", token.token)
          .then(function (res) {
            return res;
          })
          .then((res) => {
            if (res.success && res.results) {
              swal({
                title: "Éxito",
                text: res.message,
                icon: "success",
              });
              getArchivos();
            } else {
              swal({
                title: "Atención",
                text: res.message,
                icon: "warning",
              });
            }
          })
          .catch((error) => {
            swal({
              title: "Atención",
              text: error.message,
              icon: "warning",
            });
          });
      }
    });
  };

  const saveAlert = (status, file) => {
    let text = "";
    let valor = 3;
    switch (status) {
      case 1:
        text = "¿Está seguro de eliminar el archivo'?";
        valor = 5;
        break;
      default:
        text =
          "¿Está seguro de validar el archivo, no podrá ser editado después'?";
    }
    swal({
      title: "¡Atención!",
      text: text,
      dangerMode: true,
      icon: "warning",
      buttons: ["Cancelar", true],
    }).then((response) => {
      if (response) {
        setIsLoadingForm(true);
        changeStatusFiles(valor, file);
      }
    });
  };

  const changeStatusFiles = (value, file) => {
    let data = {
      idArchivo: file.id,
      idEstatus: value,
    };
    EjecutarApi(data, "solicitudes/cambiarEstatusArchivo", token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.success && res.results) {
          swal({
            title: "Completado con éxito!",
            icon: "success",
          });
          getArchivos();
        } else {
          swal({
            title: "Ha ocurrido un error",
            text: res.errors,
            icon: "warning",
          });
        }
      })
      .catch((error) => {
        setIsLoadingForm(false);
        setFlagFile(false);
        swal({
          title: "Ha ocurrido un error",
          text: error.errors,
          icon: "warning",
        });
      });
  };

  const commentFile = (file) => {
    setArchivoObservado(file);
    setFlagComment(true);
  };

  const changeFile = (file) => {
    setArchivoRemplazo(file);
    setFlagReplace(true);
  };

  const handleChangeArchivos = (files) => {
    setNewFiles(files);
  };

  const handleChangeClasificacion = (arrayClasificacion) => {
    setNewClasificacion(arrayClasificacion);
  };

  const handleDeleteFile = (files, arrayClasificacion) => {
    setNewFiles(files);
    setNewClasificacion(arrayClasificacion);
  };

  const saveComment = () => {
    if (isNullOrUndefined(observacion)) {
      swal({
        title: "Atención",
        text: "Debe agregar una observación válida",
        icon: "warning",
      });
      setLoadingNewFile(false);
      return;
    }

    const token = getVars("Token");
    const data = {
      idSolicitud: archivoObservado.idSolicitud,
      idPrograma: 2,
      idArchivo: archivoObservado.id,
      Estatus: 0,
      Observacion: observacion,
    };

    EjecutarApi(data, "calentadoresSolares/setFilesComments", token.token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.success && res.results) {
          swal({
            title: "Éxito",
            text: res.message,
            icon: "success",
          });
          getArchivos();
          setObservacion("");
          setFlagComment(false);
          setLoadingNewFile(false);
          setIsLoadingForm(true);
        } else {
          swal({
            title: "Atención",
            text: res.message,
            icon: "warning",
          });
        }
      })
      .catch((error) => {
        swal({
          title: "Atención",
          text: error.message,
          icon: "warning",
        });
        setLoadingNewFile(false);
      });
  };

  const saveReplace = () => {
    if (Object.entries(archivoNuevo).length === 0) {
      swal({
        title: "Atención",
        text: "No ha seleccionado un archivo",
        icon: "warning",
      });
      setLoadingNewFile(false);
      return;
    }

    if (Object.entries(archivoNuevo).length > 0) {
      let f = false;
      archivoNuevo.forEach((file) => {
        let nameFile = file.name.toString();
        if (!/(.jpg|.jpeg|.png|.pdf)$/i.test(nameFile)) {
          f = true;
        }
      });

      if (f) {
        swal({
          title: "Alerta",
          text: "Solo puede cargar archivos de tipo PDF y JPG o PNG",
          icon: "warning",
        });
        setLoadingNewFile(false);
        return;
      }
    }
    const token = getVars("Token");
    const data = {
      idArchivo: archivoRemplazo.id,
      idSolicitud: archivoRemplazo.idSolicitud,
      idPrograma: 2,
      NewFiles: archivoNuevo,
    };
    uploadFiles({
      data: data,
      api: `calentadoresSolares/changeFiles`,
      token: token.token,
    })
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.success && res.results) {
          swal({
            title: "Éxito",
            text: res.message,
            icon: "success",
          });
          setFlagFile(false);
          setFlagReplace(false);
          setLoadingNewFile(false);
          setObservacion("");
          setArchivoNuevo({});
          setArchivoRemplazo({});
          setIsLoadingForm(true);
          getArchivos();
        } else {
          swal({
            title: "Ha ocurrido un error",
            text: res.errors,
            icon: "warning",
          });
        }
      })
      .catch((error) => {
        swal({
          title: "Ha ocurrido un error",
          text: error.error,
          icon: "warning",
        });
        setLoadingNewFile(false);
      });
  };

  const saveNewFiles = () => {
    if (newFiles.length === 0) {
      swal({
        title: "Atención",
        text: "No ha seleccionado un archivo",
        icon: "warning",
      });
      setLoadingNewFile(false);
      return;
    }

    if (newFiles.length !== newClasificacion.length) {
      swal({
        title: "Atención",
        text: "Debe seleccionar la clasificación para cada archivo cargado",
        icon: "warning",
      });
      setLoadingNewFile(false);
      return;
    }

    const uniqueClasifications = new Set(newClasificacion);
    if (uniqueClasifications.size < newClasificacion.length) {
      swal({
        title: "Atención",
        text: "Existen clasificaciones repetidas en los archivos a cargar, por favor revise y modifique",
        icon: "warning",
      });
      setLoadingNewFile(false);
      return;
    }

    let f = false;
    newFiles.forEach((file) => {
      let nameFile = file.name.toString();
      if (!/(.jpg|.jpeg|.png|.pdf)$/i.test(nameFile)) {
        f = true;
      }
    });

    if (f) {
      swal({
        title: "Alerta",
        text: "Solo puede cargar archivos de tipo PDF y JPG o PNG",
        icon: "warning",
      });
      setLoadingNewFile(false);
      return;
    }

    const token = getVars("Token");
    const data = {
      idSolicitud: Datos.id,
      idPrograma: programa,
      NewFiles: newFiles,
      newIdClasificacion: newClasificacion,
    };
    uploadFiles({
      data: data,
      api: `calentadoresSolares/saveNewFiles`,
      token: token.token,
    })
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.success && res.results) {
          swal({
            title: "Éxito",
            text: res.message,
            icon: "success",
          });
          setIsLoadingForm(true);
          setLoadingNewFile(false);
          setFlagFile(false);
          setNewFiles([]);
          setNewClasificacion([]);
          getArchivos();
          setFlagNewFile(false);
        } else {
          swal({
            title: "¡Atención!",
            text: res.message,
            icon: "warning",
          });
          setLoadingNewFile(false);
        }
      })
      .catch((error) => {
        swal({
          title: "¡Atención!",
          text: error.message,
          icon: "warning",
        });
        setFlagFile(false);
        setNewFiles([]);
        setNewClasificacion([]);
        getArchivos();
        setFlagNewFile(false);
        setLoadingNewFile(false);
      });
  };
  const viewSolicitud = (item) => {
    setFlagSolicitud(true);
    const token = getVars("Token");
    var url = new URL(
      URL_GENERAL +
        `calentadoresSolares/getPdf?token=${token.token}&id=${item.id}`
    );
    setUrlSolicitud(url);
    setLoadingPreview(false);
  };
  const closeBtn = (
    <button
      className="close"
      onClick={() => {
        setFlagSolicitud(!flagSolicitud);
      }}
      type="button"
    >
      &times;
    </button>
  );

  return (
    <>
      <Notifications />
      <div>
        <Row>
          <Col md="12">
            <Modal
              size="lg"
              isOpen={flagSolicitud}
              toggle={() => setFlagSolicitud(!flagSolicitud)}
            >
              <ModalHeader
                toggle={flagSolicitud}
                close={closeBtn}
                style={{ height: "1rem" }}
              ></ModalHeader>
              <ModalBody>
                <Row>
                  <object
                    type="application/pdf"
                    aria-labelledby={"Solicitud"}
                    data={urlSolicitud}
                    width="100%"
                    height="500"
                  ></object>
                </Row>
              </ModalBody>
              <ModalFooter>
                <Button
                  size="sm"
                  color="danger"
                  onClick={() => {
                    setFlagSolicitud(false);
                    setUrlSolicitud("");
                  }}
                >
                  Cerrar
                </Button>
                {loadingPreview && (
                  <CircleSpinner
                    className="text-center"
                    size={30}
                    color="#686769"
                    loading={true}
                  />
                )}
              </ModalFooter>
            </Modal>
            <Modal
              size="lg"
              isOpen={flagComment}
              toggle={() => setFlagComment(!flagComment)}
            >
              <ModalHeader>
                Archivo: {archivoObservado.NombreOriginal}
              </ModalHeader>
              <ModalBody>
                <Row>
                  <Col sm="4">
                    <Col
                      sm="12"
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {archivoObservado.Tipo !== "image" ? (
                        <div display="flex" style={{ width: "100%" }}>
                          <i
                            class="fa fa-file-pdf-o fa-4x"
                            aria-hidden="true"
                            display="flex"
                            style={{
                              color: "#d34a37",
                              paddingLeft: "8rem",
                              paddingTop: "5rem",
                              height: 100,
                            }}
                          ></i>
                        </div>
                      ) : (
                        <CardImg
                          variant="top"
                          src={archivoObservado.ruta}
                          style={{
                            height: 100,
                          }}
                        ></CardImg>
                      )}
                    </Col>
                  </Col>
                  <Col
                    sm="8"
                    style={{
                      paddingTop: "1rem",
                      fontSize: "11px",
                    }}
                  >
                    <FormGroup>
                      <Label style={{ font: "bold", fontSize: "12px" }}>
                        Observación:
                      </Label>
                      <Input
                        name="observación"
                        type="text"
                        value={observacion}
                        style={{
                          textTransform: "uppercase",
                          fontSize: "10px",
                        }}
                        onChange={(e) => {
                          setObservacion(e.target.value.toUpperCase());
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </ModalBody>
              <ModalFooter>
                {loadingNewFile ? (
                  <CircleSpinner
                    className="text-center"
                    size={30}
                    color="#686769"
                    loading={true}
                  />
                ) : (
                  <>
                    <Button
                      size="sm"
                      color="danger"
                      onClick={() => {
                        setFlagComment(false);
                        setArchivoObservado({});
                        setObservacion("");
                      }}
                    >
                      Cancelar
                    </Button>
                    <Button
                      size="sm"
                      color="primary"
                      onClick={() => {
                        setLoadingNewFile(true);
                        saveComment();
                      }}
                    >
                      Guardar
                    </Button>
                  </>
                )}
              </ModalFooter>
            </Modal>
            <Modal
              size="lg"
              isOpen={flagReplace}
              toggle={() => setFlagComment(!flagReplace)}
            >
              <ModalHeader>
                Archivo: {archivoRemplazo.NombreOriginal}
              </ModalHeader>
              <ModalBody>
                <Row>
                  <Col sm="4">
                    <Col
                      sm="12"
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {!flagFile ? (
                        <FormGroup>
                          <Input
                            id="exampleFile"
                            name="file"
                            type="file"
                            color="success"
                            style={{ cursor: "pointer" }}
                            multiple
                            onChange={(e) => handleSelectItems(e.target.files)}
                          />
                          <Button size="sm" color="info" onClick={() => {}}>
                            Seleccionar
                          </Button>
                        </FormGroup>
                      ) : (
                        <>
                          {archivoNuevo.map((element) => {
                            const url = URL.createObjectURL(element);
                            const tipo = element.type;
                            const nombre = element.name;

                            if (tipo === "application/pdf") {
                              return (
                                <>
                                  <i
                                    class="fa fa-file-pdf-o fa-5x"
                                    aria-hidden="true"
                                    style={{
                                      color: "#d34a37",
                                      alignItems: "center",
                                    }}
                                  ></i>
                                  <p
                                    style={{
                                      fontSize: "12px",
                                      paddingLeft: "1rem",
                                    }}
                                  >
                                    {nombre}
                                  </p>
                                </>
                              );
                            } else {
                              return (
                                <>
                                  <CardImg variant="top" src={url}></CardImg>
                                  <CardImgOverlay>
                                    <CardText
                                      style={{ color: "#000", font: "bold" }}
                                    >
                                      {nombre}
                                    </CardText>
                                  </CardImgOverlay>
                                </>
                              );
                            }
                          })}
                        </>
                      )}
                    </Col>
                  </Col>
                  <Col sm="6">
                    <FormGroup>
                      <Label style={{ fontWeight: "bold", fontSize: "12px" }}>
                        Clasificación:
                      </Label>
                      <Input
                        name="observación"
                        type="text"
                        value={archivoRemplazo.Clasificacion}
                        disabled={true}
                        style={{
                          textTransform: "uppercase",
                          fontSize: "10px",
                        }}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label style={{ fontWeight: "bold", fontSize: "12px" }}>
                        Por corregir:
                      </Label>

                      {flagReplace ? (
                        archivoRemplazo.observaciones.map((element) => {
                          return (
                            <Input
                              name="observacion"
                              type="text"
                              size="sm"
                              value={element.Observacion}
                              disabled={true}
                              style={{
                                textTransform: "uppercase",
                                fontSize: "10px",
                              }}
                            />
                          );
                        })
                      ) : (
                        <></>
                      )}
                    </FormGroup>
                  </Col>
                </Row>
              </ModalBody>
              <ModalFooter>
                {loadingNewFile ? (
                  <CircleSpinner
                    className="text-center"
                    size={30}
                    color="#686769"
                    loading={true}
                  />
                ) : (
                  <>
                    <Button
                      size="sm"
                      color="danger"
                      onClick={() => {
                        setFlagReplace(false);
                        setArchivoRemplazo({});
                        setArchivoNuevo({});
                        setFlagFile(false);
                      }}
                    >
                      Cancelar
                    </Button>
                    <Button
                      size="sm"
                      color="primary"
                      onClick={() => {
                        setLoadingNewFile(true);
                        saveReplace();
                      }}
                    >
                      Guardar
                    </Button>
                  </>
                )}
              </ModalFooter>
            </Modal>
            <Modal
              size="xl"
              isOpen={flagNewFile}
              toggle={() => setFlagComment(!flagNewFile)}
            >
              <ModalHeader>Nuevo Archivo</ModalHeader>
              <ModalBody>
                <ModdalUploadFiles
                  files={newFiles}
                  archivosClasificacion={newClasificacion}
                  catalogClasificacion={catalogos}
                  handleChangeArchivos={handleChangeArchivos}
                  handleChangeClasificacion={handleChangeClasificacion}
                  handleDeleteFile={handleDeleteFile}
                />
              </ModalBody>
              <ModalFooter>
                {loadingNewFile ? (
                  <CircleSpinner
                    className="text-center"
                    size={30}
                    color="#686769"
                    loading={true}
                  />
                ) : (
                  <>
                    <Button
                      size="sm"
                      color="danger"
                      onClick={() => {
                        setFlagNewFile(false);
                        setFlagFile(false);
                        setNewFiles([]);
                        setNewClasificacion([]);
                      }}
                    >
                      Cancelar
                    </Button>
                    <Button
                      size="sm"
                      color="primary"
                      onClick={() => {
                        setLoadingNewFile(true);
                        saveNewFiles();
                      }}
                    >
                      Guardar
                    </Button>
                  </>
                )}
              </ModalFooter>
            </Modal>
            <Card>
              <CardHeader>
                <Row>
                  <Col size="2">
                    <Button color="success" onClick={backSolicitud} size="sm">
                      Regresar
                    </Button>
                  </Col>
                  <Col sm={{ size: "2", offset: "6" }}>
                    {isForEdit && Datos && (
                      <Button
                        color="info"
                        disabled={loadingForm}
                        onClick={() => viewSolicitud(Datos)}
                        size="sm"
                      >
                        Solicitud
                      </Button>
                    )}
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Col sm="12">
                  <CardTitle tag="h5" style={{ textAlign: "center" }}>
                    Archivos Solicitud
                    <hr
                      style={{
                        backgroundColor: "#E8E8E8",
                      }}
                    />
                  </CardTitle>
                </Col>
              </CardBody>
              {loadingForm ? (
                <Row>
                  <Col sm={{ size: "1", offset: "6" }}>
                    <CircleSpinner
                      className="text-center"
                      size={30}
                      color="#686769"
                      loading={true}
                    />
                  </Col>
                </Row>
              ) : (
                <>
                  <Row>
                    {archivos.map((file, index) => (
                      <>
                        <Col md="6">
                          <Card
                            outline
                            style={{
                              border: `1px solid ${file.color}`,
                            }}
                          >
                            <Row>
                              <Col
                                sm="12"
                                style={{
                                  paddingTop: "1rem",
                                }}
                              >
                                <Col
                                  sm={{ size: 10, offset: 1 }}
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  {file && file.Tipo !== "image" ? (
                                    <div
                                      display="flex"
                                      style={{
                                        width: "100%",
                                        border: "1px solid #999",
                                      }}
                                    >
                                      <object
                                        type="application/pdf"
                                        aria-labelledby={file.NombreOriginal}
                                        data={file.ruta}
                                        width="100%"
                                        height="300"
                                      ></object>
                                    </div>
                                  ) : (
                                    <CardImg
                                      variant="top"
                                      src={file.ruta}
                                      style={{
                                        height: 300,
                                        width: "100%",
                                        paddingLeft: "1rem",
                                        border: "1px solid #999",
                                      }}
                                    ></CardImg>
                                  )}
                                </Col>
                                <Row
                                  sm="12"
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    textAlign: "center",
                                  }}
                                >
                                  {file && (
                                    <Link
                                      className={"nav-link"}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        window.open(file.ruta, "_blank");
                                      }}
                                      style={{
                                        width: "100%",
                                      }}
                                    >
                                      {file.Clasificacion}
                                    </Link>
                                  )}
                                </Row>
                              </Col>
                              <Col
                                sm={{ size: 10, offset: 1 }}
                                style={{
                                  paddingTop: "1rem",
                                  fontSize: "11px",
                                }}
                              >
                                <Row>
                                  <Col sm="5">
                                    <FormGroup>
                                      <Label tag="h6">Estatus</Label>
                                      <Input
                                        name="estatus"
                                        type="text"
                                        value={file.Estatus ? file.Estatus : 1}
                                        disabled={true}
                                        style={{
                                          textTransform: "uppercase",
                                          fontSize: "10px",
                                        }}
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col sm="7">
                                    <FormGroup>
                                      <Label tag="h6">Clasificación</Label>
                                      <Input
                                        name="clasificacion"
                                        type="select"
                                        value={file.idClasificacion}
                                        style={{
                                          fontSize: "10px",
                                        }}
                                        onChange={(e) =>
                                          handleSelectClasificacion(
                                            file.id,
                                            e.target.value
                                          )
                                        }
                                      >
                                        <option value={""}></option>
                                        {catalogos.map((element) => (
                                          <option
                                            key={element.value}
                                            value={element.value}
                                          >
                                            {element.label}
                                          </option>
                                        ))}
                                      </Input>
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col sm="12">
                                    <FormGroup size="sm">
                                      <Label tag="h6">Observaciones</Label>
                                      <Row>
                                        {file.observaciones.length === 0 ? (
                                          <Col
                                            sm="12"
                                            style={{
                                              paddingTop: "10px",
                                            }}
                                          >
                                            <Input
                                              name="observacion"
                                              type="text"
                                              size="sm"
                                              value={""}
                                              disabled={true}
                                              style={{
                                                textTransform: "uppercase",
                                                fontSize: "10px",
                                              }}
                                            />
                                          </Col>
                                        ) : (
                                          file.observaciones.map((element) => (
                                            <Col
                                              sm="11"
                                              style={{
                                                paddingTop: "10px",
                                              }}
                                            >
                                              <Input
                                                name="observacion"
                                                type="text"
                                                size="sm"
                                                value={element.Observacion}
                                                disabled={
                                                  file.idEstatus === 3 ||
                                                  file.idEstatus === 2
                                                }
                                                style={{
                                                  textTransform: "uppercase",
                                                  fontSize: "10px",
                                                }}
                                              />
                                            </Col>
                                          ))
                                        )}
                                      </Row>
                                    </FormGroup>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col sm="12">
                                    <FormGroup size="sm">
                                      <Label tag="h6">Correciones</Label>
                                      <Row
                                        style={{
                                          overflow: "scroll",
                                          height: "80px",
                                          width: "100%",
                                        }}
                                      >
                                        {file.correciones.length === 0 ? (
                                          <Col
                                            sm="12"
                                            style={{
                                              paddingTop: "10px",
                                            }}
                                          >
                                            <Input
                                              name="correcciones"
                                              type="text"
                                              size="sm"
                                              value={""}
                                              disabled={true}
                                              style={{
                                                textTransform: "uppercase",
                                                fontSize: "10px",
                                              }}
                                            />
                                          </Col>
                                        ) : (
                                          file.correciones.map((element) => (
                                            <Col
                                              sm="12"
                                              style={{
                                                paddingTop: "10px",
                                              }}
                                            >
                                              <Input
                                                name="correcciones"
                                                type="text"
                                                size="sm"
                                                value={element.Observacion}
                                                disabled={true}
                                                style={{
                                                  textTransform: "uppercase",
                                                  fontSize: "10px",
                                                }}
                                              />
                                            </Col>
                                          ))
                                        )}
                                      </Row>
                                    </FormGroup>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                            <Row>
                              <Col
                                sm={{ size: 10, offset: 1 }}
                                style={{ fontSize: "12px" }}
                              >
                                {file.idEstatus !== 3 ? (
                                  <>
                                    {Datos.menu.Eliminar === 1 && (
                                      <Button
                                        style={{ color: "0000" }}
                                        color="danger"
                                        onClick={() => saveAlert(1, file)}
                                        size="sm"
                                      >
                                        Eliminar
                                      </Button>
                                    )}
                                    {file.idEstatus !== 3 &&
                                      Datos.menu.Imprimir === 1 && (
                                        <Button
                                          style={{ color: "0000" }}
                                          color="warning"
                                          onClick={() => commentFile(file)}
                                          size="sm"
                                        >
                                          Observar
                                        </Button>
                                      )}
                                    {file.idEstatus === 2 &&
                                      Datos.menu.Agregar === 1 && (
                                        <Button
                                          style={{ color: "0000" }}
                                          color="info"
                                          onClick={() => changeFile(file)}
                                          size="sm"
                                        >
                                          Reemplazar
                                        </Button>
                                      )}
                                    {(file.idEstatus === 1 ||
                                      file.idEstatus === 4) &&
                                      Datos.menu.Imprimir === 1 && (
                                        <Button
                                          style={{ color: "0000" }}
                                          color="success"
                                          onClick={() => saveAlert(0, file)}
                                          size="sm"
                                        >
                                          Validar
                                        </Button>
                                      )}
                                  </>
                                ) : file.idEstatus === 3 ? (
                                  <Col
                                    sm={{ size: 10, offset: 1 }}
                                    style={{ fontSize: "12px" }}
                                  >
                                    <Label
                                      style={{
                                        fontWeight: "bold",
                                        paddingTop: "1rem",
                                      }}
                                    >
                                      Aprobado por {file.UserAprobo}
                                    </Label>
                                  </Col>
                                ) : (
                                  <></>
                                )}
                              </Col>
                            </Row>
                          </Card>
                        </Col>
                      </>
                    ))}
                  </Row>

                  {Datos.idEstatusSolicitud !== 10 &&
                    Datos.idEstatusSolicitud !== 14 &&
                    !loadingForm &&
                    Datos.menu.Agregar === 1 && (
                      <Col
                        xs="12"
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <FormGroup>
                          <Button
                            color="primary"
                            onClick={() => {
                              setFlagNewFile(true);
                            }}
                          >
                            Nuevo
                          </Button>
                        </FormGroup>
                      </Col>
                    )}
                </>
              )}
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Archivos;
