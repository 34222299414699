import React from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col
} from "reactstrap";
import { Link } from 'react-router-dom';
const NotFound = (props) => {


  return (
    <>
      <div id="render-body" className="pt-6 grey lighten-5">
        <div className="pt-6 pb-4">
          <div className="container">

            <Row>
              <Col md="12">
                <Card>
                  <CardHeader>
                    <Row>
                      <Col md="6">
                        <CardTitle tag="h4">Elemento no encontrado</CardTitle>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <Link to="/">
                      <Button color="/">
                        <span className="btn-label">
                          <i className="fa fa-user-plus fa-2x" />
                        </span>
											Ir al inicio
										</Button>
                    </Link>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </div>

    </>);
};

export default NotFound;
