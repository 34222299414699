import { useState, useEffect } from "react";

export function useFilters() {
  const [filters, setFilters] = useState({
    region: 0,
    numero: "",
    nombre: "",
    date: null,
  });
  const [error, setError] = useState(null);

  useEffect(() => {
    if (
      filters.region === 0 &&
      filters.numero === "" &&
      filters.nombre === ""
    ) {
      return;
    }
    if (filters.region < 0 || filters.region > 7) {
      setError("La región no es valida");
      return;
    }
    if (filters.numero && !filters.numero.match(/^\d+$/)) {
      setError("El número ingresado es invalido");
      return;
    }
    if (filters.nombre && filters.nombre.match(/^\d+$/)) {
      setError("El nombre ingresado es invalido");
      return;
    }
    setError(null);
  }, [filters]);

  return { filters, setFilters, error };
}
