import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

Highcharts.setOptions({
  lang: {
    thousandsSep: ",",
    numericSymbols: null,
  },
  // colors: ["#2e86c1", "#f4d03f", "#e74c3c", "#aab7b8", "#229954"],
});

const LineGraphHg = (props) => {
  const { data } = props;

  const getOptions = (type) => ({
    chart: {
      type,
    },
    title: {
      text: `Vales Pineados`,
    },
    xAxis: {
      categories: [
        "10 a.m.",
        "11 a.m.",
        "12 p.m.",
        "01 p.m.",
        "02 p.m.",
        "03 p.m.",
        "04 p.m.",
        "05 p.m.",
        "06 p.m.",
        "07 p.m.",
        "08 p.m.",
        "09 p.m.",
        "10 p.m.",
        "11 p.m.",
      ],
    },
    yAxis: {
      title: {
        text: "Total",
      },
    },
    legend: {
      layout: "vertical",
      align: "right",
      verticalAlign: "middle",
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      line: {
        dataLabels: {
          enabled: true,
        },
        enableMouseTracking: false,
      },
    },
    series: data ? data : [],
  });
  return (
    <>
      {<HighchartsReact highcharts={Highcharts} options={getOptions("line")} />}
    </>
  );
};
export default LineGraphHg;
