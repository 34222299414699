import React, { useState, useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
} from "reactstrap";
import swal from "sweetalert";
import ReactTable from "react-table";
import { CircleSpinner } from "react-spinners-kit";
import EjecutarApi, { uploadFiles } from "../Consultas/Consultas";
import Notifications, { notify } from "react-notify-toast";
import { getVars } from "../GlobalStorage";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Pagination from "../components/pagination";
import Sidebar from "../components/Sidebar";
import FixedPlugin from "../components/PluginFilterSolicitudVales";
import { URL_GENERAL } from "../data/consts";
import { isNullOrUndefined } from "../utils/emptyValidations";
import ListadoArchivos from "../components/Archivos/ListadoArchivos";
import SolicitudCedulaAdd from "./SolicitudCedulaAdd";
import SolicitudCedulaAddAnterior from "./SolicitudCedulaAddFormatoAnterior";

const Cedula = (props) => {
  const [userData, setUserData] = useState();
  const [menu, setMenu] = useState({
    Ver: 0,
    Agregar: 0,
    Editar: 0,
    Eliminar: 0,
    Seguimiento: 0,
    Exportar: 0,
    Imprimir: 0,
    ViewAll: 0,
    idTipoUser: 0,
  });
  const history = useHistory();
  const [total, setTotal] = useState(0);
  const [loadingCedulaList, setLoadingCedulaList] = useState(false);
  const [dataCedulaList, setDataCedulaList] = useState([]);
  const [menuLoaded, setMenuLoaded] = useState(false);
  const [isLoadingArchivos, setIsLoadingArchivos] = useState(false);
  const [archivos, setArchivos] = useState(false);
  const [clasificacion, setClasificacion] = useState(false);
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [isLoadingCatalogos, setIsLoadingCatalogos] = useState(false);
  const [TotalSolicitudes, setTotalSolicitudes] = useState(0);
  const [TotalValidadas, setTotalValidadas] = useState(0);
  const [TotalAprobadas, setTotalAprobadas] = useState(0);
  const [TotalEntregadas, setTotalEntregadas] = useState(0);
  const [TotalExpedientes, setTotalExpedientes] = useState(0);
  const [catalogos, setCatalogos] = useState([]);
  const [selectedRecord, setSelectedRecord] = useState({});
  //Filtro
  const [filtro, setFiltro] = useState([]);
  const [pageOptions, setPage] = useState({
    page: 0,
    pageSize: 10,
  });

  const [isSolicitud, setIsSolicitud] = useState(false);
  const [solicitud, setSolicitud] = useState(null);
  const [formato, setFormato] = useState(0);

  const columns = [
    {
      Header: "Acciones",
      minWidth: 220,
      id: "Acciones",
      accessor: (d) => d.Acciones,
      className: "text-center",
      filterAll: false,
      style: { fontSize: "10px" },
      headerStyle: { fontSize: "10px" },
    },
    {
      Header: "Fecha Solicitud",
      minWidth: 120,
      id: "FechaSolicitud",
      accessor: (d) => d.FechaSolicitud,
      filterAll: false,
      style: { fontSize: "10px" },
      headerStyle: { fontSize: "10px" },
    },
    {
      Header: "Folio Ventanilla",
      minWidth: 120,
      id: "Folio",
      accessor: (d) => d.Folio,
      filterAll: false,
      style: { fontSize: "10px" },
      headerStyle: { fontSize: "10px" },
    },
    {
      Header: "Folio Solicitud",
      minWidth: 120,
      id: "FolioSolicitud",
      accessor: (d) => d.FolioSolicitud,
      filterAll: false,
      style: { fontSize: "10px" },
      headerStyle: { fontSize: "10px" },
    },
    {
      Header: "Remesa",
      minWidth: 90,
      id: "Remesa",
      accessor: (d) => d.Remesa,
      filterAll: false,
      style: { fontSize: "10px" },
      headerStyle: { fontSize: "10px" },
    },
    {
      Header: "Nombre Completo",
      minWidth: 200,
      id: "NombreCompleto",
      accessor: (d) => d.NombreCompleto,
      filterAll: false,
      style: { fontSize: "10px" },
      headerStyle: { fontSize: "10px" },
    },
    {
      Header: "Region",
      minWidth: 80,
      id: "RegionM",
      accessor: (d) => d.RegionM,
      filterAll: false,
      style: { fontSize: "10px" },
      headerStyle: { fontSize: "10px" },
    },
    {
      Header: "Municipio",
      minWidth: 80,
      id: "MunicipioVive",
      accessor: (d) => d.MunicipioVive,
      filterAll: false,
      style: { fontSize: "10px" },
      headerStyle: { fontSize: "10px" },
    },
    {
      Header: "Dirección",
      minWidth: 300,
      id: "Direccion",
      accessor: (d) => d.Direccion,
      filterAll: false,
      style: { fontSize: "10px" },
      headerStyle: { fontSize: "10px" },
    },
    {
      Header: "CURP",
      minWidth: 120,
      id: "CURP",
      accessor: (d) => d.CURP,
      filterAll: false,
      style: { fontSize: "10px" },
      headerStyle: { fontSize: "10px" },
    },
    {
      Header: "Celular",
      minWidth: 120,
      id: "Celular",
      accessor: (d) => d.Celular,
      filterAll: false,
      style: { fontSize: "10px" },
      headerStyle: { fontSize: "10px" },
    },
    {
      Header: "CreadoPor",
      minWidth: 120,
      id: "CreadoPor",
      accessor: (d) => d.CreadoPor,
      filterAll: false,
      style: { fontSize: "10px" },
      headerStyle: { fontSize: "10px" },
    },
  ];

  useEffect(() => {
    if (isNullOrUndefined(userData)) return;
    const menus = userData.menu;
    const currentMenu = menus.find((menu) => menu.idMenu === 13);
    if (isNullOrUndefined(currentMenu) || currentMenu.Ver < 1) {
      swal({
        title: "Atención",
        text: "No tiene permisos para este módulo, contacte al administrador",
        icon: "warning",
      });
      history.push("/not-found");
      return;
    }
    setMenu({
      Ver: currentMenu.Ver,
      Agregar: currentMenu.Agregar,
      Editar: currentMenu.Editar,
      Eliminar: currentMenu.Eliminar,
      Seguimiento: currentMenu.Seguimiento,
      Exportar: currentMenu.Exportar,
      Imprimir: currentMenu.Imprimir,
      ViewAll: currentMenu.ViewAll,
      idTipoUser: userData.user.idTipoUser.id,
    });
    setMenuLoaded(true);
  }, [userData]);

  const handleMiniClick = () => {
    document.body.classList.toggle("sidebar-mini");
  };

  useEffect(() => {
    setUserData(getVars("Token"));
  }, []);

  const handleShowFiles = (solicitud) => {
    setIsModalOpened(true);
    setIsLoadingArchivos(true);
    setIsLoadingCatalogos(true);
    setSelectedRecord(solicitud);
    const token = getVars("Token");
    EjecutarApi({}, `cedula/getClasificacionArchivos`, token.token, "GET")
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.results && res.success) {
          setCatalogos(res.data);
        } else {
          setIsModalOpened(false);
          swal({
            title: "¡Atención!",
            text: `${res.message}`,
            icon: "info",
          });
        }
        setIsLoadingCatalogos(false);
      })
      .catch((error) => {
        setIsLoadingCatalogos(false);
        setIsModalOpened(false);
        swal({
          title: "¡Atención!",
          text: `${error.message}`,
          icon: "info",
        });
      });
    EjecutarApi(
      {},
      `cedula/getArchivosByIdV/${solicitud.id}`,
      token.token,
      "GET"
    )
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.results && res.success) {
          setArchivos(res.data.Archivos);
          setClasificacion(res.data.ArchivosClasificacion);
        } else {
          setIsModalOpened(false);
          swal({
            title: "¡Atención!",
            text: `${res.message}`,
            icon: "info",
          });
        }
        setIsLoadingArchivos(false);
      })
      .catch((error) => {
        setIsLoadingArchivos(false);
        setIsModalOpened(false);
        swal({
          title: "¡Atención!",
          text: `${error.message}`,
          icon: "info",
        });
      });
  };

  // useEffect(() => {
  //   const token = getVars("Token").token;
  //   EjecutarApi({}, "getEstatusGlobalVentanillaVales", token)
  //     .then(function (res) {
  //       return res;
  //     })
  //     .then((res) => {
  //       if (res.results) {
  //         setTotalSolicitudes(res.data[0].Solicitudes);
  //         setTotalAprobadas(res.data[0].AprobadoComite);
  //         setTotalValidadas(res.data[0].ProcesoValidacion);
  //         setTotalEntregadas(res.data[0].Entregados);
  //         setTotalExpedientes(res.data[0].Expedientes);
  //       }
  //     })
  //     .catch((e) => {
  //       notify.show(
  //         `Error en la obtencion de datos! ${e.message}`,
  //         "custom",
  //         5000,
  //         {
  //           background: "#d61818",
  //           text: "#FFFFFF",
  //         }
  //       );
  //     });
  // }, []);

  const fetchData = useCallback(
    ({ pageSize, page, sorted, params }) => {
      const data = {
        page: page > pageOptions.page ? page : pageOptions.page,
        pageSize: pageSize,
        programa: 1,
        filtered: filtro,
      };
      setPage({
        page: page,
        pageSize: pageSize,
      });
      getRegistros(data);
    },
    [menu, filtro]
  );

  const getRegistros = (data) => {
    const token = getVars("Token");
    setLoadingCedulaList(true);
    data.filtered = filtro;
    EjecutarApi(data, "getSolicitudesCedula", token.token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.results && res.success) {
          setDataCedulaList(
            res.data.map((item) => {
              if (item.Paterno == null) item.Paterno = "";
              if (item.Materno == null) item.Materno = "";
              if (item.Referencias == null) item.Referencias = "";
              if (item.NoExtVive == null) item.NoExtVive = "";
              return {
                ...item,
                NombreCompleto: `${item.Nombre} ${item.Paterno} ${item.Materno}`,
                Direccion: `${item.CalleVive} ${item.NoExtVive} ${item.ColoniaVive} ${item.CPVive} ${item.MunicipioVive} ${item.EntidadVive}`,
                Acciones: (
                  <>
                    {item.idEstatus !== 8 ? (
                      <>
                        <Row>
                          <Col>
                            <Button
                              color="primary"
                              size="sm"
                              style={{ width: "100%" }}
                              onClick={() => sendDataSolicitud(item)}
                            >
                              <span className="btn-label">
                                <i
                                  className={`fa ${
                                    item.ListaParaEnviar > 0
                                      ? "fa fa-eye fa-xs"
                                      : "fa-pencil-square-o"
                                  } fa-xs`}
                                ></i>
                              </span>
                              Solicitud
                            </Button>
                          </Col>
                          {/* <Col>
                        <Button
                          color="info"
                          size="sm"
                          style={{ width: "100%" }}
                          onClick={() => sendDataCedula(item)}
                        >
                          <span className="btn-label">
                            {item.idCedula && item.ListaParaEnviar === 1 ? (
                              <i className="fa fa-eye fa-xs"></i>
                            ) : item.ListaParaEnviar === 0 ? (
                              <i className="fa fa-pencil-square-o fa-xs"></i>
                            ) : (
                              <i className="fa fa-plus fa-xs"></i>
                            )}
                          </span>
                          Cedula
                        </Button>
                      </Col> */}
                          <Col>
                            <Button
                              color="warning"
                              size="sm"
                              style={{ width: "100%" }}
                              onClick={() => handleShowFiles(item)}
                            >
                              <i className="fa fa-file-archive-o fa-xs"></i>
                              Archivos
                            </Button>
                          </Col>
                        </Row>
                        <Row>
                          {/* <Col>
                            {item.ListaParaEnviar === 1 && menu.Imprimir === 1 && (
                              <Button
                                color="success"
                                size="sm"
                                style={{ width: "100%" }}
                                onClick={() => sendIGTO(item.id)}
                              >
                                <span>
                                  <i className="fa fa-paper-plane fa-xs" />
                                </span>{" "}
                                Ventanilla
                              </Button>
                            )}
                          </Col> */}
                        </Row>
                      </>
                    ) : (
                      <Row>
                        <Col>
                          <Button
                            color="info"
                            size="sm"
                            style={{ width: "100%" }}
                            onClick={() => sendDataSolicitud(item)}
                          >
                            <span>
                              <i className="fa fa-paper-plane fa-xs" />
                            </span>{" "}
                            Enviada (Ver)
                          </Button>
                        </Col>
                      </Row>
                    )}
                  </>
                ),
              };
            })
          );
          setTotal(res.total);
        } else {
          swal({
            title: "¡Atención!",
            text: `${res.message}`,
            icon: "info",
          });
        }
        setLoadingCedulaList(false);
      })
      .catch((e) => {
        setLoadingCedulaList(false);
        swal({
          title: "¡Atención!",
          text: `${e.message}`,
          icon: "warning",
        });
      });
  };

  const sendDataSolicitud = (item) => {
    setFormato(item ? item.Formato : formato);
    setIsSolicitud(!isSolicitud);
    setSolicitud(item);
  };

  // const sendIGTO = (id) => {
  //   setLoadingCedulaList(true);
  //   const token = getVars("Token");
  //   const data = { id: id };
  //   EjecutarApi(data, "cedula/enviarIGTO", token.token)
  //     .then(function (res) {
  //       return res;
  //     })
  //     .then((res) => {
  //       if (res.results && res.success) {
  //         swal({
  //           title: "Solicitud enviada con éxito",
  //           text: `Folio PVG - ${res.message}`,
  //           icon: "success",
  //         });
  //         setIsSolicitud(!isSolicitud);
  //         setIsSolicitud(isSolicitud);
  //       } else {
  //         swal({
  //           title: "¡Atención!",
  //           text: `${res.message}`,
  //           icon: "info",
  //         });
  //       }
  //       setLoadingCedulaList(false);
  //     })
  //     .catch((e) => {
  //       setLoadingCedulaList(false);
  //       swal({
  //         title: "¡Atención!",
  //         text: `${e.message}`,
  //         icon: "warning",
  //       });
  //     });
  // };

  const setFilter = (filtered) => {
    filtered.programa = "1";
    setFiltro(filtered.filtered);
    const token = getVars("Token");
    setLoadingCedulaList(true);
    EjecutarApi(filtered, "getSolicitudesCedula", token.token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.results && res.success) {
          setDataCedulaList(
            res.data.map((item) => {
              if (item.Paterno == null) item.Paterno = "";
              if (item.Materno == null) item.Materno = "";
              if (item.Referencias == null) item.Referencias = "";
              if (item.NoExtVive == null) item.NoExtVive = "";
              return {
                ...item,
                NombreCompleto: `${item.Nombre} ${item.Paterno} ${item.Materno}`,
                Direccion: `${item.CalleVive} ${item.NoExtVive} ${item.ColoniaVive} ${item.CPVive} ${item.MunicipioVive} ${item.EntidadVive}`,
                Acciones: (
                  <>
                    {item.idEstatus !== 8 ? (
                      <>
                        <Row>
                          <Col>
                            <Button
                              color="primary"
                              size="sm"
                              style={{ width: "100%" }}
                              onClick={() => sendDataSolicitud(item)}
                            >
                              <span className="btn-label">
                                <i
                                  className={`fa ${
                                    item.ListaParaEnviar
                                      ? "fa fa-eye fa-xs"
                                      : "fa-pencil-square-o"
                                  } fa-xs`}
                                ></i>
                              </span>
                              Solicitud
                            </Button>
                          </Col>
                          <Col>
                            <Button
                              color="warning"
                              size="sm"
                              style={{ width: "100%" }}
                              onClick={() => handleShowFiles(item)}
                            >
                              <i className="fa fa-file-archive-o fa-xs"></i>
                              Archivos
                            </Button>
                          </Col>
                        </Row>
                        <Row>
                          {/* <Col>
                            {item.ListaParaEnviar === 1 && menu.Imprimir === 1 && (
                              <Button
                                color="success"
                                size="sm"
                                style={{ width: "100%" }}
                                onClick={() => sendIGTO(item.id)}
                              >
                                <span>
                                  <i className="fa fa-paper-plane fa-xs" />
                                </span>{" "}
                                Ventanilla
                              </Button>
                            )}
                          </Col> */}
                        </Row>
                      </>
                    ) : (
                      <>
                        <Row>
                          <Col>
                            <Button
                              color="info"
                              size="sm"
                              style={{ width: "100%" }}
                              onClick={() => sendDataSolicitud(item)}
                            >
                              <span>
                                <i className="fa fa-paper-plane fa-xs" />
                              </span>{" "}
                              Enviada (Ver)
                            </Button>
                          </Col>
                        </Row>
                      </>
                    )}
                  </>
                ),
              };
            })
          );
          setTotal(res.total);
        } else {
          swal({
            title: "¡Atención!",
            text: `${res.message}`,
            icon: "info",
          });
        }
        setLoadingCedulaList(false);
      })
      .catch((e) => {
        setLoadingCedulaList(false);
        swal({
          title: "¡Atención!",
          text: `${e.message}`,
          icon: "warning",
        });
      });
  };

  const handleChangeArchivos = (files) => {
    setArchivos(files);
  };

  const handleChangeClasificacion = (arrayClasificacion) => {
    setClasificacion(arrayClasificacion);
  };

  const handleDeleteFile = (files, arrayClasificacion) => {
    setArchivos(files);
    setClasificacion(arrayClasificacion);
  };

  const changeFiles = () => {
    const token = getVars("Token");
    let oldFiles = [];
    let newFiles = [];
    let oldClasification = [];
    let newClasification = [];
    let flag = false;

    archivos.forEach((file, index) => {
      if (isNullOrUndefined(file.id)) {
        newFiles.push(file);
        if (isNullOrUndefined(clasificacion[index])) {
          flag = true;
          return;
        }
        newClasification.push(clasificacion[index]);
      } else {
        oldFiles.push(file);
        if (isNullOrUndefined(clasificacion[index])) {
          flag = true;
          return;
        }
        oldClasification.push(clasificacion[index]);
      }
    });

    if (flag) {
      swal({
        title: "Alerta",
        text: "Debe seleccionar una clasificación para cada archivo",
        icon: "warning",
      });
      return;
    }

    if (newFiles.length > 0) {
      let f = false;
      newFiles.map((file) => {
        let nameFile = file.name.toString();
        if (!/(.jpg|.jpeg|.png|.pdf)$/i.test(nameFile)) {
          f = true;
        }
      });
      if (f) {
        swal({
          title: "Alerta",
          text: "Solo puede cargar archivos de tipo PDF y JPG o PNG",
          icon: "warning",
        });
        return;
      }
    }
    if (oldClasification.length > 0 || newClasification.length > 0) {
      let formDataFiles = [];
      formDataFiles = oldClasification.concat(newClasification);
      if (formDataFiles.length > 0) {
        const formFiles = formDataFiles.map((element) => {
          return parseInt(element);
        });
        formDataFiles = formFiles;

        let ine = formDataFiles.filter((element) => element === 3);
        if (ine.length > 1) {
          swal({
            title: "Alerta",
            icon: "warning",
            text: "Solo puede cargar la identificación una vez",
          });
          return;
        }

        let pvg = formDataFiles.filter((element) => element === 2);
        if (pvg.length > 1) {
          swal({
            title: "Alerta",
            icon: "warning",
            text: "Solo puede cargar el formato PVG una vez",
          });
          return;
        }

        let curp = formDataFiles.filter((element) => element === 4);
        if (curp.length > 1) {
          swal({
            title: "Alerta",
            icon: "warning",
            text: "Solo puede cargar el CURP una vez",
          });
          return;
        }

        let tarjeta = formDataFiles.filter((element) => element === 13);
        if (tarjeta.length > 1) {
          swal({
            title: "Alerta",
            icon: "warning",
            text: "Solo puede cargar la Tarjeta Contigo Si una vez",
          });
          return;
        }
      }
    }

    const formAux = {
      OldFiles: oldFiles,
      NewFiles: newFiles,
      OldClasificacion: oldClasification,
      NewClasificacion: newClasification,
      id: selectedRecord.id,
      programa: 1,
    };
    console.log(formAux);
    setIsLoadingArchivos(true);
    uploadFiles({
      data: formAux,
      api: `cedula/updateArchivosSolicitud`,
      token: token.token,
    })
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.success && res.results) {
          swal({
            title: "¡Éxito!",
            icon: "success",
          });
          handleShowFiles(selectedRecord);
        } else {
          swal({
            title: "Ha ocurrido un error",
            text: res.message,
            icon: "warning",
          });
          setIsLoadingArchivos(false);
        }
      })
      .catch((error) => {
        setIsLoadingArchivos(false);
        swal({
          title: "Ha ocurrido un error",
          text: error.errorInfo,
          icon: "warning",
        });
      });
  };

  const btnExportar = () => {
    const token = getVars("Token");
    var url = new URL(
      URL_GENERAL + `getReporteSolicitudVentanillaVales?token=${token.token}`
    );
    window.open(url, "_blank");
  };

  const btnExportarCompleto = () => {
    const token = getVars("Token");
    var url = new URL(
      URL_GENERAL + `getReporteCompletoVales?token=${token.token}`
    );
    window.open(url, "_blank");
  };

  return (
    <>
      <div className="wrapper">
        <Sidebar {...props} bgColor="black" activeColor="info" />
        <div className="main-panel">
          <Navbar {...props} handleMiniClick={handleMiniClick} />
          <Notifications />
          <div className="content">
            {!isSolicitud && formato === 0 ? (
              <>
                <Modal
                  size="xl"
                  isOpen={isModalOpened}
                  toggle={() => setIsModalOpened(!isModalOpened)}
                >
                  <ModalHeader>Archivos</ModalHeader>
                  <ModalBody>
                    {isLoadingArchivos && <Spinner />}
                    {!isLoadingArchivos && (
                      <ListadoArchivos
                        files={archivos}
                        archivosClasificacion={clasificacion}
                        catalogClasificacion={catalogos}
                        handleChangeArchivos={handleChangeArchivos}
                        handleChangeClasificacion={handleChangeClasificacion}
                        handleDeleteFile={handleDeleteFile}
                        loadingCatalogs={isLoadingCatalogos}
                        onlyForView={selectedRecord.ListaParaEnviar === 1}
                        fullscreen={true}
                      />
                    )}
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      size="sm"
                      color="danger"
                      disabled={isLoadingArchivos}
                      onClick={() => setIsModalOpened(false)}
                    >
                      Cancelar
                    </Button>
                    {selectedRecord.ListaParaEnviar === 0 &&
                      menu.Agregar === 1 && (
                        <Button
                          size="sm"
                          color="primary"
                          disabled={isLoadingArchivos}
                          onClick={() => changeFiles()}
                        >
                          Guardar
                        </Button>
                      )}
                  </ModalFooter>
                </Modal>{" "}
                {/* <Row>
                  <Col lg="3" md="2" sm="2">
                    <Card className="card-stats">
                      <CardBody>
                        <Row>
                          <Col md="3" xs="5">
                            <div className="icon-lg text-center icon-warning">
                              <i
                                class="fa fa-globe fa-4x"
                                aria-hidden="true"
                                style={{ color: "#f1e15a" }}
                              ></i>
                            </div>
                          </Col>
                          <Col md="9" xs="7">
                            <div className="numbers">
                              <p className="card-category">
                                
                                Capturados
                              </p>
                              <CardTitle tag="p">
                                {new Intl.NumberFormat("en-EN").format(
                                  TotalSolicitudes
                                )}
                              </CardTitle>
                              <p />
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                      <CardFooter>
                        <hr />
                        <div className="card-category">
                          {new Intl.NumberFormat("en-EN").format(
                            TotalExpedientes
                          )}{" "}
                          expedientes
                        </div>
                      </CardFooter>
                    </Card>
                  </Col>

                  <Col lg="3" md="4" sm="4">
                    <Card className="card-stats">
                      <CardBody>
                        <Row>
                          <Col md="4" xs="5">
                            <div className="icon-big text-center icon-warning">
                              <i
                                class="fa fa-check-square-o"
                                aria-hidden="true"
                                style={{ color: "#71c34d" }}
                              ></i>
                            </div>
                          </Col>
                          <Col md="8" xs="7">
                            <div className="numbers">
                              <p className="card-category">Validados</p>
                              <CardTitle tag="p">
                                {" "}
                                {new Intl.NumberFormat("en-EN").format(
                                  TotalValidadas
                                )}
                              </CardTitle>
                              <p />
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                      <CardFooter>
                        <hr />
                       
                      </CardFooter>
                      
                    </Card>
                  </Col>
                  {menu.Seguimiento === 1 && (
                    <Col lg="3" md="4" sm="4">
                      <Card className="card-stats">
                        <CardBody>
                          <Row>
                            <Col md="4" xs="5">
                              <div className="icon-big text-center icon-warning">
                              
                                <i
                                  class="fa fa-thumbs-o-up"
                                  aria-hidden="true"
                                  style={{ color: "#62ecec" }}
                                ></i>
                              </div>
                            </Col>
                            <Col md="8" xs="7">
                              <div className="numbers">
                                <p className="card-category">Aprobados</p>
                                <CardTitle tag="p">
                                  {new Intl.NumberFormat("en-EN").format(
                                    TotalAprobadas
                                  )}
                                </CardTitle>
                                <p />
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                        <CardFooter>
                          <hr />
                          
                        </CardFooter>
                      
                      </Card>
                    </Col>
                  )}
                  {menu.Seguimiento === 1 && (
                    <Col lg="3" md="4" sm="4">
                      <Card className="card-stats">
                        <CardBody>
                          <Row>
                            <Col md="4" xs="5">
                              <div className="icon-big text-center icon-warning">
                              
                                <i
                                  class="fa fa-truck"
                                  aria-hidden="true"
                                  style={{ color: "#33cca9" }}
                                ></i>
                              </div>
                            </Col>
                            <Col md="8" xs="7">
                              <div className="numbers">
                                <p className="card-category">Entregados</p>
                                <CardTitle tag="p">
                                  {new Intl.NumberFormat("en-EN").format(
                                    TotalEntregadas
                                  )}
                                </CardTitle>
                                <p />
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                        <CardFooter>
                          <hr />
                          
                        </CardFooter>
                      </Card>
                    </Col>
                  )}
                </Row> */}
                <Row>
                  <Col md="12">
                    <Card>
                      <CardHeader>
                        <Row>
                          <Col md="3">
                            <CardTitle tag="h6">
                              Listado de Solicitudes
                            </CardTitle>
                          </Col>
                          <Col md="2">
                            {menu.Agregar === 1 ? (
                              <Button
                                color="primary"
                                size="sm"
                                onClick={() => {
                                  sendDataSolicitud(null);
                                  setFormato(1);
                                }}
                              >
                                <span className="btn-label">
                                  <i className="fa fa-user-plus fa-2x" />
                                </span>
                                Captura Formato Anterior
                              </Button>
                            ) : (
                              <></>
                            )}
                          </Col>
                          <Col md="2">
                            {menu.Agregar === 1 ? (
                              <Button
                                color="primary"
                                size="sm"
                                onClick={() => {
                                  sendDataSolicitud(null);
                                  setFormato(2);
                                }}
                              >
                                <span className="btn-label">
                                  <i className="fa fa-user-plus fa-2x" />
                                </span>
                                Capturar
                              </Button>
                            ) : (
                              <></>
                            )}
                          </Col>
                          <Col md="2">
                            {menu.Exportar === 1 ? (
                              <>
                                <Button
                                  color="success"
                                  size="sm"
                                  onClick={btnExportar}
                                >
                                  <span className="btn-label">
                                    <i className="fa fa-file-excel-o fa-2x" />
                                  </span>
                                  Exportar
                                </Button>
                              </>
                            ) : (
                              <></>
                            )}
                          </Col>
                          <Col md="3">
                            {menu.Exportar === 1 &&
                            (userData.user.id === 52 ||
                              userData.user.id === 1 ||
                              userData.user.id === 1557 ||
                              userData.user.id === 55) ? (
                              <>
                                <Button
                                  color="success"
                                  size="sm"
                                  onClick={btnExportarCompleto}
                                >
                                  <span className="btn-label">
                                    <i className="fa fa-file-excel-o fa-2x" />
                                  </span>
                                  Exportar Completo
                                </Button>
                              </>
                            ) : (
                              <></>
                            )}
                          </Col>
                        </Row>
                      </CardHeader>
                      <CardBody>
                        {loadingCedulaList && (
                          <CircleSpinner
                            className="text-center"
                            size={30}
                            color="#686769"
                            loading={loadingCedulaList}
                          />
                        )}
                        <Col lg="12" md="12">
                          {menuLoaded && dataCedulaList && (
                            <ReactTable
                              size="sm"
                              data={dataCedulaList}
                              noDataText="No Existen Datos a Mostrar!"
                              manual
                              columns={columns}
                              className="-striped -highlight primary-pagination"
                              loading={loadingCedulaList}
                              showPagination={true}
                              showPaginationBottom={true}
                              showTotalPages={true}
                              showPageJump={false}
                              canNextFromData={true}
                              defaultPageSize={pageOptions.pageSize}
                              total={total}
                              page={pageOptions.page}
                              pages={Math.floor(total / pageOptions.pageSize)}
                              previousText="Anterior"
                              nextText="Siguiente"
                              ofText="de"
                              pageSizeOptions={[10, 20, 25, 50, 100]}
                              PaginationComponent={Pagination}
                              onFetchData={fetchData}
                            />
                          )}
                        </Col>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                <FixedPlugin
                  //isLoading={loading}
                  sendFiltro={setFilter}
                  clasess="dropdowns"
                />
                <Footer fluid />
              </>
            ) : isSolicitud && formato === 2 ? (
              <SolicitudCedulaAdd
                data={solicitud}
                back={() => {
                  setIsSolicitud(!isSolicitud);
                  setFormato(0);
                }}
              />
            ) : (
              <SolicitudCedulaAddAnterior
                data={solicitud}
                back={() => {
                  setIsSolicitud(!isSolicitud);
                  setFormato(0);
                }}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default Cedula;
