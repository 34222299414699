import React, { useEffect, useState } from 'react';
// react component for creating dynamic tables
import ReactTable from 'react-table';
import EjecutarApi from '../Consultas/Consultas';
import Notifications, { notify } from 'react-notify-toast';
import { getVars } from '../GlobalStorage';

import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import Sidebar from '../components/Sidebar';
import { URL_GENERAL } from '../data/consts';
// reactstrap components
import { Button, Card, CardHeader, CardBody, CardTitle, Row, Col,Input, CardFooter} from 'reactstrap';
import BravosEntrega from '../pages/BravosEntrega';
import matchSorter from 'match-sorter';
import ModalInvitado from '../components/Filtro/ModalInvitado'
import Pagination from "../components/pagination";

function GruposEntregaVales(props) {
    const [loading, setloading] = useState(false);
    const [Grupos, setGrupos] = useState([]);
    const [totalGrupos, setTotalGrupos] = useState(0);
    const [catMunicipio,setCatMunicipio]=useState([]);
    const [catResponsable,setCatResponsable]=useState([]);
    const [isEditing,setIsEditing]=useState(false)
    const [Datos,setDatos]=useState([]);
    const [Filtro,setFiltro]=useState({Folio:'',CodigoBarras:'',Invitado:''});
    const [showModal,setShowModal]=useState(false);

    const token = getVars('Token');
    
    const [_menu, _setmenu] = useState({});

    useEffect(() => {
        var parametros={
            page: 0,
            tipo: 'and',
            pageSize: 999,
            filtered:[],
            sorted:[]
        }
        EjecutarApi(parametros, 'getResponsables', token.token)
        .then(function (res) {
            return res;
        })
        .then((res) => {
            setloading(false);
            if(res.results){
                setCatResponsable(
                res.data.map((prop) => {
                
                    return <option value={prop.Responsable}>{prop.Responsable}</option>
                    
                })
                );
                setCatMunicipio(
                    res.data.map((prop) => {
                        return <option value={prop.idMunicipio}>{prop.Municipio}</option>
                    })
                );
            }
        })
        .catch((e) => {
            console.log('=Error[getResponsables]: ', e);
            setloading(false);
            notify.show(
                `Error al obtener Vales: ${e.errors}`,
                'custom',
                5000,
                {
                    background: '#0857B6',
                    fontSize: 14,
                    text: '#FFFFFF',
                }
            );

            //setloadingGrupo(false);
        });
    
        console.log('=>[GruposEntregaVales]-> token', token);
        token.menu.forEach((prop, key) => {

            if (prop.Menu === 'Entrega Vales') {
                _setmenu({
                    Ver: prop.Ver,
                    Agregar: prop.Agregar,
                    Editar: prop.Editar,
                    Eliminar: prop.Eliminar,
                    Seguimiento: prop.Seguimiento,
                    Exportar: prop.Exportar,
                    Imprimir: prop.Imprimir,
                    ViewAll: prop.ViewAll,
                    idTipoUser: token.user.idTipoUser.id
                })
            }
        });
    }, []);
    const handleMiniClick = () => {
        document.body.classList.toggle('sidebar-mini');
    };

    const handleDescargaExcel = (param) => {
        
        var url = new URL(URL_GENERAL+`getReporteInvitadosPorResponsable?token=${token.token}&Responsable=${param.Responsable}&Municipio=${param.Municipio}`);
        window.open(url, '_blank');

    }
    const handleAsignarFolio=(item)=>
    {
     
        setDatos(item);
        setIsEditing(true);
    }
    const onHide=()=>{
        setIsEditing(false);
        setShowModal(false);
        setFiltro({Folio:'',CodigoBarras:'',Invitado:''});
    }
    const handleFiltro=()=>{
  
        var show=false;
        var nombreC ='';
		var _filtro=[];
		if(Filtro.Folio) {
            _filtro.push({ id: "bravos_invitados.Folio", value: Filtro.Folio })
            show=true
		}
		if (Filtro.Invitado)
		{
            nombreC=Filtro.Invitado;
            show=true
		}
		if(Filtro.CodigoBarras)
		{
            _filtro.push({ id: "bravos_invitados.CodigoBarras", value: Filtro.CodigoBarras })
            show=true
        }
        let datax = {
            page: 0,
            tipo: "and",
            pageSize: 50,
            filtered: _filtro,
            excluir_asignados:false,
            NombreCompleto:nombreC,
        };
        setFiltro(datax);
        setShowModal(show);
		 
        }
    return (
        <div className="wrapper">
            <Sidebar {...props} bgColor="black" activeColor="info" />
            <div className="main-panel">
                <Navbar {...props} handleMiniClick={handleMiniClick} />
                <Notifications />
                <div className="content">
                        
                    <Row className=' align-items-center'>
                        <Col md="12">
                            {isEditing?<>
                                <BravosEntrega onHide={onHide} Data={Datos}/>
                            </>:<><Card>
                                    <CardHeader>
                                    <Row>
                                        <Col md="9">
                                            <CardTitle tag="h6">Entrega de Pulseras</CardTitle>
                                        </Col>
                                       
                                    </Row>
                                </CardHeader>
                                    <CardBody>
                                    <Row >
                                        <Col sm="3" className="text-muted">Folio:<Input id="icveElector" value={Filtro.Folio} onChange={(e)=>setFiltro({...Filtro, Folio:e.target.value})} /></Col>            
                                        <Col sm="3" className="text-muted">Código Barras:<Input id="icveElector" value={Filtro.CodigoBarras} onChange={(e)=>setFiltro({...Filtro, CodigoBarras:e.target.value})} /></Col>
                                        <Col sm="3" className="text-muted">Invitado: <Input id="inombre" value={Filtro.Invitado} onChange={(e)=>setFiltro({...Filtro,Invitado:e.target.value})} /></Col>
                                        <Col sm="3" className="text-muted"><Button id='btnBusca' size='sm' className='warning' color='warning' onClick={()=>handleFiltro()}><i className="fa fa-search" aria-hidden="true"></i> </Button></Col>
                                        <br/>
                                    </Row>
                                    <Row>
                                        <Col sm="12">
                                        <br/>
                                            <ReactTable size="sm" name='ract1'
                                                noDataText="¡No existen datos a mostrar!"
                                                data={Grupos}
                                                filterable
                                                manual
                                                columns={[
                                                    {
                                                        Header: 'Municipio',
                                                        minWidth: 50,
                                                        id: 'Municipio',
                                                        accessor: d => d.Municipio,
                                                        filterMethod: (filter, rows) =>
                                                        {
                                                            if (filter.value === 'Cualquier') {
                                                                return true;
                                                            }
                                                            return rows[filter.id] === filter.value;
                                                        },
                                                        Filter: ({ filter, onChange }) =>
                                                                <select name='select1'
                                                                onChange={event => onChange(event.target.value)}
                                                                style={{ width: '100%' }}
                                                                value={filter ? filter.value : 'Cualquier'}
                                                                >
                                                                <option value="">Todos</option>
                                                                {catMunicipio}
                                                                </select>,
                                                    },
                                                    {
                                                        Header: 'Responsable',
                                                        minWidth: 70,
                                                        id: 'Responsable',
                                                        accessor: d => d.Responsable,
                                                        filterMethod: (filter, rows) =>
                                                        {
                                                        if (filter.value === 'Cualquier') {
                                                            return true;
                                                        }
                                                        return rows[filter.id] === filter.value;
                                                        },
                                                        Filter: ({ filter, onChange }) =>
                                                        <select name='select2'
                                                        onChange={event => onChange(event.target.value)}
                                                        style={{ width: '100%' }}
                                                        value={filter ? filter.value : 'Cualquier'}
                                                        >
                                                        <option value="">Todos</option>
                                                        {catResponsable}
                                                        </select>,
                                                    },
                                                    {
                                                        Header: 'Total',
                                                        minWidth: 30,
                                                        id: 'Total',
                                                        accessor: d => d.Total,
                                                        filterMethod: (filter, rows) =>
                                                        matchSorter(rows, filter.value, { keys: ['Total'] }),
                                                        filterAll: true,
                                                    }
                                                    ,
                                                    {
                                                        Header: 'Entregados',
                                                        minWidth: 30,
                                                        id: 'Entregados',
                                                        accessor: d => d.Entregados,
                                                        filterMethod: (filter, rows) =>
                                                        matchSorter(rows, filter.value, { keys: ['Entregados'] }),
                                                        filterAll: true,
                                                    },
                                                    {
                                                        Header: 'Restantes',
                                                        minWidth: 30,
                                                        id: 'Restantes',
                                                        accessor: d => d.Restantes,
                                                        filterMethod: (filter, rows) =>
                                                            matchSorter(rows, filter.value, { keys: ['Restantes'] }),
                                                            filterAll: true,
                                                    },    
                                                    {
                                                        Header: '',
                                                        minWidth: 30,
                                                        id: 'action',
                                                        accessor: d => d.action,
                                                        filterable: false
                                                    }
                                                ]}
                                                className="-striped -highlight primary-pagination"
                                                loading={loading}
                                                showPagination={true}
                                                showPaginationBottom={true}
                                                showTotalPages={true}
                                                showPageJump={false}
                                                canNextFromData={true}
                                                defaultPageSize={10}
                                                total={totalGrupos}
                                                pages={Math.floor(totalGrupos / 10)}
                                                previousText="Anterior"
                                                nextText="Siguiente"
                                                ofText="de"
                                                pageSizeOptions={[10, 20, 25, 50, 100]}
                                                PaginationComponent={Pagination}
                                                onFetchData={(stateComp, instance) => {
                                                    setloading(true);
                                                    var arrays = [];
                                                    var total=""; 
                                                    var realizado="";
                                                    var faltantes="";
                                                    stateComp.filtered.map((Filtro) => {
                                                    
                                                        if (Filtro.id === 'Responsable') {
                                                            arrays.push({ id: "bravos_invitados.Responsable", value: Filtro.value })
                                                        }
                                                        if (Filtro.id === 'Municipio') {
                                                            arrays.push({ id: "bravos_invitados.Municipio", value: Filtro.value })
                                                        }
                                                        if (Filtro.id === 'Total') {
                                                            total=Filtro.value
                                                        }
                                                        if (Filtro.id === 'Entregados') {
                                                            realizado=Filtro.value
                                                        }
                                                        if (Filtro.id === 'Restantes') {
                                                            faltantes=Filtro.value
                                                        }
                                                    })

                                                    const token = getVars('Token');
                                                    const datax = {
                                                        page: stateComp.page,
                                                        tipo: 'and',
                                                        pageSize: stateComp.pageSize,
                                                        filtered:arrays,
                                                        Realizados:realizado,
                                                        Faltantes:faltantes,
                                                        Total:total
                                                    };
                                                    console.log('datax',datax)
                                                    EjecutarApi(datax, 'getListadoResponsables', token.token)
                                                        .then(function (res) {
                                                            return res;
                                                        })
                                                        .then((res) => {
                                                            setloading(false);
                                                            setTotalGrupos(res.total);
                                                            setGrupos(
                                                                res.data.map((prop) => {
                                                                    return {
                                                                        
                                                                        idMunicipio:prop.idMunicipio,
                                                                        Municipio: prop.Municipio,
                                                                        Responsable:(<Button name='btnAsignar' size="sm" color="success" onClick={() => handleAsignarFolio(prop)}>
                                                                                            {prop.Responsable}
                                                                                        </Button>),
                                                                        Total: prop.TotalInvitados,
                                                                        Entregados: prop.InvitadosRealizados,
                                                                        Restantes:prop.TotalInvitados - prop.InvitadosRealizados,
                                                                        action: (<>

                                                                            <Button name='btnDescarga' size="sm" color="success" onClick={() => handleDescargaExcel(prop)}><span className="btn-label">
                                                                                <i className="fa fa-file-excel-o fa-la" />
                                                                            </span>
                                                                            </Button>

                                                                        </>
                                                                        ),
                                                                        data: prop,
                                                                    };
                                                                })
                                                            );
                                                            //setloadingGrupo(false);
                                                        })
                                                        .catch((e) => {
                                                            console.log('=Error[getExport]: ', e);
                                                            setloading(false);
                                                            notify.show(
                                                                `Error al obtener Vales: ${e.errors}`,
                                                                'custom',
                                                                5000,
                                                                {
                                                                    background: '#0857B6',
                                                                    fontSize: 14,
                                                                    text: '#FFFFFF',
                                                                }
                                                            );

                                                            //setloadingGrupo(false);
                                                        });
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                </CardBody>
                                <CardFooter>
                                    {showModal?<ModalInvitado Datos={Filtro} onHide={onHide} />:''}
                                </CardFooter>
                                </Card></>}
                        </Col>
                    </Row>
                </div>
                <Footer />
            </div>
        </div>

    );

}

export default GruposEntregaVales;