import React, { useEffect, useState } from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";
import EjecutarApi from "../Consultas/Consultas";
import Notifications, { notify } from "react-notify-toast";
import { getVars } from "../GlobalStorage";
import { URL_GENERAL } from '../data/consts';
import { Link } from "react-router-dom";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Sidebar from "../components/Sidebar";
import FixedPlugin from "../components/PluginFilterVales";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
} from "reactstrap";
import ValesUpdate from "./ValesUpdate";

const Vales = (props) => {
  const [totalRemesa, settotalRemesa] = useState(0);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [_menu, _setmenu] = useState({});
  const token = getVars("Token");

  const handleMiniClick = () => {
    document.body.classList.toggle("sidebar-mini");
  };

  const setFilter = (filtered) => {
    var dato = filtered;
    setLoading(true);
    console.log("datos a filtrar:______", dato);
    EjecutarApi(dato, "getVales", token.token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.results) {
          setData(
            res.data.map((prop, key) => {
              setLoading(false);
              return {
                Nombre: `${prop.Nombre !== null ? prop.Nombre : ""} ${
                  prop.Paterno !== null ? prop.Paterno : ""
                } ${prop.Materno !== null ? prop.Materno : ""}`,
                TelCelular: prop.TelCelular,
                ClaveUnica: prop.ClaveUnica,
                TelFijo: prop.TelFijo,
                Estatus: prop.idStatus.Estatus,
                CorreoElectronico: prop.CorreoElectronico,
                Municipio: prop.idMunicipio.Municipio,
                Region: prop.idMunicipio.Region,
                Colonia: prop.Colonia,
                Calle: prop.Calle,
                action: <ValesUpdate prop={prop} labelButton={"Editar"} />,
                data: prop,
              };
            })
          );
        }
        setLoading(false);
      })
      .catch((e) => {
        notify.show(`Error en la busqueda! ${e.message}`, "custom", 5000, {
          background: "#d61818",
          text: "#FFFFFF",
        });
        setLoading(false);
      });
  };

  const setearMenu = (dependecia) => {
    if (dependecia == "Estado") {
      token.menu.forEach((prop, key) => {
        if (prop.Menu === "Remesas") {
          _setmenu({
            Ver: prop.Ver,
            Agregar: prop.Agregar,
            Editar: prop.Editar,
            Eliminar: prop.Eliminar,
            Seguimiento: prop.Seguimiento,
            Exportar: prop.Exportar,
            Imprimir: prop.Imprimir,
            ViewAll: prop.ViewAll,
            idTipoUser: token.user.idTipoUser.id,
          });
        }
      });
    } else {
      var json = {};
      token.menu.forEach((prop, key) => {
        if (prop.Menu === "Remesas") {
          json = {
            Ver: prop.Ver,
            Agregar: prop.Agregar,
            Editar: prop.Editar,
            Eliminar: prop.Eliminar,
            Seguimiento: prop.Seguimiento,
            Exportar: prop.Exportar,
            Imprimir: prop.Imprimir,
            ViewAll: prop.ViewAll,
            idTipoUser: token.user.idTipoUser.id,
          };
        }
      });
      return json;
    }
  };

  console.log("=>Menu ", _menu);
  useEffect(() => {
    console.log("=>Token ", token);
    setearMenu("Estado");
    /* let datos={
            page: 0,
            tipo: "and",
            pageSize: 10,
            sorted: [],
            filtered: [],
            idUser:_menu.ViewAll==0?token.user.id:''
        };

        EjecutarApi(datos, 'getValesRegion', token.token)
            .then(function (res) {
                return res;
            })
            .then((res) => {
                if (res.results) {
                    settotalRemesa(res.total);
                    setData(res.data.map((res, i) => {
                        return {
                            ClaveUnica: res.ClaveUnica,
                            Region: res.Region,
                            Municipio: res.Municipio,
                            Localidad: res.Localidad,
                            id: res.id,
                            FechaSolicitud: res.FechaSolicitud,
                            CURP: res.CURP,
                            Nombre: res.Nombre,
                            Paterno: res.Paterno,
                            Materno:res.Materno,
                            Sexo: res.Sexo,
                            FechaNacimiento: res.FechaNacimiento,
                            Calle:res.Calle,
                            NumExt:res.NumExt,
                            NumInt:res.NumInt,
                            Colonia:res.Colonia,
                            CP:res.CP,
                            TelFijo: res.TelFijo,
                            TelCelular:res.TelCelular,
                            TelRecados:res.TelRecados,
                            Compania:res.Compania,
                            NombreCompleto: res.NombreCompleto,
                            Capturo: res.Capturo,
                            Articulador: res.Articulador,
                            Estatus:res.Estatus
                        }
                       

                    }));
                   
                }
            })
            .catch((e) => {
                notify.show(`Error en la busqueda! ${e.message}`, 'custom', 5000, {
                    background: '#d61818',
                    text: '#FFFFFF',
                });
            });
       */
  }, []);

  const btnExportar = () => {
    
    var url = new URL(
      URL_GENERAL+`getReportesoValesRegionales?token=${token.token}`
    );
    window.open(url, "_blank");
  };

  return (
    <>
      <div className="wrapper">
        <Sidebar {...props} bgColor="black" activeColor="info" />
        <div className="main-panel">
          <Navbar {...props} handleMiniClick={handleMiniClick} />
          <Notifications />
          <div className="content">
            <Row>
              <Col md="12">
                <Card>
                  <CardHeader>
                    <Row>
                      <Col md="4">
                        <CardTitle tag="h4">Remesas</CardTitle>
                      </Col>
                    </Row>
                  </CardHeader>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <Card>
                  <CardHeader>
                    {_menu.Exportar == 1 ? (
                      <>
                        <Button color="success" size="sm" onClick={btnExportar}>
                          <span className="btn-label">
                            <i className="fa fa-file-excel-o fa-2x" />
                          </span>
                          Exportar
                        </Button>
                      </>
                    ) : (
                      <></>
                    )}
                  </CardHeader>
                  <CardBody>
                    <ReactTable
                      data={data}
                      noDataText="Sin información"
                      manual
                      columns={[
                        {
                          Header: "Region",
                          minWidth: 80,
                          id: "Region",
                          accessor: (d) => d.Region,
                          filterAll: false,
                        },
                        {
                          Header: "Municipio",
                          minWidth: 80,
                          id: "Municipio",
                          accessor: (d) => d.Municipio,
                          filterAll: false,
                        },
                        {
                          Header: "Localidad",
                          minWidth: 80,
                          id: "Localidad",
                          accessor: (d) => d.Localidad,
                          filterAll: false,
                        },
                        {
                          Header: "ClaveUnica",
                          minWidth: 80,
                          id: "ClaveUnica",
                          accessor: (d) => d.ClaveUnica,
                          filterAll: false,
                        },
                        {
                          Header: "Fecha Solicitud",
                          minWidth: 80,
                          id: "FechaSolicitud",
                          accessor: (d) => d.FechaSolicitud,
                          filterAll: false,
                        },
                        {
                          Header: "CURP",
                          minWidth: 80,
                          id: "CURP",
                          accessor: (d) => d.CURP,
                          filterAll: false,
                        },

                        {
                          Header: "Solicitante",
                          minWidth: 80,
                          id: "Contacto",
                          accessor: (d) => d.NombreCompleto,
                          filterAll: false,
                        },
                        {
                          Header: "Telefono Fijo",
                          minWidth: 80,
                          id: "TelNegocio",
                          accessor: (d) => d.TelCelular,
                          filterAll: false,
                        },
                        {
                          Header: "Estatus",
                          minWidth: 80,
                          id: "Estatus",
                          accessor: (d) => d.Estatus,
                          filterAll: false,
                        },
                      ]}
                      defaultPageSize={10}
                      className="-striped -highlight primary-pagination"
                      loading={loading}
                      showPagination={true}
                      showPageJump={false}
                      canNextFromData={true}
                      defaultPageSize={10}
                      total={totalRemesa}
                      pages={Math.floor(totalRemesa / 10)}
                      previousText="Anterior"
                      nextText="Siguiente"
                      pageSizeOptions={[10, 20, 25, 50, 100]}
                      onFetchData={(stateComp, instance) => {
                        setLoading(true);
                        setearMenu();
                        //console.log('=>ViewAll: ', _menu.ViewAll);
                        console.log("==>stateComp.page:", stateComp.page);
                        console.log("==>_menu:", _menu);
                        console.log("==>token.user.idUser:", token.user.id);

                        let menu_local_storage = setearMenu("Storage");

                        const datax = {
                          page: stateComp.page,
                          tipo: "and",
                          pageSize: stateComp.pageSize,
                          sorted: [],
                          filtered: [],
                          idUser:
                            menu_local_storage.ViewAll == 0
                              ? token.user.id
                              : "",
                        };
                        console.log(
                          "=>getValesRegion GRID: ",
                          JSON.stringify(datax)
                        );
                        EjecutarApi(datax, "getValesRegion", token.token)
                          .then(function (res) {
                            return res;
                          })
                          .then((res) => {
                            setLoading(false);
                            settotalRemesa(res.total);
                            setData(
                              res.data.map((res, key) => {
                                //console.log('=>Items: ', res);
                                //console.log('=>ItemsStatus: ', res.idStatus.Estatus);
                                return {
                                  ClaveUnica: res.ClaveUnica,
                                  Region: res.Region,
                                  Municipio: res.Municipio,
                                  Localidad: res.Localidad,
                                  id: res.id,
                                  FechaSolicitud: res.FechaSolicitud,
                                  CURP: res.CURP,
                                  Nombre: res.Nombre,
                                  Paterno: res.Paterno,
                                  Materno: res.Materno,
                                  Sexo: res.Sexo,
                                  FechaNacimiento: res.FechaNacimiento,
                                  Calle: res.Calle,
                                  NumExt: res.NumExt,
                                  NumInt: res.NumInt,
                                  Colonia: res.Colonia,
                                  CP: res.CP,
                                  TelFijo: res.TelFijo,
                                  TelCelular: res.TelCelular,
                                  TelRecados: res.TelRecados,
                                  Compania: res.Compania,
                                  NombreCompleto: res.NombreCompleto,
                                  Capturo: res.Capturo,
                                  Articulador: res.Articulador,
                                  Estatus: res.Estatus,
                                  data: res,
                                };
                              })
                            );
                            //setLoadingGrupo(false);
                          })
                          .catch((e) => {
                            console.log("=Error[getExport]: ", e);
                            setLoading(false);
                            notify.show(
                              `Error al obtener Vales: ${e.errors}`,
                              "custom",
                              5000,
                              {
                                background: "#0857B6",
                                fontSize: 14,
                                text: "#FFFFFF",
                              }
                            );

                            //setLoadingGrupo(false);
                          });
                      }}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
          <FixedPlugin
            isLoading={loading}
            filtrar={setFilter}
            _menu={_menu}
            clasess="dropdowns"
          />
          <Footer fluid />
        </div>
      </div>
    </>
  );
};

export default Vales;
