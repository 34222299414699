import React, { useState, useEffect } from "react";
// react plugin used to create switch buttons
import { CircleSpinner } from "react-spinners-kit";
import Select from "react-select";
import moment from "moment";
import { Button, Input, Label, Form, FormGroup } from "reactstrap";
import "./styles.css";

import { notify } from "react-notify-toast";
import EjecutarApi from "../Consultas/Consultas";
import { getVars } from "../GlobalStorage";

function PluginFilter(props) {
  const [loading, setLoading] = useState(false);
  const token = getVars("Token");
  const [classes, setclasses] = useState("dropdown");
  const [FolioSolicitud, setFolioSolicitud] = useState("");
  const [FechaSolicitud, setFechaSolicitud] = useState("");
  const [Documentacion, setDocumentacion] = useState({
    value: "",
    label: "Cualquiera",
  });
  const [ValeEntregado, setValeEntregado] = useState({
    value: "",
    label: "Cualquiera",
  });
  const [labelFechaCaptura, setLabelFechaCaptura] = useState("");
  const [labelFechaCapturaFin, setLabelFechaCapturaFin] = useState("");
  const [FechaCaptura, setFechaCaptura] = useState([]);
  const [FechaCapturaFin, setFechaCapturaFin] = useState([]);
  const [catFechaVales, setCatFechaVales] = useState([]);
  const [Ejercicio, setEjercicio] = useState({ value: 2022, label: "2022" });

  const [SolicitanteNombre, setSolicitanteNombre] = useState("");
  const [CapturoNombre, setCapturoNombre] = useState("");
  const [CURP, setCURP] = useState("");
  const [CheckDuplicados, setCheckDuplicados] = useState(0);

  const [MultiValueMuninicpio, setMultiValueMunicipio] = useState([]);
  const [MultiValueMuninicpio2, setMultiValueMunicipio2] = useState([]);
  const [MultiValueColonia, setMultiValueColonia] = useState([]);
  const [filterColonia, setfilterColonia] = useState([]);
  const [MultiValueRemesa, setMultiValueRemesa] = useState([
    { value: "9999", label: "Con Remesa" },
  ]);
  const [MultiValueRemesa2, setMultiValueRemesa2] = useState([]);
  const [MultiValueEstatus, setMultiValueEstatus] = useState([]);
  const filterEstatus = [
    { value: "1", label: "Por Validar" },
    { value: "3", label: "No Aprobado Comité" },
    { value: "4", label: "Aprobado Comité" },
  ];
  const filterDocumentacion = [
    { value: "", label: "Cualquiera" },
    { value: "0", label: "No Entregado" },
    { value: "1", label: "Entregado" },
  ];
  const filterValeEntregado = [
    { value: "", label: "Cualquiera" },
    { value: "0", label: "No Entregado" },
    { value: "1", label: "Entregado" },
  ];
  const [MultiValueRegion, setMultiValueRegion] = useState([]);
  const [filterRegion, setfilterRegion] = useState([]);

  const [MultiValueArticulador, setMultiValueArticulador] = useState([]);
  const [MultiValueArticulador2, setMultiValueArticulador2] = useState([]);

  const [isLoading, setisLoading] = useState(false);
  //const [Filtered, setFiltered] = useState([]);
  const [_menu, _setmenu] = useState({});
  const datos = {
    page: 0,
    tipo: "and",
    pageSize: 500,
    sorted: [],
    filtered: [],
  };
  let style_spinner = {
    height: "500pt",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };
  useEffect(() => {
    setearMenu("Estado");
    const datosMpio = {
      page: 0,
      tipo: "and",
      pageSize: 500,
      sorted: [{ id: "Nombre", desc: false }],
      filtered: [{ id: "userID", value: token.user.id }],
      Regiones: token.regiones.length > 0 ? token.regiones : "",
    };
    //Municipios
    EjecutarApi(datosMpio, "getMunicipiosETVales", token.token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.results) {
          var region = [];
          setMultiValueMunicipio(
            res.data.map((municipio, i) => {
              if (region.indexOf(municipio["SubRegion"]) === -1) {
                region.push(municipio["SubRegion"]);
              }
              return {
                value: municipio["Id"],
                label: municipio["Nombre"],
              };
            })
          );
          var regio_n = region.sort((a, b) => a - b);
          setfilterRegion(
            regio_n.map((region_) => {
              return { value: region_, label: region_ };
            })
          );
        }
      })
      .catch((e) => {
        notify.show(`Error en la busqueda! ${e.message}`, "custom", 5000, {
          background: "#d61818",
          text: "#FFFFFF",
        });
      });
    //Articulador
    let datoArticulador = {};
    if (token.regiones.length === 0) {
      datoArticulador = {
        page: 0,
        tipo: "and",
        pageSize: 1000,
        sorted: [{ id: "Nombre", desc: false }],
        filtered: [],
        idUser: token.user.id,
      };
    } else {
      datoArticulador = {
        page: 0,
        tipo: "and",
        pageSize: 1000,
        sorted: [{ id: "Nombre", desc: false }],
        filtered: [],
        Regiones: token.regiones,
      };
    }
    EjecutarApi(datoArticulador, "getUsersArticuladoresV2", token.token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.results) {
          setMultiValueArticulador(
            res.data.map((data, i) => {
              return {
                value: data["id"],
                label: data["Nombre"],
              };
            })
          );
        }
      })
      .catch((e) => {
        notify.show(`Error en la busqueda! ${e.message}`, "custom", 5000, {
          background: "#d61818",
          text: "#FFFFFF",
        });
      });
    //Remesa  getRemesas
    let dataRemesa = {
      page: 0,
      tipo: "and",
      pageSize: 1000,
      sorted: [],
      filtered: [],
    };
    EjecutarApi(dataRemesa, "getRemesas", token.token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.results) {
          let _remesa = {};
          setMultiValueRemesa(
            MultiValueRemesa.concat(
              res.data.map((data, i) => {
                return {
                  value: data["Remesa"] === null ? "null" : data["Remesa"],
                  label:
                    data["Remesa"] === null ? "Sin Remesa" : data["Remesa"],
                };
              })
            )
          );
        }
      })
      .catch((e) => {
        notify.show(`Error en la busqueda! ${e.message}`, "custom", 5000, {
          background: "#d61818",
          text: "#FFFFFF",
        });
      });
    //Fechas
    let parametroFecha = {
      Propietario: token.user.id,
      Region: token.regiones,
    };
    EjecutarApi(parametroFecha, "getValesFecha", token.token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.results) {
          setCatFechaVales(
            res.data.map((data, i) => {
              return {
                value: i,
                label: data["FechaAlta"],
              };
            })
          );
          //catFechaVales.push({ value: -1, label: "seleccione" });
        }
      })
      .catch((e) => {
        notify.show(
          `Error en la Fecha de Captura! ${e.message}`,
          "custom",
          5000,
          {
            background: "#d61818",
            text: "#FFFFFF",
          }
        );
      });
  }, []);
  const handleMultiEstatus = (option) => {
    setMultiValueEstatus(option);
  };
  const handleFechaInicial = (option) => {
    let fInical = option.label;
    if (labelFechaCapturaFin === "") {
      setFechaCaptura(option);
      setLabelFechaCaptura(option.label);
    } else if (fInical > labelFechaCapturaFin) {
      notify.show(
        `La Fecha Fin Captura, no puede ser menor a la Fecha Inicio Captura!`,
        "custom",
        5000,
        {
          background: "#d61818",
          text: "#FFFFFF",
        }
      );
    } else {
      setFechaCaptura(option);
      setLabelFechaCaptura(option.label);
    }
  };
  const handleFechaFinal = (option) => {
    let ffin = option.label;

    if (labelFechaCaptura <= ffin) {
      setFechaCapturaFin(option);
      setLabelFechaCapturaFin(ffin);
    } else if (ffin === "Seleccionar...") {
      setFechaCapturaFin(option);
      setLabelFechaCapturaFin("");
    } else {
      notify.show(
        `La Fecha Fin Captura, no puede ser menor a la Fecha Inicio Captura!`,
        "custom",
        5000,
        {
          background: "#d61818",
          text: "#FFFFFF",
        }
      );
    }
  };

  const setFilter = (event) => {
    setisLoading(true);
    event.preventDefault();

    var myRegiones = [];
    const myFiltro = [];

    //myFiltro.push({ id: "Ejercicio", value: Ejercicio.value });
    if (CURP.length) myFiltro.push({ id: "vales.CURP", value: CURP });

    if (Documentacion.value !== "") {
      myFiltro.push({
        id: "vales.isDocumentacionEntrega",
        value: Documentacion.value,
      });
    }

    if (ValeEntregado.value !== "") {
      myFiltro.push({ id: "vales.isEntregado", value: ValeEntregado.value });
    }

    if (FechaSolicitud.length > 1)
      myFiltro.push({ id: "vales.FechaSolicitud", value: FechaSolicitud });

    if (labelFechaCaptura.length > 1) {
      if (labelFechaCaptura !== "Seleccionar...") {
        myFiltro.push({
          id: "vales.created_at",
          value: labelFechaCaptura,
        });
      }
    }
    if (labelFechaCapturaFin.length > 1) {
      if (labelFechaCapturaFin !== "Seleccionar...") {
        myFiltro.push({
          id: "FechaCapturaFin",
          value: labelFechaCapturaFin,
        });
      }
    }

    var x = 0;
    if (MultiValueEstatus != null)
      for (x = 0; x < MultiValueEstatus.length; x++)
        myFiltro.push({
          id: "vales_status.Estatus",
          value: MultiValueEstatus[x].label,
        });

    //region
    if (MultiValueRegion != null)
      for (x = 0; x < MultiValueRegion.length; x++)
        myRegiones.push(MultiValueRegion[x].label);

    //setFiltered(myFiltro);
    var datos_ = {};
    if (token.regiones.length > 0) {
      datos_ = {
        page: 0,
        tipo: "or",
        Propietario: _menu.ViewAll === 1 ? "All" : token.user.id,
        pageSize: 10,
        sorted: [
          { id: "vales.Paterno", desc: false },
          { id: "vales.FechaSolicitud", desc: true },
        ],
        Duplicados: CheckDuplicados,
        NombreCompleto: SolicitanteNombre.length === 0 ? "" : SolicitanteNombre,
        Ejercicio:
          Ejercicio.value === 0 ? moment().format("Y") : Ejercicio.value,
        NombreCreated: CapturoNombre.length === 0 ? "" : CapturoNombre,
        UserOwned:
          MultiValueArticulador2.length > 0 ? MultiValueArticulador2 : "",
        Remesa: MultiValueRemesa2.length > 0 ? MultiValueRemesa2 : "",
        idMunicipio:
          MultiValueMuninicpio2.length > 0 ? MultiValueMuninicpio2 : "",
        Colonia: MultiValueColonia.length > 0 ? MultiValueColonia : "",
        Regiones: myRegiones.length > 0 ? myRegiones : token.regiones,
        Folio: FolioSolicitud.length > 0 ? FolioSolicitud : "",
        filtered: myFiltro,
      };
    } else {
      if (MultiValueRegion != null)
        for (x = 0; x < MultiValueRegion.length; x++)
          myFiltro.push({
            id: "et_cat_municipio.SubRegion",
            value: MultiValueRegion[x].label,
          });

      datos_ = {
        page: 0,
        tipo: "or",
        Propietario: _menu.ViewAll === 1 ? "All" : token.user.id,
        pageSize: 10,
        sorted: [
          { id: "vales.Paterno", desc: false },
          { id: "vales.FechaSolicitud", desc: true },
        ],
        filtered: myFiltro,
        Folio: FolioSolicitud.length > 0 ? FolioSolicitud : "",
        Ejercicio:
          Ejercicio.value === 0 ? moment().format("Y") : Ejercicio.value,
        NombreCompleto: SolicitanteNombre.length === 0 ? "" : SolicitanteNombre,
        NombreCreated: CapturoNombre.length === 0 ? "" : CapturoNombre,
        UserOwned:
          MultiValueArticulador2.length > 0 ? MultiValueArticulador2 : "",
        Remesa: MultiValueRemesa2.length > 0 ? MultiValueRemesa2 : "",
        idMunicipio:
          MultiValueMuninicpio2.length > 0 ? MultiValueMuninicpio2 : "",
        Colonia: MultiValueColonia.length > 0 ? MultiValueColonia : "",
        Regiones: "",
      };
    }
    setisLoading(false);
    props.filtrar(datos_);
  };
  const setearMenu = (dependecia) => {
    if (dependecia === "Estado") {
      token.menu.forEach((prop, key) => {
        if (prop.Menu === "Vales") {
          _setmenu({
            Ver: prop.Ver,
            Agregar: prop.Agregar,
            Editar: prop.Editar,
            Eliminar: prop.Eliminar,
            Seguimiento: prop.Seguimiento,
            Exportar: prop.Exportar,
            Imprimir: prop.Imprimir,
            ViewAll: prop.ViewAll,
            idTipoUser: token.user.idTipoUser.id,
          });
        }
      });
    } else {
      var json = {};

      token.menu.forEach((prop, key) => {
        if (prop.Menu === "Vales") {
          json = {
            Ver: prop.Ver,
            Agregar: prop.Agregar,
            Editar: prop.Editar,
            Eliminar: prop.Eliminar,
            Seguimiento: prop.Seguimiento,
            Exportar: prop.Exportar,
            Imprimir: prop.Imprimir,
            ViewAll: prop.ViewAll,
            idTipoUser: token.user.idTipoUser.id,
            regiones: token.regiones,
          };
        }
      });

      return json;
    }
  };
  const handleClick = () => {
    if (classes === "dropdown") {
      setclasses("dropdown show");
    } else {
      setclasses("dropdown");
    }
  };

  const handleMultiRegion = (option) => {
    setMultiValueRegion(option);
  };

  const handleColonia = (arrMunicipio) => {
    setLoading(true);
    var datosColonia = {
      page: 0,
      tipo: "and",
      pageSize: 9999,
      sorted: [{ id: "d_asenta", desc: false }],
      filtered: [],
      idMunicipio: arrMunicipio,
    };
    EjecutarApi(datosColonia, "getCP", token.token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.results) {
          var colonias_ = res.data.map((cp) => {
            return {
              value: cp["d_asenta"],
              label: cp["d_asenta"],
            };
          });

          setfilterColonia(colonias_);
          setLoading(false);
        }
      })
      .catch((e) => {
        notify.show(`Error en la busqueda! ${e.message}`, "custom", 5000, {
          background: "#d61818",
          text: "#FFFFFF",
        });
        setLoading(false);
      });
  };
  const handleFolio = (event) => {
    setFolioSolicitud(event);
  };
  return (
    <div className="fixed-plugin">
      <Form onSubmit={setFilter}>
        <div className={classes}>
          <div onClick={handleClick}>
            <i className="fa fa-cog fa-2x" />
          </div>
          <ul className="dropdown-menu show ul">
            <li className="adjustments-line">Ejercicio Fiscal:</li>
            <li className="li">
              <FormGroup sm="10">
                <Select
                  className="SelectSize"
                  name="Ejercicio"
                  placeholder="Seleccione..."
                  options={[
                    { value: 2022, label: "2022" },
                    { value: 2021, label: "2021" },
                    { value: 2020, label: "2020" },
                  ]}
                  value={Ejercicio}
                  onChange={(Ejercicio) => setEjercicio(Ejercicio)}
                />
              </FormGroup>
            </li>

            <li className="adjustments-line">Fecha Inicio Captura:</li>
            <li className="li">
              <FormGroup sm="10">
                <Select
                  className="SelectSize"
                  name="FechaSolicitud"
                  placeholder="Seleccione..."
                  options={catFechaVales}
                  value={FechaCaptura}
                  onChange={handleFechaInicial}
                />
              </FormGroup>
            </li>

            <li className="adjustments-line">Fecha Fin Captura:</li>
            <li className="li">
              <Select
                className="SelectSize"
                name="FechaFin"
                placeholder="Seleccione..."
                options={catFechaVales}
                value={FechaCapturaFin}
                onChange={handleFechaFinal}
              />
            </li>

            <li className="adjustments-line">Fecha Solicitud:</li>
            <Input
              type="date"
              name="FechaSolicitud"
              id="FechaSolicitud"
              placeholder="Fecha Solicitud"
              value={FechaSolicitud}
              onChange={(e) => setFechaSolicitud(e.target.value)}
              className="form-control"
            />
            <li className="adjustments-line">
              <Input
                placeholder="Folio de Registro"
                id="folioCaptura"
                value={FolioSolicitud}
                type="text"
                onChange={(e) => setFolioSolicitud(e.target.value)}
              />
            </li>
            <li className="adjustments-line">
              <Input
                placeholder="Capturo"
                id="NombreCapturo"
                value={CapturoNombre}
                type="text"
                onChange={(e) => setCapturoNombre(e.target.value)}
              />
            </li>
            <li className="adjustments-line">
              <Input
                placeholder="Solicitante"
                id="NombreSolicitante"
                value={SolicitanteNombre}
                type="text"
                onChange={(e) => setSolicitanteNombre(e.target.value)}
              />
            </li>
            <li className="adjustments-line">
              <Input
                placeholder="CURP"
                id="CURP"
                value={CURP}
                type="text"
                onChange={(e) => setCURP(e.target.value)}
              />
            </li>
            <li className="adjustments-line">
              <Label>Articulador:</Label>
            </li>
            <li className="li">
              <Select
                className="SelectSize"
                name="articulador"
                placeholder="Articulador"
                options={MultiValueArticulador}
                onChange={(event, i) => {
                  if (event) {
                    setMultiValueArticulador2(
                      event.map((dato) => {
                        return dato.value;
                      })
                    );
                  } else {
                    setMultiValueArticulador2({
                      MultiValueArticulador2: MultiValueArticulador2.map(
                        (dato) => {
                          if (i.removedValue.value !== dato) {
                            return dato.value;
                          }
                        }
                      ),
                    });
                  }
                }}
                isMulti
              />
            </li>
            <li className="adjustments-line">
              <Label>Región:</Label>
            </li>
            <li className="li">
              <Select
                name="region"
                className="SelectSize"
                placeholder="Región"
                value={MultiValueRegion}
                options={filterRegion}
                onChange={handleMultiRegion}
                isMulti
              />
            </li>
            <li className="adjustments-line">
              <Label>Municipio:</Label>
            </li>
            <li className="li">
              <Select
                className="SelectSize"
                name="Municipio"
                placeholder="Municipio"
                options={MultiValueMuninicpio}
                onChange={(event, i) => {
                  if (event) {
                    setMultiValueMunicipio2(
                      event.map((dato) => {
                        return dato.value;
                      })
                    );
                    handleColonia(
                      event.map((dato) => {
                        return dato.value;
                      })
                    );
                  } else {
                    setMultiValueMunicipio2({
                      MultiValueMuninicpio2: MultiValueMuninicpio2.map(
                        (dato) => {
                          if (i.removedValue.value !== dato) {
                            return dato.value;
                          }
                        }
                      ),
                    });
                    handleColonia(MultiValueMuninicpio2);
                  }
                }}
                isMulti
              />
            </li>
            <li className="adjustments-line">
              <Label>Colonia:</Label>
            </li>
            <li className="li">
              {loading && (
                <CircleSpinner
                  className="text-center"
                  size={30}
                  color="#686769"
                  loading={loading}
                />
              )}
              <Select
                className="SelectSize"
                name="colonia"
                placeholder="Colonia"
                options={filterColonia}
                onChange={(event, i) => {
                  if (event) {
                    setMultiValueColonia(
                      event.map((dato) => {
                        return dato.label;
                      })
                    );
                  } else {
                    setMultiValueColonia({
                      MultiValueColonia: MultiValueColonia.map((dato) => {
                        if (i.removedValue.label !== dato) {
                          return dato.label;
                        }
                      }),
                    });
                  }
                }}
                isMulti
              />
            </li>

            <li className="adjustments-line">
              <Label>Estatus:</Label>
            </li>
            <li className="li">
              <Select
                name="Estatus"
                className="SelectSize"
                placeholder="Estatus"
                value={MultiValueEstatus}
                options={filterEstatus}
                onChange={handleMultiEstatus}
                isMulti
              />
            </li>
            <li className="adjustments-line">
              <Label>Expediente Recepcionado:</Label>
            </li>
            <li className="li">
              <Select
                className="SelectSize"
                name="Documentacion"
                value={Documentacion}
                placeholder="Documentacion"
                options={filterDocumentacion}
                onChange={(event) => setDocumentacion(event)}
              />
            </li>
            <li className="adjustments-line">
              <Label>Vale Entregado:</Label>
            </li>
            <li className="li">
              <Select
                className="SelectSize"
                name="ValeEntregado"
                value={ValeEntregado}
                placeholder="Entrega de Vales"
                options={filterValeEntregado}
                onChange={(event) => setValeEntregado(event)}
              />
            </li>

            <li className="adjustments-line">
              <Label>Remesa:</Label>
            </li>
            <li className="li">
              <Select
                className="SelectSize"
                name="remesa"
                placeholder="Remesa"
                options={MultiValueRemesa}
                onChange={(event, i) => {
                  if (event) {
                    setMultiValueRemesa2(
                      event.map((dato) => {
                        return dato.value;
                      })
                    );
                  } else {
                    setMultiValueRemesa2({
                      MultiValueRemesa2: MultiValueRemesa2.map((dato) => {
                        if (i.removedValue.value !== dato) {
                          return dato.value;
                        }
                      }),
                    });
                  }
                }}
                isMulti
              />
            </li>

            <li className="adjustments-line">
              <Label
                style={{
                  display: "block",
                  overflow: "hidden",
                  float: "left",
                }}
              >
                {"Solo Duplicados:  "}
              </Label>{" "}
              <Input
                style={{
                  verticalAlign: "middle",
                  float: "right",
                  marginLeft: "5pt",
                }}
                type="checkbox"
                onChange={(e) => {
                  if (e.target.checked) {
                    setCheckDuplicados(1);
                  } else {
                    setCheckDuplicados(0);
                  }
                }}
              />
            </li>

            <li className="adjustments-line" />
            <li className="button-container">
              <span>
                {!isLoading && (
                  <Button
                    color="info"
                    block
                    className="btn-round"
                    type="submit"
                  >
                    Filtrar
                  </Button>
                )}
                {isLoading && (
                  <CircleSpinner
                    className="text-center"
                    size={30}
                    color="#686769"
                    loading={isLoading}
                  />
                )}
              </span>
            </li>

            <li className="li"></li>
          </ul>
        </div>
      </Form>
    </div>
  );
}
export default PluginFilter;
