import React, { useState, useEffect } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  CardText,
  CardTitle,
  Col,
  Row,
  ListGroup,
  Button,
  ListGroupItem,
  CardFooter,
} from "reactstrap";
import { notify } from "react-notify-toast";
import { URL_GENERAL } from "../../data/consts";
import { CircleSpinner } from "react-spinners-kit";
import EjecutarApi from "../../Consultas/Consultas";
import { setVars, getVars } from "../../GlobalStorage";

const ThirdStep = (props) => {
  const [groupList, setGroupList] = useState([]);
  const [loading, setLoading] = useState(false);
  const steps = getVars("StepForm");

  useEffect(() => {
    setLoading(true);

    if (steps.step === 3 && steps.Cve) {
      generateFiles();
    }
    setLoading(false);
  }, []);

  const handleReset = () => {
    sessionStorage.removeItem("StepForm");
    window.location.reload();
  };

  const generateFiles = () => {
    setLoading(true);
    const token = getVars("Token").token;
    const params = {
      Cve: steps.Cve,
    };
    EjecutarApi(params, "vales/getGroupList", token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        setGroupList(res.data);
      })
      .catch((e) => {
        notify.show(`Ocurrio un error,${e.message}`, "custom", 5000, {
          background: "#0857B6",
          fontSize: 14,
          text: "#FFFFFF",
        });
      });
    setLoading(false);
  };

  const handleDownload = (item) => {
    const token = getVars("Token").token;
    var url = new URL(
      URL_GENERAL +
        `getReporteEntregaVales2023?idGrupo=${item.id}&token=${token}`
    );
    window.open(url, "_blank");
  };

  return (
    <Card>
      <CardHeader className="text-center">
        <CardTitle>
          <h3>Grupos</h3>
        </CardTitle>
        <Row>
          <Col sm={12} md={{ size: 4, offset: 4 }}>
            <Button
              className="btn btn-success btn-block"
              onClick={generateFiles}
              size="sm"
            >
              Generar listados
            </Button>
          </Col>
        </Row>
        <CardText>
          Descarga los archivos generados para los grupos para iniciar
          nuevamente el proceso
        </CardText>
      </CardHeader>
      <CardBody>
        <Row>
          <Col sm={12} md={12}>
            {loading && (
              <CircleSpinner
                className="text-center"
                size={100}
                color="#686769"
                loading={loading}
              />
            )}
            <ListGroup>
              {groupList &&
                groupList.map((item, index) => (
                  <ListGroupItem key={index}>
                    <Row>
                      <Col sm={12} md={11}>
                        <div className="ms-2 me-auto">
                          <div className="fw-bold">{item.label}</div>
                          Responsable de Entrega: {item.Responsable}
                        </div>
                      </Col>
                      <Col sm={12} md={1}>
                        <Button
                          size="small"
                          color="info"
                          onClick={(e) => handleDownload(item)}
                          // style={{ padding: 5 }}
                        >
                          <span className="btn-label">
                            <i className="fa fa-download fa-1x" />
                          </span>
                        </Button>
                      </Col>
                    </Row>
                  </ListGroupItem>
                ))}
            </ListGroup>
          </Col>
        </Row>
      </CardBody>
      <CardFooter>
        <Row>
          <Col sm={12} md={{ size: 4, offset: 4 }}>
            <Button
              className="btn btn-success btn-block"
              onClick={handleReset}
              disabled={loading}
            >
              Comenzar de nuevo
            </Button>
          </Col>
        </Row>
      </CardFooter>
    </Card>
  );
};

export default ThirdStep;
