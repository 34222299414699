var CryptoJS = require("crypto-js");
const {
  REACT_APP_BASE_URL,
  REACT_APP_SECRET_KEY,
  REACT_APP_DEBUG,
  REACT_APP_API_KEY
} = process.env;

export const URL_PUBLICA = `${REACT_APP_BASE_URL}/`;
export const APIKEY = REACT_APP_API_KEY;
export const DEBUG = (REACT_APP_DEBUG.toLowerCase() === "true");

const substitutionsAfterEncryption = new Map([
  ['+', '-'],
  ['/', '_'],
  ['=', '~'],
]);
const substitutionsBeforeDecryption = new Map([
  ['-', '+'],
  ['_', '/'],
  ['~', '='],
]);

export const encryptVars = (text) => {
  const encrypted = CryptoJS.AES.encrypt(
    JSON.stringify(text),
    REACT_APP_SECRET_KEY
  ).toString();

  return encrypted.replace(
    /[+/=]/g,
    (match) => substitutionsAfterEncryption.get(match) ?? match
  );
}

export const decryptVars = (text, defaultvar) => {
  let decrypted = null;

  const toDecrypt = text.replace(
    /[-_~]/g,
    (match) => substitutionsBeforeDecryption.get(match) ?? match
  );

  if (toDecrypt !== null) {
    const bytes = CryptoJS.AES.decrypt(toDecrypt, REACT_APP_SECRET_KEY);
    decrypted = bytes.toString(CryptoJS.enc.Utf8);
  }

  return decrypted !== null ? JSON.parse(decrypted) : defaultvar;
}

export const renapoValidation = (CURP) => {
  return fetch(`https://seguimiento.guanajuato.gob.mx/apiinformacionsocial/api/renapo/porcurp/pL@t_1n|Run$28/${CURP}/7`)
    .then(function (res) {
      return res.json();
    })
    .then((res) => {
      return res;
    })
    .catch((e) => {
      return e;
    });
}