import React, { useState, useEffect } from "react";
import { Line, Bar, Doughnut } from "react-chartjs-2";
import moment from "moment";
import Notifications, { notify } from "react-notify-toast";
// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Label,
  FormGroup,
  Input,
  Table,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";

import {
  chartExample1,
  chartExample2,
  chartExample3,
  chartExample4,
  chartExample5,
  chartExample6,
  chartExample7,
  chartExample8,
} from "../variables/charts.jsx";

import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Sidebar from "../components/Sidebar";
import FixedPlugin from "../components/PluginFilterVales";

import { getVars } from "../GlobalStorage";
import EjecutarApi from "../Consultas/Consultas";
import ChartHorizontalBar from "../components/graficas/Horizontal";

var mapData = {
  AU: 760,
  BR: 550,
  CA: 120,
  DE: 1300,
  FR: 540,
  GB: 690,
  GE: 200,
  IN: 200,
  RO: 600,
  RU: 300,
  US: 2920,
};

var DataporEdad = [
  {
    label_Vertical: "Mayor a 45",
    label_Horizontal: 7,
  },
  {
    label_Vertical: "Entre 41 y 45",
    label_Horizontal: 6,
  },
  {
    label_Vertical: "Entre 36 y 40",
    label_Horizontal: 11,
  },
  {
    label_Vertical: "Entre 31 y 35",
    label_Horizontal: 8,
  },
  {
    label_Vertical: "Entre 26 y 30",
    label_Horizontal: 22,
  },
  {
    label_Vertical: "Entre 21 y 25",
    label_Horizontal: 138,
  },
  {
    label_Vertical: "Entre 16 y 20",
    label_Horizontal: 151,
  },
];

const ResumenAldo = (props) => {
  const [loading, setLoading] = useState(false);
  const [TotalSolicitudes, setTotalSolicitudes] = useState(0);
  const [TotalValidadas, setTotalValidadas] = useState(0);
  const [TotalAprobadas, setTotalAprobadas] = useState(0);
  const [TotalEntregadas, setTotalEntregadas] = useState(0);
  const [regiones, setRegiones] = useState([]);
  const [municipios, setMunicipios] = useState([]);

  const handleMiniClick = () => {
    document.body.classList.toggle("sidebar-mini");
  };

  useEffect(() => {
    const token = getVars("Token").token;
    EjecutarApi({}, "getEstatusGlobalVentanillaVales", token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        console.log("ZINCRI -->", res);
        if (res.results) {
          setTotalSolicitudes(res.data[0].Solicitudes);
          setTotalAprobadas(res.data[0].AprobadoComite);
          setTotalValidadas(res.data[0].ProcesoValidacion);
          setTotalEntregadas(res.data[0].Entregados);
        }
      })
      .catch((e) => {
        notify.show(
          `Error en la obtencion de datos! ${e.message}`,
          "custom",
          5000,
          {
            background: "#d61818",
            text: "#FFFFFF",
          }
        );
      });

    EjecutarApi({}, "getEstatusRegion", token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.results) {
          setRegiones(res.data);
        }
      })
      .catch((e) => {
        notify.show(
          `Error en la obtencion de datos! ${e.message}`,
          "custom",
          5000,
          {
            background: "#d61818",
            text: "#FFFFFF",
          }
        );
      });

    const datos_request = {
      page: 0,
      tipo: "and",
      pageSize: 50,
      sorted: [{ id: "meta_municipio.Region", desc: false }],
      filtered: [],
    };

    EjecutarApi(datos_request, "getEstatusMunicipio", token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.results) {
          setMunicipios(res.data);
        }
      })
      .catch((e) => {
        notify.show(
          `Error en la obtencion de datos! ${e.message}`,
          "custom",
          5000,
          {
            background: "#d61818",
            text: "#FFFFFF",
          }
        );
      });
  }, []);
  console.log("ZINCRI>>>>>>>>>", municipios);
  return (
    <>
      <div className="wrapper">
        <Sidebar {...props} bgColor="black" activeColor="info" />
        <div className="main-panel">
          <Navbar {...props} handleMiniClick={handleMiniClick} />
          <Notifications />
          <div className="content">
            <Row>
              <Col lg="3" md="6" sm="6">
                <Card className="card-stats">
                  <CardBody>
                    <Row>
                      <Col md="4" xs="5">
                        <div className="icon-big text-center icon-warning">
                          <i className="nc-icon nc-globe text-warning" />
                        </div>
                      </Col>
                      <Col md="8" xs="7">
                        <div className="numbers">
                          <p className="card-category">Solicitudes</p>
                          <CardTitle tag="p">{TotalSolicitudes}</CardTitle>
                          <p />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                  <CardFooter>
                    <hr />
                    <div className="stats">
                      <i className="fa fa-refresh" />
                      Recibidas hasta ahora
                    </div>
                  </CardFooter>
                </Card>
              </Col>
              <Col lg="3" md="6" sm="6">
                <Card className="card-stats">
                  <CardBody>
                    <Row>
                      <Col md="4" xs="5">
                        <div className="icon-big text-center icon-warning">
                          <i className="nc-icon nc-money-coins text-success" />
                        </div>
                      </Col>
                      <Col md="8" xs="7">
                        <div className="numbers">
                          <p className="card-category">Solicitudes Validadas</p>
                          <CardTitle tag="p"> {TotalValidadas}</CardTitle>
                          <p />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                  <CardFooter>
                    <hr />
                    <div className="stats">
                      <i className="fa fa-calendar-o" />
                      En revisión del comité
                    </div>
                  </CardFooter>
                </Card>
              </Col>
              <Col lg="3" md="6" sm="6">
                <Card className="card-stats">
                  <CardBody>
                    <Row>
                      <Col md="4" xs="5">
                        <div className="icon-big text-center icon-warning">
                          <i className="nc-icon nc-vector text-danger" />
                        </div>
                      </Col>
                      <Col md="8" xs="7">
                        <div className="numbers">
                          <p className="card-category">Aprobados Comité</p>
                          <CardTitle tag="p">{TotalAprobadas}</CardTitle>
                          <p />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                  <CardFooter>
                    <hr />
                    <div className="stats">
                      <i className="fa fa-clock-o" />
                      {TotalAprobadas} de {TotalValidadas} (
                      {(TotalAprobadas / TotalValidadas) * 100} %)
                    </div>
                  </CardFooter>
                </Card>
              </Col>
              <Col lg="3" md="6" sm="6">
                <Card className="card-stats">
                  <CardBody>
                    <Row>
                      <Col md="4" xs="5">
                        <div className="icon-big text-center icon-warning">
                          <i className="nc-icon nc-favourite-28 text-primary" />
                        </div>
                      </Col>
                      <Col md="8" xs="7">
                        <div className="numbers">
                          <p className="card-category">Apoyos Entregados</p>
                          <CardTitle tag="p">{TotalEntregadas}</CardTitle>
                          <p />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                  <CardFooter>
                    <hr />
                    <div className="stats">
                      <i className="fa fa-refresh" />0 de 0 (0.00 %)
                    </div>
                  </CardFooter>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <Card>
                  <CardHeader>
                    <CardTitle tag="h4">
                      Resumen general de entregas por Región
                    </CardTitle>
                    <p className="card-category">
                      corte al {moment(new Date()).format("YYYY-MM-DD")}
                    </p>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col md="6">
                        <Table responsive>
                          <thead>
                            <tr>
                              <th className="text-center">Región</th>
                              <th className="text-center">Municipios</th>
                              <th className="text-center">Solicitudes</th>
                              <th className="text-center">Apoyos</th>
                              <th className="text-center">Entregado</th>
                            </tr>
                          </thead>
                          <tbody>
                            {regiones.map((region, i) => {
                              return (
                                <tr key={i + 1}>
                                  <td className="text-center">
                                    {"   "}Región {i + 1} {"   "}
                                  </td>
                                  <td className="text-center">
                                    {region.Municipio}{" "}
                                  </td>
                                  <td className="text-center">
                                    {region.Solicitudes}
                                  </td>
                                  <td className="text-center">
                                    {region.Apoyos}
                                  </td>
                                  <td className="text-center">
                                    {region.AvanceSolicitudes + " %"}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      </Col>
                      <Col className="ml-auto mr-auto text-center" md="6">
                        <ChartHorizontalBar DataporEdad />
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            {/* <Row>
                        <Col lg="4" sm="6">
                          <Card>
                            <CardHeader>
                              <Row>
                                <Col sm="7">
                                  <div className="numbers pull-left">$34,657</div>
                                </Col>
                                <Col sm="5">
                                  <div className="pull-right">
                                    <Badge color="success" pill>
                                      +18%
                                    </Badge>
                                  </div>
                                </Col>
                              </Row>
                            </CardHeader>
                            <CardBody>
                              <h6 className="big-title">
                                recurso entregado
                              </h6>
                              <Line
                                data={chartExample1.data}
                                options={chartExample1.options}
                                height={380}
                                width={826}
                              />
                            </CardBody>
                            <CardFooter>
                              <hr />
                              <Row>
                                <Col sm="7">
                                  <div className="footer-title">Avance Entrega de Recursos</div>
                                </Col>
                                <Col sm="5">
                                  <div className="pull-right">
                                    <Button
                                      className="btn-round btn-icon"
                                      color="success"
                                      size="sm"
                                    >
                                      <i className="nc-icon nc-simple-add" />
                                    </Button>
                                  </div>
                                </Col>
                              </Row>
                            </CardFooter>
                          </Card>
                        </Col>
                        <Col lg="4" sm="6">
                          <Card>
                            <CardHeader>
                              <Row>
                                <Col sm="7">
                                  <div className="numbers pull-left">169</div>
                                </Col>
                                <Col sm="5">
                                  <div className="pull-right">
                                    <Badge color="danger" pill>
                                      -14%
                                    </Badge>
                                  </div>
                                </Col>
                              </Row>
                            </CardHeader>
                            <CardBody>
                              <h6 className="big-title">
                                total subscriptions in last 7 days
                              </h6>
                              <Line
                                data={chartExample2.data}
                                options={chartExample2.options}
                                height={380}
                                width={828}
                              />
                            </CardBody>
                            <CardFooter>
                              <hr />
                              <Row>
                                <Col sm="7">
                                  <div className="footer-title">View all members</div>
                                </Col>
                                <Col sm="5">
                                  <div className="pull-right">
                                    <Button
                                      className="btn-round btn-icon"
                                      color="danger"
                                      size="sm"
                                    >
                                      <i className="nc-icon nc-button-play" />
                                    </Button>
                                  </div>
                                </Col>
                              </Row>
                            </CardFooter>
                          </Card>
                        </Col>
                        <Col lg="4" sm="6">
                          <Card>
                            <CardHeader>
                              <Row>
                                <Col sm="7">
                                  <div className="numbers pull-left">8,960</div>
                                </Col>
                                <Col sm="5">
                                  <div className="pull-right">
                                    <Badge color="warning" pill>
                                      ~51%
                                    </Badge>
                                  </div>
                                </Col>
                              </Row>
                            </CardHeader>
                            <CardBody>
                              <h6 className="big-title">total downloads in last 6 years</h6>
                              <Line
                                data={chartExample3.data}
                                options={chartExample3.options}
                                height={380}
                                width={826}
                              />
                            </CardBody>
                            <CardFooter>
                              <hr />
                              <Row>
                                <Col sm="7">
                                  <div className="footer-title">View more details</div>
                                </Col>
                                <Col sm="5">
                                  <div className="pull-right">
                                    <Button
                                      className="btn-round btn-icon"
                                      color="warning"
                                      size="sm"
                                    >
                                      <i className="nc-icon nc-alert-circle-i" />
                                    </Button>
                                  </div>
                                </Col>
                              </Row>
                            </CardFooter>
                          </Card>
                        </Col>
                      </Row>
 */}
            <Row>
              {/* AQUI VOY A ITERAR LAS 7regiones */}
              {regiones.map((region, i) => {
                return (
                  <Col md="6">
                    <Card className="card-tasks">
                      <CardHeader>
                        <CardTitle tag="h4">Region {i + 1}</CardTitle>
                        <h5 className="card-category">
                          {region.AprobadosComite} de {region.Solicitudes}{" "}
                          Solicitudes ({region.AvanceSolicitudes} %)
                        </h5>
                      </CardHeader>
                      <CardBody>
                        <div className="table-full-width table-responsive">
                          <Table>
                            <thead>
                              <tr>
                                <th className="text-center">Municipios</th>
                                <th className="text-right">Aprobados</th>
                                <th className="text-right">Apoyos</th>
                                <th className="text-right">Total</th>
                              </tr>
                            </thead>
                            <tbody>
                              {municipios.map((municipio) => {
                                if (municipio.Region === i + 1) {
                                  return (
                                    <tr>
                                      <td className="text-left">
                                        {municipio.Municipio !== null
                                          ? municipio.Municipio
                                          : "Sin Nombre"}
                                      </td>
                                      <td className="text-center">
                                        {municipio.AprobadoComite !== null
                                          ? municipio.AprobadoComite
                                          : 0}
                                      </td>
                                      <td className="text-center">
                                        {municipio.Apoyos !== null
                                          ? municipio.Apoyos
                                          : 0}
                                      </td>
                                      <td className="text-center">
                                        {municipio.Total !== null
                                          ? municipio.Total
                                          : 0}
                                      </td>
                                    </tr>
                                  );
                                }
                              })}
                            </tbody>
                          </Table>
                        </div>
                      </CardBody>
                      <CardFooter>
                        <hr />
                        <div className="stats">
                          <i className="fa fa-refresh spin" />
                          Actualizado el{" "}
                          {moment(new Date()).format("YYYY-MM-DD")}
                        </div>
                      </CardFooter>
                    </Card>
                  </Col>
                );
              })}
            </Row>
            {/* <Row>
                        <Col md="3">
                          <Card>
                            <CardHeader>
                              <CardTitle>Email Statistics</CardTitle>
                              <p className="card-category">Last Campaign Performance</p>
                            </CardHeader>
                            <CardBody>
                              <Doughnut
                                data={chartExample5.data}
                                options={chartExample5.options}
                                className="ct-chart ct-perfect-fourth"
                                height={300}
                                width={456}
                              />
                            </CardBody>
                            <CardFooter>
                              <div className="legend">
                                <i className="fa fa-circle text-info" />
                                Open
                              </div>
                              <hr />
                              <div className="stats">
                                <i className="fa fa-calendar" />
                                Number of emails sent
                              </div>
                            </CardFooter>
                          </Card>
                        </Col>
                        <Col md="3">
                          <Card>
                            <CardHeader>
                              <CardTitle>New Visitators</CardTitle>
                              <p className="card-category">Out Of Total Number</p>
                            </CardHeader>
                            <CardBody>
                              <Doughnut
                                data={chartExample6.data}
                                options={chartExample6.options}
                                className="ct-chart ct-perfect-fourth"
                                height={300}
                                width={456}
                              />
                            </CardBody>
                            <CardFooter>
                              <div className="legend">
                                <i className="fa fa-circle text-warning" />
                                Visited
                              </div>
                              <hr />
                              <div className="stats">
                                <i className="fa fa-check" />
                                Campaign sent 2 days ago
                              </div>
                            </CardFooter>
                          </Card>
                        </Col>
                        <Col md="3">
                          <Card>
                            <CardHeader>
                              <CardTitle>Orders</CardTitle>
                              <p className="card-category">Total number</p>
                            </CardHeader>
                            <CardBody>
                              <Doughnut
                                data={chartExample7.data}
                                options={chartExample7.options}
                                className="ct-chart ct-perfect-fourth"
                                height={300}
                                width={456}
                              />
                            </CardBody>
                            <CardFooter>
                              <div className="legend">
                                <i className="fa fa-circle text-danger" />
                                Completed
                              </div>
                              <hr />
                              <div className="stats">
                                <i className="fa fa-clock-o" />
                                Updated 3 minutes ago
                              </div>
                            </CardFooter>
                          </Card>
                        </Col>
                        <Col md="3">
                          <Card>
                            <CardHeader>
                              <CardTitle>Subscriptions</CardTitle>
                              <p className="card-category">Our Users</p>
                            </CardHeader>
                            <CardBody>
                              <Doughnut
                                data={chartExample8.data}
                                options={chartExample8.options}
                                className="ct-chart ct-perfect-fourth"
                                height={300}
                                width={456}
                              />
                            </CardBody>
                            <CardFooter>
                              <div className="legend">
                                <i className="fa fa-circle text-secondary" />
                                Ended
                              </div>
                              <hr />
                              <div className="stats">
                                <i className="fa fa-history" />
                                Total users
                              </div>
                            </CardFooter>
                          </Card>
                        </Col>
                      </Row>
 */}{" "}
          </div>

          <Footer fluid />
        </div>
      </div>
    </>
  );
};

export default ResumenAldo;
