import React, { useEffect, useState } from "react";
import ReactTable from "react-table";
import { useHistory } from "react-router-dom";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  FormGroup,
  Form,
  Input,
  Label,
} from "reactstrap";
import { ImpulseSpinner } from "react-spinners-kit";
import Notifications, { notify } from "react-notify-toast";
import ReactBSAlert from "react-bootstrap-sweetalert";
import EjecutarApi from "../Consultas/Consultas";
import { getVars } from "../GlobalStorage";
import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";
import { URL_GENERAL } from "../data/consts";
import Pagination from "../components/pagination";
import matchSorter from "match-sorter";

function Grupos2023(props) {
  const [loading, setloading] = useState(false);
  const [Grupos, setGrupos] = useState([]);
  const [CatMunicipio, setCatMunicipio] = useState([]);
  const [CveInterventor, setCveInterventor] = useState([]);
  const [CatRemesa, setCatRemesa] = useState([]);
  const [CatResponsableEntrega, setCatResponsableEntrega] = useState([]);
  const [CatLocalidad, setCatLocalidad] = useState([]);
  const [totalGrupos, setTotalGrupos] = useState(0);
  const [flagBuscaArticulador, setflagBuscaArticulador] = useState(false);
  const [Folio, setFolio] = useState("");
  const [vale, setVale] = useState({ id: null });
  const [message, setMessage] = useState(null);

  let history = useHistory();

  const token = getVars("Token");

  const [_menu, _setmenu] = useState({});

  const columns = [
    {
      Header: "Remesa",
      headerClassName: "text-center",
      minWidth: 35,
      id: "Remesa",
      accessor: (d) => d.Remesa,
      filterMethod: (filter, rows) => {
        if (filter.value === "Cualquier") {
          return true;
        }
        return rows[filter.id] === filter.value;
      },
      Filter: ({ filter, onChange }) => (
        <select
          onChange={(event) => onChange(event.target.value)}
          style={{ width: "100%" }}
          value={filter ? filter.value : "Cualquier"}
        >
          <option value="Cualquier">Cualquier</option>
          {CatRemesa}
        </select>
      ),
    },
    {
      Header: "Municipio",
      headerClassName: "text-center",
      minWidth: 50,
      id: "Municipio",
      accessor: (d) => d.Municipio,
      filterMethod: (filter, rows) => {
        if (filter.value === "Cualquier") {
          return true;
        }
        return rows[filter.id] === filter.value;
      },
      Filter: ({ filter, onChange }) => (
        <select
          onChange={(event) => onChange(event.target.value)}
          style={{ width: "100%" }}
          value={filter ? filter.value : "Cualquier"}
        >
          <option value="Cualquier">Cualquier</option>
          {CatMunicipio}
        </select>
      ),
    },
    {
      Header: "CveInterventor",
      headerClassName: "text-center",
      minWidth: 50,
      id: "CveInterventor",
      accessor: (d) => d.CveInterventor,
      filterMethod: (filter, rows) => {
        if (filter.value === "Cualquier") {
          return true;
        }
        return rows[filter.id] === filter.value;
      },
      Filter: ({ filter, onChange }) => (
        <select
          onChange={(event) => onChange(event.target.value)}
          style={{ width: "100%" }}
          value={filter ? filter.value : "Cualquier"}
        >
          <option value="Cualquier">Cualquier</option>
          {CveInterventor}
        </select>
      ),
    },
    {
      Header: "Localidad",
      headerClassName: "text-center",
      minWidth: 70,
      id: "Localidad",
      accessor: (d) => d.Localidad,
      filterMethod: (filter, rows) => {
        if (filter.value === "Cualquier") {
          return true;
        }
        return rows[filter.id] === filter.value;
      },
      Filter: ({ filter, onChange }) => (
        <select
          onChange={(event) => onChange(event.target.value)}
          style={{ width: "100%" }}
          value={filter ? filter.value : "Cualquier"}
        >
          <option value="Cualquier">Cualquier</option>
          {CatLocalidad}
        </select>
      ),
    },
    {
      Header: "Responsable",
      headerClassName: "text-center",
      minWidth: 70,
      id: "ResponsableEntrega",
      accessor: (d) => d.ResponsableEntrega,
      filterMethod: (filter, rows) => {
        if (filter.value === "Cualquier") {
          return true;
        }
        return rows[filter.id] === filter.value;
      },
      Filter: ({ filter, onChange }) => (
        <select
          onChange={(event) => onChange(event.target.value)}
          style={{ width: "100%" }}
          value={filter ? filter.value : "Cualquier"}
        >
          <option value="Cualquier">Cualquier</option>
          {CatResponsableEntrega}
        </select>
      ),
    },
    {
      Header: "Total",
      headerClassName: "text-center",
      minWidth: 20,
      id: "Solicitudes",
      accessor: (d) => d.Solicitudes,
      filterMethod: (filter, rows) =>
        matchSorter(rows, filter.value, {
          keys: ["Solicitudes"],
        }),
      filterAll: false,
    },
    {
      headerClassName: "text-center",
      Header: "Acciones",
      minWidth: 90,
      id: "Acciones",
      accessor: (d) => (_menu.Exportar === 1 ? d.action : ""),
      filterMethod: (filter, rows) =>
        matchSorter(rows, filter.value, {
          keys: ["Acciones"],
        }),
      filterAll: false,
    },
  ];

  useEffect(() => {
    setearMenu("Estado");
    setloading(true);
    EjecutarApi({}, "getCatGrupos2023", token.token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        setloading(false);
        setCatMunicipio(
          res.data.Municipios.map((item) => {
            return <option value={item}>{item}</option>;
          })
        );
        setCatRemesa(
          res.data.Remesas.map((item) => {
            return <option value={item}>{item}</option>;
          })
        );
        setCatResponsableEntrega(
          res.data.ResponsablesEntrega.map((item) => {
            return <option value={item}>{item}</option>;
          })
        );
        setCatLocalidad(
          res.data.Localidades.map((item) => {
            return <option value={item}>{item}</option>;
          })
        );
        setCveInterventor(
          res.data.CvesInterventor.map((item) => {
            return <option value={item}>{item}</option>;
          })
        );
      })
      .catch((e) => {
        setloading(false);
        notify.show(`Error al obtener Grupos: ${e.message}`, "custom", 5000, {
          background: "#0857B6",
          fontSize: 14,
          text: "#FFFFFF",
        });
      });
  }, []);
  const handleMiniClick = () => {
    document.body.classList.toggle("sidebar-mini");
  };

  const setearMenu = (dependecia) => {
    if (dependecia === "Estado") {
      token.menu.forEach((prop, key) => {
        if (prop.idMenu === 28) {
          _setmenu({
            Ver: prop.Ver,
            Agregar: prop.Agregar,
            Editar: prop.Editar,
            Eliminar: prop.Eliminar,
            Seguimiento: prop.Seguimiento,
            Exportar: prop.Exportar,
            Imprimir: prop.Imprimir,
            ViewAll: prop.ViewAll,
            idTipoUser: token.user.idTipoUser.id,
          });
        }
      });
    } else {
      var json = {};

      token.menu.forEach((prop, key) => {
        if (prop.idMenu === 28) {
          json = {
            Ver: prop.Ver,
            Agregar: prop.Agregar,
            Editar: prop.Editar,
            Eliminar: prop.Eliminar,
            Seguimiento: prop.Seguimiento,
            Exportar: prop.Exportar,
            Imprimir: prop.Imprimir,
            ViewAll: prop.ViewAll,
            idTipoUser: token.user.idTipoUser.id,
            regiones: token.regiones,
          };
        }
      });

      return json;
    }
  };

  const mini_tableArticulador = (
    <>
      <ReactBSAlert
        style={{ display: "block", marginTop: "-250pt" }}
        onConfirm={() => {
          setFolio("");
          setVale({ id: null });
          setflagBuscaArticulador(false);
        }}
        onCancel={() => setflagBuscaArticulador(false)}
        confirmBtnBsStyle="info"
        size="sm"
      >
        <Card className="mb-5 text-muted">
          <CardBody className="mb-5 text-muted">
            <Form
              className="form-horizontal"
              id="TypeValidation"
              onSubmit={(e) => btnBuscar(e)}
            >
              <Row>
                <Label sm="2">Folio:</Label>
                <Col sm="4">
                  <FormGroup>
                    <Input
                      name="Folio"
                      type="text"
                      value={Folio}
                      onChange={(event) => setFolio(event.target.value)}
                    />
                  </FormGroup>
                </Col>
                <Col md="2">
                  <Button type="submit" color="primary" size="sm">
                    <span className="btn-label">
                      <i className="nc-icon nc-zoom-split" />
                    </span>
                  </Button>
                </Col>
              </Row>
              {loading && (
                <ImpulseSpinner color={"#1261ff"} size={30} loading={true} />
              )}
              {message !== null && (
                <div>
                  <b>{message}</b>
                </div>
              )}
              {vale.id && (
                <Row>
                  <table>
                    <tr>
                      <td style={{ textAlign: "left" }}>Remesa:</td>
                      <td style={{ textAlign: "left", fontSize: "12px" }}>
                        <b>{vale.Remesa}</b>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ textAlign: "left" }}>Municipio:</td>
                      <td style={{ textAlign: "left", fontSize: "12px" }}>
                        <b>{vale.Municipio}</b>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ textAlign: "left" }}>CveInterventor:</td>
                      <td style={{ textAlign: "left", fontSize: "12px" }}>
                        <b>{vale.CveInterventor}</b>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ textAlign: "left" }}>Localidad:</td>
                      <td style={{ textAlign: "left", fontSize: "12px" }}>
                        <b>{vale.Localidad}</b>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ textAlign: "left" }}>Articulador:</td>
                      <td style={{ textAlign: "left", fontSize: "12px" }}>
                        <b>{vale.ResponsableEntrega}</b>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ textAlign: "left" }}>Total:</td>
                      <td style={{ textAlign: "left", fontSize: "12px" }}>
                        <b>{vale.TotalAprobados}</b>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ textAlign: "left" }}>Capturo:</td>
                      <td style={{ textAlign: "left", fontSize: "12px" }}>
                        <b>{vale.Capturo}</b>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ textAlign: "left" }}>Hora:</td>
                      <td style={{ textAlign: "left", fontSize: "12px" }}>
                        <b>{vale.created_at}</b>
                      </td>
                    </tr>
                  </table>
                </Row>
              )}
            </Form>
          </CardBody>
        </Card>
      </ReactBSAlert>
    </>
  );

  const handleArticulador = () => {
    history.push("/gruposadd2023");
  };

  const handleDescargaExcel = (param) => {
    console.log("=>Exportar DescargaExcel: ", param);

    var url = new URL(
      URL_GENERAL +
        `getReporteNominaVales2023?idGrupo=${param.id}&token=${token.token}`
    );
    window.open(url, "_blank");
  };

  const handleDescargaEtiquetas = (param) => {
    console.log("=>Exportar DescargaEtiquetas: ", param);

    var url = new URL(
      URL_GENERAL +
        `vales/getEtiquetasVales?idGrupo=${param.id}&token=${token.token}`
    );
    window.open(url, "_blank");
  };

  const handleDescargaPDF = (param) => {
    setloading(true);
    const grupo = {
      idGrupo: param.id,
    };

    EjecutarApi(grupo, "validarGrupo2023", token.token)
      .then((res) => {
        if (res.results) {
          var url = new URL(
            URL_GENERAL +
              `getReporteAcuseVales2023?idGrupo=${param.id}&token=${token.token}`
          );
          window.open(url, "_blank");
          setloading(false);
        } else {
          notify.show(
            `Aún no se asignan los vales de este grupo`,
            "custom",
            5000,
            {
              background: "#0857B6",
              fontSize: 14,
              text: "#FFFFFF",
            }
          );
          setloading(false);
        }
      })
      .catch((e) => {
        notify.show(
          `Aún no se asignan los vales de este grupo`,
          "custom",
          5000,
          {
            background: "#0857B6",
            fontSize: 14,
            text: "#FFFFFF",
          }
        );
        setloading(false);
      });
  };

  const handleDescargaSolicitud = (param) => {
    setloading(true);

    var url = new URL(
      URL_GENERAL +
        `getSolicitudesPdfVales?idGrupo=${param.id}&token=${token.token}`
    );
    window.open(url, "_blank");
    setloading(false);
  };

  const btnBuscar = (e) => {
    e.preventDefault();
    console.log("=>Buscar: ", Folio);

    setMessage(null);

    setloading(true);

    const ParametrosVale = {
      Folio,
    };

    EjecutarApi(ParametrosVale, "getSearchFolio", token.token)
      .then((res) => {
        console.log("Vales->results:", res);
        setloading(false);
        if (res.results) {
          if (res.data) setVale(res.data);
          else setMessage("Sin resultados");
        } else {
          setVale({ id: null });
        }
      })
      .catch((e) => {
        setloading(false);
        console.log("=>Error: ", e);
      });
  };

  return (
    <div className="wrapper">
      <Sidebar {...props} bgColor="black" activeColor="info" />
      <div className="main-panel">
        <Navbar {...props} handleMiniClick={handleMiniClick} />
        <Notifications />
        <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <Row>
                    <Col md="8">
                      <CardTitle tag="h6">Listado de Grupos</CardTitle>
                    </Col>
                    <Col md="2">
                      {_menu.Agregar == 1 ? (
                        <>
                          <Button
                            color="primary"
                            size="sm"
                            onClick={() => {
                              handleArticulador();
                            }}
                          >
                            <span className="btn-label">
                              <i className="nc-icon nc-simple-add" />
                            </span>{" "}
                            Agregar
                          </Button>
                        </>
                      ) : (
                        <></>
                      )}
                    </Col>
                    <Col md="2">
                      <Button
                        color="primary"
                        size="sm"
                        onClick={() => setflagBuscaArticulador(true)}
                      >
                        <span className="btn-label">
                          <i className="nc-icon nc-zoom-split" />
                        </span>
                        Buscar Vale
                      </Button>
                      {flagBuscaArticulador ? mini_tableArticulador : <></>}
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Col sm="12">
                    <ReactTable
                      size="sm"
                      noDataText="¡No existen datos a mostrar!"
                      data={Grupos}
                      filterable
                      manual
                      columns={columns}
                      className="-striped -highlight primary-pagination"
                      loading={loading}
                      showPagination={true}
                      showPaginationBottom={true}
                      showTotalPages={true}
                      showPageJump={false}
                      canNextFromData={true}
                      defaultPageSize={10}
                      total={totalGrupos}
                      pages={Math.floor(totalGrupos / 10)}
                      previousText="Anterior"
                      nextText="Siguiente"
                      ofText="de"
                      pageSizeOptions={[10, 20, 25, 50, 100]}
                      PaginationComponent={Pagination}
                      onFetchData={(stateComp, instance) => {
                        setloading(true);

                        var arrays = [];

                        var temFullName = "";
                        stateComp.filtered.map((Filtro) => {
                          if (Filtro.id === "FullName") {
                            temFullName = Filtro.value;
                          }

                          if (Filtro.id === "Remesa") {
                            if (Filtro.value !== "Cualquier") {
                              arrays.push({
                                id: "vales_grupos.Remesa",
                                value: Filtro.value,
                              });
                            } else {
                              arrays = arrays.filter(
                                (item) => item.id !== "vales_grupos.Remesa"
                              );
                            }
                          }

                          if (Filtro.id === "Municipio") {
                            if (Filtro.value !== "Cualquier") {
                              arrays.push({
                                id: "et_cat_municipio.Nombre",
                                value: Filtro.value,
                              });
                            } else {
                              arrays = arrays.filter(
                                (item) => item.id !== "et_cat_municipio.Nombre"
                              );
                            }
                          }

                          if (Filtro.id === "Solicitudes") {
                            if (Filtro.value !== "Cualquier") {
                              arrays.push({
                                id: "vales_grupos.TotalAprobados",
                                value: Filtro.value,
                              });
                            } else {
                              arrays = arrays.filter(
                                (item) =>
                                  item.id !== "vales_grupos.TotalAprobados"
                              );
                            }
                          }

                          if (Filtro.id === "ResponsableEntrega") {
                            if (Filtro.value !== "Cualquier") {
                              arrays.push({
                                id: "vales_grupos.ResponsableEntrega",
                                value: Filtro.value,
                              });
                            } else {
                              arrays = arrays.filter(
                                (item) =>
                                  item.id !== "vales_grupos.ResponsableEntrega"
                              );
                            }
                          }

                          if (Filtro.id === "Localidad") {
                            if (Filtro.value !== "Cualquier") {
                              arrays.push({
                                id: "et_cat_localidad_2022.Nombre",
                                value: Filtro.value,
                              });
                            } else {
                              arrays = arrays.filter(
                                (item) =>
                                  item.id !== "et_cat_localidad_2022.Nombre"
                              );
                            }
                          }

                          if (Filtro.id === "CveInterventor") {
                            if (Filtro.value !== "Cualquier") {
                              arrays.push({
                                id: "vales_grupos.CveInterventor",
                                value: Filtro.value,
                              });
                            } else {
                              arrays = arrays.filter(
                                (item) =>
                                  item.id !== "vales_grupos.CveInterventor"
                              );
                            }
                          }
                        });

                        var parametros = {};
                        let menu_local_storege = setearMenu("Storage");

                        if (menu_local_storege.ViewAll === 1) {
                          parametros = {
                            page: stateComp.page,
                            tipo: "and",
                            pageSize: stateComp.pageSize,
                            // sorted: [
                            //   { id: "vales_grupos.created_at", desc: true },
                            // ],
                            filtered: arrays,
                            NombreCompleto: temFullName,
                          };
                        } else {
                          parametros = {
                            page: stateComp.page,
                            tipo: "and",
                            pageSize: stateComp.pageSize,
                            // sorted: [
                            //   { id: "vales_grupos.created_at", desc: true },
                            // ],
                            filtered: arrays,
                            NombreCompleto: temFullName,
                            Propietario: token.user.id,
                            SubRegion: menu_local_storege.Seguimiento,
                          };
                        }
                        EjecutarApi(parametros, "getGrupos2023", token.token)
                          .then(function (res) {
                            return res;
                          })
                          .then((res) => {
                            setloading(false);
                            setTotalGrupos(res.total);
                            setGrupos(
                              res.data.map((prop, key) => {
                                return {
                                  Remesa: prop.Remesa,
                                  Municipio: prop.Municipio,
                                  Localidad: prop.Localidad,
                                  CveInterventor: prop.CveInterventor,
                                  ResponsableEntrega: prop.ResponsableEntrega,
                                  Solicitudes: prop.TotalAprobados,
                                  action: (
                                    <>
                                      <Button
                                        size="sm"
                                        color="success"
                                        title="Listado"
                                        onClick={() =>
                                          handleDescargaExcel(prop)
                                        }
                                      >
                                        <span className="btn-label">
                                          <i className="fa fa-file-excel-o fa-1x" />
                                        </span>
                                      </Button>
                                      <Button
                                        size="sm"
                                        color="info"
                                        title="Solicitud"
                                        onClick={() =>
                                          handleDescargaSolicitud(prop)
                                        }
                                      >
                                        <span className="btn-label">
                                          <i className="fa fa-id-card-o fa-1x" />
                                        </span>
                                      </Button>
                                      <Button
                                        size="sm"
                                        color="danger"
                                        title="Acuse"
                                        onClick={() => handleDescargaPDF(prop)}
                                      >
                                        <span className="btn-label">
                                          <i className="fa fa-file-pdf-o fa-1x" />
                                        </span>
                                      </Button>
                                      <Button
                                        size="sm"
                                        color="warning"
                                        title="Etiquetas"
                                        onClick={() =>
                                          handleDescargaEtiquetas(prop)
                                        }
                                      >
                                        <span className="btn-label">
                                          <i className="fa fa-tag fa-1x" />
                                        </span>
                                      </Button>
                                    </>
                                  ),
                                  data: prop,
                                };
                              })
                            );
                          })
                          .catch((e) => {
                            console.log("=Error[getGrupos]: ", e);
                            console.log(
                              "=Error: parametros",
                              JSON.stringify(parametros)
                            );
                            setloading(false);
                            notify.show(
                              `Error al obtener Grupos: ${e.message}`,
                              "custom",
                              5000,
                              {
                                background: "#0857B6",
                                fontSize: 14,
                                text: "#FFFFFF",
                              }
                            );
                          });
                      }}
                    />
                  </Col>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}

export default Grupos2023;
