import React from 'react';
import { Col, Nav, NavItem, NavLink, Row } from 'reactstrap';
import { getVars } from "../../GlobalStorage";

const Navigation = (props) => {
    // const step = getVars('StepForm') ? getVars('StepForm').step : 0;
    const dots = [];
    const validate = (index) => {
        const steps = getVars('StepForm');

        if (!(steps === null || steps === undefined)) {
            if (steps.step === props.totalSteps) {
                props.goToStep(props.totalSteps);
            } else {
                props.goToStep(index <= steps.step ? index : steps.step);
            }
        }
    }

    for (let i = 1; i <= props.totalSteps; i += 1) {
        const isActive = props.currentStep === i;

        dots.push((
            <NavItem key={i}>
                <NavLink
                    href="#"
                    active={isActive}
                    onClick={e => validate(i)}>Paso {i}</NavLink>
            </NavItem>
        ));
    }

    return (
        <Row>
            <Col sm={12} style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                <Nav pills>
                    {dots}
                </Nav>
            </Col>
        </Row>

    );
};

export default Navigation;
