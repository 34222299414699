import { useState, useCallback } from "react";
import EjecutarApi from "../../../Consultas/Consultas";
import { getVars } from "../../../GlobalStorage";

export function useUsers() {
  const [users, setUsers] = useState([]);
  const [assistants, setAssistants] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingAssistants, setLoadingAssistants] = useState(false);
  const [total, setTotal] = useState(0);
  const [totalAssistants, setTotalAssistants] = useState(0);
  const [errorUser, setErrorUser] = useState(null);

  const getUsers = useCallback((filters) => {
    setLoading(true);
    setErrorUser(null);
    const token = getVars("Token");
    EjecutarApi(filters, "getUsersByRegion", token.token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.results && res.success) {
          setTotal(res.total);
          setUsers(
            res.data.map((item) => {
              return {
                ...item,
                NombreCompleto: `${item.Nombre} ${item.Paterno} ${item.Materno}`,
              };
            })
          );
          setLoading(false);
        } else {
          setErrorUser(res.errors);
          setLoading(false);
        }
      })
      .catch((e) => {
        setErrorUser(e.message);
        setLoading(false);
      });
  }, []);

  const getAssistants = useCallback((filters) => {
    setLoadingAssistants(true);
    const token = getVars("Token");
    EjecutarApi(filters, "getAssistants", token.token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.results && res.success) {
          setAssistants(
            res.data.map((item) => {
              return {
                ...item,
                NombreCompleto: `${item.Nombre} ${item.Paterno} ${item.Materno}`,
              };
            })
          );
          setTotalAssistants(res.total);
          setLoadingAssistants(false);
        } else {
          setLoadingAssistants(false);
        }
      })
      .catch((e) => {
        setLoadingAssistants(false);
      });
  }, []);

  return {
    users,
    getUsers,
    errorUser,
    loading,
    setLoading,
    total,
    assistants,
    setAssistants,
    getAssistants,
    totalAssistants,
    loadingAssistants,
    setLoadingAssistants,
    setAssistants,
  };
}
