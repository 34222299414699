import React, {useState, useEffect} from 'react';
import {HorizontalBar} from 'react-chartjs-2';
//import DataRangoEdad from '../../DataRangoEdad.json'

const ChartHorizontalBar = ({ DataRangoEdad, Title,StringyAxes,StringxAxes,label_Vertical,label_Horizontal}) => {
    console.log('=>DataRangoEdad: ', DataRangoEdad);
    
    const [ChartHdata, SetChartHdata] = useState(DataRangoEdad)
    const titleG = (Title) ? Title : 'GRAFICA BAR HORIZONTAL';
    const labelStringyAxes = (StringyAxes) ? StringyAxes : 'Rangos de Edad (Años)';
    const labelStringxAxes = (StringxAxes) ? StringxAxes : 'Numero de Encuestados en el rango de edad';

    const chart = () => {

       var labelV = (label_Vertical) ? label_Vertical : [];
       var labelH= (label_Horizontal) ?label_Horizontal: [];
        
        fetch('DataRangoEdad')
			.then(function(res) 
			{
				return res;
			})
			.then(res => {
                console.log("=>DataRangoEdad",DataRangoEdad);
                for (const dataObj of DataRangoEdad) {
                    labelV.push(dataObj.label_Vertical);
                    labelH.push(parseInt(dataObj.label_Horizontal));
                  } 
                  SetChartHdata({
                    labels: labelV,
                    datasets: [
                      {
                        label:'',
                        backgroundColor: 'rgba(80, 151, 199,0.8)',
                        borderColor: 'rgba(80, 151, 199,1)',
                        borderWidth: 1,
                        hoverBackgroundColor: 'rgba(71, 125, 158,0.8)',
                        hoverBorderColor: 'rgba(71, 125, 158,0.8)',
                        data: labelH,
                      }
                    ]
                  });
                })
                .catch(err => {
                  console.log(err);
                });
              console.log(labelV, labelH);
            };     

    useEffect(() => {
        chart()
    }, [])

    return(
        <>
            <HorizontalBar data={ChartHdata}
                options={{
                    responsive: true,
                    title: { text: titleG, display: true },
                    scales: {
                    yAxes: [
                        {
                            
                        scaleLabel: { 
                            display: true, 
                            labelString: labelStringyAxes,
                        },
                        ticks: {
                            autoSkip: true,
                            maxTicksLimit: 10,
                            beginAtZero: true
                        },
                        gridLines: {
                            display: false
                        }
                        }
                    ],
                    xAxes: [
                        {
                        scaleLabel: { 
                            display: true, 
                            labelString: labelStringxAxes, 
                        },
                        gridLines: {
                            display: true
                        }
                        }
                    ]
                    }
                }}
            />
        </>
    )
}
export default ChartHorizontalBar; 

     