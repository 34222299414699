import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardBody,
  Row,
  Col,
  FormGroup,
  Input,
  Label,
  Table,
} from "reactstrap";

import Notifications from "react-notify-toast";
import swal from "sweetalert";
import EjecutarApi from "../../Consultas/Consultas";
import { getVars } from "../../GlobalStorage";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import Sidebar from "../../components/Sidebar";
import { isNullOrUndefined } from "../../utils/emptyValidations";
import { URL_GENERAL } from "../../data/consts";
import { useFilters } from "./hooks/useFilters";
import { useUsers } from "./hooks/useUsers";
import DateTimeComponent from "../../components/DateTimeComponent";
import moment from "moment";
import { CircleSpinner } from "react-spinners-kit";
import "./css/tableUsers.css";

const Asistencia = (props) => {
  const [userData, setUserData] = useState();
  const [date, setDate] = useState(null);
  const [errorDate, setErrorDate] = useState("");
  const { filters, setFilters, error } = useFilters();
  const {
    users,
    getUsers,
    loading,
    setLoading,
    // total,
    loadingAssistants,
    setLoadingAssistants,
    assistants,
    setAssistants,
    getAssistants,
  } = useUsers({ filters });
  const [menu, setMenu] = useState({
    Ver: 0,
    Agregar: 0,
    Editar: 0,
    Eliminar: 0,
    Seguimiento: 0,
    Exportar: 0,
    Imprimir: 0,
    ViewAll: 0,
    idTipoUser: 0,
  });
  const [menuLoaded, setMenuLoaded] = useState(false);

  // const columns = [
  //   {
  //     Header: "Region",
  //     headerClassName: "text-center",
  //     id: "Remesa",
  //     minWidth: 50,
  //     accessor: (d) => d.Region,
  //     filterAll: false,
  //     style: { fontSize: "10px" },
  //     headerStyle: {
  //       fontSize: "10px",
  //       background: "#74c8cc",
  //       color: "white",
  //       fontWeight: "bold",
  //     },
  //   },
  //   {
  //     Header: "Nombre Completo",
  //     headerClassName: "text-center",
  //     id: "NombreCompleto",

  //     accessor: (d) => d.NombreCompleto,
  //     filterAll: false,
  //     style: { fontSize: "10px" },
  //     headerStyle: {
  //       fontSize: "10px",
  //       background: "#74c8cc",
  //       color: "white",
  //       fontWeight: "bold",
  //     },
  //   },
  //   {
  //     Header: "Usuario",
  //     headerClassName: "text-center",
  //     id: "user",
  //     accessor: (d) => d.email,
  //     filterAll: false,
  //     style: { fontSize: "10px" },
  //     headerStyle: {
  //       fontSize: "10px",
  //       background: "#74c8cc",
  //       color: "white",
  //       fontWeight: "bold",
  //     },
  //   },
  // ];

  useEffect(() => {
    setUserData(getVars("Token"));
  }, []);

  const handleMiniClick = () => {
    document.body.classList.toggle("sidebar-mini");
  };

  useEffect(() => {
    if (isNullOrUndefined(userData)) return;
    const menus = userData.menu;
    const currentMenu = menus.find((menu) => menu.idMenu === 37);
    if (isNullOrUndefined(currentMenu) || currentMenu.Ver < 1) {
      swal({
        title: "Atención",
        text: "No tiene permisos para este módulo, contacte al administrador",
        icon: "warning",
      });
      return;
    }
    setMenu({
      Ver: currentMenu.Ver,
      Agregar: currentMenu.Agregar,
      Editar: currentMenu.Editar,
      Eliminar: currentMenu.Eliminar,
      Seguimiento: currentMenu.Seguimiento,
      Exportar: currentMenu.Exportar,
      Imprimir: currentMenu.Imprimir,
      ViewAll: currentMenu.ViewAll,
    });
    setMenuLoaded(true);
  }, [userData, setMenu, setMenuLoaded]);

  useEffect(() => {
    if (date) {
      const newDateFormat = date.split("/").reverse().join("-");
      const newFilters = {
        FechaAsistencia: newDateFormat,
      };
      getAssistants(newFilters);
      if (filters.region) {
        const newData = {
          date: newDateFormat,
          region: filters.region,
        };
        getUsers(newData);
      }
    }
  }, [date, getAssistants]);

  const deleteAssistans = (user) => {
    setLoadingAssistants(true);
    const token = getVars("Token");
    EjecutarApi(user, "deleteAssistant", token.token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.results && res.success) {
          const newDateFormat = date.split("/").reverse().join("-");
          const newFilters = {
            FechaAsistencia: newDateFormat,
          };
          getAssistants(newFilters);
          if (filters.region) {
            const newData = {
              date: newDateFormat,
              region: filters.region,
            };
            getUsers(newData);
          }
          swal({
            title: "Éxito!",
            text: `${res.message}`,
            icon: "success",
          });
        } else {
          swal({
            title: "¡Atención!",
            text: `${res.message}`,
            icon: "info",
          });
          setLoadingAssistants(false);
        }
      })
      .catch((e) => {
        swal({
          title: "¡Atención!",
          text: `${e.message}`,
          icon: "warning",
        });
        setLoadingAssistants(false);
      });
  };

  const handleDeleteUser = (item, index) => {
    swal({
      title: `¿Deseas eliminar al usuario: ${item.NombreCompleto}?`,
      icon: "warning",
      allowEscapeKey: false,
      allowOutsideClick: false,
      confirmButtonText: "Sí, Eliminar",
      buttons: true,
      dangerMode: true,
    }).then((res) => {
      if (res) {
        deleteAssistans(item);
      }
    });
  };

  const btnExportar = () => {
    const token = getVars("Token");
    const newDateFormat = date.split("/").reverse().join("-");
    let url = new URL(
      URL_GENERAL +
        `getListPdf?token=${token.token}&FechaAsistencia=${newDateFormat}`
    );
    window.open(url, "_blank");
  };

  const checkAssistant = (user) => {
    setLoading(true);
    if (!date) {
      swal({
        title: "¡Atención!",
        text: "Debe seleccionar la fecha de la asistencia",
        icon: "warning",
      });
      setLoading(false);
      return;
    }
    const token = getVars("Token");
    const newDateFormat = date.split("/").reverse().join("-");
    const data = {
      idUser: user.id ? user.id : null,
      Usuario: user.Usuario,
      Nombre: user.Nombre,
      Paterno: user.Paterno,
      Materno: user.Materno ? user.Materno : null,
      Region: user.Region,
      FechaAsistencia: newDateFormat,
    };
    EjecutarApi(data, "checkAssistant", token.token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        swal({
          title: "Éxito!",
          text: `Se agrego al usario`,
          icon: "success",
        });
        const newDateFormat = date.split("/").reverse().join("-");
        const newFilters = {
          date: newDateFormat,
          region: filters.region,
        };
        getUsers(newFilters);
        const newFilter = {
          FechaAsistencia: newDateFormat,
        };
        getAssistants(newFilter);
        setLoading(false);
      })
      .catch((e) => {
        swal({
          title: "¡Atención!",
          text: `${e.message}`,
          icon: "warning",
        });
        setLoading(false);
      });
  };

  const style = {
    table: {
      width: "100%",
      display: "table",
      borderSpacing: 0,
      borderCollapse: "separate",
      fontSize: "8px",
    },
    th: {
      top: 0,
      left: 0,
      zIndex: 2,
      position: "sticky",
      textAlign: "center",
      backgroundColor: "#fff",
      fontSize: "10px",
      background: "#74c8cc",
      color: "white",
      fontWeight: "bold",
    },
    td: {
      padding: 3,
    },
  };

  const handleChangeRegion = (e) => {
    const newDateFormat = date.split("/").reverse().join("-");
    setFilters({
      ...filters,
      date: newDateFormat,
      region: e.target.value,
    });
    if (e.target.value > 0) {
      const newFilters = {
        date: newDateFormat,
        region: e.target.value,
      };
      getUsers(newFilters);
    }
  };

  return (
    <>
      <div className="wrapper">
        <Sidebar {...props} bgColor="black" activeColor="info" />
        <div className="main-panel">
          <Navbar {...props} handleMiniClick={handleMiniClick} />
          <Notifications />
          <div className="content">
            <>
              <Row style={{ widht: "100%" }}>
                <Col md="12">
                  <Card>
                    <CardBody>
                      <Row>
                        <Col sm="4">
                          <FormGroup>
                            <i class="fa fa-calendar" aria-hidden="true"></i>{" "}
                            <Label style={{ fontWeight: "bold" }}>
                              Fecha asistencia
                              <span className="text-danger">*</span>{" "}
                            </Label>
                            <DateTimeComponent
                              id="FechaAsistencia"
                              name="FechaAsistencia"
                              dateFormat="DD/MM/YYYY"
                              maxDate={new Date("2023-12-31")}
                              minDate={new Date("2023-10-11")}
                              timeFormat={false}
                              closeOnSelect={true}
                              value={date}
                              onChange={(e) => {
                                setAssistants([]);
                                if (e._d === undefined) {
                                  setDate(null);
                                  setErrorDate(
                                    "La fecha no es válida, seleccione nuevamente"
                                  );
                                } else {
                                  setErrorDate("");
                                  setDate(moment(e._d).format("DD/MM/YYYY"));
                                }
                              }}
                            />
                            {errorDate && (
                              <span
                                style={{
                                  color: "red",
                                  fontWeight: "bold",
                                  fontSize: "10px",
                                }}
                              >
                                {errorDate}
                              </span>
                            )}
                          </FormGroup>
                        </Col>
                        <Col sm="4">
                          <Label style={{ fontWeight: "bold" }}> Región:</Label>
                          <FormGroup>
                            <Input
                              name="dia"
                              type="select"
                              value={filters.region}
                              disabled={!date}
                              onChange={(e) => {
                                handleChangeRegion(e);
                              }}
                            >
                              <option value={0}></option>
                              <option value={1}>Región I</option>
                              <option value={2}>Región II</option>
                              <option value={3}>Región III</option>
                              <option value={4}>Región IV</option>
                              <option value={5}>Región V</option>
                              <option value={6}>Región VI</option>
                              <option value={7}>Región VII</option>
                            </Input>
                          </FormGroup>
                        </Col>
                        <Col sm="2" xs="2">
                          {error && (
                            <span className="text-danger">{error}</span>
                          )}
                        </Col>
                        <Col
                          sm="2"
                          xs="2"
                          style={{ display: "grid", placeItems: "end" }}
                        >
                          <Button color="primary" onClick={() => {}}>
                            {loading ? (
                              <span>Buscando..</span>
                            ) : (
                              <span>Agregar</span>
                            )}
                          </Button>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
                <Col sm="12">
                  <Card>
                    <CardBody>
                      <Row>
                        <Col
                          sm="6"
                          style={{ display: "grid", placeItems: "center" }}
                        >
                          <Col
                            sm="12"
                            style={{ display: "grid", placeItems: "center" }}
                          >
                            <h6>Listado de usuarios</h6>
                            {loading && (
                              <CircleSpinner
                                className="text-center"
                                size={30}
                                color="#686769"
                                loading={true}
                              />
                            )}
                          </Col>
                          <div className="tableContainer">
                            <Table striped bordered hover size="sm">
                              <thead className="tbHead">
                                <tr>
                                  <th style={style.th}>Agregar</th>
                                  <th style={style.th}>Nombre</th>
                                  <th style={style.th}>Telefono</th>
                                  <th style={style.th}>Region</th>
                                </tr>
                              </thead>
                              <tbody
                                style={{
                                  fontSize: "9px",
                                }}
                              >
                                {users.length > 0 &&
                                  users.map((item, index) => (
                                    <>
                                      <tr key={index}>
                                        <td
                                          style={style.td}
                                          className="text-center"
                                        >
                                          <Button
                                            size="small"
                                            color="info"
                                            onClick={(e) =>
                                              checkAssistant(item)
                                            }
                                            style={{ padding: 5 }}
                                          >
                                            <span>
                                              <i className="fa fa-plus fa-2x" />
                                            </span>
                                          </Button>
                                        </td>
                                        <td
                                          style={style.td}
                                          className="text-center"
                                        >
                                          {item.NombreCompleto}
                                        </td>
                                        <td
                                          style={style.td}
                                          className="text-center"
                                        >
                                          {item.Usuario}
                                        </td>
                                        <td
                                          style={style.td}
                                          className="text-center"
                                        >
                                          {item.Region}
                                        </td>
                                      </tr>
                                    </>
                                  ))}
                              </tbody>
                              <tfoot className="tbFoot">
                                <tr>
                                  <th style={style.th}></th>
                                  <th style={style.th}></th>
                                  <th style={style.th}></th>
                                  <th style={style.th}></th>
                                </tr>
                              </tfoot>
                            </Table>
                          </div>
                        </Col>
                        <Col sm="6">
                          <Row>
                            <Col
                              sm="12"
                              style={{ display: "grid", placeItems: "center" }}
                            >
                              <h6>Asitencia</h6>{" "}
                              {(loadingAssistants || loading) && (
                                <CircleSpinner
                                  className="text-center"
                                  size={30}
                                  color="#686769"
                                  loading={true}
                                />
                              )}
                            </Col>
                            <Col
                              sm="12"
                              style={{ display: "grid", placeItems: "center" }}
                            >
                              <div className="tableContainer">
                                <Table striped bordered hover size="sm">
                                  <thead className="tbHead">
                                    <tr>
                                      <th style={style.th}>Acción</th>
                                      <th style={style.th}>Nombre Completo</th>
                                      <th style={style.th}>Usuario</th>
                                    </tr>
                                  </thead>
                                  <tbody
                                    style={{
                                      fontSize: "9px",
                                    }}
                                  >
                                    {assistants.length > 0 &&
                                      assistants.map((item, index) => (
                                        <tr key={index}>
                                          <td
                                            style={style.td}
                                            className="text-center"
                                          >
                                            <Button
                                              size="small"
                                              color="danger"
                                              onClick={(e) =>
                                                handleDeleteUser(item, index)
                                              }
                                              style={{ padding: 5 }}
                                            >
                                              <span className="btn-label">
                                                <i className="fa fa-trash fa-1x" />
                                              </span>
                                            </Button>
                                          </td>
                                          <td
                                            style={style.td}
                                            className="text-center"
                                          >
                                            {item.NombreCompleto}
                                          </td>
                                          <td
                                            style={style.td}
                                            className="text-center"
                                          >
                                            {item.Usuario}
                                          </td>
                                        </tr>
                                      ))}
                                  </tbody>
                                  <tfoot className="tbFoot">
                                    <tr>
                                      <th style={style.th}></th>
                                      <th style={style.th}></th>
                                      <th style={style.th}></th>
                                    </tr>
                                  </tfoot>
                                </Table>
                                <div
                                  style={{
                                    display: "grid",
                                    placeItems: "center",
                                  }}
                                >
                                  {assistants.length === 0 ? (
                                    <span style={{ color: "#AAA" }}>
                                      Sin resultados
                                    </span>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </Col>
                        <Col
                          sm="12"
                          style={{ display: "grid", placeItems: "end" }}
                        >
                          {assistants.length > 0 && (
                            <Button
                              color="success"
                              size="sm"
                              onClick={() => btnExportar()}
                              style={{ width: "48%" }}
                            >
                              Exportar Lista
                            </Button>
                          )}
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Footer fluid />
            </>
          </div>
        </div>
      </div>
    </>
  );
};
export default Asistencia;
