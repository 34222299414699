import React, { useState, useEffect, useCallback } from "react";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Row,
  Col,
  CardHeader,
} from "reactstrap";
import ReactTable from "react-table";
import { useHistory } from "react-router-dom";
import Notifications from "react-notify-toast";
import swal from "sweetalert";
import Navbar from "../../components/Navbar";
import Sidebar from "../../components/Sidebar";
import Footer from "../../components/Footer";
import { isNullOrUndefined } from "../../utils/emptyValidations";
import { getVars } from "../../GlobalStorage";
import Pagination from "../../components/pagination";
import EjecutarApi from "../../Consultas/Consultas";
import PluginFilterEncuestas from "../../components/PluginFilterEncuestas";
import EncuestaTCS from "./EncuestaTCS";
import { URL_GENERAL } from "../../data/consts";

const EncuestaSocialTCS = (props) => {
  const history = useHistory();
  const [userData, setUserData] = useState();
  const [encuestas, setEncuestas] = useState([]);
  const [filtro, setFiltro] = useState([]);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [isSolicitud, setIsSolicitud] = useState(false);
  const [solicitud, setSolicitud] = useState(null);
  const [tipo, setTipo] = useState(1);

  const [menu, setMenu] = useState({
    Ver: 0,
    Agregar: 0,
    Editar: 0,
    Eliminar: 0,
    Seguimiento: 0,
    Exportar: 0,
    Imprimir: 0,
    ViewAll: 0,
  });
  const [pageOptions, setPage] = useState({
    page: 0,
    pageSize: 10,
  });
  const columns = [
    {
      Header: "Encuesta",
      id: "Acciones",
      headerClassName: "text-center",
      accessor: (d) => d.Acciones,
      filterAll: false,
      style: { fontSize: "10px" },
      headerStyle: { fontSize: "10px" },
    },
    {
      Header: "Folio",
      minWidth: 70,
      headerClassName: "text-center",
      id: "Folio",
      accessor: (d) => d.Folio,
      filterAll: false,
      style: { fontSize: "10px" },
      headerStyle: { fontSize: "10px" },
    },
    {
      Header: "Nombre",
      minWidth: 200,
      id: "NombreCompleto",
      headerClassName: "text-center",
      accessor: (d) => d.NombreCompleto,
      filterAll: false,
      style: { fontSize: "10px" },
      headerStyle: { fontSize: "10px" },
    },
    {
      Header: "CURP",
      minWidth: 120,
      headerClassName: "text-center",
      id: "CURP",
      accessor: (d) => d.CURP,
      filterAll: false,
      style: { fontSize: "10px" },
      headerStyle: { fontSize: "10px" },
    },
    // {
    //   Header: "Region",
    //   minWidth: 65,
    //   headerClassName: "text-center",
    //   id: "RegionM",
    //   accessor: (d) => d.Region,
    //   filterAll: false,
    //   style: { fontSize: "10px" },
    //   headerStyle: { fontSize: "10px" },
    // },
    {
      Header: "Municipio",
      minWidth: 85,
      headerClassName: "text-center",
      id: "MunicipioVive",
      accessor: (d) => d.Municipio,
      filterAll: false,
      style: { fontSize: "10px" },
      headerStyle: { fontSize: "10px" },
    },
    {
      Header: "Domicilio",
      minWidth: 90,
      id: "Domicilio",
      headerClassName: "text-center",
      accessor: (d) => d.Domicilio,
      filterAll: false,
      style: { fontSize: "10px" },
      headerStyle: { fontSize: "10px" },
    },
    {
      Header: "Celular",
      minWidth: 120,
      id: "Celular",
      headerClassName: "text-center",
      accessor: (d) => d.Celular,
      filterAll: false,
      style: { fontSize: "10px" },
      headerStyle: { fontSize: "10px" },
    },
  ];

  const fetchData = useCallback(
    ({ pageSize, page }) => {
      const data = {
        page: page > pageOptions.page ? page : pageOptions.page,
        pageSize: pageSize,
        filtered: filtro,
      };
      setPage({
        page: page,
        pageSize: pageSize,
      });
      getEncuestas(data);
    },
    [pageOptions.page]
  );

  useEffect(() => {
    setUserData(getVars("Token"));
  }, []);

  const getEncuestas = (filters) => {
    setLoading(true);
    const token = getVars("Token");
    filters.filtered = filtro;
    EjecutarApi(filters, "encuestas/getEncuestasTCS", token.token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.results && res.success) {
          setTotal(res.total);
          setEncuestas(
            res.data.map((item) => {
              if (item.Paterno == null) item.Paterno = "";
              if (item.Materno == null) item.Materno = "";
              return {
                ...item,
                NombreCompleto: `${item.Nombre} ${item.Paterno} ${item.Materno}`,
                Domicilio: `${item.Calle} #${item.NumExt}, COL ${item.Colonia}`,
                Acciones: (
                  <Col>
                    <Button
                      className="btn btn-info"
                      size="sm"
                      onClick={() => sendDataSolicitud(item)}
                    >
                      Ver
                    </Button>
                  </Col>
                ),
              };
            })
          );
        } else {
          swal({
            title: "¡Atención!",
            text: `${res.errors}`,
            icon: "info",
          });
        }
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        swal({
          title: "¡Atención!",
          text: `${e.message}`,
          icon: "warning",
        });
      });
  };

  const setFilter = (filtered) => {
    setFiltro(filtered.filtered);
    const token = getVars("Token");
    setLoading(true);
    EjecutarApi(filtered, "encuestas/getEncuestasTCS", token.token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.results && res.success) {
          setTotal(res.total);
          setEncuestas(
            res.data.map((item) => {
              if (item.Paterno == null) item.Paterno = "";
              if (item.Materno == null) item.Materno = "";
              return {
                ...item,
                NombreCompleto: `${item.Nombre} ${item.Paterno} ${item.Materno}`,
                Domicilio: `${item.Calle} #${item.NumExt}, COL ${item.Colonia}`,
                Acciones: (
                  <>
                    <Row>
                      <Col>
                        <Button
                          color="primary"
                          size="sm"
                          style={{ width: "100%" }}
                          onClick={() => sendDataSolicitud(item)}
                        >
                          <i className="fa fa-eye" aria-hidden="true"></i>
                        </Button>
                      </Col>
                    </Row>
                  </>
                ),
              };
            })
          );
        } else {
          swal({
            title: "¡Atención!",
            text: `${res.errors}`,
            icon: "info",
          });
        }
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        swal({
          title: "¡Atención!",
          text: `${e.message}`,
          icon: "warning",
        });
      });
  };

  useEffect(() => {
    if (isNullOrUndefined(userData)) return;
    const menus = userData.menu;
    const currentMenu = menus.find((menu) => menu.idMenu === 41);
    if (isNullOrUndefined(currentMenu) || currentMenu.Ver < 1) {
      swal({
        title: "Atención",
        text: "No tiene permisos para este módulo, contacte al administrador",
        icon: "warning",
      });
      history.push("/not-found");
      return;
    }
    setMenu({
      Ver: currentMenu.Ver,
      Agregar: currentMenu.Agregar,
      Editar: currentMenu.Editar,
      Eliminar: currentMenu.Eliminar,
      Seguimiento: currentMenu.Seguimiento,
      Exportar: currentMenu.Exportar,
      Imprimir: currentMenu.Imprimir,
      ViewAll: currentMenu.ViewAll,
    });
  }, [userData, history]);

  const handleMiniClick = () => {
    document.body.classList.toggle("sidebar-mini");
  };

  const sendDataSolicitud = (item, tipo = 1) => {
    setTipo(tipo);
    setSolicitud(item);
    setIsSolicitud(!isSolicitud);
  };

  const btnExportar = () => {
    const token = getVars("Token");
    var url = new URL(
      URL_GENERAL + `encuestas/getReporteEncuestas?token=${token.token}`
    );
    window.open(url, "_blank");
  };

  return (
    <>
      <div className="wrapper">
        <Sidebar {...props} bgColor="black" activeColor="info" />
        <div className="main-panel">
          <Navbar {...props} handleMiniClick={handleMiniClick} />
          <Notifications />
          <div className="content">
            <>
              {!isSolicitud ? (
                <>
                  <Row style={{ width: "100%" }}>
                    <Card style={{ width: "100%" }}>
                      <CardHeader style={{ widht: "100%" }}>
                        <Row sm="12">
                          <Col sm="9">
                            <CardTitle tag="h6">
                              Listado de Encuestas Tarjeta GTO Contigo Si
                            </CardTitle>
                          </Col>
                          <Col sm="1">
                            {menu.Agregar === 1 ? (
                              <Button
                                color="primary"
                                size="sm"
                                onClick={() => {
                                  sendDataSolicitud(null);
                                }}
                              >
                                <span className="btn-label">
                                  <i className="fa fa-user-plus fa-2x" />{" "}
                                  Agregar
                                </span>
                              </Button>
                            ) : (
                              <></>
                            )}
                          </Col>
                          <Col sm="2">
                            {menu.Exportar === 1 ? (
                              <Button
                                color="success"
                                size="sm"
                                onClick={() => {
                                  btnExportar(null);
                                }}
                              >
                                <span className="btn-label">
                                  <i
                                    className="fa fa-file-excel-o fa-2x"
                                    aria-hidden="true"
                                  ></i>{" "}
                                  Exportar
                                </span>
                              </Button>
                            ) : (
                              <></>
                            )}
                          </Col>
                        </Row>
                      </CardHeader>
                      <CardBody style={{ widht: "100%" }}>
                        <Col>
                          <ReactTable
                            size="sm"
                            data={encuestas}
                            noDataText="Sin registros"
                            manual
                            columns={columns}
                            className="-striped -highlight primary-pagination"
                            loading={loading}
                            showPagination={true}
                            showPaginationBottom={true}
                            showTotalPages={true}
                            showPageJump={false}
                            canNextFromData={true}
                            defaultPageSize={pageOptions.pageSize}
                            total={total}
                            page={pageOptions.page}
                            pages={Math.floor(total / pageOptions.pageSize)}
                            previousText="Anterior"
                            nextText="Siguiente"
                            ofText="de"
                            pageSizeOptions={[10, 20, 25, 50, 100]}
                            PaginationComponent={Pagination}
                            onFetchData={fetchData}
                          />
                        </Col>
                      </CardBody>
                    </Card>
                  </Row>
                  <PluginFilterEncuestas
                    sendFiltro={setFilter}
                    clasess="dropdowns"
                  />
                </>
              ) : (
                <EncuestaTCS
                  data={solicitud}
                  tipo={tipo}
                  back={() => {
                    setIsSolicitud(!isSolicitud);
                  }}
                />
              )}
              <Footer fluid />
            </>
          </div>
        </div>
      </div>
    </>
  );
};
export default EncuestaSocialTCS;
