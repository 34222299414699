import React, { useState, useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col,
  Spinner,
} from "reactstrap";
import swal from "sweetalert";
import ReactTable from "react-table";
import { CircleSpinner } from "react-spinners-kit";
import EjecutarApi from "../Consultas/Consultas";
import Notifications from "react-notify-toast";
import { getVars } from "../GlobalStorage";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Pagination from "../components/pagination";
import Sidebar from "../components/Sidebar";
import PluginFilterSolicitudVales2022 from "../components/PluginFilterReporteVales2022";
import { URL_GENERAL } from "../data/consts";
import { isNullOrUndefined } from "../utils/emptyValidations";

const ValesEntregados = (props) => {
  const [userData, setUserData] = useState();
  const [menu, setMenu] = useState({
    Ver: 0,
    Agregar: 0,
    Editar: 0,
    Eliminar: 0,
    Seguimiento: 0,
    Exportar: 0,
    Imprimir: 0,
    ViewAll: 0,
    idTipoUser: 0,
  });
  const history = useHistory();
  const [total, setTotal] = useState(0);
  const [loadingCedulaList, setLoadingCedulaList] = useState(false);
  const [dataCedulaList, setDataCedulaList] = useState([]);
  const [menuLoaded, setMenuLoaded] = useState(false);

  //Filtro
  const [filtro, setFiltro] = useState([]);
  const [pageOptions, setPage] = useState({
    page: 0,
    pageSize: 10,
  });

  const columns = [
    {
      Header: "Folio Sol",
      minWidth: 80,
      id: "FolioSolicitud",
      accessor: (d) => d.FolioSolicitud,
      filterAll: false,
      style: { fontSize: "10px" },
      headerStyle: { fontSize: "10px" },
    },
    {
      Header: "Folio Ventanilla",
      minWidth: 120,
      id: "Folio",
      accessor: (d) => d.Folio,
      filterAll: false,
      style: { fontSize: "10px" },
      headerStyle: { fontSize: "10px" },
    },
    {
      Header: "REMESA",
      minWidth: 70,
      id: "Remesa",
      accessor: (d) => d.Remesa,
      filterAll: false,
      style: { fontSize: "10px" },
      headerStyle: { fontSize: "10px" },
    },
    {
      Header: "Fecha Sol",
      minWidth: 90,
      id: "FechaSolicitud",
      accessor: (d) => d.FechaSolicitud,
      filterAll: false,
      style: { fontSize: "10px" },
      headerStyle: { fontSize: "10px" },
    },
    {
      Header: "Nombre Completo",
      minWidth: 200,
      id: "NombreCompleto",
      accessor: (d) => d.NombreCompleto,
      filterAll: false,
      style: { fontSize: "10px" },
      headerStyle: { fontSize: "10px" },
    },
    {
      Header: "CURP",
      minWidth: 120,
      id: "CURP",
      accessor: (d) => d.CURP,
      filterAll: false,
      style: { fontSize: "10px" },
      headerStyle: { fontSize: "10px" },
    },
    {
      Header: "Region",
      minWidth: 70,
      id: "RegionM",
      accessor: (d) => d.Region,
      filterAll: false,
      style: { fontSize: "10px" },
      headerStyle: { fontSize: "10px" },
    },
    {
      Header: "Municipio",
      minWidth: 80,
      id: "MunicipioVive",
      accessor: (d) => d.Municipio,
      filterAll: false,
      style: { fontSize: "10px" },
      headerStyle: { fontSize: "10px" },
    },
    {
      Header: "Dirección",
      minWidth: 300,
      id: "Direccion",
      accessor: (d) => d.Direccion,
      filterAll: false,
      style: { fontSize: "10px" },
      headerStyle: { fontSize: "10px" },
    },
    {
      Header: "Incidencia",
      minWidth: 90,
      id: "Incidencia",
      accessor: (d) => d.Incidencia,
      filterAll: false,
      style: { fontSize: "10px" },
      headerStyle: { fontSize: "10px" },
    },
    {
      Header: "Entregado",
      minWidth: 85,
      id: "Entregado",
      accessor: (d) => d.Entregado,
      filterAll: false,
      style: { fontSize: "10px" },
      headerStyle: { fontSize: "10px" },
    },
    {
      Header: "Fecha Entrega",
      minWidth: 110,
      id: "FechaEntrega",
      accessor: (d) => d.FechaEntrega,
      filterAll: false,
      style: { fontSize: "10px" },
      headerStyle: { fontSize: "10px" },
    },
    {
      Header: "Responsable",
      minWidth: 120,
      id: "Responsable",
      accessor: (d) => d.Responsable,
      filterAll: false,
      style: { fontSize: "10px" },
      headerStyle: { fontSize: "10px" },
    },
  ];

  useEffect(() => {
    if (isNullOrUndefined(userData)) return;
    const menus = userData.menu;
    const currentMenu = menus.find((menu) => menu.idMenu === 13);
    if (isNullOrUndefined(currentMenu) || currentMenu.Ver < 1) {
      swal({
        title: "Atención",
        text: "No tiene permisos para este módulo, contacte al administrador",
        icon: "warning",
      });
      history.push("/not-found");
      return;
    }
    setMenu({
      Ver: currentMenu.Ver,
      Agregar: currentMenu.Agregar,
      Editar: currentMenu.Editar,
      Eliminar: currentMenu.Eliminar,
      Seguimiento: currentMenu.Seguimiento,
      Exportar: currentMenu.Exportar,
      Imprimir: currentMenu.Imprimir,
      ViewAll: currentMenu.ViewAll,
      idTipoUser: userData.user.idTipoUser.id,
    });
    setMenuLoaded(true);
  }, [userData]);

  const handleMiniClick = () => {
    document.body.classList.toggle("sidebar-mini");
  };

  useEffect(() => {
    setUserData(getVars("Token"));
  }, []);

  const fetchData = useCallback(
    ({ pageSize, page, sorted, params }) => {
      const data = {
        page: page > pageOptions.page ? page : pageOptions.page,
        pageSize: pageSize,
        programa: 1,
        filtered: filtro,
      };
      setPage({
        page: page,
        pageSize: pageSize,
      });
      getRegistros(data);
    },
    [menu, filtro]
  );

  const getRegistros = (data) => {
    const token = getVars("Token");
    setLoadingCedulaList(true);
    data.filtered = filtro;
    EjecutarApi(data, "getVales2022", token.token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.results && res.success) {
          setDataCedulaList(
            res.data.map((item) => {
              if (item.Paterno == null) item.Paterno = "";
              if (item.Materno == null) item.Materno = "";
              if (item.NumExt == null) item.NumExt = "";
              return {
                ...item,
                NombreCompleto: `${item.Nombre} ${item.Paterno} ${item.Materno}`,
                Direccion: `${item.Calle} ${item.NumExt} ${item.Colonia} ${item.CP} ${item.Municipio} GUANAJUATO`,
              };
            })
          );
          setTotal(res.total);
        } else {
          swal({
            title: "¡Atención!",
            text: `${res.message}`,
            icon: "info",
          });
        }
        setLoadingCedulaList(false);
      })
      .catch((e) => {
        setLoadingCedulaList(false);
        swal({
          title: "¡Atención!",
          text: `${e.message}`,
          icon: "warning",
        });
      });
  };

  const setFilter = (filtered) => {
    filtered.programa = "1";
    setFiltro(filtered.filtered);
    const token = getVars("Token");
    setLoadingCedulaList(true);
    EjecutarApi(filtered, "getVales2022", token.token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.results && res.success) {
          setDataCedulaList(
            res.data.map((item) => {
              if (item.Paterno == null) item.Paterno = "";
              if (item.Materno == null) item.Materno = "";
              if (item.NumExt == null) item.NumExt = "";
              return {
                ...item,
                NombreCompleto: `${item.Nombre} ${item.Paterno} ${item.Materno}`,
                Direccion: `${item.Calle} ${item.NumExt} ${item.Colonia} ${item.CP} ${item.Municipio} GUANAJUATO`,
              };
            })
          );
          setTotal(res.total);
        } else {
          swal({
            title: "¡Atención!",
            text: `${res.message}`,
            icon: "info",
          });
        }
        setLoadingCedulaList(false);
      })
      .catch((e) => {
        setLoadingCedulaList(false);
        swal({
          title: "¡Atención!",
          text: `${e.message}`,
          icon: "warning",
        });
      });
  };

  const btnExportar = () => {
    const token = getVars("Token");
    var url = new URL(URL_GENERAL + `getReporteVales2022?token=${token.token}`);
    window.open(url, "_blank");
  };

  return (
    <>
      <div className="wrapper">
        <Sidebar {...props} bgColor="black" activeColor="info" />
        <div className="main-panel">
          <Navbar {...props} handleMiniClick={handleMiniClick} />
          <Notifications />
          <div className="content">
            <>
              <Row>
                <Col md="12">
                  <Card>
                    <CardHeader>
                      <Row>
                        <Col md="9">
                          <CardTitle tag="h6">Apoyos</CardTitle>
                        </Col>
                        <Col md="2">
                          {menu.Exportar === 1 ? (
                            <>
                              <Button
                                color="success"
                                size="sm"
                                onClick={btnExportar}
                              >
                                <span className="btn-label">
                                  <i className="fa fa-file-excel-o fa-2x" />
                                </span>
                                Exportar
                              </Button>
                            </>
                          ) : (
                            <></>
                          )}
                        </Col>
                      </Row>
                    </CardHeader>
                    <CardBody>
                      {loadingCedulaList && (
                        <CircleSpinner
                          className="text-center"
                          size={30}
                          color="#686769"
                          loading={loadingCedulaList}
                        />
                      )}
                      <Col lg="12" md="12">
                        {menuLoaded && dataCedulaList && (
                          <ReactTable
                            size="sm"
                            data={dataCedulaList}
                            noDataText="No Existen Datos a Mostrar!"
                            manual
                            columns={columns}
                            className="-striped -highlight primary-pagination"
                            loading={loadingCedulaList}
                            showPagination={true}
                            showPaginationBottom={true}
                            showTotalPages={true}
                            showPageJump={false}
                            canNextFromData={true}
                            defaultPageSize={pageOptions.pageSize}
                            total={total}
                            page={pageOptions.page}
                            pages={Math.floor(total / pageOptions.pageSize)}
                            previousText="Anterior"
                            nextText="Siguiente"
                            ofText="de"
                            pageSizeOptions={[10, 20, 25, 50, 100]}
                            PaginationComponent={Pagination}
                            onFetchData={fetchData}
                          />
                        )}
                      </Col>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <PluginFilterSolicitudVales2022
                sendFiltro={setFilter}
                clasess="dropdowns"
              />
              <Footer fluid />
            </>
          </div>
        </div>
      </div>
    </>
  );
};
export default ValesEntregados;
