import React from "react";

import {
  Badge,
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  Row,
  Spinner,
  Card,
  CardImg,
} from "reactstrap";
import { isNullOrUndefined } from "../../utils/emptyValidations";
import { Link, Redirect } from "react-router-dom";
const ListadoArchivos = ({
  files,
  archivosClasificacion,
  catalogClasificacion,
  handleChangeArchivos,
  handleChangeClasificacion,
  handleDeleteFile,
  loadingCatalogs,
  onlyForView,
  fullscreen = false,
}) => {
  const handleSelectItems = (newFiles) => {
    console.log(newFiles);
    handleChangeArchivos(files.concat(Array.from(newFiles)));
  };

  const getValorClasificacion = (index) => {
    const valor = archivosClasificacion[index];
    if (isNullOrUndefined(valor)) return "";
    return valor;
  };

  const handleSelectClasificacion = (idClasificacion, index) => {
    let clasificacionAux = [...archivosClasificacion];
    clasificacionAux[index] = idClasificacion;
    handleChangeClasificacion(clasificacionAux);
  };

  const deleteFile = (index) => {
    let filesAux = [...files];
    let clasificacionAux = [...archivosClasificacion];
    filesAux.splice(index, 1);
    clasificacionAux.splice(index, 1);
    handleDeleteFile(filesAux, clasificacionAux);
  };
  return (
    <Row style={{ width: "100%" }}>
      <Col xs="12">
        <ListGroup
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
          }}
        >
          {files.map((file, index) => (
            <>
              <ListGroupItem
                key={index}
                style={{ width: fullscreen ? "100%" : "80%" }}
              >
                <Row>
                  <Col
                    sm="3"
                    xs="6"
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {file && file.type === "image" ? (
                      <Card>
                        <CardImg variant="top" src={file.ruta}></CardImg>
                      </Card>
                    ) : file.type === "pdf" ? (
                      <i
                        class="fa fa-file-pdf-o fa-4x"
                        aria-hidden="true"
                        style={{ color: "#d34a37", alignItems: "center" }}
                      ></i>
                    ) : (
                      <i
                        class="fa fa-file-image-o fa-4x"
                        aria-hidden="true"
                        style={{ color: "#d34a37", alignItems: "center" }}
                      ></i>
                    )}
                  </Col>
                  <Col
                    sm="3"
                    xs="6"
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {" "}
                    {file && (
                      <Link
                        //to={file.ruta}
                        className={(isActive) =>
                          "nav-link" + (!isActive ? " unselected" : "")
                        }
                        onClick={(e) => {
                          e.preventDefault();
                          window.open(file.ruta, "_blank");
                        }}
                      >
                        {file.name}
                      </Link>
                    )}
                  </Col>
                  <Col sm="4" xs="8">
                    <FormGroup>
                      <Label>
                        Clasificación: <span className="text-danger">*</span>
                        {loadingCatalogs && <Spinner size="sm" />}
                      </Label>
                      <Input
                        name="idTipoCombustible"
                        type="select"
                        disabled={loadingCatalogs || onlyForView}
                        value={getValorClasificacion(index)}
                        onChange={(e) =>
                          handleSelectClasificacion(e.target.value, index)
                        }
                      >
                        <option value={""}></option>
                        {catalogClasificacion.map((element) => (
                          <option key={element.value} value={element.value}>
                            {element.label}
                          </option>
                        ))}
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col sm="1" xs="4">
                    {!onlyForView && (
                      <Button
                        color="danger"
                        outline
                        onClick={() => deleteFile(index)}
                      >
                        <span className="btn-label">
                          <i className="fa fa-trash fa-xs"></i>
                        </span>
                      </Button>
                    )}
                  </Col>
                </Row>
              </ListGroupItem>
            </>
          ))}
        </ListGroup>
      </Col>
      {!onlyForView && (
        <Col xs="12" style={{ display: "flex", justifyContent: "center" }}>
          <FormGroup>
            <Input
              id="exampleFile"
              name="file"
              type="file"
              // style={{display: "none"}}
              style={{ cursor: "pointer" }}
              multiple
              onChange={(e) => handleSelectItems(e.target.files)}
            />
            <Button onClick={() => {}}>Seleccionar archivos</Button>
          </FormGroup>
        </Col>
      )}
    </Row>
  );
};

export default ListadoArchivos;
