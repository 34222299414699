import React, { useState, useEffect } from 'react';
import { Button, Input, Form, FormGroup, CardBody, Row, Col, Card, CardHeader, CardTitle, Label, CardFooter } from 'reactstrap';
import ReactBSAlert from "react-bootstrap-sweetalert";
import Notifications, { notify } from 'react-notify-toast';
import EjecutarApi from '../Consultas/Consultas';
import { getVars } from '../GlobalStorage';
import InfiniteScroll from "react-infinite-scroll-component";
import { CircleSpinner } from 'react-spinners-kit';
import { URL_GENERAL } from '../data/consts';

function BravosEntrega({Data,onHide}) {
    const [flagBotonBuscar,setFlagBotonBuscar]=useState(true);
    const [flagBotonGuardar,setflagBotonGuardar]=useState(false);
    const [loading, setloading] = useState(false);
    const [dataUsers, setDataUsers] = useState({ data: [], total: 0 });
    const [hasMoreUsers, setHasMoreUsers] = useState(true);
    const [dataSearch, setDataSearch] = useState({ page: 0, pageSize: 20 });
    const [itemFolio, setItemFolio] = useState([]);
    const token = getVars('Token');
 
    const [flagmsgErrorAlerta,setflagmsgErrorAlerta]=useState(false);
    const [flagCancelarVale,setflagCancelarVale] = useState(false);
    const [BuscarCodigo,setBuscarCodigo] = useState('');
    const [CodigoBarras,setCodigoBarras] = useState('');
    const [Folio,setFolio] = useState('');
    const [Totales,setTotales] = useState({
        Total:0,
        Pendientes:0,
        Asignados:0
    })
    useEffect(() => {
        console.log('=>Data',Data)
        llenadoInfiniteScrool();
       
    }, []);

    const limpiarDataSet=()=>{
        setDataUsers({ data: [], total: 0 });
    }

    const llenadoInfiniteScrool=()=>{
        let datax = {
            page: 0,
            tipo: "and",
            pageSize: 50,
            filtered: [{ id: "bravos_invitados.Responsable", value: Data.Responsable }],//[{ "id": "vales.UserOwned", "value": dataGrupo.UserOwned }], //idARticuladortoken
            excluir_asignados:true
        };
        limpiarDataSet();
        EjecutarApi(datax, 'getListadoInvitados', token.token)
            .then(function (res) {
                return res;
            })
            .then(items => {
                console.log("===> Data getListadoInvitados: ", items);

                if (items.results) {
                    setDataSearch({ ...dataSearch, page: dataSearch.page + 1 });
                    setloading(false);
                    setTotales({Total:items.total, Pendientes:items.pendientes,Asignados:items.realizados})
                    setDataUsers({
                        data: items.data,//dataUsers.data.concat(items.data),
                        total: items.total
                    });
                }
            })
            .catch((e) => {
                console.log('=Error[getListadoInvitados]: ', e);
                setloading(false);
                notify.show(
                    `Error al obtener Vales: ${e.errors}`,
                    'custom',
                    5000,
                    {
                        background: '#0857B6',
                        fontSize: 14,
                        text: '#FFFFFF',
                    }
                );

                //setloadingGrupo(false);
            });
        
    }
   
    const handleReturn = () => {
       onHide();
    };
    
    const BravoEntrega = (_comentario,_isEntregadoOwner) => {
        setflagBotonGuardar(false);
        setloading(true);
        if(itemFolio.Folio)
        {
            var data = {
                Folio: itemFolio.Folio,
                CodigoBarras: CodigoBarras,// 1=Entregado, 2:CancelarVale [Obligatorio el comentario solo para el 2]
            
            };
            console.log("=>setAsignarCodigoBarrasInvitado, data", JSON.stringify(data));
            EjecutarApi(data, 'setAsignarCodigoBarrasInvitado', token.token)
            .then(function (res) {
                setloading(false);
                setCodigoBarras('');
                setFlagBotonBuscar(true);
                setBuscarCodigo('');
                llenadoInfiniteScrool();
                setItemFolio([]);
                setFolio('');
                notify.show('Registro Exitoso!', 'custom', 5000, {
                    background: '#18c97d',
                    text: '#FFFFFF',
                });
            })
            .catch((e) => {
                setloading(false);
                console.log("error", e.errors);

                notify.show(`Error:! ${e.message}`, 'custom', 5000, {
                    background: '#d61818',
                    text: '#FFFFFF',
                });
            });
            
        }
        else{
            setloading(false);
            notify.show(`Error: Debe seleccionar un Invitado!!!`, 'custom', 5000, {
                background: '#d61818',
                text: '#FFFFFF',
            });
            setflagBotonGuardar(true)
        }
    }
 
    const fetchMoreUsers = () => {
        if (dataSearch.page * dataSearch.pageSize >= dataUsers.total) {
            console.log("=>PorAutorizar Llego a su final: ",
                dataSearch.page * dataSearch.pageSize
            );

            setHasMoreUsers(false);
            return;
        }

        setloading(true);

        const data = {
            page: dataSearch.page + 1,
            tipo: "and",
            pageSize: dataSearch.pageSize,
            sorted: [],
            filtered: [],//[{ "id": "vales.UserOwned", "value": dataGrupo.UserOwned }], //idARticuladortoken
            excluir_asignados:true
        };
        console.log("= getValesDocumentacionIn:  ", data);

        EjecutarApi(data, 'getListadoInvitados', token.token)
        .then(function (res) {
            return res;
        })
        .then(items => {
            console.log("===> Data getListadoInvitados: ", items);

            if (items.results) {
                setDataSearch({ ...dataSearch, page: dataSearch.page + 1 });
                setloading(false);

                setDataUsers({
                    data: items.data,//dataUsers.data.concat(items.data),
                    total: items.total
                });
            }
        })
        .catch((e) => {
            console.log('=Error[getListadoInvitados]: ', e);
            setloading(false);
            notify.show(
                `Error al obtener Vales: ${e.errors}`,
                'custom',
                5000,
                {
                    background: '#0857B6',
                    fontSize: 14,
                    text: '#FFFFFF',
                }
            );

            //setloadingGrupo(false);
        });
    
        };
    
    const visualizaDetalleVales = (item) => {
        console.log('Entra a setear Detalles Vales => item', item);
        setItemFolio(item);
    };

    const alertaMensaje = (<ReactBSAlert
        style={{ display: "block", marginTop: "-100px" , fontSize:"8px" }}
        title={'Error:  ' + '' + '!!!!'}
        confirmBtnText="Aceptar"
        cancelBtnText="No"
        onConfirm={() => {setflagmsgErrorAlerta(false); }}
        onCancel={()=>{flagmsgErrorAlerta(false);} }
        confirmBtnBsStyle="info sm btn-round"
        
      />);
    const inputConfirmAlert =(e)=>{
      
        BravoEntrega(e,2);
        setflagCancelarVale(false);
    };
    const CancelarValeinputAlert = (
        <ReactBSAlert
            input
            showCancel
            style={{ display: "block", marginTop: "-100px" }}
            title={"Comentario"}
            onConfirm={e => inputConfirmAlert(e)}
            onCancel={() => setflagCancelarVale(false)}
            confirmBtnBsStyle="info"
            confirmBtnText="Cancelar"
            cancelBtnBsStyle="danger"
            cancelBtnText="Salir"
        />
    );
  
    const BravoBuscar =()=>{
        setloading(true);
        var data = {
            CodigoBarras: BuscarCodigo,// 1=Entregado, 2:CancelarVale [Obligatorio el comentario solo para el 2]
        };
        console.log("=>getDisponibilidadCodigoBarra, data", JSON.stringify(data));
       
        EjecutarApi(data, 'getDisponibilidadCodigoBarra', token.token)
            .then(function (res) {
                if(res.results)
                {
                    setloading(false);
                    setflagBotonGuardar(true);
                    setFlagBotonBuscar(false);
                    setCodigoBarras(res.data.CodigoBarras);
                    setFolio(res.data.id);
                }
                else
                {
                    notify.show(`Error: ${res.message}`, 'custom', 5000, {
                        background: '#18c97d',
                        text: '#FFFFFF',
                    });
                }

            })
            .catch((e) => {
                setloading(false);
                console.log("error", e.errors);
                notify.show(`Error:! ${e.message}`, 'custom', 5000, {
                    background: '#d61818',
                    text: '#FFFFFF',
                });
            });
            llenadoInfiniteScrool();

    }
    const handleDescargaExcel = (param) => {
        
        var url = new URL(URL_GENERAL+`getReporteInvitadosPorResponsable?token=${token.token}&Responsable=${Data.Responsable}&Municipio=${Data.Municipio}`);
        window.open(url, '_blank');

    }
    const handleDescargaWord = (param) => {
        if(Totales.Asignados>0)
        {
            var url = new URL(URL_GENERAL+`getCodigoBarra?token=${token.token}&Responsable=${Data.Responsable}&Municipio=${Data.Municipio}`);
            window.open(url, '_blank');
        }
        else
        {
            notify.show(
                `Aún no tiene Código de Barras asignados!!!!`,
                'custom',
                5000,
                {
                    background: '#d61818',
                    fontSize: 14,
                    text: '#FFFFFF',
                }
            );

        }

    }
    return (
        <div>
            <Row>
                <Col md="12">
                    <Card>
                        <CardHeader>
                            <Row>
                                <Col md="9">
                                    <CardTitle tag="h6">Asignación de Pulsera</CardTitle>
                                </Col>
                                <Col>
                                    <Button color="success" onClick={() => { handleReturn() }} size="sm"  >
                                        <i className="nc-icon nc-minimal-left" />
                                    Regresar
                                    </Button>
                                </Col>
                                
                            </Row>
                        </CardHeader>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col md="12">
                    <Card>
                        <CardBody>
                            <Form className="form-horizontal" id="TypeValidation">
                                <Notifications />
                                <Row>
                                    <Col sm="6">
                                        <FormGroup >
                                            <Label>Responsable:<span className="text-danger">*</span></Label>
                                            <Input id="articu" type="text" value={Data.Responsable} disabled />
                                        </FormGroup>
                                    </Col>
                                    <Col sm="6">
                                        <FormGroup >
                                            <Label>Municipio:<span className="text-danger">*</span></Label>
                                            <Input id="articu" type="text" value={Data.Municipio} disabled />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm="3">
                                        <Label>Total:<span className="text-danger">*</span></Label>
                                        <FormGroup >
                                            <Input id="Total" type="text" value={Totales.Total} disabled />
                                        </FormGroup>
                                    </Col>
                                    <Col sm="3">
                                   
                                        <Label>Faltantes:<span className="text-danger">*</span></Label>
                                        <FormGroup >
                                            <Input id="Total" type="text" value={Totales.Pendientes} disabled />
                                        </FormGroup>
                                    </Col>
                                    <Col sm="3">
                                   
                                        <Label>Asginados:<span className="text-danger">*</span></Label>
                                        <FormGroup >
                                            <Input id="Total" type="text" value={Totales.Asignados} disabled />
                                        </FormGroup>
                                    </Col>
                                    <Col sm="3">
                                        <Button className="btn-link" color="success" onClick={() => { handleDescargaExcel() }}>
                                            <i className="fa fa-file-excel-o fa-2x" />
                                        </Button>
                                        <Button className="btn-link" color="primary" onClick={() => { handleDescargaWord() }}>
                                            <i className="fa fa-file-word-o fa-2x" />
                                        </Button>
                                    </Col>
                                </Row>
                                
                            </Form>
                        </CardBody>
                        <CardFooter className="text-center">

                        </CardFooter>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col md="6">
                    <Card>
                        <CardHeader>
                            <Row>
                                <Col>
                                    <CardTitle tag="h6">Listado de Invitados </CardTitle>
                                        <h6 className='text-muted'>{Totales.Asignados} Asignados de {Totales.Total}</h6>
                                </Col>
                            </Row>

                        </CardHeader>

                        <CardBody>
                            <InfiniteScroll
                                dataLength={dataUsers.data.length}
                                next={fetchMoreUsers}
                                hasMore={hasMoreUsers}
                                loader={dataUsers.data.length===0?<h4>Sin Registros...</h4>:<h4>cargando...</h4>}
                                height={600}
                                endMessage={
                                    <p style={{ textAlign: "center" }}>
                                        <b>Listo! Esos son todos!</b>
                                    </p>
                                }
                            >
                                {dataUsers.data.map((item, index) =>
                                    (item.CodigoBarras==="")?(<></>):(
                                    
                                    <div key={index}>
                                        <Button size="sm" color="primary" onClick={() => {visualizaDetalleVales(item)}}>
                                        <h6>
                                            {`${item.NombreCompleto}`}
                                        </h6>

                                        <h6>
                                            {`Mpio: ${item.Municipio}`}
                                        </h6>

                                        <h6>
                                            {`Número Burbuja: ${item.NumeroBurbuja}`}
                                        </h6>
                                        </Button>
                                        
                                        <hr />
                                    </div>)
                                                    
                                )}
                            </InfiniteScroll>
                        </CardBody>


                    </Card>
                </Col>

                <Col md="6">

                    <Form md="12">
                        <Card md="12">
                            <CardHeader md="12">
                                <h6>Asignar Pulsera</h6>
                            </CardHeader>
                            <CardBody md="12">
                                <Row className='text-center'>
                                    <Col md='12' className='text-center'>
                                        {itemFolio.length===0?(<>
                                            <h6 className='text-center'>Seleccinar un Invitado</h6></>
                                        ):( <>
                                            <h6 className='text-center'>{`${itemFolio.NombreCompleto}`}</h6>
                                        </>
                                        )
                                        }
                                    
                                    </Col>
                                    
                                </Row>
                                <Row>
                                    <Label sm="4">Folio/Código Barras:<span className="text-danger">*</span></Label>
                                    <Col sm="8">
                                        <FormGroup >
                                            <Input
                                                name="Código Barras"
                                                type="text"
                                                value={BuscarCodigo}
                                                onChange={event => setBuscarCodigo(event.target.value)}
                                                
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Label sm="4">Folio:<span className="text-danger">*</span></Label>
                                    <Col sm="8">
                                        <FormGroup >
                                            <Input
                                                name="FolioFinal"
                                                type="text"
                                                value={Folio}
                                                disabled
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Label sm="4">Código Barras:<span className="text-danger">*</span></Label>
                                    <Col sm="8">
                                        <FormGroup >
                                            <Input
                                                name="FolioFinal"
                                                type="text"
                                                value={CodigoBarras}
                                                disabled
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            
                            </CardBody>
                            <CardFooter>
                                <Row>
                                    {loading && (
                                        <CircleSpinner
                                            className="text-center"
                                            size={30}
                                            color="#686769"
                                            loading={loading}
                                        />
                                    )}
                                    {flagBotonBuscar?
                                        (<>
                                            <Button color="primary" onClick={() => { BravoBuscar()  }} size="sm"  >
                                                Buscar  </Button></>
                                        ) : (<>
                                            </>

                                        )}
                                    {flagBotonGuardar?
                                        (<>
                                            <Button color="primary" onClick={() => { BravoEntrega("",1)  }} size="sm"  >
                                                Asignar  </Button>
                                            {' '}
                                            <Button color="danger" onClick={()=>{setFlagBotonBuscar(true);setflagBotonGuardar(false)}} size="sm"  >
                                                <i className="nc-icon nc-simple-remove" />
                                            Cancelar
                                        </Button> </>
                                        ) : (<>
                                            </>

                                        )}
                                </Row>
                                {flagCancelarVale?CancelarValeinputAlert:<></>}
                            </CardFooter>
                        </Card>
                    </Form>

                </Col>
            </Row>
        </div>
    );
}
export default BravosEntrega;