import React, { useState, useEffect } from "react";
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Row,
    Col,
    CardFooter,
    Form,
    FormGroup,
    Label,
    Input
} from "reactstrap";
import Select from "react-select";
import { CircleSpinner } from "react-spinners-kit";
import swal from "sweetalert";
import PublicNavBar from "./PublicNavBar";
import Footer from "./Footer"
import ApiExec from "./ApiExec";
import { renapoValidation } from './global'

const ValidaCurp = (props) => {
    const [CURP, setCurp] = useState('');
    const [Remesa, setRemesa] = useState({ value: "", label: "--  SELECCIONE --" });
    const [loading, setLoading] = useState(false);
    const [validCURP, setValidCURP] = useState(false);
    const [valid, setValid] = useState(true);
    const [message, setMessage] = useState('');
    const [coords, setCoords] = useState({
        Latitud: null,
        Longitud: null
    });
    const [catRemesas] = useState([
        { value: "02_2022", label: "02_2022" },
        { value: "03_2022", label: "03_2022" },
        { value: "04_2022", label: "04_2022" },
        { value: "05_2022", label: "05_2022" },
        { value: "06_2022", label: "06_2022" },
        { value: "07_2022", label: "07_2022" }
    ])

    useEffect(() => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                setCoords({
                    Latitud: position.coords.latitude,
                    Longitud: position.coords.longitude
                })
            })
        }
    }, [])

    const validateRegex = (curp) => {
        const re = /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/g;
        return curp.match(re) ? true : false;
    }

    const handleChangeCURP = (e) => {
        const value = e.target.value.toUpperCase();
        setMessage('');
        setCurp(value);
        setValidCURP(validateRegex(value));
    }

    const handleSearch = (e) => {
        setLoading(true);
        e.preventDefault();
        setValid(false);
        setMessage('');

        if (!validateRegex(CURP)) {
            setValidCURP(false);
            setLoading(false);
            return;
        }

        if (Remesa.value.length < 1) {
            setLoading(false);
            return;
        }

        renapoValidation(CURP).then((res) => {
            if (res.Mensaje === "OK") {
                handleLocalSearch()
            } else {
                setLoading(false);
                swal({ title: 'CURP no válida ante RENAPO', content: 'Verificar la captura', icon: 'warning' });
            }
        }).catch(e => {
            // swal({ title: 'La CURP no pudo ser validada ante RENAPO', content: 'Contacte al administrador', icon: 'warning' });
            handleLocalSearch()
        }).finally(() => {
            handleClearForm();
        });
    }

    const handleLocalSearch = () => {
        ApiExec({ CURP, Remesa: Remesa.value, ...coords }, `coupons/validate-curp-reassignment`, "POST")
            .then(function (res) {
                return res;
            })
            .then((res) => {
                if (res.results) {
                    setValid(res.response.valid);
                    setMessage(res.message)
                } else {
                    swal({ title: res.message, icon: 'warning' })
                }
            })
            .catch((e) => {
                swal({ title: e.message, icon: 'warning' })
            }).finally(() => {
                setLoading(false);
                handleClearForm();
            });
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            handleSearch(e)
        }
    }

    const handleClearForm = () => {
        setCurp('');
        setRemesa({ value: "", label: "--  SELECCIONE --" });
        setValidCURP(false);
    }

    return (
        <>
            <PublicNavBar />
            <div id="render-body" className="pt-6 grey lighten-5">
                <div className="pt-6 pb-4">
                    <div className="container">
                        <Row>
                            <Col md="12">
                                <Card>
                                    <CardHeader className="gto-blue text-center text-white">
                                        <Row>
                                            <Col md="4" sm="12" className="offset-md-4">
                                                <CardTitle tag="h4">Consulta CURP</CardTitle>
                                            </Col>
                                        </Row>
                                    </CardHeader>
                                    <CardBody className="pt-4 pb-4">
                                        <Form className="needs-validation">
                                            <Row className="text-secondary text-center font-weight-bolder">
                                                <Col md="6" sm="12" className="offset-md-3">
                                                    <h6>Reasignaciones</h6>
                                                    Ingrese la CURP del beneficiario y seleccione la remesa que desea validar
                                                </Col>
                                            </Row>
                                            {
                                                message.length > 0 && (
                                                    <Row className="pt-4">
                                                        <Col md="6" sm="12" className="offset-md-3 text-center">
                                                            <span className="btn-label">
                                                                <i className={`fa fa-${valid ? 'check-circle' : 'exclamation-circle'} fa-5x text-${valid ? 'success' : 'danger'}`} />
                                                            </span>
                                                        </Col>
                                                    </Row>
                                                )
                                            }
                                            <Row>
                                                <Col md="6" sm="12" className="offset-md-3">
                                                    <FormGroup>
                                                        <Label className={`col-form-label text-${validCURP ? 'success' : 'danger'}`}>CURP</Label>
                                                        <Input
                                                            type="text"
                                                            value={CURP}
                                                            onChange={handleChangeCURP}
                                                            onKeyDown={handleKeyDown}
                                                            className={`form-control ${validCURP ? 'is-valid' : 'is-invalid'}`}

                                                        >
                                                        </Input>
                                                        {!validCURP && (<div className="invalid-feedback">Ingrese una CURP válida</div>)}
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label className={`col-form-label text-${Remesa.value.length > 0 ? 'success' : 'danger'}`}>REMESA</Label>
                                                        <Select
                                                            name="Remesa"
                                                            placeholder="Remesa"
                                                            value={Remesa}
                                                            options={catRemesas}
                                                            onChange={e => setRemesa(e)}
                                                            className={`${Remesa.value.length > 0 ? 'is-valid' : 'is-invalid'}`}
                                                        />
                                                        {Remesa.value.length < 1 && (<div className="invalid-feedback">Seleccione una Remesa</div>)}
                                                    </FormGroup>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col md="6" sm="12" className="offset-md-3">
                                                    {
                                                        message.length > 0 && (
                                                            <div className={`alert alert-${valid ? 'success' : 'danger'}`} role="alert">
                                                                La CURP: <strong>{CURP}</strong> {message}
                                                            </div>
                                                        )
                                                    }
                                                </Col>
                                            </Row>
                                        </Form>
                                    </CardBody>
                                    <CardFooter className="pb-4">
                                        <Col md="6" sm="12" className="offset-md-3">
                                            {loading && (
                                                <CircleSpinner
                                                    className="text-center"
                                                    size={30}
                                                    color="#686769"
                                                    loading={loading}
                                                />
                                            )
                                            }
                                            <Button className="btn btn-block" color="warning" onClick={handleSearch} disabled={loading}>
                                                <span className="btn-label">
                                                    <i className="fa fa-search fa-2x" />
                                                </span>
                                                Consultar
                                            </Button>
                                        </Col>
                                    </CardFooter>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
            <Footer />
        </>);
};

export default ValidaCurp
    ;
