import React, { useState, useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
} from "reactstrap";
import swal from "sweetalert";
import ReactTable from "react-table";
import { CircleSpinner } from "react-spinners-kit";
import EjecutarApi from "../Consultas/Consultas";
import Notifications, { notify } from "react-notify-toast";
import { getVars } from "../GlobalStorage";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Sidebar from "../components/Sidebar";
import Pagination from "../components/pagination";
import PluginFilterPrevalidacion from "../components/PluginFilterPrevalidacion";
import { isNullOrUndefined } from "../utils/emptyValidations";
import ListadoExpedientes from "./ListadoValesExpedientes";

const Prevalidacion = (props) => {
  const [userData, setUserData] = useState();
  const [menu, setMenu] = useState({
    Ver: 0,
    Agregar: 0,
    Editar: 0,
    Eliminar: 0,
    Seguimiento: 0,
    Exportar: 0,
    Imprimir: 0,
    ViewAll: 0,
  });
  const history = useHistory();
  const [total, setTotal] = useState(0);
  const [loadingCedulaList, setLoadingCedulaList] = useState(false);
  const [dataCedulaList, setDataCedulaList] = useState([]);
  const [menuLoaded, setMenuLoaded] = useState(false);
  const [TotalSolicitudes, setTotalSolicitudes] = useState(0);
  const [TotalExpedientes, setTotalExpedientes] = useState(0);
  const [TotalPendientes, setTotalPendientes] = useState(0);
  const [TotalValidados, setTotalValidados] = useState(0);
  //Filtro
  const [filtro, setFiltro] = useState([]);
  const [pageOptions, setPage] = useState({
    page: 0,
    pageSize: 10,
  });
  const [isSolicitud, setIsSolicitud] = useState(false);
  const [isArchivos, setIsArchivos] = useState(false);
  const [solicitud, setSolicitud] = useState(null);
  const [archivo, setArchivo] = useState(null);

  const columns = [
    {
      Header: "Acciones",
      minWidth: 50,
      id: "Acciones",
      accessor: (d) => d.Acciones,
      className: "text-center",
      filterAll: false,
      style: { fontSize: "10px" },
      headerStyle: { fontSize: "10px" },
    },
    {
      Header: "Remesa",
      minWidth: 50,
      id: "Remesa",
      accessor: (d) => d.Remesa,
      filterAll: false,
      style: { fontSize: "10px", textAlign: "center" },
      headerStyle: { fontSize: "10px" },
    },
    {
      Header: "Region",
      minWidth: 50,
      id: "Region",
      accessor: (d) => d.Region,
      filterAll: false,
      style: { fontSize: "10px", textAlign: "center" },
      headerStyle: { fontSize: "10px" },
    },
    {
      Header: "Municipio",
      headerClassName: "text-center",
      minWidth: 90,
      id: "Folio",
      accessor: (d) => d.Municipio,
      filterAll: false,
      style: { fontSize: "10px" },
      headerStyle: { fontSize: "10px" },
    },
    {
      Header: "CveInterventor",
      headerClassName: "text-center",
      minWidth: 70,
      id: "CveInterventor",
      accessor: (d) => d.CveInterventor,
      filterAll: false,
      style: { fontSize: "10px", textAlign: "center" },
      headerStyle: { fontSize: "10px" },
    },
    {
      Header: "Beneficiarios",
      headerClassName: "text-center",
      minWidth: 65,
      id: "Beneficiarios",
      accessor: (d) => d.Beneficiarios,
      filterAll: false,
      style: { fontSize: "10px", textAlign: "center" },
      headerStyle: { fontSize: "10px" },
    },
    {
      Header: "Expedientes",
      headerClassName: "text-center",
      minWidth: 65,
      id: "Expedientes",
      accessor: (d) => d.Expedientes,
      filterAll: false,
      style: { fontSize: "10px", textAlign: "center" },
      headerStyle: { fontSize: "10px" },
    },
    {
      Header: "Validados",
      headerClassName: "text-center",
      minWidth: 65,
      id: "Validados",
      accessor: (d) => d.Validados,
      filterAll: false,
      style: { fontSize: "10px", textAlign: "center" },
      headerStyle: { fontSize: "10px" },
    },
    {
      Header: "Pendientes",
      headerClassName: "text-center",
      minWidth: 65,
      id: "Pendientes",
      accessor: (d) => d.Pendientes,
      filterAll: false,
      style: { fontSize: "10px", textAlign: "center" },
      headerStyle: { fontSize: "10px" },
    },
    {
      Header: "Avance Expedientes",
      headerClassName: "text-center",
      minWidth: 120,
      id: "AExpedientes",
      accessor: (d) => d.AExpedientes,
      filterAll: false,
      style: { fontSize: "10px" },
      headerStyle: { fontSize: "10px" },
    },
    {
      Header: "Avance Validación",
      headerClassName: "text-center",
      minWidth: 120,
      id: "AValidacion",
      accessor: (d) => d.AValidacion,
      filterAll: false,
      style: { fontSize: "10px" },
      headerStyle: { fontSize: "10px" },
    },
  ];

  const handleMiniClick = () => {
    document.body.classList.toggle("sidebar-mini");
  };

  useEffect(() => {
    setUserData(getVars("Token"));
  }, []);

  const handleShowFiles = (cedula) => {
    setIsArchivos(true);
    cedula.idPrograma = 2;
    cedula.menu = menu;
    setArchivo(cedula);
  };

  useEffect(() => {
    if (isNullOrUndefined(userData)) return;
    const menus = userData.menu;
    const currentMenu = menus.find((menu) => menu.idMenu === 30);
    if (isNullOrUndefined(currentMenu) || currentMenu.Ver < 1) {
      swal({
        title: "Atención",
        text: "No tiene permisos para este módulo, contacte al administrador",
        icon: "warning",
      });
      history.push("/not-found");
      return;
    }
    setMenu({
      Ver: currentMenu.Ver,
      Agregar: currentMenu.Agregar,
      Editar: currentMenu.Editar,
      Eliminar: currentMenu.Eliminar,
      Seguimiento: currentMenu.Seguimiento,
      Exportar: currentMenu.Exportar,
      Imprimir: currentMenu.Imprimir,
      ViewAll: currentMenu.ViewAll,
    });

    setMenuLoaded(true);
  }, [userData]);

  const fetchData = useCallback(
    ({ pageSize, page, sorted, params }) => {
      const data = {
        page: page > pageOptions.page ? page : pageOptions.page,
        pageSize: pageSize,
        programa: 1,
        filtered: filtro,
      };
      setPage({
        page: page,
        pageSize: pageSize,
      });
      getRegistros(data);
    },
    [menu, filtro]
  );

  const getRegistros = (data) => {
    const token = getVars("Token");
    setLoadingCedulaList(true);
    data.filtered = filtro;
    EjecutarApi(data, "vales/getValesExpedientes", token.token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.results && res.success) {
          setTotal(res.total);

          setDataCedulaList(
            res.data.map((item) => {
              const avanceExpediente = Math.trunc(
                (item.Expedientes * 100) / item.Beneficiarios
              );
              const avanceValidacion = Math.trunc(
                (item.Validados * 100) / item.Beneficiarios
              );
              return {
                ...item,
                Expedientes: item.Expedientes ? item.Expedientes : 0,
                Pendientes: item.Beneficiarios - item.Validados,
                Acciones: (
                  <>
                    <Row>
                      <Col>
                        <Button
                          color="primary"
                          size="sm"
                          style={{ width: "100%" }}
                          onClick={() => sendDataSolicitud(item)}
                        >
                          Ver
                        </Button>
                      </Col>
                    </Row>
                  </>
                ),
                AExpedientes: (
                  <div>
                    <div
                      className="progress"
                      style={{ marginTop: "1rem", height: "18px" }}
                    >
                      <div
                        className="progress-bar progress-bar-striped progress-bar-animated bg-info"
                        role="progressbar"
                        style={{
                          width: avanceExpediente + "%",
                        }}
                        aria-valuenow="10"
                        aria-valuemin="0"
                        aria-valuemax={item.Beneficiarios}
                      ></div>
                    </div>
                    <span
                      style={{
                        color: "black",
                        fontSize: "12px",
                        fontWeight: "bold",
                      }}
                    >
                      {avanceExpediente}%
                    </span>
                  </div>
                ),
                AValidacion: (
                  <div>
                    <div
                      className="progress"
                      style={{ marginTop: "1rem", height: "18px" }}
                    >
                      <div
                        className="progress-bar progress-bar-striped progress-bar-animated bg-info"
                        role="progressbar"
                        style={{
                          width: avanceValidacion + "%",
                        }}
                        aria-valuenow="10"
                        aria-valuemin="0"
                        aria-valuemax={item.Beneficiarios}
                      ></div>
                    </div>
                    <span
                      style={{
                        color: "black",
                        fontSize: "12px",
                        fontWeight: "bold",
                      }}
                    >
                      {avanceValidacion}%
                    </span>
                  </div>
                ),
              };
            })
          );
        } else {
          swal({
            title: "¡Atención!",
            text: `${res.errors}`,
            icon: "info",
          });
        }
        setLoadingCedulaList(false);
      })
      .catch((e) => {
        setLoadingCedulaList(false);
        swal({
          title: "¡Atención!",
          text: `${e.message}`,
          icon: "warning",
        });
      });
  };

  const sendDataSolicitud = (item) => {
    setSolicitud(item);
    setIsSolicitud(!isSolicitud);
  };

  const setFilter = (filtered) => {
    setLoadingCedulaList(true);
    filtered.programa = 2;
    setFiltro(filtered.filtered);
    const token = getVars("Token");
    EjecutarApi(filtered, "vales/getValesExpedientes", token.token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.results && res.success) {
          setTotal(res.total);
          setDataCedulaList(
            res.data.map((item) => {
              const avanceExpediente = Math.trunc(
                (item.Expedientes * 100) / item.Beneficiarios
              );
              const avanceValidacion = Math.trunc(
                (item.Validados * 100) / item.Beneficiarios
              );
              return {
                ...item,
                Expedientes: item.Expedientes ? item.Expedientes : 0,
                Pendientes: item.Beneficiarios - item.Validados,
                Acciones: (
                  <>
                    <Row>
                      <Col>
                        <Button
                          color="primary"
                          size="sm"
                          style={{ width: "100%" }}
                          onClick={() => sendDataSolicitud(item)}
                        >
                          Ver
                        </Button>
                      </Col>
                    </Row>
                  </>
                ),
                AExpedientes: (
                  <div>
                    <div
                      class="progress"
                      style={{ marginTop: "1rem", height: "18px" }}
                    >
                      <div
                        class="progress-bar progress-bar-striped progress-bar-animated bg-info"
                        role="progressbar"
                        style={{
                          width: avanceExpediente,
                        }}
                        aria-valuenow="10"
                        aria-valuemin="0"
                        aria-valuemax={item.Beneficiarios + "%"}
                      ></div>
                    </div>
                    <span
                      style={{
                        color: "black",
                        fontSize: "12px",
                        fontWeight: "bold",
                      }}
                    >
                      {avanceExpediente}%
                    </span>
                  </div>
                ),
                AValidacion: (
                  <div>
                    <div
                      class="progress"
                      style={{ marginTop: "1rem", height: "18px" }}
                    >
                      <div
                        class="progress-bar progress-bar-striped progress-bar-animated bg-info"
                        role="progressbar"
                        style={{
                          width: avanceValidacion,
                        }}
                        aria-valuenow="10"
                        aria-valuemin="0"
                        aria-valuemax={item.Beneficiarios + "%"}
                      ></div>
                    </div>
                    <span
                      style={{
                        color: "black",
                        fontSize: "12px",
                        fontWeight: "bold",
                      }}
                    >
                      {avanceValidacion}%
                    </span>
                  </div>
                ),
              };
            })
          );
        } else {
          swal({
            title: "¡Atención!",
            text: `${res.errors}`,
            icon: "info",
          });
        }
        setLoadingCedulaList(false);
      })
      .catch((e) => {
        setLoadingCedulaList(false);
        swal({
          title: "¡Atención!",
          text: `${e.message}`,
          icon: "warning",
        });
      });
  };

  useEffect(() => {
    const token = getVars("Token").token;
    EjecutarApi({}, "vales/getTotalSolicitudes", token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.results) {
          setTotalSolicitudes(res.capturadas);
        }
      })
      .catch((e) => {
        console.log(e);
        notify.show(
          `Error en al obtener el total de solicitudes! ${e.message}`,
          "custom",
          5000,
          {
            background: "#d61818",
            text: "#FFFFFF",
          }
        );
      });
  }, []);

  useEffect(() => {
    const token = getVars("Token").token;
    EjecutarApi({}, "vales/getTotalExpedientes", token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.results) {
          setTotalExpedientes(res.expedientes);
        }
      })
      .catch((e) => {
        notify.show(
          `Error al obtener el total de expedientes! ${e.message}`,
          "custom",
          5000,
          {
            background: "#d61818",
            text: "#FFFFFF",
          }
        );
      });
  }, []);
  useEffect(() => {
    const token = getVars("Token").token;
    EjecutarApi({}, "vales/getTotalPendientes", token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.results) {
          setTotalPendientes(res.pendientes);
        }
      })
      .catch((e) => {
        notify.show(
          `Error al obterner el total de observadas! ${e.message}`,
          "custom",
          5000,
          {
            background: "#d61818",
            text: "#FFFFFF",
          }
        );
      });
  }, [isSolicitud]);

  useEffect(() => {
    const token = getVars("Token").token;
    EjecutarApi({}, "vales/getTotalValidados", token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.results) {
          setTotalValidados(res.validados);
        }
      })
      .catch((e) => {
        notify.show(
          `Error en la obtencion de datos! ${e.message}`,
          "custom",
          5000,
          {
            background: "#d61818",
            text: "#FFFFFF",
          }
        );
      });
  }, [isSolicitud]);

  return (
    <>
      <div className="wrapper">
        <Sidebar {...props} bgColor="black" activeColor="info" />
        <div className="main-panel">
          <Navbar {...props} handleMiniClick={handleMiniClick} />
          <Notifications />
          <div className="content">
            {!isSolicitud && !isArchivos ? (
              <>
                <Row>
                  <Col lg="3" md="3" sm="12">
                    <Card className="card-stats">
                      <CardBody>
                        <Row>
                          <Col md="12" xs="12" style={{ textAlign: "center" }}>
                            <CardTitle>
                              <span
                                style={{
                                  color: "#2471a3",
                                  paddingRight: "2rem",
                                }}
                              >
                                <i
                                  className="fa fa-credit-card-alt fa-3x"
                                  aria-hidden="true"
                                ></i>
                              </span>

                              <span
                                style={{ fontSize: "25px", fontWeight: "bold" }}
                              >
                                {new Intl.NumberFormat("en-EN").format(
                                  TotalSolicitudes
                                )}
                              </span>
                            </CardTitle>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="12" xs="12" style={{ textAlign: "center" }}>
                            <p
                              style={{
                                fontSize: "15px",
                                color: "#2471a3",
                                fontWeight: "bold",
                              }}
                            >
                              Beneficiarios
                            </p>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg="3" md="3" sm="12">
                    <Card className="card-stats">
                      <CardBody>
                        <Row>
                          <Col md="12" xs="12" style={{ textAlign: "center" }}>
                            <CardTitle>
                              <span
                                style={{
                                  color: "#f39c12",
                                  paddingRight: "2rem",
                                }}
                              >
                                <i
                                  className="fa fa-files-o fa-3x"
                                  aria-hidden="true"
                                ></i>
                              </span>

                              <span
                                style={{ fontSize: "25px", fontWeight: "bold" }}
                              >
                                {new Intl.NumberFormat("en-EN").format(
                                  TotalExpedientes
                                )}
                              </span>
                            </CardTitle>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="12" xs="12" style={{ textAlign: "center" }}>
                            <p
                              style={{
                                fontSize: "15px",
                                color: "#f39c12",
                                fontWeight: "bold",
                              }}
                            >
                              Expedientes Cargados
                            </p>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>

                  <Col lg="3" md="3" sm="12">
                    <Card className="card-stats">
                      <CardBody>
                        <Row>
                          <Col md="12" xs="12" style={{ textAlign: "center" }}>
                            <CardTitle>
                              <span
                                style={{
                                  color: "#d4ac0d",
                                  paddingRight: "2rem",
                                }}
                              >
                                <i
                                  className="fa fa-eye fa-3x"
                                  aria-hidden="true"
                                ></i>
                              </span>

                              <span
                                style={{
                                  fontSize: "25px",
                                  fontWeight: "bold",
                                }}
                              >
                                {new Intl.NumberFormat("en-EN").format(
                                  TotalPendientes
                                )}
                              </span>
                            </CardTitle>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="12" xs="12" style={{ textAlign: "center" }}>
                            <p
                              style={{
                                fontSize: "15px",
                                color: "#d4ac0d",
                                fontWeight: "bold",
                              }}
                            >
                              Expedientes Por Validar
                            </p>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>

                  <Col lg="3" md="3" sm="12">
                    <Card className="card-stats">
                      <CardBody>
                        <Row>
                          <Col md="12" xs="12" style={{ textAlign: "center" }}>
                            <CardTitle>
                              <span
                                style={{
                                  color: "#229954",
                                  paddingRight: "2rem",
                                }}
                              >
                                <i
                                  className="fa fa-thumbs-o-up fa-3x"
                                  aria-hidden="true"
                                ></i>
                              </span>

                              <span
                                style={{
                                  fontSize: "25px",
                                  fontWeight: "bold",
                                }}
                              >
                                {new Intl.NumberFormat("en-EN").format(
                                  TotalValidados
                                )}
                              </span>
                            </CardTitle>
                          </Col>
                        </Row>
                        <Row>
                          <Col md="12" xs="12" style={{ textAlign: "center" }}>
                            <p
                              style={{
                                fontSize: "15px",
                                color: "#229954",
                                fontWeight: "bold",
                              }}
                            >
                              Expedientes Validados
                            </p>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <Card>
                      <CardHeader>
                        <Row>
                          <Col md="8">
                            <CardTitle tag="h6">
                              Listado de Solicitudes
                            </CardTitle>
                          </Col>
                        </Row>
                      </CardHeader>
                      <CardBody>
                        {loadingCedulaList && (
                          <CircleSpinner
                            className="text-center"
                            size={30}
                            color="#686769"
                            loading={loadingCedulaList}
                          />
                        )}
                        <Col lg="12" md="12">
                          {menuLoaded && (
                            <ReactTable
                              size="sm"
                              data={dataCedulaList}
                              noDataText="No Existen Datos a Mostrar!"
                              manual
                              columns={columns}
                              className="-striped -highlight primary-pagination"
                              loading={loadingCedulaList}
                              showPagination={true}
                              showPaginationBottom={true}
                              showTotalPages={true}
                              showPageJump={false}
                              canNextFromData={true}
                              defaultPageSize={pageOptions.pageSize}
                              total={total}
                              page={pageOptions.page}
                              pages={Math.floor(total / pageOptions.pageSize)}
                              previousText="Anterior"
                              nextText="Siguiente"
                              ofText="de"
                              pageSizeOptions={[10, 20, 25, 50, 100]}
                              PaginationComponent={Pagination}
                              onFetchData={fetchData}
                            />
                          )}
                        </Col>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                <PluginFilterPrevalidacion
                  sendFiltro={setFilter}
                  clasess="dropdowns"
                />
              </>
            ) : (
              isSolicitud && (
                <ListadoExpedientes
                  data={solicitud}
                  backSolicitud={() => {
                    setIsSolicitud(!isSolicitud);
                  }}
                />
              )
            )}
          </div>
          <Footer fluid />
        </div>
      </div>
    </>
  );
};
export default Prevalidacion;
