import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";
import EjecutarApi, { uploadFiles } from "../Consultas/Consultas";
import {
  FormatedSolicitudFormC,
  SolicitudFormC,
} from "../data/interfaces/Cedula";
import { getVars } from "../GlobalStorage";
import {
  isEmptyOrInvalidString,
  isEmptyOrNullObject,
  isNullOrUndefined,
} from "../utils/emptyValidations";
import { SolicitudValidatorCalentadorN } from "../data/validators/Cedula";

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardSubtitle,
  CardText,
  CardTitle,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Spinner,
} from "reactstrap";
import Notifications from "react-notify-toast";
import DateTimeComponent from "../components/DateTimeComponent";
import swal from "sweetalert";
import ListadoArchivos from "../components/Archivos/ListadoArchivos";

const SolicitudCalentadores = (props) => {
  const { data, backSolicitud } = props;
  const history = useHistory();
  const Datos = data ? data : null;
  const [userData, setUserData] = useState();
  const token = getVars("Token").token;
  const [menu, setMenu] = useState({
    Ver: 0,
    Agregar: 0,
    Editar: 0,
    Eliminar: 0,
    Seguimiento: 0,
    Exportar: 0,
    Imprimir: 0,
    ViewAll: 0,
    idTipoUser: 0,
  });
  const [form, setForm] = useState(SolicitudFormC);
  const [loadingCatalogs, setLoadingCatalogs] = useState(true);
  const [loadingForm, setIsLoadingForm] = useState(true);
  const [isForEdit, setIsForEdit] = useState(false);
  const [catalogs, setCatalogs] = useState({
    entidades: [],
    archivos_clasificacion: [],
    cat_parentesco_jefe_hogar: [],
    cat_parentesco_tutor: [],
    cat_situacion_actual: [],
    cat_estado_civil: [],
    municipios: [],
    localidades: [],
  });

  const [errors, setErrors] = useState({
    error: false,
    seccion: -1,
    message: "",
  });
  const [errorsIne, setErrorsIne] = useState({
    error: false,
    seccion: -1,
    message: "",
  });
  const [datosTarjeta, setDatostarjeta] = useState({});
  const [loadingFolio, setLoadingFolio] = useState(false);
  const [loadingCURP, setLoadingCURP] = useState(false);
  const [formLoaded, setFormLoaded] = useState(false);
  const [MunLoaded, setMunLoaded] = useState(false);
  const [LocLoaded, setLocLoaded] = useState(false);

  useEffect(() => {
    setUserData(getVars("Token"));
  }, []);

  useEffect(() => {
    if (isNullOrUndefined(userData)) return;
    const menus = userData.menu;
    const currentMenu = menus.find((menu) => menu.idMenu === 14);
    if (isNullOrUndefined(currentMenu) || currentMenu.Ver < 1) {
      swal({
        title: "Atención",
        text: "No tiene permisos para este módulo, contacte al administrador",
        icon: "warning",
      });
      history.push("/not-found");
      return;
    }
    setMenu({
      Ver: currentMenu.Ver,
      Agregar: currentMenu.Agregar,
      Editar: currentMenu.Editar,
      Eliminar: currentMenu.Eliminar,
      Seguimiento: currentMenu.Seguimiento,
      Exportar: currentMenu.Exportar,
      Imprimir: currentMenu.Imprimir,
      ViewAll: currentMenu.ViewAll,
      idTipoUser: userData.user.idTipoUser.id,
    });
  }, [userData]);

  useEffect(() => {
    EjecutarApi({}, "getCatalogsCedula", token, "GET")
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.success && res.results) {
          let cat = res.data;
          cat.localidades = [];
          setCatalogs(cat);
        } else {
          swal({
            title: "Ha ocurrido un error",
            text: res.errors,
            icon: "warning",
          });
        }
        setLoadingCatalogs(false);
      })
      .catch((error) => {
        setLoadingCatalogs(false);
        swal({
          title: "Ha ocurrido un error",
          text: error.errors,
          icon: "warning",
        });
      });
    setMunLoaded(true);
    setLocLoaded(true);
  }, [token]);

  useEffect(() => {
    setIsLoadingForm(true);
    let formData = { ...form };
    formData.ListaParaEnviar = formData.ListaParaEnviar
      ? formData.ListaParaEnviar
      : 0;
    formData.Files = [];
    formData.ArchivosClasificacion = [];

    if (Datos != null) {
      EjecutarApi({}, `calentadores/getById/${Datos.idCedula}`, token, "GET")
        .then(function (res) {
          return res;
        })
        .then((res) => {
          if (res.success && res.results) {
            setIsForEdit(true);
            const newForm = FormatedSolicitudFormC(res.data);

            setForm({
              ...newForm,
            });
            setIsLoadingForm(false);
            setFormLoaded(true);
          } else {
            swal({
              title: "Ha ocurrido un error",
              text: res.errors,
              icon: "warning",
            });
            backSolicitud();
          }
          setIsLoadingForm(false);
          setFormLoaded(true);
        })
        .catch((error) => {
          setIsLoadingForm(false);
          swal({
            title: "Ha ocurrido un error",
            text: error.error,
            icon: "warning",
          });
          backSolicitud();
        });
    } else {
      setTimeout(() => {
        setForm(formData);
        setIsLoadingForm(false);
        setFormLoaded(true);
      }, 4000);
    }
  }, [Datos]);

  useEffect(() => {
    if (form.idEntidadVive === 12) {
      let formCatalogs = {};
      if (catalogs.municipios.length > 0) {
        const MunicipioVive = catalogs.municipios.find(
          (x) => x.label === form.MunicipioVive
        );
        if (!isNullOrUndefined(MunicipioVive)) {
          formCatalogs.MunicipioVive = MunicipioVive.value;
        }
      }
      if (catalogs.localidades.length > 0) {
        const LocalidadVive = catalogs.localidades.find(
          (x) => x.label === form.LocalidadVive
        );
        if (!isNullOrUndefined(LocalidadVive)) {
          formCatalogs.LocalidadVive = LocalidadVive.value;
        }
      }
      setForm({
        ...form,
        ...formCatalogs,
      });
    }
  }, [
    catalogs.municipios,
    catalogs.localidades,
    loadingForm,
    formLoaded,
    Datos,
  ]);

  const getLocalities = (municipio) => {
    EjecutarApi(
      {},
      `cedula/getLocalidadesByMunicipio/${municipio}`,
      token,
      "GET"
    )
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.success && res.results) {
          setCatalogs({
            ...catalogs,
            localidades: res.data,
          });
        } else {
          swal({
            title: "Ha ocurrido un error",
            text: res.errors,
            icon: "warning",
          });
        }
      })
      .catch((error) => {
        swal({
          title: "Ha ocurrido un error",
          text: error.errors,
          icon: "warning",
        });
      });
  };

  const validateData = async (type, value) => {
    var urlencoded = new URLSearchParams();
    let folio = type === "Folio" ? value : "";
    let curp = type === "CURP" ? value.toUpperCase() : "";
    urlencoded.append("token", "dfee39bd-acd2-4c95-514d-74384d530496");
    urlencoded.append("Folio", folio);
    urlencoded.append("CURP", curp);

    var requestInfo = {
      method: "POST",
      headers: new Headers({
        "Content-Type": "application/x-www-form-urlencoded",
      }),
      body: urlencoded,
      redirect: "follow",
    };
    setLoadingCURP(true);
    setLoadingFolio(true);
    fetch(
      "https://peb.guanajuato.gob.mx/Tarjeta/api/APIBusquedaDatos/SETBusquedaCLUB",
      requestInfo
    )
      .then((response) => {
        return response.json();
      })
      .then((dataRS) => {
        if (dataRS.result == 0) {
          if (type === "CURP") {
            validateCurp(value);
          } else {
            setLoadingCURP(false);
            swal({
              title: "¡Atención!",
              text: "No se encuentra la persona con los datos proporcionados",
              icon: "warning",
            });
          }
          setLoadingFolio(false);
        } else {
          setLoadingFolio(false);
          setLoadingCURP(false);
          setDatostarjeta(dataRS.data);
        }
      })
      .catch((error) => {
        setLoadingFolio(false);
        setLoadingCURP(false);
        setLoadingCatalogs(false);
        swal({
          title: "Ha ocurrido un error",
          text: error.error,
          icon: "warning",
        });
      });
  };

  const validateCurp = (CURP) => {
    if (CURP.length < 18) {
      return;
    }
    fetch(
      `https://seguimiento.guanajuato.gob.mx/apiinformacionsocial/api/renapo/porcurp/pL@t_1n|Run$28/${CURP}/7`
    )
      .then((res) => res.json())
      .then((dataRS) => {
        if (dataRS.Mensaje === "OK") {
          const response = dataRS.Resultado;
          const fechaNacimiento = moment(response.fechNac, "DD/MM/YYYY").format(
            "YYYY-MM-DD"
          );
          let data = {
            Nombre: response.nombres,
            Paterno: response.apellido1,
            Materno: response.apellido2,
            Sexo: response.sexo,
            FechaNacimiento: fechaNacimiento,
            Edad: getAgeByDate(fechaNacimiento),
            idEntidadNacimiento: getEstadoNacimiento(response.cveEntidadNac),
            FolioTarjetaImpulso: "",
            TarjetaImpulso: "",
            ContactoTarjetaImpulso: 0,
          };
          setForm({
            ...form,
            ...data,
          });
          setDatostarjeta({});
        } else {
          swal({
            title: "Ha ocurrido un error",
            text: dataRS.Mensaje,
            icon: "warning",
          });
        }
        setLoadingCURP(false);
      })
      .catch((error) => {
        setLoadingCURP(false);
        setLoadingCatalogs(false);
        swal({
          title: "Ha ocurrido un error",
          text: error.error,
          icon: "warning",
        });
      });
  };

  useEffect(() => {
    if (!isEmptyOrNullObject(datosTarjeta)) {
      let data = {
        CURP: datosTarjeta[0].CURP,
        Sexo: datosTarjeta[0].Sexo,
        Edad: getAgeByDate(datosTarjeta[0].FechaNacimiento),
        FechaNacimiento: moment(
          datosTarjeta[0].FechaNacimiento,
          "DD/MM/YYYY"
        ).format("YYYY-MM-DD"),
        FolioTarjetaImpulso: datosTarjeta[0].Folio ? datosTarjeta[0].Folio : "",
        Nombre: datosTarjeta[0].Nombres,
        Paterno: datosTarjeta[0].PrimerApellido,
        Materno: datosTarjeta[0].SegundoApellido,
      };
      if (
        !isEmptyOrInvalidString(datosTarjeta[0].Folio) ||
        !isEmptyOrInvalidString(datosTarjeta[0].CURP)
      ) {
        data.TarjetaImpulso = 3;
        data.ContactoTarjetaImpulso = 0;
      }
      data.idEntidadNacimiento = getEstadoNacimiento(
        datosTarjeta[0].EntidadNacimiento
      );
      setForm({
        ...form,
        ...data,
      });
    }
  }, [datosTarjeta]);

  useEffect(() => {
    if (!isNaN(parseInt(form.MunicipioVive))) {
      getLocalities(form.MunicipioVive);
    }
  }, [form.MunicipioVive]);

  const getAgeByDate = (fechaNacimiento) => {
    if (fechaNacimiento) {
      let anio = fechaNacimiento.split("/");
      let posicion = 2;
      if (anio.length < 2) {
        anio = fechaNacimiento.split("-");
        posicion = 0;
      }
      return moment().diff(anio[posicion], "years");
    } else return 0;
  };

  const getEstadoNacimiento = (estadoCurp) => {
    const found = catalogs.entidades.find(
      (element) => element.Clave_CURP === estadoCurp
    );
    if (!isNullOrUndefined(found)) {
      return found.value;
    }
    return "";
  };

  const handleChangeArchivos = (files) => {
    setForm({
      ...form,
      Files: files,
    });
  };

  const handleChangeClasificacion = (arrayClasificacion) => {
    setForm({
      ...form,
      ArchivosClasificacion: arrayClasificacion,
    });
  };

  const handleDeleteFile = (files, arrayClasificacion) => {
    setForm({
      ...form,
      Files: files,
      ArchivosClasificacion: arrayClasificacion,
    });
  };

  const handleChangeEstadoVive = (value) => {
    setForm({
      ...form,
      idEntidadVive: value,
      LocalidadVive: "",
      MunicipioVive: "",
    });
  };

  const saveSolicitud = (redirect = false) => {
    const hasErrors = SolicitudValidatorCalentadorN(form);
    setErrors(hasErrors);
    if (!hasErrors.error) {
      setIsLoadingForm(true);
      let url;
      if (isForEdit) {
        url = "updateSolicitudCedulaCalentador";
      } else {
        url = "createSolicitudCedulaCalentador";
        form.Formato = 2;
      }
      let formData = { ...form };
      let oldFiles = [];
      let newFiles = [];
      let oldClasification = [];
      let newClasification = [];
      let flag = false;

      formData.Files.forEach((file, index) => {
        if (isNullOrUndefined(file.id)) {
          newFiles.push(file);
          if (isNullOrUndefined(formData.ArchivosClasificacion[index])) {
            flag = true;
            return;
          }
          newClasification.push(formData.ArchivosClasificacion[index]);
        } else {
          oldFiles.push(file);
          if (isNullOrUndefined(formData.ArchivosClasificacion[index])) {
            flag = true;
            return;
          }
          oldClasification.push(formData.ArchivosClasificacion[index]);
        }
      });
      if (flag) {
        swal({
          title: "Alerta",
          text: "Debe seleccionar una clasificación para cada archivo",
          icon: "warning",
        });
        return;
      }
      formData.OldFiles = oldFiles;
      formData.OldClasificacion = oldClasification;
      formData.NewFiles = newFiles;
      formData.NewClasificacion = newClasification;
      formData.Files = [];
      formData.ArchivosClasificacion = [];
      if (formData.idEntidadVive !== "" && formData.idEntidadVive === 12) {
        formData.MunicipioVive =
          formData.MunicipioVive !== "" && formData.MunicipioVive != null
            ? catalogs.municipios.find(
                (x) => x.value === parseInt(formData.MunicipioVive)
              ).label
            : "";
        formData.LocalidadVive =
          formData.LocalidadVive !== "" && formData.LocalidadVive != null
            ? catalogs.localidades.find(
                (x) => x.value === parseInt(formData.LocalidadVive)
              ).label
            : "";
      }
      formData.NoIntVive =
        formData.NoIntVive === "" ? "S/N" : formData.NoIntVive;
      if (redirect) {
        let formDataFiles = [];
        formDataFiles = formData.OldClasificacion.concat(
          formData.NewClasificacion
        );

        if (formDataFiles.length > 0) {
          const formFiles = formDataFiles.map((element) => {
            return parseInt(element);
          });
          formDataFiles = formFiles;
          let archivo = formDataFiles.includes(3);
          if (!archivo) {
            swal({
              title: "Alerta",
              icon: "warning",
              text: "Falta la identifiación oficial para completar el expediente",
            });
            setIsLoadingForm(false);
            return;
          }

          archivo = formDataFiles.includes(4);
          if (!archivo) {
            swal({
              title: "Alerta",
              icon: "warning",
              text: "Falta el CURP para completar el expediente",
            });
            setIsLoadingForm(false);
            return;
          }

          archivo = formDataFiles.includes(6);
          if (!archivo) {
            swal({
              title: "Alerta",
              icon: "warning",
              text: "Falta el comprobante de domicilio para completar el expediente",
            });
            setIsLoadingForm(false);
            return;
          }

          archivo = formDataFiles.includes(5);
          if (!archivo) {
            swal({
              title: "Alerta",
              icon: "warning",
              text: "Falta el acuse para completar el expediente",
            });
            setIsLoadingForm(false);
            return;
          }

          archivo = formDataFiles.includes(12);
          if (!archivo) {
            swal({
              title: "Alerta",
              icon: "warning",
              text: "Faltan las evidencias PDF para completar el expediente",
            });
            setIsLoadingForm(false);
            return;
          }

          archivo = formDataFiles.includes(10);
          if (!archivo) {
            swal({
              title: "Alerta",
              icon: "warning",
              text: "Faltan el formato de información del programa para completar el expediente",
            });
            setIsLoadingForm(false);
            return;
          }
        } else {
          swal({
            title: "Alerta",
            text: "Debe cargar todos los archivos para validar la solicitud",
            icon: "warning",
          });
          setIsLoadingForm(false);
          return;
        }

        formData.ListaParaEnviar = 1;
        formData.idEstatus = 9;
      }
      uploadFiles({ data: formData, api: url, token })
        .then(function (res) {
          return res;
        })
        .then((res) => {
          if (res.success && res.results) {
            swal({
              title: "Guardado con éxito",
              text: res.message,
              icon: "success",
            });
            setIsLoadingForm(false);
            backSolicitud();
          } else {
            swal({
              title: "Ha ocurrido un error",
              text: res.errors,
              icon: "warning",
            });
          }
          setIsLoadingForm(false);
        })
        .catch((error) => {
          setIsLoadingForm(false);
          swal({
            title: "Ha ocurrido un error",
            text: error.message,
            icon: "warning",
          });
        });
    }
  };

  const deleteAlert = () => {
    swal({
      title: "¡Atención!",
      text: "¿Está seguro de eliminar la solicitud?",
      dangerMode: true,
      icon: "warning",
      buttons: ["Cancelar", true],
    }).then((response) => {
      if (response) {
        deleteSolicitud();
      }
    });
  };

  const deleteSolicitud = () => {
    setIsLoadingForm(true);
    EjecutarApi({ id: form.id }, "deleteSolicitudCedulaCalentador", token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.success && res.results) {
          swal({
            title: "¡Eliminada con éxito!",
            icon: "success",
          });
          backSolicitud();
        } else {
          swal({
            title: "Ha ocurrido un error",
            text: res.message,
            icon: "warning",
          });
        }
        setIsLoadingForm(false);
      })
      .catch((error) => {
        setIsLoadingForm(false);
        swal({
          title: "Ha ocurrido un error",
          text: error.message,
          icon: "warning",
        });
      });
  };

  const handleChangeMunicipio = (municipio) => {
    if (municipio !== null && municipio !== "") {
      getLocalities(municipio);
      setForm({
        ...form,
        MunicipioVive: municipio,
      });
    } else {
      setForm({
        ...form,
        MunicipioVive: "",
        LocalidadVive: "",
      });
    }
  };

  useEffect(() => {
    if (!isNaN(parseInt(form.FechaINE))) {
      if (form.FechaINE.length === 4) {
        let fecha_actual = new Date();
        let aa_actual = fecha_actual.getFullYear();
        if (form.FechaINE !== null && form.FechaINE !== "") {
          if (parseInt(form.FechaINE) < parseInt(aa_actual)) {
            const errors = {
              error: true,
              seccion: 1,
              message: "La vigencia de la Identificación Oficial no es válida",
            };
            setErrorsIne(errors);
          } else {
            const errors = { error: false, seccion: -1, message: "" };
            setErrorsIne(errors);
          }
        }
      } else {
        if (form.FechaINE.length > 0) {
          const errors = {
            error: true,
            seccion: 1,
            message: "La vigencia de la Identificación Oficial no es válida",
          };
          setErrorsIne(errors);
        }
      }
    }
  }, [form.FechaINE]);

  return (
    <>
      <Notifications />
      <div>
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <Button color="success" onClick={backSolicitud}>
                  Regresar
                </Button>
              </CardHeader>
              <CardBody>
                <CardTitle tag="h5" style={{ textAlign: "center" }}>
                  {isForEdit
                    ? form.ListaParaEnviar > 0
                      ? "Solicitud Validada"
                      : "Editar solicitud"
                    : "Nueva Solicitud"}
                  {loadingForm && <Spinner />}
                </CardTitle>
                {formLoaded && MunLoaded && LocLoaded && (
                  <Form>
                    <Col sm="12" style={{ textAlign: "center", color: "red" }}>
                      {form.ListaParaEnviar > 0 && (
                        <CardText>
                          La solicitud ya se encuentra validada, no se puede
                          editar
                        </CardText>
                      )}
                    </Col>
                    <hr
                      style={{
                        backgroundColor:
                          errors.error && errors.seccion === 0
                            ? "red"
                            : "#E8E8E8",
                      }}
                    />
                    <Row>
                      <Col sm="3" xs="12">
                        <FormGroup>
                          <Label>
                            Fecha solicitud
                            <span className="text-danger">*</span>
                          </Label>
                          <DateTimeComponent
                            id="FechaSolicitud"
                            name="FechaSolicitud"
                            dateFormat="YYYY-MM-DD"
                            maxDate={moment()
                              .add(1, "days")
                              .format("YYYY-MM-DD")}
                            minDate={new Date("2020-01-01")}
                            timeFormat={false}
                            closeOnSelect={true}
                            value={form.FechaSolicitud}
                            onChange={(e) =>
                              setForm({
                                ...form,
                                FechaSolicitud: moment(e._d).format(
                                  "YYYY-MM-DD"
                                ),
                              })
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="3" xs="12">
                        <FormGroup>
                          <Label>Folio tarjeta impulso</Label>
                          <Input
                            id="folio"
                            type="text"
                            maxLength={10}
                            value={form.FolioTarjetaImpulso}
                            onChange={(event) =>
                              setForm({
                                ...form,
                                FolioTarjetaImpulso: event.target.value,
                              })
                            }
                          />
                          {form.ListaParaEnviar < 1 && (
                            <Button
                              size="sm"
                              color="primary"
                              disabled={form.FolioTarjetaImpulso.length === 0}
                              onClick={() =>
                                validateData("Folio", form.FolioTarjetaImpulso)
                              }
                            >
                              Validar {loadingFolio && <Spinner size="sm" />}
                            </Button>
                          )}
                        </FormGroup>
                      </Col>
                      <Col sm="3" xs="12">
                        <FormGroup>
                          <Label>
                            CURP<span className="text-danger">*</span>
                          </Label>
                          <Input
                            name="CURP"
                            type="text"
                            value={form.CURP}
                            maxLength={18}
                            style={{ textTransform: "uppercase" }}
                            onChange={(e) =>
                              setForm({
                                ...form,
                                CURP: e.target.value.toUpperCase(),
                              })
                            }
                          />
                          {form.ListaParaEnviar < 1 && (
                            <Button
                              size="sm"
                              color="primary"
                              disabled={form.CURP.length != 18}
                              onClick={() => validateData("CURP", form.CURP)}
                            >
                              Validar {loadingCURP && <Spinner size="sm" />}
                            </Button>
                          )}
                        </FormGroup>
                      </Col>
                      <Col sm="3" xs="12">
                        <FormGroup>
                          <Label>
                            Folio de solicitud
                            <span className="text-danger">*</span>
                          </Label>
                          <Input
                            name="FolioSolicitud"
                            type="text"
                            value={form.FolioSolicitud}
                            disabled={true}
                            style={{ textTransform: "uppercase" }}
                          />
                        </FormGroup>
                      </Col>
                      {errors.error && errors.seccion === 0 && (
                        <Col xs="12">
                          <CardText
                            style={{ color: "red", textAlign: "center" }}
                          >
                            {errors.message}
                          </CardText>
                        </Col>
                      )}
                    </Row>
                    <hr
                      style={{
                        backgroundColor:
                          (errors.error && errors.seccion === 1) ||
                          (errorsIne.error && errorsIne.seccion === 1)
                            ? "red"
                            : "#E8E8E8",
                      }}
                    />
                    <CardSubtitle
                      className="mb-2 text-muted"
                      tag="h6"
                      style={{ textAlign: "center" }}
                    >
                      I. DATOS DEL SOLICITANTE
                    </CardSubtitle>
                    <Row>
                      <Col sm="4" xs="12">
                        <FormGroup>
                          <Label>
                            Nombre(s) <span className="text-danger">*</span>
                          </Label>
                          <Input
                            id="nombre"
                            type="text"
                            value={form.Nombre}
                            onChange={(event) =>
                              setForm({
                                ...form,
                                Nombre: event.target.value
                                  .toUpperCase()
                                  .replace(/\d/, ""),
                              })
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="4" xs="12">
                        <FormGroup>
                          <Label>
                            Primer apellido
                            <span className="text-danger">*</span>
                          </Label>
                          <Input
                            id="paterno"
                            type="text"
                            value={form.Paterno}
                            onChange={(event) =>
                              setForm({
                                ...form,
                                Paterno: event.target.value
                                  .toUpperCase()
                                  .replace(/\d/, ""),
                              })
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="4" xs="12">
                        <FormGroup>
                          <Label>
                            Segundo apellido
                            {/* <span className="text-danger">*</span> */}
                          </Label>
                          <Input
                            id="nombre"
                            type="text"
                            value={form.Materno}
                            onChange={(event) =>
                              setForm({
                                ...form,
                                Materno: event.target.value
                                  .toUpperCase()
                                  .replace(/\d/, ""),
                              })
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="4" xs="12">
                        <FormGroup>
                          <Label check>
                            Sexo<span className="text-danger">*</span>
                          </Label>
                          <Row>
                            <Col style={{ marginLeft: "10%" }}>
                              <FormGroup check>
                                <Input
                                  name="Hombre"
                                  type="radio"
                                  checked={
                                    form.Sexo === "H" || form.Sexo === ""
                                  }
                                  onChange={() =>
                                    setForm({
                                      ...form,
                                      Sexo: "H",
                                    })
                                  }
                                />{" "}
                                <Label check>Hombre</Label>
                              </FormGroup>
                            </Col>
                            <Col>
                              <FormGroup check>
                                <Input
                                  name="Mujer"
                                  type="radio"
                                  checked={form.Sexo === "M"}
                                  onChange={() =>
                                    setForm({
                                      ...form,
                                      Sexo: "M",
                                    })
                                  }
                                />{" "}
                                <Label check>Mujer</Label>
                              </FormGroup>
                            </Col>
                          </Row>
                        </FormGroup>
                      </Col>
                      <Col sm="4" xs="12">
                        <FormGroup>
                          <Label>
                            Vigencia INE:
                            <span className="text-danger">*</span>
                          </Label>
                          <Input
                            name="FechaINE"
                            type="text"
                            pattern="[0-9]*"
                            maxLength={4}
                            value={form.FechaINE}
                            onChange={(e) => {
                              setForm({
                                ...form,
                                FechaINE: e.target.value.replace(/\D/, ""),
                              });
                            }}
                          />
                        </FormGroup>
                      </Col>
                      {errors.error && errors.seccion === 1 ? (
                        <Col xs="12">
                          <CardText
                            style={{ color: "red", textAlign: "center" }}
                          >
                            {errors.message}
                          </CardText>
                        </Col>
                      ) : errorsIne.error && errorsIne.seccion === 1 ? (
                        <Col xs="12">
                          <CardText
                            style={{ color: "red", textAlign: "center" }}
                          >
                            {errorsIne.message}
                          </CardText>
                        </Col>
                      ) : (
                        <></>
                      )}
                    </Row>
                    <hr
                      style={{
                        backgroundColor:
                          errors.error && errors.seccion === 5
                            ? "red"
                            : "#E8E8E8",
                      }}
                    />
                    <CardSubtitle
                      className="mb-2 text-muted"
                      tag="h6"
                      style={{ textAlign: "center" }}
                    >
                      V. DATOS DE DOMICILIO
                    </CardSubtitle>
                    <Row>
                      <Col sm="6" xs="12">
                        <FormGroup>
                          <Label>
                            Calle: <span className="text-danger">*</span>
                          </Label>
                          <Input
                            name="CalleVive"
                            type="text"
                            value={form.CalleVive}
                            onChange={(e) =>
                              setForm({
                                ...form,
                                CalleVive: e.target.value.toUpperCase(),
                              })
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="2" xs="12">
                        <FormGroup>
                          <Label>
                            No. Exterior: <span className="text-danger">*</span>
                          </Label>
                          <Input
                            name="NoExtVive"
                            type="text"
                            value={form.NoExtVive}
                            onChange={(e) =>
                              setForm({
                                ...form,
                                NoExtVive: e.target.value.toUpperCase(),
                              })
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="2" xs="12">
                        <FormGroup>
                          <Label>No. Interior:</Label>
                          <Input
                            name="NoIntVive"
                            type="text"
                            value={form.NoIntVive}
                            onChange={(e) =>
                              setForm({
                                ...form,
                                NoIntVive: e.target.value,
                              })
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="2" xs="12">
                        <FormGroup>
                          <Label>
                            Código Postal:<span className="text-danger">*</span>
                          </Label>
                          <Input
                            name="CPVive"
                            type="text"
                            pattern="[0-9]*"
                            maxLength={5}
                            value={form.CPVive}
                            onChange={(e) =>
                              setForm({
                                ...form,
                                CPVive: e.target.value.replace(/\D/, ""),
                              })
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="6" xs="12">
                        <FormGroup>
                          <Label>
                            Colonia: <span className="text-danger">*</span>
                          </Label>
                          <Input
                            name="ColoniaVive"
                            type="text"
                            value={form.ColoniaVive}
                            onChange={(e) =>
                              setForm({
                                ...form,
                                ColoniaVive: e.target.value.toUpperCase(),
                              })
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="6" xs="12">
                        {!loadingForm && (
                          <FormGroup>
                            <Label>Municipio:</Label>
                            <span className="text-danger">*</span>
                            {loadingCatalogs && <Spinner size="sm" />}
                            <Input
                              name="MunicipioVive"
                              type="select"
                              disabled={loadingCatalogs}
                              value={form.MunicipioVive}
                              onChange={(e) =>
                                handleChangeMunicipio(e.target.value)
                              }
                            >
                              <option value={""}></option>
                              {catalogs.municipios.map((element) => (
                                <option
                                  key={element.value}
                                  value={element.value}
                                >
                                  {element.label}
                                </option>
                              ))}
                            </Input>
                          </FormGroup>
                        )}
                      </Col>
                      <Col sm="6" xs="12">
                        <FormGroup>
                          <Label>Localidad:</Label>
                          <span className="text-danger">*</span>
                          {loadingCatalogs && <Spinner size="sm" />}
                          <Input
                            name="MunicipioVive"
                            type="select"
                            disabled={
                              loadingCatalogs || form.MunicipioVive === ""
                            }
                            value={form.LocalidadVive}
                            onChange={(e) =>
                              setForm({
                                ...form,
                                LocalidadVive: e.target.value,
                              })
                            }
                          >
                            <option value={""}></option>
                            {catalogs.localidades.map((element) => (
                              <option key={element.value} value={element.value}>
                                {element.label}
                              </option>
                            ))}
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col sm="6" xs="12">
                        <FormGroup>
                          <Label>
                            Estado: <span className="text-danger">*</span>
                          </Label>
                          <Input
                            name="idEntidadVive"
                            type="select"
                            value={form.idEntidadVive}
                            disabled={true}
                            onChange={(e) =>
                              handleChangeEstadoVive(e.target.value)
                            }
                          >
                            <option value={""}></option>
                            {catalogs.entidades.map((element) => (
                              <option key={element.value} value={element.value}>
                                {element.label}
                              </option>
                            ))}
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col sm="12" xs="12">
                        <FormGroup>
                          <Label>
                            Referencias: <span className="text-danger">*</span>
                          </Label>
                          <Input
                            name="Referencias"
                            type="textarea"
                            value={form.Referencias}
                            onChange={(e) =>
                              setForm({
                                ...form,
                                Referencias: e.target.value.toUpperCase(),
                              })
                            }
                          />
                        </FormGroup>
                      </Col>
                      {errors.error && errors.seccion === 5 && (
                        <Col xs="12">
                          <CardText
                            style={{ color: "red", textAlign: "center" }}
                          >
                            {errors.message}
                          </CardText>
                        </Col>
                      )}
                    </Row>
                    <hr
                      style={{
                        backgroundColor:
                          errors.error && errors.seccion === 2
                            ? "red"
                            : "#E8E8E8",
                      }}
                    />
                    <CardSubtitle
                      className="mb-2 text-muted"
                      tag="h6"
                      style={{ textAlign: "center" }}
                    >
                      II. DATOS DE CONTACTO
                    </CardSubtitle>
                    <Row>
                      <Col sm="4" xs="12">
                        <FormGroup>
                          <Label>
                            Teléfono celular:
                            <span className="text-danger">*</span>
                          </Label>
                          <Input
                            name="telefono"
                            type="text"
                            pattern="[0-9]*"
                            maxLength={10}
                            value={form.Celular}
                            onChange={(e) =>
                              setForm({
                                ...form,
                                Celular: e.target.value.replace(/\D/, ""),
                              })
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="4" xs="12">
                        <FormGroup>
                          <Label>Teléfono fijo:</Label>
                          <Input
                            name="telefono"
                            type="text"
                            pattern="[0-9]*"
                            maxLength={10}
                            value={form.Telefono}
                            onChange={(e) =>
                              setForm({
                                ...form,
                                Telefono: e.target.value.replace(/\D/, ""),
                              })
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="4" xs="12">
                        <FormGroup>
                          <Label>
                            Correo electrónico:
                            {/* <span className="text-danger">*</span> */}
                          </Label>
                          <Input
                            name="telefono"
                            type="text"
                            value={form.Correo}
                            onChange={(e) =>
                              setForm({
                                ...form,
                                Correo: e.target.value,
                              })
                            }
                          />
                        </FormGroup>
                      </Col>
                      {errors.error && errors.seccion === 2 && (
                        <Col xs="12">
                          <CardText
                            style={{ color: "red", textAlign: "center" }}
                          >
                            {errors.message}
                          </CardText>
                        </Col>
                      )}
                    </Row>
                    <hr
                      style={{
                        backgroundColor:
                          errors.error && errors.seccion === 3
                            ? "red"
                            : "#E8E8E8",
                      }}
                    />
                    <CardSubtitle
                      className="mb-2 text-muted"
                      tag="h6"
                      style={{ textAlign: "center" }}
                    >
                      III. DATOS DEL ACOMPAÑANTE (en caso de aplicar)
                    </CardSubtitle>
                    <Row>
                      <Col sm="6" xs="12">
                        <FormGroup>
                          <Label>
                            Parentesco con la persona solicitante:
                            {/* {form.Edad < 18 && (
                                  <span className="text-danger">*</span>
                                )} */}
                          </Label>
                          <Input
                            name="idParentescoTutor"
                            type="select"
                            value={form.idParentescoTutor}
                            onChange={(e) =>
                              setForm({
                                ...form,
                                idParentescoTutor: e.target.value,
                              })
                            }
                          >
                            <option value={""}></option>
                            {catalogs.cat_parentesco_tutor.map((element) => (
                              <option key={element.value} value={element.value}>
                                {element.label}
                              </option>
                            ))}
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col sm="6" xs="12">
                        <FormGroup>
                          <Label>
                            Nombre(s):
                            {/* {form.Edad < 18 && (
                                  <span className="text-danger">*</span>
                                )} */}
                          </Label>
                          <Input
                            name="NombreTutor"
                            type="text"
                            value={form.NombreTutor}
                            onChange={(e) =>
                              setForm({
                                ...form,
                                NombreTutor: e.target.value
                                  .toUpperCase()
                                  .replace(/\d/, ""),
                              })
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="6" xs="12">
                        <FormGroup>
                          <Label>
                            Primer apellido:
                            {/* {form.Edad < 18 && (
                                  <span className="text-danger">*</span>
                                )} */}
                          </Label>
                          <Input
                            name="PaternoTutor"
                            type="text"
                            value={form.PaternoTutor}
                            onChange={(e) =>
                              setForm({
                                ...form,
                                PaternoTutor: e.target.value
                                  .toUpperCase()
                                  .replace(/\d/, ""),
                              })
                            }
                          />
                        </FormGroup>
                      </Col>
                      <Col sm="6" xs="12">
                        <FormGroup>
                          <Label>
                            Segundo apellido:
                            {/* {form.Edad < 18 && (
                                  <span className="text-danger">*</span>
                                )} */}
                          </Label>
                          <Input
                            name="MaternoTutor"
                            type="text"
                            value={form.MaternoTutor}
                            onChange={(e) =>
                              setForm({
                                ...form,
                                MaternoTutor: e.target.value
                                  .toUpperCase()
                                  .replace(/\d/, ""),
                              })
                            }
                          />
                        </FormGroup>
                      </Col>
                      {errors.error && errors.seccion === 3 && (
                        <Col xs="12">
                          <CardText
                            style={{ color: "red", textAlign: "center" }}
                          >
                            {errors.message}
                          </CardText>
                        </Col>
                      )}
                    </Row>
                    <hr
                      style={{
                        backgroundColor:
                          errors.error && errors.seccion === 6
                            ? "red"
                            : "#E8E8E8",
                      }}
                    />
                    <CardSubtitle
                      className="mb-2 text-muted"
                      tag="h6"
                      style={{ textAlign: "center" }}
                    >
                      VI. DATOS DE ENLACE
                    </CardSubtitle>
                    <Row>
                      <Col xs="12">
                        <FormGroup>
                          <Label>
                            Persona que levantó solicitud en campo:
                            {/* <span className="text-danger">*</span> */}
                          </Label>
                          <Input
                            name="Enlace"
                            type="text"
                            value={form.Enlace}
                            onChange={(e) =>
                              setForm({
                                ...form,
                                Enlace: e.target.value.toUpperCase(),
                              })
                            }
                          />
                        </FormGroup>
                      </Col>
                      {errors.error && errors.seccion === 6 && (
                        <Col xs="12">
                          <CardText
                            style={{ color: "red", textAlign: "center" }}
                          >
                            {errors.message}
                          </CardText>
                        </Col>
                      )}
                    </Row>
                    <hr
                      style={{
                        backgroundColor:
                          errors.error && errors.seccion === 7
                            ? "red"
                            : "#E8E8E8",
                      }}
                    />
                    <CardSubtitle
                      className="mb-2 text-muted"
                      tag="h6"
                      style={{ textAlign: "center" }}
                    >
                      VIII. ARCHIVOS DE EVIDENCIA (CARPETA DIGITAL)
                    </CardSubtitle>
                    <Row>
                      <ListadoArchivos
                        files={form.Files}
                        archivosClasificacion={form.ArchivosClasificacion}
                        catalogClasificacion={catalogs.archivos_clasificacion}
                        handleChangeArchivos={handleChangeArchivos}
                        handleChangeClasificacion={handleChangeClasificacion}
                        handleDeleteFile={handleDeleteFile}
                        loadingCatalogs={loadingCatalogs}
                        onlyForView={form.ListaParaEnviar > 0}
                      />
                      {errors.error && errors.seccion === 7 && (
                        <Col xs="12">
                          <CardText
                            style={{ color: "red", textAlign: "center" }}
                          >
                            {errors.message}
                          </CardText>
                        </Col>
                      )}
                    </Row>
                    <Row>
                      <Col sm="12" style={{ textAlign: "center" }}>
                        <Button
                          color="warning"
                          disabled={loadingForm}
                          onClick={backSolicitud}
                        >
                          Cancelar
                        </Button>

                        {form.ListaParaEnviar < 1 && (
                          <>
                            {isForEdit && menu.Eliminar === 1 && !loadingForm && (
                              <Button
                                color="danger"
                                disabled={loadingForm}
                                onClick={() => deleteAlert()}
                              >
                                Eliminar
                              </Button>
                            )}

                            {menu.Agregar === 1 &&
                              menu.Editar === 1 &&
                              !loadingForm && (
                                <>
                                  <Button
                                    disabled={loadingForm}
                                    onClick={() => saveSolicitud()}
                                  >
                                    Guardar
                                  </Button>
                                  <Button
                                    color="primary"
                                    disabled={loadingForm}
                                    onClick={() => saveSolicitud(true)}
                                  >
                                    Validar
                                  </Button>
                                  {loadingForm && <Spinner />}
                                </>
                              )}
                          </>
                        )}
                      </Col>
                      <Col
                        sm="12"
                        style={{ textAlign: "center", color: "red" }}
                      >
                        {form.ListaParaEnviar > 0 && (
                          <CardText>
                            La solicitud se encuentra validada, no se puede
                            editar
                          </CardText>
                        )}
                        {errors.error && (
                          <CardText>
                            Existen errores, por favor verifique el formulario
                          </CardText>
                        )}
                      </Col>
                    </Row>
                  </Form>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default SolicitudCalentadores;
