import React, { useEffect, useState } from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";
import EjecutarApi from "../Consultas/Consultas";
import Notifications, { notify } from "react-notify-toast";
import { getVars } from "../GlobalStorage";
//import { RotateSpinner } from 'react-spinners-kit';
//import Select from "react-select";
import { Link } from "react-router-dom";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Sidebar from "../components/Sidebar";
import FixedPlugin from "../components/PluginFilter";
import ModalComercioUpdate from "../components/ModalComecioUpdate";
import Pagination from "../components/pagination";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col /* , InputGroup, Input, InputGroupAddon, Label  */,
} from "reactstrap";
import { URL_GENERAL } from "../data/consts";

const Comercios = (props) => {
  //const [flag, setFlag] = useState(false);
  const [totalComercios, setTotalComercios] = useState(0);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [deshabilitaCaptura, setdeshabilitaCaptura] = useState(false);
  const [proptemporal, setproptemporal] = useState({});
  const [flagllamarModal, setflagllamarModal] = useState(false);
  const [_menu, _setmenu] = useState({});
  const [_menuidTipoUser, _setmenuidTipoUser] = useState(0);
  const [_isTipoUserAdmin, _setisTipoUserAdmin] = useState(false);
  const [isvisibleEstatus, setisvisibleEstatus] = useState(true); //True es no puede editar // false puede editar
  const token = getVars("Token");
  const [FilteredPage, setFilteredPage] = useState(0);
  const [Filtered, setFiltered] = useState({});
  const [Filteredclaveunica, setFilteredclaveunica] = useState("");
  const handleMiniClick = () => {
    document.body.classList.toggle("sidebar-mini");
  };

  useEffect(() => {
    console.log("Token--->", token);
    setLoading(true);
    token.menu.forEach((prop, key) => {
      //console.log('prop...Menu..token',prop.Menu);
      if (prop.Menu === "Comercios") {
        _setmenu({
          Ver: prop.Ver,
          Agregar: prop.Agregar,
          Editar: prop.Editar,
          Eliminar: prop.Eliminar,
          Seguimiento: prop.Seguimiento,
          Exportar: prop.Exportar,
          Imprimir: prop.Imprimir,
          idTipoUser: token.user.idTipoUser.id,
        });
      }
    });
    //_setmenuidTipoUser(token.user.idTipoUser.id);
    //disabled={_isTipoUserAdmin?false:isvisibleEstatus}
    if (token.user.idTipoUser.id == 1) _setisTipoUserAdmin(true);

    const datax = {
      page: 0,
      tipo: "and",
      pageSize: 10,
      sorted: [
        { id: "NombreEmpresa", desc: false },
        { id: "FechaInscripcion", desc: false },
      ],
      filtered: [],
    };

    console.log('=>tokemn: ', token.token);
    console.log('=>getNegociosApp: ', datax);

    EjecutarApi(datax, "getNegociosApp", token.token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        setTotalComercios(res.total);

        setData(
          res.data.map((prop, key) => {
            //if(prop.idStatus===1 && _menu.idTipoUser===3) setisvisibleEstatus(true);
            return {
              RFC: prop.RFC,
              Refrendo2021: prop.Refrendo2021,
              NombreEmpresa: (<Button
                id="ModalUpdate"
                color="success"
                size="sm"
                onClick={() => {
                  setproptemporal(prop);
                  setflagllamarModal(true);
                }}
              >
                <span className="btn-label">
                  <i className="nc-icon nc-ruler-pencil" />
                  {prop.NombreEmpresa}
                </span>
              </Button>),
              Nombre: `${prop.Nombre !== null ? prop.Nombre : ""} ${
                prop.Paterno !== null ? prop.Paterno : ""
              } ${prop.Materno !== null ? prop.Materno : ""}`,
              Celular: prop.Celular,
              TelNegocio: prop.TelNegocio,
              TelCasa: prop.TelCasa,
              Correo: prop.Correo,
              Municipio: prop.Municipio,
              Region: prop.Region,
              Colonia: prop.Colonia,
              Calle: prop.Calle,
              Estatus: prop.Estatus,
              ClaveUnica: prop.ClaveUnica,
              action: (
                <Button
                  id="ModalUpdate"
                  color="success"
                  size="sm"
                  //disabled={_isTipoUserAdmin?false:(prop.idStatus == "1"  && _menu.idTipoUser==3)?false:true}
                  //disabled={_isTipoUserAdmin?false:isvisibleEstatus}
                  onClick={() => {
                    setproptemporal(prop);
                    setflagllamarModal(true);
                  }}
                >
                  <span className="btn-label">
                    <i className="nc-icon nc-ruler-pencil" />
                  </span>
                </Button>
              ),
              data: prop,
            };
          })
        );
        setLoading(false);
        //setLoadingGrupo(false);
      })
      .catch((e) => {
        setLoading(false);
        notify.show(`Error al obtener comercios: ${e.errors}`, "custom", 5000, {
          background: "#0857B6",
          fontSize: 14,
          text: "#FFFFFF",
        });

        //setLoadingGrupo(false);
      });
  }, [flagllamarModal]);

  const setFilter = (filtro) => {
    setLoading(true);
    console.log("Filtered-->", filtro);
    setFiltered(filtro.filtered);
    setFilteredclaveunica(filtro.claveunica);
    const datos_ = {
      page: FilteredPage,
      tipo: "and",
      pageSize: 10,
      sorted: [
        { id: "NombreEmpresa", desc: false },
        { id: "FechaInscripcion", desc: false },
      ],
      filtered: filtro.filtered,
      Folio: filtro.claveunica != "" ? filtro.claveunica : "",
    };

    console.log('=>getNegociosApp', JSON.stringify(datos_));
    console.log('=>token', token.token);

    EjecutarApi(datos_, "getNegociosApp", token.token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.results) {
          console.log('=>getNegociosAppRS: ', res);

          setTotalComercios(res.total);
          setData(
            res.data.map((prop, key) => {
              setLoading(false);
              // if(prop.idStatus===1 && _menu.idTipoUser===3) setisvisibleEstatus(true);

              return {
                RFC: prop.RFC,
                Refrendo2021: prop.Refrendo2021,
                NombreEmpresa: (<Button
                  id="ModalUpdate"
                  color="success"
                  size="sm"
                  onClick={() => {
                    setproptemporal(prop);
                    setflagllamarModal(true);
                  }}
                >
                  <span className="btn-label">
                    <i className="nc-icon nc-ruler-pencil" />
                    {prop.NombreEmpresa}
                  </span>
                </Button>),
                Nombre: `${prop.Nombre !== null ? prop.Nombre : ""} ${
                  prop.Paterno !== null ? prop.Paterno : ""
                } ${prop.Materno !== null ? prop.Materno : ""}`,
                Celular: prop.Celular,
                TelNegocio: prop.TelNegocio,
                TelCasa: prop.TelCasa,
                Correo: prop.Correo,
                Municipio: prop.Municipio,
                Region: prop.Region,
                Colonia: prop.Colonia,
                Calle: prop.Calle,
                Estatus: prop.Estatus,
                ClaveUnica: prop.ClaveUnica,
                action: (
                  <Button
                    id="ModalUpdate"
                    color="success"
                    size="sm"
                    //disabled={_isTipoUserAdmin?false:(prop.idStatus == "1"  && _menu.idTipoUser==3)?false:true}
                    //disabled={_isTipoUserAdmin?false:isvisibleEstatus}

                    onClick={() => {
                      setproptemporal(prop);
                      setflagllamarModal(true);
                    }}
                  >
                    <span className="btn-label">
                      <i className="nc-icon nc-ruler-pencil" />
                    </span>
                  </Button>
                ),
                data: prop,
              };
            })
          );
        }
        setLoading(false);
      })
      .catch((e) => {
        notify.show(`Error en la busqueda! ${e.message}`, "custom", 5000, {
          background: "#d61818",
          text: "#FFFFFF",
        });
        setLoading(false);
      });
  };

  const btnExportar = () => {
    console.log("=>Exportar");

    var url = new URL(
      URL_GENERAL+`downloadReporteComercios?token=${token.token}`
    );

    window.open(url, "_blank");
  };

  
  console.log("_menu-->", _menu);
  console.log("-->ID Usuario en Comeciolist es admin--", _isTipoUserAdmin);
  return (
    <>
      <div className="wrapper">
        <Sidebar {...props} bgColor="black" activeColor="info" />
        <div className="main-panel">
          <Navbar {...props} handleMiniClick={handleMiniClick} />
          <Notifications />
          <div className="content">
            <Card>
              <CardHeader>
                <Row>
                  <Col md="6">
                    <CardTitle tag="h4">Listado de comercios</CardTitle>
                  </Col>
                </Row>
              </CardHeader>
            </Card>
            <Row>
              <Col md="12">
                <Card>
                  <CardHeader>
                    {_menu.Agregar == 1 ? (
                      <Link to="comercioAdd">
                        <Button color="success" size="sm">
                          <span className="btn-label">
                            <i className="fa fa-user-plus fa-2x" />
                          </span>
                          Capturar
                        </Button>
                      </Link>
                    ) : (
                      <> </>
                    )}

                    {_menu.Exportar == 1 ? (
                      <Button color="success" size="sm" onClick={btnExportar}>
                        <span className="btn-label">
                          <i className="fa fa-file-excel-o fa-2x" />
                        </span>
                        Exportar
                      </Button>
                    ) : (
                      <> </>
                    )}
                    <Link to="/comerciosmap">
                      <Button color="success" size="sm">
                        <span className="btn-label">
                          <i className="fa fa-map fa-2x" />
                        </span>
                        Ir a Mapas
                      </Button>
                    </Link>
                  </CardHeader>
                  <CardBody>
                    <ReactTable
                      data={data}
                      noDataText="Sin información"
                      columns={[
                        {
                          Header: "Region",
                          minWidth: 40,
                          id: "Region",
                          accessor: (d) => d.Region,
                          filterAll: false,
                          headerStyle:{fontSize: '9px', textAlign: 'center'},
                          Style:{fontSize: '11px'}
                        },
                        {
                          Header: "Municipio",
                          minWidth: 120,
                          id: "Municipio",
                          accessor: (d) => d.Municipio,
                          filterAll: false,
                          headerStyle:{fontSize: '9px', textAlign: 'center'},
                          Style:{fontSize: '11px'}
                        },
                        {
                          Header: "Refrendo",
                          minWidth: 50,
                          id: "Refrendo2021",
                          accessor: (d) => d.Refrendo2021,
                          filterAll: false,
                          headerStyle:{fontSize: '9px', textAlign: 'center'},
                          Style:{fontSize: '11px'}
                        },
                        {
                          Header: "Folio",
                          minWidth: 50,
                          id: "ClaveUnica",
                          accessor: (d) => d.ClaveUnica,
                          filterAll: false,
                          headerStyle:{fontSize: '9px', textAlign: 'center'},
                          Style:{fontSize: '11px'}
                        },
                        {
                          Header: "Clasificación",
                          minWidth: 80,
                          id: "TipoGiro",
                          accessor: (d) => d.TipoGiro,
                          filterAll: false,
                          headerStyle:{fontSize: '9px', textAlign: 'center'},
                          Style:{fontSize: '11px'}
                        },
                        
                        {
                          Header: "Nombre de la Empresa",
                          minWidth: 130,
                          id: "NombreEmpresa",
                          accessor: (d) => d.NombreEmpresa,
                          filterAll: false,
                          headerStyle:{fontSize: '9px', textAlign: 'center'},
                          Style:{fontSize: '11px'}
                        },
                        {
                          Header: "Representante",
                          minWidth: 130,
                          id: "Contacto",
                          accessor: (d) => d.Nombre,
                          filterAll: false,
                          headerStyle:{fontSize: '9px', textAlign: 'center'},
                          Style:{fontSize: '11px'}
                        },
                        {
                          Header: "Colonia",
                          minWidth: 100,
                          id: "Colonia",
                          accessor: (d) => d.Colonia,
                          filterAll: false,
                          headerStyle:{fontSize: '9px', textAlign: 'center'},
                          Style:{fontSize: '11px'}
                        },
                        {
                          Header: "Estatus",
                          minWidth: 40,
                          id: "Estatus",
                          accessor: (d) => d.Estatus,
                          filterAll: false,
                          headerStyle:{fontSize: '9px', textAlign: 'center'},
                          Style:{fontSize: '11px'}
                        }
                      ]}
                      className="-striped -highlight primary-pagination"
                      loading={loading}
                      showPagination={true}
                      showPageJump={false}
                      previousText="Anterior"
                      nextText="Siguiente"
                      canNextFromData={true}
                      manual
                      defaultPageSize={10}
                      total={totalComercios}
                      pages={Math.floor(totalComercios / 10)}
                      PaginationComponent={Pagination}
                      ofText="de"
                      pageSizeOptions={[10, 20, 25, 50, 100]}
                      onFetchData={(stateComp, instance) => {
                        setLoading(true);
                        const token = getVars("Token").token;
                        var datax = {};
                        console.log("PAGE", stateComp.page);
                        if (Filtered != null) {
                          datax = {
                            page: stateComp.page,
                            tipo: "and",
                            pageSize: stateComp.pageSize,
                            sorted: [
                              { id: "NombreEmpresa", desc: false },
                              { id: "FechaInscripcion", desc: false },
                            ],
                            filtered: Filtered,
                            Folio: Filteredclaveunica,
                          };
                        } else {
                          datax = {
                            page: stateComp.page,
                            tipo: "and",
                            pageSize: stateComp.pageSize,
                            sorted: [
                              { id: "NombreEmpresa", desc: false },
                              { id: "FechaInscripcion", desc: false },
                            ],
                            filtered: [],
                          };
                        }

                        EjecutarApi(datax, "getNegociosApp", token)
                          .then(function (res) {
                            return res;
                          })
                          .then((res) => {
                            console.log("=> res: ", res);
                            setLoading(false);
                            setTotalComercios(res.total);

                            setData(
                              res.data.map((prop, key) => {
                                //if (prop.idStatus == 1 && _menu.idTipoUser==3){ setisvisibleEstatus(false);}
                                return {
                                  RFC: prop.RFC,
                                  NombreEmpresa: (<Button
                                    id="ModalUpdate"
                                    color="success"
                                    size="sm"
                                    onClick={() => {
                                      setproptemporal(prop);
                                      setflagllamarModal(true);
                                    }}
                                  >
                                    <span className="btn-label">
                                      <i className="nc-icon nc-ruler-pencil" />
                                      {prop.NombreEmpresa}
                                    </span>
                                  </Button>),
                                  Nombre: `${
                                    prop.Nombre !== null ? prop.Nombre : ""
                                  } ${
                                    prop.Paterno !== null ? prop.Paterno : ""
                                  } ${
                                    prop.Materno !== null ? prop.Materno : ""
                                  }`,
                                  Celular: prop.Celular,
                                  TelNegocio: prop.TelNegocio,
                                  ClaveUnica: prop.ClaveUnica,
                                  TelCasa: prop.TelCasa,
                                  TipoGiro: prop.TipoGiro,
                                  Correo: prop.Correo,
                                  Municipio: prop.Municipio,
                                  Region: prop.Region,
                                  Colonia: prop.Colonia,
                                  Calle: prop.Calle,
                                  Estatus: prop.Estatus,

                                 
                                  data: prop,
                                };
                              })
                            );
                            //setLoadingGrupo(false);
                          })
                          .catch((e) => {
                            setLoading(false);
                            notify.show(
                              `Error al obtener comercios: ${e.errors}`,
                              "custom",
                              5000,
                              {
                                background: "#0857B6",
                                fontSize: 14,
                                text: "#FFFFFF",
                              }
                            );

                            //setLoadingGrupo(false);
                          });
                      }}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
          <FixedPlugin
            isLoading={loading}
            filtrar={setFilter}
            clasess="dropdowns"
          />
          {flagllamarModal ? (
            <ModalComercioUpdate
              show={flagllamarModal}
              api_={proptemporal}
              onHide={() => setflagllamarModal(false)}
            />
          ) : (
            ""
          )}
          <Footer fluid />
        </div>
      </div>
    </>
  );
};

export default Comercios;
