import React from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

const BarGraphHg = (props) => {
  const { labels, data, dataMetas, title } = props;

  const getOptions = (type) => ({
    chart: {
      type,
    },
    title: {
      text: title,
    },
    xAxis: {
      categories: labels,
    },
    yAxis: {
      min: 0,
      title: {
        text: "Vales pineados",
      },
    },
    plotOptions: {
      column: {
        dataLabels: {
          enabled: true,
          align: "center",
          style: {
            fontSize: "10px",
            fontWeight: "bold",
          },
        },
      },
    },
    credits: {
      enabled: false,
    },
    series: [
      {
        name: "Meta",
        data: dataMetas,
      },
      {
        name: "Pineados",
        data: data,
      },
    ],
  });
  return (
    <>
      {
        <HighchartsReact
          highcharts={Highcharts}
          options={getOptions("column")}
        />
      }
    </>
  );
};
export default BarGraphHg;
