import React from "react";
import ReactDOM from "react-dom";
import {
  BrowserRouter as Router,
  Route,
  Switch /* , Redirect */,
} from "react-router-dom";

import App from "./App";
import * as serviceWorker from "./serviceWorker";
import ProtectedRoute from "./ProtectedRoute";

import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/scss/paper-dashboard.scss?v=1.1.0";
import "./assets/demo/demo.css";
import "perfect-scrollbar/css/perfect-scrollbar.css";

import Login from "./pages/LoginPage";
import ValesRecepcion from "./pages/ValesRecepcion";
import ValesRecepcionAdd from "./pages/ValesRecepcionAdd";
import NotFound from "./pages/NotFound";
import Comercios from "./pages/Comercios";
import MapaComercios from "./pages/MapaComercios";
import ComerciosAdd from "./pages/ComerciosAdd";
import ValesAdd from "./pages/ValesAdd";
import Vales from "./pages/Vales";
import ValesUpdate from "./pages/ValesUpdate";
import ResumenAldo from "./pages/ResumenAldo";
import Usuarios from "./pages/Usuarios";
import Grupos from "./pages/Grupos";
import Grupos2023 from "./pages/Grupos2023";
import GruposAdd from "./pages/GruposAdd";
import GruposAdd2023 from "./pages/GruposAdd2023";
import GruposArticulador from "./pages/GrupoArticulador";
import GruposEdit from "./pages/GruposEdit";
import Remesas from "./pages/Remesas";
import EntregaVales from "./pages/GruposEntregaVales";
import EntregaValesPaso1 from "./pages/GruposEntregaValePaso1";
import GruposDocumentos from "./pages/GruposDocumentos";
import GruposDocsPaso1 from "./pages/GruposDocPaso1";
import GruposDocPaso2 from "./pages/GruposDocPaso2";
import HistoryVales from "./pages/HistoryVales";
import Bravos from "./pages/Bravos";
import BravosEntrega from "./pages/BravosEntrega";
import ValesAvances2021 from "./pages/ValesAvances2021";
import CalentadoresAvances2022 from "./pages/CalentadoresAvances2022";
import GruposAvances from "./pages/GruposAvances";
import SolicitudCedulaAdd from "./pages/SolicitudCedulaAdd";
import SolicitudCedulaAddAnterior from "./pages/SolicitudCedulaAddFormatoAnterior";
import CedulaForm from "./pages/CedulaForm";
import Cedula from "./pages/Cedula";
import Calentadores from "./pages/CalentadoresList";
import CalentadoresSolares from "./pages/Calentadores";
import SolicitudCalentadores from "./pages/SolicitudCedulaCalentadores";
import SolicitudCalentadoresAnterior from "./pages/SolicitudCedulaCalentadoresAnterior";
import CalentadorCedulaForm from "./pages/CalentadorCedulaForm";
import Proyectos from "./pages/ProyectosList";
// import TrabajemosJuntos from "./pages/TrabajemosJuntosList";
// import GruposTrabajemosJuntos from "./pages/GruposTrabajemosJuntosList";
import SolicitudProyectos from "./pages/SolicitudCedulaProyectos";
import ProyectosCedulaForm from "./pages/ProyectosCedulaForm";
import Diagnostico from "./pages/DiagnosticosList";
import DiagnosticoCedulaForm from "./pages/DiagnosticoCedulaForm";
import YoPuedo from "./pages/YoPuedoList";
import SolicitudYoPuedo from "./pages/SolicitudCedulaYoPuedo";
import YoPuedoCedulaForm from "./pages/YoPuedoCedulaForm";
import Diagnosticos from "./pages/DiagnosticoV2List";
import SolicitudDiagnosticos from "./pages/SolicitudCedulaDiagnosticos";
import Conciliacion from "./pages/ConciliacionVales";
import ValesEntregados from "./pages/ValesEntregadosList";
import ValidaCurp from "./pages/tmp/ValidaCurp";
import ValidaCURPRemesa from "./pages/tmp/ValidaCURPRemesa";
import RmpAcuse from "./pages/ReimpresionAcuse";
import Padron from "./pages/PadronesVales";
import EntregaVale from "./pages/EntregaVales";
import Prevalidacion from "./pages/Prevalidacion";
import ValesList from "./pages/ValesList";
import EstadisticasVales from "./pages/Estadisticas";
import PadronAvance from "./pages/PadronAvance";
import ProyectosProductivos from "./pages/proyectosproductivos/ProyectosList";
import RevisionGrupo from "./pages/RevisionGrupo";
import ValesDevueltos from "./pages/vales/ValesDevueltos";
import Asistencia from "./pages/vales/Asistencia";
import ValesAuditoria from "./pages/vales/ValesAuditoria";
import EncuestaSocial from "./pages/revision/EncuestaSocial";
import EncuestaSocialTCS from "./pages/revision/EncuestaSocialTCS";
import EncuestaTCSGTO from "./pages/revision/EncuestaTCS_v2";
import User from "./pages/user/User";

const Root = () => {
  return (
    <Router>
      <Switch>
        <ProtectedRoute exact path="/" component={App} />
        <ProtectedRoute exact path="/comercioAdd" component={ComerciosAdd} />
        <ProtectedRoute
          exact
          path="/avances2021"
          component={ValesAvances2021}
        />
        <ProtectedRoute
          exact
          path="/avances-calentadores"
          component={CalentadoresAvances2022}
        />
        <ProtectedRoute exact path="/avancesgrupos" component={GruposAvances} />
        <ProtectedRoute exact path="/recepcion" component={ValesRecepcion} />
        <ProtectedRoute
          exact
          path="/recepcion_add"
          component={ValesRecepcionAdd}
        />
        <ProtectedRoute exact path="/comercios" component={Comercios} />
        <ProtectedRoute exact path="/comerciosmap" component={MapaComercios} />
        <ProtectedRoute exact path="/valeAdd" component={ValesAdd} />
        <ProtectedRoute exact path="/vales" component={Vales} />
        <ProtectedRoute exact path="/valesupdate" component={ValesUpdate} />
        <ProtectedRoute exact path="/resumen" component={ResumenAldo} />
        <ProtectedRoute exact path="/usuarios" component={User} />
        <ProtectedRoute exact path="/Grupos" component={Grupos} />
        <ProtectedRoute exact path="/Grupos-2023" component={Grupos2023} />
        <ProtectedRoute exact path="/gruposadd" component={GruposAdd} />
        <ProtectedRoute exact path="/gruposadd2023" component={GruposAdd2023} />
        <ProtectedRoute exact path="/gruposedit" component={GruposEdit} />
        <ProtectedRoute exact path="/Remesas" component={Remesas} />
        <ProtectedRoute exact path="/entregavales" component={EntregaVales} />
        <ProtectedRoute exact path="/prevalidacion" component={Prevalidacion} />
        <ProtectedRoute
          exact
          path="/entregavalespaso1"
          component={EntregaValesPaso1}
        />
        <ProtectedRoute
          exact
          path="/gruposdocumentos"
          component={GruposDocumentos}
        />
        <ProtectedRoute
          exact
          path="/gruposdocpaso1"
          component={GruposDocsPaso1}
        />
        <ProtectedRoute
          exact
          path="/gruposdocpaso2"
          component={GruposDocPaso2}
        />
        <ProtectedRoute exact path="/history" component={HistoryVales} />
        <ProtectedRoute exact path="/bravos" component={Bravos} />
        <ProtectedRoute exact path="/bravosEntrega" component={BravosEntrega} />
        <ProtectedRoute
          exact
          path="/solicitud-cedula-form"
          component={SolicitudCedulaAdd}
        />
        <ProtectedRoute
          exact
          path="/solicitud-form"
          component={SolicitudCedulaAddAnterior}
        />
        <ProtectedRoute
          exact
          path="/solicitud-calentador-cedula-form"
          component={SolicitudCalentadores}
        />
        <ProtectedRoute
          exact
          path="/solicitud-calentador-form"
          component={SolicitudCalentadoresAnterior}
        />
        <ProtectedRoute
          exact
          path="/solicitud-proyectos-form"
          component={SolicitudProyectos}
        />
        <ProtectedRoute
          exact
          path="/solicitud-yopuedo-form"
          component={SolicitudYoPuedo}
        />
        <ProtectedRoute
          exact
          path="/solicitud-diagnosticos-form"
          component={SolicitudDiagnosticos}
        />
        <ProtectedRoute exact path="/cedula-form" component={CedulaForm} />
        <ProtectedRoute
          exact
          path="/calentador-cedula-form"
          component={CalentadorCedulaForm}
        />
        <ProtectedRoute
          exact
          path="/proyectos-cedula-form"
          component={ProyectosCedulaForm}
        />
        <ProtectedRoute
          exact
          path="/diagnostico-cedula-form"
          component={DiagnosticoCedulaForm}
        />
        <ProtectedRoute
          exact
          path="/yopuedo-cedula-form"
          component={YoPuedoCedulaForm}
        />
        <ProtectedRoute exact path="/cedula-list" component={Cedula} />
        <ProtectedRoute exact path="/listado-vales" component={ValesList} />
        <ProtectedRoute exact path="/conciliacion" component={Conciliacion} />
        <ProtectedRoute exact path="/proyectos-list" component={Proyectos} />
        <ProtectedRoute
          exact
          path="/reportes-vales"
          component={ValesEntregados}
        />
        <ProtectedRoute
          exact
          path="/fecha-entrega-vales"
          component={EntregaVale}
        />
        <ProtectedRoute
          exact
          path="/diagnostico-list"
          component={Diagnostico}
        />
        <ProtectedRoute exact path="/diagnosticos" component={Diagnosticos} />
        <ProtectedRoute
          exact
          path="/calentadores-list"
          component={Calentadores}
        />
        <ProtectedRoute
          exact
          path="/calentadores-solares"
          component={CalentadoresSolares}
        />
        <ProtectedRoute
          exact
          path="/proyectos-2023"
          component={ProyectosProductivos}
        />
        <ProtectedRoute exact path="/yopuedo-list" component={YoPuedo} />
        {/* <ProtectedRoute
          exact
          path="/trabajemos-beneficiarios"
          component={TrabajemosJuntos}
        />
        <ProtectedRoute
          exact
          path="/trabajemos-grupos"
          component={GruposTrabajemosJuntos}
        /> */}
        <Route
          path="/encuesta-tj/:CURP/:Nombre/:Paterno/:Materno/:idMunicipio/:Municipio/:idLocalidad/:Localidad/:Calle/:Colonia/:NumExt/:CP/:Celular"
          component={EncuestaTCSGTO}
        />
        <Route
          path="/encuesta-tj/:CURP/:Nombre/:Paterno//:idMunicipio/:Municipio/:idLocalidad/:Localidad/:Calle/:Colonia/:NumExt/:CP/:Celular"
          component={EncuestaTCSGTO}
        />
        <Route
          path="/encuesta-tj/:CURP/:Nombre/:Paterno//:idMunicipio/:Municipio/:idLocalidad/:Localidad/:Calle/:Colonia/:NumExt//:Celular"
          component={EncuestaTCSGTO}
        />
        <Route
          path="/encuesta-tj/:CURP/:Nombre/:Paterno//:idMunicipio/:Municipio/:idLocalidad/:Localidad/:Calle/:Colonia/:NumExt//"
          component={EncuestaTCSGTO}
        />
        <Route
          path="/encuesta-tj/:CURP/:Nombre/:Paterno/:Materno/:idMunicipio/:Municipio/:idLocalidad/:Localidad/:Calle/:Colonia/:NumExt//:Celular"
          component={EncuestaTCSGTO}
        />
        <Route
          path="/encuesta-tj/:CURP/:Nombre/:Paterno/:Materno/:idMunicipio/:Municipio/:idLocalidad/:Localidad/:Calle/:Colonia/:NumExt//"
          component={EncuestaTCSGTO}
        />
        <Route
          path="/encuesta-tj/:CURP/:Nombre/:Paterno/:Materno/:idMunicipio/:Municipio/:idLocalidad/:Localidad/:Calle/:Colonia/:NumExt/:CP/"
          component={EncuestaTCSGTO}
        />
        <Route
          path="/encuesta-tj/:CURP/:Nombre/:Paterno//:idMunicpio/:Municipio/:idLocalidad/:Localidad/:Calle/:Colonia/:NumExt/:CP//"
          component={EncuestaTCSGTO}
        />
        <Route path="/curp" component={ValidaCurp} />
        <Route path="/reimpresion" component={RmpAcuse} />
        <Route path="/revision-grupo" component={RevisionGrupo} />
        <Route path="/carga-padron" component={Padron} />
        <Route path="/reasignacion" component={ValidaCURPRemesa} />
        <Route path="/estadisticas-vales" component={EstadisticasVales} />
        <Route path="/avance-padron" component={PadronAvance} />
        <Route path="/vales-devueltos" component={ValesDevueltos} />
        <Route path="/asistencia-vales" component={Asistencia} />
        <Route path="/l-vales" component={ValesAuditoria} />
        <Route path="/encuesta" component={EncuestaSocial} />
        <Route path="/encuesta-tarjeta" component={EncuestaSocialTCS} />
        <Route path="/login" component={Login} /> <Route component={NotFound} />
      </Switch>
    </Router>
  );
};

ReactDOM.render(<Root />, document.getElementById("root"));
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
