import React, { useEffect, useState } from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";
import EjecutarApi from "../Consultas/Consultas";
import Notifications, { notify } from "react-notify-toast";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { CircleSpinner } from "react-spinners-kit";
import { getVars } from "../GlobalStorage";

import { Link } from "react-router-dom";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Sidebar from "../components/Sidebar";
import FixedPlugin from "../components/PluginFilterVales";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Table,
  CardFooter,
} from "reactstrap";

const HistoryVales = (props) => {
  //const [flag, setFlag] = useState(false);
  const [totalVales, setTotalVales] = useState(0);
  const [TotalValesHistory, setTotalValesHistory] = useState(0);
  const [loading, setLoading] = useState(false);
  //const [loadingGrupo, setLoadingGrupo] = useState(true);
  const [data, setData] = useState([]);
  const [dataHistory, setdataHistory] = useState([]);
  const [regiones, setRegiones] = useState([]);
  const [_menu, _setmenu] = useState({});
  const token = getVars("Token");

  //update
  const [_Folio, _setFolio] = useState({});
  const [isUpdate, setisUpdate] = useState(false);
  const [banMensaje, setbanMensaje] = useState(false);
  const [itemPrincipal, setItemPrincial] = useState({
    UserEdito: { id: " ", Nombre: " ", Paterno: " ", Materno: " " },
    UserEditoFecha: " ",
    ClaveUnica: " ",
    FechaSolicitud: " ",
    CURP: " ",
    Nombre: " ",
    Paterno: " ",
    Materno: " ",
    Sexo: " ",
    FechaNacimiento: " ",
    idMunicipio: { id: " ", Municipio: " ", Region: " " },
    Calle: " ",
    NumInt: " ",
    NumExt: " ",
    Colonia: " ",
    idLocalidad: { id: " ", Nombre: " " },
    CP: " ",
    CorreoElectronico: " ",
    TelFijo: " ",
    TelRecados: " ",
    TelCelular: " ",
    Compania: " ",
  });
  const [itemRestaurar, setItemRestaurar] = useState({
    UserEdito: { id: " ", Nombre: " ", Paterno: " ", Materno: " " },
    UserEditoFecha: " ",
    ClaveUnica: " ",
    FechaSolicitud: " ",
    CURP: " ",
    Nombre: " ",
    Paterno: " ",
    Materno: " ",
    Sexo: " ",
    FechaNacimiento: " ",
    idMunicipio: { id: " ", Municipio: " ", Region: " " },
    Calle: " ",
    NumInt: " ",
    NumExt: " ",
    Colonia: " ",
    idLocalidad: { id: " ", Nombre: " " },
    CP: " ",
    CorreoElectronico: " ",
    TelFijo: " ",
    TelRecados: " ",
    TelCelular: " ",
    Compania: " ",
  });


  const handleMiniClick = () => {
    document.body.classList.toggle("sidebar-mini");
  };

  const setFilter = (filtered) => {
    var dato = filtered;
    setLoading(true);
    console.log("=[Vales]=> datos a filtrar:", JSON.stringify(dato));
    EjecutarApi(dato, "getVales", token.token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.results) {
          setData(
            res.data.map((prop, key) => {
              setLoading(false);
              return {
                Nombre: `${prop.Nombre !== null ? prop.Nombre : ""} ${
                  prop.Paterno !== null ? prop.Paterno : ""
                } ${prop.Materno !== null ? prop.Materno : ""}`,
                TelCelular: prop.TelCelular,
                ClaveUnica: prop.ClaveUnica,
                TelFijo: prop.TelFijo,
                Estatus: prop.idStatus.Estatus,
                CorreoElectronico: prop.CorreoElectronico,
                Municipio: prop.idMunicipio.Municipio,
                Region: prop.idMunicipio.Region,
                Colonia: prop.Colonia,
                Calle: prop.Calle,
                //action: <ValesUpdate prop={prop} labelButton={"Editar"} />,
                data: prop,
              };
            })
          );
        }
        setLoading(false);
      })
      .catch((e) => {
        notify.show(`Error en la busqueda! ${e.message}`, "custom", 5000, {
          background: "#d61818",
          text: "#FFFFFF",
        });
        setLoading(false);
      });
  };

  const setearMenu = (dependecia) => {
    if (dependecia == "Estado") {
      token.menu.forEach((prop, key) => {
        if (prop.Menu === "Vales") {
          _setmenu({
            Ver: prop.Ver,
            Agregar: prop.Agregar,
            Editar: prop.Editar,
            Eliminar: prop.Eliminar,
            Seguimiento: prop.Seguimiento,
            Exportar: prop.Exportar,
            Imprimir: prop.Imprimir,
            ViewAll: prop.ViewAll,
            idTipoUser: token.user.idTipoUser.id,
          });
          setRegiones({ regiones: prop.regiones });
        }
      });
    } else {
      var json = {};

      token.menu.forEach((prop, key) => {
        if (prop.Menu === "Vales") {
          json = {
            Ver: prop.Ver,
            Agregar: prop.Agregar,
            Editar: prop.Editar,
            Eliminar: prop.Eliminar,
            Seguimiento: prop.Seguimiento,
            Exportar: prop.Exportar,
            Imprimir: prop.Imprimir,
            ViewAll: prop.ViewAll,
            idTipoUser: token.user.idTipoUser.id,
            regiones: token.regiones,
          };
        }
      });

      return json;
    }
  };

  useEffect(() => {
    console.log("==>token:", token);
    setearMenu("Estado");
  }, []);

  const alerta = (
    <ReactBSAlert
      style={{ display: "block", marginTop: "-100px" }}
      title={
        "No tiene permisos para editar, por favor consulte al Administrador"
      }
      onConfirm={() => setbanMensaje(false)}
      confirmBtnBsStyle="info"
    />
  );

  const handleLimpiarFormulario = () => {
    setItemRestaurar({
      UserEdito: { id: " ", Nombre: " ", Paterno: " ", Materno: " " },
      UserEditoFecha: " ",
      ClaveUnica: " ",
      FechaSolicitud: " ",
      CURP: " ",
      Nombre: " ",
      Paterno: " ",
      Materno: " ",
      Sexo: " ",
      FechaNacimiento: " ",
      idMunicipio: { id: " ", Municipio: " ", Region: " " },
      Calle: " ",
      NumInt: " ",
      NumExt: " ",
      Colonia: " ",
      idLocalidad: { id: " ", Nombre: " " },
      CP: " ",
      CorreoElectronico: " ",
      TelFijo: " ",
      TelRecados: " ",
      TelCelular: " ",
      Compania: " ",
    });
    setItemPrincial({
      UserEdito: { id: " ", Nombre: " ", Paterno: " ", Materno: " " },
      UserEditoFecha: " ",
      ClaveUnica: " ",
      FechaSolicitud: " ",
      CURP: " ",
      Nombre: " ",
      Paterno: " ",
      Materno: " ",
      Sexo: " ",
      FechaNacimiento: " ",
      idMunicipio: { id: " ", Municipio: " ", Region: " " },
      Calle: " ",
      NumInt: " ",
      NumExt: " ",
      Colonia: " ",
      idLocalidad: { id: " ", Nombre: " " },
      CP: " ",
      CorreoElectronico: " ",
      TelFijo: " ",
      TelRecados: " ",
      TelCelular: " ",
      Compania: " ",
    });
  };
  const handleRestaurar = () => {
    setLoading(true);
    var updateVales = {
      id: itemRestaurar.id,
      FechaSolicitud: itemRestaurar.FechaSolicitud,
      CURP: itemRestaurar.FechaSolicitud,
      Nombre: itemRestaurar.Nombre,
      Paterno: itemRestaurar.Paterno,
      Materno: itemRestaurar.Materno,
      Sexo: itemRestaurar.Sexo,
      FechaNacimiento: itemRestaurar.FechaNacimiento,
      Calle: itemRestaurar.Calle,
      NumInt: itemRestaurar.NumInt,
      NumExt: itemRestaurar.NumExt,
      Colonia: itemRestaurar.Colonia,
      CP: itemRestaurar.CP,
      idMunicipio: itemRestaurar.idMunicipio.id,
      idLocalidad: itemRestaurar.idLocalidad.id,
      TelFijo: itemRestaurar.TelFijo,
      TelCelular: itemRestaurar.TelCelular,
      TelRecado: itemRestaurar.TelRecado,
      Compania: itemRestaurar.Compania,
      CorreoElectronico: itemRestaurar.CorreoElectronico,
      idStatus: itemRestaurar.idStatus.id,
      UserOwned: itemRestaurar.idArticulador,
    };

    console.log("updateVale:", JSON.stringify(updateVales));
    EjecutarApi(updateVales, "updateVales", token.token)
      .then(function (res) {
        if (res.success) {
          setLoading(false);

          notify.show("Registro Exitoso! ", "custom", 5000, {
            background: "#18c97d",
            text: "#FFFFFF",
          });
          return res;
        }
      })
      .catch((e) => {
        console.log("errrorrrrrrrrr", e.errors);
        setLoading(false);
        notify.show(`Error en registro de vales! ${e.errors}`, "custom", 5000, {
          background: "#d61818",
          text: "#FFFFFF",
        });
      });
    setLoading(false);
  };
  const [flagalertaRestaurar, setFlagAlertaRestaurar] = useState(false);
  const alertRestaurar = (
    <ReactBSAlert
      style={{ display: "block", marginTop: "-100px" }}
      title={"¿Está seguro que desea restaurar los valores de resistro?"}
      onConfirm={() => {
        handleRestaurar();
        setFlagAlertaRestaurar(false);
      }}
      confirmBtnBsStyle="info"
    />
  );
  return (
    <>
      <div className="wrapper">
        <Sidebar {...props} bgColor="black" activeColor="info" />
        <div className="main-panel">
          <Navbar {...props} handleMiniClick={handleMiniClick} />
          <Notifications />
          <div className="content">
            <Row>
              <Col md="12">
                <Card>
                  <CardHeader>
                    <CardTitle tag="h4">
                      Resultado de búsqueda de Vales
                    </CardTitle>
                  </CardHeader>
                  <CardBody>
                    <ReactTable
                      data={data}
                      noDataText="Sin información"
                      columns={[
                        {
                          Header: "Historial",
                          minWidth: 100,
                          id: "action",
                          accessor: (d) => d.action,
                          filterAll: false,
                          className: "text-center",
                        },
                        {
                          Header: "Edito",
                          minWidth: 280,
                          id: "Edito",
                          accessor: (d) => d.Edito,
                          filterAll: false,
                        },
                        {
                          Header: "Fecha Edición",
                          minWidth: 200,
                          id: "FechaEdicion",
                          accessor: (d) => d.FechaEdicion,
                          filterAll: false,
                        },

                        {
                          Header: "Clave Única",
                          minWidth: 120,
                          id: "ClaveUnica",
                          accessor: (d) => d.ClaveUnica,
                          filterAll: false,
                        },
                        {
                          Header: "Fecha Solicitud",
                          minWidth: 200,
                          id: "FechaEdicion",
                          accessor: (d) => d.FechaEdicion,
                          filterAll: false,
                        },
                        {
                          Header: "CURP",
                          minWidth: 120,
                          id: "CURP",
                          accessor: (d) => d.CURP,
                          filterAll: false,
                        },
                        {
                          Header: "Nombre",
                          minWidth: 200,
                          id: "Nombre",
                          accessor: (d) => d.Nombre,
                          filterAll: false,
                        },
                        {
                          Header: "Paterno",
                          minWidth: 200,
                          id: "Paterno",
                          accessor: (d) => d.Paterno,
                          filterAll: false,
                        },
                        {
                          Header: "Materno",
                          minWidth: 200,
                          id: "Materno",
                          accessor: (d) => d.Materno,
                          filterAll: false,
                        },
                        {
                          Header: "Sexo",
                          minWidth: 200,
                          id: "Sexo",
                          accessor: (d) => d.Sexo,
                          filterAll: false,
                        },
                        {
                          Header: "Fecha Nacimiento",
                          minWidth: 200,
                          id: "FNacimiento",
                          accessor: (d) => d.FechaNacimiento,
                          filterAll: false,
                        },
                        {
                          Header: "Municipio",
                          minWidth: 180,
                          id: "Municipio",
                          accessor: (d) => d.Municipio,
                          filterAll: false,
                        },
                        {
                          Header: "Calle",
                          minWidth: 180,
                          id: "Calle",
                          accessor: (d) => d.Calle,
                          filterAll: false,
                        },
                        {
                          Header: "NumExterior",
                          minWidth: 180,
                          id: "NumExterior",
                          accessor: (d) => d.NumExterior,
                          filterAll: false,
                        },
                        {
                          Header: "NumInterior",
                          minWidth: 180,
                          id: "NumInterior",
                          accessor: (d) => d.NumInterior,
                          filterAll: false,
                        },
                        {
                          Header: "Colonia",
                          minWidth: 180,
                          id: "Colonia",
                          accessor: (d) => d.Colonia,
                          filterAll: false,
                        },
                        {
                          Header: "Localidad",
                          minWidth: 180,
                          id: "Localidad",
                          accessor: (d) => d.Localidad,
                          filterAll: false,
                        },
                        {
                          Header: "CP",
                          minWidth: 180,
                          id: "CP",
                          accessor: (d) => d.CP,
                          filterAll: false,
                        },
                        {
                          Header: "Email",
                          minWidth: 100,
                          id: "CorreoElectronico",
                          accessor: (d) => d.CorreoElectronico,
                          filterAll: false,
                        },
                        {
                          Header: "Celular",
                          minWidth: 100,
                          id: "Celular",
                          accessor: (d) => d.TelCelular,
                          filterAll: false,
                        },
                        {
                          Header: "Telefono Fijo",
                          minWidth: 100,
                          id: "TelNegocio",
                          accessor: (d) => d.TelFijo,
                          filterAll: false,
                        },
                        {
                          Header: "Tel. Recados",
                          minWidth: 100,
                          id: "TelRecados",
                          accessor: (d) => d.TelRecados,
                          filterAll: false,
                        },
                        {
                          Header: "Cia. Celular",
                          minWidth: 100,
                          id: "Compania",
                          accessor: (d) => d.Compania,
                          filterAll: false,
                        },
                        {
                          Header: "Estatus",
                          minWidth: 100,
                          id: "Estatus",
                          accessor: (d) => d.Estatus,
                          filterAll: false,
                        },
                      ]}
                      defaultPageSize={5}
                      className="-striped -highlight"
                      loading={loading}
                      showPagination={true}
                      showPageJump={false}
                      canNextFromData={true}
                      total={totalVales}
                      pages={Math.floor(totalVales / 10)}
                      previousText="Anterior"
                      nextText="Siguiente"
                      pageSizeOptions={[10, 15]}
                      getTrProps={(state, rowInfo, column, instance) => {
                        //var index = -1;
                        return {
                          onClick: (e) => {
                            //index = rowInfo.index;
                            console.log("state", state);
                            console.log("column", column);
                            console.log("instance", instance);
                            console.log("rowInfo", rowInfo);
                            console.log("ee", e);
                            //setIndexRow(rowInfo.index);
                            setItemPrincial(rowInfo.row._original.data);
                            if (_menu.Editar === 1) {
                              console.log(
                                "Click en Row",
                                rowInfo.row._original.data.ClaveUnica
                              );
                              console.log("isUpdate", isUpdate);
                              _setFolio(rowInfo.row._original.data.ClaveUnica);

                              setLoading(true);
                              const token = getVars("Token");
                              var datax = {};
                              datax = {
                                page: 0,
                                tipo: "and",
                                Folio: rowInfo.row._original.data.ClaveUnica,
                                pageSize: 9999,
                                sorted: [{ id: "UserEditoFecha", desc: true }],
                                filtered: [],
                              };
                              console.log(
                                "=>getVales datax: ",
                                JSON.stringify(datax)
                              );
                              EjecutarApi(datax, "getHistoryVales", token.token)
                                .then(function (res) {
                                  return res;
                                })
                                .then((res) => {
                                  setLoading(false);
                                  setTotalValesHistory(res.total);
                                  console.log("Vales->dataTotal:", res.toal);
                                  console.log("Vales->data:", res.data);
                                  setdataHistory(
                                    res.data.map((prop, key) => {
                                      //console.log("res.data.map=>Items: ", prop);
                                      // console.log('=>ItemsStatus: ', prop.idStatus.Estatus);
                                      return {
                                        Edito: `${prop.UserEdito.Nombre}
                                                ${prop.UserEdito.Paterno}
                                                ${prop.UserEdito.Materno}`,
                                        FechaEdicion: prop.UserEditoFecha,
                                        ClaveUnica: prop.ClaveUnica,
                                        FechaSolicitud: prop.FechaSolicitud,
                                        CURP: prop.CURP,
                                        Nombre: `${
                                          prop.Nombre !== null
                                            ? prop.Nombre
                                            : ""
                                        }`,
                                        Paterno: `${
                                          prop.Paterno !== null
                                            ? prop.Paterno
                                            : ""
                                        }`,
                                        Materno: `${
                                          prop.Materno !== null
                                            ? prop.Materno
                                            : ""
                                        }`,
                                        Sexo:
                                          prop.Sexo === "M"
                                            ? "Masculino"
                                            : "Femenino",
                                        FechaNacimiento: prop.FechaNacimiento,
                                        Municipio: prop.idMunicipio.Municipio,
                                        Calle: prop.Calle,
                                        NumInterior: prop.NumInt,
                                        NumExterior: prop.NumExt,
                                        Colonia: prop.Colonia,
                                        Localidad: prop.idLocalidad.Nombre,
                                        CP: prop.CP,
                                        CorreoElectronico:
                                          prop.CorreoElectronico,
                                        TelFijo: prop.TelFijo,
                                        TelRecados: prop.TelRecados,
                                        TelCelular: prop.TelCelular,
                                        Compania: prop.Compania,
                                        Region: prop.idMunicipio.Region,
                                        Estatus: prop.idStatus.Estatus,
                                        action: <Link check>Restaurar</Link>,
                                        data: prop,
                                      };
                                    })
                                  );
                                  //setLoadingGrupo(false);
                                })
                                .catch((e) => {
                                  console.log("=Error[getExport]: ", e);
                                  setLoading(false);
                                  notify.show(
                                    `Error al obtener Vales: ${e.errors}`,
                                    "custom",
                                    5000,
                                    {
                                      background: "#0857B6",
                                      fontSize: 14,
                                      text: "#FFFFFF",
                                    }
                                  );

                                  //setLoadingGrupo(false);
                                });
                            } else {
                              setbanMensaje(true);
                            }
                          },
                          /*style: {
                            border: rowInfo
                              ? index === rowInfo.index
                                ? "5px solid red"
                                : ""
                              : "",
                          },*/
                        };
                      }}
                      onFetchData={(stateComp, instance) => {
                        setLoading(true);
                        const token = getVars("Token");
                        var parametros = {
                          page: stateComp.page,
                          tipo: "and",
                          pageSize: stateComp.pageSize,
                          sorted: [],
                          filtered: [],
                        };
                        console.log(
                          "=>getValesHistoruy parametros: ",
                          JSON.stringify(parametros)
                        );
                        EjecutarApi(
                          parametros,
                          "getValesInHistory",
                          token.token
                        )
                          .then(function (res) {
                            return res;
                          })
                          .then((res) => {
                            setLoading(false);
                            setTotalVales(res.total);
                            console.log("Vales->dataTotal:", res.toal);
                            console.log("Vales->data:", res.data);

                            setData(
                              res.data.map((prop, key) => {
                                //console.log("res.data.map=>Items: ", prop);
                                // console.log('=>ItemsStatus: ', prop.idStatus.Estatus);
                                return {
                                  Edito: `${prop.UserEdito.Nombre}
                                    ${prop.UserEdito.Paterno}
                                      ${prop.UserEdito.Materno}`,
                                  FechaEdicion: prop.UserEditoFecha,
                                  ClaveUnica: prop.ClaveUnica,
                                  FechaSolicitud: prop.FechaSolicitud,
                                  CURP: prop.CURP,
                                  Nombre: `${
                                    prop.Nombre !== null ? prop.Nombre : ""
                                  }`,
                                  Paterno: `${
                                    prop.Paterno !== null ? prop.Paterno : ""
                                  }`,
                                  Materno: `${
                                    prop.Materno !== null ? prop.Materno : ""
                                  }`,
                                  Sexo:
                                    prop.Sexo === "M"
                                      ? "Masculino"
                                      : "Femenino",
                                  FechaNacimiento: prop.FechaNacimiento,
                                  Municipio: prop.idMunicipio.Municipio,
                                  Calle: prop.Calle,
                                  NumInterior: prop.NumInt,
                                  NumExterior: prop.NumExt,
                                  Colonia: prop.Colonia,
                                  Localidad: prop.idLocalidad.Nombre,
                                  CP: prop.CP,
                                  CorreoElectronico: prop.CorreoElectronico,
                                  TelFijo: prop.TelFijo,
                                  TelRecados: prop.TelRecados,
                                  TelCelular: prop.TelCelular,
                                  Compania: prop.Compania,
                                  Region: prop.idMunicipio.Region,
                                  Estatus: prop.idStatus.Estatus,
                                  action: <Link check>Ver...</Link>,
                                  data: prop,
                                };
                              })
                            );
                            //setLoadingGrupo(false);
                          })
                          .catch((e) => {
                            console.log("=Error[getExport]: ", e);
                            setLoading(false);
                            notify.show(
                              `Error al obtener Vales: ${e.errors}`,
                              "custom",
                              5000,
                              {
                                background: "#0857B6",
                                fontSize: 14,
                                text: "#FFFFFF",
                              }
                            );

                            //setLoadingGrupo(false);
                          });
                      }}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <Card>
                  <CardHeader>
                    <CardTitle tag="h4">Historial de cambios</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <ReactTable
                      data={dataHistory}
                      noDataText="No existe historial de cambios"
                      columns={[
                        {
                          Header: "Historial",
                          minWidth: 100,
                          id: "action",
                          accessor: (d) => d.action,
                          filterAll: false,
                          className: "text-center",
                        },
                        {
                          Header: "Edito",
                          minWidth: 280,
                          id: "Edito",
                          accessor: (d) => d.Edito,
                          filterAll: false,
                        },
                        {
                          Header: "Fecha Edición",
                          minWidth: 200,
                          id: "FechaEdicion",
                          accessor: (d) => d.FechaEdicion,
                          filterAll: false,
                        },

                        {
                          Header: "Clave Única",
                          minWidth: 120,
                          id: "ClaveUnica",
                          accessor: (d) => d.ClaveUnica,
                          filterAll: false,
                        },
                        {
                          Header: "Fecha Solicitud",
                          minWidth: 200,
                          id: "FechaEdicion",
                          accessor: (d) => d.FechaEdicion,
                          filterAll: false,
                        },
                        {
                          Header: "CURP",
                          minWidth: 120,
                          id: "CURP",
                          accessor: (d) => d.CURP,
                          filterAll: false,
                        },
                        {
                          Header: "Nombre",
                          minWidth: 200,
                          id: "Nombre",
                          accessor: (d) => d.Nombre,
                          filterAll: false,
                        },
                        {
                          Header: "Paterno",
                          minWidth: 200,
                          id: "Paterno",
                          accessor: (d) => d.Paterno,
                          filterAll: false,
                        },
                        {
                          Header: "Materno",
                          minWidth: 200,
                          id: "Materno",
                          accessor: (d) => d.Materno,
                          filterAll: false,
                        },
                        {
                          Header: "Sexo",
                          minWidth: 200,
                          id: "Sexo",
                          accessor: (d) => d.Sexo,
                          filterAll: false,
                        },
                        {
                          Header: "Fecha Nacimiento",
                          minWidth: 200,
                          id: "FNacimiento",
                          accessor: (d) => d.FechaNacimiento,
                          filterAll: false,
                        },
                        {
                          Header: "Municipio",
                          minWidth: 180,
                          id: "Municipio",
                          accessor: (d) => d.Municipio,
                          filterAll: false,
                        },
                        {
                          Header: "Calle",
                          minWidth: 180,
                          id: "Calle",
                          accessor: (d) => d.Calle,
                          filterAll: false,
                        },
                        {
                          Header: "NumExterior",
                          minWidth: 180,
                          id: "NumExterior",
                          accessor: (d) => d.NumExterior,
                          filterAll: false,
                        },
                        {
                          Header: "NumInterior",
                          minWidth: 180,
                          id: "NumInterior",
                          accessor: (d) => d.NumInterior,
                          filterAll: false,
                        },
                        {
                          Header: "Colonia",
                          minWidth: 180,
                          id: "Colonia",
                          accessor: (d) => d.Colonia,
                          filterAll: false,
                        },
                        {
                          Header: "Localidad",
                          minWidth: 180,
                          id: "Localidad",
                          accessor: (d) => d.Localidad,
                          filterAll: false,
                        },
                        {
                          Header: "CP",
                          minWidth: 180,
                          id: "CP",
                          accessor: (d) => d.CP,
                          filterAll: false,
                        },
                        {
                          Header: "Email",
                          minWidth: 100,
                          id: "CorreoElectronico",
                          accessor: (d) => d.CorreoElectronico,
                          filterAll: false,
                        },
                        {
                          Header: "Celular",
                          minWidth: 100,
                          id: "Celular",
                          accessor: (d) => d.TelCelular,
                          filterAll: false,
                        },
                        {
                          Header: "Telefono Fijo",
                          minWidth: 100,
                          id: "TelNegocio",
                          accessor: (d) => d.TelFijo,
                          filterAll: false,
                        },
                        {
                          Header: "Tel. Recados",
                          minWidth: 100,
                          id: "TelRecados",
                          accessor: (d) => d.TelRecados,
                          filterAll: false,
                        },
                        {
                          Header: "Cia. Celular",
                          minWidth: 100,
                          id: "Compania",
                          accessor: (d) => d.Compania,
                          filterAll: false,
                        },
                        {
                          Header: "Estatus",
                          minWidth: 100,
                          id: "Estatus",
                          accessor: (d) => d.Estatus,
                          filterAll: false,
                        },
                      ]}
                      defaultPageSize={5}
                      className="-striped -highlight primary-pagination"
                      loading={loading}
                      showPagination={true}
                      showPageJump={false}
                      canNextFromData={true}
                      total={TotalValesHistory}
                      pages={Math.floor(TotalValesHistory / 10)}
                      previousText="Anterior"
                      nextText="Siguiente"
                      pageSizeOptions={[10, 15]}
                      getTrProps={(state, rowInfo, column, instance) => {
                        return {
                          onClick: (e) => {
                            setItemRestaurar(rowInfo.row._original.data);
                          },
                        };
                      }}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <Card>
                  <CardHeader>
                    <CardTitle tag="h4">
                      Verficar registros seleccionados
                    </CardTitle>
                  </CardHeader>
                  <CardBody className="table-full-width table-hover">
                    <Table responsive>
                      <thead>
                        <tr>
                          <th>Registro</th>
                          <th className="col-xl-12">User Editó</th>
                          <th>Fecha de Edición</th>
                          <th>Clave Unica</th>
                          <th>Fecha de Solicitud</th>
                          <th>CURP</th>
                          <th>Nombre</th>
                          <th>Apellido Paterno</th>
                          <th>Apellido Materno</th>
                          <th>Sexo</th>
                          <th>Fecha de Nacimiento</th>
                          <th>Municipio</th>
                          <th>Calle</th>
                          <th>Número Exterior</th>
                          <th>Número Interior</th>
                          <th>Colonia</th>
                          <th>Localidad</th>
                          <th>CP</th>
                          <th>Correo</th>
                          <th>Tel Fijo</th>
                          <th>Tel Recados</th>
                          <th>Tel Celular</th>
                          <th>Compañía Celular</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr color="#d9f2e6">
                          <td>Original</td>
                          <td className="col-xl-12">
                            {itemPrincipal.UserEdito.Nombre}{" "}
                            {itemPrincipal.UserEdito.Paterno}{" "}
                            {itemPrincipal.UserEdito.Materno}
                          </td>
                          <td>{itemPrincipal.UserEditoFecha}</td>
                          <td>{itemPrincipal.ClaveUnica}</td>
                          <td>{itemPrincipal.FechaSolicitud}</td>
                          <td>{itemPrincipal.CURP}</td>
                          <td>{itemPrincipal.Nombre}</td>
                          <td>{itemPrincipal.Paterno}</td>
                          <td>{itemPrincipal.Materno}</td>
                          <td>{itemPrincipal.Sexo}</td>
                          <td>{itemPrincipal.FechaNacimiento}</td>
                          <td>{itemPrincipal.idMunicipio.Municipio}</td>
                          <td>{itemPrincipal.Calle}</td>
                          <td>{itemPrincipal.NumExt}</td>
                          <td>{itemPrincipal.NumInt}</td>
                          <td>{itemPrincipal.Colonia}</td>
                          <td>{itemPrincipal.idLocalidad.Nombre}</td>
                          <td>{itemPrincipal.CP}</td>
                          <td>{itemPrincipal.CorreoElectronico}</td>
                          <td>{itemPrincipal.TelFijo}</td>
                          <td>{itemPrincipal.TelRecados}</td>
                          <td>{itemPrincipal.TelCelular}</td>
                          <td>{itemPrincipal.Compania}</td>
                        </tr>
                        <tr className="#e6f5ff">
                          <td>Restaurar por:</td>
                          <td className="col-xl-12">
                            {itemRestaurar.UserEdito.Nombre}{" "}
                            {itemRestaurar.UserEdito.Paterno}{" "}
                            {itemRestaurar.UserEdito.Materno}
                          </td>
                          <td>{itemRestaurar.UserEditoFecha}</td>
                          <td>{itemRestaurar.ClaveUnica}</td>
                          <td>{itemRestaurar.FechaSolicitud}</td>
                          <td>{itemRestaurar.CURP}</td>
                          <td>{itemRestaurar.Nombre}</td>
                          <td>{itemRestaurar.Paterno}</td>
                          <td>{itemRestaurar.Materno}</td>
                          <td>{itemRestaurar.Sexo}</td>
                          <td>{itemRestaurar.FechaNacimiento}</td>
                          <td>{itemRestaurar.idMunicipio.Municipio}</td>
                          <td>{itemRestaurar.Calle}</td>
                          <td>{itemRestaurar.NumExt}</td>
                          <td>{itemRestaurar.NumInt}</td>
                          <td>{itemRestaurar.Colonia}</td>
                          <td>{itemRestaurar.idLocalidad.Nombre}</td>
                          <td>{itemRestaurar.CP}</td>
                          <td>{itemRestaurar.CorreoElectronico}</td>
                          <td>{itemRestaurar.TelFijo}</td>
                          <td>{itemRestaurar.TelRecados}</td>
                          <td>{itemRestaurar.TelCelular}</td>
                          <td>{itemRestaurar.Compania}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </CardBody>
                  <CardFooter>
                    <Button color="danger" onClick={handleLimpiarFormulario}>
                      Limpiar Registros
                    </Button>
                    {_menu.Editar === 1 ? (
                      <Button
                        color="info"
                        onClick={() => setFlagAlertaRestaurar(true)}
                      >
                        Restaurar Registro
                      </Button>
                    ) : (
                      <></>
                    )}
                    {loading && (
                      <CircleSpinner
                        className="text-center"
                        size={30}
                        color="#686769"
                        loading={loading}
                      />
                    )}
                    {flagalertaRestaurar ? alertRestaurar : <></>}
                  </CardFooter>
                </Card>
              </Col>
            </Row>
          </div>
          <FixedPlugin
            isLoading={loading}
            filtrar={setFilter}
            _menu={_menu}
            clasess="dropdowns"
          />

          {banMensaje ? alerta : <></>}
          <Footer fluid />
        </div>
      </div>
    </>
  );
};
export default HistoryVales;
