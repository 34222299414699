import React from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  CardText,
} from "reactstrap";
import StepWizard from "react-step-wizard";
import Notifications from "react-notify-toast";

import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Sidebar from "../components/Sidebar";

import FirstStep from "../components/EntregaVales/FirstStep";
import SecondStep from "../components/EntregaVales/SecondStep";
import ThirdStep from "../components/EntregaVales/ThirdStep";

import transitions from "../components/EntregaVales/transitions.less";
import Nav from "../components/EntregaVales/nav";

const EntregaVale = (props) => {
  const handleMiniClick = () => {
    document.body.classList.toggle("sidebar-mini");
  };

  // Do something on step change
  const onStepChange = (stats) => {
    // console.log(stats);
  };

  return (
    <div className="wrapper">
      <Sidebar {...props} bgColor="black" activeColor="info" />

      <div className="main-panel">
        <Navbar {...props} handleMiniClick={handleMiniClick} />
        <Notifications />

        <div className="content">
          <Row>
            <Col xs={12} md={10} className="mr-auto ml-auto">
              <Card>
                <CardHeader className="text-center">
                  <CardTitle>
                    <h3>Recepción de Vales</h3>
                  </CardTitle>
                  <CardText>
                    Escanea los vales que no fueron entregados al beneficiario
                  </CardText>
                </CardHeader>
                <CardBody>
                  <StepWizard
                    onStepChange={onStepChange}
                    transitions={transitions} // comment out for default transitions
                    nav={<Nav />}
                  >
                    <FirstStep />
                    <SecondStep />
                    <ThirdStep />
                  </StepWizard>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Footer fluid />
        </div>
      </div>
    </div>
  );
};

export default EntregaVale;
