import React, { useEffect, useState } from 'react';
import {
    Button,
    Label,
    FormGroup,
    Form,
    Input,
    Row,
    Col,
    CardHeader,
    Card,
    CardBody,
    CardFooter,CardTitle,
} from "reactstrap";
import DateTimeComponent from '../components/DateTimeComponent';
import Select from "react-select";
import EjecutarApi from '../Consultas/Consultas';
import { RotateSpinner, ImpulseSpinner } from 'react-spinners-kit';
import Notifications, { notify } from 'react-notify-toast';
import { Link } from 'react-router-dom';
import ReactDatetime from "react-datetime";
import moment, { now } from 'moment';
import { getVars } from '../GlobalStorage';
import ReactTable from 'react-table';
import ReactBSAlert from "react-bootstrap-sweetalert";

import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import Sidebar from '../components/Sidebar';
import { CircleSpinner } from 'react-spinners-kit';

function GruposDocPaso2(props) {
     
    const token = getVars('Token');
    const itemFolio = getVars('itemFolio');
    const [valesDocumentacion,setvalesDocumentacion]=useState(getVars('valesDocumentacion'));
    console.log("valesDocumentacion",valesDocumentacion);
    
    console.log('=>ItemFolio: ',itemFolio);
    const [municipios, setMunicipios] = useState([]);
    const [localidades, setLocalidades] = useState([]);
    const [Articulador,setArticulador] = useState(token.user.Nombre+" "+token.user.Paterno + " " + token.user.Paterno);
    
    const [nomMunicipio, setNomMunicipio] = useState('');
 
    const [loading,setloading] = useState(false);
    const [ban,setban] = useState(false);

    const [data, setData] = useState([]);
    const [totalColonias, setTotalColonias] = useState(0);

    const [flagEscogerColonia, setFlagEscogerColonia] = useState(false);
    const [flagEscogerArticulador, setflagEscogerArticulador] = useState(false);
    const [flagBtnGuardar, setflagBtnGuardar] = useState(true);
   
     const [vale, setVale] = useState({
        FechaSolicitud: itemFolio.FechaSolicitud,
        CURP: itemFolio.CURP,
        Nombre: itemFolio.Nombre,
        Paterno: itemFolio.Paterno,
        Materno: itemFolio.Materno,
        Sexo: itemFolio.Sexo,
        FechaNacimiento: itemFolio.FechaNacimiento,
        Calle: itemFolio.Calle,
        NumInt: itemFolio.NumInt,
        NumExt: itemFolio.NumExt,
        Colonia: itemFolio.Colonia,
        CP: itemFolio.CP,
        idMunicipio: itemFolio.idMunicipio,
        idLocalidad: itemFolio.idLocalidad,
        TelFijo: itemFolio.TelFijo,
        TelCelular: itemFolio.TelCelular,
        TelRecado:itemFolio.TelRecado,
        CompaniaCelular:itemFolio.CompaniaCelular,
        CorreoElectronico: itemFolio.CorreoElectronico,
        idStatus: 1, //PENDIENTE VERIFICAR EL ESTATUS 
        UserOwned:itemFolio.UserOwned,
        idArticulador:itemFolio.UserOwned.id,
        Articulador:itemFolio.UserOwned.Nombre +' '+itemFolio.UserOwned.Paterno +' '+itemFolio.UserOwned.Materno
    });
   
    
    const datos = {
        page: 0,
        tipo: 'and',
        pageSize: 1000,
        sorted: [],
        filtered: [],
    };
    const datosColonia = {
        page: 0,
        tipo: 'and',
        pageSize: 9999,
        sorted: [],
        filtered: [
            {
                "id": "d_codigo",
                "value": vale.CP
            },
            {
                "id": "D_mnpio",
                "value": nomMunicipio
            }
        ],
    };

    const Spinenerstyle = {
        'height': '50pt',
        'display': 'flex',
        'justifyContent': 'center',
        'alignItems': 'center'

    }
    const handleMiniClick = () => {
        document.body.classList.toggle('sidebar-mini');
    };
    useEffect(() => {
        
        EjecutarApi(datos, 'getMunicipiosET', token.token)
            .then(function (res) {
                return res;
            })
            .then((res) => {
                if (res.results) {
                    setMunicipios(res.data.map((municipio) => {
                        return {
                            "value": municipio["Id"],
                            "label": municipio["Nombre"]
                        }
                    }));
                }
            })
            .catch((e) => {
                notify.show(`Error en la busqueda! ${e.message}`, 'custom', 5000, {
                    background: '#d61818',
                    text: '#FFFFFF',
                });
            });
        
        //ObteniendoArticuladores
        var datoArt={
            page: 0,
            tipo: "and",
            pageSize: 400,
            sorted: [],
            filtered: [{"id": "idTipoUser", "value": 9}]
        };
     
    }, [])



   
    const handleGuardar=()=>{
        setloading(true);
        //comparando arrays
            var _FechaSolicitudChange=0;
            var _NombreChanged=0;
            var _PaternoChanged=0;
            var _MaternoChanged=0;
            var _CalleChanged=0;
            var _NumExtChanged=0;
            var _ColoniaChanged=0;
            var _idLocalidadChanged=0;
            var _idMunicipioChanged=0;
            var _UserOwnedchanged=0;
            var _NumIntChanged=0;
      
        if(itemFolio.FechaSolicitud!==vale.FechaSolicitud)
        {
             _FechaSolicitudChange=1;
            console.log('fechaSolicitud..cambio',_FechaSolicitudChange);
        }
        
        if(itemFolio.Nombre !== vale.Nombre)
        {
             _NombreChanged=1 ;
            console.log('Nombre..cambio',_NombreChanged=1)
        }
        if(itemFolio.Paterno !== vale.Paterno)
        {
            _PaternoChanged=1;
            console.log('Paterno..cambio')
        }
        if(itemFolio.Materno!== vale.Materno)
        {
             _MaternoChanged=1 ;
            console.log('Materno..cambio')
        }
        if(itemFolio.Calle !== vale.Calle)
        {
             _CalleChanged=1;
        }
        if(itemFolio.NumExt !== vale.NumExt)
        {
             _NumExtChanged=1;
            console.log('NumExt..cambio')
        }
        if(itemFolio.NumInt !== vale.NumInt)
        {
             _NumIntChanged=1;
            console.log('NumInt..cambio')
        }
        if(itemFolio.Colonia !== vale.Colonia)
        {
             _ColoniaChanged=1;
            console.log('Colonia..cambio')
        }
       
        if(itemFolio.idLocalidad.id !== vale.idLocalidad.id)
        {
            _idLocalidadChanged=1;
            console.log('idLocalidad..cambio')
        }
        if(itemFolio.idMunicipio.id !== vale.idMunicipio.id)
        {
            _idMunicipioChanged=1;
            console.log('idMunicipio..cambio')
        }
        if(itemFolio.UserOwned !== vale.idArticulador)
        {
             _UserOwnedchanged=1 ;
            console.log('UserOwned..cambio')
        }
        var _Documentacion={
            id : itemFolio.id,
            FolioSolicitud:itemFolio.ClaveUnica,
            Nombre: vale.Nombre,
            Paterno: vale.Paterno,
            Materno:vale.Materno,
            FechaNacimiento: vale.FechaNacimiento,
            Sexo: vale.Sexo,
            CURP: vale.CURP,
            idMunicipio: vale.idMunicipio.id,
            idLocalidad: vale.idLocalidad.id, 
            Calle:vale.Calle,
            Colonia: vale.Colonia,
            NumExt: vale.NumExt,
            NumInt:vale.NumInt,
            CP: vale.CP,
            CorreoElectronico:vale.CorreoElectronico,
            TelFijo:vale.TelFijo,
            TelRecados:vale.TelRecado,
            TelCelular:vale.TelCelular,
            CompaniaCelular:vale.CompaniaCelular,
            FechaSolicitud: vale.FechaSolicitud, 
            UserOwned: vale.idArticulador,
            
            idStatusDocumentacion: 3, //Si tiene un NO en check el estatus = 2=Cambiar Formato Solicitud
            TieneFolio: valesDocumentacion.tieneFolio?valesDocumentacion.tieneFolio:0,
            TieneFechaSolicitud: valesDocumentacion.TieneFechaSolicitud?valesDocumentacion.TieneFechaSolicitud:0,
            TieneCURPValida: valesDocumentacion.tieneCURP?valesDocumentacion.tieneCURP:0,
            NombreCoincideConINE: valesDocumentacion.coincideINE?valesDocumentacion.coincideINE:0,
            TieneDomicilio: valesDocumentacion.tieneDomicilio?valesDocumentacion.tieneDomicilio:0,
            TieneArticuladorReverso: valesDocumentacion.tieneArticulador?valesDocumentacion.tieneArticulador:0,
            FolioCoincideListado: valesDocumentacion.tieneFolio?valesDocumentacion.tieneFolio:0,
            CURPCoincideListado: valesDocumentacion.tieneCURP?valesDocumentacion.tieneCURP:0,
            FechaSolicitudChange:_FechaSolicitudChange,
            NombreChanged:_NombreChanged,
            PaternoChanged:_PaternoChanged,
            MaternoChanged:_MaternoChanged,
            CalleChanged:_CalleChanged,
            NumExtChanged:_NumExtChanged,
            ColoniaChanged:_ColoniaChanged,
            idLocalidadChanged:_idLocalidadChanged,
            idMunicipioChanged:_idMunicipioChanged,
            UserOwnedchanged:_UserOwnedchanged,
            NumIntChanged:_NumIntChanged
        };
        
        console.log('=>Paso2. Documentacion: ',JSON.stringify(_Documentacion));
        console.log('=>Paso2. Documentacion: ',_Documentacion);
        //Guardamos la documentacion
        console.log('=>itemFolio',itemFolio);

        EjecutarApi(_Documentacion, 'setValesDocumentacion', token.token)
            .then(function (res) {
                return res;
            })
            .then((res) => {
                setloading(false);
                setflagBtnGuardar(false);
                notify.show(
                    `Revisión de documento físico guardado Exitosamente`,
                    'custom',
                    5000,
                    {
                        fontSize: 20,
                        background: '#18c97d',
                        text: '#FFFFFF',
                    }
                );
               
            })
            .catch((e) => {
                console.log('=Error[getExport]: ', e);
                setloading(false);
                notify.show(
                    `Error al guardar la revisión del documento fisico: ${e.errors}`,
                    'custom',
                    5000,
                    {
                        background: '#0857B6',
                        fontSize: 20,
                        text: '#FFFFFF',
                    }
                );
    
            });


    }
    const alerta = (<ReactBSAlert
        style={{ display: "block", marginTop: "-100px" }}
        title={'Solicitud inscrita con la Clave Única : ' + itemFolio.ClaveUnica}
        onConfirm={() => setban(false)}
        
        confirmBtnBsStyle="info"
      />)

   
    const municipioChange = (mun) => {
        if (mun) {
            const datos_localidades = {
                page: 0,
                tipo: 'and',
                pageSize: 1000,
                sorted: [],
                filtered: [
                    {
                        "id": "IdMunicipio",
                        "value": mun
                    }
                ],
            };

            EjecutarApi(datos_localidades, 'getLocalidadET', token.token)
                .then(function (res) {
                    return res;
                })
                .then((res) => {
                    if (res.results) {

                        //setData(res.data);
                        setLocalidades(res.data.map((status_) => {
                            return {
                                "value": status_["Id"],
                                "label": status_["Nombre"]
                            }
                        }));
                    }
                })
                .catch((e) => {
                    notify.show(`Error en la busqueda! ${e.message}`, 'custom', 5000, {
                        background: '#d61818',
                        text: '#FFFFFF',
                    });
                });
        }
    }

   
    

    const getCURP = () => {
		setloading(true);
		console.log('=> Buscar CURP');
		if (!vale.CURP) {
            setloading(false);
            notify.show(`Error: CURP inválida!`, 'custom', 5000, {  background: '#d61818', text: '#FFFFFF',});
			return true;
		}

		if (vale.CURP.length != 18) {
			setloading(false);
            notify.show(`Error: CURP inválida!`, 'custom', 5000, {  background: '#d61818', text: '#FFFFFF',});
			return true;
		}

		const CURP = vale.CURP.toUpperCase();
        var sexo_obtenido;
		console.log('=> Buscar CURP: ', CURP);
        fetch(`https://seguimiento.guanajuato.gob.mx/apiinformacionsocial/api/renapo/porcurp/pL@t_1n|Run$28/${CURP}`)
        .then(res => res.json())
        .then((dataRS) => {
            setloading(false);
            console.log('=> Resultados CURP', dataRS);
            if (dataRS.Mensaje === 'OK') {
                //aqui
                var FN = dataRS.Resultado.fechNac.split('/');
                
                console.log('=FNAC: ', FN);//año-mes-dia
                console.log('@@@valores SEXO',dataRS.Resultado.sexo);
                sexo_obtenido = dataRS.Resultado.sexo;
                setVale({
                    ...vale,
                    Nombre: dataRS.Resultado.nombres,
                    Paterno: dataRS.Resultado.apellido1,
                    Materno: dataRS.Resultado.apellido2,
                    Sexo: dataRS.Resultado.sexo === "H" ? "M" : "F",// { value: "M", label:"MASCULINO"} : { value: "F", label:"FEMENINO"} , //{ label: dataRS.Resultado.sexo, value: dataRS.Resultado.sexo === 'H' ? 'M' : 'F' },//"M",//dataRS.Resultado.sexo === 'H' ? 'M' : 'F',
                    FechaNacimiento: FN[2] + '-' + FN[1] + '-' + FN[0],
                });
                console.log('=>valores CURP',vale);
            } else {
                setloading(false);
                if(dataRS.Mensaje === "La CURP no se encuentra en la base de datos")
                {
                    console.log("La CURP no se encuentra en la base de datos");
                    notify.show(`Error en CURP...! ${dataRS.Mensaje}`, 'custom', 5000, {
                        background: '#d61818',
                        text: '#FFFFFF',
                    });
                }
                else if(dataRS.Mensaje === "El campo CURP: No cumple con el formato especificado.")
                {
                    console.log("El campo CURP: No cumple con el formato especificado.");
                    notify.show(`Error en CURP...! ${dataRS.Mensaje}`, 'custom', 5000, {
                        background: '#d61818',
                        text: '#FFFFFF',
                    });
                }
                else{
                    console.log("El servicio no esta disponible.");
                }
                return true;
            }
            console.log('=> Resultados CURP', dataRS);
        })
        .catch((err) => {
            setloading(false);
            console.log('=>Error en CURO: ', err);
        });
        //
    };
             
    const mini_tableArticulador = (
        <>
            <ReactBSAlert
                style={{ display: "block", marginTop: "-250pt" }}
                //title="Here's a message!"
                onConfirm={() => setflagEscogerArticulador(false)}
                onCancel={() => setflagEscogerArticulador(false)}
                confirmBtnBsStyle="info"
            >
                <Card className="mb-2 text-muted">
                    <CardBody className="mb-2 text-muted">


                        <ReactTable
                            data={data}
                            noDataText="¡No existen datos a mostrar!"
                            columns={[
                                {
                                    Header: 'Articulador',
                                    minWidth: 10,
                                    id: 'idArticulador',
                                    accessor: (d) => d.Articulador,
                                    filterAll: false,
                                }
                            ]}
                            defaultPageSize={5}
                            filterable
                            className={"-striped -highlight primary-pagination"}
                            loading={loading}
                            showPagination={true}
                            showPageJump={false}
                            previousText="Anterior"
                            nextText="Siguiente"
                            canNextFromData={true}
                            manual
                            total={totalColonias}
                            pages={Math.floor(totalColonias / 10)}
                            pageSizeOptions={[10, 20, 25, 50, 100]}
                            getTrProps={(state, rowInfo, column, instance) => {
                                return {
                                    onClick: e => {
                                        console.log('@#@#@--- Datos', rowInfo.original.data);
                                        setVale({ ...vale, idArticulador: rowInfo.original.data.id })
                                        //setArticulador(rowInfo.original.data.Nombre !==null ? rowInfo.original.data.Nombre:'' + rowInfo.original.data.Paterno!== null ?rowInfo.original.data.Paterno:'' + rowInfo.original.data.Materno !== null?rowInfo.original.data.Materno:'');
                                        setArticulador(rowInfo.original.data.Nombre +" " + rowInfo.original.data.Paterno+ ' ' + rowInfo.original.data.Materno);
                                        setflagEscogerArticulador(false);

                                    }
                                    
                                }
                               
                            }}
                            onFetchData={(stateComp, instance) => {
                                setloading(true);
                                const token = getVars('Token').token;

                                var array_filtros = [];
                                array_filtros.push({ id: 'idTipoUser', value: 9 });
                                
                                //if (stateComp.filtered.length != 0) {
                                  //  array_filtros.push({ id: 'NombreCompleto', value: stateComp.filtered[0].value })
                                //}
                                const datax = {
                                    page: stateComp.page,
                                    tipo: 'and',
                                    pageSize: stateComp.pageSize,
                                    sorted: [
                                        { id: 'Nombre', desc: false }
                                    ],
                                    filtered: array_filtros,
                                    NombreCompleto: stateComp.filtered.length != 0?stateComp.filtered[0].value:''
                                };

                                EjecutarApi(datax, 'getUsersArticuladores', token)
                                    .then(function (res) {
                                        return res;
                                    })
                                    .then((res) => {
                                        setloading(false);
                                        setTotalColonias(res.total);
                                        setData(
                                            res.data.map((prop, key) => {

                                                return {
                                                    Articulador: `${prop.Nombre !==null ? prop.Nombre:'' } ${prop.Paterno!== null ?prop.Paterno:'' } ${prop.Materno !== null?prop.Materno:''}`,
                                                    data: prop,
                                                };
                                            })
                                        );
                                    })
                                    .catch((e) => {
                                        console.log('=Error[getArticuladores]: ', e);
                                        setloading(false);
                                        notify.show(
                                            `Error al obtener colonias: ${e.errors}`,
                                            'custom',
                                            5000,
                                            {
                                                background: '#0857B6',
                                                fontSize: 14,
                                                text: '#FFFFFF',
                                            }
                                        );
                                    });
                            }}
                        />
                    </CardBody>
                </Card>
            </ReactBSAlert>
        </>
    )      

    const mini_table = (
        <>
            <ReactBSAlert className="fontSize: 8px"
                style={{ display: "block", marginTop: "-250pt" }}
                //title="Here's a message!"
                onConfirm={() => setFlagEscogerColonia(false)}
                onCancel={() => setFlagEscogerColonia(false)}
                confirmBtnBsStyle="info"
            >
                <Card>
                    <CardBody>


                        <ReactTable className="-striped -highlight primary-pagination"
                            data={data}
                            noDataText="Debe Seleccionar un Municipio"
                            columns={[
                                {
                                    Header: 'Colonia',
                                    minWidth: 10,
                                    id: 'Colonia',
                                    accessor: (d) => d.Colonia,
                                    filterAll: false,
                                }
                            ]}
                            defaultPageSize={5}
                            filterable
                            
                            loading={loading}
                            showPagination={true}
                            showPageJump={false}
                            previousText="Anterior"
                            nextText="Siguiente"
                            canNextFromData={true}
                            manual
                            total={totalColonias}
                            pages={Math.floor(totalColonias / 10)}
                            pageSizeOptions={[10, 20, 25, 50, 100]}
                            getTrProps={(state, rowInfo, column, instance) => {
                                return {
                                    onClick: e => {
                                        setVale({ ...vale, Colonia: rowInfo.original.Colonia, CP: rowInfo.original.data.d_codigo })
                                        setFlagEscogerColonia(false);
                                    }
                                }
                            }}
                            onFetchData={(stateComp, instance) => {
                                setloading(true);
                                const token = getVars('Token').token;

                                var array_filtros = [];
                                array_filtros.push({ id: 'c_estado', value: 11 });
                                array_filtros.push({ id: 'c_mnpio', value: vale.idMunicipio });
                                if (stateComp.filtered.length !== 0) {
                                    array_filtros.push({ id: 'd_asenta', value: stateComp.filtered[0].value })
                                }
                                const datax = {
                                    page: stateComp.page,
                                    tipo: 'and',
                                    pageSize: stateComp.pageSize,
                                    sorted: [
                                        { id: 'd_asenta', desc: false }
                                    ],
                                    filtered: array_filtros
                                };

                                EjecutarApi(datax, 'getCP', token)
                                    .then(function (res) {
                                        return res;
                                    })
                                    .then((res) => {
                                        setloading(false);
                                        setTotalColonias(res.total);
                                        setData(
                                            res.data.map((prop, key) => {

                                                return {
                                                    Colonia: prop.d_asenta,
                                                    data: prop,
                                                };
                                            })
                                        );
                                    })
                                    .catch((e) => {
                                        console.log('=Error[getCP]: ', e);
                                        setloading(false);
                                        notify.show(
                                            `Error al obtener colonias: ${e.errors}`,
                                            'custom',
                                            5000,
                                            {
                                                background: '#0857B6',
                                                fontSize: 14,
                                                text: '#FFFFFF',
                                            }
                                        );
                                    });
                            }}
                        />
                    </CardBody>
                </Card>
            </ReactBSAlert>
        </>
    )
               

    //console.log('@#@#@--- Datos', optcompania[0].label);
    return (
        <>
            <div className="wrapper">
                <Sidebar {...props} bgColor="black" activeColor="info" />
                <div className="main-panel">
                    <Navbar {...props} handleMiniClick={handleMiniClick} />
                    <Notifications />
                    <div className="content">

                        <Row>
                            <Col md="12">
                                <Card>
                                    <CardHeader>
                                        <Link to="/gruposdocpaso1">
                                            <Button color="success">
                                                Regresar
                                            </Button>
                                        </Link>
                                    </CardHeader>
                                    <CardBody>
                                        <Form className="form-horizontal" id="TypeValidation">
                                            <Notifications />
                                            <Card>
                                                <CardHeader>
                                                    <CardTitle tag="h4">Inscripción de Vales</CardTitle>
                                                </CardHeader>

                                                <CardBody>
                                                    <CardTitle tag="h6">Articulador</CardTitle>
                                                    <Row>
                                                        
                                                        <Col sm="4">
                                                            <FormGroup >
                                                                <Input id="articu" type="text" value={vale.Articulador} disabled    />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col sm="2">
                                                            <FormGroup >
                                                            <Button
                                                                    onClick={() => {
                                                                        setflagEscogerArticulador(true)
                                                                        console.log("ONCLIK")
                                                                    }

                                                                    }>Buscar</Button>
                                                                {(flagEscogerArticulador) ?
                                                                    mini_tableArticulador
                                                                    :
                                                                    <></>
                                                                }
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    
                                                     <h6>Datos del Solicitante</h6>

                                                    <Row>
                                                        <Label sm="2">Fecha de Solicitud:<span className="text-danger">*</span></Label>
                                                        <Col sm="8">

                                                            <FormGroup>
                                                                <DateTimeComponent
                                                                    id="FechaSolicitud"
                                                                    name="FechaSolicitud"
                                                                    dateFormat="YYYY-MM-DD"
                                                                    maxDate={new Date("2020-12-31")}
                                                                    minDate={new Date("2020-05-01")}
                                                                    timeFormat={false}
                                                                    closeOnSelect={true}
                                                                    onChange={e => setVale({ ...vale, FechaSolicitud: moment(e._d).format('YYYY-MM-DD') })}
                                                                    inputProps={{ className: "form-control" }}
                                                                    defaultValue={vale.FechaSolicitud}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        
                                                    </Row>
                                                    <Row>
                                                        <Label sm="2">CURP:</Label>
                                                        <Col sm="4">
                                                            <FormGroup>

                                                                <Input
                                                                    maxLength={18}
                                                                    name="CURP"
                                                                    type="text"
                                                                    value={vale.CURP}
                                                                    onChange={event => setVale({ ...vale, CURP: event.target.value.toUpperCase() })}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Col sm="2">
                                                            <FormGroup >
                                                                <Button color="primary" onClick={getCURP}>
                                                                    {loading? <ImpulseSpinner color={'#1261ff'} size={30} loading={loading} />:''}

                                                                    {loading ? <span>Validando</span> : <span>Validar</span>} 
                                                                </Button>
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Label sm="2">Nombre:<span className="text-danger">*</span></Label>
                                                        <Col sm="6">
                                                            <FormGroup >
                                                                 <Input
                                                                    name="nombre"
                                                                    type="text"
                                                                    value={vale.Nombre}
                                                                    onChange={event => setVale({ ...vale, Nombre: event.target.value.toUpperCase() })}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Label sm="2">Apellido Paterno:</Label>
                                                        <Col sm="6">
                                                            <FormGroup>
                                                                <Input
                                                                    name="paterno"
                                                                    type="text"
                                                                    value={vale.Paterno}
                                                                    onChange={event => setVale({ ...vale, Paterno: event.target.value.toUpperCase() })}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Label sm="2">Apellido Materno:</Label>
                                                        <Col sm="6">
                                                            <FormGroup>

                                                                <Input
                                                                    name="materno"
                                                                    type="text"
                                                                    value={vale.Materno}
                                                                    onChange={event => setVale({ ...vale, Materno: event.target.value.toUpperCase() })}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Label sm="2">Sexo:</Label>
                                                        <Col sm="6">
                                                        
                                                        <FormGroup>
                                                            <select
                                                                defaultValue={vale.Sexo}
                                                                onChange={(e)=>setVale({ ...vale, Sexo: e.target.value })}
                                                                className="browser-default custom-select">
                                                                <option value="F">FEMENINO</option>
                                                                <option value="M">MASCULINO</option>
                                                            </select>
                                                        </FormGroup>
                                                        </Col>
                                                         
                                                    </Row>
                                                    <Row>
                                                        <Label sm="2">Fecha de Nacimiento:<span className="text-danger">*</span></Label>
                                                            <Col sm="4">
                                                                <FormGroup >
                                                                    <ReactDatetime 
                                                                        id="FechaNacimiento"
                                                                        name="FechaNacimiento"
                                                                        dateFormat="YYYY-MM-DD"
                                                                        timeFormat={false}
                                                                        value={vale.FechaNacimiento}
                                                                        closeOnSelect
                                                                        onChange={e => setVale({ ...vale, FechaNacimiento: moment(e._d).format('YYYY-MM-DD') })}
                                                                        inputProps={{ className: "form-control" }}
                                                                        
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                    </Row>      

                                                    <h6>Datos de Ubicación Geográfica</h6>            

                                                    <Row>
                                                        {
                                                            (municipios.length !== 0) ? (
                                                                <>
                                                                    <Label sm="2">Municipio:<span className="text-danger">*</span></Label>
                                                                    <Col sm="6">
                                                                        <FormGroup >
                                                                            <Select
                                                                                className="react-select primary"
                                                                                classNamePrefix="react-select"
                                                                                id="municipio"
                                                                                defaultValue={{ value: vale.idMunicipio.id, label: vale.idMunicipio.Municipio}}
                                                                                onChange={event => {
                                                                                    setVale({ ...vale, idMunicipio: event.value })
                                                                                    setNomMunicipio(event.value)
                                                                                    municipioChange(event.value)
                                                                                }}
                                                                                options={municipios}
                                                                                placeholder="seleccione su municipio" />
                                                                        </FormGroup>
                                                                    </Col>
                                                                </>
                                                            ) : (<>
                                                                <Col sm="2" style={Spinenerstyle}>
                                                                    <RotateSpinner color={'#1261ff'} size={30} loading={true} />
                                                                </Col>
                                                            </>)
                                                        }
                                                    </Row>
                                                    <Row>
                                                        <Label sm="2">Calle:<span className="text-danger">*</span></Label>
                                                        <Col sm="6">
                                                            <FormGroup >
                                                                <Input
                                                                    id="calle"
                                                                    type="text"
                                                                    value={vale.Calle}
                                                                    onChange={event => setVale({ ...vale, Calle: event.target.value })}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Label sm="2">Número Exterior:<span className="text-danger">*</span></Label>
                                                        <Col sm="2">
                                                            <FormGroup >
                                                                <Input
                                                                    id="numExt"
                                                                    type="text"
                                                                    value={vale.NumExt}
                                                                    onChange={event => setVale({ ...vale, NumExt: event.target.value })}
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Label sm="2">Número Interior</Label>
                                                        <Col sm="2">
                                                            <FormGroup >
                                                                <Input
                                                                    id="numInt222"
                                                                    type="text"
                                                                    value={vale.NumInt}
                                                                    onChange={event => setVale({ ...vale, NumInt: event.target.value })}
                                                                />
                                                            </FormGroup>
                                                            
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Label sm="2">Colonia:<span className="text-danger">*</span></Label>
                                                            <Col sm="4">
                                                                <FormGroup >
                                                                    <Input
                                                                        name="Colonia"
                                                                        type="text"
                                                                        value={vale.Colonia}
                                                                        onChange={event => setVale({ ...vale, Colonia: event.target.value })}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                            <Col sm="2">
                                                                <Button
                                                                    onClick={() => {
                                                                        setFlagEscogerColonia(true)
                                                                        console.log("ONCLIK")
                                                                    }

                                                                    }>BUSCAR</Button>
                                                                {(flagEscogerColonia) ?
                                                                    mini_table
                                                                    :
                                                                    <></>
                                                                }
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                        <Label sm="2">Localidad:<span className="text-danger">*</span></Label>
                                                        <Col sm="6">
                                                        <FormGroup >
                                                            <Select
                                                                className="react-select primary"
                                                                classNamePrefix="react-select"
                                                                id="Localidad"
                                                                defaultValue={{ value: vale.idLocalidad, label: vale.idLocalidad.Nombre}}
                                                                onChange={event => {
                                                                    setVale({ ...vale, idLocalidad: event.value })
                                                                }}
                                                                options={localidades}
                                                                placeholder="seleccione su localidad" />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Label sm="2">Codigo Postal:<span className="text-danger">*</span></Label>
                                                        <Col sm="4">
                                                            <FormGroup >
                                                                <Input
                                                                    name="cp"
                                                                    type="number"
                                                                    value={vale.CP}
                                                                    onChange={event => setVale({ ...vale, CP: event.target.value })}
                                                                    
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        
                                                    </Row>
                                                    
                                                    
                                                    <CardTitle tag="h6">Datos del Contacto</CardTitle>
                                                    <Row>
                                                        <Label sm="2">Correo:</Label>
                                                        <Col sm="6">
                                                            <FormGroup>

                                                                <Input
                                                                    name="correo"
                                                                    type="email"
                                                                    value={vale.CorreoElectronico}
                                                                    onChange={event => setVale({ ...vale, CorreoElectronico: event.target.value })}
                                                                    
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Label sm="2">Teléfono Fijo:</Label>
                                                        <Col sm="4">
                                                            <FormGroup >
                                                                <Input
                                                                    max="999999999"
                                                                    id="TelFijo"
                                                                    type="number"
                                                                    onChange={event => setVale({ ...vale, TelFijo: event.target.value })}
                                                                    value={vale.TelFijo}
                                                                    
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Label sm="2">Teléfono Recados:</Label>
                                                        <Col sm="4">
                                                            <FormGroup>
                                                                <Input
                                                                    
                                                                    maxLength={10}
                                                                    id="telRecados"
                                                                    type="number"
                                                                    onChange={event => setVale({ ...vale, TelRecado: event.target.value })}
                                                                    value={vale.TelRecado}
                                                                    
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                   </Row>
                                                   <Row>
                                                        <Label sm="2">Teléfono Celular:<span className="text-danger">*</span></Label>
                                                        <Col sm="4">
                                                            <FormGroup >
                                                                <Input
                                                                    max="999999999"
                                                                    min="1"
                                                                    id="celular"
                                                                    type="number"
                                                                    onChange={event => setVale({ ...vale, TelCelular: event.target.value })}
                                                                    value={vale.TelCelular}
                                                                    
                                                                />
                                                            </FormGroup>
                                                        </Col>
                                                        <Label sm="2">Compañía Celular:<span className="text-danger">*</span></Label>
                                                        <Col sm="4">
                                                                <FormGroup >
                                                                <select
                                                                    defaultValue={vale.CompaniaCelular}
                                                                    onChange={(e)=>setVale({ ...vale, CompaniaCelular: e.target.value })}
                                                                    className="browser-default custom-select">
                                                                    <option value="Telcel">Telcel</option>
                                                                    <option value="Iusacell">Iusacell</option>
                                                                    <option value="Movistar">Movistar</option>
                                                                    <option value="at&t">at&t</option>
                                                                    <option value="Unefon">Unefon</option>
                                                                </select>
                                                                   
                                                                </FormGroup>
                                                        </Col>
                                                    </Row>
                                                  
        
                                                </CardBody>
                                                <CardFooter className="text-center">
                                                    {loading && (
                                                        <CircleSpinner
                                                            className="text-center"
                                                            size={30}
                                                            color="#686769"
                                                            loading={loading}
                                                        />
                                                    )}
                                                    {flagBtnGuardar?( 
                                                        <Button color="primary" onClick={handleGuardar}> Guardar </Button>
                                                    ):( <></>)
                                                    }
                                                </CardFooter>
                                            </Card>
                                        </Form >
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                    {ban ? alerta : <> </>}
                    <Footer fluid />
                </div>
            </div>
        </>
    )

}
export default GruposDocPaso2;