import React from "react";
import {
  Button,
  Col,
  FormGroup,
  Input,
  ListGroup,
  ListGroupItem,
  Row,
} from "reactstrap";
import swal from "sweetalert";

const ConciliacionArchivos = ({
  files,
  handleChangeArchivos,
  handleDeleteFile,
  onlyForView,
  fullscreen = false,
}) => {
  const handleSelectItems = (newFiles) => {
    if (newFiles.length !== 1) {
      swal({
        title: "¡Atención!",
        text: `Solo puede cargar un archivo, por favor intente nuevamente`,
        icon: "warning",
      });
      return;
    }
    handleChangeArchivos(files.concat(Array.from(newFiles)));
  };

  const deleteFile = (index) => {
    let filesAux = [...files];
    filesAux.splice(index, 1);
    handleDeleteFile(filesAux);
  };

  return (
    <Row style={{ width: "100%" }}>
      <Col xs="12">
        <ListGroup
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
          }}
        >
          {" "}
          {files.map((file, index) => (
            <>
              <ListGroupItem
                key={index}
                style={{ width: fullscreen ? "100%" : "80%" }}
              >
                <Row>
                  <Col
                    sm="4"
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <i
                      class="fa fa-file-excel-o fa-4x"
                      aria-hidden="true"
                      style={{ color: "green", alignItems: "center" }}
                    ></i>
                  </Col>
                  <Col
                    sm="5"
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    {" "}
                    {file && file.name}
                  </Col>
                  <Col sm="1">
                    {!onlyForView && (
                      <Button
                        color="danger"
                        outline
                        onClick={() => deleteFile(index)}
                      >
                        <span className="btn-label">
                          <i className="fa fa-trash fa-xs"></i>
                        </span>
                      </Button>
                    )}
                  </Col>
                </Row>
              </ListGroupItem>
            </>
          ))}
        </ListGroup>
      </Col>
      {!onlyForView && (
        <Col xs="12" style={{ display: "flex", justifyContent: "center" }}>
          <FormGroup>
            <Input
              id="exampleFile"
              name="file"
              type="file"
              // style={{display: "none"}}
              style={{ cursor: "pointer" }}
              multiple
              onChange={(e) => handleSelectItems(e.target.files)}
            />
            {files.length < 1 && (
              <Button color="primary" onClick={() => {}}>
                Seleccionar
              </Button>
            )}
          </FormGroup>
        </Col>
      )}
    </Row>
  );
};

export default ConciliacionArchivos;
