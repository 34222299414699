import React, { useState, useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
  Form,
  FormGroup,
  Input,
  Label,
} from "reactstrap";
import swal from "sweetalert";
import { CircleSpinner } from "react-spinners-kit";
import EjecutarApi from "../Consultas/Consultas";
import Notifications from "react-notify-toast";
import { getVars } from "../GlobalStorage";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Sidebar from "../components/Sidebar";
import { URL_GENERAL } from "../data/consts";
import { isNullOrUndefined } from "../utils/emptyValidations";

const RmpAcuse = (props) => {
  const [userData, setUserData] = useState();
  const [menu, setMenu] = useState({
    Ver: 0,
    Agregar: 0,
    Editar: 0,
    Eliminar: 0,
    Seguimiento: 0,
    Exportar: 0,
    Imprimir: 0,
    ViewAll: 0,
    idTipoUser: 0,
  });
  const history = useHistory();
  const [menuLoaded, setMenuLoaded] = useState(false);
  const [folio, setFolio] = useState({
    Folio: "",
    CURP: "",
  });
  const [loading, setLoading] = useState(false);
  const [loadingCedulaList, setLoadingCedulaList] = useState(false);
  const [apoyos, setApoyos] = useState([]);
  const [ejercicio, setEjercicio] = useState(2023);

  useEffect(() => {
    if (isNullOrUndefined(userData)) return;
    const menus = userData.menu;
    const currentMenu = menus.find((menu) => menu.idMenu === 25);
    if (isNullOrUndefined(currentMenu) || currentMenu.Ver < 1) {
      swal({
        title: "Atención",
        text: "No tiene permisos para este módulo, contacte al administrador",
        icon: "warning",
      });
      history.push("/not-found");
      return;
    }
    setMenu({
      Ver: currentMenu.Ver,
      Agregar: currentMenu.Agregar,
      Editar: currentMenu.Editar,
      Eliminar: currentMenu.Eliminar,
      Seguimiento: currentMenu.Seguimiento,
      Exportar: currentMenu.Exportar,
      Imprimir: currentMenu.Imprimir,
      ViewAll: currentMenu.ViewAll,
      idTipoUser: userData.user.idTipoUser.id,
    });
    setMenuLoaded(true);
  }, [userData]);

  const handleMiniClick = () => {
    document.body.classList.toggle("sidebar-mini");
  };

  useEffect(() => {
    setUserData(getVars("Token"));
  }, []);

  const validateRegex = (curp) => {
    const re = /^[0-9A-F]+$/gi;
    return curp.match(re) ? true : false;
  };

  const btnDescargar = (folio) => {
    console.log(folio);
    const token = getVars("Token");
    var url = new URL(
      URL_GENERAL +
        `getAcuseUnico?folio=${folio.id}&token=${token.token}&ejercicio=${folio.Ejercicio}`
    );
    window.open(url, "_blank");
    swal({
      title: "Descarga completa",
      text: "Se genero correctamente el acuse",
      icon: "success",
    });
  };

  const btnDescargarSol = (folio) => {
    console.log(folio);
    const token = getVars("Token");
    var url = new URL(
      URL_GENERAL +
        `vales/getSolicitudesValeUnico?folio=${folio.id}&token=${token.token}`
    );
    window.open(url, "_blank");
    swal({
      title: "Descarga completa",
      text: "Se genero correctamente la solicitud",
      icon: "success",
    });
  };

  const btnBuscar = (e) => {
    setLoading(true);
    if (folio.Folio.length < 1 && folio.CURP.length < 1) {
      setLoading(false);

      swal({
        title: "!Atención!",
        text: "Debe agregar un folio válido",
        icon: "warning",
      });
      return;
    }

    if (!validateRegex(folio.Folio)) {
      setLoading(false);
      swal({
        title: "!Atención!",
        text: "Debe agregar un folio válido",
        icon: "warning",
      });
      return;
    }

    const token = getVars("Token");
    const data = { folio };

    EjecutarApi(data, "getAcuse", token.token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.success && res.results) {
          if (res.data.length === 0) {
            swal({
              title: "!Atención!",
              text: res.message,
              icon: "warning",
            });
          }
          setApoyos(res.data);
          setEjercicio(res.ejercicio);
        } else {
          swal({
            title: "!Atención!",
            text: res.message,
            icon: "warning",
          });
        }
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
        swal({
          title: "Ha ocurrido un error",
          text: "Contacte al administrador",
          icon: "warning",
        });
      });
  };

  return (
    <>
      <div className="wrapper">
        <Sidebar {...props} bgColor="black" activeColor="info" />
        <div className="main-panel">
          <Navbar {...props} handleMiniClick={handleMiniClick} />
          <Notifications />
          <div className="content">
            <>
              <Row>
                <Col md="6">
                  <Card>
                    <CardBody>
                      <Row>
                        <Col sm="12">
                          <Label> Buscar por folio de solicitud</Label>
                          <Input
                            id="Folio"
                            type="text"
                            placeholder={`Escriba el Folio`}
                            value={folio.Folio}
                            maxLength={6}
                            style={{ textTransform: "uppercase" }}
                            onChange={(event) => {
                              setApoyos([]);
                              setFolio({
                                ...folio,
                                Folio: event.target.value.toUpperCase(),
                              });
                            }}
                          />
                        </Col>
                      </Row>
                      {/* <Row>
                        <Col sm="12">
                          <Label> Buscar por CURP del Beneficiario</Label>
                          <Input
                            id="CURP"
                            type="text"
                            placeholder={`Escriba el CURP`}
                            value={folio.CURP}
                            onChange={(event) =>
                              setFolio({
                                ...folio,
                                CURP: event.target.value.toUpperCase(),
                              })
                            }
                          />
                        </Col>
                      </Row> */}
                      <Row>
                        <Col sm={{ sm: 4, offset: 8 }}>
                          <Button
                            color="primary"
                            onClick={() => {
                              btnBuscar();
                            }}
                          >
                            {loading ? (
                              <span>Buscando..</span>
                            ) : (
                              <span>Buscar</span>
                            )}
                          </Button>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
                <Col md="6">
                  {apoyos.length > 0 && (
                    <>
                      {apoyos.map((item) => (
                        <Card sm="12">
                          <CardHeader>
                            <Row>
                              <Col sm="12">
                                {" "}
                                <CardTitle tag="h6">
                                  Información del beneficiario
                                </CardTitle>
                              </Col>
                            </Row>
                          </CardHeader>
                          <CardBody>
                            <Row>
                              <Col sm="3">
                                <Label>Folio:</Label>
                              </Col>
                              <Col sm="3">{item.Folio}</Col>
                              <Col sm="3">
                                <Label>Remesa:</Label>
                              </Col>
                              <Col sm="3">{item.RemesaSistema}</Col>
                            </Row>
                            <Row>
                              <Col sm="3">
                                <Label>Nombre:</Label>
                              </Col>
                              <Col sm="9">{item.nombre}</Col>
                            </Row>
                            <Row>
                              <Col sm="3">
                                <Label>CURP:</Label>
                              </Col>
                              <Col sm="9">{item.curp}</Col>
                            </Row>
                            <Row>
                              <Col sm="3">
                                <Label>Domicilio:</Label>
                              </Col>
                              <Col sm="9">{item.domicilio}</Col>
                            </Row>
                            <Row>
                              <Col sm="3">
                                <Label>Municipio:</Label>
                              </Col>
                              <Col sm="9">{item.municipio}</Col>
                            </Row>
                            <Row>
                              <Col sm="3">
                                <Label>Localidad:</Label>
                              </Col>
                              <Col sm="9">{item.localidad}</Col>
                            </Row>
                            <Row>
                              <Col sm="3">
                                <Label>Colonia:</Label>
                              </Col>
                              <Col sm="9">{item.colonia}</Col>
                            </Row>
                            <Row>
                              <Col sm="3">
                                <Label>Folio inicial:</Label>
                              </Col>
                              <Col sm="3">{item.folioinicial}</Col>
                              <Col sm="3">
                                <Label>Folio final:</Label>
                              </Col>
                              <Col sm="3">{item.foliofinal}</Col>
                            </Row>
                            <Row>
                              <Col sm={{ sm: 3, offset: 3 }}>
                                <Button
                                  color="warning"
                                  onClick={() => {
                                    btnDescargar(item);
                                  }}
                                >
                                  Generar Acuse
                                </Button>
                              </Col>
                              {item.Ejercicio !== 2022 && (
                                <Col sm={{ size: 5 }}>
                                  <Button
                                    color="info"
                                    onClick={() => {
                                      btnDescargarSol(item);
                                    }}
                                  >
                                    Generar Sol
                                  </Button>
                                </Col>
                              )}
                            </Row>
                          </CardBody>
                        </Card>
                      ))}
                    </>
                  )}
                </Col>
              </Row>
              <Footer fluid />
            </>
          </div>
        </div>
      </div>
    </>
  );
};
export default RmpAcuse;
