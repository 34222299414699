import React, { useState, useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col,
  Spinner,
  FormGroup,
  Input,
  Label,
  Table,
} from "reactstrap";
import ReactTable from "react-table";
import Notifications from "react-notify-toast";
import swal from "sweetalert";
import EjecutarApi from "../../Consultas/Consultas";
import { getVars } from "../../GlobalStorage";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import Sidebar from "../../components/Sidebar";
import Pagination from "../../components/pagination";
import { isNullOrUndefined } from "../../utils/emptyValidations";
import { URL_GENERAL } from "../../data/consts";

const ValesDevueltos = (props) => {
  const [userData, setUserData] = useState();
  const [menu, setMenu] = useState({
    Ver: 0,
    Agregar: 0,
    Editar: 0,
    Eliminar: 0,
    Seguimiento: 0,
    Exportar: 0,
    Imprimir: 0,
    ViewAll: 0,
    idTipoUser: 0,
  });
  const history = useHistory();
  const [menuLoaded, setMenuLoaded] = useState(false);
  const [devueltos, setDevueltos] = useState([]);
  const [catRemesa, setCatRemesa] = useState([]);
  const [catMunicipio, setCatMunicipio] = useState([]);
  const [catListado, setCatListado] = useState([]);
  const [folioApi, setFolioApi] = useState("");
  const [serieVale, setSerieVale] = useState("");
  const [remesa, setRemesa] = useState("");
  const [municipio, setMunicipio] = useState("");
  const [listado, setListado] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadingRemesa, setLoadingRemesa] = useState(false);
  const [loadingMunicipio, setLoadingMunicipio] = useState(false);
  const [loadingListado, setLoadingListado] = useState(false);
  const [listadoVales, setListadoVales] = useState([]);
  const [loadingDevueltos, setLoadingDevueltos] = useState(false);
  const [filtro, setFiltro] = useState([]);
  const [total, setTotal] = useState(0);
  const [idCarga, setIdCarga] = useState(0);
  const [pageOptions, setPage] = useState({
    page: 0,
    pageSize: 10,
  });
  const columns = [
    {
      Header: "Remesa",
      headerClassName: "text-center",
      minWidth: 70,
      id: "Remesa",
      accessor: (d) => d.Remesa,
      filterAll: false,
      style: { fontSize: "10px" },
      headerStyle: {
        fontSize: "10px",
        background: "#74c8cc",
        color: "white",
        fontWeight: "bold",
      },
    },
    {
      Header: "Folio",
      headerClassName: "text-center",
      minWidth: 70,
      id: "Folio",
      accessor: (d) => d.FolioSolicitud,
      className: "text-center",
      filterAll: false,
      style: { fontSize: "10px" },
      headerStyle: {
        fontSize: "10px",
        background: "#74c8cc",
        color: "white",
        fontWeight: "bold",
      },
    },
    {
      Header: "CURP",
      headerClassName: "text-center",
      minWidth: 130,
      id: "CURP",
      accessor: (d) => d.CURP,
      filterAll: false,
      style: { fontSize: "10px" },
      headerStyle: {
        fontSize: "10px",
        background: "#74c8cc",
        color: "white",
        fontWeight: "bold",
      },
    },
    {
      Header: "Nombre Completo",
      headerClassName: "text-center",
      minWidth: 200,
      id: "NombreCompleto",
      accessor: (d) => d.NombreCompleto,
      filterAll: false,
      style: { fontSize: "10px" },
      headerStyle: {
        fontSize: "10px",
        background: "#74c8cc",
        color: "white",
        fontWeight: "bold",
      },
    },
    {
      Header: "S. Inicial",
      headerClassName: "text-center",
      minWidth: 80,
      id: "SerieInicial",
      accessor: (d) => d.SerieInicial,
      filterAll: false,
      style: { fontSize: "10px" },
      headerStyle: {
        fontSize: "10px",
        background: "#74c8cc",
        color: "white",
        fontWeight: "bold",
      },
    },
    {
      Header: "S. Final",
      headerClassName: "text-center",
      minWidth: 80,
      id: "SerieFinal",
      accessor: (d) => d.SerieFinal,
      filterAll: false,
      style: { fontSize: "10px" },
      headerStyle: {
        fontSize: "10px",
        background: "#74c8cc",
        color: "white",
        fontWeight: "bold",
      },
    },
  ];

  useEffect(() => {
    setUserData(getVars("Token"));
  }, []);

  const handleMiniClick = () => {
    document.body.classList.toggle("sidebar-mini");
  };

  useEffect(() => {
    if (isNullOrUndefined(userData)) return;
    const menus = userData.menu;
    const currentMenu = menus.find((menu) => menu.idMenu === 35);
    if (isNullOrUndefined(currentMenu) || currentMenu.Ver < 1) {
      swal({
        title: "Atención",
        text: "No tiene permisos para este módulo, contacte al administrador",
        icon: "warning",
      });
      //   history.push("/not-found");
      return;
    }
    setMenu({
      Ver: currentMenu.Ver,
      Agregar: currentMenu.Agregar,
      Editar: currentMenu.Editar,
      Eliminar: currentMenu.Eliminar,
      Seguimiento: currentMenu.Seguimiento,
      Exportar: currentMenu.Exportar,
      Imprimir: currentMenu.Imprimir,
      ViewAll: currentMenu.ViewAll,
    });
    setMenuLoaded(true);
  }, [userData]);

  const validateHex = (curp) => {
    const re = /^[0-9A-F]+$/gi;
    return curp.match(re) ? true : false;
  };

  const fetchData = useCallback(({ pageSize, page }) => {
    getRegistros(pageSize, page);
  }, []);

  useEffect(() => {
    setLoadingRemesa(true);
    setLoadingMunicipio(true);
    const token = getVars("Token");
    EjecutarApi({}, "getCatGrupos2023", token.token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        setCatRemesa(
          res.data.Remesas.map((item) => {
            return <option value={item}>{item}</option>;
          })
        );
        setCatMunicipio(
          res.data.Municipios.map((item) => {
            return <option value={item}>{item}</option>;
          })
        );
        setLoadingRemesa(false);
        setLoadingMunicipio(false);
      })
      .catch((e) => {
        swal({
          title: "¡Atención!",
          text: `Error al obtener Grupos: ${e.message}`,
          icon: "warning",
        });
        setLoadingRemesa(false);
        setLoadingMunicipio(false);
      });
  }, []);

  const getListadosVales = (municipio) => {
    setLoadingListado(true);
    if (remesa === "") {
      swal({
        title: "¡Atención!",
        text: `Debe seleccionar la remesa`,
        icon: "warning",
      });
      setLoadingListado(false);
      return;
    }

    if (municipio === "") {
      swal({
        title: "¡Atención!",
        text: `Debe seleccionar el municipio`,
        icon: "warning",
      });
      setLoadingListado(false);
      return;
    }

    const token = getVars("Token");
    const data = {
      remesa: remesa,
      municipio: municipio,
    };
    EjecutarApi(data, "getListados2023", token.token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.results && res.success) {
          setCatListado(
            res.data.Listados.map((item) => {
              return <option value={item.idGrupo}>{item.Listado}</option>;
            })
          );
        } else {
          swal({
            title: "¡Atención!",
            text: `${res.errors}`,
            icon: "info",
          });
        }
        setLoadingListado(false);
      })
      .catch((e) => {
        setLoadingListado(false);
        swal({
          title: "¡Atención!",
          text: `${e.message}`,
          icon: "warning",
        });
      });
  };
  const getRegistros = (pageSize, page) => {
    const data = {
      page: page > pageOptions.page ? page : pageOptions.page,
      pageSize: pageSize,
      filtered: filtro,
    };
    setPage({
      page: page,
      pageSize: pageSize,
    });
    const token = getVars("Token");
    setLoading(true);
    EjecutarApi(data, "vales/getListadoVales2023", token.token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.results && res.success) {
          setListadoVales(
            res.data.map((item) => {
              if (item.Paterno == null) item.Paterno = "";
              if (item.Materno == null) item.Materno = "";
              return {
                ...item,
                NombreCompleto: `${item.Nombre} ${item.Paterno} ${item.Materno}`,
              };
            })
          );
          setTotal(res.total);
        } else {
          swal({
            title: "¡Atención!",
            text: `${res.message}`,
            icon: "info",
          });
        }
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        swal({
          title: "¡Atención!",
          text: `${e.message}`,
          icon: "warning",
        });
      });
  };

  const getRegistrosFilter = () => {
    if (!folioApi && !serieVale && listado === 0) {
      swal({
        title: "¡Atención!",
        text: `Debe seleccionar un campo para filtrar`,
        icon: "warning",
      });
      setLoadingListado(false);
      return;
    }

    let filtros = [];

    if (folioApi) {
      setFiltro({ ...filtro }, { id: ".id", value: folioApi });
      filtros.push({ id: ".id", value: folioApi });
    }

    if (serieVale) {
      if (serieVale.length < 10) {
        swal({
          title: "¡Atención!",
          text: `El codigo de barras del vale es incorrecto`,
          icon: "warning",
        });
        setLoadingListado(false);
        return;
      }
      setFiltro(
        { ...filtro },
        { id: ".CodigoBarrasInicial", value: serieVale }
      );
      filtros.push({ id: ".CodigoBarrasInicial", value: serieVale });
    }

    if (listado > 0) {
      setFiltro({ ...filtro }, { id: ".idGrupo", value: listado });
      filtros.push({ id: ".idGrupo", value: listado });
    }

    const token = getVars("Token");
    const data = {
      page: pageOptions.page ? pageOptions.page : 0,
      pageSize: pageOptions.pageSize ? pageOptions.pageSize : 10,
      filtered: filtros,
    };
    setLoading(true);
    EjecutarApi(data, "vales/getListadoVales2023", token.token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.results && res.success) {
          setListadoVales(
            res.data.map((item) => {
              if (item.Paterno == null) item.Paterno = "";
              if (item.Materno == null) item.Materno = "";
              return {
                ...item,
                NombreCompleto: `${item.Nombre} ${item.Paterno} ${item.Materno}`,
              };
            })
          );
          setTotal(res.total);
        } else {
          swal({
            title: "¡Atención!",
            text: `${res.message}`,
            icon: "info",
          });
        }
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        swal({
          title: "¡Atención!",
          text: `${e.message}`,
          icon: "warning",
        });
      });
  };

  const devolverVales = () => {
    setLoadingDevueltos(true);
    if (devueltos.length === 0) {
      swal({
        title: "¡Atención!",
        text: `Debe seleccionar las valeras a devolver`,
        icon: "warning",
      });
      setLoadingDevueltos(false);
      return;
    }
    const token = getVars("Token");
    const data = {
      devueltos,
    };
    EjecutarApi(data, "vales/valesIncidencia", token.token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.results && res.success) {
          swal({
            title: "Éxito!",
            text: `${res.message}`,
            icon: "success",
          });
          setIdCarga(res.idCarga);
          setDevueltos([]);
          setRemesa("");
          setFolioApi("");
          setSerieVale("");
          setLoadingDevueltos(false);
          getRegistros(10, 0);
        } else {
          swal({
            title: "¡Atención!",
            text: `${res.message}`,
            icon: "info",
          });
          setLoadingDevueltos(false);
        }
      })
      .catch((e) => {
        swal({
          title: "¡Atención!",
          text: `${e.message}`,
          icon: "warning",
        });
        setLoadingDevueltos(false);
      });
  };

  const handleDeleteFolio = (item, index) => {
    swal({
      title: `¿Está seguro que desea eliminar el Folio: ${item.FolioSolicitud}?`,
      icon: "warning",
      allowEscapeKey: false,
      allowOutsideClick: false,
      confirmButtonText: "Sí, Eliminar",
      buttons: true,
      dangerMode: true,
    }).then((res) => {
      if (res) {
        let newData = devueltos.filter((item, j) => j !== index);
        setDevueltos(newData);
        swal({
          title: "¡Eliminado con éxito!",
          icon: "success",
        });
      }
    });
  };

  const btnExportar = () => {
    const token = getVars("Token");
    let url = new URL(
      URL_GENERAL + `getReporteDevueltos?token=${token.token}&Carga=${idCarga}`
    );
    window.open(url, "_blank");
  };

  const style = {
    table: {
      width: "100%",
      display: "table",
      borderSpacing: 0,
      borderCollapse: "separate",
      fontSize: "8px",
    },
    th: {
      top: 0,
      left: 0,
      zIndex: 2,
      position: "sticky",
      backgroundColor: "#fff",
      fontSize: "10px",
      background: "#74c8cc",
      color: "white",
      fontWeight: "bold",
    },
    td: {
      padding: 3,
    },
  };

  return (
    <>
      <div className="wrapper">
        <Sidebar {...props} bgColor="black" activeColor="info" />
        <div className="main-panel">
          <Navbar {...props} handleMiniClick={handleMiniClick} />
          <Notifications />
          <div className="content">
            <>
              <Row style={{ widht: "100%" }}>
                <Col md="12">
                  <Card>
                    <CardBody>
                      <Row>
                        <Col sm="6">
                          <Label style={{ fontWeight: "bold" }}>
                            {" "}
                            Buscar Folio de Solicitud:
                          </Label>
                          <Input
                            id="Folio"
                            type="text"
                            placeholder={`Escriba el Folio Api`}
                            value={folioApi}
                            maxLength={6}
                            style={{ textTransform: "uppercase" }}
                            onChange={(e) => {
                              setFolioApi(e.target.value);
                            }}
                          />
                        </Col>
                        <Col sm="6">
                          <Label style={{ fontWeight: "bold" }}>
                            {" "}
                            Buscar Código de Barras Inicial:
                          </Label>
                          <Input
                            id="Folio"
                            type="text"
                            placeholder={`Escriba el código`}
                            value={serieVale}
                            maxLength={25}
                            style={{ textTransform: "uppercase" }}
                            onChange={(e) => {
                              setSerieVale(e.target.value);
                            }}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col sm="12">
                          <hr />
                          <Label style={{ fontWeight: "bold" }}>
                            {" "}
                            Buscar por Listado:
                          </Label>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm="2">
                          <FormGroup>
                            {/* <span className="text-danger">*</span> */}
                            {loadingRemesa && <Spinner size="sm" />}
                            <Input
                              name="Remesa"
                              type="select"
                              disabled={loadingRemesa}
                              value={remesa}
                              onChange={(e) => {
                                setRemesa(e.target.value);
                                setMunicipio("");
                                setListado(0);
                              }}
                            >
                              <option value={""}>Remesa</option>
                              {catRemesa}
                            </Input>
                          </FormGroup>
                        </Col>
                        <Col sm="3">
                          <FormGroup>
                            {/* <span className="text-danger">*</span> */}
                            {loadingMunicipio && <Spinner size="sm" />}
                            <Input
                              name="Municipio"
                              type="select"
                              disabled={loadingMunicipio || remesa === ""}
                              value={municipio}
                              onChange={(e) => {
                                setMunicipio(e.target.value);
                                getListadosVales(e.target.value);
                              }}
                            >
                              <option value={""}>Municipio</option>
                              {catMunicipio}
                            </Input>
                          </FormGroup>
                        </Col>
                        <Col sm="7">
                          <FormGroup>
                            {(loadingRemesa || loadingListado) && (
                              <Spinner size="sm" />
                            )}
                            <Input
                              name="Listado"
                              type="select"
                              disabled={
                                loadingListado ||
                                remesa === "" ||
                                municipio === 0
                              }
                              value={listado}
                              onChange={(e) => {
                                setListado(e.target.value);
                              }}
                            >
                              <option value={""}>Listado</option>
                              {catListado}
                            </Input>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col
                          sm="12"
                          xs="12"
                          style={{ display: "grid", placeItems: "end" }}
                        >
                          <Button
                            color="primary"
                            onClick={() => {
                              getRegistrosFilter();
                            }}
                          >
                            {loading ? (
                              <span>Buscando..</span>
                            ) : (
                              <span>Buscar</span>
                            )}
                          </Button>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
                <Col sm="12">
                  <Card>
                    <CardBody>
                      <Row>
                        <Col
                          sm="7"
                          style={{ display: "grid", placeItems: "center" }}
                        >
                          <Row>
                            <h6>Listado de vales activos</h6>
                          </Row>
                          <Row>
                            <ReactTable
                              size="sm"
                              data={listadoVales}
                              noDataText="Ingrese un método de búsqueda"
                              style={{ width: "100%" }}
                              manual
                              columns={columns}
                              className="-responsive -highlight primary-pagination"
                              loading={loading}
                              showPagination={true}
                              showPaginationBottom={true}
                              showTotalPages={true}
                              showPageJump={false}
                              canNextFromData={true}
                              defaultPageSize={pageOptions.pageSize}
                              total={total}
                              page={pageOptions.page}
                              pages={Math.floor(total / pageOptions.pageSize)}
                              previousText="Anterior"
                              nextText="Siguiente"
                              ofText="de"
                              pageSizeOptions={[10, 20, 25, 50, 100]}
                              PaginationComponent={Pagination}
                              onFetchData={fetchData}
                              getTrProps={(
                                state,
                                rowInfo,
                                column,
                                instance
                              ) => {
                                if (rowInfo) {
                                  return {
                                    style: {
                                      cursor: "pointer",
                                    },
                                    onClick: (e) => {
                                      if (
                                        devueltos.find(
                                          (item) =>
                                            item.FolioSolicitud ===
                                            rowInfo.original.FolioSolicitud
                                        ) ||
                                        devueltos.find(
                                          (item) =>
                                            item.SerieInicial ===
                                            rowInfo.original.SerieInicial
                                        )
                                      ) {
                                        swal({
                                          title: "Alerta",
                                          text: "El folio ya fue agregado a la lista",
                                          icon: "warning",
                                        });
                                      } else {
                                        swal({
                                          title: "¡Atención!",
                                          text: `Dese marcar como devuelta la valera: \n Serie Inicial - ${rowInfo.original.SerieInicial} \n Del beneficiario: \n CURP - ${rowInfo.original.CURP} `,
                                          dangerMode: true,
                                          icon: "warning",
                                          buttons: ["Cancelar", true],
                                        }).then((response) => {
                                          if (response) {
                                            setIdCarga(0);
                                            setDevueltos([
                                              ...devueltos,
                                              rowInfo.original,
                                            ]);
                                          }
                                        });
                                      }
                                    },
                                  };
                                } else {
                                  return {};
                                }
                              }}
                            />
                          </Row>
                        </Col>
                        <Col sm="5">
                          <Row>
                            <Col
                              sm="12"
                              style={{ display: "grid", placeItems: "center" }}
                            >
                              <h6>Valeras a devolver</h6>
                            </Col>
                            <Col
                              sm="12"
                              style={{ display: "grid", placeItems: "center" }}
                            >
                              <Table
                                responsive
                                striped
                                bordered
                                hover
                                size="sm"
                                maxHeight={600}
                              >
                                <thead>
                                  <tr>
                                    <th style={style.th}></th>
                                    <th style={style.th}>#</th>
                                    <th style={style.th}>Folio</th>
                                    <th style={style.th}>CURP</th>
                                    <th style={style.th}>Nombre Completo</th>
                                    <th style={style.th}>Serie Inicial</th>
                                  </tr>
                                </thead>
                                <tbody
                                  style={{
                                    fontSize: "9px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  {devueltos.length > 0 &&
                                    devueltos.map((item, index) => (
                                      <tr key={index}>
                                        <td
                                          style={style.td}
                                          className="text-center"
                                        >
                                          <Button
                                            size="small"
                                            color="danger"
                                            onClick={(e) =>
                                              handleDeleteFolio(item, index)
                                            }
                                            style={{ padding: 5 }}
                                          >
                                            <span className="btn-label">
                                              <i className="fa fa-trash fa-1x" />
                                            </span>
                                          </Button>
                                        </td>
                                        <td style={style.td}>{index + 1}</td>
                                        <td
                                          style={style.td}
                                          className="text-center"
                                        >
                                          {item.FolioSolicitud}
                                        </td>
                                        <td
                                          style={style.td}
                                          className="text-center"
                                        >
                                          {item.CURP}
                                        </td>
                                        <td
                                          style={style.td}
                                          className="text-center"
                                        >
                                          {item.NombreCompleto}
                                        </td>
                                        <td
                                          style={style.td}
                                          className="text-center"
                                        >
                                          {item.SerieInicial}
                                        </td>
                                      </tr>
                                    ))}
                                </tbody>
                              </Table>
                            </Col>
                            <Col
                              sm="6"
                              style={{ display: "grid", placeItems: "center" }}
                            >
                              {loadingDevueltos ? (
                                <Spinner size="sm" />
                              ) : (
                                <Button
                                  color="danger"
                                  size="sm"
                                  onClick={() => devolverVales()}
                                  style={{ width: "60%" }}
                                  disabled={devueltos.length === 0}
                                >
                                  Devolver vales
                                </Button>
                              )}
                            </Col>
                            <Col
                              sm="6"
                              style={{ display: "grid", placeItems: "center" }}
                            >
                              <Button
                                color="success"
                                size="sm"
                                onClick={() => btnExportar()}
                                style={{ width: "60%" }}
                                disabled={idCarga === 0}
                              >
                                Exportar Devueltos
                              </Button>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Footer fluid />
            </>
          </div>
        </div>
      </div>
    </>
  );
};
export default ValesDevueltos;
