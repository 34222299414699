import React from "react";
import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  ListGroup,
  ListGroupItem,
  Row,
  Spinner,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Container,
} from "reactstrap";
import swal from "sweetalert";
import { isNullOrUndefined } from "../../utils/emptyValidations";
import { Link } from "react-router-dom";

const PadronArchivos = ({
  files,
  archivosClasificacion,
  arrayOrigen,
  codigo,
  catalogClasificacion,
  catalogOrigen,
  handleChangeArchivos,
  handleChangeClasificacion,
  handleChangeOrigen,
  handleChangeCodigo,
  handleDeleteFile,
  loadingCatalogs,
  onlyForView,
  fullscreen = false,
}) => {
  const handleSelectItems = (newFiles) => {
    if (newFiles.length > 1) {
      swal({
        title: "¡Atención!",
        text: `Solo puede cargar un archivo`,
        icon: "info",
      });
      return;
    }
    handleChangeArchivos(files.concat(Array.from(newFiles)));
  };

  const getValorClasificacion = (index) => {
    const valor = archivosClasificacion[index];
    if (isNullOrUndefined(valor)) return "";
    return valor;
  };

  const getValorOrigen = (index) => {
    const valor = arrayOrigen[index];
    if (isNullOrUndefined(valor)) return "";
    return valor;
  };

  const getValorCodigo = (index) => {
    const valor = codigo[index];
    if (isNullOrUndefined(valor)) return "";
    return valor;
  };

  const handleSelectClasificacion = (idClasificacion, index) => {
    let clasificacionAux = [...archivosClasificacion];
    clasificacionAux[index] = idClasificacion;
    handleChangeClasificacion(clasificacionAux);
  };

  const handleSelectOrigen = (idOrigen, index) => {
    let origenAux = [...arrayOrigen];
    origenAux[index] = idOrigen;
    handleChangeOrigen(origenAux);
  };

  const handleCodigo = (idClasificacion, index) => {
    let clasificacionAux = [...codigo];
    clasificacionAux[index] = idClasificacion;
    handleChangeCodigo(clasificacionAux);
  };

  const deleteFile = (index) => {
    let filesAux = [...files];
    let clasificacionAux = [...archivosClasificacion];
    let origenAux = [...arrayOrigen];
    let codigoAux = [...codigo];
    filesAux.splice(index, 1);
    origenAux.splice(index, 1);
    clasificacionAux.splice(index, 1);
    clasificacionAux.splice(index, 1);
    handleDeleteFile(filesAux, clasificacionAux, origenAux, codigoAux);
  };
  return (
    <Row style={{ width: "100%" }}>
      <Col xs="12">
        <ListGroup
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
          }}
        >
          {files.map((file, index) => (
            <>
              <ListGroupItem
                key={index}
                style={{ width: fullscreen ? "100%" : "80%" }}
              >
                <Row>
                  <Container>
                    <Row sm="12">
                      <Col sm="10">
                        <Col sm="12">
                          <i
                            class="fa fa-file-excel-o fa-4x"
                            aria-hidden="true"
                            style={{ color: "#3fca2e" }}
                          ></i>{" "}
                          {file && (
                            <Link
                              //to={file.ruta}
                              className={(isActive) =>
                                "nav-link" + (!isActive ? " unselected" : "")
                              }
                              onClick={(e) => {
                                e.preventDefault();
                              }}
                            >
                              {file.name}
                            </Link>
                          )}
                        </Col>

                        <Col sm="12" style={{ paddingTop: "1rem" }}>
                          <FormGroup>
                            <Label>
                              Remesa: <span className="text-danger">*</span>
                              {loadingCatalogs && <Spinner size="sm" />}
                            </Label>
                            <Input
                              name="idTipoCombustible"
                              type="select"
                              disabled={loadingCatalogs || onlyForView}
                              value={getValorClasificacion(index)}
                              onChange={(e) =>
                                handleSelectClasificacion(e.target.value, index)
                              }
                            >
                              <option value={""}></option>
                              {catalogClasificacion.map((element) => (
                                <option
                                  key={element.value}
                                  value={element.value}
                                >
                                  {element.label}
                                </option>
                              ))}
                            </Input>
                          </FormGroup>
                        </Col>
                        <Col sm="12" style={{ paddingTop: "1rem" }}>
                          <FormGroup>
                            <Label>
                              Origen: <span className="text-danger">*</span>
                              {loadingCatalogs && <Spinner size="sm" />}
                            </Label>
                            <Input
                              name="Origen"
                              type="select"
                              disabled={loadingCatalogs || onlyForView}
                              value={getValorOrigen(index)}
                              onChange={(e) =>
                                handleSelectOrigen(e.target.value, index)
                              }
                            >
                              <option value={""}></option>
                              {catalogOrigen.map((element) => (
                                <option
                                  key={element.value}
                                  value={element.value}
                                >
                                  {element.label}
                                </option>
                              ))}
                            </Input>
                          </FormGroup>
                        </Col>
                        <Col sm="12">
                          <FormGroup>
                            <Label>
                              Codigo: <span className="text-danger">*</span>
                              {loadingCatalogs && <Spinner size="sm" />}
                            </Label>
                            <Input
                              name="codigo"
                              type="text"
                              disabled={loadingCatalogs || onlyForView}
                              value={getValorCodigo(index)}
                              onChange={(e) =>
                                handleCodigo(
                                  e.target.value.toUpperCase(),
                                  index
                                )
                              }
                            ></Input>
                          </FormGroup>
                        </Col>
                      </Col>
                      <Col sm="2">
                        {!onlyForView && (
                          <Button
                            color="danger"
                            outline
                            onClick={() => deleteFile(index)}
                          >
                            <span className="btn-label">
                              <i className="fa fa-trash fa-xs"></i>
                            </span>
                          </Button>
                        )}
                      </Col>
                    </Row>
                  </Container>
                </Row>
              </ListGroupItem>
            </>
          ))}
        </ListGroup>
      </Col>
      {!onlyForView && files.length < 1 && (
        <Col xs="12" style={{ display: "flex", justifyContent: "center" }}>
          <FormGroup>
            <Input
              id="exampleFile"
              name="file"
              type="file"
              // style={{display: "none"}}
              style={{ cursor: "pointer" }}
              multiple
              onChange={(e) => handleSelectItems(e.target.files)}
            />
            <Button onClick={() => {}}>Seleccionar archivos</Button>
          </FormGroup>
        </Col>
      )}
    </Row>
  );
};

export default PadronArchivos;
