import React, { useState, useEffect } from 'react';
import { Button, Input, Form, FormGroup, CardBody, Row, Col, Card, CardHeader, CardTitle, Label, CardFooter } from 'reactstrap';
import ReactBSAlert from "react-bootstrap-sweetalert";
import Navbar from '../components/Navbar';
import Sidebar from '../components/Sidebar';
import Notifications, { notify } from 'react-notify-toast';
import EjecutarApi from '../Consultas/Consultas';
import { getVars, setVars } from '../GlobalStorage';
import { useHistory } from 'react-router-dom';
import InfiniteScroll from "react-infinite-scroll-component";
import { CircleSpinner } from 'react-spinners-kit';

function GruposDocPaso1(props) {
    
    const [flagAlertaMensaje, setflagAlertaMensaje] = useState(false);
    const [loading, setloading] = useState(false);

    
    
    const [dataUsers, setDataUsers] = useState({ data: [], total: 0 });
    const [hasMoreUsers, setHasMoreUsers] = useState(true);
    const [DetalleSolicitante, setDetalleSolicitante] = useState({ data: [], total: 0 });
    
    const [dataSearch, setDataSearch] = useState({ page: 0, pageSize: 20 });
    

    const [Estatus,setEstatus]=useState(0);
    const [tieneFolio, settieneFolio] = useState(0); //0=No Seleccionado ; 1 = SI ; 2 =NO
    const [tieneCURP, settieneCURP] = useState(0);
    const [coincideINE, setcoincideINE] = useState(0);
    const [tieneDomicilio, settieneDomicilio] = useState(0);
    const [tieneArticulador, settieneArticulador] = useState(0);
    const [TieneFechaSolicitud,setTieneFechaSolicitud]=useState(0);

    
    const dataGrupo = getVars('dataGrupo');
    const [itemFolio, setItemFolio] = useState([]);
    
    const [ObservacionesINE,setObservacionesINE]=useState('');
    
    const token = getVars('Token');
    let history = useHistory();

    useEffect(() => {

        let datax = {
            page: 0,
            tipo: "and",
            pageSize: 50,
            sorted: [],
            filtered: [{ "id": "vales.UserOwned", "value": dataGrupo.UserOwned }] //idARticuladortoken
        };

        //console.log('######GrupoEditArticulador', token);
        //console.log('######EGrupoEditArticulador', JSON.stringify(datax));
        EjecutarApi(datax, 'getValesDocumentacionNotIn', token.token)
            .then(function (res) {
                return res;
            })
            .then(items => {
                console.log("===> Data getValesIn: ", items);

                if (items.results) {
                    setDataSearch({ ...dataSearch, page: dataSearch.page + 1 });
                    setloading(false);

                    setDataUsers({
                        data: dataUsers.data.concat(items.data),
                        total: items.total
                    });
                }
            })
            .catch((e) => {
                console.log('=Error[getValesIn]: ', e);
                setloading(false);
                notify.show(
                    `Error al obtener Vales: ${e.errors}`,
                    'custom',
                    5000,
                    {
                        background: '#0857B6',
                        fontSize: 14,
                        text: '#FFFFFF',
                    }
                );

                //setloadingGrupo(false);
            });
        
    }, []);


    const handleMiniClick = () => {
        document.body.classList.toggle('sidebar-mini');
    };

    
   
    const handleReturn = () => {
        history.push('/gruposdocumentos');
    };

    const handleSiguiente = () => {
        //validar los folio  getSerieVale
        setloading(true);
        //Valido el estatus
        if(itemFolio.length===0){
            console.log('Falta seleccionar el nombre')
            setEstatus(-1);
            setflagAlertaMensaje(true);
            return true;
        }
        if(tieneFolio===0 || tieneCURP===0 || coincideINE===0 || tieneDomicilio===0 || tieneArticulador===0 || TieneFechaSolicitud===0)
        {
            console.log('validar Estatus -1')
            setEstatus(-1);
            setflagAlertaMensaje(true);
            return true;
        }
        if(tieneFolio===1 && tieneCURP===1 && coincideINE===1 && tieneDomicilio===1 && tieneArticulador===1 && TieneFechaSolicitud===1)
        {    
            setEstatus(1); //OK
            console.log('validar Estatus 1')
        }
        else if(coincideINE===2)
        {    
            setEstatus(2); //NoCumple
            console.log('validar Estatus 1')
        }
        else if(tieneFolio===2 || tieneCURP===2 || tieneDomicilio===2 || tieneArticulador===2 || TieneFechaSolicitud===2)
        {    
            setEstatus(3);
            console.log('validar Estatus -1')
        }
        
        
        const valesDocumentacion={
            id : itemFolio.id,
            Nombre: itemFolio.Nombre,
            Paterno: itemFolio.Paterno,
            FechaNacimiento: itemFolio.FechaNacimiento,
            Sexo: itemFolio.Sexo,
            CURP: itemFolio.CURP,
            idMunicipio: itemFolio.idMunicipio.id,
            idLocalidad: itemFolio.idLocalidad.id, 
            Colonia: itemFolio.Colonia,
            NumExt: itemFolio.NumExt,
            CP: itemFolio.CP,
            FechaSolicitud: itemFolio.FechaSolicitud, 
            UserOwned: itemFolio.UserOwned.id,
            idStatusDocumentacion: Estatus, //Si tiene un NO en check el estatus = 2=Cambiar Formato Solicitud
            TieneFolio: tieneFolio,
            TieneFechaSolicitud: TieneFechaSolicitud,
            TieneCURPValida: tieneCURP,
            NombreCoincideConINE: coincideINE,
            TieneDomicilio: tieneDomicilio,
            TieneArticuladorReverso: tieneArticulador,
            FolioCoincideListado: tieneFolio,
            CURPCoincideListado: tieneCURP,
            FechaSolicitudChange:0,
            NombreChanged:0,
            PaternoChanged:0,
            MaternoChanged:0,
            CalleChanged:0,
            NumExtChanged:0,
            ColoniaChanged:0,
            idLocalidadChanged:0,
            idMunicipioChanged:0,
            UserOwnedchanged:0,
            NumIntChanged:0
        };
        
        console.log('===>dataUsers', itemFolio);
        setVars('itemFolio', itemFolio);
        setVars('valesDocumentacion', valesDocumentacion);
        history.push('/gruposdocpaso2');

    }

    const fetchMoreUsers = () => {
        if (dataSearch.page * dataSearch.pageSize >= DetalleSolicitante.total) {
            console.log("=>PorAutorizar Llego a su final: ",
                dataSearch.page * dataSearch.pageSize
            );

            setHasMoreUsers(false);
            return;
        }

        setloading(true);

        const data = {
            page: dataSearch.page + 1,
            tipo: "and",
            pageSize: dataSearch.pageSize,
            sorted: [],
            filtered: [{ id: "vales.UserOwned", value: dataGrupo.UserOwned }]
        };


        console.log("= getValesIN: ", data);

        EjecutarApi(data, "getValesDocumentacionNotIn", getVars('Token').token, "POST")
            .then(function (res) {
                return res;
            })
            .then(items => {
                console.log("===> Data Por Autorizar: ", items);

                if (items.results) {
                    setDataSearch({ ...dataSearch, page: dataSearch.page + 1 });
                    setloading(false);

                    setDataUsers({
                        data: dataUsers.data.concat(items.data),
                        total: items.total
                    });
                }
            })
            .catch(e => {
                console.log("error GetValesIN: ", e.message);
                setloading(false);
            });
    };
    
    
    const visualizaDetalleVales = (item) => {
        setloading(true);
        console.log('Entra a setear Detalles Vales => item', item);
        setItemFolio(item);
        setloading(false);
    };
    
   

    const alertaMensaje = (<ReactBSAlert
      
        style={{ display: "block", marginTop: "-100px" , fontSize:"8px" }}
        title={'Debe completar el formulario!!!!'}
        confirmBtnText="Ok"
        onConfirm={() => {setflagAlertaMensaje(false); setloading(false); }}
        confirmBtnBsStyle="info sm btn-round"

    />);
    
    const handleRadioFolio= (e)=>
    {
        settieneFolio(e);
    }
    const handleRadioCurp= (e)=>
    {
        settieneCURP(e);
    }
    const handleRadioINE= (e)=>
    {
        setcoincideINE(e);
    }
    const handleRadioDomicilio= (e)=>
    {
        settieneDomicilio(e);
    }
    const handleRadioArticulador= (e)=>
    {
        settieneArticulador(e);
    }
    const handleRadioTieneFechaSolicitud= (e)=>
    {
        setTieneFechaSolicitud(e);
    }
    const handleObservacionesINE=(e)=>{
        setObservacionesINE(e);
    }
    return (
        <div className="wrapper">
            <Sidebar {...props} bgColor="black" activeColor="info" />
            <div className="main-panel">
                <Navbar {...props} handleMiniClick={handleMiniClick} />
                <Notifications />
                <div className="content">
                    <Row>
                        <Col md="12">
                            <Card>
                                <CardHeader>
                                    <Row>
                                        <Col md="9">
                                            <CardTitle tag="h6">Revisón de Documentos Físicos</CardTitle>
                                        </Col>
                                        <Col>
                                            <Button color="success" onClick={() => { handleReturn() }} size="sm"  >
                                                <i className="nc-icon nc-minimal-left" />
                                            Regresar

                                    </Button>
                                        </Col>
                                    </Row>
                                </CardHeader>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="12">
                            <Card>
                                <CardBody>
                                    <Form className="form-horizontal" id="TypeValidation">
                                        <Notifications />
                                        <Row>
                                            <Label sm="2">Articulador:<span className="text-danger">*</span></Label>

                                            <Col sm="6">
                                                <FormGroup >
                                                    <Input id="articu" type="text" value={dataGrupo.FullName} disabled />
                                                </FormGroup>
                                            </Col>

                                        </Row>
                                        <Row>
                                            <Label sm="2">Municipio:<span className="text-danger">*</span></Label>
                                            <Col sm="6">
                                                <FormGroup >
                                                    <Input id="articu" type="text" value={dataGrupo.Municipio} disabled />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Label sm="2">Total Aprobados:<span className="text-danger">*</span></Label>
                                            <Col sm="4">
                                                <FormGroup >
                                                    <Input id="Total" type="text" value={dataGrupo.TotalAprobados} disabled />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Label sm="2">Número de Remesa:<span className="text-danger">*</span></Label>
                                            <Col sm="4">
                                                <FormGroup >
                                                    <Input id="remesa" type="text" value={dataGrupo.Remesa} disabled />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                    </Form>
                                </CardBody>
                                <CardFooter className="text-center">

                                </CardFooter>
                            </Card>
                        </Col>
                    </Row>
                    <Row >

                        <Col md="6">
                            <Card>
                                <CardHeader>
                                    <Row>
                                        <Col>
                                            <CardTitle tag="h6">Detalle del Solicitante</CardTitle>
                                        </Col>
                                    </Row>

                                </CardHeader>

                                <CardBody>
                                    <InfiniteScroll
                                        dataLength={dataUsers.data.length}
                                        next={fetchMoreUsers}
                                        hasMore={hasMoreUsers}
                                        loader={<h4>cargando...</h4>}
                                        height={600}
                                        endMessage={
                                            <p style={{ textAlign: "center" }}>
                                                <b>Listo! Esos son todos!</b>
                                            </p>
                                        }
                                        >
                                        {dataUsers.data.map((item, index) =>
                                            <div key={index}>
                                                <span style={{ color: "#4ACCCD" }}>
                                                    <b onClick={() => visualizaDetalleVales(item)}>{item.CURP}</b>
                                                </span>
                                                <h6>
                                                    {`${item.Nombre} ${item.Paterno} ${item.Materno}`}
                                                </h6>

                                                <h6>
                                                    {`Mpio: ${item.idMunicipio.Municipio}, Loc: ${item.idLocalidad.Nombre} `}
                                                </h6>

                                                <Button size="sm" color="primary" onClick={() => visualizaDetalleVales(item)}>
                                                    Validar Documentación
                                                </Button>
                                                <hr />
                                            </div>

                                        )}
                                    </InfiniteScroll>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md="6">
                            <Form>
                                <Card>
                                    <CardHeader>
                                        <h6 sm="12">Revisión Documento Físico</h6>
                                    </CardHeader>
                                    <CardBody>
                                        <Row>
                                            
                                            {itemFolio.length===0?(<>
                                                    <Label sm="12"> <h6>Seleccinar un beneficiario</h6></Label> </>
                                            ):( <>
                                                <Label sm="12"> <h6>{`${itemFolio.Nombre} ${itemFolio.Paterno} ${itemFolio.Materno}`}</h6></Label>
                                                
                                            </>
                                            )
                                            }
                                        
                                        </Row>
                                        <Row>
                                            <Label sm="12"><h6>CURP: {`${itemFolio.CURP}`} </h6></Label>
                                        </Row>
                                        
                                        <Row>
                                            <Label sm="6">Formato impreso, ¿Tiene Folio? <span className="text-danger">*</span></Label>
                                            <Col sm="6">
                                                <FormGroup sm="6">
                                                    <div className="styleRadiodiv">
                                                        <label className="styleRadio">
                                                            <input
                                                                defaultValue="1"
                                                                name="Radio"
                                                                type="radio"
                                                                onChange={(e)=>{handleRadioFolio(e.target.value)}}
                                                            /> Si
                                                                </label>
                                                        <label className="styleRadio">
                                                            <input
                                                                defaultValue="2"
                                                                name="Radio"
                                                                type="radio"
                                                                onChange={(e)=>{handleRadioFolio(e.target.value)}}
                                                            /> No
                                                                </label>
                                                    </div>
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Label sm="6">Formato impreso, ¿Tiene CURP válida? <span className="text-danger">*</span></Label>
                                            <Col sm="6">
                                                <FormGroup sm="6">
                                                    <div className="styleRadiodiv">
                                                        <label className="styleRadio">
                                                            <input
                                                                defaultValue="1"
                                                                name="Radioscurp"
                                                                type="radio"
                                                                onChange={(e)=>{handleRadioCurp(e.target.value)}}
                                                            /> Si
                                                                </label>
                                                        <label className="styleRadio">
                                                            <input
                                                                defaultValue="2"
                                                                name="Radioscurp"
                                                                type="radio"
                                                                onChange={(e)=>{handleRadioCurp(e.target.value)}}
                                                            /> No
                                                                </label>
                                                    </div>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Label sm="6">Formato impreso, ¿Nombre coincide con INE? <span className="text-danger">*</span></Label>
                                            <Col sm="6">
                                                <FormGroup sm="6">
                                                    <div className="styleRadiodiv">
                                                        <label className="styleRadio">
                                                            <input
                                                                defaultValue="1"
                                                                name="radioine"
                                                                type="radio"
                                                                onChange={(e)=>{handleRadioINE(e.target.value)}}
                                                            /> Si
                                                                </label>
                                                        <label className="styleRadio">
                                                            <input
                                                                defaultValue="2"
                                                                name="radioine"
                                                                type="radio"
                                                                onChange={(e)=>{handleRadioINE(e.target.value)}}
                                                            /> No
                                                                </label>
                                                    </div>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        {coincideINE===1?(<></>):( <>
                                            <Row>
                                            <Label sm="6">Observaciones: <span className="text-danger">*</span></Label>
                                                <Col sm="6">
                                                    <FormGroup sm="6">
                                                    <Input id="ObservacionesINE"  type="text" value={ObservacionesINE} 
                                                    onChange={(e)=>handleObservacionesINE(e.target.label) } />
                                                    </FormGroup>
                                                </Col>
                                            </Row> </>
                                        )}
                                        <Row>
                                            <Label sm="6">Formato impreso, ¿Tiene domicilio? <span className="text-danger">*</span></Label>
                                            <Col sm="6">
                                                <FormGroup sm="6">
                                                    <div className="styleRadiodiv">
                                                        <label className="styleRadio">
                                                            <input
                                                                defaultValue="1"
                                                                name="radiodomicilio"
                                                                type="radio"
                                                                onChange={(e)=>{handleRadioDomicilio(e.target.value)}}
                                                            /> Si
                                                                </label>
                                                        <label className="styleRadio">
                                                            <input
                                                                defaultValue="2"
                                                                name="radiodomicilio"
                                                                type="radio"
                                                                onChange={(e)=>{handleRadioDomicilio(e.target.value)}}
                                                            /> No
                                                                </label>
                                                    </div>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Label sm="6">Formato impreso, ¿Tiene Articulador al reverso? <span className="text-danger">*</span></Label>
                                            <Col sm="6">
                                                <FormGroup sm="6">
                                                    <div className="styleRadiodiv">
                                                        <label className="styleRadio">
                                                            <input
                                                                defaultValue="1"
                                                                name="radioarticulador"
                                                                type="radio"
                                                                onChange={(e)=>{handleRadioArticulador(e.target.value)}}
                                                            /> Si
                                                                </label>
                                                        <label className="styleRadio">
                                                            <input
                                                                defaultValue="2"
                                                                name="radioarticulador"
                                                                type="radio"
                                                                onChange={(e)=>{handleRadioArticulador(e.target.value)}}
                                                            /> No
                                                                </label>
                                                    </div>
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Label sm="6">Formato impreso, ¿Folio Tiene Fecha Solicitud? <span className="text-danger">*</span></Label>
                                            <Col sm="6">
                                                <FormGroup sm="6">
                                                    <div className="styleRadiodiv">
                                                        <label className="styleRadio">
                                                            <input
                                                                defaultValue="1"
                                                                name="radiolistado"
                                                                type="radio"
                                                                onChange={(e)=>{handleRadioTieneFechaSolicitud(e.target.value)}}
                                                            /> Si
                                                                </label>
                                                        <label className="styleRadio">
                                                            <input
                                                                
                                                                defaultValue="2"
                                                                name="radiolistado"
                                                                type="radio"
                                                                onChange={(e)=>{handleRadioTieneFechaSolicitud(e.target.value)}}
                                                            /> No
                                                                </label>
                                                    </div>
                                                </FormGroup>
                                            </Col>
                                        </Row>

                                    </CardBody>
                                    <CardFooter>
                                        <Row>
                                            {loading && (
                                                <CircleSpinner
                                                    className="text-center"
                                                    size={30}
                                                    color="#686769"
                                                    loading={loading}
                                                />
                                            )}
                                            <Button color="primary" onClick={() => {handleSiguiente()}} size="sm"  >
                                                Siguiente
                                            </Button>
                                                
                                        </Row> 
                                        
                                        {flagAlertaMensaje ? alertaMensaje : <> </>}  
                                    
                                    </CardFooter>
                                </Card>
                            </Form>


                        </Col>

                    </Row> 
                                             
                </div>
            </div>
        </div>
    );
}
export default GruposDocPaso1;


