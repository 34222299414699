import React, { useEffect, useState } from "react";
// react component for creating dynamic tables
import ReactTable from "react-table";
import EjecutarApi from "../Consultas/Consultas";
import Notifications, { notify } from "react-notify-toast";
import { getVars, setVars } from "../GlobalStorage";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { Link } from "react-router-dom";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Sidebar from "../components/Sidebar";
import FixedPlugin from "../components/PluginFilterVales";
import moment from "moment";
import { useHistory } from "react-router-dom";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  InputGroup,
  Input,
  InputGroupAddon,
  Label,
} from "reactstrap";
import ValesUpdate from "./ValesUpdate";
import matchSorter from "match-sorter";
import { URL_GENERAL } from "../data/consts";

function GruposEntregaVales(props) {
  const [loading, setloading] = useState(false);
  const [Grupos, setGrupos] = useState([]);
  const [totalGrupos, setTotalGrupos] = useState(0);
  const [data, setData] = useState([]);
  const [flagBuscaArticulador, setflagBuscaArticulador] = useState(false);
  const [totalArticulador, settotalArticulador] = useState(0);
  const [vale, setVale] = useState([]);
  const [Articulador, setArticulador] = useState("");
  const [idArticulador, setidArticulador] = useState("");

  let history = useHistory();

  const token = getVars("Token");
  const datos = {
    page: 0,
    tipo: "and",
    pageSize: 1000,
    sorted: [],
    filtered: [],
  };
  const [_menu, _setmenu] = useState({});
  //const [,]=useEffect();
  //const [,]=useEffect();
  //const [,]=useEffect();
  //const [,]=useEffect();
  //const [,]=useEffect();
  //const [,]=useEffect();

  useEffect(() => {
    console.log("=>[GruposEntregaVales]-> token", token);
    token.menu.forEach((prop, key) => {
      if (prop.Menu === "Entrega Vales") {
        _setmenu({
          Ver: prop.Ver,
          Agregar: prop.Agregar,
          Editar: prop.Editar,
          Eliminar: prop.Eliminar,
          Seguimiento: prop.Seguimiento,
          Exportar: prop.Exportar,
          Imprimir: prop.Imprimir,
          ViewAll: prop.ViewAll,
          idTipoUser: token.user.idTipoUser.id,
        });
      }
    });
  }, []);
  const handleMiniClick = () => {
    document.body.classList.toggle("sidebar-mini");
  };

  const mini_tableArticulador = (
    <>
      <ReactBSAlert
        style={{ display: "block", marginTop: "-250pt" }}
        //title="Here's a message!"
        onConfirm={() => setflagBuscaArticulador(false)}
        onCancel={() => setflagBuscaArticulador(false)}
        confirmBtnBsStyle="info"
        size="sm"
      >
        <Card className="mb-2 text-muted">
          <CardBody className="mb-2 text-muted">
            <ReactTable
              data={data}
              noDataText="¡No existen datos a mostrar!"
              columns={[
                {
                  Header: "Articulador",
                  minWidth: 10,
                  id: "idArticulador",
                  accessor: (d) => d.Articulador,
                  filterAll: false,
                },
              ]}
              defaultPageSize={5}
              filterable
              className={"-striped -highlight primary-pagination"}
              loading={loading}
              showPagination={true}
              showPageJump={false}
              previousText="Anterior"
              nextText="Siguiente"
              canNextFromData={true}
              manual
              total={totalArticulador}
              pages={Math.floor(totalArticulador / 10)}
              previousText="Anterior"
              nextText="Siguiente"
              pageSizeOptions={[10, 20, 25, 50, 100]}
              getTrProps={(state, rowInfo, column, instance) => {
                return {
                  onClick: (e) => {
                    setVale({
                      ...vale,
                      idArticulador: rowInfo.original.data.id,
                    });
                    setArticulador(
                      rowInfo.original.data.Nombre +
                        " " +
                        rowInfo.original.data.Paterno +
                        " " +
                        rowInfo.original.data.Materno
                    );
                    setVars("dataGrupo", rowInfo.original.data);
                  },
                };
              }}
              onFetchData={(stateComp, instance) => {
                setloading(true);
                const token = getVars("Token").token;

                //if (stateComp.filtered.length != 0) {
                //  array_filtros.push({ id: 'NombreCompleto', value: stateComp.filtered[0].value })
                //}
                const datax = {
                  page: stateComp.page,
                  tipo: "and",
                  pageSize: stateComp.pageSize,
                  sorted: [{ id: "Nombre", desc: false }],
                  filtered: [],
                };
                console.log("Datos API -->", datax);
              }}
            />
          </CardBody>
        </Card>
      </ReactBSAlert>
    </>
  );

  const handleArticulador = () => {
    history.push("/gruposadd");
  };

  const handleGrupoArticulador = (idOwner, idGru) => {
    setVars("idArticulador", idOwner);
    setVars("idGrupo", idGru);
    //console.log('--------------->idOwner',idOwner);
    // console.log('--------------->idGru',idGru);
    history.push("/gruposedit");
  };
  const handleDescargaExcel = (param) => {
    console.log("=>Exportar DescargaExcel: ", param);

    var url = new URL(
      URL_GENERAL +
        `getReporteNominaVales?idGrupo=${param.id}&token=${token.token}`
    );
    window.open(url, "_blank");
  };
  return (
    <div className="wrapper">
      <Sidebar {...props} bgColor="black" activeColor="info" />
      <div className="main-panel">
        <Navbar {...props} handleMiniClick={handleMiniClick} />
        <Notifications />
        <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <Row>
                    <Col md="9">
                      <CardTitle tag="h6">Entrega de Vales</CardTitle>
                    </Col>
                    <Col md="2">
                      {_menu.Agregar == 1 ? (
                        <>
                          <Button
                            color="primary"
                            size="sm"
                            onClick={() => {
                              handleArticulador();
                              console.log("ONCLIK");
                            }}
                          >
                            <span className="btn-label">
                              <i className="nc-icon nc-simple-add" />
                            </span>{" "}
                            Agregar
                          </Button>
                          {flagBuscaArticulador ? mini_tableArticulador : <></>}
                        </>
                      ) : (
                        <></>
                      )}
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Col sm="12">
                    <ReactTable
                      size="sm"
                      noDataText="¡No existen datos a mostrar!"
                      data={Grupos}
                      filterable
                      manual
                      columns={[
                        {
                          Header: "Remesa",
                          minWidth: 40,
                          id: "Remesa",
                          accessor: (d) => d.Remesa,
                          filterMethod: (filter, rows) =>
                            matchSorter(rows, filter.value, {
                              keys: ["Remesa"],
                            }),
                          filterAll: false,
                        },
                        {
                          Header: "Municipio",
                          minWidth: 50,
                          id: "Municipio",
                          accessor: (d) => d.Municipio,
                          filterMethod: (filter, rows) =>
                            matchSorter(rows, filter.value, {
                              keys: ["Municipio"],
                            }),
                          filterAll: true,
                        },
                        {
                          Header: "Articulador",
                          minWidth: 70,
                          id: "FullName",
                          accessor: (d) => d.FullName,
                          filterMethod: (filter, rows) =>
                            matchSorter(rows, filter.value, {
                              keys: ["FullName"],
                            }),
                          filterAll: true,
                        },
                        {
                          Header: "Total",
                          minWidth: 30,
                          id: "Total",
                          accessor: (d) => d.Total,
                          filterMethod: (filter, rows) =>
                            matchSorter(rows, filter.value, {
                              keys: ["Total"],
                            }),
                          filterAll: false,
                        },
                        {
                          Header: "Restantes",
                          minWidth: 30,
                          id: "Restantes",
                          accessor: (d) => d.Restantes,
                          filterMethod: (filter, rows) =>
                            matchSorter(rows, filter.value, {
                              keys: ["Restantes"],
                            }),
                          filterAll: false,
                        },
                        {
                          Header: "Acciones",
                          minWidth: 30,
                          id: "Acciones",
                          accessor: (d) =>
                            _menu.Exportar == 1 ? d.action : "",
                          filterMethod: (filter, rows) =>
                            matchSorter(rows, filter.value, {
                              keys: ["Acciones"],
                            }),
                          filterAll: false,
                        },
                      ]}
                      className="-striped -highlight primary-pagination"
                      loading={loading}
                      showPagination={true}
                      showPageJump={false}
                      canNextFromData={true}
                      defaultPageSize={10}
                      total={totalGrupos}
                      pages={Math.floor(totalGrupos / 10)}
                      previousText="Anterior"
                      nextText="Siguiente"
                      pageSizeOptions={[10, 20, 25, 50, 100]}
                      getTrProps={(state, rowInfo, column, instance) => {
                        return {
                          onClick: (e) => {
                            console.log(
                              "[EntregaVales][GridListado]",
                              rowInfo.original.data.UserOwned
                            );
                            setidArticulador(rowInfo.original.data.UserOwned);
                            if (_menu.Agregar === 1) {
                              setVars("dataGrupo", rowInfo.original.data);
                              history.push("/entregavalespaso1");
                            }
                          },
                        };
                      }}
                      onFetchData={(stateComp, instance) => {
                        setloading(true);

                        var arrays = [];

                        var temFullName = "";
                        stateComp.filtered.map((Filtro) => {
                          if (Filtro.id == "FullName") {
                            temFullName = Filtro.value;
                          }
                          if (Filtro.id == "Remesa") {
                            arrays.push({
                              id: "vales_grupos.Remesa",
                              value: Filtro.value,
                            });
                          }
                          if (Filtro.id == "Municipio") {
                            arrays.push({
                              id: "et_cat_municipio.Nombre",
                              value: Filtro.value,
                            });
                          }
                          if (Filtro.id == "Solicitudes") {
                            arrays.push({
                              id: "vales_grupos.TotalAprobados",
                              value: Filtro.value,
                            });
                          }
                        });

                        const token = getVars("Token");
                        const datax = {
                          page: stateComp.page,
                          tipo: "and",
                          pageSize: stateComp.pageSize,
                          sorted: [],
                          filtered: [],
                          //filtered: arrays,
                          //NombreCompleto: temFullName
                        };
                        console.log("######datax-->", datax);
                        EjecutarApi(datax, "getArticularEntregado", token.token)
                          .then(function (res) {
                            return res;
                          })
                          .then((res) => {
                            setloading(false);
                            setTotalGrupos(res.total);
                            setGrupos(
                              res.data.map((prop, key) => {
                                //console.log('$$$$$$$$$$$$$$$$Obtiene el props.id =>Items: ', prop);

                                return {
                                  Region: prop.Region,
                                  Remesa: prop.Remesa,
                                  idMunicipio: prop.idMunicipio,
                                  Municipio: prop.Municipio,
                                  FullName: prop.FullName,
                                  Total: prop.Total,
                                  Restantes: prop.Total - prop.Entregados,
                                  action: (
                                    <>
                                      <Button
                                        size="sm"
                                        color="success"
                                        onClick={() =>
                                          handleDescargaExcel(prop)
                                        }
                                      >
                                        <span className="btn-label">
                                          <i className="fa fa-file-excel-o fa-2x" />
                                        </span>
                                      </Button>
                                    </>
                                  ),
                                  data: prop,
                                };
                              })
                            );
                            //setloadingGrupo(false);
                          })
                          .catch((e) => {
                            console.log("=Error[getExport]: ", e);
                            setloading(false);
                            notify.show(
                              `Error al obtener Vales: ${e.errors}`,
                              "custom",
                              5000,
                              {
                                background: "#0857B6",
                                fontSize: 14,
                                text: "#FFFFFF",
                              }
                            );

                            //setloadingGrupo(false);
                          });
                      }}
                    />
                  </Col>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}

export default GruposEntregaVales;
