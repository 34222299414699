import {
  Col,
  Row,
  Table,
  Modal,
  Input,
  Label,
  Button,
  Spinner,
  CardImg,
  FormGroup,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import swal from "sweetalert";
import { getVars } from "../../GlobalStorage";
import React, { useEffect, useState } from "react";
import { uploadFiles } from "../../Consultas/Consultas";

const ModalCotizacionProyectos = (props) => {
  const { idSolicitud, backSolicitud } = props;
  const [file, setFile] = useState([]);
  const token = getVars("Token").token;
  const [flagFile, setFlagFile] = useState(false);
  const [flagFolioC, setFlagFolioC] = useState(true);
  const [flagProducto, setFlagProducto] = useState(true);
  const [loading, setLoading] = useState(false);
  const [nuevo, setNuevo] = useState({
    producto: "",
    cantidad: 0,
    precio: 0,
  });
  const [data, setData] = useState({
    folioCotizacion: "",
    productos: [],
    subtotalCotizacion: 0,
    ivaCotizacion: 0,
    totalCotizacion: 0,
  });

  useEffect(() => {
    if (data.folioCotizacion) {
      setFlagFolioC(false);
    } else {
      setFlagFolioC(true);
    }
  }, [data.folioCotizacion]);

  useEffect(() => {
    if (nuevo.producto && nuevo.cantidad > 0 && nuevo.precio > 0) {
      setFlagProducto(false);
    } else {
      setFlagProducto(true);
    }
  }, [nuevo]);

  const handleSelectItems = (newFiles) => {
    if (newFiles) {
      if (newFiles.length > 1) {
        swal({
          title: "Alerta",
          text: "Solo puede cargar un archivo",
          icon: "warning",
        });
        return;
      }
      if (!/(.jpg|.jpeg|.png|.pdf)$/i.test(newFiles[0].name)) {
        swal({
          title: "Alerta",
          text: "Solo puede cargar archivos de tipo PDF y JPG o PNG",
          icon: "warning",
        });
        return;
      }
      setFile(Array.from(newFiles));
      setFlagFile(true);
    }
  };

  const deleteFile = () => {
    setFile([]);
    setData({
      folioCotizacion: "",
      productos: {},
      subtotalCotizacion: 0,
      ivaCotizacion: 0,
      totalCotizacion: 0,
    });
    setNuevo({
      producto: "",
      cantidad: 0,
      precio: 0,
    });
    setFlagFile(false);
  };

  const addProduct = () => {
    if (!nuevo.producto) {
      swal({
        title: "Alerta",
        text: "Debe agregar el nombre del producto",
        icon: "warning",
      });
      return;
    }

    if (nuevo.cantidad < 1 || nuevo.precio < 1) {
      swal({
        title: "Alerta",
        text: "La cantidad o el precio son invalidos",
        icon: "warning",
      });
      return;
    }
    let productos = data.productos;
    productos.push(nuevo);
    let subtotal = 0;
    productos.forEach((item) => {
      subtotal += item.precio * item.cantidad;
    });
    const iva = (subtotal * 0.16).toFixed(2);
    const total = (subtotal * 1.16).toFixed(2);
    setData({
      ...data,
      subtotalCotizacion: subtotal,
      ivaCotizacion: iva,
      totalCotizacion: total,
      productos: productos,
    });
    setNuevo({
      producto: "",
      cantidad: 0,
      precio: 0,
    });
  };

  const handleDeleteProduct = (product, index) => {
    swal({
      title: "Alerta",
      text: `¿Está seguro que desea eliminar el producto ${product.producto}`,
      icon: "warning",
    }).then((response) => {
      if (response) {
        let productos = data.productos;
        productos.splice(index, 1);
        let subtotal = 0;
        productos.forEach((item) => {
          subtotal += item.precio * item.cantidad;
        });
        subtotal = subtotal > 0 ? subtotal : 0;
        const iva = subtotal > 0 ? (subtotal * 0.16).toFixed(2) : 0;
        const total = subtotal > 0 ? (subtotal * 1.16).toFixed(2) : 0;
        setData({
          ...data,
          subtotalCotizacion: subtotal,
          ivaCotizacion: iva,
          totalCotizacion: total,
          productos: productos,
        });
      }
    });
  };

  const alertSave = () => {
    swal({
      title: "Alerta",
      text: `¿Desea crear la cotización? (una vez creada no podrá agregar más productos)`,
      icon: "warning",
    }).then((response) => {
      if (response) {
        addCotizacion();
      }
    });
  };

  const addCotizacion = () => {
    if (!data.folioCotizacion.trim()) {
      swal({
        title: "Alerta",
        text: "Debe agregar el folio de la cotización",
        icon: "warning",
      });
      return;
    }
    if (data.productos.length < 1) {
      swal({
        title: "Alerta",
        text: "Debe agregar por lo menos un producto a la cotización",
        icon: "warning",
      });
      return;
    }
    if (!idSolicitud) {
      swal({
        title: "Alerta",
        text: "La solicitud no fue encontrada",
        icon: "warning",
      });
      return;
    }
    if (!flagFile) {
      swal({
        title: "Alerta",
        text: "Debe cargar la cotización en formato de imagen o pdf",
        icon: "warning",
      });
      return;
    }
    setLoading(true);
    const formData = {
      idSolicitud: idSolicitud,
      folioCotizacion: data.folioCotizacion,
      productos: JSON.stringify(data.productos),
      subtotal: data.subtotalCotizacion,
      iva: data.ivaCotizacion,
      total: data.totalCotizacion,
      archivo: file,
    };
    uploadFiles({ data: formData, api: "proyectos/createCotizacion", token })
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.success && res.results) {
          swal({
            title: "Guardado con éxito",
            text: "Cotización creada con éxito",
            icon: "success",
          });
          setLoading(false);
          deleteFile();
          backSolicitud();
        } else {
          swal({
            title: "Atención",
            text: res.message,
            icon: "warning",
          });
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        swal({
          title: "Atención",
          text: error.message,
          icon: "warning",
        });
      });
  };

  const style = {
    table: {
      width: "100%",
      display: "table",
      borderSpacing: 0,
      borderCollapse: "separate",
      fontSize: "8px",
    },
    th: {
      top: 0,
      left: 0,
      zIndex: 2,
      position: "sticky",
      backgroundColor: "#fff",
    },
    td: {
      padding: 3,
    },
  };

  return (
    <>
      <Modal size="xl" isOpen={true} toggle={() => backSolicitud()}>
        <ModalHeader>Nueva Cotización</ModalHeader>
        <ModalBody>
          <Row style={{ width: "100%" }}>
            <Col
              sm="5"
              style={{
                width: "100%",
                height: "100%",
              }}
            >
              {flagFile ? (
                <>
                  {file.map((f) => {
                    return (
                      <FormGroup>
                        {f.type === "application/pdf" ? (
                          <object
                            type="application/pdf"
                            aria-labelledby={f.name}
                            data={URL.createObjectURL(f)}
                            width="100%"
                            height="300px"
                          ></object>
                        ) : (
                          <CardImg
                            variant="top"
                            src={URL.createObjectURL(f)}
                            style={{ height: "300px", width: "85%" }}
                          ></CardImg>
                        )}
                        <Label style={{ fontSize: "8px" }}>{f.name}</Label>
                      </FormGroup>
                    );
                  })}
                </>
              ) : (
                <FormGroup style={{ width: "100%", height: "100%" }}>
                  <Input
                    id="exampleFile"
                    name="file"
                    type="file"
                    size="sm"
                    style={{ cursor: "pointer" }}
                    multiple
                    onChange={(e) => handleSelectItems(e.target.files)}
                  />
                  <Button
                    style={{
                      background: "#f5f8f9",
                      color: "black",
                      width: "100%",
                      height: "380px",
                      borderWidth: "2px",
                      borderStyle: "dotted",
                      borderColor: "#b3c8c8",
                      padding: "2rem 0rem",
                    }}
                    onClick={() => {}}
                  >
                    Seleccione o arrastre la cotización
                  </Button>
                </FormGroup>
              )}
            </Col>
            <Col sm="7">
              <Row style={{ width: "100%" }}>
                <Col sm="12" xs="12">
                  <Row>
                    <Col style={{ textAlign: "right" }}>
                      <Label size="sm">Folio Cotización:</Label>
                    </Col>
                    <Col>
                      <Input
                        name="folioCotizacion"
                        type="text"
                        size="sm"
                        value={data.folioCotizacion}
                        style={{
                          borderColor:
                            data.folioCotizacion === ""
                              ? "#d03d3dcf"
                              : "#3dd03dcf",
                        }}
                        disabled={loading}
                        onChange={(e) =>
                          setData({
                            ...data,
                            folioCotizacion: e.target.value.toUpperCase(),
                          })
                        }
                      ></Input>
                    </Col>
                  </Row>
                </Col>
                <Col sm="12">
                  <hr></hr>
                </Col>
                <Col sm="12" xs="12">
                  <Row>
                    <Col>
                      <Row>
                        <Col sm="2">
                          <Label size="sm">Producto:</Label>
                        </Col>
                        <Col sm="10">
                          <Input
                            name="nuevoProducto"
                            type="text"
                            size="sm"
                            disabled={flagFolioC || loading}
                            value={nuevo.producto}
                            onChange={(e) =>
                              setNuevo({
                                ...nuevo,
                                producto: e.target.value.toUpperCase(),
                              })
                            }
                          ></Input>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Row>
                        <Col sm="2">
                          <Label size="sm">Precio:</Label>
                        </Col>
                        <Col sm="2">
                          <Input
                            name="nuevoPrecio"
                            disabled={flagFolioC || loading}
                            type="text"
                            size="sm"
                            value={nuevo.precio}
                            onChange={(e) =>
                              setNuevo({
                                ...nuevo,
                                precio: e.target.value.toUpperCase(),
                              })
                            }
                          ></Input>
                        </Col>
                        <Col sm="2">
                          <Label size="sm">Cantidad:</Label>
                        </Col>
                        <Col sm="2">
                          {" "}
                          <Input
                            name="nuevoCantidad"
                            type="text"
                            disabled={flagFolioC || loading}
                            size="sm"
                            value={nuevo.cantidad}
                            onChange={(e) =>
                              setNuevo({
                                ...nuevo,
                                cantidad: e.target.value.toUpperCase(),
                              })
                            }
                          ></Input>
                        </Col>
                        <Col sm="4" xs="12" style={{ textAlign: "center" }}>
                          <Button
                            color="info"
                            size="sm"
                            disabled={flagProducto || loading}
                            onClick={() => addProduct()}
                          >
                            Agregar
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
                <Col sm="12">
                  <hr></hr>
                </Col>
                <Col
                  style={{ width: "100%", overflow: "scroll", height: "140px" }}
                >
                  <Table
                    responsive
                    striped
                    bordered
                    hover
                    size="sm"
                    maxHeight={600}
                  >
                    <thead>
                      <tr>
                        <th style={style.th}></th>
                        <th style={style.th}>#</th>
                        <th style={style.th}>Producto</th>
                        <th style={style.th}>Cantidad</th>
                        <th style={style.th}>Precio U.</th>
                        <th style={style.th}>Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.productos.length > 0 &&
                        data.productos.map((item, index) => (
                          <tr key={index}>
                            <td style={style.td} className="text-center">
                              <Button
                                size="small"
                                color="danger"
                                onClick={(e) =>
                                  handleDeleteProduct(item, index)
                                }
                                style={{ padding: 5 }}
                              >
                                <span className="btn-label">
                                  <i className="fa fa-trash fa-1x" />
                                </span>
                              </Button>
                            </td>
                            <td style={style.td}>{index + 1}</td>
                            <td style={style.td} className="text-center">
                              {item.producto}
                            </td>
                            <td style={style.td} className="text-center">
                              {item.cantidad}
                            </td>
                            <td style={style.td} className="text-center">
                              {new Intl.NumberFormat("en-EN").format(
                                item.precio
                              )}
                            </td>
                            <td style={style.td} className="text-center">
                              {new Intl.NumberFormat("en-EN").format(
                                item.precio * item.cantidad
                              )}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                </Col>
                <Col sm="12">
                  <hr></hr>
                </Col>
                <Col sm="12" xs="12">
                  <Row>
                    <Col sm="2" style={{ textAlign: "right" }}>
                      <Label size="sm">Subtotal:</Label>
                    </Col>
                    <Col sm="2">
                      <Input
                        size="sm"
                        name="subtotalCotizacion"
                        type="text"
                        value={new Intl.NumberFormat("en-EN").format(
                          data.subtotalCotizacion
                        )}
                        disabled={true}
                      ></Input>
                    </Col>
                    <Col sm="1" style={{ textAlign: "right" }}>
                      <Label size="sm">IVA:</Label>
                    </Col>
                    <Col sm="3">
                      <Input
                        size="sm"
                        name="ivaCotizacion"
                        type="text"
                        value={new Intl.NumberFormat("en-EN").format(
                          (data.subtotalCotizacion * 0.16).toFixed(2)
                        )}
                        disabled={true}
                      ></Input>
                    </Col>
                    <Col sm="1" style={{ textAlign: "right" }}>
                      <Label size="sm">Total:</Label>
                    </Col>
                    <Col sm="3">
                      <Input
                        size="sm"
                        name="ivaCotizacion"
                        type="text"
                        value={new Intl.NumberFormat("en-EN").format(
                          (data.subtotalCotizacion * 1.16).toFixed(2)
                        )}
                        disabled={true}
                      ></Input>
                    </Col>
                  </Row>
                </Col>
                <Col sm="12" xs="12">
                  <Row></Row>
                </Col>
                <Col sm="12" xs="12">
                  <Row></Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button
            size="sm"
            color="danger"
            disabled={loading}
            onClick={() => {
              deleteFile();
              backSolicitud();
            }}
          >
            Cancelar
          </Button>
          {loading ? (
            <Spinner />
          ) : (
            <Button
              size="sm"
              color="success"
              disabled={flagFolioC || data.productos.length === 0 || loading}
              onClick={() => {
                alertSave();
              }}
            >
              Guardar
            </Button>
          )}
        </ModalFooter>
      </Modal>
    </>
  );
};

export default ModalCotizacionProyectos;
