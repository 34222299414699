import React, { useState, useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  Label,
  Form,
  FormGroup,
  Input,
  Spinner,
  Table,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import swal from "sweetalert";
import ReactTable from "react-table";
import { CircleSpinner } from "react-spinners-kit";
import EjecutarApi from "../../Consultas/Consultas";
import Notifications from "react-notify-toast";
import { getVars } from "../../GlobalStorage";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import Pagination from "../../components/pagination";
import Sidebar from "../../components/Sidebar";
import { FormatedUser } from "../../data/interfaces/User";
import { isNullOrUndefined } from "../../utils/emptyValidations";
import PluginFilterUsers from "../../components/PluginFilterUsers";
import { UserValidator } from "../../data/validators/Users";

const User = (props) => {
  const [userData, setUserData] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const [menu, setMenu] = useState({
    Ver: 0,
    Agregar: 0,
    Editar: 0,
    Eliminar: 0,
    Seguimiento: 0,
    Exportar: 0,
    Imprimir: 0,
    ViewAll: 0,
    idTipoUser: 0,
  });
  const history = useHistory();
  const [total, setTotal] = useState(0);
  const [activeTab, setActiveTab] = useState("1");
  const [form, setForm] = useState(FormatedUser);
  const [isLoadingUser, setIsLoadingUser] = useState(false);
  const [loadingMenu, setLoadingMenu] = useState(false);
  const [loadingCedulaList, setLoadingCedulaList] = useState(false);
  const [dataCedulaList, setDataCedulaList] = useState([]);
  const [menuLoaded, setMenuLoaded] = useState(false);
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [loadingCatalogs, setLoadingCatalogs] = useState(false);
  const [catalogs, setCatalogs] = useState({
    regiones: [],
    roles: [],
    menus: [],
  });
  const [userMenus, setUserMenus] = useState([]);
  const [errors, setErrors] = useState({
    error: false,
    field: "",
    message: "",
  });
  //Filtro
  const [filtro, setFiltro] = useState([]);
  const [pageOptions, setPage] = useState({
    page: 0,
    pageSize: 10,
  });
  const style = {
    table: {
      width: "100%",
      display: "table",
      borderSpacing: 0,
      borderCollapse: "separate",
      fontSize: "8px",
    },
    th: {
      top: 0,
      left: 0,
      zIndex: 2,
      position: "sticky",
      textAlign: "center",
      backgroundColor: "#fff",
      fontSize: "10px",
      background: "#74c8cc",
      color: "white",
      fontWeight: "bold",
    },
    td: {
      padding: 3,
    },
  };

  const columns = [
    {
      Header: "Acciones",
      headerClassName: "text-center",
      minWidth: 45,
      id: "Acciones",
      accessor: (d) => d.Acciones,
      className: "text-center",
      filterAll: false,
      style: { fontSize: "10px" },
      headerStyle: { fontSize: "10px" },
    },
    {
      Header: "Nombre",
      headerClassName: "text-center",
      minWidth: 70,
      id: "Remesa",
      accessor: (d) => d.Nombre,
      filterAll: true,
      style: { fontSize: "10px" },
      headerStyle: { fontSize: "10px" },
      className: "text-center",
    },
    {
      Header: "Region",
      headerClassName: "text-center",
      minWidth: 25,
      id: "Region",
      accessor: (d) => d.Region,
      filterAll: true,
      style: { fontSize: "10px" },
      headerStyle: { fontSize: "10px" },
      className: "text-center",
    },
    {
      Header: "Usuario",
      headerClassName: "text-center",
      minWidth: 50,
      id: "email",
      accessor: (d) => d.email,
      filterAll: false,
      style: { fontSize: "10px" },
      headerStyle: { fontSize: "10px" },
      className: "text-center",
    },
    {
      Header: "Tipo Usuario",
      headerClassName: "text-center",
      minWidth: 60,
      id: "TipoUser",
      accessor: (d) => d.TipoUser,
      filterAll: false,
      style: { fontSize: "10px" },
      headerStyle: { fontSize: "10px" },
      className: "text-center",
    },
    {
      Header: "Celular",
      headerClassName: "text-center",
      minWidth: 60,
      id: "TelCelular",
      accessor: (d) => d.TelCelular,
      filterAll: false,
      style: { fontSize: "10px" },
      headerStyle: { fontSize: "10px" },
      className: "text-center",
    },
    {
      Header: "Estatus",
      headerClassName: "text-center",
      minWidth: 60,
      id: "Estatus",
      accessor: (d) => d.Estatus,
      filterAll: false,
      style: { fontSize: "10px" },
      headerStyle: { fontSize: "10px" },
      className: "text-center",
    },
  ];

  useEffect(() => {
    if (isNullOrUndefined(userData)) return;
    const menus = userData.menu;
    const currentMenu = menus.find((menu) => menu.idMenu === 40);
    if (isNullOrUndefined(currentMenu) || currentMenu.Ver < 1) {
      swal({
        title: "Atención",
        text: "No tiene permisos para este módulo, contacte al administrador",
        icon: "warning",
      });
      history.push("/not-found");
      return;
    }
    setMenu({
      Ver: currentMenu.Ver,
      Agregar: currentMenu.Agregar,
      Editar: currentMenu.Editar,
      Eliminar: currentMenu.Eliminar,
      Seguimiento: currentMenu.Seguimiento,
      Exportar: currentMenu.Exportar,
      Imprimir: currentMenu.Imprimir,
      ViewAll: currentMenu.ViewAll,
      idTipoUser: userData.user.idTipoUser.id,
    });
    setMenuLoaded(true);
  }, [history, userData]);

  const handleMiniClick = () => {
    document.body.classList.toggle("sidebar-mini");
  };

  useEffect(() => {
    setUserData(getVars("Token"));
  }, []);

  useEffect(() => {
    const token = getVars("Token");
    EjecutarApi({}, `users/getCatalogs`, token.token, "GET")
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.success && res.results) {
          let cat = res.data;
          setCatalogs(cat);
        } else {
          swal({
            title: "Ha ocurrido un error",
            text: res.errors,
            icon: "warning",
          });
        }
        setLoadingCatalogs(false);
      })
      .catch((error) => {
        setLoadingCatalogs(false);
        swal({
          title: "Ha ocurrido un error",
          text: error.errors,
          icon: "warning",
        });
      });
  }, []);

  const fetchData = useCallback(
    ({ pageSize, page, sorted, params }) => {
      const data = {
        page: page > pageOptions.page ? page : pageOptions.page,
        pageSize: pageSize,
        programa: 1,
        filtered: filtro,
      };
      setPage({
        page: page,
        pageSize: pageSize,
      });
      getRegistros(data);
    },
    [pageOptions.page, filtro]
  );

  const getRegistros = (data) => {
    const token = getVars("Token");
    setLoadingCedulaList(true);
    data.filtered = filtro;
    EjecutarApi(data, "users/getAll", token.token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.results && res.success) {
          setDataCedulaList(
            res.data.map((item) => {
              if (item.Paterno == null) item.Paterno = "";
              if (item.Materno == null) item.Materno = "";
              return {
                ...item,
                Acciones: (
                  <Row style={{ margin: "0" }}>
                    <Col style={{ padding: "0" }}>
                      <Button
                        size="sm"
                        color="info"
                        onClick={() => {
                          setForm({
                            ...form,
                            id: item.id,
                            Nombre: item.Nombre,
                            Paterno: item.Paterno,
                            Materno: item.Materno ? item.Materno : "",
                            TelCelular: item.TelCelular,
                            Correo: item.Correo ? item.Correo : "",
                            Region: item.Region,
                            email: item.email,
                            idTipoUser: item.idTipoUser,
                          });
                          setIsEdit(true);
                          setIsModalOpened(true);
                          getUserMenus(item.id);
                        }}
                      >
                        <div className="d-flex justify-content-center align-items-center">
                          <i
                            className="fa fa-pencil-square-o fa-2x"
                            aria-hidden="true"
                            style={{ margin: "0" }}
                          ></i>
                        </div>
                      </Button>
                    </Col>
                    <Col style={{ padding: "0" }}>
                      <Row>
                        <Col>
                          <Button
                            size="sm"
                            color={item.idStatus === 1 ? "success" : "danger"}
                            className="rounded-circle"
                            style={{ width: "30px", height: "30px" }}
                            onClick={() => saveAlert(item)}
                          >
                            <div className="d-flex justify-content-center align-items-center">
                              <i
                                className="fa fa-power-off fa-2x"
                                aria-hidden="true"
                                style={{ margin: "0" }}
                              ></i>
                            </div>
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                ),
                Nombre: `${item.Nombre} ${item.Paterno} ${item.Materno}`,
                Estatus: item.idStatus === 0 ? "Inactivo" : "Activo",
              };
            })
          );
          setTotal(res.total);
          setLoadingCedulaList(false);
        } else {
          swal({
            title: "¡Atención!",
            text: `${res.message}`,
            icon: "info",
          });
          setLoadingCedulaList(false);
        }
      })
      .catch((e) => {
        setLoadingCedulaList(false);
        setTimeout(() => {
          swal({
            title: "¡Atención!",
            text: `${e.message}`,
            icon: "warning",
          });
        }, 1000);
      });
  };

  const setFilter = (filtered) => {
    setFiltro(filtered.filtered);
    const token = getVars("Token");
    setLoadingCedulaList(true);

    EjecutarApi(filtered, "users/getAll", token.token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.results && res.success) {
          setDataCedulaList(
            res.data.map((item) => {
              if (item.Paterno == null) item.Paterno = "";
              if (item.Materno == null) item.Materno = "";
              return {
                ...item,
                Acciones: (
                  <Row style={{ margin: "0" }}>
                    <Col style={{ padding: "0" }}>
                      <Button
                        size="sm"
                        color="info"
                        onClick={() => {
                          setForm({
                            ...form,
                            id: item.id,
                            Nombre: item.Nombre,
                            Paterno: item.Paterno,
                            Materno: item.Materno ? item.Materno : "",
                            TelCelular: item.TelCelular,
                            email: item.email,
                            Correo: item.Correo ? item.Correo : "",
                            Region: item.Region,
                            idTipoUser: item.idTipoUser,
                          });
                          setIsEdit(true);
                          setIsModalOpened(true);
                          getUserMenus(item.id);
                        }}
                      >
                        <div className="d-flex justify-content-center align-items-center">
                          <i
                            className="fa fa-pencil-square-o fa-2x"
                            aria-hidden="true"
                            style={{ margin: "0" }}
                          ></i>
                        </div>
                      </Button>
                    </Col>
                    <Col style={{ padding: "0" }}>
                      <Row>
                        <Col>
                          <Button
                            size="sm"
                            color={item.idStatus === 1 ? "success" : "danger"}
                            className="rounded-circle"
                            style={{ width: "30px", height: "30px" }}
                            onClick={() => saveAlert(item)}
                          >
                            <div className="d-flex justify-content-center align-items-center">
                              <i
                                className="fa fa-power-off fa-2x"
                                aria-hidden="true"
                                style={{ margin: "0" }}
                              ></i>
                            </div>
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                ),
                Nombre: `${item.Nombre} ${item.Paterno} ${item.Materno}`,
                Estatus: item.idStatus === 0 ? "Inactivo" : "Activo",
              };
            })
          );
          setTotal(res.total);
          setLoadingCedulaList(false);
        } else {
          swal({
            title: "¡Atención!",
            text: `${res.errors}`,
            icon: "info",
          });
        }
        setLoadingCedulaList(false);
      })
      .catch((e) => {
        setLoadingCedulaList(false);
        swal({
          title: "¡Atención!",
          text: `${e.message}`,
          icon: "warning",
        });
      });
  };

  const getUserMenus = async (id) => {
    setLoadingMenu(true);
    const token = getVars("Token");
    await EjecutarApi({}, `users/getMenusById/${id}`, token.token, "GET")
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.success && res.results) {
          let menus = res.data;
          setUserMenus(menus);
          setLoadingMenu(false);
        } else {
          swal({
            title: "Ha ocurrido un error",
            text: res.errors,
            icon: "warning",
          });
        }
        setLoadingCatalogs(false);
      })
      .catch((error) => {
        setLoadingCatalogs(false);
        setLoadingMenu(false);
        swal({
          title: "Ha ocurrido un error",
          text: error.errors,
          icon: "warning",
        });
      });
  };

  const updateStatus = (item) => {
    const token = getVars("Token");
    const data = {
      id: item.id,
      idStatus: item.idStatus === 0 ? 1 : 0,
    };
    setLoadingCedulaList(true);
    EjecutarApi(data, "users/update", token.token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.success && res.results) {
          swal({
            title: "Exito!",
            text: `El usuario esta ${
              item.idStatus === 0 ? "Activo" : "Inactivo"
            }`,
            icon: "success",
          });
          fetchData(pageOptions);
        } else {
          setLoadingCedulaList(false);
          swal({
            title: "¡Atención!",
            text: `${res.message}`,
            icon: "info",
          });
        }
      })
      .catch((e) => {
        setLoadingCedulaList(false);
        setTimeout(() => {
          swal({
            title: "¡Atención!",
            text: `${e.message}`,
            icon: "warning",
          });
        }, 1000);
      });
  };

  const saveAlert = (item) => {
    swal({
      title: "¡Atención!",
      text: `¿Está seguro de ${
        item.idStatus === 1 ? "desactivar" : "activar"
      } el usuario?`,
      dangerMode: true,
      icon: "warning",
      buttons: ["Cancelar", true],
    }).then((response) => {
      if (response) {
        updateStatus(item);
      }
    });
  };

  const saveUser = () => {
    setIsLoadingUser(true);
    setForm({
      ...form,
      Nombre: form.Nombre.trim(),
      Paterno: form.Paterno.trim(),
      Materno: form.Materno.trim(),
      TelCelular: form.TelCelular.trim(),
      email: form.email.trim(),
      password: form.password.trim(),
      Correo: form.Correo.trim(),
      idTipoUser: form.idTipoUser,
      Region: form.Region,
    });

    const hasErrors = UserValidator(form);
    if (hasErrors.error) {
      setErrors(hasErrors);
      setIsLoadingUser(false);
      return;
    }

    if (!isEdit || (isEdit && form.password !== "")) {
      if (form.password === "") {
        setErrors({
          error: true,
          field: "password",
          message: "Debe agregar la contraseña del usuario",
        });
        setIsLoadingUser(false);
        return;
      }

      if (form.password.length > 10 || form.password.length < 10) {
        setErrors({
          error: true,
          field: "password",
          message: "La contraseña debe contener 10 caracteres alfanumericos",
        });
        setIsLoadingUser(false);
        return;
      }
    }

    const token = getVars("Token");
    setLoadingCedulaList(true);
    const api = isEdit ? "update" : "create";
    EjecutarApi(form, "users/" + api, token.token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.success && res.results) {
          swal({
            title: "Exito!",
            text: `El usuario ha sido ${
              isEdit ? "actualizado" : "creado"
            } correctamente`,
            icon: "success",
          });
          if (!isEdit) {
            setIsEdit(true);
            setForm({ ...form, id: res.data, email: form.TelCelular });
          }

          getRegistros(pageOptions);
          setIsLoadingUser(false);
        } else {
          setLoadingCedulaList(false);
          swal({
            title: "¡Atención!",
            text: `${res.message}`,
            icon: "info",
          });
        }
      })
      .catch((e) => {
        setLoadingCedulaList(false);
        setIsLoadingUser(false);
        setTimeout(() => {
          swal({
            title: "¡Atención!",
            text: `${e.message}`,
            icon: "warning",
          });
        }, 1000);
      });
  };

  const checkAssistant = (user, flag) => {
    setLoadingMenu(true);
    if (!form.id) {
      swal({
        title: "¡Atención!",
        text: `Debe guardar el usuario antes de asignarle menús`,
        icon: "info",
      });
      setLoadingMenu(false);
      return;
    }
    const token = getVars("Token");
    const data = {
      idUser: form.id,
      idMenu: user.id,
      idTipoUser: form.idTipoUser,
      flag: flag,
    };
    EjecutarApi(data, "users/setMenu", token.token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.success && res.results) {
          swal({
            title: "Exito!",
            text: `El menú ha sido ${
              flag === 1 ? "asignado" : "desasignado"
            } correctamente`,
            icon: "success",
          });
          getUserMenus(form.id);
          setLoadingMenu(false);
        } else {
          setLoadingMenu(false);
          swal({
            title: "¡Atención!",
            text: `${res.message}`,
            icon: "info",
          });
        }
      })
      .catch((e) => {
        setLoadingMenu(false);
        setTimeout(() => {
          swal({
            title: "¡Atención!",
            text: `${e.message}`,
            icon: "warning",
          });
        }, 1000);
      });
  };

  const bloqueoMasivo = () => {
    swal({
      title: "¡Atención!",
      text: `¿Está seguro de bloquear todos los usuarios?`,
      dangerMode: true,
      icon: "warning",
      buttons: ["Cancelar", true],
    }).then((response) => {
      if (response) {
        const token = getVars("Token");
        setLoadingCedulaList(true);
        EjecutarApi({}, "users/bloqueoMasivo", token.token)
          .then(function (res) {
            return res;
          })
          .then((res) => {
            if (res.success && res.results) {
              swal({
                title: "Exito!",
                text: `Todos los usuarios han sido bloqueados`,
                icon: "success",
              });
              fetchData(pageOptions);
            } else {
              setLoadingCedulaList(false);
              swal({
                title: "¡Atención!",
                text: `${res.message}`,
                icon: "info",
              });
            }
          })
          .catch((e) => {
            setLoadingCedulaList(false);
            setTimeout(() => {
              swal({
                title: "¡Atención!",
                text: `${e.message}`,
                icon: "warning",
              });
            }, 1000);
          });
      }
    });
  };

  return (
    <>
      <div className="wrapper">
        <Sidebar {...props} bgColor="black" activeColor="info" />
        <div className="main-panel">
          <Navbar {...props} handleMiniClick={handleMiniClick} />
          <Notifications />
          <div className="content">
            <>
              <Modal
                isOpen={isModalOpened}
                toggle={() => setIsModalOpened(false)}
                keyboard={false}
                backdrop={"static"}
                size="lg"
              >
                <ModalBody>
                  <Nav
                    tabs
                    style={{
                      borderBottom: "1px solid",
                      borderColor: errors.error ? "red" : "#BBB",
                    }}
                  >
                    <NavItem>
                      <NavLink
                        className={activeTab === "1" ? "active" : ""}
                        onClick={() => {
                          setActiveTab("1");
                        }}
                      >
                        Información del usuario
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={activeTab === "2" ? "active" : ""}
                        disabled={!isEdit}
                        onClick={() => {
                          setActiveTab("2");
                        }}
                      >
                        Menus
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent
                    activeTab={activeTab}
                    style={{ paddingTop: "1rem" }}
                  >
                    <TabPane tabId="1">
                      <Row
                        style={{
                          width: "85%",
                          marginLeft: "auto",
                          marginRight: "auto",
                        }}
                      >
                        <Form>
                          <Row>
                            <Col sm="3" xs="12">
                              <Row
                                sm="12"
                                style={{ height: "100%", color: "#BBB" }}
                                className="d-flex justify-content-center align-items-center"
                              >
                                <i
                                  className="fa fa-address-card-o"
                                  aria-hidden="true"
                                  style={{ fontSize: "6rem" }}
                                ></i>
                              </Row>
                            </Col>
                            <Col sm="9" xs="12">
                              <Row>
                                <Col sm="12" xs="12">
                                  <FormGroup>
                                    <Label>
                                      Nombres
                                      <span className="text-danger">*</span>
                                    </Label>
                                    <Input
                                      name="Nombre"
                                      type="text"
                                      bsSize="sm"
                                      disabled={isEdit}
                                      value={form.Nombre}
                                      style={{
                                        textTransform: "uppercase",
                                        borderColor:
                                          errors.field === "Nombre"
                                            ? "#f99797"
                                            : "",
                                      }}
                                      onChange={(e) =>
                                        setForm({
                                          ...form,
                                          Nombre: e.target.value
                                            .toUpperCase()
                                            .replace(/\d/, ""),
                                        })
                                      }
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>
                              <Row>
                                <Col sm="6" xs="12">
                                  <FormGroup>
                                    <Label>
                                      Paterno
                                      <span className="text-danger">*</span>
                                    </Label>
                                    <Input
                                      name="Paterno"
                                      type="text"
                                      disabled={isEdit}
                                      value={form.Paterno}
                                      style={{
                                        textTransform: "uppercase",
                                        borderColor:
                                          errors.field === "Paterno"
                                            ? "#f99797"
                                            : "",
                                      }}
                                      onChange={(e) =>
                                        setForm({
                                          ...form,
                                          Paterno: e.target.value
                                            .toUpperCase()
                                            .replace(/\d/, ""),
                                        })
                                      }
                                    />
                                  </FormGroup>
                                </Col>
                                <Col sm="6" xs="12">
                                  <FormGroup>
                                    <Label>Materno</Label>
                                    <Input
                                      name="Materno"
                                      type="text"
                                      disabled={isEdit}
                                      value={form.Materno}
                                      style={{
                                        textTransform: "uppercase",
                                      }}
                                      onChange={(e) =>
                                        setForm({
                                          ...form,
                                          Materno: e.target.value
                                            .toUpperCase()
                                            .replace(/\d/, ""),
                                        })
                                      }
                                    />
                                  </FormGroup>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                          <Row>
                            <Col sm="4" xs="12">
                              <FormGroup>
                                <Label>
                                  Celular<span className="text-danger">*</span>
                                </Label>
                                <Input
                                  name="TelCelular"
                                  type="text"
                                  value={form.TelCelular}
                                  style={{
                                    borderColor:
                                      errors.field === "TelCelular"
                                        ? "#f99797"
                                        : "",
                                  }}
                                  maxLength={10}
                                  onChange={(e) =>
                                    setForm({
                                      ...form,
                                      TelCelular: e.target.value
                                        .trim()
                                        .replace(/\D/g, ""),
                                    })
                                  }
                                />
                              </FormGroup>
                            </Col>
                            <Col sm="4" xs="12">
                              <FormGroup>
                                <Label>
                                  Contraseña
                                  <span className="text-danger">*</span>
                                </Label>
                                <Input
                                  name="password"
                                  type="password"
                                  value={form.password}
                                  style={{
                                    borderColor:
                                      errors.field === "password"
                                        ? "#f99797"
                                        : "",
                                  }}
                                  onChange={(e) =>
                                    setForm({
                                      ...form,
                                      password: e.target.value
                                        .trim()
                                        .replace(" ", ""),
                                    })
                                  }
                                />
                              </FormGroup>
                            </Col>
                            <Col sm="4" xs="12">
                              <FormGroup>
                                <Label>Correo</Label>
                                <Input
                                  name="email"
                                  type="email"
                                  value={form.Correo}
                                  style={{
                                    borderColor:
                                      errors.field === "Correo"
                                        ? "#f99797"
                                        : "",
                                  }}
                                  onChange={(e) =>
                                    setForm({
                                      ...form,
                                      Correo: e.target.value.trim(),
                                    })
                                  }
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row>
                            <Col sm="6" xs="12">
                              <FormGroup>
                                <Label>Region:</Label>
                                <span className="text-danger">*</span>
                                {loadingCatalogs && <Spinner size="sm" />}
                                <Input
                                  name="Region"
                                  type="select"
                                  disabled={loadingCatalogs}
                                  style={{
                                    borderColor:
                                      errors.field === "Region"
                                        ? "#f99797"
                                        : "",
                                  }}
                                  value={form.Region}
                                  onChange={(e) =>
                                    setForm({
                                      ...form,
                                      Region: e.target.value,
                                    })
                                  }
                                >
                                  <option value={""}></option>
                                  {catalogs.regiones.map((element) => (
                                    <option
                                      key={element.value}
                                      value={element.value}
                                    >
                                      {element.label}
                                    </option>
                                  ))}
                                </Input>
                              </FormGroup>
                            </Col>

                            <Col sm="6" xs="12">
                              <FormGroup>
                                <Label>Tipo de usuario:</Label>
                                <span className="text-danger">*</span>
                                {loadingCatalogs && <Spinner size="sm" />}
                                <Input
                                  name="idTipoUser"
                                  type="select"
                                  style={{
                                    borderColor:
                                      errors.field === "idTipoUser"
                                        ? "#f99797"
                                        : "",
                                  }}
                                  disabled={loadingCatalogs}
                                  value={form.idTipoUser}
                                  onChange={(e) =>
                                    setForm({
                                      ...form,
                                      idTipoUser: e.target.value,
                                    })
                                  }
                                >
                                  <option value={""}></option>
                                  {catalogs.roles.map((element) => (
                                    <option
                                      key={element.value}
                                      value={element.value}
                                    >
                                      {element.label}
                                    </option>
                                  ))}
                                </Input>
                              </FormGroup>
                            </Col>
                          </Row>
                        </Form>
                      </Row>
                    </TabPane>
                    <TabPane tabId="2">
                      <Row sm="10" style={{ width: "90%", margin: "auto" }}>
                        <Col sm="12" xs="12">
                          {loadingMenu && <Spinner size="sm" />}
                          <Table
                            responsive
                            bordered
                            hover
                            size="sm"
                            style={{ fontSize: "9px" }}
                          >
                            <thead>
                              <tr>
                                <th style={style.th}>Acciones</th>
                                <th style={style.th}>Menu</th>
                                <th style={style.th}>Estatus</th>
                              </tr>
                            </thead>
                            <tbody>
                              {catalogs.menus.map((item, index) => (
                                <tr key={item.id}>
                                  <td style={style.td} className="text-center">
                                    {userMenus.find(
                                      (menu) => menu.id === item.id
                                    ) ? (
                                      <Button
                                        size="small"
                                        color="danger"
                                        disabled={loadingMenu}
                                        onClick={(e) => checkAssistant(item, 0)}
                                        style={{ padding: 5 }}
                                      >
                                        <span>
                                          <i className="fa fa-minus-square fa-2x" />
                                        </span>
                                      </Button>
                                    ) : (
                                      <Button
                                        size="small"
                                        color="info"
                                        disabled={loadingMenu}
                                        onClick={(e) => checkAssistant(item, 1)}
                                        style={{ padding: 5 }}
                                      >
                                        <span>
                                          <i className="fa fa-plus fa-2x" />
                                        </span>
                                      </Button>
                                    )}
                                  </td>
                                  <td>{item.Menu}</td>
                                  <td>
                                    {userMenus.find(
                                      (menu) => menu.id === item.id
                                    ) ? (
                                      <Label>Asignado</Label>
                                    ) : (
                                      <Label>Disponible</Label>
                                    )}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </Col>
                      </Row>
                    </TabPane>
                  </TabContent>
                  <Row>
                    <Col sm="12" xs="12" style={{ textAlign: "center" }}>
                      <span className="text-danger" style={{ color: "red" }}>
                        {errors.message}
                      </span>
                    </Col>
                  </Row>
                </ModalBody>
                <ModalFooter
                  style={{ borderColor: errors.error ? "red" : "#BBB" }}
                >
                  <Button
                    color="danger"
                    onClick={() => {
                      setIsModalOpened(false);
                      setForm(FormatedUser);
                      setUserMenus([]);
                      setIsEdit(false);
                      setActiveTab("1");
                      setErrors({
                        error: false,
                        field: "",
                        message: "",
                      });
                    }}
                  >
                    Cancelar
                  </Button>
                  {!isLoadingUser ? (
                    <Button
                      color="info"
                      onClick={() => {
                        saveUser();
                      }}
                    >
                      Guardar
                    </Button>
                  ) : (
                    <Spinner color="info" />
                  )}
                </ModalFooter>
              </Modal>
              <Row>
                <Col md="12">
                  <Card>
                    <CardHeader>
                      <Row>
                        <Col md="9">
                          <CardTitle tag="h6">Usuarios</CardTitle>
                        </Col>

                        <Col md="2">
                          {menu.Agregar === 1 ? (
                            <Button
                              color="primary"
                              size="sm"
                              onClick={() => {
                                setIsModalOpened(true);
                              }}
                            >
                              <span className="btn-label">
                                <i className="fa fa-user-plus fa-2x" />
                              </span>
                              Capturar
                            </Button>
                          ) : (
                            <></>
                          )}
                        </Col>
                        {userData && menu.ViewAll === 1 && (
                          <Col sm="1">
                            <Button
                              color="danger"
                              size="sm"
                              onClick={() => {
                                bloqueoMasivo();
                              }}
                            >
                              <div className="d-flex justify-content-center align-items-center">
                                <i
                                  className="fa fa-power-off fa-2x"
                                  aria-hidden="true"
                                  style={{ margin: "0" }}
                                ></i>
                              </div>
                            </Button>
                          </Col>
                        )}
                      </Row>
                    </CardHeader>
                    <CardBody>
                      {loadingCedulaList && (
                        <CircleSpinner
                          className="text-center"
                          size={30}
                          color="#686769"
                          loading={loadingCedulaList}
                        />
                      )}
                      <Col lg="12" md="12">
                        {menuLoaded && dataCedulaList && (
                          <ReactTable
                            size="sm"
                            data={dataCedulaList}
                            noDataText="No Existen Datos a Mostrar!"
                            manual
                            columns={columns}
                            className="-striped -highlight primary-pagination"
                            loading={loadingCedulaList}
                            showPagination={true}
                            showPaginationBottom={true}
                            showTotalPages={true}
                            showPageJump={false}
                            canNextFromData={true}
                            defaultPageSize={pageOptions.pageSize}
                            total={total}
                            page={pageOptions.page}
                            pages={Math.floor(total / pageOptions.pageSize)}
                            previousText="Anterior"
                            nextText="Siguiente"
                            ofText="de"
                            pageSizeOptions={[10, 20, 25, 50, 100]}
                            PaginationComponent={Pagination}
                            onFetchData={fetchData}
                          />
                        )}
                      </Col>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <PluginFilterUsers
                sendFiltro={setFilter}
                catRegiones={catalogs.regiones}
                catRoles={catalogs.roles}
                clasess="dropdowns"
              />
              <Footer fluid />
            </>
          </div>
        </div>
      </div>
    </>
  );
};
export default User;
