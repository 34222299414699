import React, { useState, useEffect } from "react";
import moment from "moment";
import { Button, Input, Form, Label } from "reactstrap";
import Select from "react-select";
import EjecutarApi from "../Consultas/Consultas";
import { notify } from "react-notify-toast";
import { getVars } from "../GlobalStorage";
import "./styles.css";

function PluginFilterSolicitudCedula(props) {
  const { sendFiltro } = props;
  const [classes, setclasses] = useState("dropdown");
  const token = getVars("Token");

  const [form, setForm] = useState({
    FechaSolicitud: "",
    CURP: "",
    RFC: "",
    Nombre: "",
    Materno: "",
    Paterno: "",
    Cedula: false,
    idEstatus: false,
    ListosParaEnviar: false,
    ListosParaEnviarCedula: false,
    Region: "",
    Municipio: "",
    FolioSolicitud: "",
    FolioVentanilla: "",
    FechaCreo: "",
    Enviadas: "",
  });
  const [MultiValueMuninicpio, setMultiValueMunicipio] = useState([]);
  const [MultiValueMuninicpio2, setMultiValueMunicipio2] = useState([]);
  const [MultiValueRegion, setMultiValueRegion] = useState([]);
  const [filterRegion, setfilterRegion] = useState([]);

  const handleClick = () => {
    if (classes === "dropdown") {
      setclasses("dropdown show");
    } else {
      setclasses("dropdown");
    }
  };

  useEffect(() => {
    //Municipios
    EjecutarApi({}, "calentadores/getMunicipiosVales", token.token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.results) {
          var region = [];
          setMultiValueMunicipio(
            res.data.map((municipio, i) => {
              if (region.indexOf(municipio["SubRegion"]) === -1) {
                region.push(municipio["SubRegion"]);
              }
              return {
                value: municipio["Id"],
                label: municipio["Nombre"],
              };
            })
          );
          var regio_n = region.sort((a, b) => a - b);
          setfilterRegion(
            regio_n.map((region_) => {
              return { value: region_, label: region_ };
            })
          );
        }
      })
      .catch((e) => {
        notify.show(`Error en la busqueda! ${e.message}`, "custom", 5000, {
          background: "#d61818",
          text: "#FFFFFF",
        });
      });
  }, []);

  const handleMultiRegion = (option) => {
    setMultiValueRegion(option);
  };

  const setFilter = (event) => {
    event.preventDefault();
    let region = [];

    if (MultiValueRegion) {
      if (MultiValueRegion.length > 0) {
        MultiValueRegion.map((r) => {
          region.push(r.value);
        });
      }
    }
    let filter = [];
    if (form.FechaSolicitud.length > 0)
      filter.push({
        id: ".FechaSolicitud",
        value: form.FechaSolicitud,
      });
    if (form.FechaCreo.length > 0)
      filter.push({
        id: ".FechaCreo",
        value: form.FechaCreo,
      });
    if (form.CURP.length > 0) filter.push({ id: ".CURP", value: form.CURP });
    if (form.RFC.length > 0) filter.push({ id: ".RFC", value: form.RFC });
    if (form.Nombre.length > 0)
      filter.push({ id: ".Nombre", value: form.Nombre });
    if (form.Paterno.length > 0)
      filter.push({ id: ".Paterno", value: form.Paterno });
    if (form.Materno.length > 0)
      filter.push({ id: ".Materno", value: form.Materno });
    if (form.FolioVentanilla.length > 0)
      filter.push({ id: ".Folio", value: form.FolioVentanilla });
    if (form.FolioSolicitud.length > 0)
      filter.push({ id: ".id", value: form.FolioSolicitud });
    if (form.ListosParaEnviar)
      filter.push({ id: ".ListaParaEnviar", value: 1 });
    if (form.idEstatus) filter.push({ id: ".idEstatus", value: 1 });
    if (form.ListosParaEnviarCedula)
      filter.push({ id: ".ListaParaEnviarCedula", value: 1 });
    if (form.Enviadas) filter.push({ id: ".idEstatus", value: 8 });
    if (region.length > 0) filter.push({ id: "region", value: region });
    if (MultiValueMuninicpio2.length > 0)
      filter.push({ id: ".MunicipioVive", value: MultiValueMuninicpio2 });

    let datos_ = {};
    datos_ = {
      page: 0,
      tipo: "or",
      pageSize: 10,
      filtered: filter,
    };
    sendFiltro(datos_);
  };

  return (
    <div className="fixed-plugin">
      <Form onSubmit={setFilter}>
        <div className={classes}>
          <div onClick={handleClick}>
            <i className="fa fa-cog fa-2x" />
          </div>
          <ul className="dropdown-menu show ul">
            <li className="adjustments-line">Folio Solicitud:</li>
            <Input
              type="text"
              name="FolioSolicitud"
              id="FolioSolicitud"
              placeholder="Folio Solicitud"
              value={form.FolioSolicitud}
              onChange={(e) =>
                setForm({
                  ...form,
                  FolioSolicitud: e.target.value.toUpperCase(),
                })
              }
              className="form-control"
            />

            <li className="adjustments-line">Folio Ventanilla:</li>
            <Input
              type="text"
              name="FolioVentanilla"
              id="FolioVentanilla"
              placeholder="Folio Ventanilla"
              value={form.FolioVentanilla}
              onChange={(e) =>
                setForm({
                  ...form,
                  FolioVentanilla: e.target.value.toUpperCase(),
                })
              }
              className="form-control"
            />

            <li className="adjustments-line">Fecha Solicitud:</li>
            <Input
              type="date"
              name="FechaSolicitud"
              id="FechaSolicitud"
              placeholder="Fecha Solicitud"
              value={form.FechaSolicitud}
              onChange={(e) =>
                setForm({
                  ...form,
                  FechaSolicitud: moment(e.target.value).format("Y-MM-DD"),
                })
              }
              className="form-control"
            />
            <li className="adjustments-line">Fecha Creación:</li>
            <Input
              type="date"
              name="FechaCreo"
              id="FechaCreo"
              placeholder="Fecha Creación"
              value={form.FechaCreo}
              onChange={(e) =>
                setForm({
                  ...form,
                  FechaCreo: moment(e.target.value).format("Y-MM-DD"),
                })
              }
              className="form-control"
            />
            <li className="adjustments-line">SOLICITANTE:</li>
            <Input
              id="nombre"
              type="text"
              placeholder="Nombre"
              value={form.Nombre}
              onChange={(event) =>
                setForm({
                  ...form,
                  Nombre: event.target.value.toUpperCase(),
                })
              }
            />
            <Input
              id="paterno"
              type="text"
              placeholder="Apellido paterno"
              value={form.Paterno}
              onChange={(event) =>
                setForm({
                  ...form,
                  Paterno: event.target.value.toUpperCase(),
                })
              }
            />
            <Input
              id="materno"
              type="text"
              placeholder="Apellido materno"
              value={form.Materno}
              onChange={(event) =>
                setForm({
                  ...form,
                  Materno: event.target.value.toUpperCase(),
                })
              }
            />
            <Input
              type="text"
              name="CURP"
              placeholder="CURP"
              value={form.CURP}
              style={{ textTransform: "uppercase" }}
              onChange={(e) =>
                setForm({
                  ...form,
                  CURP: e.target.value.toUpperCase(),
                })
              }
            />
            <Input
              type="text"
              name="RFC"
              placeholder="RFC"
              value={form.RFC}
              style={{ textTransform: "uppercase" }}
              onChange={(e) =>
                setForm({
                  ...form,
                  RFC: e.target.value.toUpperCase(),
                })
              }
            />
            <li className="adjustments-line">
              <Label>Región:</Label>
            </li>
            <li className="li">
              <Select
                name="region"
                className="SelectSize"
                placeholder="Región"
                value={MultiValueRegion}
                options={filterRegion}
                onChange={handleMultiRegion}
                isMulti
              />
            </li>
            <li className="adjustments-line">
              <Label>Municipio:</Label>
            </li>
            <li className="li">
              <Select
                className="SelectSize"
                name="Municipio"
                placeholder="Municipio"
                options={MultiValueMuninicpio}
                onChange={(event, i) => {
                  if (event) {
                    setMultiValueMunicipio2(
                      event.map((dato) => {
                        return dato.label;
                      })
                    );
                  } else {
                    setMultiValueMunicipio2({
                      MultiValueMuninicpio2: MultiValueMuninicpio2.map(
                        (dato) => {
                          if (i.removedValue.value !== dato) {
                            return dato.label;
                          }
                        }
                      ),
                    });
                  }
                }}
                isMulti
              />
            </li>
            <li className="adjustments-line">
              <Label
                style={{
                  display: "block",
                  overflow: "hidden",
                  float: "left",
                }}
              >
                {"Solicitudes pendientes  "}
              </Label>{" "}
              <Input
                style={{
                  verticalAlign: "middle",
                  float: "right",
                  marginLeft: "5pt",
                }}
                type="checkbox"
                onChange={(e) =>
                  setForm({
                    ...form,
                    idEstatus: !form.idEstatus,
                  })
                }
              />
            </li>
            {/* <li className="adjustments-line">
              <Label
                style={{
                  display: "block",
                  overflow: "hidden",
                  float: "left",
                }}
              >
                {"Vales listos para enviar  "}
              </Label>{" "}
              <Input
                style={{
                  verticalAlign: "middle",
                  float: "right",
                  marginLeft: "5pt",
                }}
                type="checkbox"
                onChange={(e) =>
                  setForm({
                    ...form,
                    ListosParaEnviar: !form.ListosParaEnviar,
                  })
                }
              />
            </li> */}
            <li className="adjustments-line">
              <Label
                style={{
                  display: "block",
                  overflow: "hidden",
                  float: "left",
                }}
              >
                {"Listos para enviar  "}
              </Label>{" "}
              <Input
                style={{
                  verticalAlign: "middle",
                  float: "right",
                  marginLeft: "5pt",
                }}
                type="checkbox"
                onChange={(e) =>
                  setForm({
                    ...form,
                    ListosParaEnviarCedula: !form.ListosParaEnviarCedula,
                  })
                }
              />
            </li>

            <li className="adjustments-line">
              <Label
                style={{
                  display: "block",
                  overflow: "hidden",
                  float: "left",
                }}
              >
                {"Enviados a ventanilla  "}
              </Label>{" "}
              <Input
                style={{
                  verticalAlign: "middle",
                  float: "right",
                  marginLeft: "5pt",
                }}
                type="checkbox"
                onChange={(e) =>
                  setForm({
                    ...form,
                    Enviadas: !form.Enviadas,
                  })
                }
              />
            </li>
            {/* <li className="adjustments-line" /> */}
            <li className="button-container">
              <span>
                {/* {!isLoading && ( */}
                <Button color="info" block className="btn-round" type="submit">
                  Filtrar
                </Button>
              </span>
            </li>

            <li className="li"></li>
          </ul>
        </div>
      </Form>
    </div>
  );
}
export default PluginFilterSolicitudCedula;
