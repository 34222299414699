import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
} from "reactstrap";
// react component for creating dynamic tables
import ReactTable from "react-table";
import { CircleSpinner } from "react-spinners-kit";
import { URL_GENERAL } from "../data/consts";
import EjecutarApi from "../Consultas/Consultas";
import Notifications, { notify } from "react-notify-toast";

import { getVars } from "../GlobalStorage";

import { Link } from "react-router-dom";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Sidebar from "../components/Sidebar";
import FixedPlugin from "../components/PluginFilterVales";
import ValesUpdate from "./ValesUpdate";
import Pagination from "../components/pagination";

const Vales = (props) => {
  //const [flag, setFlag] = useState(false);
  const [totalVales, setTotalVales] = useState(0);
  const [loading, setLoading] = useState(false);
  //const [loadingGrupo, setLoadingGrupo] = useState(true);
  const [data, setData] = useState([]);
  const [regiones, setRegiones] = useState([]);
  const [_menu, _setmenu] = useState({});
  const token = getVars("Token");

  //update
  const [_item, _setItem] = useState({});
  const [isUpdate, setisUpdate] = useState(false);

  //Filtro
  const [Filtro, setFiltro] = useState({
    _Folio: "",
    _Ejercicio: "",
    _NombreCompleto: "",
    _NombreCreated: "",
    _UserOwned: "",
    _Remesa: "",
    _Municipio: "",
    _Colonia: "",
    _Region: [],
    _filtro: [],
  });

  useEffect(() => {
    setearMenu("Estado");
  }, []);

  useEffect(() => {}, [_menu]);

  const handleMiniClick = () => {
    document.body.classList.toggle("sidebar-mini");
  };

  const setFilter = (filtered) => {
    //Duplicados
    setFiltro({
      ...Filtro,
      _Folio: filtered.Folio,
      _Ejercicio: filtered.Ejercicio,
      _NombreCompleto: filtered.NombreCompleto,
      _NombreCreated: filtered.NombreCreated,
      _UserOwned: filtered.UserOwned,
      _Remesa: filtered.Remesa,
      _Municipio: filtered.Municipio,
      _Colonia: filtered.Colonia,
      _Region: filtered.Regiones,
      _filtro: filtered.filtered,
      _Duplicados: filtered.Duplicados,
    });
    console.log("Filtro", Filtro);
    let menu_local_storage = setearMenu("Storage");
    var ParametrosVale = filtered;
    var editar = false;
    setLoading(true);

    EjecutarApi(ParametrosVale, "getValesV2", token.token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.results) {
          setTotalVales(res.total);
          setLoading(false);
          setData(
            res.data.map((prop, key) => {
              editar = false;
              if (menu_local_storage.regiones.length === 0) {
                if (menu_local_storage.Editar === 1) {
                  if (prop.Remesa === null) {
                    if (
                      prop.UserOwned.id === token.user.id ||
                      prop.UserCreated.id === token.user.id
                    ) {
                      editar = true;
                    } else {
                      editar = false;
                    }
                  } else {
                    editar = false;
                  }
                } //else de Editar
                else {
                  editar = false;
                }
              } else if (prop.Remesa === null) {
                //regiones
                editar = true;
              }

              return {
                Nombre: editar ? (
                  <Button
                    color="info"
                    size="sm"
                    onClick={() => handleclickRowEdit(prop)}
                  >
                    <span className="btn-label">
                      <i className="nc-icon nc-ruler-pencil" />
                    </span>
                    {prop.Nombre} {prop.Paterno} {prop.Materno}
                  </Button>
                ) : (
                  `${prop.Nombre} ${prop.Paterno}
                  ${prop.Materno}`
                ),
                TelCelular: prop.TelCelular,
                ClaveUnica: prop.ClaveUnica,
                TelFijo: prop.TelFijo,
                Estatus: prop.idStatus.Estatus,
                CorreoElectronico: prop.CorreoElectronico,
                Municipio: prop.idMunicipio.Municipio,
                Region: prop.idMunicipio.Region,
                Colonia: prop.Colonia,
                Calle: prop.Calle,
                Articulador: `${prop.UserOwned.Nombre} ${prop.UserOwned.Paterno} ${prop.UserOwned.Materno}`,
                Capturo: `${prop.UserCreated.Nombre} ${prop.UserCreated.Paterno} ${prop.UserCreated.Materno}`,
                FechaCaptura: prop.created_at,
                data: prop,
              };
            })
          );
        } else if (res.total === 0) {
          setTotalVales(res.total);
          setData([]);
          setLoading(false);
          notify.show(`No Existen datos a Mostrar`, "custom", 5000, {
            background: "#AED6F1",
            text: "#FFFFFF",
          });
        }
      })
      .catch((e) => {
        notify.show(`Error en la busqueda! ${e.message}`, "custom", 5000, {
          background: "#d61818",
          text: "#FFFFFF",
        });
        setLoading(false);
      });
  };

  const setearMenu = (dependecia) => {
    if (dependecia === "Estado") {
      token.menu.forEach((prop, key) => {
        if (prop.Menu === "Vales") {
          _setmenu({
            Ver: prop.Ver,
            Agregar: prop.Agregar,
            Editar: prop.Editar,
            Eliminar: prop.Eliminar,
            Seguimiento: prop.Seguimiento,
            Exportar: prop.Exportar,
            Imprimir: prop.Imprimir,
            ViewAll: prop.ViewAll,
            idTipoUser: token.user.idTipoUser.id,
          });
          setRegiones({ regiones: prop.regiones });
        }
      });
    } else {
      var json = {};

      token.menu.forEach((prop, key) => {
        if (prop.Menu === "Vales") {
          _setmenu({
            Ver: prop.Ver,
            Agregar: prop.Agregar,
            Editar: prop.Editar,
            Eliminar: prop.Eliminar,
            Seguimiento: prop.Seguimiento,
            Exportar: prop.Exportar,
            Imprimir: prop.Imprimir,
            ViewAll: prop.ViewAll,
            idTipoUser: token.user.idTipoUser.id,
          });

          json = {
            Ver: prop.Ver,
            Agregar: prop.Agregar,
            Editar: prop.Editar,
            Eliminar: prop.Eliminar,
            Seguimiento: prop.Seguimiento,
            Exportar: prop.Exportar,
            Imprimir: prop.Imprimir,
            ViewAll: prop.ViewAll,
            idTipoUser: token.user.idTipoUser.id,
            regiones: token.regiones,
          };
        }
      });

      return json;
    }
  };

  const btnExportar = () => {
    var url = new URL(
      URL_GENERAL + `getReportesolicitudVales?token=${token.token}`
    );
    window.open(url, "_blank");
  };

  const btnExportarDesglosado = () => {
    var url = new URL(
      URL_GENERAL + `getReportesolicitudValesDesglosado?token=${token.token}`
    );
    window.open(url, "_blank");
  };

  const btnPadronPotencial = () => {
    var url = new URL(URL_GENERAL + `getPadronPotencial?token=${token.token}`);

    window.open(url, "_blank");
  };

  const onHide = (action) => {
    setisUpdate(action);
    var datax = {};
    let menu_local_storage = setearMenu("Storage");

    if (menu_local_storage.regiones.length === 0)
      datax = {
        page: 0,
        tipo: "or",
        Propietario: _menu.ViewAll === 1 ? "" : token.user.id,
        pageSize: 10,
        sorted: [
          { id: "vales.Paterno", desc: false },
          { id: "vales.FechaSolicitud", desc: true },
        ],
        filtered: [],
        //UserOwned:token.user.id
      };
    else
      datax = {
        page: 0,
        tipo: "or",
        //Propietario: _menu.ViewAll ==1 ? "":token.user.id,
        pageSize: 10,
        sorted: [
          { id: "vales.Paterno", desc: false },
          { id: "vales.FechaSolicitud", desc: true },
        ],
        filtered: [],
        Regiones: menu_local_storage.regiones,
      };
    setFilter(datax);
  };
  const handleclickRowEdit = (e) => {
    //var item = e;
    //let menu_local_storage = setearMenu("Storage");
    //if (menu_local_storage.Editar === 1) {
    _setItem(e);
    setisUpdate(true);
    //}
  };

  return (
    <>
      <div className="wrapper">
        <Sidebar {...props} bgColor="black" activeColor="info" />
        <div className="main-panel">
          <Navbar {...props} handleMiniClick={handleMiniClick} />
          <Notifications />
          <div className="content">
            <Row>
              <Col md="12">
                <Card>
                  <CardHeader>
                    <Row>
                      <Col md="4">
                        <CardTitle tag="h4">Listado de Vales</CardTitle>
                      </Col>
                    </Row>
                  </CardHeader>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <Card>
                  <CardHeader>
                    {_menu.Agregar === 1 ? (
                      <>
                        <Link to="valeAdd">
                          <Button color="success" size="sm">
                            <span className="btn-label">
                              <i className="fa fa-user-plus fa-2x" />
                            </span>
                            Capturar
                          </Button>
                        </Link>
                      </>
                    ) : (
                      <></>
                    )}
                    {_menu.Exportar === 1 ? (
                      <>
                        <Button color="success" size="sm" onClick={btnExportar}>
                          <span className="btn-label">
                            <i className="fa fa-file-excel-o fa-2x" />
                          </span>
                          Exportar
                        </Button>
                      </>
                    ) : (
                      <></>
                    )}

                    {_menu.Exportar === 1 ? (
                      <>
                        <Button
                          color="success"
                          size="sm"
                          onClick={btnExportarDesglosado}
                        >
                          <span className="btn-label">
                            <i className="fa fa-file-excel-o fa-2x" />
                          </span>
                          Exportar Desglosado
                        </Button>
                      </>
                    ) : (
                      <></>
                    )}
                    {_menu.Seguimiento === 1 ? (
                      <>
                        <Button
                          onClick={btnPadronPotencial}
                          color="success"
                          size="sm"
                        >
                          <span className="btn-label">
                            <i className="fa fa-user-plus fa-2x" />
                          </span>
                          Padrón Potencial
                        </Button>
                      </>
                    ) : (
                      <></>
                    )}
                  </CardHeader>
                  <CardBody>
                    {loading && (
                      <CircleSpinner
                        className="text-center"
                        size={30}
                        color="#686769"
                        loading={loading}
                      />
                    )}
                    <ReactTable
                      size="sm"
                      data={data}
                      noDataText="No Existen Datos a Mostrar!"
                      //filerable
                      manual
                      columns={[
                        {
                          Header: "Region",
                          minWidth: 80,
                          id: "Region",
                          accessor: (d) => d.Region,
                          filterAll: false,
                          show: token.regiones.length > 0 ? true : false,
                        },
                        {
                          Header: "Municipio",
                          minWidth: 80,
                          id: "Municipio",
                          accessor: (d) => d.Municipio,
                          filterAll: false,
                        },

                        {
                          Header: "ClaveUnica",
                          minWidth: 80,
                          id: "ClaveUnica",
                          accessor: (d) => d.ClaveUnica,
                          filterAll: false,
                        },
                        {
                          Header: "Solicitante",
                          minWidth: 80,
                          id: "Contacto",
                          accessor: (d) => d.Nombre,
                          filterAll: false,
                        },
                        {
                          Header: "Colonia",
                          minWidth: 80,
                          id: "Colonia",
                          accessor: (d) => d.Colonia,
                          filterAll: false,
                        },
                        {
                          Header: "Celular",
                          minWidth: 80,
                          id: "Celular",
                          accessor: (d) => d.TelCelular,
                          filterAll: false,
                        },
                        {
                          Header: "Capturo",
                          minWidth: 80,
                          id: "Capturo",
                          accessor: (d) => d.Capturo, //,Capturo, Articulador, FechaCaptura
                          filterAll: false,
                        },
                        {
                          Header: "Articulador",
                          minWidth: 80,
                          id: "articulador",
                          accessor: (d) => d.Articulador,
                          filterAll: false,
                        },
                        {
                          Header: "FechaCaptura",
                          minWidth: 80,
                          id: "FechaCaptura",
                          accessor: (d) => d.FechaCaptura,
                          filterAll: false,
                        },
                        {
                          Header: "Estatus",
                          minWidth: 80,
                          id: "Estatus",
                          accessor: (d) => d.Estatus,
                          filterAll: false,
                        },
                      ]}
                      className="-striped -highlight primary-pagination"
                      loading={loading}
                      showPagination={true}
                      showPaginationBottom={true}
                      showTotalPages={true}
                      showPageJump={false}
                      canNextFromData={true}
                      defaultPageSize={10}
                      total={totalVales}
                      pages={Math.floor(totalVales / 10)}
                      previousText="Anterior"
                      nextText="Siguiente"
                      ofText="de"
                      pageSizeOptions={[10, 20, 25, 50, 100]}
                      PaginationComponent={Pagination}
                      /* getTrProps={(state, rowInfo, column, instance) => {
                        return {
                          onClick: (e) => {
                            if (_menu.Editar === 1) {
                              
                              _setItem(rowInfo.row._original.data);

                              setisUpdate(true);
                            }
                          },
                        };
                      }}*/
                      onFetchData={(stateComp, instance) => {
                        setLoading(true);
                        var editar = false;
                        const token = getVars("Token");
                        let menu_local_storage = setearMenu("Storage");
                        var ParametrosVale = {};
                        console.log("Filtro--->", Filtro);
                        if (menu_local_storage.regiones.length > 0) {
                          ParametrosVale = {
                            page: stateComp.page,
                            tipo: "or",
                            Propietario:
                              _menu.ViewAll === 1 ? "All" : token.user.id,
                            pageSize: stateComp.pageSize,
                            sorted: [
                              { id: "vales.Paterno", desc: false },
                              { id: "vales.FechaSolicitud", desc: true },
                            ],
                            filtered: Filtro._filtro,
                            Folio: Filtro._Folio,
                            NombreCompleto: Filtro._NombreCompleto,
                            NombreCreated: Filtro._NombreCreated,
                            UserOwned: Filtro._UserOwned,
                            Remesa: Filtro._Remesa,
                            idMunicipio: Filtro._Municipio,
                            Colonia: Filtro._Colonia,
                            Regiones:
                              Filtro._Region.length > 0
                                ? Filtro._Region
                                : menu_local_storage.regiones,
                            Duplicados: Filtro._Duplicados,
                          };
                        } else {
                          ParametrosVale = {
                            page: stateComp.page,
                            tipo: "or",
                            pageSize: stateComp.pageSize,
                            sorted: [
                              { id: "vales.Paterno", desc: false },
                              { id: "vales.FechaSolicitud", desc: true },
                            ],
                            filtered: Filtro._filtro,
                            Folio: Filtro._Folio,
                            NombreCompleto: Filtro._NombreCompleto,
                            NombreCreated: Filtro._NombreCreated,
                            UserOwned: Filtro._UserOwned,
                            Remesa: Filtro._Remesa,
                            idMunicipio: Filtro._Municipio,
                            Colonia: Filtro._Colonia,
                            Propietario:
                              _menu.ViewAll === 1 ? "All" : token.user.id,
                          };
                        }

                        EjecutarApi(ParametrosVale, "getValesV2", token.token)
                          .then((res) => {
                            if (res.results) {
                              setLoading(false);
                              setTotalVales(res.total);

                              setData(
                                res.data.map((prop, key) => {
                                  editar = false;
                                  if (
                                    menu_local_storage.regiones.length === 0
                                  ) {
                                    if (menu_local_storage.Editar === 1) {
                                      if (prop.Remesa === null) {
                                        if (
                                          prop.UserOwned.id === token.user.id ||
                                          prop.UserCreated.id === token.user.id
                                        ) {
                                          editar = true;
                                        } else {
                                          editar = false;
                                        }
                                      } else {
                                        editar = false;
                                      }
                                    } //else de Editar
                                    else {
                                      editar = false;
                                    }
                                  } else if (prop.Remesa === null) {
                                    //regiones
                                    editar = true;
                                  }

                                  return {
                                    Nombre: editar ? (
                                      <Button
                                        color="info"
                                        size="sm"
                                        onClick={() => handleclickRowEdit(prop)}
                                      >
                                        <span className="btn-label">
                                          <i className="nc-icon nc-ruler-pencil" />
                                        </span>
                                        {prop.Nombre} {prop.Paterno}{" "}
                                        {prop.Materno}
                                      </Button>
                                    ) : (
                                      `${prop.Nombre} ${prop.Paterno}
                                              ${prop.Materno}`
                                    ),
                                    TelCelular: prop.TelCelular,
                                    ClaveUnica: prop.ClaveUnica,
                                    TelFijo: prop.TelFijo,
                                    CorreoElectronico: prop.CorreoElectronico,
                                    Municipio: prop.idMunicipio.Municipio,
                                    Region: prop.idMunicipio.Region,
                                    Colonia: prop.Colonia,
                                    Calle: prop.Calle,
                                    Ocupacion: prop.Ocupacion,
                                    OcupacionOtro: prop.OcupacionOtro,
                                    Estatus: prop.idStatus.Estatus,
                                    IngresoPercibido: prop.IngresoPercibido,
                                    OtrosIngresos: prop.OtrosIngresos,
                                    NumeroPersonas: prop.NumeroPersonas,
                                    Articulador: `${prop.UserOwned.Nombre} ${prop.UserOwned.Paterno} ${prop.UserOwned.Materno}`,
                                    Capturo: `${prop.UserCreated.Nombre} ${prop.UserCreated.Paterno} ${prop.UserCreated.Materno}`,
                                    FechaCaptura: prop.created_at,
                                    data: prop,
                                  };
                                })
                              );
                              //setLoadingGrupo(false);
                            } else if (res.total === 0) {
                              setTotalVales(res.total);
                              setData([]);
                              notify.show(
                                `No Existen datos a Mostrar`,
                                "custom",
                                5000,
                                {
                                  background: "#AED6F1",
                                  text: "#FFFFFF",
                                }
                              );
                            }
                          })
                          .catch((e) => {
                            setLoading(false);
                            notify.show(
                              `Error al obtener Vales: ${e.errors}`,
                              "custom",
                              5000,
                              {
                                background: "#0857B6",
                                fontSize: 14,
                                text: "#FFFFFF",
                              }
                            );

                            //setLoadingGrupo(false);
                          });
                      }}
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
          <FixedPlugin
            isLoading={loading}
            filtrar={setFilter}
            _menu={_menu}
            clasess="dropdowns"
          />
          {isUpdate ? (
            <ValesUpdate props={props} _vales={_item} onHide={onHide} />
          ) : (
            <></>
          )}

          <Footer fluid />
        </div>
      </div>
    </>
  );
};
export default Vales;
