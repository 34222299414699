import React, { useState, useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
} from "reactstrap";
import swal from "sweetalert";
import ReactTable from "react-table";
import { CircleSpinner } from "react-spinners-kit";
import EjecutarApi, { uploadFiles } from "../Consultas/Consultas";
import Notifications, { notify } from "react-notify-toast";
import { getVars } from "../GlobalStorage";
import { Link } from "react-router-dom";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Pagination from "../components/pagination";
import Sidebar from "../components/Sidebar";
import FixedPlugin from "../components/PluginFilterCedulaDiagnostico"; //Cambiar
import { URL_GENERAL } from "../data/consts";
import { isNullOrUndefined } from "../utils/emptyValidations";
import { FormatedCedulaFormDiagnostico } from "../data/interfaces/Cedula";
import ListadoArchivos from "../components/Archivos/ListadoArchivos";

const Diagnostico = (props) => {
  const [userData, setUserData] = useState();
  const [menu, setMenu] = useState({
    Ver: 0,
    Agregar: 0,
    Editar: 0,
    Eliminar: 0,
    Seguimiento: 0,
    Exportar: 0,
    Imprimir: 0,
    ViewAll: 0,
    idTipoUser: 0,
  });
  const history = useHistory();
  const [total, setTotal] = useState(0);
  const [loadingCedulaList, setLoadingCedulaList] = useState(false);
  const [isLoadingArchivos, setIsLoadingArchivos] = useState(false);
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [isLoadingCatalogos, setIsLoadingCatalogos] = useState(false);
  const [dataCedulaList, setDataCedulaList] = useState([]);
  const [menuLoaded, setMenuLoaded] = useState(false);
  const [archivos, setArchivos] = useState(false);
  const [clasificacion, setClasificacion] = useState(false);
  const [catalogos, setCatalogos] = useState([]);
  const [TotalSolicitudes, setTotalSolicitudes] = useState(0);
  const [TotalAprobadas, setTotalAprobadas] = useState(0);
  const [selectedRecord, setSelectedRecord] = useState({});
  //Filtro
  const [filtro, setFiltro] = useState([]);

  const columns = [
    {
      Header: "Acciones",
      minWidth: 250,
      id: "Acciones",
      accessor: (d) => d.Acciones,
      className: "text-center",
      filterAll: false,
      style: { fontSize: "10px" },
      headerStyle: { fontSize: "10px" },
    },
    {
      Header: "Fecha Diagnóstico",
      minWidth: 120,
      id: "FechaSolicitud",
      accessor: (d) => d.FechaSolicitud,
      filterAll: false,
      style: { fontSize: "10px" },
      headerStyle: { fontSize: "10px" },
    },
    {
      Header: "Nombre Completo",
      minWidth: 200,
      id: "NombreCompleto",
      accessor: (d) => d.NombreCompleto,
      filterAll: false,
      style: { fontSize: "10px" },
      headerStyle: { fontSize: "10px" },
    },
    {
      Header: "Region",
      minWidth: 80,
      id: "Region",
      accessor: (d) => d.Region,
      filterAll: false,
      style: { fontSize: "10px" },
      headerStyle: { fontSize: "10px" },
    },
    {
      Header: "Municipio",
      minWidth: 80,
      id: "MunicipioVive",
      accessor: (d) => d.MunicipioVive,
      filterAll: false,
      style: { fontSize: "10px" },
      headerStyle: { fontSize: "10px" },
    },
    {
      Header: "Dirección",
      minWidth: 300,
      id: "Direccion",
      accessor: (d) => d.Direccion,
      filterAll: false,
      style: { fontSize: "10px" },
      headerStyle: { fontSize: "10px" },
    },
    {
      Header: "Fecha Nacimiento",
      minWidth: 120,
      id: "FechaNacimiento",
      accessor: (d) => d.FechaNacimiento,
      filterAll: false,
      style: { fontSize: "10px" },
      headerStyle: { fontSize: "10px" },
    },
    {
      Header: "Entidad Nacimiento",
      minWidth: 120,
      id: "EntidadNacimiento",
      accessor: (d) => d.EntidadNacimiento,
      filterAll: false,
      style: { fontSize: "10px" },
      headerStyle: { fontSize: "10px" },
    },
    {
      Header: "Edad",
      minWidth: 60,
      id: "Edad",
      accessor: (d) => d.Edad,
      filterAll: false,
      style: { fontSize: "10px" },
      headerStyle: { fontSize: "10px" },
    },
    {
      Header: "Sexo",
      minWidth: 120,
      id: "Sexo",
      accessor: (d) => d.Sexo,
      filterAll: false,
      style: { fontSize: "10px" },
      headerStyle: { fontSize: "10px" },
    },
    {
      Header: "Estado Civil",
      minWidth: 120,
      id: "EstadoCivil",
      accessor: (d) => d.EstadoCivil,
      filterAll: false,
      style: { fontSize: "10px" },
      headerStyle: { fontSize: "10px" },
    },
    {
      Header: "CURP",
      minWidth: 120,
      id: "CURP",
      accessor: (d) => d.CURP,
      filterAll: false,
      style: { fontSize: "10px" },
      headerStyle: { fontSize: "10px" },
    },
    {
      Header: "RFC",
      minWidth: 120,
      id: "RFC",
      accessor: (d) => d.RFC,
      filterAll: false,
      style: { fontSize: "10px" },
      headerStyle: { fontSize: "10px" },
    },
    {
      Header: "Celular",
      minWidth: 120,
      id: "Celular",
      accessor: (d) => d.Celular,
      filterAll: false,
      style: { fontSize: "10px" },
      headerStyle: { fontSize: "10px" },
    },
    {
      Header: "Necesidad Solicitante",
      minWidth: 120,
      id: "NecesidadSolicitante",
      accessor: (d) => d.NecesidadSolicitante,
      filterAll: false,
      style: { fontSize: "10px" },
      headerStyle: { fontSize: "10px" },
    },
    {
      Header: "Costo Necesidad",
      minWidth: 120,
      id: "CostoNecesidad",
      accessor: (d) => d.CostoNecesidad,
      filterAll: false,
      style: { fontSize: "10px" },
      headerStyle: { fontSize: "10px" },
    },
    {
      Header: "CreadoPor",
      minWidth: 120,
      id: "CreadoPor",
      accessor: (d) => d.CreadoPor,
      filterAll: false,
      style: { fontSize: "10px" },
      headerStyle: { fontSize: "10px" },
    },
  ];

  useEffect(() => {
    if (isNullOrUndefined(userData)) return;
    const menus = userData.menu;
    const currentMenu = menus.find((menu) => menu.idMenu === 16);
    if (isNullOrUndefined(currentMenu) || currentMenu.Ver < 1) {
      swal({
        title: "Atención",
        text: "No tiene permisos para este módulo, contacte al administrador",
        icon: "warning",
      });
      history.push("/not-found");
      return;
    }
    setMenu({
      Ver: currentMenu.Ver,
      Agregar: currentMenu.Agregar,
      Editar: currentMenu.Editar,
      Eliminar: currentMenu.Eliminar,
      Seguimiento: currentMenu.Seguimiento,
      Exportar: currentMenu.Exportar,
      Imprimir: currentMenu.Imprimir,
      ViewAll: currentMenu.ViewAll,
      idTipoUser: userData.user.idTipoUser.id,
    });
    setMenuLoaded(true);
  }, [userData]);

  const handleMiniClick = () => {
    document.body.classList.toggle("sidebar-mini");
  };

  useEffect(() => {
    setUserData(getVars("Token"));
  }, []);

  useEffect(() => {
    const token = getVars("Token").token;
    EjecutarApi({}, "diagnostico/getEstatusGlobalVentanillaDiagnostico", token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.results) {
          setTotalSolicitudes(res.data[0].Solicitudes);
          setTotalAprobadas(res.data[0].AprobadoComite);
        }
      })
      .catch((e) => {
        notify.show(
          `Error en la obtencion de datos! ${e.message}`,
          "custom",
          5000,
          {
            background: "#d61818",
            text: "#FFFFFF",
          }
        );
      });
  }, []);

  const fetchData = useCallback(
    ({ pageSize, page }) => {
      const data = {
        page: page,
        pageSize: pageSize,
        programa: 1,
      };
      getRegistros(data);
    },
    [menu, filtro]
  );

  const getRegistros = (data) => {
    const token = getVars("Token");
    setLoadingCedulaList(true);
    data.filtered = filtro;
    EjecutarApi(data, "getCedulasDiagnostico", token.token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.results && res.success) {
          setDataCedulaList(
            res.data.map((item) => {
              if (item.Nombre == null) item.Nombre = "";
              if (item.Paterno == null) item.Paterno = "";
              if (item.Materno == null) item.Materno = "";
              if (item.CalleVive == null) item.CalleVive = "";
              if (item.NoExtVive == null) item.NoExtVive = "";
              if (item.ColoniaVive == null) item.ColoniaVive = "";
              if (item.Referencias == null) item.Referencias = "";
              if (item.CPVive == null) item.CPVive = "";
              return {
                ...item,
                NombreCompleto: `${item.Nombre} ${item.Paterno} ${item.Materno}`,
                Direccion: `${item.CalleVive} ${item.NoExtVive} ${item.ColoniaVive} ${item.CPVive} ${item.MunicipioVive} ${item.EntidadVive}`,
                Acciones: (
                  <>
                    {item.idEstatus != 8 ? (
                      <>
                        <Row>
                          <Col>
                            <Button
                              color="info"
                              size="sm"
                              style={{ width: "100%" }}
                              onClick={() => sendDataCedula(item)}
                            >
                              <span className="btn-label">
                                {item.idEstatus != 1 ? (
                                  <i className="fa fa-eye fa-xs"></i>
                                ) : (
                                  <i className="fa fa-pencil-square-o fa-xs"></i>
                                )}
                              </span>
                              Cedula
                            </Button>
                          </Col>
                          <Col>
                            {menu.Agregar === 1 && menu.Editar === 1 && (
                              <Button
                                color="warning"
                                size="sm"
                                style={{ width: "100%" }}
                                onClick={() => handleShowFiles(item)}
                              >
                                <i className="fa fa-file-archive-o fa-xs"></i>
                                Archivos
                              </Button>
                            )}
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            {item.ListaParaEnviar === 1 && menu.Imprimir === 1 && (
                              <Button
                                color="success"
                                size="sm"
                                style={{ width: "100%" }}
                                onClick={() => {
                                  validar(item.id);
                                }}
                              >
                                <span>
                                  <i className="fa fa-thumbs-up" />
                                </span>{" "}
                                Validar
                              </Button>
                            )}
                          </Col>
                        </Row>
                      </>
                    ) : (
                      <Row>
                        <Col>
                          <Button
                            color="info"
                            size="sm"
                            style={{ width: "100%" }}
                            onClick={() => sendDataCedula(item)}
                          >
                            <span>
                              <i className="fa fa-check-square-o fa-xs" />
                            </span>{" "}
                            Validada (Ver)
                          </Button>
                        </Col>
                      </Row>
                    )}
                  </>
                ),
              };
            })
          );
          setTotal(res.total);
        } else {
          swal({
            title: "¡Atención!",
            text: `${res.message}`,
            icon: "info",
          });
        }
        setLoadingCedulaList(false);
      })
      .catch((e) => {
        setLoadingCedulaList(false);
        swal({
          title: "¡Atención!",
          text: `${e.message}`,
          icon: "warning",
        });
      });
  };

  const sendDataCedula = (item) => {
    let data = FormatedCedulaFormDiagnostico(item);
    let { ...formatedData } = data;
    history.push({
      pathname: "/diagnostico-cedula-form",
      state: { data: formatedData },
    });
  };

  const validar = (id) => {
    setLoadingCedulaList(true);
    const token = getVars("Token");
    const data = { id: id };
    EjecutarApi(data, "diagnostico/validarCedula", token.token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.results && res.success) {
          swal({
            title: "Solicitud validada con éxito",
            icon: "success",
          });
          const data = {
            page: 0,
            pageSize: 10,
            programa: 1,
            filtered: [],
          };
          getRegistros(data);
        } else {
          swal({
            title: "¡Atención!",
            text: `${res.message}`,
            icon: "info",
          });
        }
        setLoadingCedulaList(false);
      })
      .catch((e) => {
        setLoadingCedulaList(false);
        swal({
          title: "¡Atención!",
          text: `${e.message}`,
          icon: "warning",
        });
      });
  };

  const setFilter = (filtered) => {
    filtered.programa = "1";
    setFiltro(filtered.filtered);
    const token = getVars("Token");
    setLoadingCedulaList(true);
    EjecutarApi(filtered, "getCedulasDiagnostico", token.token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.results && res.success) {
          setDataCedulaList(
            res.data.map((item) => {
              if (item.Nombre == null) item.Nombre = "";
              if (item.Paterno == null) item.Paterno = "";
              if (item.Materno == null) item.Materno = "";
              if (item.CalleVive == null) item.CalleVive = "";
              if (item.NoExtVive == null) item.NoExtVive = "";
              if (item.ColoniaVive == null) item.ColoniaVive = "";
              if (item.Referencias == null) item.Referencias = "";
              if (item.CPVive == null) item.CPVive = "";
              return {
                ...item,
                NombreCompleto: `${item.Nombre} ${item.Paterno} ${item.Materno}`,
                Direccion: `${item.CalleVive} ${item.NoExtVive} ${item.ColoniaVive} ${item.CPVive} ${item.MunicipioVive} ${item.EntidadVive}`,
                Acciones: (
                  <>
                    {item.idEstatus != 8 ? (
                      <>
                        <Row>
                          <Col>
                            <Button
                              color="info"
                              size="sm"
                              style={{ width: "100%" }}
                              onClick={() => sendDataCedula(item)}
                            >
                              <span className="btn-label">
                                {item.idEstatus != 1 ? (
                                  <i className="fa fa-eye fa-xs"></i>
                                ) : (
                                  <i className="fa fa-pencil-square-o fa-xs"></i>
                                )}
                              </span>
                              Cedula
                            </Button>
                          </Col>
                          <Col>
                            {menu.Agregar === 1 && menu.Editar === 1 && (
                              <Button
                                color="warning"
                                size="sm"
                                style={{ width: "100%" }}
                                onClick={() => handleShowFiles(item)}
                              >
                                <i className="fa fa-file-archive-o fa-xs"></i>
                                Archivos
                              </Button>
                            )}
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            {item.ListaParaEnviar === 1 && menu.Imprimir === 1 && (
                              <Button
                                color="success"
                                size="sm"
                                style={{ width: "100%" }}
                                onClick={() => {
                                  validar(item.id);
                                }}
                              >
                                <span>
                                  <i className="fa fa-paper-plane fa-xs" />
                                </span>{" "}
                                Validar
                              </Button>
                            )}
                          </Col>
                        </Row>
                      </>
                    ) : (
                      <>
                        <Row>
                          <Col>
                            <Button
                              color="info"
                              size="sm"
                              style={{ width: "100%" }}
                              onClick={() => sendDataCedula(item)}
                            >
                              <span>
                                <i className="fa fa-paper-plane fa-xs" />
                              </span>{" "}
                              Validada (Ver)
                            </Button>
                          </Col>
                        </Row>
                      </>
                    )}
                  </>
                ),
              };
            })
          );
          setTotal(res.total);
        } else {
          swal({
            title: "¡Atención!",
            text: `${res.message}`,
            icon: "info",
          });
        }
        setLoadingCedulaList(false);
      })
      .catch((e) => {
        setLoadingCedulaList(false);
        swal({
          title: "¡Atención!",
          text: `${e.message}`,
          icon: "warning",
        });
      });
  };

  const handleChangeArchivos = (files) => {
    setArchivos(files);
  };

  const handleChangeClasificacion = (arrayClasificacion) => {
    setClasificacion(arrayClasificacion);
  };

  const handleDeleteFile = (files, arrayClasificacion) => {
    setArchivos(files);
    setClasificacion(arrayClasificacion);
  };

  const changeFiles = () => {
    const token = getVars("Token");
    let oldFiles = [];
    let newFiles = [];
    let oldClasification = [];
    let newClasification = [];
    let flag = false;

    archivos.forEach((file, index) => {
      if (isNullOrUndefined(file.id)) {
        newFiles.push(file);
        if (isNullOrUndefined(clasificacion[index])) {
          flag = true;
          return;
        }
        newClasification.push(clasificacion[index]);
      } else {
        oldFiles.push(file);
        if (isNullOrUndefined(clasificacion[index])) {
          flag = true;
          return;
        }
        oldClasification.push(clasificacion[index]);
      }
    });

    if (flag) {
      swal({
        title: "Alerta",
        text: "Debe seleccionar una clasificación para cada archivo",
        icon: "warning",
      });
      return;
    }

    const formAux = {
      OldFiles: oldFiles,
      NewFiles: newFiles,
      OldClasificacion: oldClasification,
      NewClasificacion: newClasification,
      id: selectedRecord.id,
      programa: 1,
    };
    setIsLoadingArchivos(true);
    uploadFiles({
      data: formAux,
      api: `diagnostico/updateArchivosCedula`,
      token: token.token,
    })
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.success && res.results) {
          swal({
            title: "¡Éxito!",
            icon: "success",
          });
          handleShowFiles(selectedRecord);
        } else {
          swal({
            title: "Ha ocurrido un error",
            text: res.message,
            icon: "warning",
          });
          setIsLoadingArchivos(false);
        }
      })
      .catch((error) => {
        setIsLoadingArchivos(false);
        swal({
          title: "Ha ocurrido un error",
          text: error.errorInfo,
          icon: "warning",
        });
      });
  };

  const handleShowFiles = (cedula) => {
    setIsModalOpened(true);
    setIsLoadingArchivos(true);
    setIsLoadingCatalogos(true);
    setSelectedRecord(cedula);
    const token = getVars("Token");
    EjecutarApi({}, `calentadores/getClasificacionArchivos`, token.token, "GET")
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.results && res.success) {
          setCatalogos(res.data);
        } else {
          setIsModalOpened(false);
          swal({
            title: "¡Atención!",
            text: `${res.errors}`,
            icon: "info",
          });
        }
        setIsLoadingCatalogos(false);
      })
      .catch((error) => {
        setIsLoadingCatalogos(false);
        setIsModalOpened(false);
        swal({
          title: "¡Atención!",
          text: `${error.error}`,
          icon: "info",
        });
      });
    EjecutarApi(
      {},
      `diagnostico/getArchivosByIdD/${cedula.id}`,
      token.token,
      "GET"
    )
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.results && res.success) {
          setArchivos(res.data.Archivos);
          setClasificacion(res.data.ArchivosClasificacion);
        } else {
          setIsModalOpened(false);
          swal({
            title: "¡Atención!",
            text: `${res.errors}`,
            icon: "info",
          });
        }
        setIsLoadingArchivos(false);
      })
      .catch((error) => {
        setIsLoadingArchivos(false);
        setIsModalOpened(false);
        swal({
          title: "¡Atención!",
          text: `${error.error}`,
          icon: "info",
        });
      });
  };
  const btnExportar = () => {
    const token = getVars("Token");
    var url = new URL(
      URL_GENERAL +
        `getReporteSolicitudVentanillaDiagnostico?token=${token.token}`
    );
    window.open(url, "_blank");
  };

  return (
    <>
      <div className="wrapper">
        <Sidebar {...props} bgColor="black" activeColor="info" />
        <div className="main-panel">
          <Navbar {...props} handleMiniClick={handleMiniClick} />
          <Notifications />
          <div className="content">
            <Modal
              size="xl"
              isOpen={isModalOpened}
              toggle={() => setIsModalOpened(!isModalOpened)}
            >
              <ModalHeader>Archivos</ModalHeader>
              <ModalBody>
                {isLoadingArchivos && <Spinner />}
                {!isLoadingArchivos && (
                  <ListadoArchivos
                    files={archivos}
                    archivosClasificacion={clasificacion}
                    catalogClasificacion={catalogos}
                    handleChangeArchivos={handleChangeArchivos}
                    handleChangeClasificacion={handleChangeClasificacion}
                    handleDeleteFile={handleDeleteFile}
                    loadingCatalogs={isLoadingCatalogos}
                    onlyForView={selectedRecord.ListaParaEnviar === 1}
                    fullscreen={true}
                  />
                )}
              </ModalBody>
              <ModalFooter>
                <Button
                  size="sm"
                  color="danger"
                  disabled={isLoadingArchivos}
                  onClick={() => setIsModalOpened(false)}
                >
                  Cancelar
                </Button>
                {selectedRecord.ListaParaEnviar === 0 && (
                  <Button
                    size="sm"
                    color="primary"
                    disabled={isLoadingArchivos}
                    onClick={() => changeFiles()}
                  >
                    Guardar
                  </Button>
                )}
              </ModalFooter>
            </Modal>
            <Row>
              <Col lg="6" md="6" sm="6">
                <Card className="card-stats">
                  <CardBody>
                    <Row>
                      <Col md="4" xs="5">
                        <div className="icon-big text-center icon-warning">
                          <i className="nc-icon nc-globe text-warning" />
                        </div>
                      </Col>
                      <Col md="8" xs="7">
                        <div className="numbers">
                          <p className="card-category">Cedulas Capturadas</p>
                          <CardTitle tag="p">{TotalSolicitudes}</CardTitle>
                          <p />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                  {/* <CardFooter>
                    <hr />
                    <div className="stats">
                      <i className="fa fa-refresh" />
                      Recibidas hasta ahora
                    </div>
                  </CardFooter> */}
                </Card>
              </Col>
              {menu.Seguimiento == 1 && (
                <Col lg="6" md="6" sm="6">
                  <Card className="card-stats">
                    <CardBody>
                      <Row>
                        <Col md="4" xs="5">
                          <div className="icon-big text-center icon-warning">
                            <i className="nc-icon nc-vector text-danger" />
                          </div>
                        </Col>
                        <Col md="8" xs="7">
                          <div className="numbers">
                            <p className="card-category">Cedulas Validadas</p>
                            <CardTitle tag="p">{TotalAprobadas}</CardTitle>
                            <p />
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              )}
            </Row>
            <Row>
              <Col md="12">
                <Card>
                  <CardHeader>
                    <Row>
                      <Col md="7">
                        <CardTitle tag="h6">Listado de Solicitudes</CardTitle>
                      </Col>
                      <Col md="2">
                        <Link to="diagnostico-cedula-form">
                          <Button color="primary" size="sm">
                            <span className="btn-label">
                              <i className="fa fa-user-plus fa-2x" />
                            </span>
                            Capturar
                          </Button>
                        </Link>
                      </Col>
                      <Col md="3">
                        {menu.Exportar === 1 ? (
                          <>
                            <Button
                              color="success"
                              size="sm"
                              onClick={btnExportar}
                            >
                              <span className="btn-label">
                                <i className="fa fa-file-excel-o fa-2x" />
                              </span>
                              Exportar
                            </Button>
                          </>
                        ) : (
                          <></>
                        )}
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    {loadingCedulaList && (
                      <CircleSpinner
                        className="text-center"
                        size={30}
                        color="#686769"
                        loading={loadingCedulaList}
                      />
                    )}
                    <Col lg="12" md="12">
                      {menuLoaded && (
                        <ReactTable
                          size="sm"
                          data={dataCedulaList}
                          noDataText="No Existen Datos a Mostrar!"
                          manual
                          columns={columns}
                          className="-striped -highlight primary-pagination"
                          loading={loadingCedulaList}
                          showPagination={true}
                          showPaginationBottom={true}
                          showTotalPages={true}
                          showPageJump={false}
                          canNextFromData={true}
                          defaultPageSize={10}
                          total={total}
                          pages={Math.floor(total / 10)}
                          previousText="Anterior"
                          nextText="Siguiente"
                          ofText="de"
                          pageSizeOptions={[10, 20, 25, 50, 100]}
                          PaginationComponent={Pagination}
                          onFetchData={fetchData}
                        />
                      )}
                    </Col>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
          <FixedPlugin
            //isLoading={loading}
            sendFiltro={setFilter}
            clasess="dropdowns"
          />
          <Footer fluid />
        </div>
      </div>
    </>
  );
};
export default Diagnostico;
