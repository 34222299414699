import React, { useState, useEffect } from "react";
// react plugin used to create switch buttons
import { CircleSpinner } from "react-spinners-kit";
import Select from "react-select";
import moment from "moment";
import { Button, Input, Label, Form, FormGroup } from "reactstrap";
import "./styles.css";

import { notify } from "react-notify-toast";
import EjecutarApi from "../Consultas/Consultas";
import { getVars } from "../GlobalStorage";

function PluginFilterRecepcionExpedientes(props) {
  const [loading, setLoading] = useState(false);
  const token = getVars("Token");
  const [classes, setclasses] = useState("dropdown");
  const [FolioSolicitud, setFolioSolicitud] = useState("");
  const [FechaSolicitud, setFechaSolicitud] = useState("");
  const [Ejercicio, setEjercicio] = useState({ value: 2022, label: "2022" });

  const [SolicitanteNombre, setSolicitanteNombre] = useState("");
  const [CapturoNombre, setCapturoNombre] = useState("");
  const [CURP, setCURP] = useState("");

  const [MultiValueMuninicpio, setMultiValueMunicipio] = useState([]);
  const [MultiValueMuninicpio2, setMultiValueMunicipio2] = useState([]);
  const [filterColonia, setfilterColonia] = useState([]);
  const [MultiValueRemesa, setMultiValueRemesa] = useState([]);
  const [MultiValueRemesa2, setMultiValueRemesa2] = useState([]);
  const [filterEstatus, setfilterEstatus] = useState([
    { value: "1", label: "Por Validar" },
    { value: "3", label: "No Aprobado Comité" },
    { value: "4", label: "Aprobado Comité" },
  ]);
  const [MultiValueRegion, setMultiValueRegion] = useState([]);
  const [filterRegion, setfilterRegion] = useState([]);

  const [MultiValueArticulador, setMultiValueArticulador] = useState([]);
  const [MultiValueRecepciono, setMultiValueRecepciono] = useState([]);
  const [MultiValueRecepcionoData, setMultiValueRecepcionoData] = useState([]);
  const [MultiValueArticulador2, setMultiValueArticulador2] = useState([]);

  const [isLoading, setisLoading] = useState(false);
  //const [Filtered, setFiltered] = useState([]);
  const [_menu, _setmenu] = useState({});
  const datos = {
    page: 0,
    tipo: "and",
    pageSize: 500,
    sorted: [],
    filtered: [],
  };
  let style_spinner = {
    height: "500pt",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };
  useEffect(() => {
    console.log("FilterV.Token: ", token);
    setearMenu("Estado");
    const datosMpio = {
      page: 0,
      tipo: "and",
      pageSize: 500,
      sorted: [{ id: "Nombre", desc: false }],
      filtered: [{ id: "userID", value: token.user.id }],
      Regiones: token.regiones.length > 0 ? token.regiones : "",
    };
    //Municipios
    EjecutarApi(datosMpio, "getMunicipiosETVales", token.token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.results) {
          var region = [];
          console.log("Municipios:", res.total);
          setMultiValueMunicipio(
            res.data.map((municipio, i) => {
              if (region.indexOf(municipio["SubRegion"]) === -1) {
                region.push(municipio["SubRegion"]);
              }
              return {
                value: municipio["Id"],
                label: municipio["Nombre"],
              };
            })
          );
          var regio_n = region.sort((a, b) => a - b);
          setfilterRegion(
            regio_n.map((region_) => {
              return { value: region_, label: region_ };
            })
          );
        }
      })
      .catch((e) => {
        notify.show(`Error en la busqueda! ${e.message}`, "custom", 5000, {
          background: "#d61818",
          text: "#FFFFFF",
        });
      });
    //Articulador
    let datoArticulador = {};
    if (token.regiones.length === 0) {
      datoArticulador = {
        page: 0,
        tipo: "and",
        pageSize: 1000,
        sorted: [{ id: "Nombre", desc: false }],
        filtered: [],
        idUser: token.user.id,
      };
    } else {
      datoArticulador = {
        page: 0,
        tipo: "and",
        pageSize: 1000,
        sorted: [{ id: "Nombre", desc: false }],
        filtered: [],
        Regiones: token.regiones,
      };
    }
    console.log("-->datoArticulador", JSON.stringify(datoArticulador));
    EjecutarApi(datoArticulador, "getUsersArticuladoresV2", token.token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.results) {
          console.log("Articulador-total:", res.total);
          setMultiValueArticulador(
            res.data.map((data, i) => {
              return {
                value: data["id"],
                label: data["Nombre"],
              };
            })
          );
        }
      })
      .catch((e) => {
        notify.show(`Error en la busqueda! ${e.message}`, "custom", 5000, {
          background: "#d61818",
          text: "#FFFFFF",
        });
      });

    EjecutarApi(
      {
        page: 0,
        tipo: "and",
        pageSize: 1000,
        sorted: [],
        filtered: [],
      },
      "getUsersRecepcionoV2",
      token.token
    )
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.results) {
          console.log("=>setMultiValueRecepciono", res);

          if (res.data.length > 0) {
            setMultiValueRecepciono(
              res.data.map((data, i) => {
                return {
                  value: data["id"],
                  label: data["Nombre"],
                };
              })
            );
          }
        }
      })
      .catch((e) => {
        notify.show(`Error en la busqueda! ${e.message}`, "custom", 5000, {
          background: "#d61818",
          text: "#FFFFFF",
        });
      });
  }, []);

  const setFilter = (event) => {
    setisLoading(true);
    event.preventDefault();

    console.log("=>Ejercicio: ", Ejercicio);
    console.log("Anio: ", moment().format("Y"));

    var myRegiones = [];
    const myFiltro = [];

    //myFiltro.push({ id: "Ejercicio", value: Ejercicio.value });
    if (CURP.length) myFiltro.push({ id: "V.CURP", value: CURP });

    if (FechaSolicitud.length > 1)
      myFiltro.push({ id: "V.FechaDocumentacion", value: FechaSolicitud });

    //region
    console.log("=Regiones: ", MultiValueRegion);

    if (MultiValueRegion != null)
      for (let x = 0; x < MultiValueRegion.length; x++)
        myRegiones.push(MultiValueRegion[x].label);

    //setFiltered(myFiltro);
    var datos_ = {};
    // if (token.regiones.length > 0)
    // {
    //   console.log("Tiene asignado myRegiones: ", myRegiones);
    //   datos_ = {
    //     page: 0,
    //     tipo: "or",
    //     Propietario: _menu.ViewAll === 1 ? "All" : token.user.id,
    //     pageSize: 10,
    //     sorted: [
    //       { id: "V.FechaDocumentacion", desc: true },
    //     ],
    //     NombreCompleto: SolicitanteNombre.length === 0 ? "" : SolicitanteNombre,
    //     Ejercicio: Ejercicio.value === 0 ? moment().format('Y') : Ejercicio.value,
    //     NombreCreated: CapturoNombre.length === 0 ? "" : CapturoNombre,
    //     UserOwned:
    //       MultiValueArticulador2.length > 0 ? MultiValueArticulador2 : "",
    //     UserRecepciono:
    //       MultiValueRecepcionoData.length > 0 ? MultiValueRecepcionoData : "",
    //     Remesa: MultiValueRemesa2.length > 0 ? MultiValueRemesa2 : "",
    //     idMunicipio:
    //       MultiValueMuninicpio2.length > 0 ? MultiValueMuninicpio2 : "",
    //     Regiones: myRegiones.length > 0 ? myRegiones : token.regiones,
    //     Folio: FolioSolicitud.length > 0 ? FolioSolicitud : "",
    //     filtered: myFiltro,
    //   };
    // } else {

    // if (MultiValueRegion != null)
    //   for (let x = 0; x < MultiValueRegion.length; x++)
    //     myFiltro.push({
    //       id: "et_cat_municipio.SubRegion",value: MultiValueRegion[x].label});
    //       console.log(
    //       "No tiene regiones asignadas pero puede ver todo: ",
    //       myRegiones
    //       );

    datos_ = {
      page: 0,
      tipo: "or",
      Propietario: _menu.ViewAll === 1 ? "All" : token.user.id,
      pageSize: 10,
      sorted: [{ id: "V.FechaDocumentacion", desc: true }],
      filtered: myFiltro,
      Folio: FolioSolicitud.length > 0 ? FolioSolicitud : "",
      Ejercicio: Ejercicio.value === 0 ? moment().format("Y") : Ejercicio.value,
      NombreCompleto: SolicitanteNombre.length === 0 ? "" : SolicitanteNombre,
      UserOwned:
        MultiValueArticulador2.length > 0 ? MultiValueArticulador2 : "",
      UserRecepciono:
        MultiValueRecepcionoData.length > 0 ? MultiValueRecepcionoData : "",
      Remesa: MultiValueRemesa2.length > 0 ? MultiValueRemesa2 : "",
      idMunicipio:
        MultiValueMuninicpio2.length > 0 ? MultiValueMuninicpio2 : "",
      Regiones: myRegiones,
      CURP: CURP.length > 0 ? CURP : "",
    };
    //}

    console.log("myFiltro", datos_);
    console.log("==>datosFiltro a mandar: ", JSON.stringify(datos_));
    setisLoading(false);
    props.filtrar(datos_);
  };

  const setearMenu = (dependecia) => {
    if (dependecia === "Estado") {
      token.menu.forEach((prop, key) => {
        if (prop.Menu === "Recepción Expedientes") {
          _setmenu({
            Ver: prop.Ver,
            Agregar: prop.Agregar,
            Editar: prop.Editar,
            Eliminar: prop.Eliminar,
            Seguimiento: prop.Seguimiento,
            Exportar: prop.Exportar,
            Imprimir: prop.Imprimir,
            ViewAll: prop.ViewAll,
            idTipoUser: token.user.idTipoUser.id,
          });
        }
      });
    } else {
      var json = {};

      token.menu.forEach((prop, key) => {
        if (prop.Menu === "Recepción Expedientes") {
          json = {
            Ver: prop.Ver,
            Agregar: prop.Agregar,
            Editar: prop.Editar,
            Eliminar: prop.Eliminar,
            Seguimiento: prop.Seguimiento,
            Exportar: prop.Exportar,
            Imprimir: prop.Imprimir,
            ViewAll: prop.ViewAll,
            idTipoUser: token.user.idTipoUser.id,
            regiones: token.regiones,
          };
        }
      });

      return json;
    }
  };
  const handleClick = () => {
    if (classes === "dropdown") {
      setclasses("dropdown show");
    } else {
      setclasses("dropdown");
    }
  };

  const handleMultiRegion = (option) => {
    setMultiValueRegion(option);
  };

  //console.log('__>FilterColonia',filterColonia);
  return (
    <div className="fixed-plugin">
      <Form onSubmit={setFilter}>
        <div className={classes}>
          <div onClick={handleClick}>
            <i className="fa fa-cog fa-2x" />
          </div>
          <ul className="dropdown-menu show ul">
            <li className="adjustments-line">Ejercicio Fiscal:</li>
            <li className="li">
              <FormGroup sm="10">
                <Select
                  className="SelectSize"
                  name="Ejercicio"
                  placeholder="Seleccione..."
                  options={[
                    { value: 2022, label: "2022" },
                    { value: 2021, label: "2021" },
                    { value: 2020, label: "2020" },
                  ]}
                  value={Ejercicio}
                  onChange={(Ejercicio) => setEjercicio(Ejercicio)}
                />
              </FormGroup>
            </li>

            <li className="adjustments-line">
              <Input
                placeholder="Folio de Registro"
                id="folioCaptura"
                value={FolioSolicitud}
                type="text"
                onChange={(e) => setFolioSolicitud(e.target.value)}
              />
            </li>

            <li className="adjustments-line">
              <Input
                placeholder="Solicitante"
                id="NombreSolicitante"
                value={SolicitanteNombre}
                type="text"
                onChange={(e) => setSolicitanteNombre(e.target.value)}
              />
            </li>
            <li className="adjustments-line">
              <Input
                placeholder="CURP"
                id="CURP"
                value={CURP}
                type="text"
                onChange={(e) => setCURP(e.target.value)}
              />
            </li>

            <li className="adjustments-line">Fecha Recepción:</li>
            <Input
              type="date"
              name="FechaSolicitud"
              id="FechaSolicitud"
              placeholder="Fecha Recepción"
              value={FechaSolicitud}
              onChange={(e) => setFechaSolicitud(e.target.value)}
              className="form-control"
            />
            {/* <Select
                className="SelectSize"
                name="fecharecepcion"
                placeholder="Fecha Recepción"
                options={MultiValueFechaRecepciono}
                onChange={(event, i) => {
                  if (event) {
                    setMultiValueFechaRecepcionoData(
                      event.map((dato) => {
                        return dato.value;
                      })
                    );
                  } else {
                    setMultiValueFechaRecepcionoData({
                      MultiValueFechaRecepcionoData: MultiValueFechaRecepcionoData.map(
                        (dato) => {
                          if (i.removedValue.value !== dato) {
                            return dato.value;
                          }
                        }
                      ),
                    });
                  }
                }}
                isMulti
              /> */}

            <li className="adjustments-line">
              <Label>Receptor:</Label>
            </li>
            <li className="li">
              <Select
                className="SelectSize"
                name="recepciono"
                placeholder="Recepciono"
                options={MultiValueRecepciono}
                onChange={(event, i) => {
                  if (event) {
                    setMultiValueRecepcionoData(
                      event.map((dato) => {
                        return dato.value;
                      })
                    );
                  } else {
                    setMultiValueRecepcionoData({
                      MultiValueRecepcionoData: MultiValueRecepcionoData.map(
                        (dato) => {
                          if (i.removedValue.value !== dato) {
                            return dato.value;
                          }
                        }
                      ),
                    });
                  }
                }}
                isMulti
              />
            </li>

            <li className="adjustments-line">
              <Label>Articulador:</Label>
            </li>
            <li className="li">
              <Select
                className="SelectSize"
                name="articulador"
                placeholder="Articulador"
                options={MultiValueArticulador}
                onChange={(event, i) => {
                  if (event) {
                    setMultiValueArticulador2(
                      event.map((dato) => {
                        return dato.value;
                      })
                    );
                  } else {
                    setMultiValueArticulador2({
                      MultiValueArticulador2: MultiValueArticulador2.map(
                        (dato) => {
                          if (i.removedValue.value !== dato) {
                            return dato.value;
                          }
                        }
                      ),
                    });
                  }
                }}
                isMulti
              />
            </li>
            <li className="adjustments-line">
              <Label>Región:</Label>
            </li>
            <li className="li">
              <Select
                name="region"
                className="SelectSize"
                placeholder="Región"
                value={MultiValueRegion}
                options={filterRegion}
                onChange={handleMultiRegion}
                isMulti
              />
            </li>
            <li className="adjustments-line">
              <Label>Municipio:</Label>
            </li>
            <li className="li">
              <Select
                className="SelectSize"
                name="Municipio"
                placeholder="Municipio"
                options={MultiValueMuninicpio}
                onChange={(event, i) => {
                  if (event) {
                    setMultiValueMunicipio2(
                      event.map((dato) => {
                        return dato.value;
                      })
                    );
                  } else {
                    setMultiValueMunicipio2({
                      MultiValueMuninicpio2: MultiValueMuninicpio2.map(
                        (dato) => {
                          if (i.removedValue.value !== dato) {
                            return dato.value;
                          }
                        }
                      ),
                    });
                  }
                }}
                isMulti
              />
            </li>
            <li className="adjustments-line" />
            <li className="button-container">
              <span>
                {!isLoading && (
                  <Button
                    color="info"
                    block
                    className="btn-round"
                    type="submit"
                  >
                    Filtrar
                  </Button>
                )}
                {isLoading && (
                  <CircleSpinner
                    className="text-center"
                    size={30}
                    color="#686769"
                    loading={isLoading}
                  />
                )}
              </span>
            </li>

            <li className="li"></li>
          </ul>
        </div>
      </Form>
    </div>
  );
}
export default PluginFilterRecepcionExpedientes;
