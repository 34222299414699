import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import moment from "moment";
import swal from "sweetalert";
import { URL_GENERAL } from "../../data/consts";

import Notifications from "react-notify-toast";
import React, { useEffect, useState } from "react";
import DateTimeComponent from "../../components/DateTimeComponent";

import {
  FormatedEncuestaTCS,
  EncuestaEntradaFormTCS,
} from "../../data/interfaces/Cedula";
import { EncuestaTCSValidator } from "../../data/validators/Cedula";

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardSubtitle,
  CardText,
  CardTitle,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Spinner,
  UncontrolledCollapse,
} from "reactstrap";

import Slider from "rc-slider";
import "rc-slider/assets/index.css";

const EncuestaTCSGTO = (props) => {
  const Datos = useParams();
  const [form, setForm] = useState(EncuestaEntradaFormTCS);
  const [blockButton, setBlockButton] = useState(false);
  const [loadingForm, setIsLoadingForm] = useState(true);

  const [errors, setErrors] = useState({
    error: false,
    seccion: -1,
    message: "",
  });

  const [saved, setSaved] = useState(false);

  const marksConsideracion = {
    0: "",
    1: "Muy insatisfecho(a)",
    2: "Insatisfecho(a)",
    3: "Ni satisfacho(a) ni insatisfecho(a)",
    4: "Satisfecho(a)",
    5: "Muy satisfecho(a)",
  };

  const marksConsideracion2 = {
    0: "",
    1: "Muy difícil",
    2: "Difícil",
    3: "Regular",
    4: "Fácil",
    5: "Muy fácil",
  };

  useEffect(() => {
    setIsLoadingForm(true);
    let data = { ...form };
    if (Datos?.CURP) {
      const newData = { ...Datos, TipoEncuesta: "E" };
      console.log(newData);
      data = FormatedEncuestaTCS(newData);
      setForm(data);
      setIsLoadingForm(false);
    } else {
      setSaved(true);
    }
  }, []);

  const save = (redirect = 0) => {
    const hasErrors = EncuestaTCSValidator(form);
    setErrors(hasErrors);
    if (!hasErrors.error) {
      setIsLoadingForm(true);
      let url = URL_GENERAL + "encuestaTCS";
      let formData = { ...form };
      var requestInfo = {
        method: "POST",
        body: JSON.stringify(formData),
        headers: new Headers({
          "Content-Type": "application/json",
        }),
      };

      fetch(url, requestInfo)
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          throw new Error(`Ocurrio un error: ${response.statusText}`);
        })
        .then((dataRS) => {
          swal(
            "Éxito",
            `Se ha creado la encuesta con folio ${dataRS.folio.toUpperCase()}`,
            "success"
          );
          setBlockButton(false);
        })
        .catch((e) => {
          const rs = {
            results: false,
            data: {},
            message: e.message,
          };
          swal("Error", "Ocurrió un error al guardar la encuesta", "error");
        })
        .finally(() => {
          setIsLoadingForm(false);
          setSaved(true);
          setBlockButton(false);
        });
    } else {
      setBlockButton(false);
    }
  };

  const style = {
    slider: {
      width: "80%",
      marginTop: "2rem",
      marginBottom: "4rem",
      marginLeft: "auto",
      marginRight: "auto",
      display: "grid",
      placeItems: "center",
    },
    check: {
      width: "80%",
      marginTop: "2rem",
      marginBottom: "4rem",
      marginLeft: "auto",
      marginRight: "auto",
      display: "grid",
      placeItems: "center",
    },
  };

  return (
    <>
      <Notifications />
      <div
        className="content"
        style={{
          diplay: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "30px 30px",
        }}
      >
        <Row>
          <Col md="12">
            <Card style={{ boxShadow: "2px 2px 10px 0px rgba(0,0,0,0.2)" }}>
              <CardHeader style={{ backgroundColor: "#000F9F" }}>
                <Row sm="12">
                  <Col sm="2"></Col>
                  <Col
                    sm="12"
                    style={{ display: "grid", placeItems: "center" }}
                  >
                    <CardTitle
                      style={{
                        fontSize: "25px",
                        textAlign: "center",
                        fontWeight: "bold",
                        color: "#DDD",
                      }}
                    >
                      Encuesta de satisfacción Tarjeta GTO Contigo Si
                    </CardTitle>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody style={{ paddingTop: "0" }}>
                {!saved ? (
                  <>
                    <Form>
                      <hr
                        style={{
                          backgroundColor:
                            errors.error && errors.seccion === 0
                              ? "red"
                              : "#E8E8E8",
                        }}
                      />
                      <Row>
                        <Col sm="4" xs="12">
                          <FormGroup>
                            <Label>Apoyo:</Label>
                            <Input
                              name="TipoApoyo"
                              type="Text"
                              value={"Tarjeta GTO Contigo Si"}
                              disabled={true}
                              // onChange={(e) => {
                              //   setForm({
                              //     ...form,
                              //     idTipoApoyo: e.target.value,
                              //   });
                              //   setFormPrograma(getTipoApoyo(e.target.value));
                              // }}
                            ></Input>
                          </FormGroup>
                        </Col>

                        <Col sm="4" xs="12">
                          <FormGroup>
                            <Label>Fecha:</Label>
                            {/* <span className="text-danger">*</span> */}
                            <DateTimeComponent
                              id="Fecha"
                              name="Fecha"
                              dateFormat="DD-MM-YYYY"
                              maxDate={moment()
                                .add(1, "days")
                                .format("DD-MM-YYYY")}
                              minDate={new Date("15-10-2023")}
                              timeFormat={false}
                              closeOnSelect={true}
                              disabled={true}
                              value={form.Fecha}
                              onChange={(e) =>
                                setForm({
                                  ...form,
                                  Fecha: moment(e._d).format("DD-MM-YYYY"),
                                })
                              }
                            />
                          </FormGroup>
                        </Col>

                        {errors.error && errors.seccion === 0 && (
                          <Col xs="12">
                            <CardText
                              style={{ color: "red", textAlign: "center" }}
                            >
                              {errors.message}
                            </CardText>
                          </Col>
                        )}
                      </Row>
                      <hr
                        style={{
                          backgroundColor:
                            errors.error && errors.seccion === 1
                              ? "red"
                              : "#E8E8E8",
                        }}
                      />

                      <Row>
                        <div
                          style={{
                            width: "98%",
                            display: "grid",
                            placeItems: "center",
                            margin: "auto auto",
                          }}
                        >
                          <CardSubtitle
                            id="beneficiario"
                            className="mb-2 text-muted"
                            tag="h6"
                            style={{
                              textAlign: "center",
                              width: "100%",
                              backgroundColor: "#CDE",
                              paddingTop: ".5rem",
                              paddingBottom: ".5rem",
                            }}
                          >
                            I. DATOS DEL SOLICITANTE
                            <i
                              style={{ marginLeft: "2rem" }}
                              className="fa fa-chevron-down"
                              aria-hidden="true"
                            ></i>
                          </CardSubtitle>

                          <UncontrolledCollapse
                            toggler="#beneficiario"
                            defaultOpen="beneficiario"
                          >
                            <Card>
                              <CardBody>
                                <Row sm="12">
                                  <Col sm="4">
                                    <FormGroup>
                                      <Label>
                                        CURP{" "}
                                        <span className="text-danger">*</span>
                                      </Label>
                                      <Input
                                        id="CURP"
                                        type="text"
                                        disabled={Datos.CURP !== null}
                                        maxLength="18"
                                        value={form.CURP}
                                        onChange={(event) =>
                                          setForm({
                                            ...form,
                                            CURP: event.target.value
                                              .toUpperCase()
                                              .replace(" ", ""),
                                          })
                                        }
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col
                                    sm="8"
                                    xs="12"
                                    style={{
                                      display: "grid",
                                      placeItems: "center",
                                    }}
                                  ></Col>
                                  <Col sm="4" xs="12">
                                    <FormGroup>
                                      <Label>
                                        Nombre(s){" "}
                                        <span className="text-danger">*</span>
                                      </Label>
                                      <Input
                                        id="Nombre"
                                        type="text"
                                        value={form.Nombre}
                                        onChange={(event) =>
                                          setForm({
                                            ...form,
                                            Nombre: event.target.value
                                              .toUpperCase()
                                              .replace(/\d/, ""),
                                          })
                                        }
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col sm="4" xs="12">
                                    <FormGroup>
                                      <Label>
                                        Primer apellido
                                        <span className="text-danger">*</span>
                                      </Label>
                                      <Input
                                        id="paterno"
                                        type="text"
                                        value={form.Paterno}
                                        onChange={(event) =>
                                          setForm({
                                            ...form,
                                            Paterno: event.target.value
                                              .toUpperCase()
                                              .replace(/\d/, ""),
                                          })
                                        }
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col sm="4" xs="12">
                                    <FormGroup>
                                      <Label>
                                        Segundo apellido
                                        {/* <span className="text-danger">*</span> */}
                                      </Label>
                                      <Input
                                        id="nombre"
                                        type="text"
                                        value={form.Materno}
                                        onChange={(event) =>
                                          setForm({
                                            ...form,
                                            Materno: event.target.value
                                              .toUpperCase()
                                              .replace(/\d/, ""),
                                          })
                                        }
                                      />
                                    </FormGroup>
                                  </Col>

                                  <Col sm="6" xs="12">
                                    <FormGroup>
                                      <Label>Municipio:</Label>
                                      <span className="text-danger">*</span>

                                      <Input
                                        name="Municipio"
                                        type="text"
                                        disabled={
                                          form.CURP === null || form.CURP === ""
                                        }
                                        value={form.Municipio}
                                        onChange={(e) =>
                                          setForm({
                                            ...form,
                                            Municipio: e.target.value
                                              .toUpperCase()
                                              .replace(/\d/, ""),
                                          })
                                        }
                                      ></Input>
                                    </FormGroup>
                                  </Col>

                                  <Col sm="6" xs="12">
                                    <FormGroup>
                                      <Label>Localidad:</Label>
                                      <span className="text-danger">*</span>

                                      <Input
                                        name="Localidad"
                                        type="text"
                                        disabled={
                                          form.CURP === null || form.CURP === ""
                                        }
                                        value={form.Localidad}
                                        onChange={(e) =>
                                          setForm({
                                            ...form,
                                            Localidad: e.target.value
                                              .toUpperCase()
                                              .replace(/\d/, ""),
                                          })
                                        }
                                      ></Input>
                                    </FormGroup>
                                  </Col>
                                  <Col sm="4" xs="12">
                                    <FormGroup>
                                      <Label>
                                        Colonia:{" "}
                                        <span className="text-danger">*</span>
                                      </Label>
                                      <Input
                                        name="Colonia"
                                        type="text"
                                        value={form.Colonia}
                                        onChange={(e) =>
                                          setForm({
                                            ...form,
                                            Colonia:
                                              e.target.value.toUpperCase(),
                                          })
                                        }
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col sm="4" xs="12">
                                    <FormGroup>
                                      <Label>
                                        Calle:{" "}
                                        <span className="text-danger">*</span>
                                      </Label>
                                      <Input
                                        name="Calle"
                                        type="text"
                                        value={form.Calle}
                                        onChange={(e) =>
                                          setForm({
                                            ...form,
                                            Calle: e.target.value.toUpperCase(),
                                          })
                                        }
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col sm="2" xs="12">
                                    <FormGroup>
                                      <Label>
                                        NumExt:{" "}
                                        <span className="text-danger">*</span>
                                      </Label>
                                      <Input
                                        name="NumExt"
                                        type="text"
                                        maxLength="5"
                                        value={form.NumExt}
                                        onChange={(e) =>
                                          setForm({
                                            ...form,
                                            NumExt:
                                              e.target.value.toUpperCase(),
                                          })
                                        }
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col sm="2" xs="12">
                                    <FormGroup>
                                      <Label>
                                        CP:{" "}
                                        <span className="text-danger">*</span>
                                      </Label>
                                      <Input
                                        name="CP"
                                        type="text"
                                        maxLength="5"
                                        value={form.CP}
                                        onChange={(e) =>
                                          setForm({
                                            ...form,
                                            CP: e.target.value.replace(
                                              /\D/,
                                              ""
                                            ),
                                          })
                                        }
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col sm="4" xs="12">
                                    <FormGroup>
                                      <Label>
                                        Celular:{" "}
                                        <span className="text-danger">*</span>
                                      </Label>
                                      <Input
                                        name="Telefono"
                                        type="text"
                                        value={form.Celular}
                                        onChange={(e) =>
                                          setForm({
                                            ...form,
                                            Celular: e.target.value.replace(
                                              /\D/,
                                              ""
                                            ),
                                          })
                                        }
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col sm="4" xs="12">
                                    <FormGroup>
                                      <Label>Correo: </Label>
                                      <Input
                                        name="Correo"
                                        type="text"
                                        value={form.Correo}
                                        onChange={(e) =>
                                          setForm({
                                            ...form,
                                            Correo:
                                              e.target.value.toLowerCase(),
                                          })
                                        }
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col sm="4" xs="12">
                                    <FormGroup>
                                      <Label>Facebook: </Label>
                                      <Input
                                        id="facebook"
                                        type="text"
                                        value={form.Facebook}
                                        onChange={(event) =>
                                          setForm({
                                            ...form,
                                            Facebook:
                                              event.target.value.toLowerCase(),
                                          })
                                        }
                                      />
                                    </FormGroup>
                                  </Col>
                                </Row>
                              </CardBody>
                            </Card>
                          </UncontrolledCollapse>
                        </div>

                        {errors.error && errors.seccion === 1 && (
                          <Col xs="12">
                            <CardText
                              style={{ color: "red", textAlign: "center" }}
                            >
                              {errors.message}
                            </CardText>
                          </Col>
                        )}
                      </Row>
                      <hr
                        style={{
                          backgroundColor:
                            errors.error && errors.seccion === 2
                              ? "red"
                              : "#E8E8E8",
                        }}
                      />
                      <CardSubtitle
                        className="mb-2 text-muted"
                        tag="h6"
                        style={{
                          textAlign: "center",
                          width: "100%",
                          backgroundColor: "#CDE",
                          paddingTop: ".5rem",
                          paddingBottom: ".5rem",
                        }}
                      >
                        II. ENCUESTA
                      </CardSubtitle>
                      <Row style={{ paddingTop: "2rem" }}>
                        <Col lg="6" md="12" sm="12" xs="12">
                          <FormGroup check>
                            <Label
                              style={{
                                fontWeight: "bold",
                              }}
                            >
                              1. ¿Conoce los beneficios de la Tarjeta GTO
                              Contigo Si?
                              <span className="text-danger">*</span>
                            </Label>
                            <Row
                              style={{
                                paddingTop: "2rem",
                                paddingBottom: "3rem",
                                marginLeft: "auto",
                                marginRight: "auto",
                              }}
                            >
                              <Col style={{ marginLeft: "30%" }}>
                                <FormGroup check>
                                  <Input
                                    name="q18"
                                    type="radio"
                                    checked={form.q18 === "S"}
                                    onChange={() =>
                                      setForm({
                                        ...form,
                                        q18: "S",
                                      })
                                    }
                                  />{" "}
                                  <Label
                                    check
                                    style={{
                                      paddingLeft: 0,
                                      cursorPointer: "none",
                                    }}
                                  >
                                    Si
                                  </Label>
                                </FormGroup>
                              </Col>
                              <Col>
                                <FormGroup check>
                                  <Input
                                    name="q18"
                                    type="radio"
                                    checked={form.q18 === "N"}
                                    onChange={() => {
                                      setForm({
                                        ...form,
                                        q18: "N",
                                      });
                                    }}
                                  />{" "}
                                  <Label check style={{ paddingLeft: 0 }}>
                                    No
                                  </Label>
                                </FormGroup>
                              </Col>
                            </Row>
                          </FormGroup>
                        </Col>

                        <Col lg="12" md="12" sm="12" xs="12">
                          <FormGroup>
                            <Label style={{ fontWeight: "bold" }}>
                              2. ¿Qué tan satisfecho(a) está con los beneficios
                              actuales de la Tarjeta GTO Contigo Si
                              <span className="text-danger">*</span>
                            </Label>
                            <Slider
                              value={form.q19}
                              min={1}
                              max={5}
                              step={1}
                              onChange={(e) => {
                                setForm({
                                  ...form,
                                  q19: e,
                                });
                              }}
                              marks={marksConsideracion}
                              style={style.slider}
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="12" md="12" sm="12" xs="12">
                          <FormGroup>
                            <Label style={{ fontWeight: "bold" }}>
                              3. ¿Cuáles son los beneficios que utiliza con
                              mayor frecuencia?{" "}
                              <span className="text-danger">*</span>
                            </Label>
                            <Input
                              id="beneficiosUsados"
                              type="textarea"
                              value={form.q20}
                              onChange={(event) =>
                                setForm({
                                  ...form,
                                  q20: event.target.value
                                    .toUpperCase()
                                    .replace(/\d/, ""),
                                })
                              }
                              style={{
                                fontSize: "11px",
                                marginTop: "1rem",
                                marginBottom: "3rem",
                              }}
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="12" md="12" sm="12" xs="12">
                          <FormGroup>
                            <Label style={{ fontWeight: "bold" }}>
                              4. ¿Qué tan fácil o díficil diría que es acceder a
                              los beneficios de la Tarjeta GTO Contigo Sí?
                              <span className="text-danger">*</span>
                            </Label>
                            <Slider
                              value={form.q21}
                              min={1}
                              max={5}
                              step={1}
                              onChange={(e) => {
                                setForm({
                                  ...form,
                                  q21: e,
                                });
                              }}
                              marks={marksConsideracion2}
                              style={style.slider}
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="12" md="12" sm="12" xs="12">
                          <FormGroup>
                            <Label style={{ fontWeight: "bold" }}>
                              5. Si pudiera elegir, ¿qué tipo de beneficios
                              adicionales le gustaría recibir con su Tarjeta GTO
                              Contigo Sí? (Marque todas las que correspondan)
                              <span className="text-danger">*</span>
                            </Label>
                            <Row
                              style={{
                                paddingTop: "2rem",
                                paddingBottom: "3rem",
                                marginLeft: "auto",
                                marginRight: "auto",
                              }}
                            >
                              <Row style={{ width: "90%", marginLeft: "3%" }}>
                                <Col>
                                  <FormGroup>
                                    <Input
                                      name="medicamentos"
                                      type="checkbox"
                                      checked={form.q22.r1}
                                      onChange={() =>
                                        setForm({
                                          ...form,
                                          q22: {
                                            ...form.q22,
                                            r1: !form.q22.r1,
                                          },
                                        })
                                      }
                                    />{" "}
                                    <Label check style={{ paddingLeft: 0 }}>
                                      Descuentos en medicamentos
                                    </Label>
                                  </FormGroup>
                                </Col>
                                <Col>
                                  <FormGroup>
                                    <Input
                                      name="utiles"
                                      type="checkbox"
                                      checked={form.q22.r2}
                                      onChange={() => {
                                        setForm({
                                          ...form,
                                          q22: {
                                            ...form.q22,
                                            r2: !form.q22.r2,
                                          },
                                        });
                                      }}
                                    />{" "}
                                    <Label check style={{ paddingLeft: 0 }}>
                                      Apoyo para útiles escolares
                                    </Label>
                                  </FormGroup>
                                </Col>
                                <Col>
                                  <FormGroup>
                                    <Input
                                      name="actividades"
                                      type="checkbox"
                                      checked={form.q22.r3}
                                      onChange={() => {
                                        setForm({
                                          ...form,
                                          q22: {
                                            ...form.q22,
                                            r3: !form.q22.r3,
                                          },
                                        });
                                      }}
                                    />{" "}
                                    <Label check style={{ paddingLeft: 0 }}>
                                      Acceso a actividades culturales y
                                      recreativas
                                    </Label>
                                  </FormGroup>
                                </Col>
                                <Col>
                                  <FormGroup>
                                    <Input
                                      name="programas"
                                      type="checkbox"
                                      checked={form.q22.r4}
                                      onChange={() => {
                                        setForm({
                                          ...form,
                                          q22: {
                                            ...form.q22,
                                            r4: !form.q22.r4,
                                          },
                                        });
                                      }}
                                    />{" "}
                                    <Label check style={{ paddingLeft: 0 }}>
                                      Programas de capacitación y empleo
                                    </Label>
                                  </FormGroup>
                                </Col>
                                <Col>
                                  <FormGroup>
                                    <Input
                                      name="consultas"
                                      type="checkbox"
                                      checked={form.q22.r5}
                                      onChange={() => {
                                        setForm({
                                          ...form,
                                          q22: {
                                            ...form.q22,
                                            r5: !form.q22.r5,
                                          },
                                        });
                                      }}
                                    />{" "}
                                    <Label check style={{ paddingLeft: 0 }}>
                                      Consultas medicas y dentales
                                    </Label>
                                  </FormGroup>
                                </Col>
                              </Row>
                              <Row
                                style={{
                                  width: "90%",
                                  marginLeft: "3%",
                                  paddingTop: "2rem",
                                }}
                              >
                                <Col>
                                  <FormGroup>
                                    <Input
                                      name="analisis"
                                      type="checkbox"
                                      checked={form.q22.r6}
                                      onChange={() => {
                                        setForm({
                                          ...form,
                                          q22: {
                                            ...form.q22,
                                            r6: !form.q22.r6,
                                          },
                                        });
                                      }}
                                    />{" "}
                                    <Label style={{ paddingLeft: 0 }}>
                                      Análisis clínicos
                                    </Label>
                                  </FormGroup>
                                </Col>
                                <Col>
                                  <FormGroup>
                                    <Input
                                      name="examenes"
                                      type="checkbox"
                                      checked={form.q22.r7}
                                      onChange={() => {
                                        setForm({
                                          ...form,
                                          q22: {
                                            ...form.q22,
                                            r7: !form.q22.r7,
                                          },
                                        });
                                      }}
                                    />{" "}
                                    <Label check style={{ paddingLeft: 0 }}>
                                      Exámenes de la vista
                                    </Label>
                                  </FormGroup>
                                </Col>
                                <Col>
                                  <FormGroup>
                                    <Input
                                      name="saldo"
                                      type="checkbox"
                                      checked={form.q22.r8}
                                      onChange={() => {
                                        setForm({
                                          ...form,
                                          q22: {
                                            ...form.q22,
                                            r8: !form.q22.r8,
                                          },
                                        });
                                      }}
                                    />{" "}
                                    <Label check style={{ paddingLeft: 0 }}>
                                      Saldo para el celular
                                    </Label>
                                  </FormGroup>
                                </Col>
                                <Col>
                                  <FormGroup>
                                    <Input
                                      name="otro"
                                      type="checkbox"
                                      checked={form.q22.r9}
                                      onChange={() => {
                                        setForm({
                                          ...form,
                                          q22: {
                                            ...form.q22,
                                            r9: !form.q22.r9,
                                          },
                                        });
                                      }}
                                    />{" "}
                                    <Label check style={{ paddingLeft: 0 }}>
                                      Otros
                                    </Label>
                                    {form.q22.r9 && (
                                      <Input
                                        id="otrosBeneficios"
                                        type="textarea"
                                        value={form.q22.r10}
                                        onChange={(event) =>
                                          setForm({
                                            ...form,
                                            q22: {
                                              ...form.q22,
                                              r10: event.target.value.toUpperCase(),
                                            },
                                          })
                                        }
                                        style={{
                                          fontSize: "11px",
                                          marginTop: "1rem",
                                        }}
                                      />
                                    )}
                                  </FormGroup>
                                </Col>
                              </Row>
                            </Row>
                          </FormGroup>
                        </Col>
                        <Col lg="12" md="12" sm="12" xs="12">
                          <FormGroup>
                            <Label style={{ fontWeight: "bold" }}>
                              6. ¿Qué considera que hace falta en los beneficios
                              actuales de la Tarjeta para satisfacer mejor sus
                              necesidades?{" "}
                              <span className="text-danger">*</span>
                            </Label>
                            <Input
                              id="beneficiosFaltantes"
                              type="textarea"
                              value={form.q23}
                              onChange={(event) =>
                                setForm({
                                  ...form,
                                  q23: event.target.value.toUpperCase(),
                                })
                              }
                              style={{
                                fontSize: "11px",
                                marginTop: "1rem",
                              }}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="12" md="12" sm="12" xs="12">
                          <FormGroup>
                            <Label style={{ fontWeight: "bold" }}>
                              7. ¿Tiene algún comentario o sugerencia para
                              mejorar la Tarjeta GTO Contigo Sí?
                              <span className="text-danger">*</span>
                            </Label>
                            <Input
                              id="Comentario"
                              type="textarea"
                              value={form.q24}
                              onChange={(event) =>
                                setForm({
                                  ...form,
                                  q24: event.target.value.toUpperCase(),
                                })
                              }
                              style={{
                                fontSize: "11px",
                                marginTop: "1rem",
                              }}
                            />
                          </FormGroup>
                        </Col>
                        {errors.error && errors.seccion === 2 && (
                          <Col xs="12">
                            <CardText
                              style={{ color: "red", textAlign: "center" }}
                            >
                              {errors.message}
                            </CardText>
                          </Col>
                        )}
                      </Row>
                      <hr
                        style={{
                          backgroundColor:
                            errors.error && errors.seccion === 2
                              ? "red"
                              : "#E8E8E8",
                        }}
                      />
                      <CardSubtitle
                        className="mb-2 text-muted"
                        tag="h6"
                        style={{
                          textAlign: "center",
                          width: "100%",
                          backgroundColor: "#CDE",
                          paddingTop: ".5rem",
                          paddingBottom: ".5rem",
                        }}
                      >
                        OBSERVACIONES DEL ENCUESTADOR
                      </CardSubtitle>

                      <Row>
                        <Col sm="12" xs="12">
                          <FormGroup>
                            <Label style={{ fontWeight: "bold" }}>
                              Comentarios:
                            </Label>
                            <Input
                              id="Observaciones"
                              type="textarea"
                              value={form.Observaciones}
                              onChange={(event) =>
                                setForm({
                                  ...form,
                                  Observaciones:
                                    event.target.value.toUpperCase(),
                                })
                              }
                              style={{
                                fontSize: "11px",
                                marginTop: "1rem",
                              }}
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      <hr
                        style={{
                          backgroundColor:
                            errors.error && errors.seccion === 2
                              ? "red"
                              : "#E8E8E8",
                        }}
                      />

                      <Row>
                        <Col sm="12" style={{ textAlign: "center" }}>
                          <Button
                            color="info"
                            disabled={blockButton}
                            onClick={() => {
                              setBlockButton(true);
                              save();
                            }}
                          >
                            Guardar
                          </Button>

                          {loadingForm && <Spinner />}
                        </Col>
                      </Row>
                    </Form>
                    <Row>
                      <Col
                        sm="12"
                        style={{ textAlign: "center", color: "red" }}
                      >
                        {errors.error && (
                          <CardText>
                            Existen errores, por favor verifique el formulario
                          </CardText>
                        )}
                      </Col>
                    </Row>
                  </>
                ) : (
                  <Row>
                    <Col>
                      <Button color="danger" onClick={() => {}}>
                        Cierre la ventana
                      </Button>
                    </Col>
                  </Row>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default EncuestaTCSGTO;
