import React, { useState, useEffect } from "react";
import moment from "moment";
import { Button, Input, Form, Label, FormGroup } from "reactstrap";
import Select from "react-select";
import EjecutarApi from "../Consultas/Consultas";
import { notify } from "react-notify-toast";
import { getVars } from "../GlobalStorage";
import "./styles.css";

function PluginFilterCalentadores(props) {
  const { sendFiltro } = props;
  const [classes, setclasses] = useState("dropdown");
  const token = getVars("Token");

  const [form, setForm] = useState({
    FechaSolicitud: "",
    CURP: "",
    RFC: "",
    Nombre: "",
    Materno: "",
    Paterno: "",
    Pendientes: false,
    Espera: false,
    Baja: false,
    Corregidos: false,
    Observadas: false,
    ExpedienteI: false,
    ExpedienteC: false,
    Aprobados: false,
    Comite: false,
    Region: "",
    Municipio: "",
    FolioSolicitud: "",
    FechaCreo: "",
    Formato: false,
  });
  const [MultiValueMuninicpio, setMultiValueMunicipio] = useState([]);
  const [MultiValueMuninicpio2, setMultiValueMunicipio2] = useState([]);
  const [MultiValueRegion, setMultiValueRegion] = useState([]);
  const [filterRegion, setfilterRegion] = useState([]);
  const [Ejercicio, setEjercicio] = useState({ value: 2023, label: "2023" });

  const handleClick = () => {
    if (classes === "dropdown") {
      setclasses("dropdown show");
    } else {
      setclasses("dropdown");
    }
  };

  useEffect(() => {
    EjecutarApi({}, "calentadoresSolares/getMunicipios", token.token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.results) {
          var region = [];
          setMultiValueMunicipio(
            res.data.map((municipio, i) => {
              if (region.indexOf(municipio["SubRegion"]) === -1) {
                region.push(municipio["SubRegion"]);
              }
              return {
                value: municipio["Id"],
                label: municipio["Nombre"],
              };
            })
          );
          var regio_n = region.sort((a, b) => a - b);
          setfilterRegion(
            regio_n.map((region_) => {
              return { value: region_, label: region_ };
            })
          );
        }
      })
      .catch((e) => {
        notify.show(`Error en la busqueda! ${e.message}`, "custom", 5000, {
          background: "#d61818",
          text: "#FFFFFF",
        });
      });
  }, []);

  const handleMultiRegion = (option) => {
    setMultiValueRegion(option);
  };

  const setFilter = (event) => {
    event.preventDefault();
    let region = [];

    if (MultiValueRegion) {
      if (MultiValueRegion.length > 0) {
        MultiValueRegion.map((r) => {
          region.push(r.value);
        });
      }
    }
    let filter = [];
    if (form.FechaSolicitud.length > 0)
      filter.push({
        id: ".FechaSolicitud",
        value: form.FechaSolicitud,
      });
    if (form.FechaCreo.length > 0)
      filter.push({
        id: ".FechaCreo",
        value: form.FechaCreo,
      });
    if (form.CURP.length > 0) filter.push({ id: ".CURP", value: form.CURP });
    if (form.Nombre.length > 0)
      filter.push({ id: ".Nombre", value: form.Nombre });
    if (form.Paterno.length > 0)
      filter.push({ id: ".Paterno", value: form.Paterno });
    if (form.Materno.length > 0)
      filter.push({ id: ".Materno", value: form.Materno });
    if (form.FolioSolicitud.length > 0)
      filter.push({ id: ".id", value: form.FolioSolicitud });
    if (form.Pendientes) filter.push({ id: ".idEstatusSolicitud", value: 1 });
    if (form.Corregidos) filter.push({ id: ".idEstatusSolicitud", value: 13 });
    if (form.Observadas) filter.push({ id: ".idEstatusSolicitud", value: 11 });
    if (form.ExpedienteI) filter.push({ id: ".ExpedienteCompleto", value: 0 });
    if (form.ExpedienteC) filter.push({ id: ".ExpedienteCompleto", value: 1 });
    if (form.Aprobados) filter.push({ id: ".idEstatusSolicitud", value: 5 });
    if (form.Espera) filter.push({ id: ".idEstatusSolicitud", value: 15 });
    if (form.Baja) filter.push({ id: ".idEstatusSolicitud", value: 16 });
    if (form.Comite) filter.push({ id: ".idEstatusSolicitud", value: 14 });
    if (form.Formato) filter.push({ id: ".Formato", value: 2 });
    if (region.length > 0) filter.push({ id: "region", value: region });
    if (MultiValueMuninicpio2.length > 0)
      filter.push({ id: ".idMunicipio", value: MultiValueMuninicpio2 });

    if (Ejercicio.value === 0) {
      filter.push({ id: ".Ejercicio", value: moment().format("Y") });
    } else {
      filter.push({ id: ".Ejercicio", value: Ejercicio.value });
    }

    let datos_ = {};
    datos_ = {
      page: 0,
      tipo: "or",
      pageSize: 10,
      filtered: filter,
    };
    sendFiltro(datos_);
  };

  return (
    <div className="fixed-plugin">
      <Form onSubmit={setFilter}>
        <div className={classes}>
          <div onClick={handleClick}>
            <i className="fa fa-cog fa-2x" />
          </div>
          <ul className="dropdown-menu show ul">
            <li className="adjustments-line">Ejercicio Fiscal:</li>
            <li className="li">
              <FormGroup sm="10">
                <Select
                  className="SelectSize"
                  name="Ejercicio"
                  placeholder="Seleccione..."
                  options={[
                    { value: 2024, label: "2024" },
                    { value: 2023, label: "2023" },
                  ]}
                  value={Ejercicio}
                  onChange={(Ejercicio) => setEjercicio(Ejercicio)}
                />
              </FormGroup>
            </li>
            <li className="adjustments-line">Folio Solicitud:</li>
            <Input
              type="text"
              name="FolioSolicitud"
              id="FolioSolicitud"
              placeholder="Folio Solicitud"
              value={form.FolioSolicitud}
              maxLength={6}
              onChange={(e) =>
                setForm({
                  ...form,
                  FolioSolicitud: e.target.value.toUpperCase(),
                })
              }
              className="form-control"
            />
            <li className="adjustments-line">Fecha Solicitud:</li>
            <Input
              type="date"
              name="FechaSolicitud"
              id="FechaSolicitud"
              placeholder="Fecha Solicitud"
              value={form.FechaSolicitud}
              onChange={(e) =>
                setForm({
                  ...form,
                  FechaSolicitud: moment(e.target.value).format("Y-MM-DD"),
                })
              }
              className="form-control"
            />
            <li className="adjustments-line">Fecha Creación:</li>
            <Input
              type="date"
              name="FechaCreo"
              id="FechaCreo"
              placeholder="Fecha Creación"
              value={form.FechaCreo}
              onChange={(e) =>
                setForm({
                  ...form,
                  FechaCreo: moment(e.target.value).format("Y-MM-DD"),
                })
              }
              className="form-control"
            />
            <li className="adjustments-line">SOLICITANTE:</li>
            <Input
              id="nombre"
              type="text"
              placeholder="Nombre"
              value={form.Nombre}
              onChange={(event) =>
                setForm({
                  ...form,
                  Nombre: event.target.value.toUpperCase(),
                })
              }
            />
            <Input
              id="paterno"
              type="text"
              placeholder="Apellido paterno"
              value={form.Paterno}
              onChange={(event) =>
                setForm({
                  ...form,
                  Paterno: event.target.value.toUpperCase(),
                })
              }
            />
            <Input
              id="materno"
              type="text"
              placeholder="Apellido materno"
              value={form.Materno}
              onChange={(event) =>
                setForm({
                  ...form,
                  Materno: event.target.value.toUpperCase(),
                })
              }
            />
            <Input
              type="text"
              name="CURP"
              placeholder="CURP"
              value={form.CURP}
              maxLength={18}
              style={{ textTransform: "uppercase" }}
              onChange={(e) =>
                setForm({
                  ...form,
                  CURP: e.target.value.toUpperCase(),
                })
              }
            />
            <li className="adjustments-line">
              <Label>Región:</Label>
            </li>
            <li className="li">
              <Select
                name="region"
                className="SelectSize"
                placeholder="Región"
                value={MultiValueRegion}
                options={filterRegion}
                onChange={handleMultiRegion}
                isMulti
              />
            </li>
            <li className="adjustments-line">
              <Label>Municipio:</Label>
            </li>
            <li className="li">
              <Select
                className="SelectSize"
                name="Municipio"
                placeholder="Municipio"
                options={MultiValueMuninicpio}
                onChange={(event, i) => {
                  if (event) {
                    setMultiValueMunicipio2(
                      event.map((dato) => {
                        return dato.value;
                      })
                    );
                  } else {
                    setMultiValueMunicipio2({
                      MultiValueMuninicpio2: MultiValueMuninicpio2.map(
                        (dato) => {
                          if (i.removedValue.value !== dato) {
                            return dato.value;
                          }
                        }
                      ),
                    });
                  }
                }}
                isMulti
              />
            </li>
            <li className="adjustments-line">
              <Label
                style={{
                  display: "block",
                  overflow: "hidden",
                  float: "left",
                }}
              >
                {"Por Validar"}
              </Label>{" "}
              <Input
                style={{
                  verticalAlign: "middle",
                  float: "right",
                  marginLeft: "5pt",
                }}
                type="checkbox"
                onChange={(e) =>
                  setForm({
                    ...form,
                    Pendientes: !form.Pendientes,
                  })
                }
              />
            </li>
            <li className="adjustments-line">
              <Label
                style={{
                  display: "block",
                  overflow: "hidden",
                  float: "left",
                }}
              >
                {"Observados"}
              </Label>{" "}
              <Input
                style={{
                  verticalAlign: "middle",
                  float: "right",
                  marginLeft: "5pt",
                }}
                type="checkbox"
                onChange={(e) =>
                  setForm({
                    ...form,
                    Observadas: !form.Observadas,
                  })
                }
              />
            </li>
            <li className="adjustments-line">
              <Label
                style={{
                  display: "block",
                  overflow: "hidden",
                  float: "left",
                }}
              >
                {"Corregidos"}
              </Label>{" "}
              <Input
                style={{
                  verticalAlign: "middle",
                  float: "right",
                  marginLeft: "5pt",
                }}
                type="checkbox"
                onChange={(e) =>
                  setForm({
                    ...form,
                    Corregidos: !form.Corregidos,
                  })
                }
              />
            </li>

            <li className="adjustments-line">
              <Label
                style={{
                  display: "block",
                  overflow: "hidden",
                  float: "left",
                }}
              >
                {"Lista de Espera"}
              </Label>{" "}
              <Input
                style={{
                  verticalAlign: "middle",
                  float: "right",
                  marginLeft: "5pt",
                }}
                type="checkbox"
                onChange={(e) =>
                  setForm({
                    ...form,
                    Espera: !form.Espera,
                  })
                }
              />
            </li>

            <li className="adjustments-line">
              <Label
                style={{
                  display: "block",
                  overflow: "hidden",
                  float: "left",
                }}
              >
                {"Bajas"}
              </Label>{" "}
              <Input
                style={{
                  verticalAlign: "middle",
                  float: "right",
                  marginLeft: "5pt",
                }}
                type="checkbox"
                onChange={(e) =>
                  setForm({
                    ...form,
                    Baja: !form.Baja,
                  })
                }
              />
            </li>

            <li className="adjustments-line">
              <Label
                style={{
                  display: "block",
                  overflow: "hidden",
                  float: "left",
                }}
              >
                {"Expediente Incompleto"}
              </Label>{" "}
              <Input
                style={{
                  verticalAlign: "middle",
                  float: "right",
                  marginLeft: "5pt",
                }}
                type="checkbox"
                onChange={(e) =>
                  setForm({
                    ...form,
                    ExpedienteI: !form.ExpedienteI,
                  })
                }
              />
            </li>
            <li className="adjustments-line">
              <Label
                style={{
                  display: "block",
                  overflow: "hidden",
                  float: "left",
                }}
              >
                {"Expediente Completo"}
              </Label>{" "}
              <Input
                style={{
                  verticalAlign: "middle",
                  float: "right",
                  marginLeft: "5pt",
                }}
                type="checkbox"
                onChange={(e) =>
                  setForm({
                    ...form,
                    ExpedienteC: !form.ExpedienteC,
                  })
                }
              />
            </li>
            <li className="adjustments-line">
              <Label
                style={{
                  display: "block",
                  overflow: "hidden",
                  float: "left",
                }}
              >
                {"Solicitud Completa"}
              </Label>{" "}
              <Input
                style={{
                  verticalAlign: "middle",
                  float: "right",
                  marginLeft: "5pt",
                }}
                type="checkbox"
                onChange={(e) =>
                  setForm({
                    ...form,
                    Aprobados: !form.Aprobados,
                  })
                }
              />
            </li>
            <li className="adjustments-line">
              <Label
                style={{
                  display: "block",
                  overflow: "hidden",
                  float: "left",
                }}
              >
                {"Aprobados Comité"}
              </Label>{" "}
              <Input
                style={{
                  verticalAlign: "middle",
                  float: "right",
                  marginLeft: "5pt",
                }}
                type="checkbox"
                onChange={(e) =>
                  setForm({
                    ...form,
                    Comite: !form.Comite,
                  })
                }
              />
            </li>
            <li className="adjustments-line">
              <Label
                style={{
                  display: "block",
                  overflow: "hidden",
                  float: "left",
                }}
              >
                {"Con base"}
              </Label>{" "}
              <Input
                style={{
                  verticalAlign: "middle",
                  float: "right",
                  marginLeft: "5pt",
                }}
                type="checkbox"
                onChange={(e) =>
                  setForm({
                    ...form,
                    Formato: !form.Formato,
                  })
                }
              />
            </li>
            <li className="button-container">
              <span>
                <Button color="info" block className="btn-round" type="submit">
                  Filtrar
                </Button>
              </span>
            </li>

            <li className="li"></li>
          </ul>
        </div>
      </Form>
    </div>
  );
}
export default PluginFilterCalentadores;
