import React, { useState, useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
} from "reactstrap";
import swal from "sweetalert";
import ReactTable from "react-table";
import { CircleSpinner } from "react-spinners-kit";
import EjecutarApi, { uploadFiles } from "../Consultas/Consultas";
import Notifications from "react-notify-toast";
import { getVars } from "../GlobalStorage";
import Pagination from "../components/pagination";
import { isNullOrUndefined } from "../utils/emptyValidations";
import ListadoArchivosExp from "../components/Archivos/ListadoArchivosExpedientes";
import PluginFilterListadoExpedientes from "../components/PluginFilterListadoExpedientes";

const ListadoExpedientes = (props) => {
  const history = useHistory();
  const { data, backSolicitud } = props;
  const Datos = data ? data : null;
  const [userData, setUserData] = useState();
  const [menu, setMenu] = useState({
    Ver: 0,
    Agregar: 0,
    Editar: 0,
    Eliminar: 0,
    Seguimiento: 0,
    Exportar: 0,
    Imprimir: 0,
    ViewAll: 0,
    idTipoUser: 0,
  });
  const [total, setTotal] = useState(0);
  const [catalogos, setCatalogos] = useState([]);
  const [archivos, setArchivos] = useState(false);
  const [menuLoaded, setMenuLoaded] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState({});
  const [dataCedulaList, setDataCedulaList] = useState([]);
  const [clasificacion, setClasificacion] = useState(false);
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [isLoadingArchivos, setIsLoadingArchivos] = useState(false);
  const [loadingCedulaList, setLoadingCedulaList] = useState(false);
  const [isLoadingCatalogos, setIsLoadingCatalogos] = useState(false);
  //Filtro
  const [filtro, setFiltro] = useState([]);
  const [pageOptions, setPage] = useState({
    page: 0,
    pageSize: 10,
  });

  const columns = [
    {
      Header: "Archivos",
      minWidth: 100,
      id: "Acciones",
      accessor: (d) => d.Acciones,
      className: "text-center",
      filterAll: false,
      style: { fontSize: "10px" },
      headerStyle: { fontSize: "10px" },
    },
    {
      Header: "Expediente Completo",
      minWidth: 120,
      id: "ExpedienteC",
      accessor: (d) => d.ExpedienteC,
      filterAll: false,
      style: { fontSize: "10px", textAlign: "center" },
      headerStyle: { fontSize: "10px" },
    },
    {
      Header: "Expediente Validado",
      minWidth: 120,
      id: "ExpedienteV",
      accessor: (d) => d.ExpedienteV,
      filterAll: false,
      style: { fontSize: "10px", textAlign: "center" },
      headerStyle: { fontSize: "10px" },
    },
    {
      Header: "Folio",
      minWidth: 80,
      id: "FolioSolicitud",
      accessor: (d) => d.FolioSolicitud,
      filterAll: false,
      style: { fontSize: "10px" },
      headerStyle: { fontSize: "10px" },
    },
    {
      Header: "Remesa",
      minWidth: 70,
      id: "Remesa",
      accessor: (d) => d.Remesa,
      filterAll: false,
      style: { fontSize: "10px" },
      headerStyle: { fontSize: "10px" },
    },
    {
      Header: "Nombre Completo",
      minWidth: 200,
      id: "NombreCompleto",
      accessor: (d) => d.NombreCompleto,
      filterAll: false,
      style: { fontSize: "10px" },
      headerStyle: { fontSize: "10px" },
    },
    {
      Header: "CURP",
      minWidth: 120,
      id: "CURP",
      accessor: (d) => d.CURP,
      filterAll: false,
      style: { fontSize: "10px" },
      headerStyle: { fontSize: "10px" },
    },
    {
      Header: "Region",
      minWidth: 70,
      id: "RegionM",
      accessor: (d) => d.Region,
      filterAll: false,
      style: { fontSize: "10px" },
      headerStyle: { fontSize: "10px" },
    },
    {
      Header: "Municipio",
      minWidth: 80,
      id: "Municipio",
      accessor: (d) => d.Municipio,
      filterAll: false,
      style: { fontSize: "10px" },
      headerStyle: { fontSize: "10px" },
    },
    {
      Header: "Localidad",
      minWidth: 80,
      id: "Localidad",
      accessor: (d) => d.Localidad,
      filterAll: false,
      style: { fontSize: "10px" },
      headerStyle: { fontSize: "10px" },
    },
    {
      Header: "Dirección",
      minWidth: 250,
      id: "Direccion",
      accessor: (d) => d.Direccion,
      filterAll: false,
      style: { fontSize: "10px" },
      headerStyle: { fontSize: "10px" },
    },
    {
      Header: "Responsable",
      minWidth: 150,
      id: "ResponsableEntrega",
      accessor: (d) => d.Responsable,
      filterAll: false,
      style: { fontSize: "10px" },
      headerStyle: { fontSize: "10px" },
    },
  ];

  useEffect(() => {
    if (isNullOrUndefined(userData)) return;
    const menus = userData.menu;
    const currentMenu = menus.find((menu) => menu.idMenu === 30);
    if (isNullOrUndefined(currentMenu) || currentMenu.Ver < 1) {
      swal({
        title: "Atención",
        text: "No tiene permisos para este módulo, contacte al administrador",
        icon: "warning",
      });
      history.push("/not-found");
      return;
    }
    setMenu({
      Ver: currentMenu.Ver,
      Agregar: currentMenu.Agregar,
      Editar: currentMenu.Editar,
      Eliminar: currentMenu.Eliminar,
      Seguimiento: currentMenu.Seguimiento,
      Exportar: currentMenu.Exportar,
      Imprimir: currentMenu.Imprimir,
      ViewAll: currentMenu.ViewAll,
      idTipoUser: userData.user.idTipoUser.id,
    });
    setMenuLoaded(true);
  }, [userData]);

  useEffect(() => {
    setUserData(getVars("Token"));
  }, []);

  const handleShowFiles = (solicitud) => {
    setIsModalOpened(true);
    setIsLoadingArchivos(true);
    setIsLoadingCatalogos(true);
    setSelectedRecord(solicitud);
    const token = getVars("Token");
    EjecutarApi({}, `vales/getClasificacionArchivos`, token.token, "GET")
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.results && res.success) {
          setCatalogos(res.data);
        } else {
          setIsModalOpened(false);
          swal({
            title: "¡Atención!",
            text: `${res.message}`,
            icon: "info",
          });
        }
        setIsLoadingCatalogos(false);
      })
      .catch((error) => {
        setIsLoadingCatalogos(false);
        setIsModalOpened(false);
        swal({
          title: "¡Atención!",
          text: `${error.message}`,
          icon: "info",
        });
      });
    EjecutarApi({}, `vales/getFilesById/${solicitud.id}`, token.token, "GET")
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.results && res.success) {
          setArchivos(res.data.Archivos);
          setClasificacion(res.data.ArchivosClasificacion);
        } else {
          setIsModalOpened(false);
          swal({
            title: "¡Atención!",
            text: `${res.message}`,
            icon: "info",
          });
        }
        setIsLoadingArchivos(false);
      })
      .catch((error) => {
        setIsLoadingArchivos(false);
        setIsModalOpened(false);
        swal({
          title: "¡Atención!",
          text: `${error.message}`,
          icon: "info",
        });
      });
  };

  const fetchData = useCallback(
    ({ pageSize, page, sorted, params }) => {
      const data = {
        page: page > pageOptions.page ? page : pageOptions.page,
        pageSize: pageSize,
        programa: 1,
        filtered: filtro,
        CveInterventor: Datos.CveInterventor,
        Remesa: Datos.Remesa,
      };
      setPage({
        page: page,
        pageSize: pageSize,
      });
      getRegistros(data);
    },
    [menu, filtro]
  );

  const getRegistros = (data) => {
    const token = getVars("Token");
    setLoadingCedulaList(true);
    data.filtered = filtro;
    EjecutarApi(data, "vales/getSolicitudesExpedientes", token.token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.results && res.success) {
          setDataCedulaList(
            res.data.map((item) => {
              if (item.Paterno == null) item.Paterno = "";
              if (item.Materno == null) item.Materno = "";
              if (item.NumInt == null) item.NumInt = "S/N";
              return {
                ...item,
                NombreCompleto: `${item.Nombre} ${item.Paterno} ${item.Materno}`,
                Direccion: `${item.Calle} ${item.NumExt} ${item.Colonia} ${item.CP}`,
                ExpedienteC:
                  item.ExpedienteCompleto === 1 ? (
                    <>
                      <span
                        class="spinner-grow text-success"
                        style={{ width: "1rem", height: "1rem" }}
                      ></span>{" "}
                    </>
                  ) : (
                    <>
                      <span
                        class="spinner-grow text-danger"
                        style={{ width: "1rem", height: "1rem" }}
                      ></span>{" "}
                    </>
                  ),
                ExpedienteV:
                  item.Validado === 1 ? (
                    <>
                      <span
                        class="spinner-grow text-success"
                        style={{ width: "1rem", height: "1rem" }}
                      ></span>{" "}
                    </>
                  ) : (
                    <>
                      <span
                        class="spinner-grow text-warning"
                        style={{ width: "1rem", height: "1rem" }}
                      ></span>{" "}
                    </>
                  ),
                Acciones: (
                  <>
                    <Row>
                      <Col>
                        <Button
                          color="warning"
                          size="sm"
                          style={{ width: "100%" }}
                          onClick={() => handleShowFiles(item)}
                        >
                          <i className="fa fa-file-archive-o fa-xs"></i>
                          Archivos
                        </Button>
                      </Col>
                    </Row>
                  </>
                ),
              };
            })
          );
          setTotal(res.total);
        } else {
          swal({
            title: "¡Atención!",
            text: `${res.message}`,
            icon: "info",
          });
        }
        setLoadingCedulaList(false);
      })
      .catch((e) => {
        setLoadingCedulaList(false);
        swal({
          title: "¡Atención!",
          text: `${e.message}`,
          icon: "warning",
        });
      });
  };

  const setFilter = (filtered) => {
    filtered.Remesa = Datos.Remesa;
    filtered.CveInterventor = Datos.CveInterventor;
    setFiltro(filtered.filtered);
    const token = getVars("Token");
    setLoadingCedulaList(true);
    EjecutarApi(filtered, "vales/getSolicitudesExpedientes", token.token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.results && res.success) {
          setDataCedulaList(
            res.data.map((item) => {
              if (item.Paterno == null) item.Paterno = "";
              if (item.Materno == null) item.Materno = "";
              if (item.NumInt == null) item.NumInt = "S/N";
              return {
                ...item,
                NombreCompleto: `${item.Nombre} ${item.Paterno} ${item.Materno}`,
                Direccion: `${item.Calle} ${item.NumExt} ${item.Colonia} ${item.CP}`,
                ExpedienteC:
                  item.ExpedienteCompleto === 1 ? (
                    <>
                      <span
                        class="spinner-grow text-success"
                        style={{ width: "1rem", height: "1rem" }}
                      ></span>{" "}
                    </>
                  ) : (
                    <>
                      <span
                        class="spinner-grow text-danger"
                        style={{ width: "1rem", height: "1rem" }}
                      ></span>{" "}
                    </>
                  ),
                ExpedienteV:
                  item.Validado === 1 ? (
                    <>
                      <span
                        class="spinner-grow text-success"
                        style={{ width: "1rem", height: "1rem" }}
                      ></span>{" "}
                    </>
                  ) : (
                    <>
                      <span
                        class="spinner-grow text-warning"
                        style={{ width: "1rem", height: "1rem" }}
                      ></span>{" "}
                    </>
                  ),
                Acciones: (
                  <>
                    <Row>
                      <Col>
                        <Button
                          color="warning"
                          size="sm"
                          style={{ width: "100%" }}
                          onClick={() => handleShowFiles(item)}
                        >
                          <i className="fa fa-file-archive-o fa-xs"></i>
                          Archivos
                        </Button>
                      </Col>
                    </Row>
                  </>
                ),
              };
            })
          );
          setTotal(res.total);
        } else {
          swal({
            title: "¡Atención!",
            text: `${res.message}`,
            icon: "info",
          });
        }
        setLoadingCedulaList(false);
      })
      .catch((e) => {
        setLoadingCedulaList(false);
        swal({
          title: "¡Atención!",
          text: `${e.message}`,
          icon: "warning",
        });
      });
  };

  const handleChangeArchivos = (files) => {
    setArchivos(files);
  };

  const handleChangeClasificacion = (arrayClasificacion) => {
    setClasificacion(arrayClasificacion);
  };

  const handleDeleteFile = (files, arrayClasificacion) => {
    setArchivos(files);
    setClasificacion(arrayClasificacion);
  };

  const validar = () => {
    const token = getVars("Token");
    const data = {
      id: selectedRecord.id,
    };
    setIsLoadingArchivos(true);
    EjecutarApi(data, "vales/validateSolicitud", token.token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.success && res.results) {
          swal({
            title: "¡Éxito!",
            icon: "success",
          });
          const data = {
            page: pageOptions.page,
            pageSize: pageOptions.pageSize,
            programa: 1,
            filtered: filtro,
            CveInterventor: Datos.CveInterventor,
            Remesa: Datos.Remesa,
          };
          getRegistros(data);
          setIsModalOpened(false);
        } else {
          swal({
            title: "Ha ocurrido un error",
            text: res.message,
            icon: "warning",
          });
          setIsLoadingArchivos(false);
        }
      })
      .catch((error) => {
        setIsLoadingArchivos(false);
        swal({
          title: "¡Atención!",
          text: error.message,
          icon: "info",
        });
      });
  };

  const changeFiles = () => {
    const token = getVars("Token");
    let oldFiles = [];
    let newFiles = [];
    let oldClasification = [];
    let newClasification = [];
    let flag = false;

    archivos.forEach((file, index) => {
      if (isNullOrUndefined(file.id)) {
        newFiles.push(file);
        if (isNullOrUndefined(clasificacion[index])) {
          flag = true;
          return;
        }
        newClasification.push(clasificacion[index]);
      } else {
        oldFiles.push(file);
        if (isNullOrUndefined(clasificacion[index])) {
          flag = true;
          return;
        }
        oldClasification.push(clasificacion[index]);
      }
    });

    if (flag) {
      swal({
        title: "Alerta",
        text: "Debe seleccionar una clasificación para cada archivo",
        icon: "warning",
      });
      return;
    }

    if (newFiles.length > 0) {
      let f = false;
      newFiles.map((file) => {
        let nameFile = file.name.toString();
        if (!/(.jpg|.jpeg|.png|.pdf)$/i.test(nameFile)) {
          f = true;
        }
      });
      if (f) {
        swal({
          title: "Alerta",
          text: "Solo puede cargar archivos de tipo PDF y JPG o PNG",
          icon: "warning",
        });
        return;
      }
    }
    if (oldClasification.length > 0 || newClasification.length > 0) {
      let formDataFiles = [];
      formDataFiles = oldClasification.concat(newClasification);
      if (formDataFiles.length > 0) {
        const formFiles = formDataFiles.map((element) => {
          return parseInt(element);
        });
        formDataFiles = formFiles;

        let ine = formDataFiles.filter((element) => element === 2);
        if (ine.length > 1) {
          swal({
            title: "Alerta",
            icon: "warning",
            text: "Solo puede cargar la identificación oficial una vez",
          });
          return;
        }

        let pvg = formDataFiles.filter((element) => element === 1);
        if (pvg.length > 1) {
          swal({
            title: "Alerta",
            icon: "warning",
            text: "Solo puede cargar la solicitud PVG una vez",
          });
          return;
        }

        let acuse = formDataFiles.filter((element) => element === 3);
        if (acuse.length > 1) {
          swal({
            title: "Alerta",
            icon: "warning",
            text: "Solo puede cargar el acuse de entrega una vez",
          });
          return;
        }

        let comp = formDataFiles.filter((element) => element === 7);
        if (comp.length > 1) {
          swal({
            title: "Alerta",
            icon: "warning",
            text: "Solo puede cargar el comprobante de domicilio una vez",
          });
          return;
        }

        let curp = formDataFiles.filter((element) => element === 8);
        if (curp.length > 1) {
          swal({
            title: "Alerta",
            icon: "warning",
            text: "Solo puede cargar el CURP una vez",
          });
          return;
        }

        let tarjeta = formDataFiles.filter((element) => element === 5);
        if (tarjeta.length > 1) {
          swal({
            title: "Alerta",
            icon: "warning",
            text: "Solo puede cargar la Tarjeta Contigo Si una vez",
          });
          return;
        }
      }
    }

    const formAux = {
      OldFiles: oldFiles,
      NewFiles: newFiles,
      OldClasificacion: oldClasification,
      NewClasificacion: newClasification,
      id: selectedRecord.id,
      programa: 1,
    };
    setIsLoadingArchivos(true);
    uploadFiles({
      data: formAux,
      api: `vales/updateArchivosSolicitud`,
      token: token.token,
    })
      .then(function (res) {
        return res;
      })
      .then((res) => {
        if (res.success && res.results) {
          swal({
            title: "¡Éxito!",
            icon: "success",
          });
          handleShowFiles(selectedRecord);
        } else {
          swal({
            title: "Ha ocurrido un error",
            text: res.message,
            icon: "warning",
          });
          setIsLoadingArchivos(false);
        }
      })
      .catch((error) => {
        setIsLoadingArchivos(false);
        swal({
          title: "Ha ocurrido un error",
          text: error.errorInfo,
          icon: "warning",
        });
      });
  };

  return (
    <>
      <Notifications />
      <div className="content">
        <>
          <Modal
            size="xl"
            isOpen={isModalOpened}
            toggle={() => setIsModalOpened(!isModalOpened)}
          >
            <ModalHeader>Archivos</ModalHeader>
            <ModalBody>
              {isLoadingArchivos && <Spinner />}
              {!isLoadingArchivos && (
                <ListadoArchivosExp
                  solicitud={selectedRecord}
                  files={archivos}
                  archivosClasificacion={clasificacion}
                  catalogClasificacion={catalogos}
                  handleChangeArchivos={handleChangeArchivos}
                  handleChangeClasificacion={handleChangeClasificacion}
                  handleDeleteFile={handleDeleteFile}
                  loadingCatalogs={isLoadingCatalogos}
                  onlyForView={selectedRecord.Validado === 1}
                  fullscreen={true}
                />
              )}
            </ModalBody>
            <ModalFooter>
              <Button
                size="sm"
                color="danger"
                disabled={isLoadingArchivos}
                onClick={() => setIsModalOpened(false)}
              >
                Cancelar
              </Button>
              {selectedRecord.Validado === 0 && (
                <>
                  {menu.Agregar === 1 && (
                    <Button
                      size="sm"
                      color="info"
                      disabled={isLoadingArchivos}
                      onClick={() => changeFiles()}
                    >
                      Guardar
                    </Button>
                  )}
                  {menu.Imprimir === 1 && (
                    <Button
                      size="sm"
                      color="success"
                      disabled={isLoadingArchivos}
                      onClick={() => validar()}
                    >
                      Validar
                    </Button>
                  )}
                </>
              )}
            </ModalFooter>
          </Modal>{" "}
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <Row>
                    <Col md="5">
                      <CardTitle tag="h6">
                        Listado{" "}
                        {Datos ? Datos.Remesa + "_" + Datos.CveInterventor : ""}
                      </CardTitle>
                    </Col>
                    <Col sm={{ size: "2", offset: "4" }}>
                      <Button color="success" onClick={backSolicitud} size="sm">
                        Regresar
                      </Button>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  {loadingCedulaList && (
                    <CircleSpinner
                      className="text-center"
                      size={30}
                      color="#686769"
                      loading={loadingCedulaList}
                    />
                  )}
                  <Col lg="12" md="12">
                    {menuLoaded && dataCedulaList && (
                      <ReactTable
                        size="sm"
                        data={dataCedulaList}
                        noDataText="No Existen Datos a Mostrar!"
                        manual
                        columns={columns}
                        className="-striped -highlight primary-pagination"
                        loading={loadingCedulaList}
                        showPagination={true}
                        showPaginationBottom={true}
                        showTotalPages={true}
                        showPageJump={false}
                        canNextFromData={true}
                        defaultPageSize={pageOptions.pageSize}
                        total={total}
                        page={pageOptions.page}
                        pages={Math.floor(total / pageOptions.pageSize)}
                        previousText="Anterior"
                        nextText="Siguiente"
                        ofText="de"
                        pageSizeOptions={[10, 20, 25, 50, 100]}
                        PaginationComponent={Pagination}
                        onFetchData={fetchData}
                      />
                    )}
                  </Col>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <PluginFilterListadoExpedientes
            sendFiltro={setFilter}
            Ejercicio={2023}
            clasess="dropdowns"
          />
        </>
      </div>
    </>
  );
};
export default ListadoExpedientes;
