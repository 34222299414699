import swal from "sweetalert";
import { isEmptyOrNullObject } from "../../utils/emptyValidations";
import { URL_PUBLICA, encryptVars, decryptVars, DEBUG, APIKEY } from "./global";

export const ApiExec = (data, api, method = "POST") => {
    let headers = new Headers({
        Authorization: APIKEY
    });

    if (DEBUG) {
        headers.append("Content-Type", "application/json");
    } else {
        headers.append("Content-Type", "text/plain;charset=UTF-8");
    }

    return new Promise((resolve, reject) => {
        let requestInfo = {
            method: method,
            body: DEBUG ? JSON.stringify(data) : encryptVars(JSON.stringify(data)),
            headers: headers,
        };
        let url = "";

        switch (method) {
            case "GET":
                url = new URL(URL_PUBLICA + api);
                delete requestInfo.body;
                if (!isEmptyOrNullObject(data)) {
                    Object.keys(data).forEach((key) => {
                        const value = data[key];
                        if (value !== "") {
                            url.searchParams.append(key, data[key]);
                        }
                    });
                }
                break;

            case "POST":
            case "PUT":
            case "DELETE":
            case "PATCH":
            default:
                url = URL_PUBLICA + api;
                break;
        }

        let errorMessage = "";
        fetch(url, requestInfo)
            .then((res) => {
                if (res.ok) {
                    return res.json();
                }

                switch (res.status) {
                    case 400:
                        errorMessage = "Error en la validación. Verifique los campos e intente nuevamente.";
                        break;

                    case 401:
                        return swal({
                            title: "Su sesión ha expirado!",
                            text: "Inicie sesión nuevamente",
                            icon: "warning",
                            showDenyButton: true,
                            showCancelButton: false,
                            showConfirmButton: false,
                            focusConfirm: true,
                            denyButtonText: "Renovar sesión",
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                            customClass: {
                                container: "modal-alert",
                            },
                        }).then((res) => {
                            if (res.isDenied) {
                                window.location.href = "/";
                            }
                        });

                    case 404:
                        errorMessage = "Api no encontrada.";
                        break;

                    case 428:
                        errorMessage = "El captcha no pasó la validación. Contacte al administrador."

                    case 429:
                        errorMessage =
                            "Su cuenta ha sido suspendida por 5 minutos por demasiados intentos inválidos. Por favor inténtelo más tarde.";
                        break;

                    case 500:
                        errorMessage = "Ocurrió un error, contacte al administrador.";
                        break;

                    default:
                        errorMessage = res.statusText;
                        break;
                }
                throw new Error(errorMessage);
            })
            .then((dataRS) => {
                if (!dataRS.success) {
                    reject({ ...dataRS, results: false });
                } else {
                    resolve({
                        ...dataRS,
                        response: DEBUG ? dataRS.response : decryptVars(dataRS.response),
                    });
                }
            })
            .catch((errors) => {
                reject({
                    results: false,
                    data: {},
                    message: errors.message,
                });
            });
    });
}

export default ApiExec;
