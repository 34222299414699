import React, { useState, useEffect, createRef } from "react";
// react plugin used to create switch buttons

import {
  Button,
  Input,
  Form,
  FormGroup,
  CardBody,
  Row,
  Col,
  Card,
  CardHeader,
  CardTitle,
  Label,
  CardFooter,
} from "reactstrap";

import ReactBSAlert from "react-bootstrap-sweetalert";
import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";
import Notifications, { notify } from "react-notify-toast";
import EjecutarApi from "../Consultas/Consultas";
import { getVars } from "../GlobalStorage";
import { useHistory } from "react-router-dom";
import ReactTable from "react-table";
import { ImpulseSpinner } from "react-spinners-kit";
import { URL_GENERAL } from "../data/consts";
import matchSorter from "match-sorter";

function GruposAdd(props) {
  console.log("props-modal", props.getResponse);
  const [data, setData] = useState([]);
  const [flagFolio, setflagFolio] = useState(true);
  const [flagFolioValidar, setflagFolioValidar] = useState(false);

  const [Articulador, setArticulador] = useState("");
  const [idArticulador, setidArticulador] = useState("");
  const [flagBoton, setflagBoton] = useState(true);
  const [flagBotonGruposAdd, setflagBotonGruposAdd] = useState(false);

  const [TotalArticuladores, setTotalArticuladores] = useState(0);
  const [flagMostrarValesAdd, setflagMostrarValesAdd] = useState(false);
  const [flagBuscaArticulador, setflagBuscaArticulador] = useState(false);
  const [loading, setloading] = useState(false);
  const [BuscaArticulador, setBuscaArticulador] = useState("");
  const [TotalNotIN, setTotalNotIN] = useState(0);
  let history = useHistory();

  const [DetalleSolicitante, setDetalleSolicitante] = useState([]);
  const [SolicitanteNombre, setSolicitanteNombre] = useState("");
  const [SolicitanteCURP, setSolicitanteCURP] = useState("");
  const [SolicitanteFolioInicialHumano, setSolicitanteFolioInicialHumano] =
    useState("");
  const [SolicitanteFolioInicial, setSolicitanteFolioInicial] = useState("");
  const [SolicitanteFolioFinal, setSolicitanteFolioFinal] = useState("");
  const [SolicitanteCodigoInicial, setSolicitanteCodigoInicial] = useState("");
  const [SolicitanteCodigoFinal, setSolicitanteCodigoFinal] = useState("");
  const [Grupo, setGrupo] = useState({});
  const [GrupoDetalle, setGrupoDetalle] = useState({});
  const [idGrupoState, setIdGrupoState] = useState(0);
  const inputFolio = createRef();
  const token = getVars("Token");
  useEffect(() => {
    const datos = {
      page: 0,
      tipo: "and",
      pageSize: 1000,
      sorted: [],
      filtered: [],
    };
  }, []);
  const btnFocus = () => {
    console.log("btnFocus");
  };

  const handledBucarArticulador = (NombreArticulador) => {
    setloading(true);
    const token = getVars("Token").token;
    const datax = {
      page: 0,
      tipo: "and",
      pageSize: 5,
      sorted: [{ id: "A.Nombre", desc: false }],
      filtered: [],
      NombreCompleto: NombreArticulador,
    };
    console.log("Modal::datax::", datax);
    EjecutarApi(datax, "getArticularSolicitudes", token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        setBuscaArticulador("");
        setloading(false);
        setTotalArticuladores(res.total);
        setData(
          res.data.map((prop, key) => {
            return {
              FullName: prop.FullName,
              Remesa: prop.Remesa,
              Municipio: prop.Municipio,
              Solicitudes: prop.Solicitudes,
              data: prop,
            };
          })
        );
      })
      .catch((e) => {
        setloading(false);
        notify.show(`Error al obtener colonias: ${e.errors}`, "custom", 5000, {
          background: "#0857B6",
          fontSize: 14,
          text: "#FFFFFF",
        });
      });
  };
  const handleMiniClick = () => {
    document.body.classList.toggle("sidebar-mini");
  };
  const mini_tableArticulador = (
    <>
      <ReactBSAlert
        style={{ display: "block", marginTop: "-250pt", width: "600px" }}
        onConfirm={() => setflagBuscaArticulador(false)}
        onCancel={() => setflagBuscaArticulador(false)}
        confirmBtnBsStyle="info"
      >
        <Card className="mb-4 text-muted">
          <CardBody className="mb-4 text-muted">
            <Form>
              <Row>
                <Label sm="2">
                  Articulador:<span className="text-danger">*</span>
                </Label>
                <Col sm="4">
                  <FormGroup>
                    <Input
                      id="articu"
                      type="text"
                      value={BuscaArticulador}
                      onChange={(e) => setBuscaArticulador(e.target.value)}
                    />
                  </FormGroup>
                </Col>
                <Col sm="2">
                  <Button
                    color="primary"
                    size="sm"
                    onClick={() => handledBucarArticulador(BuscaArticulador)}
                  >
                    <i className="nc-icon nc-zoom-split" />
                    Buscar
                  </Button>
                </Col>
              </Row>
              <ReactTable
                data={data}
                noDataText="¡No existen datos a mostrar!"
                columns={[
                  {
                    Header: "Remesa",
                    minWidth: 40,
                    id: "Remesa",
                    accessor: (d) => d.Remesa,
                    filterMethod: (filter, rows) =>
                      matchSorter(rows, filter.value, { keys: ["Remesa"] }),
                    filterAll: false,
                    headerStyle: { fontSize: "10px" },
                    style: { fontSize: "11px" },
                  },
                  {
                    Header: "Municipio",
                    minWidth: 50,
                    id: "Municipio",
                    accessor: (d) => d.Municipio,
                    filterMethod: (filter, rows) =>
                      matchSorter(rows, filter.value, { keys: ["Municipio"] }),
                    filterAll: false,
                    headerStyle: { fontSize: "10px" },
                    style: { fontSize: "11px" },
                  },
                  {
                    Header: "Articulador",
                    minWidth: 70,
                    id: "FullName",
                    accessor: (d) => d.FullName,
                    filterMethod: (filter, rows) =>
                      matchSorter(rows, filter.value, { keys: ["FullName"] }),
                    filterAll: false,
                    headerStyle: { fontSize: "10px" },
                    style: { fontSize: "11px" },
                  },
                  {
                    Header: "Solicitudes",
                    minWidth: 30,
                    id: "Solicitudes",
                    accessor: (d) => d.Solicitudes,
                    filterMethod: (filter, rows) =>
                      matchSorter(rows, filter.value, {
                        keys: ["Solicitudes"],
                      }),
                    filterAll: false,
                    headerStyle: { fontSize: "10px" },
                    style: { fontSize: "11px" },
                  },
                ]}
                defaultPageSize={5}
                manual
                className={"-striped -highlight primary-pagination"}
                loading={loading}
                showPagination={true}
                showPageJump={false}
                previousText="Anterior"
                nextText="Siguiente"
                canNextFromData={true}
                total={TotalArticuladores}
                pages={Math.floor(TotalArticuladores / 10)}
                pageSizeOptions={[10, 20, 25, 50, 100]}
                getTrProps={(state, rowInfo, column, instance) => {
                  return {
                    onClick: (e) => {
                      console.log(
                        "Modal--- rowInfo.original",
                        rowInfo.original
                      );
                      setGrupo({
                        ...Grupo,
                        UserOwned: rowInfo.original.data.UserOwned,
                        Municipio: rowInfo.original.data.Municipio,
                        idMunicipio: rowInfo.original.data.idMunicipio,
                        TotalAprobados: rowInfo.original.data.Solicitudes,
                        Remesa: rowInfo.original.data.Remesa,
                        Articulador: rowInfo.original.data.FullName,
                        Region: rowInfo.original.data.Region,
                        idIncidencia: 1,
                      });
                      setArticulador(rowInfo.original.data.FullName);
                      setidArticulador(rowInfo.original.data.UserOwned);
                      setflagBuscaArticulador(false); //Bandera de Modal
                    },
                  };
                }}
                onFetchData={(stateComp, instance) => {
                  setloading(true);
                  const token = getVars("Token").token;
                  const datax = {
                    page: stateComp.page,
                    tipo: "and",
                    pageSize: stateComp.pageSize,
                    sorted: [{ id: "A.Nombre", desc: false }],
                    filtered: [],
                  };
                  console.log("Modal::datax::", datax);
                  console.log("Entra aqui!!!!!!!!!!!!!!!!!!!!!");
                  EjecutarApi(datax, "getArticularSolicitudes", token)
                    .then(function (res) {
                      return res;
                    })
                    .then((res) => {
                      setloading(false);
                      setTotalArticuladores(res.total);
                      setData(
                        res.data.map((prop, key) => {
                          return {
                            FullName: prop.FullName,
                            Remesa: prop.Remesa,
                            Municipio: prop.Municipio,
                            FullName: prop.FullName,
                            Solicitudes: prop.Solicitudes,
                            data: prop,
                          };
                        })
                      );
                    })
                    .catch((e) => {
                      setloading(false);
                      notify.show(
                        `Error al obtener colonias: ${e.errors}`,
                        "custom",
                        5000,
                        {
                          background: "#0857B6",
                          fontSize: 14,
                          text: "#FFFFFF",
                        }
                      );
                    });
                }}
              />
            </Form>
          </CardBody>
        </Card>
      </ReactBSAlert>
    </>
  );
  const setGrupoEvent = () => {
    if (Grupo.UserOwned.length < 3) {
      notify.show("Error: Articulador es requerido", "custom", 5000, {
        background: "#0857B6",
        fontSize: 14,
        text: "#FFFFFF",
      });
      return true;
    }
    if (Grupo.idMunicipio.length < 1) {
      notify.show("Error: Municipio es requerido", "custom", 5000, {
        background: "#0857B6",
        fontSize: 14,
        text: "#FFFFFF",
      });
      return true;
    }
    if (Grupo.TotalAprobados.length < 1) {
      notify.show("Error: Total Aprobados es requerido", "custom", 5000, {
        background: "#0857B6",
        fontSize: 14,
        text: "#FFFFFF",
      });
      return true;
    }
    if (Grupo.Remesa.length < 1) {
      notify.show("Error: Remesa es requerido", "custom", 5000, {
        background: "#0857B6",
        fontSize: 14,
        text: "#FFFFFF",
      });
      return true;
    }

    console.log("Grupos Add-->", Grupo);
    EjecutarApi(Grupo, "setGrupos", token.token)
      .then(function (res) {
        setflagBoton(false);
        setflagBotonGruposAdd(true);
        console.log("=>> ID GRUPO ##################", res.data.id);
        handleDetallesSolicitante();
        setflagMostrarValesAdd(true);

        setIdGrupoState(res.data.id);
        notify.show("Registro Exitoso! ", "custom", 5000, {
          background: "#18c97d",
          text: "#FFFFFF",
        });
        return res;
      })
      .catch((e) => {
        console.log("errrorrrrrrrrr", e.errors);

        notify.show(
          `Error al registrar Grupo, el Articulador ha sido guardado! `,
          "custom",
          5000,
          {
            background: "#d61818",
            text: "#FFFFFF",
          }
        );
      });
  };
  const handleReturnGrupos = () => {
    history.push("/Grupos");
  };
  const handleReturn = () => {
    setSolicitanteFolioInicial("");
    setSolicitanteFolioFinal("");
    setSolicitanteCodigoInicial("");
    setSolicitanteCodigoFinal("");
    setSolicitanteCURP("");
    setSolicitanteNombre("");
    setGrupoDetalle({});
    setflagFolio(true);
    handleDetallesSolicitante();
  };
  const GrupoValeAdd = (Folio) => {
    setloading(true);
    if (SolicitanteCURP < 1) {
      setflagFolio(true);
      setloading(false);
      notify.show("Error: CURP, es requerido", "custom", 5000, {
        background: "#0857B6",
        fontSize: 14,
        text: "#FFFFFF",
      });
      return true;
    }
    if (SolicitanteNombre < 1) {
      setflagFolio(true);
      setloading(false);
      notify.show("Error: CURP, es requerido", "custom", 5000, {
        background: "#0857B6",
        fontSize: 14,
        text: "#FFFFFF",
      });
      return true;
    }
    if (Folio < 1) {
      setflagFolio(true);
      setloading(false);
      notify.show(
        "Error: Serie / Código de Barras, es requerido",
        "custom",
        5000,
        {
          background: "#0857B6",
          fontSize: 14,
          text: "#FFFFFF",
        }
      );
      return true;
    }
    if (SolicitanteFolioInicial % 10 !== 1) {
      setflagFolio(true);
      setloading(false);
      notify.show(
        "Error: Serie / Código de Barras, no tiene residuo de 1.",
        "custom",
        5000,
        {
          background: "#0857B6",
          fontSize: 14,
          text: "#FFFFFF",
        }
      );
      return true;
    }

    let data = {
      idSolicitud: GrupoDetalle.idSolicitud,
      CURP: GrupoDetalle.CURP,
      Nombre: GrupoDetalle.Nombre,
      Paterno: GrupoDetalle.Paterno,
      Materno: GrupoDetalle.Materno,
      SerieInicial: SolicitanteFolioInicial,
      SerieFinal: SolicitanteFolioFinal,
      CodigoBarrasInicial: SolicitanteCodigoInicial,
      CodigoBarrasFinal: SolicitanteCodigoFinal,
      UserOwned: idArticulador,
      Articulador: Articulador,
      idMunicipio: Grupo.idMunicipio,
      Municipio: Grupo.Municipio,
      Remesa: Grupo.Remesa,
    };

    console.log("=> setValesSolicitudes: ", JSON.stringify(data));
    EjecutarApi(data, "setValesSolicitudes", token.token)
      .then(function (res) {
        setloading(false);
        console.log("setValesSolicitudes RS:", res);
        setSolicitanteFolioInicialHumano("");
        setSolicitanteFolioInicial("");
        setSolicitanteFolioFinal("");
        setSolicitanteCodigoInicial("");
        setSolicitanteCodigoFinal("");
        setSolicitanteCURP("");
        setSolicitanteNombre("");
        setGrupoDetalle({});
        setflagFolio(true);
        handleDetallesSolicitante();

        notify.show("Registro Exitoso!", "custom", 5000, {
          background: "#18c97d",
          text: "#FFFFFF",
        });

        return res;
      })
      .catch((e) => {
        console.log("error", e.errors);
        setflagFolio(true);
        setloading(false);
        notify.show(`Error:! ${e.message}`, "custom", 5000, {
          background: "#d61818",
          text: "#FFFFFF",
        });
      });
  };
  const handleDetallesSolicitante = () => {
    var array = [];
    var pageSizeTemporal = 0;
    console.log("IDMUNICIPIO", Grupo.idMunicipio);
    if (idArticulador !== "") {
      array.push({ id: "usersC.id", value: idArticulador });
      array.push({ id: "vales.idMunicipio", value: Grupo.idMunicipio });
      array.push({ id: "vales.Remesa", value: Grupo.Remesa });
      array.push({ id: "vales.idIncidencia", value: 1 });
      pageSizeTemporal = 10;
    }

    setloading(true);
    const token = getVars("Token");
    const datax = {
      page: 0,
      tipo: "and",
      pageSize: 200,
      sorted: [],
      filtered: array,
    };

    console.log("=>getValesNotIn", JSON.stringify(datax));
    console.log("=>token", token.token);

    EjecutarApi(datax, "getValesNotIn", token.token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        setloading(false);
        setTotalNotIN(res.data.total);
        setDetalleSolicitante(
          res.data.map((prop, key) => {
            return {
              CURP: prop.CURP,
              Nombre: prop.Nombre,
              Paterno: prop.Paterno,
              Materno: prop.Materno,
              CodigoBarrasInicial: prop.FolioInicial,
              CodigoBarrasFinal: prop.FolioFinal,
              Remesa: prop.Remesa,
              SerialInicial: prop.SerialInicial,
              SerieFinal: prop.SerieFinal,
              data: prop,
            };
          })
        );
      })
      .catch((e) => {
        console.log("=Error[getExport]: ", e);
        setloading(false);
        notify.show(
          `Error al obtener getValesNotIn: ${e.errors}`,
          "custom",
          5000,
          {
            background: "#0857B6",
            fontSize: 14,
            text: "#FFFFFF",
          }
        );
      });
  };

  const handleDescargaPDF = (param) => {
    const token = getVars("Token");
    var url = new URL(
      URL_GENERAL + `getReporteAcuseVales?idGrupo=${param}&token=${token.token}`
    );
    console.log(
      `${URL_GENERAL}getReporteNominaVales?idGrupo=${param}&token=${token.token}`
    );
    window.open(url, "_blank");
  };

  const handleValidarForlio = (Folio) => {
    setloading(true);

    if (SolicitanteCURP < 1) {
      setloading(false);
      setflagFolio(true);
      notify.show("Error: CURP, es requerido", "custom", 5000, {
        background: "#0857B6",
        fontSize: 14,
        text: "#FFFFFF",
      });
      return true;
    }
    if (SolicitanteNombre < 1) {
      setloading(false);
      setflagFolio(true);
      notify.show("Error: CURP, es requerido", "custom", 5000, {
        background: "#0857B6",
        fontSize: 14,
        text: "#FFFFFF",
      });
      return true;
    }
    if (Folio < 1) {
      setloading(false);
      setflagFolio(true);
      notify.show(
        "Error: Serie / Código de Barras, es requerido",
        "custom",
        5000,
        {
          background: "#0857B6",
          fontSize: 14,
          text: "#FFFFFF",
        }
      );
      return true;
    }
    if (Folio < 1) {
      setloading(false);
      setflagFolio(true);
      notify.show(
        "Error: Serie / Código de Barras, es requerido",
        "custom",
        5000,
        {
          background: "#0857B6",
          fontSize: 14,
          text: "#FFFFFF",
        }
      );
      return true;
    }

    const token = getVars("Token");
    const datax = {
      CodigoBarra: Folio,
    };
    console.log("=>getSerieVale: ", JSON.stringify(datax));
    console.log("=>tok: ", token.token);
    EjecutarApi(datax, "getSerieVale", token.token)
      .then(function (res) {
        return res;
      })
      .then((res) => {
        setloading(false);
        console.log("···············res.data", res.data);
        setflagFolio(false);
        setSolicitanteFolioFinal(res.data.FolioFinal);
        setSolicitanteFolioInicial(res.data.FolioInicial);
        setSolicitanteCodigoInicial(res.data.CodigoBarraInicial);
        setSolicitanteCodigoFinal(res.data.CodigoBarraFinal);
      })
      .catch((e) => {
        console.log("=Error[getExport]: ", e);
        setloading(false);
        notify.show(`Error al obtener Vales: ${e.message}`, "custom", 5000, {
          background: "#0857B6",
          fontSize: 14,
          text: "#FFFFFF",
        });
      });
  };

  return (
    <div className="wrapper">
      <Sidebar {...props} bgColor="black" activeColor="info" />
      <div className="main-panel">
        <Navbar {...props} handleMiniClick={handleMiniClick} />
        <Notifications />
        <div className="content">
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <Row>
                    <Col md="9">
                      <CardTitle tag="h6">Agregar Grupos</CardTitle>
                    </Col>
                    <Col>
                      <Button
                        color="success"
                        onClick={() => {
                          handleReturnGrupos();
                        }}
                        size="sm"
                      >
                        <i className="nc-icon nc-minimal-left" />
                        Regresar
                      </Button>
                    </Col>
                  </Row>
                </CardHeader>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <Card>
                <CardBody>
                  <Form className="form-horizontal" id="TypeValidation">
                    <Notifications />
                    <Row>
                      <Label sm="2">
                        Articulador:<span className="text-danger">*</span>
                      </Label>
                      <Col sm="4">
                        <FormGroup>
                          <Input
                            id="articu"
                            type="text"
                            value={Articulador}
                            disabled
                          />
                        </FormGroup>
                      </Col>
                      <Col md="2">
                        <Button
                          color="primary"
                          size="sm"
                          onClick={() => {
                            setflagBuscaArticulador(true);
                            console.log("Buscando Articulador POPup");
                          }}
                        >
                          <span className="btn-label">
                            <i className="nc-icon nc-zoom-split" />
                          </span>{" "}
                          Buscar
                        </Button>
                        {flagBuscaArticulador ? mini_tableArticulador : <></>}
                      </Col>
                    </Row>
                    <Row>
                      <Label sm="2">
                        Municipio:<span className="text-danger">*</span>
                      </Label>
                      <Col sm="6">
                        <FormGroup>
                          <Input
                            id="articu"
                            type="text"
                            value={Grupo.Municipio}
                            disabled
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Label sm="2">
                        Total Aprobados:<span className="text-danger">*</span>
                      </Label>
                      <Col sm="4">
                        <FormGroup>
                          <Input
                            id="Total"
                            type="text"
                            value={Grupo.TotalAprobados}
                            disabled
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Label sm="2">
                        Número de Remesa:<span className="text-danger">*</span>
                      </Label>
                      <Col sm="4">
                        <FormGroup>
                          <Input
                            id="remesa"
                            type="text"
                            value={Grupo.Remesa}
                            disabled
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
                <CardFooter className="text-center">
                  {flagBoton ? (
                    <>
                      <Button
                        color="primary"
                        onClick={() => {
                          setGrupoEvent();
                        }}
                        size="sm"
                      >
                        {
                          <ImpulseSpinner
                            color={"#1261ff"}
                            size={30}
                            loading={flagBotonGruposAdd}
                          />
                        }

                        {flagBotonGruposAdd ? (
                          <span>Guardando..</span>
                        ) : (
                          <span>Guardar</span>
                        )}
                      </Button>
                      <Button color="danger" onClick={handleReturn} size="sm">
                        <i className="nc-icon nc-simple-remove" />
                        Cancelar
                      </Button>{" "}
                    </>
                  ) : (
                    <>
                      <Button
                        size="sm"
                        color="danger"
                        onClick={() => {
                          console.log("GRUPO EN EL ONCLICK", idGrupoState);
                          handleDescargaPDF(idGrupoState);
                        }}
                      >
                        <span className="btn-label">
                          <i className="fa fa-file-pdf-o fa-1x" />
                        </span>
                      </Button>
                    </>
                  )}
                </CardFooter>
              </Card>
            </Col>
          </Row>
          {flagMostrarValesAdd ? (
            <Row>
              <Col md="6">
                <Card>
                  <CardHeader>
                    <Row>
                      <Col>
                        <CardTitle tag="h6"> Detalle del Solicitante</CardTitle>
                      </Col>
                    </Row>
                    <Row>
                      <p className="text-info">
                        [Total Capturados:{" "}
                        {DetalleSolicitante.length - Grupo.TotalAprobados} de{" "}
                        {Grupo.TotalAprobados} ]
                      </p>
                    </Row>
                  </CardHeader>
                  <Row>
                    <Col>
                      <ReactTable
                        size="sm"
                        noDataText="¡No existen datos a mostrar!"
                        data={DetalleSolicitante}
                        columns={[
                          {
                            Header: "CURP",
                            minWidth: 50,
                            id: "CURP",
                            accessor: (d) => d.CURP,
                            filterMethod: (filter, rows) =>
                              matchSorter(rows, filter.value, {
                                keys: ["Municipio"],
                              }),
                            filterAll: false,
                            headerStyle: { fontSize: "10px" },
                            style: { fontSize: "11px" },
                          },
                          {
                            Header: "Nombre",
                            minWidth: 70,
                            id: "Nombre",
                            accessor: (d) =>
                              `${d.Nombre !== null ? d.Nombre : ""} ${
                                d.Paterno !== null ? d.Paterno : ""
                              } ${d.Materno !== null ? d.Materno : ""}`,
                            filterMethod: (filter, rows) =>
                              matchSorter(rows, filter.value, {
                                keys: ["Articulador"],
                              }),
                            headerStyle: { fontSize: "10px" },
                            style: { fontSize: "11px" },
                          },
                        ]}
                        className="-striped -highlight primary-pagination"
                        loading={loading}
                        showPagination={true}
                        showPageJump={false}
                        canNextFromData={true}
                        defaultPageSize={DetalleSolicitante.length}
                        manual
                        total={TotalNotIN}
                        pages={1}
                        previousText="Anterior"
                        nextText="Siguiente"
                        pageSizeOptions={[50, 100, 200]}
                        getTrProps={(state, rowInfo, column, instance) => {
                          return {
                            onClick: (e) => {
                              setSolicitanteCURP(rowInfo.original.data.CURP);
                              setSolicitanteNombre(
                                rowInfo.original.data.Nombre
                              );
                              setGrupoDetalle({
                                ...GrupoDetalle,
                                idSolicitud: rowInfo.original.data.id,
                                CURP: rowInfo.original.data.CURP,
                                Nombre: rowInfo.original.data.Nombre,
                                Paterno: rowInfo.original.data.Paterno,
                                Materno: rowInfo.original.data.Materno,
                                CodigoBarrasInicial:
                                  rowInfo.original.data.FolioInicial,
                                CodigoBarrasFinal:
                                  rowInfo.original.data.FolioFinal,
                                Remesa: rowInfo.original.data.Remesa,
                                SerialInicial:
                                  rowInfo.original.data.SerialInicial,
                                SerieFinal: rowInfo.original.data.SerieFinal,
                              });
                              inputFolio.current.focus();
                            },
                          };
                        }}
                      />
                    </Col>
                  </Row>
                </Card>
              </Col>

              <Col md="6">
                <Form>
                  <Card>
                    <CardHeader>
                      <h6>Datos del Vale</h6>
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Label sm="2">CURP:</Label>
                        <Col sm="10">
                          <FormGroup>
                            <Input
                              id="CURP"
                              type="text"
                              value={SolicitanteCURP}
                              disabled
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Label sm="2">Nombre:</Label>
                        <Col sm="10">
                          <FormGroup>
                            <Input
                              id="Nombre"
                              type="text"
                              value={SolicitanteNombre}
                              disabled
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Label sm="2">
                          Folio/Código Barras Inicial:
                          <span className="text-danger">*</span>
                        </Label>
                        <Col sm="10">
                          <FormGroup>
                            <Input
                              ref={inputFolio}
                              isFocused={btnFocus}
                              autoFocus={true}
                              name="FolioInicialHumano"
                              id="FolioInicialHumano"
                              value={SolicitanteFolioInicialHumano}
                              onChange={(event) =>
                                setSolicitanteFolioInicialHumano(
                                  event.target.value
                                )
                              }
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Label sm="2">
                          Folio Inicial:<span className="text-danger">*</span>
                        </Label>
                        <Col sm="10">
                          <FormGroup>
                            <Input
                              name="FolioInicial"
                              type="text"
                              value={SolicitanteFolioInicial}
                              onChange={(event) =>
                                setSolicitanteFolioInicial(event.target.value)
                              }
                              disabled
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Label sm="2">
                          Folio Final:<span className="text-danger">*</span>
                        </Label>
                        <Col sm="10">
                          <FormGroup>
                            <Input
                              name="FolioFinal"
                              type="text"
                              value={SolicitanteFolioFinal}
                              disabled
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Label sm="2">
                          Códifo Barras Inicial:
                          <span className="text-danger">*</span>
                        </Label>
                        <Col sm="10">
                          <FormGroup>
                            <Input
                              name="FolioInicial"
                              type="text"
                              value={SolicitanteCodigoInicial}
                              disabled
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Label sm="2">
                          Código Barras Final:
                          <span className="text-danger">*</span>
                        </Label>
                        <Col sm="10">
                          <FormGroup>
                            <Input
                              name="FolioFinal"
                              type="text"
                              value={SolicitanteCodigoFinal}
                              disabled
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Label sm="2">
                          Remesa:<span className="text-danger">*</span>
                        </Label>
                        <Col sm="10">
                          <FormGroup>
                            <Input
                              name="FolioFinal"
                              type="text"
                              value={Grupo.Remesa}
                              disabled
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </CardBody>
                    <CardFooter>
                      <Row>
                        {flagFolio ? (
                          <>
                            {!loading && (
                              <Button
                                color="primary"
                                onClick={() => {
                                  handleValidarForlio(
                                    SolicitanteFolioInicialHumano
                                  );
                                }}
                                size="sm"
                              >
                                {
                                  <ImpulseSpinner
                                    color={"#1261ff"}
                                    size={30}
                                    loading={flagFolioValidar}
                                  />
                                }

                                {flagFolioValidar ? (
                                  <span>Guardando..</span>
                                ) : (
                                  <span>Buscar Vales</span>
                                )}
                              </Button>
                            )}
                            {loading && (
                              <ImpulseSpinner
                                color={"#1261ff"}
                                size={30}
                                loading={true}
                              />
                            )}
                            <Button
                              color="danger"
                              onClick={handleReturn}
                              size="sm"
                            >
                              <i className="nc-icon nc-simple-remove" />
                              Cancelar
                            </Button>{" "}
                          </>
                        ) : (
                          <>
                            {!loading && (
                              <Button
                                color="primary"
                                onClick={() => {
                                  GrupoValeAdd(SolicitanteFolioInicial);
                                }}
                                size="sm"
                              >
                                {
                                  <ImpulseSpinner
                                    color={"#1261ff"}
                                    size={30}
                                    loading={flagFolioValidar}
                                  />
                                }

                                {flagFolioValidar ? (
                                  <span>Guardando..</span>
                                ) : (
                                  <span>Asignar Vales</span>
                                )}
                              </Button>
                            )}
                            {loading && (
                              <ImpulseSpinner
                                color={"#1261ff"}
                                size={30}
                                loading={true}
                              />
                            )}
                            <Button
                              color="danger"
                              onClick={handleReturn}
                              size="sm"
                            >
                              <i className="nc-icon nc-simple-remove" />
                              Cancelar
                            </Button>{" "}
                          </>
                        )}
                      </Row>
                    </CardFooter>
                  </Card>
                </Form>
              </Col>
            </Row>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
}
export default GruposAdd;
